import clone from "just-clone";
import React, { useEffect, useState } from "react";
import { ConvertedBlock } from "../../../../asset-generator-lib/composer/models/Block";
import { useValidationsContext } from "../../../providers/ValidationsProvider";
import { updateSection } from "../../helpers/templateConfigHelpers";
import { Action } from "../../helpers/templatePageReducer";
import { TemplatePageDraft } from "../../types/TemplatePages";
import DnDItem from "../inputs/DnDInput/DnDItem/DnDItem";
import DnDWrapper from "../inputs/DnDInput/DnDWrapper/DnDWrapper";
import {
  launchDateTemplateConfig,
  siteNameTemplateConfig,
  sitePasswordTemplateConfig,
} from "../StaticTemplateSections/StaticTemplateSections";
import TemplatePageFormSection from "../TemplatePageFormSection/TemplatePageFormSection";
import styles from "./TemplatePageEditorForm.module.scss";
import { createPortal } from "react-dom";

type Props = {
  templatePageDraft: TemplatePageDraft;
  dispatchTemplatePage: React.Dispatch<Action>;
};

const TemplatePageEditorForm = ({
  templatePageDraft,
  dispatchTemplatePage,
}: Props) => {
  const { validations, validationsDispatch, validateAtKey } =
    useValidationsContext();
  const [sectionDragging, setSectionDragging] = useState<string | null>(null);
  const clonedTemplatePageDraft = clone(templatePageDraft);

  const reOrder = (templateConfig: ConvertedBlock[]) => {
    dispatchTemplatePage({
      type: "UPDATE_DRAFT", //used to be update_templateconfig
      payload: {
        ...templatePageDraft,
        templateConfig: templateConfig,
      },
    });
    setSectionDragging(null);
  };

  const updateLaunchDate = (update: any) => {
    switch (update.launchDate.key) {
      case "startDate":
        //set templatePageDraft.startDate to update.launchdate.startDate.value
        if (templatePageDraft.startDate !== update.launchDate.value) {
          dispatchTemplatePage({
            type: "UPDATE_DRAFT",
            payload: {
              ...templatePageDraft,
              startDate: update.launchDate.value.value,
            },
          });
        }
        break;

      case "expirationDate":
        //set templatePageDraft.expirationDate to update.launchdate.expirationDate.value
        if (templatePageDraft.startDate !== update.launchDate.value) {
          dispatchTemplatePage({
            type: "UPDATE_DRAFT",
            payload: {
              ...templatePageDraft,
              endDate: update.launchDate.value.value,
            },
          });
        }
        break;
      default:
        return console.error("🛑", update.launchDate.key);
    }
  };
  const updatePassword = (update: any) => {
    if (templatePageDraft.password !== update.sitePassword.value) {
      dispatchTemplatePage({
        type: "UPDATE_DRAFT",
        payload: {
          ...templatePageDraft,
          password: update.sitePassword.value.value,
        },
      });
    }
  };

  const launchDateKey = launchDateTemplateConfig(templatePageDraft).key;
  const siteNameKey = siteNameTemplateConfig(templatePageDraft).key;

  const launchDate =
    launchDateTemplateConfig(templatePageDraft).params?.launchDate;
  const expirationDate =
    launchDateTemplateConfig(templatePageDraft).params?.expirationDate;

  useEffect(() => {
    //This useEffect is for revalidating or removing validations
    //based on expirations and scheduled dates
    const updatedValidations = { ...validations };
    const hasDate = validations[launchDateKey];
    const hasExpiration = hasDate && hasDate[expirationDate.value.key];
    const hasScheduled = hasDate && hasDate[launchDate.value.key];

    if (hasDate && hasExpiration && !launchDate.value.value) {
      delete updatedValidations[launchDateKey][expirationDate.value.key];
      validationsDispatch({
        type: "REMOVE_VALIDATION",
        payload: updatedValidations,
      });
    }
    if (hasDate && hasScheduled && !expirationDate.value.value) {
      delete updatedValidations[launchDateKey][launchDate.value.key];
      validationsDispatch({
        type: "REMOVE_VALIDATION",
        payload: updatedValidations,
      });
    }

    if (
      launchDate.value.value &&
      expirationDate.value.value &&
      (!hasExpiration || !hasScheduled)
    ) {
      // validate both expiration and launch dates
      validateAtKey(
        {
          [launchDate.value.key]: launchDate.value.value,
          [expirationDate.value.key]: expirationDate.value.value,
        },
        launchDateKey,
        [launchDate.value.key, expirationDate.value.key]
      );
    }
  }, [launchDate.value.value, expirationDate.value.value]);

  console.log(templatePageDraft);

  return (
    <div className={styles.container}>
      {/* this is where we put any other form inputs that dont go in a template
      (template page name, password, etc) */}
      <div
        className={`${styles["template-page-name"]}`}
        id={`${siteNameKey}-form_section`}
      >
        <input
          className={`input-underline ${styles["template-page-name-input"]}`}
          type="text"
          name="name"
          value={templatePageDraft.name || ""}
          onBlur={() =>
            validateAtKey(
              { [siteNameKey]: templatePageDraft.name },
              siteNameKey,
              [siteNameKey]
            )
          }
          onChange={(e) => {
            dispatchTemplatePage({
              type: "UPDATE_DRAFT",
              payload: { ...templatePageDraft, name: e.target.value },
            }),
              validateAtKey({ [siteNameKey]: e.target.value }, siteNameKey, [
                siteNameKey,
              ]);
          }}
          placeholder="NEW ARTIST EPK"
        />
        <div className={`error ${styles["error-position"]}`}>
          {validations &&
            validations[siteNameKey] &&
            validations[siteNameKey][siteNameKey]}
        </div>
      </div>
      <div id="above-dnd"></div>
      {/* main template sections  */}
      <DnDWrapper
        id={"form-sections"}
        items={templatePageDraft.templateConfig}
        setItems={reOrder}
        setSectionDragging={setSectionDragging}
      >
        {clonedTemplatePageDraft?.templateConfig?.map(
          (section: ConvertedBlock, index: number) => {
            // we don't want sections rendering inside the DnDWrapper, causes jumpyness
            // so we check each section to see if the next in line is draggable
            // this sorts sections to above and below the dnd sections outside the DnDwrapper
            // which fixes the jumpyness creating a clean  DnD UI/UX.
            const portalSection =
              !section.draggable &&
              clonedTemplatePageDraft?.templateConfig[index - 1]?.draggable &&
              !clonedTemplatePageDraft?.templateConfig[index + 1]?.draggable
                ? "below-dnd"
                : "above-dnd";
            const portalDiv = document.getElementById(portalSection)!;
            return (
              <React.Fragment key={section.key}>
                {!section.draggable ? (
                  <>
                    {portalDiv &&
                      createPortal(
                        <TemplatePageFormSection
                          templatePageDraft={templatePageDraft}
                          dispatchTemplatePage={dispatchTemplatePage}
                          clonedSection={section}
                          updateTemplateConfigSection={(input) => {
                            updateSection(
                              input,
                              templatePageDraft,
                              dispatchTemplatePage
                            );
                          }}
                          disabled={false}
                        />,
                        portalDiv
                      )}
                  </>
                ) : (
                  <DnDItem item={section} index={index}>
                    <TemplatePageFormSection
                      sectionDragging={sectionDragging}
                      templatePageDraft={templatePageDraft}
                      dispatchTemplatePage={dispatchTemplatePage}
                      clonedSection={section}
                      updateTemplateConfigSection={(input) => {
                        updateSection(
                          input,
                          templatePageDraft,
                          dispatchTemplatePage
                        );
                      }}
                      disabled={false}
                    />
                  </DnDItem>
                )}
              </React.Fragment>
            );
          }
        )}
      </DnDWrapper>

      <div id="below-dnd"></div>
      {/* launch date */}
      <TemplatePageFormSection
        templatePageDraft={templatePageDraft}
        dispatchTemplatePage={dispatchTemplatePage}
        clonedSection={
          launchDateTemplateConfig(templatePageDraft) as ConvertedBlock // not cloned like below
        }
        updateTemplateConfigSection={updateLaunchDate}
        disabled={false}
      />

      {/* site password */}
      <TemplatePageFormSection
        templatePageDraft={templatePageDraft}
        dispatchTemplatePage={dispatchTemplatePage}
        clonedSection={
          sitePasswordTemplateConfig(templatePageDraft) as ConvertedBlock // not cloned like below
        }
        updateTemplateConfigSection={updatePassword}
        disabled={false}
      />
    </div>
  );
};

export default TemplatePageEditorForm;
