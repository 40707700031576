import React, { useContext } from "react";
import { isWidthUp, makeStyles, withWidth } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ContentNode } from "../ContentProvider";
import { TOCContext } from "./TableOfContentsMenu";
import { useActiveButton } from "../../../shared/CustomHooks";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

type Props = {
  contentNode?: ContentNode;
  width: Breakpoint;
  indent?: number;
  fontWeight?: "normal" | "bold";
  fontSize?: "13px" | "15px";
};

type StyleProps = {
  isMobile: Boolean;
  indent?: number;
  fontWeight?: "normal" | "bold";
  fontSize?: "13px" | "15px";
};

const useStyles = makeStyles(() => ({
  button: {
    display: "block",
    textDecoration: "none",
    transition: "100ms ease-in-out",
    cursor: "pointer",
    padding: ({ indent }) =>
      indent ? `0 20px 0 ${(indent + 1) * 18}px` : `0 18px`,
    textAlign: "start",
    fontFamily: "Helvetica",
    fontSize: ({ fontSize }) => fontSize || "15px",
    fontWeight: ({ fontWeight }) => fontWeight || "bold",
    color: "#ffffff",
    border: 0,
    borderRadius: "4px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",

    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#1d1d1d",
    },
    "&:active": {
      backgroundColor: "#555555",
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: ({ isMobile }: StyleProps) =>
      isMobile ? "1fr" : "repeat(4,1fr)",
    gridAutoRows: "47px",
    paddingTop: ({ isMobile }) => (isMobile ? "0" : "14px"),
    paddingBottom: ({ isMobile }) => (isMobile ? "0" : "14px"),
  },
  activeButton: {
    backgroundColor: "#555555",
  },
}));

const TOCButtons = ({
  contentNode,
  width,
  indent,
  fontWeight,
  fontSize,
}: Props) => {
  const isMobile = !isWidthUp("sm", width);
  const classes = useStyles({ isMobile, indent, fontWeight, fontSize });
  const { setOpen } = useContext(TOCContext);

  const buttons = contentNode?.children?.map((child) => (
    <Link
      key={child.slug}
      to={child.path()}
      className={`${classes.button} ${
        useActiveButton(contentNode, child) && classes.activeButton
      }`}
      onClick={() => setOpen(false)}
    >
      {child.title}
    </Link>
  ));

  return <div className={classes.gridContainer}>{buttons}</div>;
};

export default withWidth()(TOCButtons);
