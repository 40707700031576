import React from "react";
import { Box, Grid, Theme, makeStyles } from "@material-ui/core";
import TextValidatorWrapper from "./TextValidatorWrapper";
import { Update } from "../ArtistLinkEdit/FormFields";

const useStyles = makeStyles((theme: Theme) => ({
  titleInput: {
    textAlign: "center",
    height: "50px",
    backgroundColor: theme.palette.primary.main,
    fontFamily: "Futura",
    fontSize: "24px",
    fontWeight: "bold",
  },
}));

interface Props {
  name: string;
  label: string;
  onChange: (update: Update) => void;
  artistLinkKey?: any;
  id?: any;
  multiline?: any;
}

export default function ArtistLinkTitleInput(props: Props) {
  const classes = useStyles();

  return (
    <Box width={1} pb={3}>
      <Grid item xs={12}>
        <Box p={3}>
          <TextValidatorWrapper
            update={props.onChange}
            initValue={props.artistLinkKey}
            passThroughProps={{
              variant: "standard",
              id: props.id,
              name: props.name,
              fullWidth: true,
              placeholder: props.label,
              inputProps: { className: classes.titleInput },
              errorMessages: ["This field is required"],
              validators: ["required"],
            }}
          />
        </Box>
      </Grid>
    </Box>
  );
}
