import React, { useEffect, useState } from "react";
import { bookmarkNode, isNodeBookmarked } from "./BookmarksManager";
import { ContentNode } from "./ContentProvider";
import { ReactComponent as BookmarkEmpty } from "../../shared/icons/bookmark.svg";
import { ReactComponent as BookmarkFilled } from "../../shared/icons/bookmark-fill.svg";
import { IconButton, makeStyles } from "@material-ui/core";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

type Props = {
  contentNode: ContentNode;
  width?: string;
  color?: string;
};

type StyleProps = {
  width?: string;
  color?: string;
};

const useStyles = makeStyles(() => ({
  bookmarkButton: {
    cursor: "pointer",
    fontFamily: "inherit",
    border: 0,
    backgroundColor: "transparent",
    color: ({ color }: StyleProps) => (color ? color : "#70d7ff"),
  },

  icon: {
    width: ({ width }: StyleProps) => (width ? width : "27px"),
    color: ({ color }: StyleProps) => (color ? color : "#70d7ff"),
  },
}));

// TODO: we should make a provider for bookmarks so we get live updates from around the site

const Bookmark = ({ contentNode, width, color }: Props) => {
  const { trackContentNodeBookmarked } = useAnalyticsContext();
  const [bookmarked, setBookmarked] = useState(isNodeBookmarked(contentNode)); // need to actually get init status

  const classes = useStyles({ width, color });

  //send off to bookmark manager when hook is changed
  useEffect(() => {
    bookmarkNode(contentNode, bookmarked);
  }, [bookmarked]);

  // when we change content nodes, check if its bookmarked
  useEffect(() => {
    setBookmarked(isNodeBookmarked(contentNode));
  }, [contentNode]);

  const handleBookmarkClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setBookmarked(!bookmarked);
    trackContentNodeBookmarked(contentNode, bookmarked);
  };

  return (
    <IconButton
      color="inherit"
      className={classes.bookmarkButton}
      onClick={handleBookmarkClick}
    >
      {bookmarked ? (
        <BookmarkFilled className={classes.icon} />
      ) : (
        <BookmarkEmpty className={classes.icon} />
      )}
    </IconButton>
  );
};

export default Bookmark;
