import { TemplateVariant } from "../../../asset-generator-lib";
import PromoPack from "../models/PromoPack";
import { AssetGeneratorAction } from "../../providers/AnalyticsProvider";
import { Asset } from "../../link-builder/models/ArtistLink";

type VariantStatus = {
  variant: string;
  status: "PENDING" | "RENDERING" | "UPLOADING" | "DONE";
  upload?: any;
};

type Status = {
  [key: string]: VariantStatus;
};

type ValidationObject = {
  field: string;
  valid: boolean;
};

const useUploadBlob = async (
  updateAsset: (
    blob: any,
    promopackId: string,
    assetId: string
  ) => Promise<any>,
  createAsset: (blob: any, promoPackId: string) => Promise<any>,
  setUploadStatus: (value: React.SetStateAction<Status>) => void,
  blob: any,
  promoPackId: string,
  variant: TemplateVariant,
  assetId?: string
) => {
  setUploadStatus((prevStatus) => ({
    ...prevStatus,
    [variant.key]: {
      variant: variant.name,
      status: "UPLOADING",
      upload: null,
    },
  }));

  // check if we are in edit or create mode
  if (assetId) return await updateAsset(blob, promoPackId, assetId);
  else return await createAsset(blob, promoPackId);
};

const useUploadVariant = async (
  updateAsset: (
    blob: any,
    promopackId: string,
    assetId: string
  ) => Promise<any>,
  createAsset: (blob: any, promoPackId: string) => Promise<any>,
  trackPromoAsset: (
    promoAsset: Asset,
    action: AssetGeneratorAction,
    p4aArtistId: string
  ) => void,
  setUploadStatus: (value: React.SetStateAction<Status>) => void,
  promoPack: PromoPack,
  variant: TemplateVariant,
  exportRenderedComposition: any,
  p4aArtistId: string,
  assetId?: string
) => {
  setUploadStatus((prevStatus) => ({
    ...prevStatus,
    [variant.key]: {
      variant: variant.name,
      status: "RENDERING",
      upload: null,
    },
  }));

  let blob = await exportRenderedComposition();
  const formData = new FormData();
  formData.append("attachment", blob);
  formData.append("description", variant.name);
  formData.append("type", ".png");

  const result = await useUploadBlob(
    updateAsset,
    createAsset,
    setUploadStatus,
    formData,
    promoPack.id,
    variant,
    assetId
  );

  trackPromoAsset(result, AssetGeneratorAction.assetCreated, p4aArtistId);
  setUploadStatus((prevStatus) => ({
    ...prevStatus,
    [variant.key]: {
      variant: variant.name,
      status: "DONE",
      upload: result,
    },
  }));
};

export {
  useUploadBlob,
  useUploadVariant,
  VariantStatus,
  Status,
  ValidationObject,
};
