import React from "react";
import { ReactComponent as TwitterIcon } from "../../shared/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../../shared/icons/youtube.svg";
import { ReactComponent as FacebookIcon } from "../../shared/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../shared/icons/instagram.svg";
import { ReactComponent as TiktokIcon } from "../../shared/icons/tiktok.svg";
import { ReactComponent as WebsiteIcon } from "../../shared/icons/website.svg";

const SocialIcon = ({ icon }: any) => {
  //TODO: Typed as any because typescript object constructor
  switch (icon) {
    case "instagram":
      return <InstagramIcon />;
    case "twitter":
      return <TwitterIcon />;
    case "facebook":
      return <FacebookIcon />;
    case "tiktok":
      return <TiktokIcon />;
    case "youtube":
      return <YoutubeIcon />;
    case "website":
      return <WebsiteIcon />;
    default:
      return null;
  }
};

export default SocialIcon;
