import { CardData } from "../PromoteCard/PromoteCard";

export const cardsData: CardData[] = [
  {
    title: "ASSET GENERATOR",
    img: "/images/promote/cards/asset-generator.png",
    desc: "Build beautiful marketing assets to promote your releases with Asset Generator. Just choose a release, customize, and share with your fans.",
    btnTxt: "build",
    internalLink: "asset-generator",
  },
  {
    title: "Artist EPK",
    img: "/images/promote/cards/template-page-builder.png",
    desc: "Create a stunning Electronic Press Kit (EPK) with just a few easy clicks. Fully customize your EPK with your biography, Press Images, Videos, Tour Dates, Press Clippings, and more.",
    btnTxt: "create",
    internalLink: "template-page-builder",
  },
  {
    title: "Stealth Player",
    img: "/images/promote/cards/stealth-player.png",
    desc: "Stealth player allows you to easily build private listening links for your music. These links are meant to assist you when pitching to playlists and other marketing outlets.",
    btnTxt: "create",
    internalLink: "stealth-builder",
  },
  {
    title: "Pitch Portal",
    img: "/images/promote/cards/pitch-portal.png",
    desc: "Submit your music in the pitch portal for playlist consideration across all streaming services such as Apple Music, Spotify, Tidal, and more.",
    btnTxt: "pitch",
    externalLink: `https://${process.env.SYMPHONY_HOST}/pitch-portal`,
  },
];
