import React from "react";
import { Link } from "react-router-dom";
import styles from "./PromoteCard.module.scss";

export type CardData =
  | {
      title: string;
      img: string;
      desc: string;
      btnTxt: string;
      internalLink: string;
      externalLink?: never;
    }
  | {
      title: string;
      img: string;
      desc: string;
      btnTxt: string;
      internalLink?: never;
      externalLink: string;
    };

type CardProps = { cardData: CardData };

const PromoteCard = ({ cardData }: CardProps) => {
  return (
    <div className={styles["card"]}>
      <h2>{cardData.title}</h2>
      <div
        className={styles["img"]}
        style={{
          backgroundImage: `url('${cardData.img}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className={styles["container"]}>
        <p>{cardData.desc}</p>
        {cardData.externalLink && (
          <a target="_parent" className="btn" href={cardData.externalLink}>
            {cardData.btnTxt}
          </a>
        )}
        {cardData.internalLink && (
          <Link className="btn" to={cardData.internalLink}>
            {cardData.btnTxt}
          </Link>
        )}
      </div>
    </div>
  );
};

export default PromoteCard;
