import React from "react";
import { useWindowSize } from "react-use";

import Header from "../../../shared/Header";
import InfiniteScrollIndexSection, {
  IndexSectionProps,
} from "../../../shared/InfiniteScrollIndexSection/InfiniteScrollIndexSection";
import PaginatedIndexSection from "../../../shared/PaginatedIndexSection/PaginatedIndexSection";
import NoTemplatePages from "../NoTemplatePages/NoTemplatePages";
import TemplatePageCard from "../TemplatePageCard/TemplatePageCard";
import styles from "./TemplatePageIndex.module.scss";

const Index = () => {
  const { width } = useWindowSize();
  const isMobile = width < 906;

  const indexSectionProps: IndexSectionProps = {
    headerTitle: "EPKS",
    linkObj: {
      buttonText: "EPK",
      emptyText: "Build an Artist EPK",
      to: "artist-epk/new/edit",
    },
    endpoint: "template-pages", // this will need a param to filter by type
    cardElement: <TemplatePageCard />,
    emptyElement: <NoTemplatePages />,
    perPage: isMobile ? 3 : 5,
    sort: { attr: "updatedAt", order: "DESC" },
  };

  return (
    <section className={styles.section}>
      <div className={styles["header"]}>
        <Header text="Artist Pages" padding={0} />
      </div>
      {isMobile ? (
        <InfiniteScrollIndexSection {...indexSectionProps} />
      ) : (
        <PaginatedIndexSection {...indexSectionProps} />
      )}
    </section>
  );
};

export default Index;
