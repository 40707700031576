import React from "react";
import ButtonBlock, { LinkButtonModel, CopyButtonModel } from "./ButtonBlock";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import CopyToClipBoard from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import { HoverActions } from "../../PlatoonContext";
import Hover from "../../Hover";
import { makeStyles, createStyles, Button } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    linkButton: {
      display: "block",
      padding: "15px 16px",
      border: "1px solid #fff",
      backgroundColor: "transparent",
      fontFamily: "Futura",
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "14px",
      textAlign: "center",
      color: "#ffffff",
      margin: "24px 10px 10px 0",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
    },
    copySnackBarAlert: {
      textAlign: "center",
    },
  })
);

interface Props {
  buttonBlock?: ButtonBlock; //used to be src, let's name it something really readable and specific to this module
  classes: any;
  hoverActions: HoverActions;
}

interface LinkButtonProps {
  button: LinkButtonModel;
}

function LinkButtonRenderer({ button }: LinkButtonProps) {
  const classes = useStyles();
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
      href={button.url.validUrl()}
      className={classes.linkButton}
    >
      {button.title}
    </a>
  );
}

type TransitionProps = Omit<SlideProps, "direction">;
function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

interface CopyButtonProps {
  button: CopyButtonModel;
}

const CopyButtonRenderer = ({ button }: CopyButtonProps) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  const handleClick =
    (Transition: React.ComponentType<TransitionProps>) => () => {
      setTransition(() => Transition);
      setOpen(true);
    };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CopyToClipBoard text={button.text}>
        <Button
          disableRipple={true}
          color="primary"
          onClick={handleClick(TransitionUp)}
          className={classes.linkButton}
          variant="contained"
        >
          Copy
        </Button>
      </CopyToClipBoard>
      <Snackbar
        className={classes.copySnackBarAlert}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        TransitionComponent={transition}
        message="Copied!"
      />
    </React.Fragment>
  );
};

function ButtonBlockRenderer({ buttonBlock, hoverActions }: Props) {
  const classes = useStyles();
  return (
    <Hover actions={hoverActions}>
      <div className={classes.buttonContainer}>
        {buttonBlock?.type === "button" &&
          buttonBlock?.linkButtons?.map((linkButton, index) => (
            // no unique key available for button, two buttons may have the same text
            <LinkButtonRenderer button={linkButton} key={index} />
          ))}
        {buttonBlock?.type == "copy-button" && buttonBlock?.copyButton && (
          <CopyButtonRenderer button={buttonBlock?.copyButton} />
        )}
      </div>
    </Hover>
  );
}

export default ButtonBlockRenderer;
