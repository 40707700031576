import { Logger } from "../../../../shared/SafeLogger";
import React, { useState, useEffect } from "react";
import {
  AssetPickerSource,
  AssetBlockProps,
  emptyAssetBlock,
} from "./AssetBlock";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Asset from "../../../models/Asset";
import { DropzoneArea } from "material-ui-dropzone";
import AssetManager from "../../../services/AssetManager";
import { makeStyles, Theme, Button, Chip, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  dropZone: {
    outline: "none",
    height: "120px",
    width: "575px",
    minHeight: 0,
    margin: "auto",
    backgroundColor: "#333333",
    "& .MuiDropzonePreviewList-imageContainer": {
      // position: 'absolute',
      top: "-120px",
      left: "125px",
    },
    "& .MuiDropzonePreviewList-image": {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    "& .MuiTypography-root .MuiTypography-subtitle1": {
      marginLeft: "35px",
    },
  },
  dropZoneText: {
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: "26px",
    color: "#999999",
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
    marginLeft: "30px",
    marginTop: "15px",
  },
  textField: {
    width: "92.5%",
    marginLeft: "25px",
    marginTop: "15px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  uploadSpinner: {
    marginRight: "45px",
    marginTop: "35px",
  },
  uploadButton: {
    marginTop: "30px",
    width: "150px",
    float: "right",
    right: "30px",
  },
  chipContainer: {
    textAlign: "center",
    padding: theme.spacing(3),
  },
}));

const acceptedFileTypes = {
  image: ["image/jpeg", "image/png", "image/bmp"],
  video: ["video/quicktime"],
};

function AssetUploadItem({ assetBlock, setAssetBlock }: AssetBlockProps) {
  const classes = useStyles();

  const [files, setFiles] = useState<any>([]);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  Logger.of("App").info(files, "files");

  useEffect(() => {
    setAssetBlock(
      emptyAssetBlock(assetBlock.assetType, AssetPickerSource.upload)
    );
  }, []);

  function upload() {
    const formData = new FormData();
    const file = files[0];
    if (file) {
      //validate type on file
      if (file.type.includes(assetBlock.assetType)) {
        formData.append("attachment", file);
        formData.append("description", description);
        formData.append("type", assetBlock.assetType);
      } else {
        alert(
          `File type ${file.type} not allowed for ${assetBlock.assetType} picker`
        );
      }
    } else {
      alert("No File");
    }
    setLoading(true);
    AssetManager.uploadAsset(formData)
      .then((asset: Asset) => {
        // props.onButtonChange(asset); //todo: auto upload
        setLoading(false);
        setFiles([]);
        let ab = { ...assetBlock };
        ab.asset = asset; //maybe set type to library?
        ab.errors = null;
        ab.warnings = null;
        ab.assetPickerSource = AssetPickerSource.library;
        setAssetBlock(ab);
      })
      .catch((error: Error) => {
        alert(error.message);
        setLoading(false);
      });
  }

  function onChangeDescription(event: any) {
    setDescription(event.target.value);
  }

  const handleDelete = (asset: any) => {
    let assetToBeRemoved = [...files];
    let index = files.indexOf(asset);
    assetToBeRemoved.splice(index, 1);
    setFiles(assetToBeRemoved);
  };

  const dropZoneText =
    assetBlock.assetType === "image"
      ? "Drag Image Here, or"
      : "Drag Video Here, or";
  const placeHolder =
    assetBlock.assetType === "video"
      ? "Video Description"
      : "Image Description";
  return (
    <div className={classes.root}>
      {files.length === 0 && (
        <DropzoneArea
          filesLimit={1}
          onChange={setFiles}
          acceptedFiles={acceptedFileTypes[assetBlock.assetType]}
          maxFileSize={10000000000000000000}
          dropzoneText={dropZoneText}
          dropzoneParagraphClass={classes.dropZoneText}
          dropzoneClass={classes.dropZone}
          showAlerts={false}
        />
      )}
      {files.map((asset: any) => {
        return (
          <div className={classes.chipContainer} key={asset.name}>
            <Chip
              label={asset.name}
              variant={"outlined"}
              onDelete={() => handleDelete(asset)}
            />
          </div>
        );
      })}
      <TextField
        placeholder={placeHolder}
        className={classes.textField}
        value={description}
        onChange={onChangeDescription}
      />
      <div className={classes.buttonContainer}>
        {loading && (
          <div className={classes.uploadSpinner}>
            <Loader type="Oval" color="grey" height={25} width={25} />
          </div>
        )}
        <Button
          className={classes.uploadButton}
          variant="contained"
          disabled={files?.length <= 0}
          color="primary"
          onClick={upload}
        >
          Upload
        </Button>
      </div>
    </div>
  );
}

function AssetUploaderTabContent(props: AssetBlockProps) {
  return <AssetUploadItem {...props} />;
}
export default AssetUploaderTabContent;
