import React, { useEffect, useState } from "react";
import Header from "../shared/Header";
import Release from "../promo-pack-builder/PromoPackCreator/Release";
import ActionBar from "../shared/ActionBar";
import CustomTile from "../promo-pack-builder/PromoPackCreator/CustomTile";
import { ReactComponent as Plus } from "../shared/icons/plus.svg";
import { makeStyles, Theme, SvgIcon } from "@material-ui/core";
import { usePlatoonArtistContext } from "../providers/PlatoonArtistProvider";
import { usePlatoonReleaseContext } from "../providers/PlatoonReleaseProvider";
import { useArtistLinkContext } from "../providers/ArtistLinkProvider";
import { constructArtistLink } from "./models/ArtistLink";
import { useNavigate, useParams } from "react-router-dom";
import { useLoadingContext } from "../providers/LoadingProvider";

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    padding: theme.spacing(3),
    maxWidth: "1000px",
    overflow: "hidden",
    margin: "auto",
  },
  icon: {
    fontSize: "60px",
  },
}));

const ArtistLinkRelease = () => {
  const { p4aArtist } = usePlatoonArtistContext();
  const { artistId } = useParams();
  const { artistLink, dispatch } = useArtistLinkContext();
  const { setLoading } = useLoadingContext();
  const { fetchReleases } = usePlatoonReleaseContext();
  const [buildFromScratch, setBuildFromScratch] = useState<boolean>(false);

  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    if (artistId) {
      fetchReleases(artistId).then(() => setLoading(false));
      const artistLink = constructArtistLink({ p4aArtist });

      dispatch({
        type: "SET_ARTISTLINK",
        payload: artistLink,
      });
    }
  }, [artistId]);

  const isDirty = artistLink && !artistLink.release && !buildFromScratch;

  const handleCustomReleaseClick = () => {
    if (artistLink.release) {
      setBuildFromScratch(true);
    } else {
      setBuildFromScratch(!buildFromScratch);
    }
    dispatch({
      type: "SET_RELEASE",
      payload: {
        p4aReleaseId: "",
        release: null,
      },
    });
  };

  const customRelease = (
    <div onClick={handleCustomReleaseClick}>
      <CustomTile
        text="CREATE FROM SCRATCH"
        icon={
          <SvgIcon className={classes.icon}>
            <Plus />
          </SvgIcon>
        }
        selected={artistLink && artistLink.release ? false : buildFromScratch}
      />
    </div>
  );

  return (
    <>
      <Header text="STEALTH PLAYER" />

      <div className={classes.contentContainer}>
        <Release
          customRelease={customRelease}
          selectedP4aReleaseId={artistLink?.p4aReleaseId}
          dispatch={dispatch}
        />
      </div>
      <ActionBar
        position="bottom"
        buttons={[
          {
            text: "Cancel",
            action: () => navigate("../../"),
            group: "right",
            buttonProps: { variant: "outlined" },
            textProps: {
              color: "textPrimary",
              style: {
                fontWeight: "bold",
              },
            },
          },
          {
            text: "Next",
            group: "right",
            action: () => {
              setLoading(true), navigate("../new/edit");
            },
            buttonProps: {
              variant: "contained",
              color: "secondary",
              disabled: isDirty,
            },
          },
        ]}
      />
    </>
  );
};

export default ArtistLinkRelease;
