import React, { useContext } from 'react';
import clsx from 'clsx';
import { PlatoonContext } from "../PlatoonContext"


interface Props {
  theme: any,
  getEditorState: any,
  setEditorState: any,
  addVideo: any,
  modifier: any,
  blockType: string
}

const VideoButton = (props: Props) => {
  const { setBlockEditSession } = useContext(PlatoonContext);

  const onClick = () => {
    let session = { blockType: "video" };
    setBlockEditSession(session);

  };

  const preventBubblingUp = (event: any) => { event.preventDefault(); }

  const blockTypeIsActive = () => {
    const editorState = props.getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === props.blockType;
  };


  const theme = props.theme;
  const className = blockTypeIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
      <button className={className} onClick={onClick} type="button">
        <svg width="24px" height="24px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
          <g id="WYSIWYG-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu-Buttons" transform="translate(-40.000000, -171.000000)" fill="#888888" fillRule="nonzero">
              <path d="M57.0070547,186.983887 C58.9162344,186.983887 59.9704336,185.929688 59.9704336,184.037109 L59.9704336,174.001465 C59.9704336,172.100586 58.9162344,171.054688 57.0070547,171.054688 L43.0949453,171.054688 C41.1857656,171.054688 40.1315664,172.100586 40.1315664,174.001465 L40.1315664,184.037109 C40.1315664,185.937988 41.1857656,186.983887 43.0949453,186.983887 L57.0070547,186.983887 Z M56.7497305,184.759277 L43.3522695,184.759277 C42.7048086,184.759277 42.3561758,184.443848 42.3561758,183.754883 L42.3561758,174.283691 C42.3561758,173.586426 42.7048086,173.279297 43.3522695,173.279297 L56.7497305,173.279297 C57.3971914,173.279297 57.7458242,173.586426 57.7458242,174.283691 L57.7458242,183.754883 C57.7458242,184.443848 57.3971914,184.759277 56.7497305,184.759277 Z M48.3410391,182.111328 L52.8815664,179.47168 C53.2135977,179.272461 53.2135977,178.774414 52.8815664,178.583496 L48.3410391,175.927246 C47.9841055,175.719727 47.5275625,175.910645 47.5275625,176.300781 L47.5275625,181.746094 C47.5275625,182.12793 47.9675039,182.327148 48.3410391,182.111328 Z" id="vid"></path>
            </g>
          </g>
        </svg>
      </button>
    </div>
  );
}


export default VideoButton;
