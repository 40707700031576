import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import {
  idFallthrough,
  templatePageExternalLinkURL,
  templatePageLiveStatus,
} from "../../../shared/helpers/helpers";

import MenuOptions from "../../../shared/indexCards/MenuOptions";
import { mutationFn } from "../../../shared/queries/useGeneric";
import { useSnackContext } from "../../../shared/SnackProvider";
import {
  TemplatePageAdaptor,
  TemplatePageResponse,
  TemplatePage,
} from "../../types/TemplatePages";
import styles from "./TemplatePageCard.module.scss";
import { getLatestDraft } from "../../../shared/helpers/helpers";
import isEqual from "react-fast-compare";
import DeleteConfirmation from "../../../shared/DeleteConfirmation/DeleteConfirmation";
import CounterAnalytics from "../../../shared/components/CounterAnalytics/CounterAnalytics";
import { TemplatePageAction } from "../../../providers/AnalyticsProvider";
import { blockIsHeader } from "../../helpers/templateConfigHelpers";

type Props = {
  data?: TemplatePageResponse;
};

type DeleteMutation = {
  artistId: string;
  endpoint: string;
  id: string;
};

// do a diff to see if there have been edits
const unpublishedChanges = (templatePage: TemplatePage) => {
  // i discovered we have some issues with password and slugs on publish.
  // if we fix this, we can simplify the else if below to the commented out version

  // templatePage.name === "test" &&
  //   console.log(
  //     isEqual(
  //       {
  //         ...templatePage,
  //         drafts: {},
  //         password: templatePage.password || null,
  //         slug: null,
  //       },
  //       {
  //         ...templatePage.drafts[0],
  //         drafts: {},
  //         password: templatePage.drafts[0].password || null,
  //         slug: null,
  //       }
  //     )
  //   );
  // if there is no template config, hasnt been published, or everything matches, return false
  if (!templatePage.templateConfig) {
    return false;
  } else if (!templatePage.published) {
    return false;
  } else if (
    templatePage.drafts &&
    templatePage.drafts.length &&
    isEqual(
      templatePage.templateConfig,
      templatePage.drafts[0]?.templateConfig
    ) &&
    isEqual(templatePage.startDate, templatePage.drafts[0].startDate) &&
    isEqual(templatePage.endDate, templatePage.drafts[0].endDate) &&
    templatePage.name === templatePage.drafts[0].name &&
    // password needs to be == because it could be null or undefined
    templatePage.password == templatePage.drafts[0].password
    // isEqual(
    //   { ...templatePage, drafts: {} },
    //   { ...templatePage.drafts, drafts: {} }
    // )
  ) {
    return false;
  } else {
    return true;
  }
};

const displayName = (templatePage: TemplatePage) => {
  return (
    (templatePage.drafts &&
      templatePage.drafts.length > 0 &&
      templatePage.drafts[0].name) ||
    templatePage.name
  );
};

const getBackgroundValue = (templatePage: TemplatePage) => {
  // grabs the background value of the header in template config
  const templateConfigHeader =
    templatePage?.templateConfig?.find(blockIsHeader);
  // grabs the background value of the header in draft template config
  const draftHeader = templatePage.drafts
    ? getLatestDraft(templatePage.drafts)?.templateConfig?.find(blockIsHeader)
    : undefined;

  // if there isnt a template config value, use the draft value, if there's no draft value, use our default background color

  const publishedOrDraft =
    templateConfigHeader?.params?.background?.value ||
    draftHeader?.params?.background?.value;

  const backgroundValue = getValueAndType(publishedOrDraft);

  return backgroundValue;
};

const getValueAndType = (
  value: any
): { type: string; value: string } | undefined => {
  if (typeof value === "undefined") {
    return undefined;
  } else {
    switch (value?.key) {
      case "color":
        return { type: value?.key, value: value?.value };
      case "video":
        return { type: value?.key, value: "#3b3b3b" };

      case "file":
        // we now default template pages to have an image for background, but it doesnt mean theyve uploaded one.
        const file = value?.value && value?.value[0];
        const fileId = idFallthrough(file);

        if (fileId) {
          const fileValue = `url('/api/v1/signed-assets/${fileId}/small')`;

          return {
            type: value.value && value?.value[0]?.type,
            value: fileValue,
          };
        } else {
          // if there's no file uploaded, return our color option
          return {
            type: "color",
            value: "#3b3b3b",
          };
        }

      default:
        return undefined;
    }
  }
};

const TemplatePageCard = ({ data }: Props) => {
  // query analytic data and use in the details section
  const navigate = useNavigate();
  const { artistId } = useParams();
  const queryClient = useQueryClient();
  const { setSnack } = useSnackContext();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const deleteMutation = useMutation({
    mutationFn: ({ artistId, endpoint, id }: DeleteMutation) =>
      mutationFn("DELETE", artistId, endpoint, id),
    onSuccess: () => {
      queryClient.invalidateQueries("template-pages");
      setSnack({ message: `${displayName(templatePage)} has been deleted.` });
    },
  });

  if (!data) return <div>No data</div>;

  // Data is still coming into this component as a raw GET response, so dates aren't parsed
  // here we adapt it
  const templatePage = TemplatePageAdaptor.toFrontend(data);

  const { status, details } = templatePageLiveStatus(
    templatePage.startDate,
    templatePage.endDate,
    templatePage.published
  );

  const background = getBackgroundValue(templatePage);

  const handleDelete = () => {
    if (artistId) {
      deleteMutation.mutate({
        artistId,
        endpoint: "template-pages",
        id: templatePage.id,
      });
    }
  };

  return (
    /*
      This div should be a button, because we need to click it to navigate,
      however we can't nest a button in a button (MenuOptions below).
      TODO: Talk to ryan to find the best way through this
    */
    <div
      className={styles["card"]}
      // we need to dynamically show the artist-epk piece ${/*data.templateType*/ }
      onClick={() =>
        navigate(`${templatePage.templateSlug}/${templatePage.id}/edit`)
      }
    >
      <div
        className={styles["thumb"]}
        // if you set background inline, you need to set background-size and background-position inline as well
        // can't mix and match those props
        style={{
          background: background?.value || "#3b3b3b", // in case we dont have a value
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className={styles["text-container"]}>
          {(background?.type == "video" ||
            background?.type === "color" ||
            !background?.value) &&
            templatePage.artist.name}
        </div>
      </div>
      <div className={styles["flex-items"]}>
        <div className={styles["details"]}>
          <h4 className={styles["details--name"]}>
            {displayName(templatePage)}
          </h4>
          {unpublishedChanges(templatePage) && (
            <p className="italic">You have unpublished changes</p>
          )}
          {details && (
            <p className={styles["details--scheduled"]}>
              {details.verb}{" "}
              <span className={styles["bold"]}>
                {details.date &&
                  details.date
                    .toLocaleDateString("en-uk", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })
                    .split(" ")
                    .join("-")}
              </span>{" "}
              at{" "}
              {details.date &&
                details.date.toLocaleTimeString("en-us", {
                  timeZoneName: "short",
                  hour: "numeric",
                  minute: "2-digit",
                })}
            </p>
          )}
          <CounterAnalytics
            resourceId={templatePage.id}
            resourceType="TemplatePage"
            actionType={TemplatePageAction.mount}
            actionDisplayName="OPENS"
          />
        </div>
        <CopyToClipboard
          text={templatePageExternalLinkURL(
            templatePage?.templateSlug,
            templatePage?.slug
          )}
        >
          <button
            className="btn btn--white"
            onClick={(e: any) => {
              e.stopPropagation();
              setSnack({ message: "Link Copied!" });
            }}
          >
            Copy Link
          </button>
        </CopyToClipboard>

        <div className={styles["flex"]}>
          <div
            className={`${styles["status"]} ${
              status === "Published" && styles["live"]
            }`}
          >
            {status}
          </div>

          {/* copylink button */}
          <div className={styles["actions"]}>
            <MenuOptions
              options={[
                {
                  text: "Delete",
                  textColor: "#d50442",
                  action: () => setShowDeleteConfirm(true),
                },
              ]}
            />
          </div>
        </div>
      </div>
      {showDeleteConfirm && (
        <DeleteConfirmation
          setOpen={setShowDeleteConfirm}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default TemplatePageCard;
