import React, { useEffect } from "react";
import { BlockParam } from "../../../../../asset-generator-lib/composer/models/Block";
import { TemplatePageDraft } from "../../../types/TemplatePages";
import SelectInput from "../SelectInput/SelectInput";

type MappedSelectProps = {
  param: BlockParam;
  update: (input: BlockParam | string) => void;
  subField?: boolean;
  sectionKey: string;
  templatePageDraft: TemplatePageDraft;
  path: string[];
};

const MappedSelectInput = ({
  param,
  update,
  templatePageDraft,
  subField,
  sectionKey,
  path,
}: MappedSelectProps) => {
  const mappedData =
    param.mappedPath &&
    templatePageDraft.mappedData &&
    templatePageDraft.mappedData[param.mappedPath];

  const loadingOption: BlockParam = {
    key: "loading",
    name: `Loading ${param.name}...`,
    value: null,
    type: "STATIC",
  };

  const noMappedDataOption: BlockParam = {
    key: "noOptions",
    name: `No ${param.name} Available`,
    value: null,
    type: "STATIC",
  };

  useEffect(() => {
    update({
      ...param,
      options: [loadingOption],
    });
  }, []);

  // UE builds the param options using mappedData & mappedOption
  useEffect(() => {
    // we only need to be building param.options once
    // which is why a MAPPEDSELECT should have no options key initially in the template config
    // if we need an option in that isn't provided through the mappedData then we can use
    // defaultOption in the template config.
    if (mappedData) {
      const mappedOptions = mappedData.map((data: any) => {
        let option: any = {};
        let optionName = "";
        Object.entries(data).forEach(([key, val]) => {
          if (
            val &&
            param.mappedOption?.name.includes(key) &&
            param.mappedOption?.name.length > 1
          ) {
            optionName = optionName + `${val} - `;
          }
          option.key = data.id;
          option.name = optionName.slice(0, -3);
          option.value = data[`${param.mappedOption?.value}`];
          option.type = param.mappedOption?.type;
        });
        return option;
      });

      if (!!mappedOptions.length) {
        const options = param.mappedOption?.defaultOption
          ? [param.mappedOption?.defaultOption, ...mappedOptions]
          : mappedOptions;

        update({ ...param, options: options });
      } else {
        update({ ...param, options: [noMappedDataOption] });
      }
    }
  }, [mappedData]);

  return (
    <>
      {!!param.options?.length && (
        <SelectInput
          param={param}
          update={update}
          subField={subField}
          sectionKey={sectionKey}
          templatePageDraft={templatePageDraft}
          path={path}
        />
      )}
    </>
  );
};

export default MappedSelectInput;
