import React from 'react';
import {
  Divider,
  Box,
  Grid
} from '@material-ui/core'

export default function ArtistLinkDivider() {

  return (
    <Grid item xs={12}>
      <Box p={3} pb={4} pt={4}>
        <Divider />
      </Box>
    </Grid>

  );
}
