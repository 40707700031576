import React, { useState, useEffect } from "react";
import { Track } from "../../../link-builder/models/ArtistLink";
import { ReactComponent as PlayArrow } from "../../../shared/icons/playArrow.svg";
import { ReactComponent as Pause } from "../../../shared/icons/pause.svg";
import { getAudioDuration, convertTime } from "../../../shared/helpers/helpers";
import styles from "./TrackList.module.scss";
import Loading from "../../../shared/Loading";

type Props = {
  tracks: Track[];
  currentFileIndex: number;
  setCurrentFileIndex: React.Dispatch<React.SetStateAction<number>>;
  playStatus: boolean;
  setPlayStatus: React.Dispatch<React.SetStateAction<boolean>>;
};

type TrackListItemProps = {
  track: Track;
  index: number;
  currentFileIndex: number;
  setCurrentFileIndex: React.Dispatch<React.SetStateAction<number>>;
  playStatus: boolean;
  setPlayStatus: React.Dispatch<React.SetStateAction<boolean>>;
};

const TrackListItem = ({
  track,
  index,
  currentFileIndex,
  setCurrentFileIndex,
  playStatus,
  setPlayStatus,
}: TrackListItemProps) => {
  const [trackDurationDescription, setTrackDurationDescription] = useState<
    string | undefined
  >(undefined);
  const selectedTrack = currentFileIndex === index;
  const currentTrackSrc =
    track.derivatives?.mp3Kbps192 ||
    track.derivatives?.original ||
    track.attachment;

  useEffect(() => {
    if (!trackDurationDescription) {
      if (track.duration == null) {
        // Fall back to calculating
        getAudioDuration(currentTrackSrc).then((td: number) => {
          setTrackDurationDescription(convertTime(td));
        });
      } else {
        // Using new track.duration property which is less magical than alternatives:
        // attachmentData?.metadata?.duration
        // metadata?.duration
        setTrackDurationDescription(convertTime(track.duration));
      }
    }
  }, [track, trackDurationDescription]);

  return (
    <div
      key={track.id}
      onClick={() => {
        setCurrentFileIndex(index), setPlayStatus(!playStatus);
      }}
      className={`${styles["track-list-item"]} ${
        selectedTrack && styles["highlighted"]
      }`}
    >
      <div className={styles["track-list-data"]}>
        <div className={styles["track-list-icon"]}>
          {index === currentFileIndex && (
            <span style={{ display: "flex" }}>
              {playStatus ? (
                <Pause className={styles["icon"]} />
              ) : (
                <PlayArrow className={styles["icon"]} />
              )}
            </span>
          )}
        </div>
        <span>
          {index + 1}. {track.title?.removeExt()}
        </span>
      </div>
      <span>
        {trackDurationDescription ? (
          trackDurationDescription
        ) : (
          <Loading size={"15px"} />
        )}
      </span>
    </div>
  );
};

const TrackList = ({
  tracks,
  currentFileIndex,
  setCurrentFileIndex,
  playStatus,
  setPlayStatus,
}: Props) => {
  return (
    <>
      {tracks?.map((track: Track, index: number) => {
        return (
          <TrackListItem
            key={track.id}
            track={track}
            index={index}
            currentFileIndex={currentFileIndex}
            setCurrentFileIndex={setCurrentFileIndex}
            playStatus={playStatus}
            setPlayStatus={setPlayStatus}
          />
        );
      })}
    </>
  );
};

export default TrackList;
