import React, { useState, useEffect } from "react";
import { resolveMappedPath } from "../../../../../asset-generator-lib/helpers";
import MultiInput from "../MultiInput/MultiInput";
import { TemplatePageBlockParam } from "../../../../../asset-generator-lib";
import { TemplatePageDraft } from "../../../types/TemplatePages";
import {
  BlockParam,
  BlockParamMulti,
} from "../../../../../asset-generator-lib/composer/models/Block";
import { usePrevious } from "react-use";

type Props = {
  param: BlockParamMulti;
  sectionParam: any;
  update: (input: TemplatePageBlockParam | string) => void;
  subField?: boolean;
  sectionKey: string;
  templatePageDraft: TemplatePageDraft;
  path: string[];
};

const MappedMultiInput = ({
  param,
  sectionParam,
  update,
  subField,
  sectionKey,
  templatePageDraft,
  path,
}: Props) => {
  const [resolvedParamValue, setResolvedParamValue] = useState<any>(null);
  const prevResolvedParamValue = usePrevious(resolvedParamValue);

  const resolvedMappedSectionValue =
    param.sectionPath &&
    sectionParam?.params &&
    resolveMappedPath(param.sectionPath, sectionParam?.params);

  useEffect(() => {
    //UE is in charge of shaping the resolvedMappedSectionValue
    if (resolvedMappedSectionValue) {
      let arrayOfResolvedValues: any = [];
      resolvedMappedSectionValue?.forEach((val: any) => {
        if (param.sectionData) {
          const sectionData = resolveMappedPath(param.sectionData, val);

          sectionData.forEach((innerSection: any) => {
            const obj: any = {};
            param.mappedOptions?.forEach((option: any) => {
              const optionVal = option.sectionPath
                ? resolveMappedPath(option.sectionPath, val)
                : resolveMappedPath(option.mappedPath, innerSection);

              obj[option.key] = {
                key: option.key,
                name: option.name,
                type: option.type,
                validations: option.validations,
                value: optionVal || "",
              };
            });
            arrayOfResolvedValues.push({
              //key is important for knowing which data is coming from mapped source
              key: `mappedMultiData - ${innerSection.id}`,
              name: "metadata",
              type: "GROUP",
              options: param.mappedOptions,
              value: JSON.stringify(obj),
            });
          });
        } else {
          //we don't have a case with 1 level of data yet..
        }
      });
      setResolvedParamValue(arrayOfResolvedValues);
    } else {
      setResolvedParamValue([]);
    }
  }, [resolvedMappedSectionValue]);

  useEffect(() => {
    // UE is in charge of adding and removing values based on resolvedParamValue changes
    // and what is already save into the param.value
    if (!param.value?.length && resolvedParamValue) {
      update({
        ...param,
        value: [...resolvedParamValue],
      });
    }
    if (
      prevResolvedParamValue &&
      resolvedParamValue?.length > prevResolvedParamValue.length
    ) {
      const resolvedToAdd = resolvedParamValue.filter(
        (innerResolve: BlockParam) => {
          if (
            prevResolvedParamValue.find(
              (innerPrev: any) => innerPrev.key === innerResolve.key
            )
          ) {
            return null;
          } else return innerResolve;
        }
      );
      update({
        ...param,
        value: [...param.value, ...resolvedToAdd],
      });
    }
    if (
      prevResolvedParamValue &&
      resolvedParamValue?.length < prevResolvedParamValue.length
    ) {
      const resolvedToRemove = param.value.filter((innerVal: BlockParam) => {
        if (
          resolvedParamValue.find(
            (innerResolve: any) => innerResolve.key === innerVal.key
          ) ||
          !innerVal.key.includes("mappedMultiData")
        ) {
          return innerVal;
        } else return null;
      });
      update({
        ...param,
        value: [...resolvedToRemove],
      });
    }
  }, [resolvedParamValue]);

  return (
    <MultiInput
      path={path}
      param={param}
      update={update}
      templatePageDraft={templatePageDraft}
      sectionKey={sectionKey}
      subField={subField}
    />
  );
};

export default MappedMultiInput;
