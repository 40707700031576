import ResourceSchema, {
  UserRole,
  emptyResourceSchema,
} from "../shared/ResourceSchema";
import { Node, RawNode, NodeFactory } from "../platoon-cms-lib";

function adaptToBackend(frontendObject: CMSNode) {
  return frontendObject.toRawNode();
}

function adaptToFrontend(backendObject: RawNode) {
  return NodeFactory.createNodeFromRaw(CMSNode, backendObject);
}

const NodeSchema: ResourceSchema = {
  ...emptyResourceSchema(),
  ...{
    title: "Page",
    pluralTitle: "Pages",
    rootPath: "pages",
    apiRootPath: "nodes",
    attrs: ["title", "subtitle", "description", "type", "icon", "published"],
    adaptor: {
      toFrontend: adaptToFrontend,
      toBackend: adaptToBackend,
    },
    paths: {
      edit: (item: any) => {
        return `/pages/${item.id}`;
      },
    },
    locale: "en-US",
    dataIsNested: false,
    role: UserRole.Admin, //default node schema manipulation is done by an admin
    // isContentRoot: false
  },
};

export default NodeSchema;

class CMSNode extends Node {
  get parent(): CMSNode | undefined {
    return this._parent && (this._parent as CMSNode);
  }

  get hasParent(): boolean {
    return (
      this.parentId !== undefined &&
      this.parentId !== null &&
      this.parentId.length > 0
    );
  }

  get children(): CMSNode[] {
    return this._children as CMSNode[];
  }

  get previewPath(): string {
    return `/apps/publications/${this.slug}`;
  }

  copyNode() {
    return NodeFactory.createNodeFromRaw(CMSNode, this.toRawNode());
  }

  toFormData(): FormData {
    const formData = new FormData();
    const rawNode = this.toRawNode();

    Object.entries(rawNode).forEach((entry) => {
      let key = entry[0];
      let value = entry[1];
      formData.append(key, value);
    });

    formData.delete("_id");
    formData.delete("id");
    formData.delete("children");

    return formData;
  }

  static emptyNode() {
    return new CMSNode({
      identifier: "",
      title: "",
      slug: "",
      subtitle: "",
      description: "",
      type: "article",
      cardType: "text",
      rawContent: "",
      parentId: "",
      icon: "",
      order: 0,
      published: false,
      isContentRoot: false,
      level: 0,
    });
  }
}

export { CMSNode };
