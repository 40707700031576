import React, {
  useReducer,
  useContext,
  createContext,
  useState,
  useEffect,
} from "react";
import ArtistLink, {
  // Artist,
  ArtistLinkAPI,
  deepCopyArtistLink,
  Track,
} from "../link-builder/models/ArtistLink";
import { Logger } from "../shared/SafeLogger";
import ArtistLinkSchema from "../artist-links/ArtistLinkSchema";
import { UserRole } from "../shared/ResourceSchema";
import base64 from "base-64";
import utf8 from "utf8";
import API from "../shared/API";
import { useLoadingContext } from "./LoadingProvider";
import ArtistSchema from "../artists/ArtistSchema";
import { populateFromRelease } from "../link-builder/helpers/BuildFromPrior";
import { useAnalyticsContext, ArtistLinkAction } from "./AnalyticsProvider";
import { useErrorHandlingContext } from "./ErrorHandlingProvider";
import { FileUploadType } from "../template-page-builder/components/inputs/FileInput/FileInput";

type Props = {
  children: any;
};

type ArtistLinkContext = {
  artistLink: ArtistLink;
  ogArtistLink: ArtistLink | undefined;
  setOGArtistLink: React.Dispatch<React.SetStateAction<ArtistLink | undefined>>;
  artistLinks: ArtistLink[] | undefined;
  fetchArtistLinks: any;
  createArtistLink: any;
  saveArtistLink: any;
  loadArtistLink: any;
  loadArtistLinkWithPassword: any;
  pageCount: number;
  setPageCount: React.Dispatch<React.SetStateAction<number>>;
  dispatch: any;
};

export const ArtistLinkContext = createContext<ArtistLinkContext | null>(null);

export const useArtistLinkContext = () => useContext(ArtistLinkContext)!;

export const ArtistLinkProvider: React.FC<Props> = ({ children }: Props) => {
  const { errorDispatch } = useErrorHandlingContext();
  const { trackArtistLink } = useAnalyticsContext();
  const [artistLinks, setArtistLinks] = useState<ArtistLink[] | undefined>();
  const [ogArtistLink, setOGArtistLink] = useState<ArtistLink>();

  const ArtistLinkReducer = (state: ArtistLink | null, action: any) => {
    switch (action.type) {
      case "SET_RELEASE":
        return {
          ...state,
          p4aReleaseId: action.payload.p4aReleaseId,
          release: action.payload.release,
        };
      case "SET_ARTISTLINK":
        return {
          ...action.payload,
        };

      default:
        console.error("UNKNOWN ACTION TYPE:", action.type);
        return state;
    }
  };

  const [artistLink, dispatch] = useReducer(ArtistLinkReducer, null);

  useEffect(() => {
    // lets convert the shape of the track to match the old format
    // and lets create the tracklist entry

    artistLink?.tracks.forEach((track: any, index: number) => {
      if (track.status === "COMPLETED") {
        let updatedTracks = [...artistLink.tracks];
        updatedTracks[index] = revertToOldTrackStructure(track);

        let updatedTrackLinks = [...artistLink.trackLinks];
        updatedTrackLinks.push({ position: index, trackId: track.asset?.id });

        dispatch({
          type: "SET_ARTISTLINK",
          payload: {
            ...artistLink,
            tracks: updatedTracks,
            trackLinks: updatedTrackLinks,
          },
        });
      }
    });
  }, [artistLink?.tracks]);

  const revertToOldTrackStructure: (track: FileUploadType) => Track = (track) => {
    const oldTrackObj: Track = {
      attachment: track.asset?.attachment,
      title: track.asset?.description || track.id, // shouldnt ever go without a description
      id: track.asset?.id,
    };
    return oldTrackObj;
  };

  const { setLoading } = useLoadingContext();

  const [pageCount, setPageCount] = useState(0);

  const deleteOldFields = (artistLink: any) => {
    delete artistLink.artistSocials["snapchat"];
  };

  const handleLoad = (artistLink: ArtistLink) => {
    errorDispatch({ type: "CLEAR_ERRORS" });
    setOGArtistLink(deepCopyArtistLink(artistLink));
    deleteOldFields(artistLink);
    dispatch({
      type: "SET_ARTISTLINK",
      payload: {
        ...artistLink,
      },
    });
    setLoading(false);
  };

  const fetchArtistLinks = async (artistId: string, page: number) => {
    try {
      const response = await ArtistLinkAPI.query(artistId, page);
      setArtistLinks(response.results);
      setPageCount(response.pagination.totalPages);
    } catch (error) {
      errorDispatch({
        type: "SET_ERROR",
        payload: { error: error, serveErrorPage: true },
      });
    }
  };

  const createArtistLink = (artistSlug: string, p4aRelease?: any) => {
    API.read(artistSlug, ArtistSchema).then((artist: any) => {
      artistLink.artist = artist;
      saveArtistLink(artistLink).then((al) => {
        if (al.p4aReleaseId) {
          populateFromRelease(al, p4aRelease).then((res: any) => {
            dispatch({
              type: "SET_ARTISTLINK",
              payload: { ...res },
            });
            setLoading(false);
          });
        } else {
          dispatch({
            type: "SET_ARTISTLINK",
            payload: { ...al },
          });
          setLoading(false);
        }
      });
    });
  };
  // wanted to be defined here? uhh weird
  const alTrackingPayload: any = (payload: ArtistLink) => {
    return {
      hasPassword: payload.requiresPassword,
      expDate: payload.endDateTime,
      p4a_release_id: payload.p4aReleaseId,
    };
  };

  const saveArtistLink = async (al: ArtistLink) => {
    let editedLink = { ...al };
    if (!al.id) {
      const result = await ArtistLinkAPI.create(al.artist.id, editedLink);
      trackArtistLink(
        result,
        ArtistLinkAction.artistLinkCreated,
        alTrackingPayload(result)
      );
      return result;
    } else {
      const result = await ArtistLinkAPI.update(
        al.artist.id,
        editedLink,
        al.id
      );
      trackArtistLink(
        result,
        ArtistLinkAction.artistLinkUpdated,
        alTrackingPayload(result)
      );
      return result;
    }
  };

  const loadArtistLink = (artistSlug: string, artistLinkId: string) => {
    setLoading(true);
    Logger.of("App.LinkBuilder").info(
      "Read artist slug, artist link id",
      artistSlug,
      artistLinkId
    );
    ArtistLinkAPI.read(artistSlug, artistLinkId)
      .then(handleLoad)
      .catch((error) =>
        errorDispatch({
          type: "SET_ERROR",
          payload: { error: error, serveErrorPage: true },
        })
      );
  };

  const loadArtistLinkWithPassword = (
    artistSlug: string,
    artistLinkSlug: string,
    artistLinkShortId: string,
    password: string
  ) => {
    let schema = { ...ArtistLinkSchema };
    schema.apiRootPath = `viewer/links/artists/${artistSlug}`;
    schema.role = UserRole.Public;

    var path = `${schema.apiRootPath}/${artistLinkSlug}/${artistLinkShortId}`;

    if (password) {
      // need to encode our string in utf8 in case it has extended latin letters like ' à '
      const bytes = utf8.encode(password);
      const encodedPassword = base64.encode(bytes);
      path += `?password=${encodedPassword}`;
    }
    API.fetchAPI(path, schema)
      .then(handleLoad)
      .catch((error) => {
        // If the stealth player has a password it will immediately 403,
        //which is why we are doing this check here. We serve the password page on 403,
        // but it might otherwise error, hence the check
        const serveErrorPage = error?.response?.status !== 403 ? true : false;
        errorDispatch({
          type: "SET_ERROR",
          payload: { error: error, serveErrorPage: serveErrorPage },
        });
      });
  };

  return (
    <ArtistLinkContext.Provider
      value={{
        artistLink,
        artistLinks,
        dispatch,
        createArtistLink,
        saveArtistLink,
        loadArtistLink,
        loadArtistLinkWithPassword,
        ogArtistLink,
        setOGArtistLink,
        pageCount,
        setPageCount,
        fetchArtistLinks,
      }}
    >
      {children}
    </ArtistLinkContext.Provider>
  );
};
