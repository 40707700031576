import React from "react";

import ItemIndex, { ItemRenderProps } from "../shared/ItemIndex";
import ArtistLinkSchema from "./ArtistLinkSchema";


import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'


function ArtistContent(props: ItemRenderProps) {
  let artist = props.item[props.attr];

  return (
    <Link to={`artists/${artist.slug}`}>
      <Typography style={{ color: "#FFF" }}>{artist.name}</Typography>
    </Link >
  )
}

const renderers = { artist: ArtistContent }

const ArtistLinkIndex = () => {
  return (
    <ItemIndex schema={ArtistLinkSchema} renderers={renderers} />
  );
};

export default ArtistLinkIndex;

