import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ArtistNameSection from "./ArtistNameSection";
import ArtistAlbumArtworkSection from "./ArtistAlbumArtworkSection";
import ArtistHeadlineDescriptionSection from "./ArtistHeadlineDescriptionSection";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

type Props = {
  preview: boolean;
  displayMode: string;
  setOpenDescription: React.Dispatch<React.SetStateAction<boolean>>;
  fontColor: string;
  playerIsMobile: boolean;
};

type StyleProps = {
  playerContentHeight: string;
  hasContent: boolean;
};

const useStyles = makeStyles(() => ({
  playerContentArea: {
    height: ({ playerContentHeight }: StyleProps) => playerContentHeight,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  nameSection: {
    display: "flex",
    height: "25%",
    width: "100%",
  },
  artworkSection: {
    height: "50%",
    maxHeight: "525px",
    maxWidth: "525px",
    width: "100%",
  },
  headLineDescriptionSection: {
    height: "25%",
    width: "100%",
  },
}));

const ArtistContentArea = (props: Props) => {
  const { artistLink } = useArtistLinkContext();

  const playerContentHeight = props.playerIsMobile
    ? "calc(100% - 160px)"
    : "calc(100% - 200px)";

  const hasContent =
    (artistLink.headline || artistLink.description) !== "" ||
    artistLink.requiresAudioDownloadPassword;

  const classes = useStyles({ playerContentHeight, hasContent });

  return (
    <Grid container className={classes.playerContentArea}>
      <div className={classes.nameSection}>
        <ArtistNameSection
          preview={props.preview}
          fontColor={props.fontColor}
          playerIsMobile={props.playerIsMobile}
        />
      </div>
      <div className={classes.artworkSection}>
        <ArtistAlbumArtworkSection preview={props.preview} />
      </div>
      {(hasContent || artistLink.requiresAudioDownloadPassword) && (
        <div className={classes.headLineDescriptionSection}>
          <ArtistHeadlineDescriptionSection
            setOpenDescription={props.setOpenDescription}
            displayMode={props.displayMode}
            preview={props.preview}
            fontColor={props.fontColor}
          />
        </div>
      )}
    </Grid>
  );
};

export default ArtistContentArea;
