import React, { useEffect, useState } from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import styles from "./RenderedBiography.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import { ReactComponent as CheckmarkCircleFill } from "../../../../shared/icons/checkmark-circle-fill.svg";
import ReadMoreParagraph from "../../ReadMoreParagraph/ReadMoreParagraph";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

const RenderedBiography = ({ convertedBlock, highlight }: Props) => {
  const [copyButtonText, setCopyButtonText] = useState("copy bio");

  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;

  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;

  const backgroundColor: string = convertedBlock?.params?.background?.value;

  useEffect(() => {
    if (copyButtonText === "copied") {
      setTimeout(() => setCopyButtonText("copy bio"), 5000);
    }
  }, [copyButtonText]);

  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      <main className={styles.section}>
        <ReadMoreParagraph
          maxHeight={120}
          text={convertedBlock.params?.biography?.value}
          backgroundColor={backgroundColor}
        />

        <CopyToClipboard text={convertedBlock.params?.biography?.value}>
          <button
            className={`btn btn--outline ${styles["copy-btn"]} `}
            onClick={() => setCopyButtonText("copied")}
          >
            {copyButtonText === "copied" && (
              <CheckmarkCircleFill className={styles["icon"]} />
            )}
            {copyButtonText}
          </button>
        </CopyToClipboard>
      </main>
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedBiography;
