// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import ReactOnRails from "react-on-rails";

import App from "../App"; //feel like these lead to the same place
import PublicationsApp from "../bundles/publications/PublicationsApp";
import LinkBuilderApp from "../bundles/link-builder/LinkBuilderApp";
import LinkViewerApp from "../bundles/linkviewer/LinkViewerApp";
import PromoPackBuilderApp from "../bundles/promo-pack-builder/PromoPackBuilderApp";
import TemplatePageBuilderApp from "../bundles/template-page-builder/TemplatePageBuilderApp";
import ArtistPagesApp from "../bundles/artist-pages/ArtistPagesApp";
import ArtistPagesPreviewApp from "../bundles/artist-pages-preview/ArtistPagesPreviewApp";
import PromoteApp from "../bundles/promote/PromoteApp";
import RedirectApp from "../bundles/redirect/RedirectApp";

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  App,
  PublicationsApp,
  LinkBuilderApp,
  LinkViewerApp,
  PromoPackBuilderApp,
  TemplatePageBuilderApp,
  ArtistPagesApp,
  ArtistPagesPreviewApp,
  PromoteApp,
  RedirectApp,
});
