import React from "react";
import styles from "./ProgressBar.module.scss";

type Props = {
  progress: number;
};

const ProgressBar = ({ progress }: Props) => {
  return (
    <div className={styles["progressBarContainer"]}>
      <div
        style={{ transition: "100ms", width: `${progress}%` }}
        className={styles["progressBar"]}
      ></div>
    </div>
  );
};

export default ProgressBar;
