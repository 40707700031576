import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  Template,
  TemplateVariant,
  variantForKey,
} from "../../../asset-generator-lib";
import { usePromoPackContext } from "../providers/PromoPackProvider";
import TemplateManager from "../templates/TemplateManager";
import ActionBar from "../../shared/ActionBar";
import CustomizeForm from "./CustomizeForm";
import { TextInput } from "./FormInputs";
import Loading from "../../shared/Loading";
import {
  AssetGeneratorAction,
  useAnalyticsContext,
} from "../../providers/AnalyticsProvider";
import { usePlatoonArtistContext } from "../../providers/PlatoonArtistProvider";
import Overlay from "../../shared/components/Overlay/Overlay";
import { useUploadVariant } from "./customizeHelpers";
import CustomizePreview from "./CustomizePreview";

type VariantStatus = {
  variant: string;
  status: "PENDING" | "RENDERING" | "UPLOADING" | "DONE";
  upload?: any;
};

type Status = {
  [key: string]: VariantStatus;
};

export type ValidationObject = {
  field: string;
  valid: boolean;
};

const Customize = () => {
  const {
    savingMode,
    promoPack,
    dispatch,
    postPromoPack,
    updatePromoPack,
    updateAsset,
    createAsset,
    fetchPromoPacks,
    fetchPromoPack,
  } = usePromoPackContext();

  const { p4aArtist } = usePlatoonArtistContext();
  const { artistId } = useParams();
  const { trackPromoPack, trackPromoAsset } = useAnalyticsContext();

  const navigate = useNavigate();

  const [exportRenderedComposition, setExportRenderedComposition] =
    useState<any>(null);
  const [packName, setPackName] = useState(promoPack.name || "");
  const [saving, setSaving] = useState(false);
  const [template, setTemplate] = useState(
    TemplateManager.getTemplateById(promoPack?.templateIdentifier)
  );
  const [uploadStatus, setUploadStatus] = useState<Status>({});
  const [validationCollection, setValidationCollection] = useState<any>({});
  const [variant, setVariant] = useState(0);

  const theVariant: TemplateVariant | undefined =
    template?.variants && template.variants[variant];
  const currentVariant: TemplateVariant | undefined = variantForKey(
    theVariant?.key || "",
    template?.variants
  );

  const changeVariant = (value: number) => {
    if (
      template?.variants &&
      variant + value < template?.variants?.length &&
      variant + value >= 0
    ) {
      // do it
      setVariant(variant + value);
    } else if (
      template?.variants &&
      variant + value === template?.variants.length
    ) {
      // reached the end, go back to 0 position
      setVariant(0);
    } else if (template?.variants && variant + value < 0) {
      // going back too far, go to last position in array
      setVariant(template?.variants && template?.variants.length - 1);
    }
  };

  const generateTemplateConfig = (template: Template) => {
    dispatch({
      type: "UPDATE_TEMPLATECONFIG",
      payload: {
        templateConfig: TemplateManager.templateConfigFromTemplate(template),
      },
    });
  };

  const validationCallback = (returnedObj: ValidationObject) => {
    setValidationCollection({
      ...validationCollection,
      [returnedObj.field]: returnedObj.valid,
    });
  };

  const updatePromoPackName = (value: string) => {
    setPackName(value);
  };

  const createUploadStatusKeys = () => {
    template?.variants?.forEach((variant) => {
      // react batches these because it's started by a click event
      // future versions of react will batch regardless
      setUploadStatus((prevStatus) => ({
        ...prevStatus,
        [variant.key]: {
          variant: variant.name,
          status: "PENDING",
          upload: null,
        },
      }));
    });
  };

  useEffect(() => {
    // if we change the variant, clear out the export func hook
    setExportRenderedComposition(null);
  }, [variant]);

  // creating upload status keys of pending
  useEffect(() => {
    if (
      template?.variants &&
      Object.keys(uploadStatus).length < template?.variants.length
    ) {
      createUploadStatusKeys();
    }
  }, [uploadStatus, template]);

  // check for needed data, and redirect back as needed
  useEffect(() => {
    // if we wait to render this component until there is a promo pack, we can drop the first conditional here
    if (Object.keys(promoPack).length > 2 && !promoPack?.p4aReleaseId)
      navigate("../release");
    if (Object.keys(promoPack).length > 2 && !promoPack?.templateIdentifier)
      navigate("../template");
  }, [promoPack]);

  // generate template config? set template? setPromoPackName
  useEffect(() => {
    if (
      template &&
      promoPack?.templateConfig &&
      Object.keys(promoPack?.templateConfig)?.length < 1
    )
      generateTemplateConfig(template);
    !template &&
      setTemplate(
        TemplateManager.getTemplateById(promoPack?.templateIdentifier)
      );
    setPackName(promoPack?.name || "");
  }, [promoPack, template]);

  // updating pack name
  useEffect(() => {
    // might be able to get rid of this extra hook now that we have the useReducer
    dispatch({
      type: "SET_PACK_NAME",
      payload: {
        name: packName,
      },
    });
  }, [packName]);

  const handleSave = async () => {
    setExportRenderedComposition(null);
    if (savingMode === "CREATE") handleCreate();
    else handleUpdate();
  };

  const handleCreate = async () => {
    setSaving(true);
    const result = await postPromoPack();
    trackPromoPack(result, AssetGeneratorAction.promoPackCreated);
    navigate(`./../../${result.id}/customize`);
    return result;
  };

  const handleUpdate = async () => {
    setSaving(true);
    // updates the promo pack (not related to assets)
    const result = await updatePromoPack();
    trackPromoPack(result, AssetGeneratorAction.promoPackUpdated);
    return result;
  };

  const handleReadyToExport = (exportFunc: any) => {
    setExportRenderedComposition(exportFunc);
  };

  // upload UEs
  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      if (
        saving &&
        currentVariant &&
        uploadStatus[currentVariant.key].status === "PENDING" &&
        exportRenderedComposition &&
        promoPack.id
      ) {
        // find the matching asset.  If that doesn't exist, it will just pass undefined, which is fine
        const assetToUpdate = promoPack.promoAssets.find(
          (asset) => asset.description === currentVariant?.name
        );
        useUploadVariant(
          updateAsset,
          createAsset,
          trackPromoAsset,
          setUploadStatus,
          promoPack,
          currentVariant,
          exportRenderedComposition,
          p4aArtist.id,
          assetToUpdate?.id
        );
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [
    saving,
    exportRenderedComposition,
    promoPack,
    uploadStatus,
    template?.variants,
    template,
    variant,
  ]);

  // rotating through
  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      if (
        saving &&
        currentVariant &&
        uploadStatus[currentVariant.key].status === "DONE" &&
        Object.values(uploadStatus).some(
          (variant) => variant.status === "PENDING"
        )
      ) {
        //now that we are uploading, empty the exportRenderedComp hook, and change status
        setExportRenderedComposition(null);
        changeVariant(1);
      } else if (
        saving &&
        Object.values(uploadStatus).every(
          (variant) => variant.status === "DONE"
        )
      ) {
        // refetch promo pack
        fetchPromoPack(promoPack?.id);
        // run updatePromoPack
        updatePromoPack({
          ...promoPack,
          assetsFinishedAt: new Date(), //FE decides this promoPack is finished with assets.
        });
        setSaving(false);
        //for when the navigate back to the index
        artistId && fetchPromoPacks(artistId);
        // move on to download page
        navigate("../download");
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [uploadStatus, currentVariant, saving, promoPack]);

  if (!currentVariant) return <Loading height={"calc(100vh - 500px)"} />;

  return (
    <>
      <Grid container spacing={2}>
        {/* preview */}
        {template && (
          <CustomizePreview
            currentVariant={currentVariant}
            template={template}
            changeVariant={changeVariant}
            variantIndex={variant}
            handleReadyToExport={handleReadyToExport}
          />
        )}

        {/* form + assetName */}
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ outline: "0px solid orange" }}>
              <TextInput
                promoPackField
                name="ASSET NAME"
                update={updatePromoPackName}
                initValue={packName}
                validationCallback={validationCallback}
              />
            </Grid>
            {template && (
              <CustomizeForm
                template={template}
                templateConfig={promoPack.templateConfig}
                disabled={saving}
                validationCallback={validationCallback}
              />
            )}
          </Grid>
        </Grid>

        {/* some sort of spacer? */}
        <Grid item xs={12} style={{ marginBottom: 96 }}></Grid>

        {/* // actionbar and loading */}
        <Grid item xs={12}>
          <ActionBar
            position="bottom"
            buttons={[
              {
                text: "Save",
                group: "right",
                action: () => handleSave(),
                buttonProps: {
                  color: "secondary",
                  variant: "contained",
                  disabled:
                    !Object.values(validationCollection).every(
                      (valid) => valid === true
                    ) || saving, // need to check validations for all fields
                },
              },
              {
                text: "Cancel",
                group: "left",
                action: () => navigate("./../.."),
                buttonProps: {
                  variant: "outlined",
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      {saving && promoPack && (
        <Overlay>
          <Loading size={80} height={"80px"} />
        </Overlay>
      )}
    </>
  );
};

export default Customize;
