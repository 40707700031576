import React from "react";
import Block, { View } from "../../models/Block";
import TextBlockRenderer from "./TextBlockRenderer";
import TextBoxBlockRenderer from "./TextBoxBlockRenderer";
import SpriteBlockRenderer from "./SpriteBlockRenderer";
import ShapeBlockRenderer from "./ShapeBlockRenderer";
import { TemplateEditorState } from "../../TemplateEditorState";

interface PixiBlockRendererProps {
  block: Block;
  parentView: View;
  editorState: TemplateEditorState;
  setEditorState: React.Dispatch<React.SetStateAction<TemplateEditorState>>;
  readOnly: boolean;
  selectBlocks: any;
  selectBlock: any;
  composition: any;
  loader: any;
}

const PixiBlockRenderer = (props: PixiBlockRendererProps) => {
  const { block } = props;

  //TODO: add check for block.params at this level instead of the renderer
  //TODO: test the asset generator lib with the new check and remove unnecessary checks in the renderers

  if (block.params) {
    switch (block.type) {
      case "SPRITE":
        return <SpriteBlockRenderer {...props} />;
      case "TEXT":
        return <TextBlockRenderer {...props} />;
      case "TEXTBOX":
        return <TextBoxBlockRenderer {...props} />;
      case "SHAPE":
        return <ShapeBlockRenderer {...props} />;
      default:
        break;
    }
  }
  return null;
};

export default PixiBlockRenderer;
