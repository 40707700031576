import React, { useContext, useEffect } from "react";
import IframeBlock, { IframeModel } from "./IframeBlock";
import { PlatoonContext } from "../../PlatoonContext";
import validator from "validator";
import { makeStyles, TextField, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  textInput: {
    marginRight: "10px",
    "& label.Mui-focused": {
      color: theme.palette.primary.contrastText,
    },
    "& label": {
      color: theme.palette.primary.contrastText,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.contrastText,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#999999",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

interface Props {
  iframeBlock: IframeBlock;
  setIframeBlock: (
    iframeBlock: IframeBlock,
    errors?: string[] | null,
    warnings?: string[] | null
  ) => void;
}

function UrlIframeItem(props: any) {
  const classes = useStyles();
  const { blockEditSession } = useContext(PlatoonContext);

  function validateButton(iframe: IframeModel) {
    let errors = [];
    if (iframe.url && iframe.url.length <= 0) {
      errors.push("Title should be at least 1 character");
    }
    return errors.length > 0 ? errors : null;
  }

  function warningsForButton(iframe: IframeModel) {
    let warnings = [];
    if (!validator.isURL(iframe.url)) {
      warnings.push("Must be a valid embed URL");
    }
    return warnings.length > 0 ? warnings : null;
  }

  function handleChange(event: any) {
    let b = { ...props.iframe } as any;
    b[event.target.name] = event.target.value;
    let errors = validateButton(b);
    let warnings = warningsForButton(b);
    b.warnings = warnings;
    props.onButtonChange(b, errors, warnings);
  }

  return (
    <div>
      <TextField
        className={classes.textInput}
        style={{
          width: "575px",
          marginLeft: "20px",
          marginTop: "10px",
          padding: "5px 10px 15px",
        }}
        name="url"
        placeholder="External URL"
        value={props.iframe.url}
        onChange={handleChange}
        error={blockEditSession.showWarnings && props.iframe.warnings}
        helperText={
          blockEditSession.showWarnings ? props.iframe.warnings?.join(",") : ""
        }
      />
    </div>
  );
}

function UrlIframeTabContent(props: Props) {
  function onButtonChange(
    iframe: IframeModel,
    errors: string[] | null = null,
    warnings: string[] | null = null
  ) {
    let bb = { ...props.iframeBlock };
    bb.urlIframe = iframe;
    props.setIframeBlock(bb, errors, warnings);
  }

  return (
    <div>
      <UrlIframeItem
        iframe={props.iframeBlock.urlIframe}
        onButtonChange={onButtonChange}
      />
    </div>
  );
}

export { UrlIframeTabContent };

function CodeIframeItem(props: any) {
  const classes = useStyles();
  const { blockEditSession } = useContext(PlatoonContext);

  useEffect(() => {
    let b = { ...props.iframe } as any;
    let errors = validateIframe(b);
    let warnings = warningsForIframe(b);
    b.warnings = warnings;
    props.onButtonChange(b, errors, warnings);
  }, []);

  function validateIframe(iframe: IframeModel) {
    let errors = [];
    if (iframe.code && iframe.code.length <= 0) {
      errors.push("title should be at least 1 character");
    }
    return errors.length > 0 ? errors : null;
  }
  function warningsForIframe(iframe: IframeModel) {
    let warnings = [];
    if (iframe.code && iframe.code.length <= 0) {
      warnings.push("Must a valid Iframe block");
    }
    return warnings.length > 0 ? warnings : null;
  }

  function handleChange(event: any) {
    let b = { ...props.iframe } as any;
    b[event.target.name] = event.target.value;
    let errors = validateIframe(b);
    let warnings = warningsForIframe(b);
    b.warnings = warnings;
    props.onButtonChange(b, errors, warnings);
  }

  return (
    <div>
      <TextField
        className={classes.textInput}
        multiline
        rows={10}
        style={{ width: "575px", marginLeft: "20px", padding: "0px 10px 0px" }}
        variant="outlined"
        name="code"
        placeholder="Iframe Code Block"
        value={props.iframe.code}
        onChange={handleChange}
        error={blockEditSession.showWarnings && props.iframe.warnings}
        helperText={
          blockEditSession.showWarnings ? props.iframe.warnings?.join(",") : ""
        }
      />
    </div>
  );
}

function CodeIframeTabContent(props: Props) {
  function onButtonChange(
    iframe: IframeModel,
    errors: string[] | null = null,
    warnings: string[] | null = null
  ) {
    let bb = { ...props.iframeBlock };
    bb.codeIframe = iframe;
    props.setIframeBlock(bb, errors, warnings);
  }

  return (
    <div>
      <CodeIframeItem
        iframe={props.iframeBlock.codeIframe}
        onButtonChange={onButtonChange}
      />
    </div>
  );
}

export { CodeIframeTabContent };
