import { TemplatePageSection } from "../../../asset-generator-lib/composer/models/Block";
import { JSONParseSafely } from "../../shared/helpers/helpers";
import { MappedData } from "./TemplatePages";

// Adapts attributes between frontend/native (e.g. Dates, Onjects) and backend/serializable (e.g. strings) types
export const TemplateVersionAdaptor = {
  toFrontend: (backendObject: BackendTemplateVersion) => {
    // Create a frontend object with native dates/objects by deserializing backend attributes
    return {
      ...backendObject,
      mappedData: JSONParseSafely(backendObject.mappedData),
      blocks: JSONParseSafely(backendObject.blocks),
    } as TemplateVersion;
  },
};

type TemplateVersionBase = {
  id: string;
  version: number;
};

export type BackendTemplateVersion = TemplateVersionBase & {
  mappedData: string;
  blocks: string;
};

export type TemplateVersion = TemplateVersionBase & {
  mappedData: MappedData[];
  blocks: TemplatePageSection[];
};
