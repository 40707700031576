import React, { useState } from "react";
import AssetBlock, { AssetBlockProps, emptyAssetBlock } from "./AssetBlock";
import { makeStyles, Button } from "@material-ui/core";
import "../../ModalCms.tsx/modalStyles.scss";
import AssetBlockPickerModal from "./AssetBlockPickerModal";
import FramedAsset from "./FramedAsset";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    width: "100%",
    gap: "18px",
  },
  assetWrapper: {
    textAlign: "center",
    flex: "1",
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

function AssetBlockPreviewItem(props: AssetBlockProps) {
  const classes = useStyles();
  const [pickerAssetBlock, setPickerAssetBlock] = useState<AssetBlock>(); // can we add an empty assetblock here?

  function editAssetBlock() {
    setPickerAssetBlock(props.assetBlock);
  }

  function onPickAssetBlock(assetBlock: AssetBlock) {
    props.setAssetBlock(assetBlock);
    setPickerAssetBlock(emptyAssetBlock(props.assetBlock.assetType));
  }

  function handleSave() {
    pickerAssetBlock && onPickAssetBlock(pickerAssetBlock);
  }

  return (
    <div className={classes.flex}>
      {pickerAssetBlock && (
        <AssetBlockPickerModal
          assetBlockProps={{
            assetBlock: pickerAssetBlock,
            setAssetBlock: setPickerAssetBlock,
          }}
          onSave={handleSave}
        />
      )}
      <div className={classes.assetWrapper}>
        <FramedAsset assetBlock={pickerAssetBlock || props.assetBlock} />
      </div>
      <div className={classes.buttonWrapper}>
        <Button variant="contained" color="primary" onClick={editAssetBlock}>
          CHANGE {props.assetBlock.assetType}
        </Button>
      </div>
    </div>
  );
}

export default AssetBlockPreviewItem;
