import React from "react";
import { Box, Grid } from "@material-ui/core";
import ArtistLinkInputHeader from "../LinkEditComponents/ArtistLinkInputHeader";
import ArtistLinkInput from "../LinkEditComponents/ArtistLinkInput";
import ArtistLinkFontPicker from "../LinkEditComponents/ArtistLinkFontPicker";
import ArtistLinkTitleInput from "../LinkEditComponents/ArtistLinkTitleInput";
import ArtistLink, { ArtistNameMode } from "../models/ArtistLink";
import ArtistLinkRadioInput from "../LinkEditComponents/ArtistLinkRadioInput";
import { Update } from "../ArtistLinkEdit/FormFields";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import ArtistFileInput from "./ArtistFileInput/ArtistFileInput";

interface Props {
  onChange: any;
}

const ArtistName = (props: Props) => {
  const { artistLink, dispatch } = useArtistLinkContext();

  const modeIsText = artistLink.artistNameMode === ArtistNameMode.text;

  const artistNameChange = (update: Update) => {
    let updatedArtistName = { ...artistLink } as ArtistLink;
    updatedArtistName.artist.name = update.value;
    dispatch({
      type: "SET_ARTISTLINK",
      payload: updatedArtistName,
    });
  };

  return (
    <>
      <ArtistLinkTitleInput
        artistLinkKey={artistLink.title}
        id={"title"}
        name={"title"}
        label={"LISTENING LINK TITLE"}
        onChange={props.onChange}
      />
      <ArtistLinkInputHeader header={"ARTIST NAME"} variant={"h5"} />
      <Box width={1} pl={4} display="flex">
        <ArtistLinkRadioInput
          name="artistNameMode"
          checkedValue={artistLink.artistNameMode}
          label={"TEXT"}
          value={ArtistNameMode.text}
        />

        <ArtistLinkRadioInput
          name="artistNameMode"
          checkedValue={artistLink.artistNameMode}
          label={"LOGO"}
          value={ArtistNameMode.image}
        />
      </Box>
      {modeIsText ? (
        <>
          <ArtistLinkInput
            disabled={true}
            artistLinkKey={artistLink.artist.name}
            name={"artist"}
            id={"name"}
            label={"Artist Name"}
            validators={["required"]}
            errorMessage={["This field is required"]}
            onChange={artistNameChange}
          />
          <ArtistLinkInputHeader header={"ARTIST NAME FONT"} variant={"h6"} />
          <ArtistLinkFontPicker artistLinkKey={"artistNameFont"} />
        </>
      ) : (
        <Grid item xs={12}>
          <Box p={1} pl={3} pr={3} style={{ color: "white" }}>
            <ArtistFileInput
              artistLinkKey={"artistNameLogo"}
              artistLinkKeyId={"artistNameLogoID"}
              accepts="image/png, image/jpeg"
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default ArtistName;
