import React from "react";
import { useParams } from "react-router-dom";
import { getTemplatePage } from "../../template-page-builder/queries/useTemplatePages";
import { usePlatoonArtistContext } from "../../providers/PlatoonArtistProvider";
import Loading from "../../shared/Loading";
import ErrorPage from "../../linkviewer/ErrorPage";
import TemplatePageRenderer from "../../template-page-builder/components/TemplatePageRenderer/TemplatePageRenderer";
import ArtistPagePreviewHeader from "../ArtistPagesPreviewHeader/ArtistPagesPreviewHeader";
import { getLatestDraft } from "../../shared/helpers/helpers";

const ArtistPagesPreviewLoader = () => {
  const { templatePageId } = useParams();
  const { p4aArtist } = usePlatoonArtistContext();

  const canWeFetch = p4aArtist?.id ? true : false;

  const {
    isLoading,
    error,
    data: templatePageRecord,
  } = getTemplatePage(p4aArtist?.id, templatePageId, canWeFetch);

  if (isLoading) return <Loading height="100vh" />;

  if (error) return <ErrorPage />;

  // we want to preview the latest draft
  const latestDraft =
    templatePageRecord?.drafts && getLatestDraft(templatePageRecord.drafts);

  return (
    <div>
      {templatePageRecord && (
        <>
          <ArtistPagePreviewHeader />
          {latestDraft && (
            <TemplatePageRenderer
              templatePage={latestDraft}
              assembleArtist={templatePageRecord?.artist}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ArtistPagesPreviewLoader;
