import React, { createContext, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import "typeface-roboto";
import { CssBaseline, Toolbar } from "@material-ui/core";
import NavBar from "./bundles/shared/NavBar";
import SideNav from "./bundles/shared/SideNav";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import SnackProvider from "./bundles/shared/SnackProvider";
import { AssetManager } from "./bundles/platoon-cms-lib";
import APIAssetService from "./bundles/assets/APIAssetService";
import "react-awesome-slider/dist/styles.css"; // this is the default stlying
import "./packs/styles/awssld.css";
import "./bundles/platoon-cms-lib/components/styles.scss";
// import "./bundles/af/index.css";
import * as Sentry from "@sentry/react";
import { newTracker } from "@snowplow/browser-tracker";
import ErrorHandlingProvider from "./bundles/providers/ErrorHandlingProvider";

newTracker("sp", process.env.API_HOST, {
  appId: `ID-platoon-${process.env.NODE_ENV}`,
  plugins: [],
});
process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0,
  });
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#0d0d0d",
      dark: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff3e66",
      dark: "#1d1d1d",
      light: "#424242",
      contrastText: "#fff",
    },
    background: {
      default: "#191919",
    },
  },
});
const useStyles = () => ({
  root: {
    display: "flex",
    height: "100%",
  },
  content: {
    height: "100%",
    width: "100%",
    minWidth: 0,
  },
});

AssetManager.useService(new APIAssetService());

// This is the platoon.vip/admin app
class App extends React.Component {
  render() {
    const { classes } = this.props;
    console.log('Hi');
    return (
      <Router>
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <SnackProvider>
              <ErrorHandlingProvider>
                <CssBaseline />
                <NavBar />
                <SideNav />
                <div className={classes.content}>
                  <Toolbar />
                  <Routes />
                </div>
              </ErrorHandlingProvider>
            </SnackProvider>
          </div>
        </ThemeProvider>
      </Router>
    );
  }
}
export default withStyles(useStyles)(App);
