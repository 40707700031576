import React, { useContext } from 'react';
import { PlatoonContext } from "../../PlatoonContext";
import { Box } from '@material-ui/core';
import { AssetType } from "../../../models/Asset";
import AssetCollectionContent from './AssetCollectionContent';
import { AssetCollectionBlock, emptyAssetCollectionBlock } from './AssetBlock';

interface Props {
  assetType: AssetType;
}

export default function AssetCollectionBlockPicker({ assetType }: Props) {
  const { blockEditSession, setBlockEditSession } = useContext(PlatoonContext);

  const assetCollectionBlock = blockEditSession.blockData as AssetCollectionBlock || emptyAssetCollectionBlock(assetType)

  const setAssetColletionBlock = (assetCollectionBlock: AssetCollectionBlock) => {
    let s = { ...blockEditSession };
    s.blockData = assetCollectionBlock;
    setBlockEditSession(s);
  }

  return (
    <Box>
      <AssetCollectionContent
        assetCollectionBlock={assetCollectionBlock}
        setAssetCollectionBlock={setAssetColletionBlock} />
    </Box>
  );
}


