import React, { useState } from "react";
import AssetBlock, { AssetFrame } from "../asset/AssetBlock";
import Hover from "../../Hover";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { HoverActions } from "../../PlatoonContext";
import FramedAsset from "../asset/FramedAsset";

interface Props {
  assetBlock?: AssetBlock;
  styles: any; // this is out of pattern for the rest of the app, do we want to do this?
  hoverActions: HoverActions;
}

const ImageBlockRenderer = ({ assetBlock, hoverActions, styles }: Props) => {
  const [loading, setLoading] = useState(true);

  const assetLoaded = () => {
    setLoading(false);
  };

  const imgUrl =
    assetBlock?.assetPickerSource === "url"
      ? assetBlock.asset?.attachment
      : `/api/v1/signed-assets/${assetBlock?.asset?.id}`;

  return (
    <Hover actions={hoverActions}>
      <div className={styles.assetCentered}>
        {assetBlock?.frame === AssetFrame.none || !assetBlock?.frame ? (
          <React.Fragment>
            <div style={{ display: loading ? "block" : "none" }}>
              <Loader type="Oval" color="grey" height={100} width={100} />
            </div>
            <div style={{ display: loading ? "none" : "block" }}>
              <img
                onLoad={assetLoaded}
                src={imgUrl}
                alt="logo"
                className={styles.asset}
              />
            </div>
          </React.Fragment>
        ) : (
          <FramedAsset assetBlock={assetBlock} />
        )}
      </div>
    </Hover>
  );
};

export default ImageBlockRenderer;
