import { Logger } from "./SafeLogger";
import ResourceSchema, { UserRole } from "./ResourceSchema";
import RockDove from "./RockDove";

class ResponseError extends Error {
  reasons: { [key: string]: any };
  response: Response;
  constructor(reasons: { [key: string]: string }, response: Response) {
    Logger.of("App").info("in response error", reasons, response);
    // example reasons:  {title: ["can't be blank"], type: ["can't be blank"]}
    let keys = Object.keys(reasons);
    let anyReasons = reasons as any;

    let responseMessage = keys
      .map((key: string) => {
        let specificReasons = anyReasons[key];
        let reasonSummary =
          specificReasons && specificReasons.join
            ? specificReasons.join(", ")
            : "Unknown Reasons";
        return `${key} ${reasonSummary}`;
      })
      .join(" & ");

    super(responseMessage);
    this.name = "ResponseError";
    this.reasons = reasons;
    this.response = response;
  }
  responseMessage() {}
}

export { ResponseError };

async function validateResponse(
  response: Response,
  resourceSchema: ResourceSchema
) {
  const contentType = response.headers.get("content-type");

  if (contentType?.indexOf("application/json") !== -1) {
  } else {
    console.warn(
      "NOT JSON, exiting validateResponse",
      { contentType },
      { response }
    );
    return;
  }

  let json = await response?.json().catch((err) => {
    throw new Error(err);
  });

  if (response.ok && json) {
    if (resourceSchema.adaptor) {
      // If data is nested in it's type (rootPath), as is the case for paginated
      // results, we need to dive 1 layer down
      json = resourceSchema.dataIsNested ? json["results"] : json;

      if (json instanceof Array) {
        return json.map((backendResource) =>
          resourceSchema.adaptor?.toFrontend?.(backendResource)
        );
      }
      return resourceSchema.adaptor.toFrontend?.(json);
    }
    return json;
  } else {
    throw new ResponseError(json, response);
  }
}

async function validateQueryResponse(
  response: Response,
  resourceSchema: ResourceSchema
) {
  let json = await response.json();
  if (response.ok && json) {
    if (resourceSchema.adaptor) {
      const adaptedResources = await Promise.all(
        json["results"].map(async (backendResource: any) =>
          resourceSchema.adaptor?.toFrontend?.(backendResource)
        )
      );
      let queryResult = {} as any;
      queryResult["results"] = adaptedResources;
      queryResult.pagination = json["pagination"];

      return queryResult;
    }
    throw new Error("Could not parse json");
  } else {
    throw new ResponseError(json, response);
  }
}

function cloneResource(resource: any, resourceSchema: ResourceSchema) {
  return RockDove.shared()
    .getHeaders(resourceSchema.role)
    .then((headers: any) => {
      // Too much duplication
      if (
        resourceSchema.adaptor &&
        resourceSchema.adaptor.toBackend &&
        !(resource instanceof FormData)
      ) {
        resource = resourceSchema.adaptor.toBackend(resource);
      }

      const formHeaders =
        resource instanceof FormData
          ? {}
          : { "Content-Type": "application/json" };
      const fullHeaders = { ...headers, ...formHeaders };
      const body =
        resource instanceof FormData ? resource : JSON.stringify(resource);

      let options = {
        method: "POST",
        body: body,
        headers: fullHeaders,
      } as any;

      var p = `${resourceSchema.apiBase}/${resourceSchema.apiRootPath}/${resource.id}/clone`;
      if (resourceSchema.locale) {
        p += `?locale=${resourceSchema.locale}`;
      }

      const path = p;
      Logger.of("App").info("POST", path);

      return fetch(path, options).then((response) =>
        validateResponse(response, resourceSchema)
      );
    });
}

function postResource(resource: any, resourceSchema: ResourceSchema) {
  return RockDove.shared()
    .getHeaders(resourceSchema.role)
    .then((headers: any) => {
      if (
        resourceSchema.adaptor &&
        resourceSchema.adaptor.toBackend &&
        !(resource instanceof FormData)
      ) {
        resource = resourceSchema.adaptor.toBackend(resource);
      }

      const formHeaders =
        resource instanceof FormData
          ? {}
          : { "Content-Type": "application/json" };
      const fullHeaders = { ...headers, ...formHeaders };

      const body =
        resource instanceof FormData ? resource : JSON.stringify(resource);

      let options = {
        method: "POST",
        body: body,
        headers: fullHeaders,
      } as any;

      var p = `${resourceSchema.apiBase}/${resourceSchema.apiRootPath}`;
      if (resourceSchema.locale) {
        p += `?locale=${resourceSchema.locale}`;
      }

      const path = p;
      Logger.of("App").info("POST", path);

      return fetch(path, options).then((response) =>
        validateResponse(response, resourceSchema)
      );
    });
}

function patchResource(
  identifier: string,
  resource: any,
  resourceSchema: ResourceSchema
) {
  return RockDove.shared()
    .getHeaders(resourceSchema.role)
    .then((headers: any) => {
      if (
        resourceSchema.adaptor &&
        resourceSchema.adaptor.toBackend &&
        !(resource instanceof FormData)
      ) {
        resource = resourceSchema.adaptor.toBackend(resource);
      }

      // Our firewall doesn't like some things... We're stumped by a JSON payload
      // that our QA firewall isn't letting through (on any resource), namely:
      // { "title": "'!@" }  //403 from firewall
      // We discovered that adding the resource id to PATCH fixes the issue (even though it's redundant)
      // so the PATCH payload now looks like
      // { "title": "'!@", "id": "123"}  // 200 or 201 (OK)
      // This change should be ok because the id should match the incoming resource identifier
      resource.id = identifier;

      const formHeaders =
        resource instanceof FormData
          ? {}
          : { "Content-Type": "application/json" };
      const fullHeaders = { ...headers, ...formHeaders };

      const body =
        resource instanceof FormData ? resource : JSON.stringify(resource);

      let options = {
        method: "PATCH",
        body: body,
        headers: fullHeaders,
      } as any;

      var p = `${resourceSchema.apiBase}/${resourceSchema.apiRootPath}/${identifier}`;
      if (resourceSchema.locale) {
        p += `?locale=${resourceSchema.locale}`;
      }

      const path = p;

      Logger.of("App").info("PATCH", path);
      return fetch(path, options).then((response) =>
        validateResponse(response, resourceSchema)
      );
    });
}

function getResource(identifier: string, resourceSchema: ResourceSchema) {
  Logger.of("App").info("getResource getHeaders from RockDove");
  return RockDove.shared()
    .getHeaders(resourceSchema.role)
    .then((headers: any) => {
      Logger.of("App").info("getResource headers", headers);
      var path = `${resourceSchema.apiBase}/${resourceSchema.apiRootPath}/${identifier}`;
      if (resourceSchema.locale) {
        path += `?locale=${resourceSchema.locale}`;
      }

      Logger.of("App").info("GET", path, headers);
      return fetch(path, { headers }).then((response) => {
        return validateResponse(response, resourceSchema);
      });
    });
}

function deleteResource(identifier: string, resourceSchema: ResourceSchema) {
  return RockDove.shared()
    .getHeaders(resourceSchema.role)
    .then((headers: any) => {
      const formHeaders = { "Content-Type": "application/json" };
      const fullHeaders = { ...formHeaders, ...headers };

      const path = `${resourceSchema.apiBase}/${resourceSchema.apiRootPath}/${identifier}`;
      Logger.of("App").info(
        "DELETE",
        `${resourceSchema.apiBase}/${resourceSchema.apiRootPath}/${identifier}`
      );
      return fetch(path, {
        method: "DELETE",
        headers: fullHeaders,
      }).then((response) => {
        if (response.ok) {
          return { status: "OK" };
        } else {
          return validateResponse(response, resourceSchema);
        }
      });
    });
}

function getResourceQueryResponse(resourceSchema: ResourceSchema, page = 0) {
  resourceSchema.dataIsNested = true;
  resourceSchema.page = page;
  resourceSchema.sort = { attr: "updatedAt", order: "DESC" };
  return fetchQueryAPI(resourceSchema.apiRootPath, resourceSchema);
}

function getResources(resourceSchema: ResourceSchema) {
  resourceSchema.dataIsNested = true;

  return fetchAPI(resourceSchema.apiRootPath, resourceSchema);
}

function fetchAPI(path: string, resourceSchema: ResourceSchema) {
  return RockDove.shared()
    .getHeaders(resourceSchema.role)
    .then((headers: any) => {
      let base = `${window.location.protocol}//${window.location.host}`;
      Logger.of("App").info("base", base);

      let urlString = `${resourceSchema.apiBase}/${path}`;
      Logger.of("App").info("urlString", urlString);

      let url = new URL(urlString, base);

      if (resourceSchema.page) {
        url.searchParams.append("page", resourceSchema.page.toString());
      }

      if (resourceSchema.locale) {
        url.searchParams.append("locale", resourceSchema.locale);
      }

      if (resourceSchema.query) {
        url.searchParams.append("query", resourceSchema.query);
      }

      Logger.of("App").info("fetchAPI URL", url);
      Logger.of("App").info("fetchAPI headers", headers);
      return fetch(url.toString(), { headers }).then((response) =>
        validateResponse(response, resourceSchema)
      );
    });
}

// TODO: Refactor so fetchAPI will return object containing pagination data
function fetchQueryAPI(path: string, resourceSchema: ResourceSchema) {
  return RockDove.shared()
    .getHeaders(resourceSchema.role)
    .then((headers: any) => {
      let base = `${window.location.protocol}//${window.location.host}`;

      let urlString = `${resourceSchema.apiBase}/${path}`;

      let url = new URL(urlString, base);

      if (resourceSchema.page) {
        url.searchParams.append("page", resourceSchema.page.toString());
      }

      if (resourceSchema.locale) {
        url.searchParams.append("locale", resourceSchema.locale);
      }

      if (resourceSchema.query) {
        url.searchParams.append("query", resourceSchema.query);
      }

      if (resourceSchema.sort) {
        url.searchParams.append("sortAttr", resourceSchema.sort.attr);
        url.searchParams.append("sortOrder", resourceSchema.sort.order);
      }

      Logger.of("App").info("fetchQueryAPI URL", url);
      Logger.of("App").info("fetchQueryAPI headers", headers);

      return fetch(url.toString(), { headers }).then((response) =>
        validateQueryResponse(response, resourceSchema)
      );
    });
}

export type ApiBase = "/api/v1" | "/p4a-api";

function fetchAPIBase(
  path: string,
  role: UserRole,
  options: any = undefined,
  apiBase: ApiBase = "/api/v1"
) {
  return RockDove.shared()
    .getHeaders(role)
    .then((headers: any) => {
      var headerInjectedOptions = { ...options };

      const contentHeader = { "Content-Type": "application/json" };
      headerInjectedOptions.headers = {
        ...headerInjectedOptions.headers,
        ...headers,
        ...contentHeader,
      };

      Logger.of("App").info("fetchAPIBase", path, headerInjectedOptions);

      return fetch(`${apiBase}/${path}`, headerInjectedOptions);
    });
}

const API = {
  index: getResources,
  query: getResourceQueryResponse,
  read: getResource,
  create: postResource,
  update: patchResource,
  delete: deleteResource,
  fetchAPI: fetchAPI,
  fetch: fetchAPIBase,
  clone: cloneResource,
};

export default API;

export { validateResponse, validateQueryResponse };
