import React from "react"

interface Props {
  displayMode?: string
}

const IphoneButton = (props: Props) => {

  const iconColor = props.displayMode === 'Mobile' ? '#FFFFFF' : 'gray'

  return (


    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17">
      <g fill="none" fillRule="evenodd">
        <g fill={`${iconColor}`} fillRule="nonzero">
          <path d="M875.055 182.508c1.68 0 2.547-.883 2.547-2.57V168.57c0-1.695-.875-2.57-2.547-2.57h-5.508c-1.672 0-2.547.875-2.547 2.57v11.368c0 1.687.875 2.57 2.547 2.57h5.508zm.992-3.477h-7.485v-9.484h7.485v9.484z" transform="translate(-867 -166)" />
        </g>
      </g>
    </svg>
  )
}

export default IphoneButton
