import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Immutable from "immutable";

const useStyles = makeStyles(() => ({
  textContainer: {},

  h1: {
    fontFamily: "Helvetica",
    fontSize: "19px",
    fontWeight: "normal",
    color: "#ffffff",
  },
  h2: {
    fontFamily: "Helvetica",
    // don't have an example, guessed
    fontSize: "17px",
    fontWeight: "normal",
    color: "#ffffff",
  },
  paragraph: {
    fontFamily: "Helvetica",
    fontSize: "15px",
    lineHeight: "23px",
    color: "#ffffff",
  },
  lists: {
    letterSpacing: 0,
  },
}));

const TextBlock = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.paragraph} id="textBlock">
      {props.children}
    </div>
  );
};

const UnorderedListBlock = (props: any) => {
  const classes = useStyles();
  return (
    <ul
      className={`${classes.lists} ${classes.paragraph}`}
      id="unorderedListBlock"
    >
      {props.children}
    </ul>
  );
};

const OrderedListBlock = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.paragraph} id="orderedListBlock">
      <ol className={classes.lists}>{props.children}</ol>
    </div>
  );
};

const HeaderBlock = (props: any) => {
  const classes = useStyles();
  return (
    <h1 className={classes.h1} id="headerBlock">
      {props.children}
    </h1>
  );
};

const HeaderTwoBlock = (props: any) => {
  const classes = useStyles();

  return (
    <h2 id="headerTwoBlock" className={classes.h2}>
      {props.children}
    </h2>
  );
};

const AtomicBlock = (props: any) => {
  return <div>{props.children}</div>;
};

function createBlockRenderMap() {
  return Immutable.Map({
    "header-one": {
      wrapper: <HeaderBlock />,
    },
    "header-two": {
      wrapper: <HeaderTwoBlock />,
    },
    unstyled: {
      element: "div", //changed
      aliasedElements: ["div"], //changed
      wrapper: <TextBlock />,
    },
    "unordered-list-item": {
      element: "li",
      wrapper: <UnorderedListBlock />,
    },
    "ordered-list-item": {
      element: "li",
      wrapper: <OrderedListBlock />,
    },
    atomic: {
      wrapper: <AtomicBlock />,
    },
  });
}

export { createBlockRenderMap };
