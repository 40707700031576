import { LearnLinkCardData } from "../PromoteLearnCard/PromoteLearnCard";

import { ReactComponent as LinkFire } from "../../../shared/icons/linkfire.svg";
import { ReactComponent as Person3Fill } from "../../../shared/icons/person-3-fill.svg";
import { ReactComponent as BookClosedFill } from "../../../shared/icons/book-closed-fill.svg";

export const learnLinksData: LearnLinkCardData[] = [
  {
    icon: LinkFire,
    title: "linkfire",
    description: "Learn how to retreive and post your Linkfire links",
    link: "/apps/publications/linkfire",
  },
  {
    icon: Person3Fill,
    title: "partnerize",
    description: "Earn extra money with this powerful affiliate tool",
    link: "/apps/publications/partnerize",
  },
  {
    icon: BookClosedFill,
    title: "Release Playbook",
    description: "Learn industry best practice for releasing your music",
    link: "/apps/publications",
  },
];
