import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import API from "../shared/API";
import { UserRole } from "../shared/ResourceSchema";

interface CountMetric {
  count: number;
  timeframe: string;
}

const countMetricUseStyles = makeStyles((theme: Theme) => ({
  shapeCircle: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    borderColor: "grey",
    color: theme.palette.secondary.contrastText,
    border: "1px solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "18px",
  },
}));

interface ReportWidgetProps {
  report: any;
}

interface CountMetricWidgetProps {
  countMetric: CountMetric;
}

function CountMetricWidget({ countMetric }: CountMetricWidgetProps) {
  const classes = countMetricUseStyles();

  return (
    <Box m={2}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Box className={classes.shapeCircle}>
            <Typography variant={"h5"}>{countMetric.count}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box textAlign="center">
            <Typography variant="body1">{countMetric.timeframe}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const reportWidgetUseStyles = makeStyles(() => ({
  csvLink: {
    textDecoration: "none",
    color: "black",
  },
}));
export default function ReportWidget({ report }: ReportWidgetProps) {
  const classes = reportWidgetUseStyles();

  const [csvData, setCsvData] = React.useState<any>();

  async function getCsvData() {
    try {
      const r = await API.fetch(
        `raw_reports/${report.scope_key}`,
        UserRole.Admin
      );
      const result = await r.json();
      setCsvData(result);
      return result;
    } catch (error) {
      console.log("Error", error);
    }
  }

  return (
    <Paper>
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4" style={{ textAlign: "center" }}>
                {report.kpi}
              </Typography>
            </Grid>

            <Grid item>
              {csvData ? (
                <Button variant="contained" color="default" fullWidth>
                  <CSVLink
                    className={classes.csvLink}
                    filename={`${report.kpi}-fullreport.csv`}
                    headers={csvData[0]}
                    data={csvData[1]}
                    asyncOnClick={true}
                  >
                    DOWNLOAD FULL REPORT ({csvData[1].length} Entries)
                  </CSVLink>
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => getCsvData()}
                >
                  GENERATE REPORT
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            spacing={3}
            justify="center"
            alignItems="flex-start"
          >
            {report.totals.map((countMetric: CountMetric) => (
              <Grid item key={countMetric.timeframe} xs={3}>
                <CountMetricWidget countMetric={countMetric} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
