import React, { useEffect, useState } from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import TemplatePageFormRenderer from "../../TemplatePageFormRenderer/TemplatePageFormRenderer";
import { InputProps, GenericObj } from "../helpers";
import styles from "./CollapsableInput.module.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/icons/plus-circle.svg";
import { ReactComponent as MinusCircle } from "../../../../shared/icons/minus-circle.svg";
import { resolveMappedPath } from "../../../../../asset-generator-lib/helpers";
import { TemplatePageDraft } from "../../../types/TemplatePages";

const initValues = (
  param: BlockParam,
  templatePageDraft: TemplatePageDraft
) => {
  const newValues: GenericObj<BlockParam> = {};
  param.options &&
    param.options.forEach((item) => {
      if (item.mappedPath) {
        newValues[item.key] = {
          ...item,
          value: resolveMappedPath(
            item.mappedPath,
            templatePageDraft.mappedData
          ),
        };
      } else {
        newValues[item.key] = {
          ...item,
        };
      }
    });
  return { ...newValues };
};

const CollapsableInput = ({
  param,
  update,
  templatePageDraft,
  sectionKey,
  path,
}: InputProps) => {
  //need to access nested data

  const [value, setValue] = useState<GenericObj<BlockParam>>(
    param.value ? JSON.parse(param.value) : initValues(param, templatePageDraft)
  );
  const [collapsed, setCollapsed] = useState(true);

  // we have to set local value, then send that to the update
  useEffect(() => {
    if (Object.keys(value).length < 1) {
      initValues(param, templatePageDraft);
    } else {
      update({ ...param, value: JSON.stringify(value) });
    }
  }, [value]);

  // we don't know what kind of data we are getting, so using ANY as long as the parent update func is also any
  const wrapUpdateWithParamKey = (updatedData: any) => {
    setValue({ ...value, [updatedData.key]: updatedData });
  };

  return (
    <div>
      <button
        className={`btn--naked ${styles["header-btn"]}`}
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? (
          <PlusCircle className={styles["icon"]} />
        ) : (
          <MinusCircle className={styles["icon"]} />
        )}
        <span className="label label--big">{param.name}</span>
      </button>
      <div
        className={`${styles["group-container"]} ${
          collapsed ? styles["collapsed"] : null
        }`}
      >
        <TemplatePageFormRenderer
          blockParams={Object.values(value)}
          update={wrapUpdateWithParamKey}
          disabled={false}
          templatePageDraft={templatePageDraft}
          sectionKey={sectionKey}
          path={path}
        />
      </div>
    </div>
  );
};

export default CollapsableInput;
