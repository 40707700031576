import React, { useRef, useEffect } from "react";
import styles from "./AnimatedViewSwitcher.module.scss";
import { usePrevious } from "react-use";

type ViewSwitcher = {
  displayedView: { value: string; animation: "slide-up" | "slide-down" };
  children: React.ReactNode[];
};

const AnimatedViewSwitcher = ({ displayedView, children }: ViewSwitcher) => {
  const element = children.find((child) => {
    if (
      !React.isValidElement(child) ||
      !child ||
      !child.props ||
      !child.props.id
    ) {
      return false;
    }
    return child.props.id === displayedView.value;
  });

  const viewSwitchRef = useRef<HTMLDivElement>(null);

  const previousView = usePrevious(displayedView.value);
  useEffect(() => {
    if (previousView && viewSwitchRef.current) {
      const animation = displayedView.animation;
      viewSwitchRef.current.className = styles[`${animation}`];
    }
    return () => {
      if (viewSwitchRef.current) {
        viewSwitchRef.current.className = "";
      }
    };
  }, [displayedView.value]);

  return (
    (
      <div ref={viewSwitchRef} key={displayedView.value}>
        {element}
      </div>
    ) || null
  );
};

export default AnimatedViewSwitcher;
