import React, { useState } from "react";
import { emptyAsset } from "../../../models/Asset";
import { Divider, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AssetUrlTabContent from "./AssetUrlTabContent";
import AssetUploaderTabContent from "./AssetUploaderTabContent";
import AssetLibraryTabContent from "./AssetLibraryTabContent";
import { AssetPickerSource, AssetBlockProps } from "./AssetBlock";

const useStyles = makeStyles(() => ({
  modalContent: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginBottom: "30px",
  },
  tabs: {
    color: "#ffffff99",
    fontSize: "14px",
    fontFamily: "SF Pro Display",
    letterSpacing: "1.25px",
    width: "64px",
  },
  modalTitle: {
    color: "#ffffff",
    fontSize: "26px",
    fontFamily: "SF Pro Display",
    marginTop: "30px",
    marginLeft: "30px",
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#999999",
  },
}));

const pickerModes = [
  { title: "URL", value: AssetPickerSource.url },
  { title: "Upload", value: AssetPickerSource.upload },
  { title: "Library", value: AssetPickerSource.library },
];

export default function AssetBlockSource({
  assetBlock,
  setAssetBlock,
}: AssetBlockProps) {
  const classes = useStyles();

  //Create our own assetBlock here, copying the ones passed in if needed
  // const [assetBlock, setAssetBlock] = useState<AssetBlock>(props.assetBlock || emptyAssetBlock(assetType)); //will be empty on create
  // const [asset, setAsset] = useState(assetBlock.asset || emptyAsset(assetBlock.assetType));
  const [currentPickerMode, setCurrentPickerMode] = useState(
    assetBlock.assetPickerSource || AssetPickerSource.url
  );

  function handlePickerMode(_: unknown, newMode: string | null) {
    //Avoid deselecting the toggle group
    if (newMode !== currentPickerMode && newMode !== null) {
      setCurrentPickerMode(newMode as AssetPickerSource);
      // Switching tabs means the asset of the tab should be cleared
      let ab = { ...assetBlock };
      ab.asset = emptyAsset();
      setAssetBlock(ab);
    }
  }

  return (
    <div>
      <Tabs
        value={currentPickerMode}
        indicatorColor="secondary"
        onChange={handlePickerMode}
        aria-label="Picker Mode"
      >
        {pickerModes.map((mode) => (
          <Tab
            key={mode.title}
            className={classes.tabs}
            value={mode.value}
            aria-label={mode.title}
            label={mode.title}
          >
            test
          </Tab>
        ))}
      </Tabs>
      <Divider style={{ marginBottom: "10px" }} />

      <div className={classes.modalContent}>
        {currentPickerMode === AssetPickerSource.url && (
          <AssetUrlTabContent
            assetBlock={assetBlock}
            setAssetBlock={setAssetBlock}
          />
        )}

        {currentPickerMode === AssetPickerSource.upload && (
          <AssetUploaderTabContent
            assetBlock={assetBlock}
            setAssetBlock={setAssetBlock}
          />
        )}

        {currentPickerMode === AssetPickerSource.library && (
          <AssetLibraryTabContent
            assetBlock={assetBlock}
            setAssetBlock={setAssetBlock}
          />
        )}
      </div>
    </div>
  );
}
