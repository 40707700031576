import React from "react";
import styles from "./RemoveFile.module.scss";

import { ReactComponent as XMark } from "../../../../../shared/icons/xmark.svg";

type Props = {
  removeFn: any; // TODO
};

const RemoveFile = ({ removeFn }: Props) => {
  return (
    <button className="btn--naked" onClick={removeFn}>
      <XMark className={styles["icon"]} />
    </button>
  );
};

export default RemoveFile;
