import React, { useContext, createContext, useState } from "react";

type Props = {
  children: any;
};

type Status = {
  [key: string]: "DONE" | "IN PROGRESS";
};

type LoadingContext = {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loadingStatus: Status;
  setLoadingStatus: React.Dispatch<React.SetStateAction<Status>>;
};

export const LoadingContext = createContext<LoadingContext | null>(null);
export const useLoadingContext = () => useContext(LoadingContext)!;

export const LoadingProvider = ({ children }: Props) => {
  const [loadingStatus, setLoadingStatus] = useState<Status>({});
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        loadingStatus,
        setLoadingStatus,
        loading,
        setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
