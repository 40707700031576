import React from "react";
import HistoryPoster from "../../bundles/shared/HistoryPoster";
import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import ArtistLinkTheme from "../link-builder/ArtistLinkTheme";
import ArtistLinkPublicViewer from "./ArtistLinkPublicViewer";
import SnackProvider from "../shared/SnackProvider";
import { ArtistLinkProvider } from "../providers/ArtistLinkProvider";
import { LoadingProvider } from "../providers/LoadingProvider";
import { AnalyticsProvider } from "../providers/AnalyticsProvider";
import ErrorHandlingProvider from "../providers/ErrorHandlingProvider";
import { AppType } from "../providers/AnalyticsProvider";

const basePath = "stealth-player";

const LinkViewerApp = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <ThemeProvider theme={ArtistLinkTheme}>
        <SnackProvider>
          <LoadingProvider>
            <ErrorHandlingProvider>
              <AnalyticsProvider app={AppType.artistLinkPlayer}>
                <ArtistLinkProvider>
                  <HistoryPoster>
                    <Routes>
                      <Route path={basePath}>
                        <Route
                          path={`:artistSlug/:artistLinkSlug/:artistLinkShortId`}
                          element={<ArtistLinkPublicViewer />}
                        />
                      </Route>
                    </Routes>
                  </HistoryPoster>
                </ArtistLinkProvider>
              </AnalyticsProvider>
            </ErrorHandlingProvider>
          </LoadingProvider>
        </SnackProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};
export default LinkViewerApp;
