import React, { ReactNode } from "react";
import styles from "./TemplatePageRenderedSectionWrapper.module.scss";

type Props = {
  children?: ReactNode;
  title?: string | ReactNode;
  highlightName?: string;
  highlight: boolean;
  styleOverride?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["style"];
  hideHeading?: true;
};

const TemplatePageRenderedSectionWrapper = ({
  children,
  title,
  highlightName,
  highlight,
  styleOverride,
  hideHeading,
}: Props) => {
  const renderedHighlight = highlightName ? (
    <h4>{highlightName}</h4>
  ) : typeof title === "string" ? (
    <h4>{title}</h4>
  ) : (
    <div>{title}</div>
  );

  return (
    <section
      className={`${styles.section} `}
      style={{
        ...(!title && { paddingTop: 0, paddingBottom: 0 }),
        ...styleOverride,
      }}
    >
      {highlight && (
        <div className={`${styles.highlight}  `}>{renderedHighlight}</div>
      )}
      <div className={styles.container}>
        {title && !hideHeading && (
          <header>
            <div
              className={styles.heading}
              style={{
                ...(styleOverride?.color && { color: styleOverride?.color }),
              }}
            >
              {title}
            </div>
          </header>
        )}
        {children}
      </div>
    </section>
  );
};

export default TemplatePageRenderedSectionWrapper;
