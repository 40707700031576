import React, { useState } from "react";
import AssetBlock, {
  AssetCollectionBlock,
  AssetPickerSource,
} from "../asset/AssetBlock";
import Hover from "../../Hover";
import ReactPlayer from "react-player";
import AwesomeSlider from "react-awesome-slider";
import { makeStyles } from "@material-ui/core";
import { HoverActions } from "../../PlatoonContext";
import Loading from "../../../../shared/Loading";

const useStyles = makeStyles(() => ({
  reactPlayerContainer: {
    position: "relative",
    paddingTop: "56.25%",
    height: "0",
    width: "100%",
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  wrapper: {
    margin: "20px 0",
  },
}));

interface VideoURLProps {
  assets: AssetBlock[] | undefined;
  hoverActions: HoverActions;
}

const CarouselRenderer = ({ assets, hoverActions }: VideoURLProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const assetLoaded = () => {
    setLoading(false);
  };

  let images = assets?.map((asset: AssetBlock, index: number) => (
    // IMO IDs should never be optional, and since this is, we can't rely on them for a key prop.
    // had to use index, which is not preferred
    <div key={index} className={classes.reactPlayerContainer}>
      <ReactPlayer
        controls={true}
        onReady={assetLoaded}
        width="100%"
        height="100%"
        className={classes.reactPlayer}
        url={
          asset.assetPickerSource === AssetPickerSource.url
            ? asset.asset?.attachment
            : `/api/v1/signed-assets/${asset.asset?.id}`
        }
      />
    </div>
  ));

  return (
    <div className={classes.wrapper}>
      <Hover actions={hoverActions}>
        <div>
          <div style={{ display: loading ? "block" : "none" }}>
            <Loading height="80px" />
          </div>
          <div
            style={{
              display: loading ? "none" : "block",
            }}
          >
            <AwesomeSlider
              mobileTouch={true}
              buttons={true}
              organicArrows={true}
            >
              {images}
            </AwesomeSlider>
          </div>
        </div>
      </Hover>
    </div>
  );
};

interface CarouselBlockRendererProps {
  assetCollectionBlock?: AssetCollectionBlock; //used to be src, let's name it something really readable and specific to this module
  hoverActions: HoverActions;
}

function CarouselBlockRenderer({
  assetCollectionBlock,
  hoverActions,
}: CarouselBlockRendererProps) {
  return (
    <React.Fragment>
      <CarouselRenderer
        assets={assetCollectionBlock?.assetBlocks}
        hoverActions={hoverActions}
      />
    </React.Fragment>
  );
}

export default CarouselBlockRenderer;
