import React from "react";
import IframeBlock, { IframeModel } from "./IframeBlock";
import { HoverActions } from "../../PlatoonContext";
import Hover from "../../Hover";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  iframeContainer: {
    textAlign: "center",
    width: "100%",
  },
  iframeSize: {
    width: "100%",
  },
}));

interface Props {
  iframeBlock?: IframeBlock; //used to be src, let's name it something really readable and specific to this module
  hoverActions: HoverActions;
}

interface IframeURLProps {
  iframe: IframeModel | undefined;
}

const IframeURLRenderer = ({ iframe }: IframeURLProps) => {
  const classes = useStyles();

  return (
    <div className={classes.iframeSize}>
      <iframe
        title="form"
        frameBorder="0"
        width="80%"
        height="450px"
        src={iframe?.url}
      />
    </div>
  );
};

interface IframeCodeProps {
  iframe: IframeModel | undefined;
}

const IframeCodeRenderer = ({ iframe }: IframeCodeProps) => {
  return (
    <div>
      <div>
        {iframe?.code && (
          <div dangerouslySetInnerHTML={{ __html: iframe.code }} />
        )}
      </div>
    </div>
  );
};

function IframeBlockRenderer({ iframeBlock, hoverActions }: Props) {
  const classes = useStyles();

  return (
    <Hover actions={hoverActions}>
      <Box className={classes.iframeContainer}>
        {iframeBlock?.type === "url" && (
          <IframeURLRenderer iframe={iframeBlock.urlIframe} />
        )}
        {iframeBlock?.type == "code" && (
          <IframeCodeRenderer iframe={iframeBlock.codeIframe} />
        )}
      </Box>
    </Hover>
  );
}

export default IframeBlockRenderer;
