import React, { useState } from "react";
import { ReactComponent as DownloadIcon } from "../../shared/icons/download.svg";
import { makeStyles, Theme } from "@material-ui/core";
import ArtistLink, { ArtistAudioDownloadButton } from "../models/ArtistLink";
import LockDownPortal from "./LockDownPortal";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { ArtistLinkAction, useAnalyticsContext } from "../../providers/AnalyticsProvider";
import { useErrorHandlingContext } from "../../providers/ErrorHandlingProvider";
import MenuOptions from "../../shared/indexCards/MenuOptions";
import axios from "axios";
import utf8 from "utf8";
import base64 from "base-64";

type Props = {
  artistLink: ArtistLink;
  preview: boolean;
  fontColor: string;
  isMobile: boolean;
};

type StyleProps = {
  buttonColor: string | undefined;
  fontColor: string | undefined;
  fontFamily: string | undefined;
  isMobile: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  portalContainer: {
    display: "flex",
    position: "absolute",
    left: "50%",
    top: "50%",
    backgroundColor: "black",
    transform: "translate(-50%, -50%)"
  },
  iconButton: {
    float: "right",
    left: "15px",
    bottom: "15px",
    backgroundColor: theme.palette.secondary.light
  },
  loaderContainer: {
    marginBottom: "25px"
  },
  downloadButtonContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px",
    paddingRight: "12px",
    maxHeight: "20px"
  },
  downloadButtonWrapper: {
    cursor: "pointer"
  },
  circle: {
    backgroundColor: ({ buttonColor }: StyleProps) => buttonColor,
    height: "32px",
    width: "32px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  square: {
    backgroundColor: ({ buttonColor }: StyleProps) => buttonColor,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "32px",
    width: "32px",
    borderRadius: "5px"
  },
  downloadText: {
    color: ({ fontColor }: StyleProps) => fontColor,
    marginRight: "5px",
    fontFamily: ({ fontFamily }: StyleProps) => fontFamily,
    fontSize: ({ isMobile }: StyleProps) => (isMobile ? "2vh" : "24px")
  }
}));

export async function zipTracks(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setShowPortal: React.Dispatch<React.SetStateAction<boolean>>,
  errorDispatch: any,
  artistLink: ArtistLink,
  type: "wav" | "mp3",
  password: string
) {
  // lets just bail if there are no assets
  if (!artistLink.tracks) return;

  setLoading(true);

  let path = `/api/v1/viewer/links/artists/${artistLink.artist.slug}/${artistLink.slug}/${artistLink.shortId}/download-tracks-zip?type=${type}`;

  if (password) {
    // need to encode our string in utf8 in case it has extended latin letters like ' à '
    const bytes = utf8.encode(password);
    const encodedPassword = base64.encode(bytes);
    path += `&password=${encodedPassword}`;
  }

  axios({
    url: path,
    method: "GET",
    responseType: "blob"
  }).then(results => {
    let hidden_a = document.createElement("a");
    hidden_a.href = window.URL.createObjectURL(new Blob([results.data]));
    hidden_a.setAttribute("download", `Tracks.zip`);
    document.body.appendChild(hidden_a);
    hidden_a.click();
    // done, close up
    setShowPortal(false);
  }).catch(e => {
    if (e.response.status === 403) {
      errorDispatch({
        type: "SET_ERROR",
        payload: {
          error: {
            reasons: { message: "Incorrect Password" }
          }
        }
      });
    } else {
      console.error("Download failed:\n", e);
      errorDispatch({
        type: "SET_ERROR",
        payload: {
          error: {
            reasons: { message: "There was an error downloading your files." }
          }
        }
      });
    }
  }).finally(() => {
    setLoading(false);
  });
}

const ArtistAudioDownload = ({ artistLink, preview, fontColor, isMobile }: Props) => {
  const { trackArtistLink } = useAnalyticsContext();
  const { errorDispatch } = useErrorHandlingContext();

  const [downloadMp3, setDownloadMp3] = useState<boolean>(false);

  const { setLoading } = useLoadingContext();
  const [showPortal, setShowPortal] = useState(false);
  const buttonColor = artistLink.audioDownloadButtonColor;
  const fontFamily = artistLink.headlineFont;
  const classes = useStyles({ buttonColor, fontColor, fontFamily, isMobile });

  const hasMp3Available = artistLink.tracks?.some(
    (track) => track.derivatives?.mp3Kbps192
  );

  const renderDownloadButton = () => {
    switch (artistLink.audioDownloadButton) {
      case ArtistAudioDownloadButton.circle:
        return (
          <div className={classes.circle}>
            <DownloadIcon transform={"scale(.8)"} color={fontColor} />
          </div>
        );
      case ArtistAudioDownloadButton.square:
        return (
          <div className={classes.square}>
            <DownloadIcon transform={"scale(.8)"} color={fontColor} />
          </div>
        );
      case ArtistAudioDownloadButton.transparent:
        return (
          <div>
            <DownloadIcon color={fontColor} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.downloadButtonContainer}>
      {hasMp3Available ? (
        <MenuOptions
          icon={renderDownloadButton()}
          options={[
            {
              text: "Download .mp3",
              textColor: "#ffffff",
              action: () => {
                !preview && setShowPortal(true), setDownloadMp3(true);
              }
            },
            {
              text: "Download .wav",
              textColor: "#ffffff",
              action: () => {
                !preview && setShowPortal(true), setDownloadMp3(false);
              }
            }
          ]}
        />
      ) : (
        <div
          className={classes.downloadButtonWrapper}
          onClick={(event: any) => {
            event.preventDefault(), !preview && setShowPortal(true);
          }}
        >
          {renderDownloadButton()}
        </div>
      )}
      {showPortal && (
        <LockDownPortal
          setShowPortal={setShowPortal}
          onSubmit={(userPassword) => {
            if (artistLink.tracks) {
              zipTracks(
                setLoading,
                setShowPortal,
                errorDispatch,
                artistLink,
                downloadMp3 ? "mp3" : "wav",
                userPassword
              );
              trackArtistLink(artistLink, ArtistLinkAction.assetDownloaded);
            }
          }}
        />
      )}
    </div>
  );
};

export default ArtistAudioDownload;
