import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import utf8 from "utf8";
import base64 from "base-64";
import { TemplatePage, PatchTemplatePage } from "../types/TemplatePages";

import {
  PaginatedTemplatePages,
  TemplatePageAdaptor,
  PaginatedTemplatePagesAdaptor,
} from "../types/TemplatePages";
import RockDove from "../../shared/RockDove";
import { UserRole } from "../../shared/ResourceSchema";

const BASE_PATH = `/api/v1/artists`;
const PUBLIC_BASE_PATH = "/api/v1/viewer/template-pages";

const apiCall = (endpoint: string, headers: any) => {
  return fetch(endpoint, {
    method: "GET",
    credentials: "include",
    mode: "cors",
    headers,
  }).then((response) => {
    if (!response.ok) throw new Error(response.statusText);
    else return response.json();
  });
};

const publicApiCall = (endpoint: string) => {
  return fetch(endpoint, {
    method: "GET",
    credentials: "include",
    mode: "cors",
  }).then((response) => {
    if (!response.ok) throw new Error(response.status.toString());
    else return response.json();
  });
};

const getTemplatePages = (page: number = 1, limit: number | undefined = 10) => {
  const { artistId } = useParams();
  const endpoint = `${BASE_PATH}/${artistId}/template-pages?page=${page}limit=${limit}`;
  const response = useQuery<PaginatedTemplatePages>(
    ["template-pages", page],
    async () => {
      const authHeaders = await RockDove.shared().getHeaders(UserRole.Artist);
      return apiCall(endpoint, authHeaders).then(
        PaginatedTemplatePagesAdaptor.toFrontend
      );
    }
  );
  return { ...response };
};

const getTemplatePage = (
  artistId: string | undefined,
  templatePageId: string | undefined,
  isExistingTemplatePage: boolean
) => {
  const endpoint = `${BASE_PATH}/${artistId}/template-pages/${templatePageId}`;
  const goodId = isExistingTemplatePage ? templatePageId : undefined;

  const response = useQuery<TemplatePage>( //fix typings, we receive the template config as a json string.  we need a second typing
    ["template_page", goodId],
    async () => {
      const authHeaders = await RockDove.shared().getHeaders(UserRole.Artist);
      return apiCall(endpoint, authHeaders).then(
        TemplatePageAdaptor.toFrontend
      );
    },
    {
      enabled: !!goodId,
      retry: false,
      // refetchOnWindowFocus: false, // removed so when you leave the window and come back it will make sure you have the latest (used for preview too)
      refetchOnMount: true,
      // refetchOnReconnect: false, // removed these so when you exit to the index and come back it refetches
      // staleTime: Infinity, // removed these so when you exit to the index and come back it refetches
    }
  );
  return { ...response };
};

const getTemplatePagePublic = (
  templatePageSlug: string | undefined,
  password: string | undefined
) => {
  const bytes = utf8.encode(password || "");
  const encodedPassword = base64.encode(bytes);

  const endpoint = `${PUBLIC_BASE_PATH}/${templatePageSlug}${
    password ? `?password=${encodedPassword}` : ""
  }`;
  const response = useQuery<TemplatePage, Error>(
    ["template-pages", templatePageSlug],
    () => publicApiCall(endpoint).then(TemplatePageAdaptor.toFrontend),
    {
      enabled: !!templatePageSlug,
      retry: false,
      refetchOnWindowFocus: true,
      // refetchOnReconnect: false,
    }
  );
  return { ...response };
};

const createTemplatePageFn = async (
  artistId: string,
  templatePage: TemplatePage
) => {
  const body = TemplatePageAdaptor.toBackend(templatePage);
  const url = `${BASE_PATH}/${artistId}/template-pages`;
  const authHeaders = await RockDove.shared().getHeaders(UserRole.Artist);
  const headers: any = {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      ...authHeaders,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    referrerPolicy: "no-referrer",
  };

  return fetch(url, headers);
};

const updateTemplatePageFn = async (
  artistId: string,
  templatePage: PatchTemplatePage, //TODO: type this as a PatchTemplatePage or maybe SparseTemplatePage
  id: string
) => {
  const body = TemplatePageAdaptor.toBackend(templatePage);
  const url = `${BASE_PATH}/${artistId}/template-pages/${id}`;
  const authHeaders = await RockDove.shared().getHeaders(UserRole.Artist);
  const headers: any = {
    method: "PUT",
    mode: "cors",
    credentials: "include",
    headers: {
      ...authHeaders,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    referrerPolicy: "no-referrer",
  };

  return fetch(url, headers);
};

export {
  getTemplatePages,
  getTemplatePage,
  updateTemplatePageFn,
  createTemplatePageFn,
  getTemplatePagePublic,
};
