import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  BlockParam,
  BlockParamMulti,
  ConvertedBlock,
} from "../../../../../asset-generator-lib/composer/models/Block";
import { JSONParseSafely } from "../../../../shared/helpers/helpers";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import { ReactComponent as CopyIcon } from "../../../../shared/icons/square-on-square.svg";
import styles from "./RenderedMetadata.module.scss";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

type Metadata = {
  name: BlockParam<string>;
  upc: BlockParam<string>;
  adamId: BlockParam<string>;
  spotifyUri: BlockParam<string>;
};

const RenderedMetaData = ({ convertedBlock, highlight }: Props) => {
  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  const metadata = convertedBlock?.params?.metadataMulti?.value?.map(
    (multiVals: BlockParamMulti) => {
      return JSONParseSafely(multiVals.value);
    }
  );

  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title || "Metadata"}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      <main className={styles["section"]}>
        {!!metadata?.length &&
          metadata?.map((data: Metadata, idx: number) => {
            return (
              <div key={idx} className={styles["grid-container"]}>
                {Object.values(data).map((dataEntry: any, index: any) => {
                  return (
                    <React.Fragment key={dataEntry.key}>
                      <div
                        className={`${styles["header"]} ${
                          styles[`header-${index + 1}`]
                        } flex-vert-center`}
                      >
                        {dataEntry.name}
                      </div>
                      <div
                        className={`ellipsis ${styles["entry"]} ${
                          styles[`entry-${index + 1}`]
                        }`}
                      >
                        <CopyToClipboard text={dataEntry.value}>
                          <div className={`${styles["entry-value"]} ellipsis`}>
                            <div
                              className={`${styles["text-container"]} ellipsis`}
                            >
                              <div className={`${styles["text"]} ellipsis`}>
                                {dataEntry.value}
                              </div>
                              <div
                                className={`${styles["mobile-copy-icon"]} ellipsis`}
                              >
                                <CopyIcon className={styles["copy-icon"]} />
                              </div>
                            </div>
                            <span className={styles["copy-text"]}>COPY</span>
                          </div>
                        </CopyToClipboard>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            );
          })}
      </main>
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedMetaData;
