import React from "react";
import styles from "./MultiDate.module.scss";

import { ReactComponent as Pencil } from "../../../../../shared/icons/pencil.svg";
import { JSONParseSafely } from "../../../../../shared/helpers/helpers";
import {
  MultiChildProps,
  MultiContainerProps,
} from "../../MultiInput/MultiInput";
import MultiPill from "../../MultiPill/MultiPill";
import { MultiBlockParamPreData } from "../../../../../../asset-generator-lib/composer/models/Block";

const MultiDate = ({
  param,
  setParam,
  editFn,
  deleteFn,
  selectedChildValue,
}: MultiContainerProps) => {
  const sortedByDate = param?.value?.sort(
    (childParam1: any, childParam2: any) => {
      const parsedChild1 = JSONParseSafely(childParam1.value);
      const parsedChild2 = JSONParseSafely(childParam2.value);

      const date1 = new Date(parsedChild1.dateTime.value);
      const date2 = new Date(parsedChild2.dateTime.value);

      //kinda cool way to coerce dates to number :)
      return +date1 - +date2;
    }
  );

  return (
    <>
      {sortedByDate?.map(
        (childParam: MultiBlockParamPreData, index: number) => {
          const childValue =
            JSONParseSafely(childParam.value) || childParam.value;

          return (
            <DateTime
              key={index}
              index={index}
              childParam={childParam}
              childValue={childValue}
              selectedChildValue={selectedChildValue}
              setParam={setParam}
              editFn={editFn}
              deleteFn={deleteFn}
            />
          );
        }
      )}
    </>
  );
};

const DateTime = ({
  childParam,
  selectedChildValue,
  index,
  childValue,
  editFn,
  deleteFn,
}: MultiChildProps) => {
  selectedChildValue;
  index;

  const value = childValue;
  // this will only get used below if it's not a string
  // and since we don't know what it is, we have to keep any
  const firstValue: any = Object.values(value)[0];

  // if value is a string, use it.
  // otherwise it's an obj, and we are going to try the title prop first,
  // if it doesn't exist, try firstValue.value
  let text: string =
    typeof value === "string"
      ? value
      : value?.title?.value || firstValue?.value;

  let date: Date = new Date(value?.dateTime?.value);

  let dateString = date.toLocaleDateString("en-US", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
  });

  if (typeof text !== "string") {
    console.error(`${childParam.name} has a non string value: ${text}`);
    text = "error";
  }

  return (
    <MultiPill
      multiFile={{
        prePend: <p className={styles["index"]}>{dateString}</p>,
        selected: childParam.key === selectedChildValue?.key,
      }}
      remove={() => deleteFn(childParam?.key)}
    >
      <div className={styles["container"]}>
        <p className="ellipsis">{text}</p>
        <button onClick={() => editFn(childParam.key)} className="btn--naked">
          <Pencil className={styles["icon"]} />
        </button>
      </div>
    </MultiPill>
  );
};

export default MultiDate;
