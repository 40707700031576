import React, { useEffect, useContext, createContext, useState } from "react";
import {
  emptyP4aArtist,
  P4aArtist,
  P4aArtistAPI,
  P4aArtistSocialsAPI,
  SocialMedia,
} from "../promo-pack-builder/models/p4a_proxy/P4aArtist";
import RockDove, { ArtistCredentials } from "../shared/RockDove";
import Loading from "../shared/Loading";
import API from "../shared/API";
import ArtistSchema from "../artists/ArtistSchema";
import { Artist } from "../link-builder/models/ArtistLink";

type Props = {
  children: React.ReactNode;
};

type PlatoonArtistContext = {
  p4aArtist: P4aArtist;
  assembleArtist: Artist | undefined;
};

type ContructP4aArtist = {
  artist: P4aArtist;
  socials: SocialMedia[];
};

export const PlatoonArtistContext = createContext<PlatoonArtistContext | null>(
  null
);

export const usePlatoonArtistContext = () => useContext(PlatoonArtistContext)!;

export const PlatoonArtistProvider: React.FC<Props> = ({ children }: Props) => {
  const [p4aArtist, setP4aArtist] = useState<P4aArtist | undefined>();
  const [assembleArtist, setAssembleArtist] = useState<Artist | undefined>();

  useEffect(() => {
    getP4aArtist();
    if (p4aArtist?.id) {
      fetchAssembleArtist();
    }
  }, [p4aArtist?.id]);

  const fetchAssembleArtist = () => {
    p4aArtist?.id &&
      API.read(p4aArtist?.id, ArtistSchema).then((artist: Artist) => {
        setAssembleArtist(artist);
      });
  };

  const constructP4aArtist = ({ artist, socials }: ContructP4aArtist) => {
    const updatedSocialsFormat: any = {};
    artist.socialMedia.forEach((social: SocialMedia) => {
      const matchedSocial = socials.find((artistSocial: any) => {
        return artistSocial.id === social.socialMediaId;
      });
      if (matchedSocial) {
        return (updatedSocialsFormat[matchedSocial.name.toLowerCase()] =
          social.url);
      } else return null;
    });
    artist.socialMedia = updatedSocialsFormat;
    return artist;
  };

  const getP4aArtist = async () => {
    try {
      const creds =
        (await RockDove.shared().getArtistCredentials()) as ArtistCredentials;
      let p4aArtist = await P4aArtistAPI.read(creds.artist.id);
      const p4aArtistSocials = await P4aArtistSocialsAPI.read();

      p4aArtist = constructP4aArtist({
        artist: p4aArtist.results,
        socials: p4aArtistSocials.results,
      });
      p4aArtist.role = await RockDove.shared().getUserCategory();
      setP4aArtist(p4aArtist);
    } catch (e) {
      setP4aArtist(emptyP4aArtist());
      console.log("Could not get artist, providing empty artist (guest)", e);
    }
  };

  if (!p4aArtist) {
    return <Loading height={"100vh"} />;
  }

  return (
    <PlatoonArtistContext.Provider value={{ p4aArtist, assembleArtist }}>
      {children}
    </PlatoonArtistContext.Provider>
  );
};
