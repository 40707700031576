interface ButtonModel {
  title: string;
}

interface LinkButtonModel extends ButtonModel {
  url: string;
}

interface CopyButtonModel extends ButtonModel {
  text: string;
}

interface ButtonBlock {
  type: string;
  linkButtons?: LinkButtonModel[];
  copyButton?: CopyButtonModel;
  warnings?: string[];
}

function emptyButtonBlock() {
  return {
    type: "button",
    linkButtons: [{ title: '', url: '' }],
    copyButton: { title: '', text: '' },
  }
}

export default ButtonBlock;
export { CopyButtonModel, LinkButtonModel, emptyButtonBlock };
