import React, { useEffect, useRef, useState } from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import styles from "./RenderedHeader.module.scss";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import { ReactComponent as PlatoonLogo } from "../../../../shared/icons/platoon-logo.svg";
import {
  idFallthrough,
  isJSON,
  removeHttp,
} from "../../../../shared/helpers/helpers";
import { ArtistSocials } from "../../../../link-builder/models/ArtistLink";
import SocialIcon from "../../../../link-builder/shared/SocialIcons";
import ReactPlayer from "react-player";
import useWindowSize from "../../../../shared/CustomHooks";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

type ChangeHeightProps = {
  ref: any;
  minContentWidth: number;
  maxHeight: number;
};

type HeightWidth = {
  h: number;
  w: number;
};

const resizeVid = (container: { h: number; w: number }) => {
  // using a bit more extreme ratio than 16:9 hardcoded right now.
  // it should cover the most common aspects
  const ratio = 0.527756;
  const containerRatio = container.h / container.w;

  if (container.h > container.w) {
    // container is tall
    return { h: container.h, w: container.h / ratio };
  } else {
    // container is wide
    const adjustedWidth = container.h / ratio;

    if (containerRatio > ratio) {
      return {
        h: container.h,
        w: adjustedWidth,
      };
    } else {
      // container ratio is smaller than 16x9, so we need to use the 16x9
      return {
        h: adjustedWidth / ratio,
        w: container.h / containerRatio,
      };
    }
  }
};

const changeHeight = ({
  ref,
  minContentWidth,
  maxHeight,
}: ChangeHeightProps) => {
  const browserWidth: number = document.body.getBoundingClientRect().width;
  const contentWidth: number =
    ref.current && ref.current.getBoundingClientRect().width;

  const ratio =
    contentWidth <= minContentWidth ? 1 : contentWidth / browserWidth;

  const windowHeight = document.body.getBoundingClientRect().height;

  let height;

  if (contentWidth && contentWidth <= minContentWidth) {
    // we are in a mobile view, and we should limit the height.  no device is going to be 390 x 900+
    height = windowHeight > maxHeight ? maxHeight : windowHeight;
    ref.current.style.height = `calc((${height} * ${ratio}px))`;
  } else {
    height = windowHeight;
  }

  if (ref.current && windowHeight && ratio < 1) {
    ref.current.style.height = `calc((${height} * ${ratio}px))`;
  }
};

const RenderedHeader = ({ convertedBlock, highlight }: Props) => {
  const windowSize = useWindowSize();
  const videoContainerRef = useRef<any>(null);
  const sectionWrapperRef = useRef<any>(null);
  const [onReady, setOnReady] = useState(false);
  const [containerSize, setContainerSize] = useState<HeightWidth>({
    h: 0,
    w: 0,
  });

  const maxHeight = 900;
  const minContentWidth = 390;

  // abstracted variables
  const textColor =
    convertedBlock?.params?.textColorOverride?.value?.type === "COLOR" &&
    convertedBlock?.params?.textColorOverride?.value?.value;

  const backgroundValue = convertedBlock?.params?.background?.value;

  const backgroundColor: string =
    backgroundValue?.key === "color" ? backgroundValue?.value : "inherit";

  // when we refactored the fileInput, the data form changed slightly.  this allows the old and new
  // const backgroundImgThumbnail =
  //   backgroundValue?.key === "file" &&
  //   backgroundValue?.value &&
  //   backgroundValue?.value[0]
  //     ? backgroundValue?.value[0]?.thumbnail ||
  //       backgroundValue?.value[0]?.asset?.thumbnail
  //     : "";

  // const backgroundImage =
  //   backgroundValue?.key === "file" &&
  //   backgroundValue?.value &&
  //   backgroundValue?.value[0]
  //     ? backgroundValue?.value[0]?.attachment ||
  //       backgroundValue?.value[0]?.asset?.attachment
  //     : "";

  // old data format, stores the asset directly instaed of an asset subkey
  const backgroundImageId: string | undefined = idFallthrough(
    backgroundValue?.value[0]
  );

  // backgroundValue?.key === "file" && backgroundValue.value[0]?.asset?.id;

  const videoRegex = new RegExp(
    "^(https?://)?(www.youtube.com|m.youtube.com|youtu.be)/.+$"
  );
  const backgroundVideo =
    backgroundValue?.key === "video" &&
    videoRegex.test(backgroundValue?.value) &&
    backgroundValue?.value;

  const artistName =
    convertedBlock.params.artistName?.value?.key === "textName" &&
    convertedBlock.params.artistName?.value?.value;

  const artistLogo =
    convertedBlock.params.artistName?.value?.key === "logoName" &&
    convertedBlock.params.artistName?.value;

  const logoId = artistLogo?.value && idFallthrough(artistLogo);

  const artistSocials = isJSON(convertedBlock.params.artistSocials.value);
  const artistShortLink = convertedBlock.params.shortLink.value;

  useEffect(() => {
    if (videoContainerRef?.current?.offsetHeight) {
      setContainerSize({
        h: videoContainerRef.current.offsetHeight,
        w: videoContainerRef.current.offsetWidth, // maybe window width?
      });
    }
  }, [videoContainerRef, windowSize, onReady]);

  useEffect(() => {
    if (sectionWrapperRef.current) {
      changeHeight({
        ref: sectionWrapperRef,
        maxHeight,
        minContentWidth,
      });
    }
  }, [sectionWrapperRef, windowSize]);

  // JSX
  const renderedArtistSocials = Object.entries(artistSocials).map(
    (artistSocial) => {
      const artistSocialKey = artistSocial[0] as keyof ArtistSocials;
      const artistSocialValue = artistSocial[1] as any;

      if (artistSocialKey && artistSocialValue.value) {
        return (
          <a
            key={artistSocialKey}
            href={artistSocialValue.value}
            target="_blank"
            rel="noreferrer"
            className={styles["social-anchor"]}
          >
            <SocialIcon
              className={styles["social-icon"]}
              icon={artistSocialKey}
            />
          </a>
        );
      } else return null;
    }
  );

  return (
    <div
      className={styles["section-wrapper"]}
      style={{
        ...(backgroundImageId && {
          backgroundImage: `url('/api/v1/signed-assets/${backgroundImageId}'), url('/api/v1/signed-assets/${backgroundImageId}/small')`,
        }),
      }}
      ref={sectionWrapperRef}
    >
      {backgroundVideo && (
        <div ref={videoContainerRef} className={styles["player-wrapper"]}>
          <ReactPlayer
            className={styles["react-player"]}
            width={`${resizeVid(containerSize).w}px`}
            height={`${resizeVid(containerSize).h}px`}
            url={backgroundVideo}
            muted
            loop
            playing={true}
            controls={false}
            onReady={() => setOnReady(true)}
            onError={(e) => {
              console.log("ON ERROR: ", e);
            }}
            config={{
              youtube: {
                playerVars: {
                  // want to play with this maybe will fix youtube cors problem for safari
                  // origin: null,
                  // this should hide annotations on youtube videos
                  iv_load_policy: 3,
                },
              },
            }}
            //prevents safari from blinking white when the player is still loading
            style={{ visibility: onReady ? "visible" : "hidden" }}
          />
        </div>
      )}
      <TemplatePageRenderedSectionWrapper
        hideHeading
        title={convertedBlock.title}
        highlight={highlight}
        styleOverride={{
          backgroundColor: backgroundColor || "transparent",
          ...(textColor && { color: textColor }),
          // height: "100%",  //this was making it 100% + padding.
          // but since we are absolute positioning and setting top + bottom to 0, its not needed
          position: "absolute",
          zIndex: 999,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <div className={styles["inner-section-wrapper"]}>
          <main className={styles.section} style={{}}>
            <div className={styles["platoon-logo-container"]}>
              <PlatoonLogo className={styles["platoon-logo"]} />
            </div>
            <div>
              {logoId && (
                <img
                  className={styles["artist-name-logo"]}
                  src={`/api/v1/signed-assets/${logoId}/medium`}
                />
              )}
              {artistName && (
                <h1 className={styles["artist-header"]}>{artistName}</h1>
              )}
              <div className={styles["artist-socials"]}>
                {renderedArtistSocials}
              </div>
              <a
                target="_blank"
                className={styles["short-link-anchor"]}
                href={artistShortLink}
              >
                <h2 className={styles["short-link-text"]}>
                  {removeHttp(artistShortLink)}
                </h2>
              </a>
            </div>
          </main>
        </div>
      </TemplatePageRenderedSectionWrapper>
    </div>
  );
};

export default RenderedHeader;
