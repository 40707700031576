import ResourceSchema, {
  emptyResourceSchema,
  UserRole,
} from "../shared/ResourceSchema";
import {
  adaptArtistLinkToFrontend,
  adaptArtistLinkToBackend,
} from "../artist-links/ArtistLinkSchema";

// TODO: remove Adapters?
function adaptToBackend(frontendObject: any) {
  var target = Object.assign({}, frontendObject);

  if (target.links) {
    target.links = frontendObject.links.map((frontendArtistLink: any) =>
      adaptArtistLinkToBackend(frontendArtistLink)
    );
  }

  return target;
}

function adaptToFrontend(backendObject: any) {
  var target = Object.assign({}, backendObject);

  if (target.links) {
    target.links = target.links.map((backendArtistLink: any) =>
      adaptArtistLinkToFrontend(backendArtistLink, 1)
    );
  }

  return target;
}

function adaptTrackToFrontend(backendTrack: any) {
  return backendTrack;
}

function adaptTracksToFrontend(backendObjects: any) {
  const frontendObjects = backendObjects.map(adaptTrackToFrontend);
  return frontendObjects;
}

function adaptTrackLinksToFrontend(backendObject: any) {
  return backendObject;
}

const ArtistSchema: ResourceSchema = {
  ...emptyResourceSchema(),
  ...{
    title: "Artist",
    pluralTitle: "Artists",
    rootPath: "artists",
    apiRootPath: "artists",
    attrs: ["name", "linkInfo"],
    adaptor: {
      toFrontend: adaptToFrontend,
      toBackend: adaptToBackend,
    },
    role: UserRole.Artist,
  },
};

export default ArtistSchema;
export {
  adaptToBackend,
  adaptToFrontend,
  adaptTracksToFrontend,
  adaptTrackLinksToFrontend,
  adaptTrackToFrontend,
};
