import { ConvertedBlock } from "../../../../asset-generator-lib/composer/models/Block";
import { TemplatePageDraft } from "../../types/TemplatePages";

export const launchDateTemplateConfig = (
  templatePageDraft: TemplatePageDraft
): ConvertedBlock => {
  return {
    key: "launchDate",
    type: "TEXT",
    title: "Launch Date",
    required: true,
    params: {
      launchDate: {
        key: "startDate",
        name: "Start Date",
        type: "RADIONOLABEL",
        options: [
          {
            key: "Scheduled",
            name: "Scheduled",
            type: "DATETIME",
            value:
              templatePageDraft.startDate ||
              new Date(new Date().setHours(24, 0, 0, 0)), // looks dumb, but setHours returns UTC milliseconds (so annoying), and the date input requires type of Date.
            validations: {
              type: "date",
              params: [
                templatePageDraft.endDate
                  ? {
                      validation: "max",
                      limit: templatePageDraft.endDate,
                      message: "Must be before expire date",
                    }
                  : null,
              ],
            },
          },
          {
            key: "immediately",
            name: "immediately",
            type: "STATIC",
            value: null,
          },
        ],
        value: templatePageDraft.startDate
          ? {
              key: "Scheduled",
              name: "Scheduled",
              type: "DATETIME",
              value: templatePageDraft.startDate,
              validations: {
                type: "date",
                params: [
                  templatePageDraft.endDate
                    ? {
                        validation: "max",
                        limit: templatePageDraft.endDate,
                        message: "Must be before expire date",
                      }
                    : null,
                ],
              },
            }
          : {
              key: "immediately",
              name: "immediately",
              type: "STATIC",
              value: null,
            },
      },
      hr: { key: "dividerTest", name: "", type: "DIVIDER" },

      expirationDate: {
        key: "expirationDate",
        name: "expiration Date",
        type: "RADIONOLABEL",
        options: [
          {
            key: "Expires",
            name: "Expires",
            type: "DATETIME",
            value:
              templatePageDraft.endDate ||
              new Date(
                new Date(
                  new Date().setFullYear(new Date().getFullYear() + 1)
                ).setHours(24, 0, 0, 0)
              ), // need to set to midnight as well
            validations: {
              type: "date",
              params: [
                templatePageDraft.startDate
                  ? {
                      validation: "min",
                      limit: templatePageDraft.startDate,
                      message: "Must be after start date",
                    }
                  : null,
              ],
            },
          },
          {
            key: "neverExpires",
            name: "Never Expires",
            type: "STATIC",
            value: null,
          },
        ],
        value: templatePageDraft.endDate
          ? {
              key: "Expires",
              name: "Expires",
              type: "DATETIME",
              value: templatePageDraft.endDate,
              validations: {
                type: "date",
                params: [
                  templatePageDraft.startDate
                    ? {
                        validation: "min",
                        limit: templatePageDraft.startDate,
                        message: "Must be after start date",
                      }
                    : null,
                ],
              },
            }
          : {
              key: "neverExpires",
              name: "Never Expires",
              type: "STATIC",
              value: null,
            },
      },
    },
  };
};

export const siteNameTemplateConfig = (
  templatePageDraft: TemplatePageDraft
): ConvertedBlock => {
  return {
    key: "siteName",
    type: "TEXT",
    title: "site name",
    required: true,
    params: {
      siteName: {
        key: "siteName",
        name: "site name",
        type: "TEXT",
        value: templatePageDraft.name || "",
        validations: {
          type: "string",
          params: [{ validation: "required", message: "Field is required" }],
        },
      },
    },
  };
};

export const sitePasswordTemplateConfig = (
  templatePageDraft: TemplatePageDraft
): ConvertedBlock => {
  return {
    key: "sitePassword",
    type: "TEXT",
    title: "site password",
    required: true,
    params: {
      sitePassword: {
        key: "sitePassword",
        name: "SitePassword",
        type: "RADIONOLABEL",
        options: [
          {
            key: "on",
            name: "on",
            type: "PASSWORD",
            value: templatePageDraft.password || "",
            validations: {
              type: "string",
              params: [
                {
                  validation: "required",
                  message: "Field is required",
                },
              ],
            },
          },
          {
            key: "off",
            name: "off",
            type: "STATIC",
            value: null,
          },
        ],
        value:
          templatePageDraft.password || templatePageDraft.password === ""
            ? {
                key: "on",
                name: "on",
                type: "PASSWORD",
                value: templatePageDraft.password,
                validations: {
                  type: "string",
                  params: [
                    {
                      validation: "required",
                      message: "Field is required",
                    },
                  ],
                },
              }
            : {
                key: "off",
                name: "off",
                type: "STATIC",
                value: null,
              },
      },
    },
  };
};
