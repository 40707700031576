import React from "react";
import { Sprite, withFilters, Container } from "@inlet/react-pixi";
import Block, { BlockParam, BlockUtils } from "../../models/Block";
import DraggableBlock from "./DraggableBlock";
import * as PIXI from "pixi.js";
import { TemplateEditorState } from "../../TemplateEditorState";

interface Props {
  block: Block;
  parentView: any;
  readOnly: boolean;
  editorState: TemplateEditorState;
  setEditorState: React.Dispatch<React.SetStateAction<TemplateEditorState>>;
  selectBlocks: any;
  selectBlock: any;
  loader: any;
}

const blockValue = (value: any) => {
  if (value?.charAt(0) === "/") return value;
  else return `${value}?cacheblock=true`;
};

export const addSpriteBlockResources = (
  params: BlockParam,
  childBlock: Block,
  pixiLoader: any
) => {
  if (
    (params.key === "image" ||
      params.key === "texture" ||
      params.key === "background") &&
    childBlock.type === "SPRITE"
  ) {
    let blockKey =
      childBlock.id &&
      BlockUtils.keyForBlockLoader(
        childBlock.id,
        params.key,
        params.value?.name || params.name
      );
    if (blockKey && !pixiLoader?.resources[blockKey] && childBlock.params) {
      pixiLoader?.add(
        blockKey,
        blockValue(BlockUtils.paramValueForKey(params.key, childBlock.params))
      );
    }
  }
};

const SpriteBlockRenderer = (props: Props) => {
  const Filters = withFilters(Container, {
    blur: PIXI.filters.BlurFilter,
  });

  const color = BlockUtils.paramValueForKey("color", props.block.params);
  const spriteColor = PIXI.utils.string2hex(color?.toString());

  const background = BlockUtils.paramValueForKey(
    "background",
    props.block.params
  );

  const backgroundStyle = BlockUtils.paramStyleForKey(
    "background",
    props.block.params
  );
  const backgroundColor = PIXI.utils.string2hex(background?.toString());

  const optionalColor = PIXI.utils.string2hex(
    backgroundStyle?.background?.color
  )
    ? PIXI.utils.string2hex(backgroundStyle?.background?.color)
    : undefined;

  const blurStrength = backgroundStyle?.filter?.blur
    ? backgroundStyle?.filter?.blur
    : 0;
  const blurQuality = backgroundStyle?.filter?.quality
    ? backgroundStyle?.filter?.quality
    : 1;
  const blurResolution = backgroundStyle?.filter?.resolution
    ? backgroundStyle?.filter?.resolution
    : 1;

  const imageName = BlockUtils.paramNameForKey("image", props.block.params);
  const textureName = BlockUtils.paramNameForKey("texture", props.block.params);
  const backgroundName = BlockUtils.paramNameForKey(
    "background",
    props.block.params
  );

  const imageKey =
    props.block.id &&
    BlockUtils.keyForBlockLoader(props.block.id, "image", imageName);
  const textureKey =
    props.block.id &&
    BlockUtils.keyForBlockLoader(props.block.id, "texture", textureName);
  const backgroundKey =
    props.block.id &&
    BlockUtils.keyForBlockLoader(props.block.id, "background", backgroundName);

  const imageResource = imageKey && props.loader?.resources[imageKey]?.url;
  const textureResource =
    textureKey && props.loader?.resources[textureKey]?.url;
  const backgroundResource =
    backgroundKey && props.loader?.resources[backgroundKey]?.url;

  return (
    <DraggableBlock {...props}>
      <Filters
        blur={{
          blur: blurStrength,
          quality: blurQuality,
          resolution: blurResolution,
          autoFit: true,
          repeatEdgePixels: false,
        }}
      >
        {!isNaN(backgroundColor) && props.block.frame && (
          <Sprite
            anchor={
              [props.block.frame.anchor?.x, props.block.frame.anchor?.y] as any
            }
            texture={PIXI.Texture.WHITE}
            tint={backgroundColor}
            width={props.block.frame.size?.w}
            height={props.block.frame.size?.h}
          />
        )}
        {!isNaN(spriteColor) && isNaN(backgroundColor) && props.block.frame && (
          <Sprite
            anchor={
              [props.block.frame.anchor?.x, props.block.frame.anchor?.y] as any
            }
            texture={PIXI.Texture.WHITE}
            tint={spriteColor}
            width={props.block.frame.size?.w}
            height={props.block.frame.size?.h}
          />
        )}
        {optionalColor && props.block.frame && (
          <Sprite
            anchor={
              [props.block.frame.anchor?.x, props.block.frame.anchor?.y] as any
            }
            texture={PIXI.Texture.WHITE}
            tint={optionalColor}
            width={props.block.frame.size?.w}
            height={props.block.frame.size?.h}
          />
        )}
        {backgroundResource && props.block.frame && (
          <Sprite
            anchor={
              [props.block.frame.anchor?.x, props.block.frame.anchor?.y] as any
            }
            texture={PIXI.Texture.from(backgroundResource)}
            width={props.block.frame.size?.w}
            height={props.block.frame.size?.h}
          />
        )}
        {imageResource && props.block.frame && (
          <Sprite
            anchor={
              [props.block.frame.anchor?.x, props.block.frame?.anchor?.y] as any
            }
            texture={PIXI.Texture.from(imageResource)}
            width={props.block.frame.size?.w}
            height={props.block.frame.size?.h}
          />
        )}
      </Filters>
      {textureResource && props.block?.frame && (
        <Sprite
          width={props.block.frame.size?.w}
          height={props.block.frame.size?.h}
          texture={PIXI.Texture.from(textureResource)}
          anchor={props.block.frame.anchor}
        />
      )}
    </DraggableBlock>
  );
};

export default SpriteBlockRenderer;
