import React, { ReactElement, useEffect, useState } from "react";
import styles from "./PaginatedIndexSection.module.scss";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getPaginated } from "../queries/useGeneric";
import Loading from "../Loading";
import Pagination from "../Pagination/Pagination";
import { GenericPaginatedResponse, SortOption } from "../types/SharedTypes";

type Props = {
  headerTitle: string;
  linkObj: {
    buttonText: string;
    emptyText: string;
    to: string;
  };
  endpoint: string;
  cardElement: ReactElement;
  perPage?: number;
  emptyElement: ReactElement;
  sort: SortOption;
};

const PaginatedIndexSection = ({
  headerTitle,
  linkObj,
  endpoint,
  cardElement,
  perPage = 10,
  emptyElement,
  sort = { attr: "updatedAt", order: "DESC" },
}: Props) => {
  // this component has a desktop and mobile view,
  // but the mobile view used in TemplatePageIndex
  // uses a different component. leaving mobile stuff
  // in here for other uses in the future

  let [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(
    parseInt(searchParams.get(`${endpoint}_page`) || "1") || 1
  );
  const { artistId } = useParams();

  // query data
  const { isLoading, data } = getPaginated<GenericPaginatedResponse>(
    endpoint,
    artistId || "",
    perPage,
    page,
    sort
  );

  useEffect(() => {
    searchParams.set(`${endpoint}_page`, page.toString());
    setSearchParams(searchParams);
  }, [page]);

  const onChangePage = (newPage: number) => {
    if (newPage != page) setPage(newPage);
  };

  const cards = data?.results?.map((item: any) =>
    React.cloneElement(cardElement, {
      data: item,
      key: item.id || item._id, // we need to update some of the models to send back the id, not the _id.
    })
  );

  const emptyCard = React.cloneElement(emptyElement, {
    linkObj,
  });

  if (!data && !isLoading) return <div>Error, no data and not loading</div>;

  return (
    <section className={styles["section"]}>
      <header>
        <h4> {headerTitle}</h4>
        <Link
          to={linkObj.to}
          className={`btn ${styles["btn"]} ${styles["btn-top"]}`}
        >
          Create {linkObj.buttonText}
        </Link>
      </header>

      <div className={styles["card-container"]}>
        {isLoading ? (
          <div>
            <Loading />
          </div>
        ) : (
          cards
        )}
        {!isLoading && data?.pagination?.totalEntries === 0 && emptyCard}
      </div>
      <div className={styles["lower-container"]}>
        <div className={styles["pagination-container"]}>
          {!isLoading &&
          data?.pagination?.totalEntries &&
          data?.pagination?.totalEntries > 0 ? (
            <Pagination onChange={onChangePage} pagination={data?.pagination} />
          ) : null}
        </div>
        <Link
          to={linkObj.to}
          className={`btn ${styles["btn"]} ${styles["btn-bottom"]}`}
        >
          + {linkObj.buttonText}
        </Link>
      </div>
    </section>
  );

  return <div></div>;
};

export default PaginatedIndexSection;
