import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import API from "../shared/API";
import { UserRole } from "../shared/ResourceSchema";
import {
  AppBar,
  Button,
  Box,
  Grid,
  makeStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";
import ReportWidget from "./ReportWidget";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    height: "100px",
  },
  buttonsContainer: {
    height: "100%",
  },
  buttonStyle: {
    width: "100px",
    color: "black",
  },
  csvLink: {
    textDecoration: "none",
    color: "black",
  },
}));

export default function Dashboard() {
  const [reports, setReports] = React.useState<any>();
  const [csvData, setCsvData] = React.useState([[], []]);
  const [loading, setLoading] = React.useState(true);

  const classes = useStyles();

  useEffect(() => {
    fetchCsvData();
    fetchReports();
  }, []);

  async function fetchReports() {
    try {
      const r = await API.fetch("reports", UserRole.Admin);
      const result = await r.json();
      setReports(result);
      setLoading(false);
    } catch (error) {
      console.log("Error", error);
    }
  }

  async function getCsvData() {
    try {
      const r = await API.fetch("reports/csv_metrics", UserRole.Admin);
      const result = await r.json();
      return result;
    } catch (error) {
      console.log("Error", error);
    }
  }

  const fetchCsvData = () => {
    getCsvData().then((result: any) => {
      return setCsvData(result);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m={3} p={3}>
      <Grid container spacing={5} direction="row" alignItems="center">
        {reports.map((report: any) => (
          <Grid item lg={6} key={report.scope_key}>
            <ReportWidget report={report} />
          </Grid>
        ))}
      </Grid>
      <Toolbar />
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Grid
          className={classes.buttonsContainer}
          container
          justify="flex-end"
          alignContent="center"
        >
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="default"
              className={classes.buttonStyle}
            >
              <CSVLink
                className={classes.csvLink}
                filename={"platoon-report.csv"}
                headers={csvData[0]}
                data={csvData[1]}
                asyncOnClick={true}
              >
                DOWNLOAD
              </CSVLink>
            </Button>
          </Grid>
        </Grid>
      </AppBar>
    </Box>
  );
}
