import React from "react";

import ItemIndex, { ItemRenderProps } from "../shared/ItemIndex";
import AssetSchema from "./AssetSchema";
import { defaultActions } from "../shared/ResourceSchema";

import { Grid, Link, Typography } from '@material-ui/core'


function ImageCellContent(props: ItemRenderProps) {
  let icon = props.item[props.attr];
  return (
    <Link href={icon}>
      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>

        <Grid item><img src={icon} style={{ width: 50, height: 50 }} /></Grid>
        <Grid item><Typography style={{ color: "#FFF" }}>{icon}</Typography></Grid>


      </Grid>

    </Link>

  )
}
const renderers = { attachment: ImageCellContent }


const AssetIndex = () => {
  let actions = { ...defaultActions(), ...{ delete: true } };

  return (
    <ItemIndex schema={AssetSchema} renderers={renderers} actions={actions} />
  );
};

export default AssetIndex;

