import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import ArtistLinkInputHeader from "../../LinkEditComponents/ArtistLinkInputHeader";
import ArtistLinkInput from "../../LinkEditComponents/ArtistLinkInput";
import { Update } from "../../ArtistLinkEdit/FormFields";
import { useArtistLinkContext } from "../../../providers/ArtistLinkProvider";

interface Props {
  onChange: any;
  setForceOpenCollapse: any;
}

const AudioDownloadPassword = (props: Props) => {
  const { artistLink, ogArtistLink, setOGArtistLink, dispatch } =
    useArtistLinkContext();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hasPassword, setHasPassword] = useState(false);

  const passwordHeader = hasPassword ? "PASSWORD" : "CREATE PASSWORD";
  const passwordEditable = hasPassword;

  useEffect(() => {
    if (ogArtistLink?.audioDownloadPassword) {
      setHasPassword(true);
    }
  }, [artistLink.audioDownloadPassword]);

  const confirmPasswordChange = (update: Update) => {
    props.setForceOpenCollapse([]);
    setConfirmPassword(update.value);
  };

  const resetPassword = () => {
    setOGArtistLink({
      ...(ogArtistLink as any),
      audioDownloadPassword: null,
    });
    dispatch({
      type: "SET_ARTISTLINK",
      payload: { ...artistLink, audioDownloadPassword: null },
    });
    setHasPassword(false);
  };

  return (
    <>
      {artistLink.requiresAudioDownloadPassword === true && (
        <Box>
          <ArtistLinkInputHeader header={passwordHeader} variant={"h6"} />
          <ArtistLinkInput
            disabled={passwordEditable}
            name={"audioDownloadPassword"}
            id={"audioDownloadPassword"}
            label={"Enter Password"}
            onChange={props.onChange}
            artistLinkKey={artistLink.audioDownloadPassword}
            validators={["required"]}
            errorMessage={["Password is required"]}
            // type="password" // should we set these to password?
          />
          {!hasPassword && (
            <>
              <ArtistLinkInputHeader
                header={"CONFIRM PASSWORD"}
                variant={"h6"}
              />
              <ArtistLinkInput
                name={"confirmPassword"}
                id={"confirmPassword"}
                label={"Confirm Password"}
                onChange={confirmPasswordChange}
                artistLinkKey={confirmPassword}
                validators={["isAudioDownloadPasswordMatch"]}
                errorMessage={["Passwords do not match"]}
                // type="password"  // should we set these to password?
              />
            </>
          )}
        </Box>
      )}
      {hasPassword && artistLink.requiresAudioDownloadPassword && (
        <Box pl={3} pr={3} display="flex" justifyContent="flex-end">
          <Button onClick={resetPassword} variant="contained" color="secondary">
            Reset
          </Button>
        </Box>
      )}
    </>
  );
};

export default AudioDownloadPassword;
