import { ContentNode } from "./ContentProvider";

// I dont think this is needed.

function useBehaviors(node: ContentNode) {
  return new Behaviors(node);
}

export { useBehaviors };

enum CardDisplayType {
  Header = "header",
  Text = "text",
  Icon = "icon",
}

export { CardDisplayType };

export default class Behaviors {
  node: ContentNode;

  constructor(node: ContentNode) {
    this.node = node;
  }

  get allChildrenHaveIcons() {
    let foundUndefinedImage = this.node.children.some((child) => {
      return child.icon === undefined;
    });

    return !foundUndefinedImage;
  }

  get isRoot() {
    return this.node.parent === undefined || this.node.parent === null;
  }

  get cardDisplayType(): string {
    return this.node.cardType || "text";
  }

  // Content needs to be present for it to be shown. Currently node with type page & resources have content to be shown.
  showContent(): boolean {
    if (this.node.content) {
      return true;
    } else {
      return false;
    }
  }

  // Playbook header is unique to categories type
  showPlaybookHeader(): boolean {
    // Playbook header is deprecated, so replacing icon header with showPlatoonHeader
    return false; //this.node.cardType === "icon";
  }

  // Bookmarks already have logic built into them to be displayed where necessary, however that isn't the case with the new resouces type. May need to open up this logic in the future to support bookmarks for resources.
  showBookMarkHeader(): boolean {
    return true; //this.node.cardType !== "header";
  }

  // Unique header style for the Platoon page. Contains resource cards and doesn't have bookmarks.
  showPlatoonHeader(): boolean {
    // Deprecated, see resourceHeader and bookmarkHeader
    return false; //this.node.cardType === "header" || this.node.cardType === "icon";
  }

  // Another unique header similiar without bookmark logic. May change in the future to support bookmarking.
  showResourceHeader(): boolean {
    return !this.node.hasParent();
  }

  // Show cards excludes type page because page contains content. Is called in NodeView conditionally to present cards.
  showCards(): boolean {
    return this.node.type === "section";
  }

  // Type page which contains content has bottom navigation links to move between content pages.
  showContentPageBottomNav(): boolean {
    return this.node.type === "article";
  }

  // Currently the categories type is the home of the collection view for bookmarks. Can be changed in the future to move to another location. I.E type platoon which is the highest level component.
  showBookMarkCollection(): boolean {
    return !this.node.hasParent();
  }
}
