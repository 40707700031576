import React from "react";
import { Routes, Route } from "react-router-dom";
import { NodeEdit, NodeSchema } from "./bundles/nodes/Bundle";
import { AssetIndex, AssetEdit, AssetSchema } from "./bundles/assets/Bundle";
import ArtistIndex from "./bundles/artists/ArtistIndex";
import ArtistSchema from "./bundles/artists/ArtistSchema";
import ItemEdit from "./bundles/shared/ItemEdit";
import TemplateSchema from "./asset-generator-lib/composer/TemplateSchema";
import ArtistLinkSchema from "./bundles/artist-links/ArtistLinkSchema";
import ArtistLinkIndex from "./bundles/artist-links/ArtistLinkIndex";
import ArtistLinkRoutes from "./bundles/artist-links/Routes";
import Dashboard from "./bundles/dashboard/Dashboard";
import TempplateIndex from "./bundles/templates/TemplatesIndex/TemplatesIndex";
import TemplateShow from "./bundles/templates/TemplateShow/TemplateShow";

// document.addEventListener("contextmenu", (event) => event.preventDefault());

export default () => {
  return (
    <Routes>
      <Route path="admin">
        <Route
          path={`${ArtistLinkSchema.rootPath}`}
          element={<ArtistLinkIndex />}
        />

        <Route path={`${ArtistSchema.rootPath}`} element={<ArtistIndex />}>
          <Route path={`:artist_slug/links`} element={<ArtistLinkRoutes />} />
          <Route path={`new`} element={<ItemEdit />} />
          <Route path={`:id`} element={<ItemEdit />} />
        </Route>

        <Route path={`${AssetSchema.rootPath}`} element={<AssetIndex />}>
          <Route path={`new`} element={<AssetEdit />} />
          <Route path={`:id`} element={<AssetEdit />} />
        </Route>

        {/* pages */}

        <Route path={`${NodeSchema.rootPath}`} element={<NodeEdit />}>
          <Route path={`new`} element={<NodeEdit />} />
          <Route path={`:id`} element={<NodeEdit />} />
        </Route>

        <Route
          path={`${TemplateSchema.rootPath}/:id`}
          element={<TemplateShow />}
        />
        <Route
          path={`${TemplateSchema.rootPath}`}
          element={<TempplateIndex />}
        />

        <Route index element={<Dashboard />} />
      </Route>
    </Routes>
  );
};
