import React, { useState, ReactNode, useEffect, useRef } from "react";
import styles from "./TemplatePagePreviewFrame.module.scss";
import { ReactComponent as Iphone } from "../../../shared/icons/iphone.svg";
import { ReactComponent as Desktopcomputer } from "../../../shared/icons/desktopcomputer.svg";
import FrameSizeWrapper from "../../../shared/FrameSizeWrapper";
import { isMobile } from "react-device-detect";

type Props = {
  children: ReactNode;
};

const TemplatePagePreviewFrame = ({ children }: Props) => {
  const previewWidth = 1440;

  const ref = useRef<null | HTMLDivElement>(null);
  // TODO: we'll want to see if we're in the mobile editor or desktop editor to set the init state of isDesktop
  const [isDesktop, setIsDesktop] = useState(!isMobile);
  const [transform, setTransform] = useState(1);

  useEffect(() => {
    const resizeFunc = () => {
      if (ref.current) {
        const container = ref.current.scrollWidth;
        const ratio = container < previewWidth ? container / previewWidth : 1;
        setTransform(+ratio.toFixed(3));
      }
    };
    resizeFunc();
    window.addEventListener("resize", resizeFunc);

    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  return (
    <div className={styles.outerContainer}>
      <div ref={ref}>
        {/* using this div to measure the available space to do the scaling  */}
      </div>
      <div className={styles.headingContainer}>
        <h1 className={styles.headerText}>Page Preview </h1>
        <div>
          <button className="btn--naked" onClick={() => setIsDesktop(true)}>
            <Desktopcomputer
              className={` ${styles.icon} ${isDesktop && styles.iconSelected}`}
            />
          </button>

          <button className="btn--naked" onClick={() => setIsDesktop(false)}>
            <Iphone
              className={` ${styles.icon} ${!isDesktop && styles.iconSelected}`}
            />
          </button>
        </div>
      </div>
      {isDesktop ? (
        <div className={styles.innerContainer}>
          <div
            style={{
              transform: `scale(${transform})`,
              transformOrigin: "top",
            }}
          >
            <div
              style={{
                width: `${previewWidth}px`,
              }}
            >
              {children}
            </div>
          </div>
        </div>
      ) : (
        <div className={`${styles.innerContainerMobile}`}>
          <FrameSizeWrapper>
            <div id="frame-root">{children}</div>
          </FrameSizeWrapper>
        </div>
      )}
    </div>
  );
};

export default TemplatePagePreviewFrame;
