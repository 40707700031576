import React, { useState, useEffect } from "react";
import { AssetPickerSource, AssetBlockProps } from "./AssetBlock";
import Asset, { AssetType } from "../../../models/Asset";
import AssetManager, { AssetQueryResult } from "../../../services/AssetManager";
import Loader from "react-loader-spinner";
import ReactPlayer from "react-player";
import {
  makeStyles,
  Theme,
  Button,
  GridList,
  GridListTile,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  assetWithBorder: {
    boxSizing: "unset",
    border: "solid 2px #da556c",
    height: "100px",
    width: "100px",
    objectFit: "contain",
  },
  assetWithoutBorder: {
    height: "100px",
    width: "100px",
    objectFit: "contain",
  },
  gridList: {
    width: "95%",
    height: "250px",
    margin: "auto",
  },
  loaderCentered: {
    [theme.breakpoints.down(420)]: {
      marginTop: "10%",
    },
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  paginationRoot: {
    "& > *": {
      marginTop: theme.spacing(2),
      justifyContent: "center",
      display: "flex",
    },
  },
  videoPlayer: {
    objectFit: "contain",
    width: "100px",
    height: "100px",
  },
  videoWrapper: {
    pointerEvents: "none",
  },
}));

function AssetLibraryTabContent(props: AssetBlockProps) {
  const { assetBlock, setAssetBlock } = props;

  useEffect(() => {
    // Reset the source and asset so that URL is not persisted
    let copyAssetBlock = { ...assetBlock };
    copyAssetBlock.assetPickerSource = AssetPickerSource.library;
    copyAssetBlock.asset = undefined;
    setAssetBlock(copyAssetBlock);
  }, []);

  return <AssetLibraryItem {...props} />;
}

function AssetLibraryItem(props: AssetBlockProps) {
  const classes = useStyles();
  const { assetBlock, setAssetBlock } = props;
  const [loading, setLoading] = useState(true);
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    AssetManager.getAssetsForQuery({
      type: props.assetBlock.assetType,
      page: page,
    }).then((response: AssetQueryResult) => {
      setAssets(response.results);
      setPage(response.pagination.currentPage);
      setPageCount(response.pagination.totalPages);
    });
  }, [page]);

  function handleSave(asset: any) {
    let ab = { ...assetBlock };
    ab.asset = asset;
    ab.errors = undefined;
    ab.warnings = undefined;
    setAssetBlock(ab);
  }

  const assetLoaded = () => {
    setLoading(false);
  };

  function onChangePage(_: unknown, page: number) {
    setPage(page);
  }

  function classNameForSelected(asset: any) {
    let isItemSelected = selectedAsset === asset;
    return isItemSelected
      ? classes.assetWithBorder
      : classes.assetWithoutBorder;
  }

  const GridTiles = () => {
    return (
      <>
        {assets?.map((tile: Asset, i: number) => (
          <GridListTile key={tile.id} cols={1}>
            <Button onClick={() => handleSave(tile)}>
              {tile.type === AssetType.image && (
                <div
                  onClick={() => setSelectedAsset(tile)}
                  style={{ display: loading ? "none" : "block" }}
                >
                  <img
                    key={i}
                    className={classNameForSelected(tile)}
                    onLoad={assetLoaded}
                    src={tile.attachment}
                  />
                </div>
              )}
              {tile.type === AssetType.video && (
                <div
                  key={i}
                  className={classNameForSelected(tile)}
                  style={{ display: loading ? "none" : "block" }}
                >
                  <div onClick={() => setSelectedAsset(tile)}>
                    <div className={classes.videoWrapper}>
                      <ReactPlayer
                        className={classes.videoPlayer}
                        onReady={assetLoaded}
                        key={i}
                        onLoad={assetLoaded}
                        url={tile.attachment}
                        width="100px"
                        height="100px"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Button>
          </GridListTile>
        ))}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <div>
        <div
          className={classes.loaderCentered}
          style={{ display: loading ? "block" : "none" }}
        >
          <Loader type="Oval" color="grey" height={100} width={100} />
        </div>
        <GridList cellHeight={120} className={classes.gridList} cols={5}>
          <GridTiles />
        </GridList>
        {pageCount > 1 && (
          <div className={classes.paginationRoot}>
            <Pagination page={page} onChange={onChangePage} count={pageCount} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AssetLibraryTabContent;
