import React, { useMemo } from "react";
import styles from "./CustomTimeInput.module.scss";

const format24HrTimeStr = (timeStr: string) => {
  const splitTime = timeStr.split(":");
  const parsedHour = parseInt(splitTime[0]);
  const suffix = parsedHour < 12 ? " am" : " pm";
  const convertedHour =
    parsedHour === 0
      ? 12
      : parsedHour > 12
      ? parsedHour % 12
      : parsedHour.toLocaleString("en-us", { minimumIntegerDigits: 1 });
  return `${convertedHour}:${splitTime[1]} ${suffix}`;
};

const generateTimes = () => {
  const times = [];

  for (let i = 0; i < 24; i++) {
    times.push(`${i.toLocaleString("en-us", { minimumIntegerDigits: 2 })}:00`);
    times.push(`${i.toLocaleString("en-us", { minimumIntegerDigits: 2 })}:30`);
  }

  return times;
};

type CustomTimeInputProps = {
  value: any;
  onChange: any;
};
const CustomTimeInput = ({ value, onChange }: CustomTimeInputProps) => {
  const times = useMemo(generateTimes, []);

  return (
    <select
      onChange={(e) => onChange(e.target.value)}
      value={value}
      className={`select ${styles["select-overrides"]}`}
    >
      {times.map((time) => (
        <option key={time} value={time}>
          {format24HrTimeStr(time)}
        </option>
      ))}
    </select>
  );
};

export default CustomTimeInput;
