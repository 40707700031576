import React, { useState, useEffect } from "react";
import AssetBlock, { AssetPickerSource, AssetBlockProps } from "./AssetBlock";
import { AssetType, emptyAsset } from "../../../models/Asset";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { makeStyles, Theme, Grid, TextField } from "@material-ui/core";
import "../../ModalCms.tsx/modalStyles.scss";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  textInput: {
    marginRight: "10px",
    "& label.Mui-focused": {
      color: theme.palette.primary.contrastText,
    },
    "& label": {
      color: theme.palette.primary.contrastText,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.contrastText,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#999999",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.main,
    },
  },
  textInput2: {
    padding: theme.spacing(2),
    width: "100%",
  },

  addButtonText: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#dc536a",
    fontFamily: "SF Pro Text",
    letterSpacing: "1.25px",
  },
  thumbnailImage: {
    height: "95%",
    width: "80%",
  },
}));

function AssetUrlTabContent(props: AssetBlockProps) {
  const classes = useStyles();
  const { assetBlock, setAssetBlock } = props;

  const [didLoadPreview, setDidLoadPreview] = useState(false);

  useEffect(() => {
    var ab = { ...assetBlock };
    // If we don't have an asset on the url tab, generate an empty one
    if (!ab.asset) {
      ab.asset = emptyAsset();
    }
    ab.assetPickerSource = AssetPickerSource.url;
    interceptedSetAssetBlock(ab);
  }, []);

  function interceptedSetAssetBlock(assetBlock: AssetBlock | undefined) {
    if (assetBlock) {
      let ab = { ...assetBlock };

      ab.errors = [`Enter a valid URL`];
      ab.warnings = didLoadPreview ? ab.errors : null;

      setAssetBlock(ab);
    } else {
      console.warn("No asset block provided");
    }
  }

  function assetLoaded() {
    setDidLoadPreview(true);
    let ab = { ...assetBlock };
    ab.warnings = null;
    ab.errors = ab.warnings;
    setAssetBlock(ab);
  }

  return assetBlock.asset ? (
    <Grid container direction="row" justify="center">
      <Grid item>
        <AssetUrlItem
          assetBlock={assetBlock}
          setAssetBlock={interceptedSetAssetBlock}
        />
      </Grid>
      {assetBlock.asset.attachment && (
        <Grid item style={{ textAlign: "center" }}>
          {assetBlock.assetType === AssetType.image ? (
            <img
              src={assetBlock.asset.attachment}
              onLoad={assetLoaded}
              className={classes.thumbnailImage}
              hidden={
                assetBlock.errors !== undefined && assetBlock.errors !== null
              }
            />
          ) : (
            <ReactPlayer
              width="100%"
              height="100%"
              onReady={assetLoaded}
              url={assetBlock.asset.attachment}
            />
          )}
        </Grid>
      )}
    </Grid>
  ) : (
    <React.Fragment />
  );
}

function AssetUrlItem({ assetBlock, setAssetBlock }: AssetBlockProps) {
  const classes = useStyles();

  function handleUrlChange(event: any) {
    let ab = { ...assetBlock };
    let asset = ab.asset;
    if (asset) {
      asset.attachment = event.target.value;
      asset.thumbnail = event.target.value;
      asset.description = "hotlinked image";
    } else {
      console.warn("No attachment provided");
    }

    setAssetBlock(ab);
  }

  const placeHolder =
    assetBlock.assetType === AssetType.image ? "Image URL" : "Video URL";

  return (
    <TextField
      autoFocus
      className={classes.textInput2}
      style={{ width: "575px", marginTop: "10px", padding: "5px 10px 15px" }}
      name="url"
      placeholder={placeHolder}
      value={assetBlock.asset?.attachment}
      onChange={handleUrlChange}
      error={assetBlock.warnings !== undefined && assetBlock.warnings !== null}
      helperText={assetBlock.warnings ? assetBlock.warnings.join(",") : ""}
    />
  );
}

export default AssetUrlTabContent;
