import React, { useState } from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import styles from "./RenderedVideos.module.scss";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import ReactPlayer from "react-player/lazy";
import { ReactComponent as PlayCircle } from "../../../../shared/icons/play-circle.svg";
type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

const RenderedVideos = ({ convertedBlock, highlight }: Props) => {
  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  const videos: string[] = convertedBlock.params.videos?.value.map(
    (vid: any) => vid.value
  );

  const [playIndex, setPlayIndex] = useState(-1);

  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      {/* loops over videos and it's a 2 wide */}
      <main className={styles["videos-container"]}>
        {videos?.map((videoUrl, index) => (
          <div key={index} className={styles["player-wrapper"]}>
            <ReactPlayer
              className={styles["react-player"]}
              width={"100%"}
              height={"100%"}
              url={videoUrl}
              playing={playIndex == index}
              controls={false}
              light={true}
              playIcon={<PlayCircle className={styles["icon"]} />}
              onClickPreview={() => setPlayIndex(index)}
              onPlay={() => setPlayIndex(index)}
            />
          </div>
        ))}
      </main>
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedVideos;
