import React from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import { DateInput } from "../index";

type DateTimeInputProps = {
  param: BlockParam;
  update: (value: any) => void; // really this should be an input of just string
  subField?: boolean;
  sectionKey: string;
  childKey?: string;
};

const DateTimeInput = ({
  param,
  update,
  subField,
  sectionKey,
  childKey,
}: DateTimeInputProps) => {
  return (
    <DateInput
      sectionKey={sectionKey}
      childKey={childKey}
      param={param}
      update={update}
      subField={subField}
      showTime
    />
  );
};

export default DateTimeInput;
