import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Collapse, makeStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const useStyles = makeStyles(() => ({
  collapseAreaCursor: {
    cursor: "pointer",
  },
}));

interface Props {
  children: any;
  header: string;
  setForceOpenCollapse?: any;
  forceOpenCollapse?: string[];
  validationRule?: string[];
}

export default function ArtistLinkRadioInput(props: Props) {
  const [openCollapse, setOpenCollapse] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    props.validationRule?.map((validation: string) => {
      if (props.forceOpenCollapse?.includes(validation)) {
        setOpenCollapse(true);
      }
    });
  }, [props.forceOpenCollapse]);

  const handleClick = () => {
    if (openCollapse && props.forceOpenCollapse) {
      props.setForceOpenCollapse([]);
    }
    setOpenCollapse(!openCollapse);
  };

  function collapseFinished() {
    let collapseArea = document.getElementById(props.header);
    collapseArea?.scrollIntoView({ block: "nearest", behavior: "smooth" });
  }

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" pl={4} pr={4} onClick={handleClick}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            className={classes.collapseAreaCursor}
          >
            <Grid item xs={2}>
              {openCollapse ? (
                <RemoveCircleOutlineIcon color="action" fontSize="large" />
              ) : (
                <AddCircleOutlineIcon color="action" fontSize="large" />
              )}
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5" color="textPrimary">
                <Box fontWeight={800}>{props.header}</Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Box id={props.header} width={1}>
        <Collapse onEntered={collapseFinished} in={openCollapse} timeout="auto">
          {props.children}
        </Collapse>
      </Box>
    </>
  );
}
