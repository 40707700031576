import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Theme,
  Tooltip,
  Grid,
  ClickAwayListener,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) => ({
  toolTip: {
    maxWidth: "725px",
    marginLeft: "4px",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.primary.light,
  },
  toolTipIcon: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  toolTipHeaderText: {
    fontSize: "20px",
  },
  toolTipLink: {
    color: "#03a9fc",
  },
  toolTipArrow: {
    color: theme.palette.primary.light,
  },
  listMargin: {
    marginTop: "20px",
  },
  toolTipList: {
    marginTop: theme.spacing(2),
    fontSize: "16px",
  },
}));

type Props = {
  page: number;
  width: Breakpoint;
};

const ToolTipTOS = ({ width, page }: Props) => {
  const [openToolTip, setOpenToolTip] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setOpenToolTip(false);
  }, [isWidthUp("sm", width), page]);

  const ToolTipContent = ({ showCloseIcon }: any) => {
    return (
      <Box>
        <Grid container>
          <Grid item xs={showCloseIcon ? 11 : 12}>
            <Box display={showCloseIcon ? "unset" : "flex"}>
              <Box className={classes.toolTipHeaderText}>
                <Typography>Stealth Player Terms</Typography>
              </Box>
              <Box ml={showCloseIcon ? 0 : 1}>
                <Typography>
                  <a
                    target="_blank"
                    className={classes.toolTipLink}
                    href={`https://${process.env.SYMPHONY_HOST}/terms`}
                  >
                    see full Terms of Service
                  </a>
                </Typography>
              </Box>
            </Box>
          </Grid>
          {showCloseIcon && (
            <Grid item xs={1}>
              <Box>
                <CloseIcon onClick={() => setOpenToolTip(false)} />
              </Box>
            </Grid>
          )}
        </Grid>
        <Box className={classes.toolTipList}>
          <Typography>
            Platoon may permit you to use Stealth Player to give individuals
            targeted, limited, private access to Artist Recordings, Artist
            Images, and other biographical material about an Artist. You agree
            that your use of Stealth Player is subject to the following terms
            and conditions:
          </Typography>
          <ul>
            <li>
              <Typography>
                You acknowledge that Stealth Player is provided solely to enable
                you to privately share Artist Recordings or Artist Images with
                targeted individuals;
              </Typography>
            </li>
            <li className={classes.listMargin}>
              <Typography>
                You must not use Stealth Player to distribute Artist Recordings
                or Artist Images to the public;
              </Typography>
            </li>
            <li className={classes.listMargin}>
              <Typography>
                You warrant and represent that you have obtained all necessary
                rights and consents from third parties whose rights, work, or
                otherwise are embodied in the Artist Images or Artist Recordings
                (including the underlying musical and/or literary works embodied
                in the Artist Recordings) to enable you to share such Artist
                Recordings and/or Artist Images with third parties.
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    );
  };

  const UnControlledToolTip = () => {
    return (
      <Tooltip
        classes={{
          tooltip: classes.toolTip,
          arrow: classes.toolTipArrow,
        }}
        arrow
        placement="bottom-start"
        interactive
        title={<ToolTipContent showCloseIcon={false} />}
      >
        <InfoOutlinedIcon fontSize="small" className={classes.toolTipIcon} />
      </Tooltip>
    );
  };

  const ControlledToolTip = () => {
    return (
      <ClickAwayListener onClickAway={() => setOpenToolTip(false)}>
        <Tooltip
          disableTouchListener
          disableHoverListener
          open={openToolTip}
          classes={{
            tooltip: classes.toolTip,
            arrow: classes.toolTipArrow,
          }}
          arrow
          placement="bottom-start"
          interactive
          title={<ToolTipContent showCloseIcon={true} />}
        >
          <InfoOutlinedIcon
            onClick={() => setOpenToolTip(!openToolTip)}
            fontSize="small"
            className={classes.toolTipIcon}
          />
        </Tooltip>
      </ClickAwayListener>
    );
  };

  return (
    <>
      {isWidthUp("sm", width) ? <UnControlledToolTip /> : <ControlledToolTip />}
    </>
  );
};

export default withWidth()(ToolTipTOS);
