import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./SlideCarousel.module.scss";
import { ReactComponent as ChevronLeft } from "../../icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
type Props = { children: ReactNode };

const SlideCarousel = ({ children }: Props) => {
  const slideContainer = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const [translateX, setTranslateX] = useState(0);

  const slideContainerWidth = slideContainer.current?.scrollWidth || 0;
  const containerWidth = container.current?.offsetWidth || 0;
  const maxTranslation = -slideContainerWidth + containerWidth;

  // watches the slideContainerWidth, and have it move over if needed
  useEffect(() => {
    if (slideContainerWidth < containerWidth) {
      if (translateX !== 0) {
        setTranslateX(0);
      }
    } else if (translateX < maxTranslation) {
      setTranslateX(maxTranslation);
    }
  }, [slideContainer.current?.scrollWidth, container.current?.offsetWidth]);

  const scroll = (direction: "backward" | "forward") => {
    // we pull these values on each run to make sure we have fresh values
    const slideContainerWidth = slideContainer.current?.scrollWidth || 0;
    const containerWidth = container.current?.offsetWidth || 0;
    const maxTranslation = -slideContainerWidth + containerWidth;

    // if the slide container width is less than the container width
    if (slideContainerWidth < containerWidth) {
      // only move to the 0 position if it's not there already
      // example: you have 8 photos, you scrolled over, then deleted 5 of them.
      // you want to be able to get back to the correct display
      if (translateX !== 0) {
        setTranslateX(0);
      }
    } else if (direction === "forward") {
      const nextMove = translateX - containerWidth;
      nextMove < maxTranslation
        ? setTranslateX(maxTranslation)
        : setTranslateX(nextMove);
    } else {
      const nextMove = translateX + containerWidth;
      direction === "backward" && nextMove > 0
        ? setTranslateX(0)
        : setTranslateX(nextMove);
    }
  };

  return (
    <div className={styles["controls-wrapper"]}>
      {slideContainerWidth > containerWidth && translateX != 0 && (
        <button
          className={`btn--naked ${styles["scroll-left"]} ${styles["btn"]}`}
          onClick={() => scroll("backward")}
        >
          <ChevronLeft className={styles["arrows"]} />
        </button>
      )}
      {slideContainerWidth > containerWidth && translateX != maxTranslation && (
        <button
          className={`btn--naked ${styles["scroll-right"]} ${styles["btn"]}`}
          onClick={() => scroll("forward")}
        >
          <ChevronRight className={styles["arrows"]} />
        </button>
      )}
      <div className={styles["container"]} ref={container}>
        <div
          id="slideCarousel"
          ref={slideContainer}
          className={styles["slide-container"]}
          style={{ transform: `translateX(${translateX}px)` }}
        >
          {/* this will be children, or needs to be a separate component */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default SlideCarousel;
