import React, { useContext } from "react";
import clsx from "clsx";
import { PlatoonContext } from "../PlatoonContext";
interface Props {
  theme: any;
  getEditorState: any;
  setEditorState: any;
  addImage: any;
  modifier: any;
  blockType: string;
  editorState: any;
  onChange: any;
}

const PhoneVideoButton = (props: Props) => {
  const { setBlockEditSession } = useContext(PlatoonContext);

  const onClick = () => {
    let session = { blockType: "phone-video" };
    setBlockEditSession(session);
  };

  const preventBubblingUp = (event: any) => {
    event.preventDefault();
  };

  const blockTypeIsActive = () => {
    const editorState = props.getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === props.blockType;
  };

  const theme = props.theme;
  const className = blockTypeIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <React.Fragment>
      <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
        <button className={className} onClick={onClick} type="button">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 13 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="WYSIWYG-icons"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Menu-Buttons"
                transform="translate(-79.000000, -202.000000)"
                fill="#888888"
                fillRule="nonzero"
              >
                <path
                  d="M88.9824219,221.838867 C90.8833008,221.838867 91.9291992,220.784668 91.9291992,218.875488 L91.9291992,204.963379 C91.9291992,203.054199 90.8833008,202 88.9824219,202 L81.9467773,202 C80.0541992,202 79,203.054199 79,204.963379 L79,218.875488 C79,220.784668 80.0458984,221.838867 81.9467773,221.838867 L88.9824219,221.838867 Z M89.7128906,217.763184 L81.2246094,217.763184 L81.2246094,206.283203 L89.7128906,206.283203 L89.7128906,217.763184 Z"
                  id="iphone"
                ></path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </React.Fragment>
  );
};

export default PhoneVideoButton;
