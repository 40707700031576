import React from "react";
import { ModalInputActionBarProps } from "../ModalInput";
import styles from "./ModalInputMobileActionBar.module.scss";
import { ReactComponent as ArrowLeft } from "../../../../../shared/icons/arrow-left.svg";
import { ReactComponent as XMark } from "../../../../../shared/icons/xmark.svg";
import { ReactComponent as CheckmarkCitcle } from "../../../../../shared/icons/checkmark-circle.svg";

const ModalInputMobileActionBar = ({
  showSave,
  showPrevious,
  handlePrevious,
  handleCancel,
  handleSave,
  handleNext,
}: ModalInputActionBarProps) => {
  return (
    <div className={styles["container"]}>
      <div style={{ flex: 1 }}>
        <button className="btn btn--naked" onClick={handleCancel}>
          <XMark className={styles["icon"]} />
          <div className={styles["button-text"]}>Cancel</div>
        </button>
      </div>
      {showPrevious && (
        <button className="btn btn--naked" onClick={handlePrevious}>
          <ArrowLeft className={styles["icon"]} />
          <div className={styles["button-text"]}>Previous</div>
        </button>
      )}

      {showSave ? (
        <button className="btn btn--naked" onClick={handleSave}>
          <CheckmarkCitcle className={styles["icon"]} />
          <div className={styles["button-text"]}>Save</div>
        </button>
      ) : (
        <button className="btn btn--naked" onClick={handleNext}>
          <ArrowLeft className={`${styles["icon"]} ${styles["reversed"]}`} />
          <div className={styles["button-text"]}>NEXT</div>
        </button>
      )}
    </div>
  );
};
export default ModalInputMobileActionBar;
