import { Block, Template, TemplateVariant, } from '../../../asset-generator-lib';
import { ShapeType } from '../../../asset-generator-lib/composer/models/Block';

const backgroundBlock: Block = {
  id: 'blur_BGB',
  key: 'blur_BGB',
  type: 'SPRITE',
  frame: { origin: { x: 50, y: 50 }, size: { w: 1950, h: 1950 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
    { key: 'image', name: "Background", type: 'MAPPED', mappedPath: "release.artworkUrl" },
  ],
  style: {
    filter: { blur: 30 }
  }
};

const textBackgroundBlock: Block = {
  id: 'blur_TBB',
  key: 'blur_TBB',
  type: 'SPRITE',
  frame: { origin: { x: 50, y: 50 }, size: { w: 850, h: 850 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
    { key: 'color', name: "Background", type: 'STATIC', value: "#000000" }

  ],
}

const albumArtworkBlock: Block = {
  id: 'blur_AAB',
  key: 'blur_AAB',
  type: 'SPRITE',
  frame: { origin: { x: 50, y: 50 }, size: { w: 850, h: 850 }, anchor: { x: 0.5, y: 0.5 } },
  params: [{ key: 'image', name: "Album Artwork", type: 'MAPPED', mappedPath: "release.artworkUrl" }],
};

const text1: Block = {
  id: 'blur_T1',
  key: 'blur_T1',
  type: 'TEXT',
  params: [
    {
      key: 'text',
      name: 'Artist Name',
      type: 'MAPPED',
      defaultValue: 'Artist Name',
      mappedPath: "release.artistName"
    }
  ],
  frame: { origin: { x: 50, y: 25 }, anchor: { x: 0.5, y: 1 } },
  style: {
    wordWrap: { wrap: false, wrapWidth: 0 },
    rotation: { angle: 0 },
    border: { borderSize: 0, borderColor: '000000' },
    font: {
      fontColor: 'ffffff',
      fontSize: '75px',
      fontFamily: "Futura",
      fontWeight: '900',
    }
  }
}

const text2: Block = {
  id: 'blur_T2',
  key: 'blur_T2',
  type: 'TEXTBOX',
  frame: { origin: { x: 50, y: 80 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
    { key: 'color', name: "Background", type: 'STATIC', value: "#ffffff" },
    { key: 'text', name: "Link", type: 'STATIC', value: "LINK IN BIO" }
  ],
  children: [
    {
      key: '34',
      id: '34',
      type: 'TEXT',
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: '23',
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {
      }
    }
  ],
  style: {
    border: { borderColor: "ffffff", borderSize: 0 },
    wordWrap: { wrap: false, wrapWidth: 0 },
    padding: { padding: 40 },
    font: {
      fontColor: '000000',
      fontSize: '50px',
      fontFamily: "Futura",
      fontWeight: '500',
    },
    rotation: { angle: 0 }
  }
}

const text3: Block = {
  id: 'blur_T3',
  key: 'blur_T3',
  type: 'TEXTBOX',
  frame: { origin: { x: 50, y: 72.5 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
    { key: 'color', name: "Background", type: 'STATIC', value: "#000000" },
    {
      key: 'text', name: "Marketing Message", defaultValue: 'Release Name', type: 'MAPPED', mappedPath: "release.name"
    },
  ],
  children: [
    {
      key: '34',
      id: '34',
      type: 'TEXT',
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: '23',
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {
      }
    }
  ],
  style: {
    border: { borderColor: "ffffff", borderSize: 5 },
    wordWrap: { wrap: true, wrapWidth: 850 },
    padding: { padding: 40 },
    font: {
      fontColor: 'ffffff',
      fontSize: '40px',
      fontFamily: "Futura",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}



const blurFacebook: TemplateVariant = {
  name: "Facebook Cover",
  key: 'facebookCover',
  size: { w: 820, h: 360 },
  blockOverrides: [
    {
      key: 'blur_BGB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 875, h: 875 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'blur_AAB',
      type: "SPRITE",
      frame: { origin: { x: 80, y: 50 }, size: { w: 200, h: 200 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'blur_TBB',
      type: "SPRITE",
      frame: { origin: { x: 40, y: 50 }, size: { w: 500, h: 200 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'blur_T1',
      type: "TEXT",
      frame: { origin: { x: 40, y: 25 }, anchor: { x: 0.5, y: 0 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: 'ffffff',
          fontSize: '40px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'blur_T2',
      type: "TEXT",
      frame: { origin: { x: 40, y: 67.5 }, anchor: { x: 0.5, y: 1 } },
      params: [
        {
          key: 'text', name: "Marketing Message", type: 'SELECT', options: [
            { key:'outNow',name: 'Out Now', type: 'STATIC', value: 'Out Now' }, //show a color picker when this param is selected
            { key:'marketingMessage',name: 'Marketing Message', type: "MAPPED", mappedPath: 'release.releaseDate' },

          ]
        },
      ],
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 15 },
        font: {
          fontColor: 'ffffff',
          fontSize: '18px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'blur_T3',
      type: "TEXTBOX",
      frame: { origin: { x: 40, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: 'ffffff',
          fontSize: '20px',
          fontFamily: "Futura",
          fontWeight: '500',
        },
        rotation: { angle: 0 }
      }
    },
  ]
}

const blurSpotify: TemplateVariant = {
  name: "Spotify Header",
  key: 'spotifyHeader',
  size: { w: 2660, h: 1140 },
  blockOverrides: [
    {
      key: 'blur_BGB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 2700, h: 2700 }, anchor: { x: 0.5, y: 0.5 } },
      style: { filter: { blur: 30 } }
    },
    {
      key: 'blur_AAB',
      type: "SPRITE",
      frame: { origin: { x: 62.5, y: 50 }, size: { w: 350, h: 350 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'blur_TBB',
      type: "SPRITE",
      frame: { origin: { x: 42.5, y: 50 }, size: { w: 775, h: 350 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'blur_T1',
      type: "TEXT",
      frame: { origin: { x: 42.5, y: 37.5 }, anchor: { x: 0.5, y: 0 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: 'ffffff',
          fontSize: '80px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'blur_T2',
      type: "TEXT",
      frame: { origin: { x: 42.5, y: 60 }, anchor: { x: 0.5, y: 1 } },
      params: [
        {
          key: 'text', name: "Marketing Message", type: 'SELECT', options: [
            { key:'outNow',name: 'Out Now', type: 'STATIC', value: 'Out Now' }, //show a color picker when this param is selected
            { key:'marketingMessage',name: 'Marketing Message', type: "MAPPED", mappedPath: 'release.releaseDate' },

          ]
        },
      ],
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 15 },
        font: {
          fontColor: 'ffffff',
          fontSize: '40px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'blur_T3',
      type: "TEXTBOX",
      frame: { origin: { x: 42.5, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: 'ffffff',
          fontSize: '50px',
          fontFamily: "Futura",
          fontWeight: '500',
        },
        rotation: { angle: 0 }
      }
    },
  ]
}

const blurTwitter: TemplateVariant = {
  name: "Twitter Header",
  key: 'twitterHeader',
  size: { w: 1500, h: 500 },
  blockOverrides: [
    {
      key: 'blur_BGB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 1550, h: 1550 }, anchor: { x: 0.5, y: 0.5 } },
      style: { filter: { blur: 30 } }
    },
    {
      key: 'blur_AAB',
      type: "SPRITE",
      frame: { origin: { x: 75, y: 50 }, size: { w: 225, h: 225 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'blur_TBB',
      type: "SPRITE",
      frame: { origin: { x: 44.5, y: 50 }, size: { w: 700, h: 225 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'blur_T1',
      type: "TEXT",
      frame: { origin: { x: 45, y: 30 }, anchor: { x: 0.5, y: 0 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: 'ffffff',
          fontSize: '40px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'blur_T2',
      type: "TEXT",
      frame: { origin: { x: 45, y: 65 }, anchor: { x: 0.5, y: 1 } },
      params: [
        {
          key: 'text', name: "Marketing Message", type: 'SELECT', options: [
            { key:'outNow',name: 'Out Now', type: 'STATIC', value: 'Out Now' }, //show a color picker when this param is selected
            { key:'marketingMessage',name: 'Marketing Message', type: "MAPPED", mappedPath: 'release.releaseDate' },

          ]
        },
      ],
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 15 },
        font: {
          fontColor: 'ffffff',
          fontSize: '18px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'blur_T3',
      type: "TEXTBOX",
      frame: { origin: { x: 45, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: 'ffffff',
          fontSize: '20px',
          fontFamily: "Futura",
          fontWeight: '500',
        },
        rotation: { angle: 0 }
      }
    },
  ]
}

const blurYoutube: TemplateVariant = {
  name: "Youtube Cover",
  key: 'youtubeCover',
  size: { w: 2560, h: 1440 },
  blockOverrides: [
    {
      key: 'blur_BGB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 2600, h: 2600 }, anchor: { x: 0.5, y: 0.5 } },
      style: { filter: { blur: 30 } }
    },
    {
      key: 'blur_AAB',
      type: "SPRITE",
      frame: { origin: { x: 72.5, y: 50 }, size: { w: 350, h: 350 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'blur_TBB',
      type: "SPRITE",
      frame: { origin: { x: 42.5, y: 50 }, size: { w: 1200, h: 350 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'blur_T1',
      type: "TEXT",
      frame: { origin: { x: 42.5, y: 40 }, anchor: { x: 0.5, y: 0 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: 'ffffff',
          fontSize: '80px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'blur_T2',
      type: "TEXT",
      frame: { origin: { x: 42.5, y: 57.5 }, anchor: { x: 0.5, y: 1 } },
      params: [
        {
          key: 'text', name: "Marketing Message", type: 'SELECT', options: [
            { key:'outNow',name: 'Out Now', type: 'STATIC', value: 'Out Now' }, //show a color picker when this param is selected
            { key:'marketingMessage',name: 'Marketing Message', type: "MAPPED", mappedPath: 'release.releaseDate' },

          ]
        },
      ],
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 15 },
        font: {
          fontColor: 'ffffff',
          fontSize: '40px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'blur_T3',
      type: "TEXTBOX",
      frame: { origin: { x: 42.5, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: 'ffffff',
          fontSize: '50px',
          fontFamily: "Futura",
          fontWeight: '500',
        },
        rotation: { angle: 0 }
      }
    },
  ]
}

const blurInstagram = {
  name: "Instagram Story",
  key: 'instagramStory',
  size: { w: 1080, h: 1920 }
} as TemplateVariant

const Blur = {
  id: 'blur',
  name: "blur",
  blocks: [
    backgroundBlock,
    textBackgroundBlock,
    albumArtworkBlock,
    text2,
    text3,
    text1
  ],
  meta: { version: "1.0", basis: { w: 100, h: 100 } },
  variants: [blurInstagram, blurFacebook, blurSpotify, blurTwitter, blurYoutube],
} as Template;

export default Blur;
