import React, { useContext } from "react";
import { Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UrlIframeTabContent, CodeIframeTabContent } from "./IframeTabContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IframeBlock, { emptyIframeBlock } from "./IframeBlock";
import { PlatoonContext } from "../../PlatoonContext";

const useStyles = makeStyles(() => ({
  modalContent: {
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: 'space-around',
    overflow: "hidden",
    marginBottom: "30px",

    // backgroundColor: theme.palette.background.paper,
    // width: 600,
    // height: 400
  },
  tabs: {
    color: "#ffffff99",
    fontSize: "14px",
    fontFamily: "SF Pro Display",
    letterSpacing: "1.25px",
    width: "64px",
  },
  modalTitle: {
    color: "#ffffff",
    fontSize: "26px",
    fontFamily: "SF Pro Display",
    marginTop: "30px",
    marginLeft: "30px",
    // letterSpacing: '1.25px',
    // width: '64px'
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#999999",
  },
}));

const pickerModes = [
  { title: "Link", value: "url" },
  { title: "Embed", value: "code" },
];

export default function IframePicker() {
  const classes = useStyles();

  const { blockEditSession, setBlockEditSession } = useContext(PlatoonContext);

  // Casting block data to a ButtonBlock and initializing if it's null.
  // This ensures ButtonPicker and descendants have types to work with
  // instead of just object data, which is error prone and not readable.
  const iframeBlock =
    (blockEditSession.blockData as IframeBlock) || emptyIframeBlock();

  const setIframeBlock = (
    iframeBlock: IframeBlock,
    errors: string[] | null = null,
    warnings: string[] | null = null
  ) => {
    let s = { ...blockEditSession };
    s.blockData = iframeBlock;
    s.errors = errors;
    s.warnings = warnings;
    setBlockEditSession(s);
  };

  const handlePickerMode = (_: unknown, newMode: string | null) => {
    //Avoid deselecting the toggle group
    if (newMode !== iframeBlock.type && newMode !== null) {
      iframeBlock.type = newMode;
      setIframeBlock(iframeBlock);
    }
  };

  return (
    <Box>
      <Tabs
        value={iframeBlock.type}
        indicatorColor="secondary"
        onChange={handlePickerMode}
        aria-label="Picker Mode"
      >
        {pickerModes.map((mode) => (
          <Tab
            className={classes.tabs}
            value={mode.value}
            aria-label={mode.title}
            label={mode.title}
          ></Tab>
        ))}
      </Tabs>
      <Divider style={{ marginBottom: "10px" }} />

      <div className={classes.modalContent}>
        {iframeBlock.type === "url" && (
          <UrlIframeTabContent
            iframeBlock={iframeBlock}
            setIframeBlock={setIframeBlock}
          />
        )}

        {iframeBlock.type === "code" && (
          <CodeIframeTabContent
            iframeBlock={iframeBlock}
            setIframeBlock={setIframeBlock}
          />
        )}
      </div>
    </Box>
  );
}
