import React, { useContext, useEffect } from "react";
import ButtonBlock, { LinkButtonModel, CopyButtonModel } from "./ButtonBlock";
import Add from "@material-ui/icons/Add";
import { PlatoonContext } from "../../PlatoonContext";
import CancelIcon from "@material-ui/icons/Cancel";
import validator from "validator";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  textInput: {
    marginRight: "10px",
    marginTop: "0%",
    "& label.Mui-focused": {
      color: "white",
    },
    "& label": {
      color: "white",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#34363a",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#da556c",
    },
  },
  linkButtonContainer: {
    marginLeft: "25px",
    backgroundColor: "#424242",
    borderRadius: "5px",
    marginTop: "10px",
    width: "585px",
    marginBottom: "15px",
    padding: "5px 10px 15px",
    alignItems: "flex-end",
  },
  linkButton: {
    padding: "0px",
    position: "static",
    backgroundColor: "#da556c",
    "&:hover": {
      backgroundColor: "#DB405A",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    marginTop: "10px",
    marginBottom: "10px",
    marginRight: "10px",
  },
  addButtonContainer: {
    display: "flex",
    marginLeft: "20px",
  },
  addButtonWrapper: {
    display: "flex",
    cursor: "pointer",
  },
  addButtonText: {
    marginTop: "2.5%",
    fontSize: "14px",
    fontWeight: 600,
    color: "#dc536a",
    fontFamily: "SF Pro Text",
    letterSpacing: "1.25px",
  },
  addIcon: {
    marginTop: "1px",
    color: "#da556c",
  },
  copyButtonTextField: {
    width: "575px",
    marginLeft: "20px",
    marginTop: "10px",
    padding: "5px 10px 15px",
  },
}));

interface Props {
  buttonBlock: ButtonBlock;
  setButtonBlock: (
    buttonBlock: ButtonBlock,
    errors?: string[] | null,
    warnings?: string[] | null
  ) => void;
}

function LinkButtonItem(props: any) {
  const { blockEditSession } = useContext(PlatoonContext);
  const classes = useStyles();
  function validateButton(button: LinkButtonModel) {
    let errors = [];
    if (button.title.length <= 0) {
      errors.push("title should be at least 1 character");
    }
    if (button.url.length <= 0) {
      errors.push("url should be at least 1 character");
    }

    return errors.length > 0 ? errors : null;
  }

  function warningsForButton(button: LinkButtonModel) {
    let warnings = [];
    if (!validator.isURL(button.url)) {
      warnings.push("Must be a valid URL");
    }
    return warnings.length > 0 ? warnings : null;
  }
  function handleChange(event: any) {
    let b = { ...props.button } as any;
    b[event.target.name] = event.target.value;
    let errors = validateButton(b);
    let warnings = warningsForButton(b);
    b.warnings = warnings;
    props.onButtonChange(b, props.index, errors, warnings);
  }

  return (
    <div>
      <TextField
        className={classes.textInput}
        name="title"
        placeholder="Button Label"
        value={props.button.title}
        onChange={handleChange}
      />
      <TextField
        className={classes.textInput}
        name="url"
        placeholder="Link URL"
        style={{ marginLeft: "20px", width: "260px", marginTop: "0px" }}
        value={props.button.url}
        onChange={handleChange}
        error={blockEditSession.showWarnings && props.button.warnings}
        helperText={
          blockEditSession.showWarnings ? props.button.warnings?.join(",") : ""
        }
      />
    </div>
  );
}

function LinkButtonTabContent(props: Props) {
  const classes = useStyles();

  function addButtonInputField() {
    let bb = { ...props.buttonBlock };
    bb.linkButtons?.push({ title: "", url: "" });
    props.setButtonBlock(bb, ["need content"]);
  }

  function removeButtonInputField(index: any) {
    let bb = { ...props.buttonBlock };
    bb.linkButtons?.splice(index, 1);
    props.setButtonBlock(bb);
  }

  function onButtonChange(
    button: LinkButtonModel,
    index: number,
    errors: string[] | null = null,
    warnings: string[] | null = null
  ) {
    if (
      props.buttonBlock?.linkButtons &&
      props.buttonBlock.linkButtons[index]
    ) {
      props.buttonBlock.linkButtons[index] = button;
    } else {
      console.warn("No button blocks provided");
    }
    props.setButtonBlock(props.buttonBlock, errors, warnings);
  }

  return (
    <div>
      <Grid className={classes.linkButtonContainer} container spacing={1}>
        {props.buttonBlock.linkButtons?.map(
          (button: LinkButtonModel, index: any) => {
            return (
              <React.Fragment>
                <Grid item>
                  <LinkButtonItem
                    button={button}
                    index={index}
                    onButtonChange={onButtonChange}
                  />
                </Grid>
                {props.buttonBlock.linkButtons &&
                  props.buttonBlock.linkButtons.length > 1 && (
                    <Grid item>
                      <CancelIcon
                        onClick={() => removeButtonInputField(index)}
                      />
                    </Grid>
                  )}
              </React.Fragment>
            );
          }
        )}
      </Grid>

      <Box className={classes.addButtonContainer}>
        <div className={classes.addButtonWrapper} onClick={addButtonInputField}>
          <Add className={classes.addIcon} />
          <Typography className={classes.addButtonText}>
            ADD BUTTON TO SET
          </Typography>
        </div>
      </Box>
    </div>
  );
}

export { LinkButtonTabContent };

function CopyButtonItem(props: any) {
  const classes = useStyles();

  const { blockEditSession } = useContext(PlatoonContext);

  useEffect(() => {
    let b = { ...props.button } as any;
    let errors = validateButton(b);
    let warnings = warningsForButton(b);
    b.warnings = warnings;
    props.onButtonChange(b, errors, warnings);
    // validateButton(props.button)
  }, []);

  function validateButton(button: CopyButtonModel) {
    let errors = [];
    if (button.text.length <= 0) {
      errors.push("test errer");
    }
    return errors.length > 0 ? errors : null;
  }

  function warningsForButton(button: CopyButtonModel) {
    let warnings = [];
    if (button.text.length <= 0) {
      warnings.push("Need information for Copy");
    }
    return warnings.length > 0 ? warnings : null;
  }

  function handleChange(event: any) {
    let b = { ...props.button } as any;
    b[event.target.name] = event.target.value;
    let errors = validateButton(b);
    let warnings = warningsForButton(b);
    props.onButtonChange(b, errors, warnings);
  }

  return (
    <div>
      <TextField
        className={classes.copyButtonTextField}
        name="text"
        placeholder="Text For Copy"
        value={props.button.text}
        onChange={handleChange}
        error={blockEditSession.showWarnings && props.button.warnings}
        helperText={
          blockEditSession.showWarnings ? props.button.warnings?.join(",") : ""
        }
      />
    </div>
  );
}

function CopyButtonTabContent(props: Props) {
  function onButtonChange(
    button: CopyButtonModel,
    errors: string[] | null = null,
    warnings: string[] | null = null
  ) {
    let bb = { ...props.buttonBlock };
    bb.copyButton = button;
    props.setButtonBlock(bb, errors, warnings);
  }
  return (
    <div>
      <CopyButtonItem
        button={props.buttonBlock.copyButton}
        onButtonChange={onButtonChange}
      />
    </div>
  );
}

export { CopyButtonTabContent };
