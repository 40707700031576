import React, { ReactNode } from "react";
import { BlockParam } from "../../../../asset-generator-lib/composer/models/Block";
import Overlay from "../../../shared/components/Overlay/Overlay";
import useWindowSize from "../../../shared/CustomHooks";
import Header from "../../../shared/Header";
import AddChangeButton from "../inputs/AddChangeButton/AddChangeButton";
import { ModalInputActionBarProps } from "../inputs/ModalInput/ModalInput";
import ModalInputDesktopActionBar from "../inputs/ModalInput/ModalInputDesktopActionBar/ModalInputDesktopActionBar";
import ModalInputMobileActionBar from "../inputs/ModalInput/ModalInputMobileActionBar/ModalInputMobileActionBar";
import styles from "./FormModal.module.scss";

type Props = {
  headerText: string;
  state: "CHANGE" | "ADD";
  buttonTextStates?: [string, string];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  handleSave: () => void;
  handleCancel: () => void;
  param: BlockParam<any>;
};

const FormModal = ({
  children,
  headerText,
  open,
  setOpen,
  handleSave,
  handleCancel,
  state,
  param,
}: Props) => {
  const { width } = useWindowSize();

  const desktopView = width > 1048; //same as scss $tablet-breakpoint

  const actionBarProps: ModalInputActionBarProps = {
    handlePrevious: () => {},
    handleCancel: handleCancel,
    handleSave: handleSave,
    handleNext: () => {},
    showPrevious: false,
    showSave: true,
  };

  return (
    <React.Fragment>
      <AddChangeButton
        onClick={() => setOpen(!open)}
        paramName={param.name} // we need to turn off the verb
        state={state}
        buttonTextStates={param.buttonTextStates}
        noVerb={!!param.overrideName} // if they are overriding the name, we are disabling the verb on add/change
      />

      {/* modal */}
      {open && (
        <Overlay setOpen={setOpen}>
          <div className={styles["modal"]}>
            <Header text={headerText} padding={0} />
            <div className={styles["container"]}>{children}</div>

            {desktopView ? (
              <ModalInputDesktopActionBar
                {...actionBarProps}
                leftCancel={true}
              />
            ) : (
              <ModalInputMobileActionBar {...actionBarProps} />
            )}
          </div>
        </Overlay>
      )}
    </React.Fragment>
  );
};

export default FormModal;
