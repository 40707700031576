import React, { useState, useCallback } from "react";
import { Text, Graphics } from "@inlet/react-pixi";
import Block, { ShapeType, BlockUtils } from "../../models/Block";
import DraggableBlock from "./DraggableBlock";
import * as PIXI from "pixi.js";
import { TemplateEditorState } from "../../TemplateEditorState";

interface Props {
  block: Block;
  parentView: any;
  readOnly: boolean;
  editorState: TemplateEditorState;
  setEditorState: React.Dispatch<React.SetStateAction<TemplateEditorState>>;
  selectBlocks: any;
  selectBlock: any;
  composition?: any;
}

export default function TextBoxBlockRenderer(props: Props) {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [textBox, setTextBox] = useState<any>();
  const [textScale, setTextScale] = useState<number>();

  (document as any).fonts.ready.then(() => {
    setFontLoaded(true);
  });

  const resetRef = async () => {
    setTextBox(undefined);
  };

  const textBoxRef = useCallback(
    (node) => {
      resetRef().then(() => setTextBox(node));
      BlockUtils.resetTextScale(setTextScale).then(() => {
        setTextScale(BlockUtils.findTextScale(node, props.block));
      });
    },
    [props]
  );

  let shapeType = props.block.children?.[1]?.shapeType as ShapeType;
  let shapeAttributes = props.block?.children?.[1]?.style;

  const fillForAllShapes = BlockUtils.paramValuesByKey(
    "shapeColor",
    props.composition.blocks
  );
  const textBorderFill = BlockUtils.paramValuesByKey(
    "textColor",
    props.composition.blocks
  );

  const blockStyle = props.block.style;
  const padding = blockStyle?.padding?.padding;

  const shapeColor = PIXI.utils.string2hex(
    fillForAllShapes ? fillForAllShapes : blockStyle?.background?.color
  );
  const shapeBorderColor = textBorderFill
    ? PIXI.utils.string2hex(textBorderFill)
    : `0x${blockStyle?.border?.borderColor}`;

  const textBoxWidth = () => {
    if (props.block.frame?.size && blockStyle?.fixedWidth) {
      return props.block.frame.size?.w;
    } else if (textBox && textBox?.width && textScale) {
      return textBox?.width * textScale;
    } else {
      return 0;
    }
  };

  const textBoxHeight = () => {
    if (props.block.frame?.size) {
      return props.block?.frame?.size?.h;
    } else if (textBox && textBox?.height) return textBox?.height;
  };

  const xCoor = () => {
    if (props.block.frame?.anchor) {
      switch (props.block.frame.anchor?.x) {
        case 0:
          return 0;
        case 0.5:
          return textBoxWidth() / 2;
        case 1:
          return textBoxWidth();
        default:
          return 0;
      }
    } else return 0;
  };

  const yCoor = () => {
    if (props.block.frame?.anchor) {
      switch (props.block.frame?.anchor?.y) {
        case 0:
          return 0;
        case 0.5:
          return textBoxHeight() / 2;
        case 1:
          return textBoxHeight();
        default:
          return 0;
      }
    } else return 0;
  };

  const draw = (g: any) => {
    g.clear();
    g.lineStyle(parseInt(blockStyle?.border?.borderSize), shapeBorderColor);
    g.beginFill(shapeColor);
    switch (shapeType) {
      case ShapeType.rect:
        return g.drawRect(
          padding && -(xCoor() + padding / 2), // x coor
          padding && -(yCoor() + padding / 2), // y coor
          padding && textBoxWidth() + padding, // width
          textBoxHeight() + padding // height
        );
      case ShapeType.roundedRect:
        return g.drawRoundedRect(
          padding && -(xCoor() + padding / 2), // x coor
          padding && -(yCoor() + padding / 2), // y coor
          padding && textBoxWidth() + padding, // width
          textBoxHeight() + padding, // height
          shapeAttributes?.radius
        ); // borderRadius
      case ShapeType.circle:
        return g.drawCircle(
          textBoxWidth() / 2, // x coor
          textBoxHeight() / 2, // y coor
          padding && textBoxWidth() / 2 + padding
        ); // radius
      case ShapeType.ellipse:
        return g.drawEllipse(
          textBoxWidth() / 2, // x coor
          textBoxHeight() / 2, // y coor
          padding && textBoxWidth() / 2 + padding, // width
          textBoxHeight() + padding // height
        );
      case ShapeType.torus:
        return (
          g.drawCircle(
            textBoxWidth() / 2, // x coor
            textBoxHeight() / 2, // y coor
            props.block?.children?.[1]?.frame?.size?.outerRadius
          ),
          g.beginHole(),
          g.drawCircle(
            textBoxWidth() / 2, // x coor
            textBoxHeight() / 2, // y coor
            props.block?.children?.[1]?.frame?.size?.innerRadius // inner radius
          ),
          g.endHole()
        );
      default:
        break;
    }
    g.endFill();
  };

  return (
    <DraggableBlock {...props}>
      {textBox && fontLoaded && (
        <Graphics draw={draw} angle={blockStyle?.rotation?.angle} />
      )}
      <Text
        scale={textScale ? textScale : 1}
        ref={textBoxRef}
        name={props.block.id}
        anchor={props.block.frame?.anchor}
        text={BlockUtils.paramValueForKey("text", props.block.params)}
        angle={blockStyle?.rotation?.angle}
        style={{
          wordWrap: blockStyle?.wordWrap?.wrap,
          wordWrapWidth: blockStyle?.wordWrap?.wrapWidth,
          fill: textBorderFill ? textBorderFill : blockStyle?.font?.fontColor,
          fontSize: blockStyle?.font?.fontSize,
          fontFamily: blockStyle?.font?.fontFamily,
          fontWeight: blockStyle?.font?.fontWeight,
        }}
      />
    </DraggableBlock>
  );
}
