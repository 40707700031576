import React, { useContext } from 'react';
import AssetBlock, { emptyAssetBlock } from './AssetBlock';
import AssetBlockSource from './AssetBlockSource';

import { PlatoonContext } from "../../PlatoonContext";
import { AssetType } from "../../../models/Asset";

interface Props {
  assetType: AssetType;
}

export default function AssetBlockPicker({ assetType }: Props) {
  const { blockEditSession, setBlockEditSession } = useContext(PlatoonContext);

  const assetBlock = blockEditSession.blockData as AssetBlock || emptyAssetBlock(assetType);

  const setAssetBlock = (assetBlock: AssetBlock | undefined) => {
    let s = { ...blockEditSession };
    s.errors = assetBlock?.errors;
    s.warnings = assetBlock?.warnings;
    s.blockData = assetBlock;
    setBlockEditSession(s);
  };

  return (
    <AssetBlockSource assetBlock={assetBlock} setAssetBlock={setAssetBlock} />
  );
}

