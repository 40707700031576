import React from "react";
import { BlockParam } from "../../../../../../asset-generator-lib";
import { Sizes } from "../../../../../../asset-generator-lib/composer/models/Block";
import { getClosestBreakpoint } from "../../../../../shared/helpers/breakpoint";
import { useTemplatePageEditorContext } from "../../../TemplatePageEditor/TemplatePageEditor";
//import styles from './GridItemWrapper.module.scss'

type Props = {
  layout?: BlockParam["layout"];
};

const GridItemWrapper: React.FC<Props> = ({ children, layout }) => {
  const { currentBreakpoint } = useTemplatePageEditorContext();

  const closestBreakpoint: keyof Sizes<number> =
    getClosestBreakpoint(currentBreakpoint, layout?.size) || currentBreakpoint;

  return (
    <div
      style={{
        gridColumn: `auto / span ${
          (layout?.size && layout?.size[closestBreakpoint]) || 12
        }`,
      }}
    >
      {children}
    </div>
  );
};

export default GridItemWrapper;
