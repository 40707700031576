import React, { useContext } from "react";
import {
  makeStyles,
  MenuItem,
  Popper,
  Paper,
  useTheme,
} from "@material-ui/core";
import TrashButton from "./Buttons.tsx/TrashButton";
import EditButton from "./Buttons.tsx/EditButton";
import { PlatoonContext, HoverActions } from "./PlatoonContext";

const useStyles = makeStyles(() => ({
  hoverButton: {
    borderRadius: "50%",
    backgroundColor: "white",
    height: "60px",
    pointerEvents: "auto",
  },
  popper: {
    pointerEvents: "none",
    zIndex: 1,
  },

  paper: {
    backgroundColor: "white",
    borderRadius: "50%",
  },
}));

interface Props {
  actions: HoverActions;
  children: any;
}

export default function Hover(props: Props) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<JSX.Element | null>(null);
  const { readOnly } = useContext(PlatoonContext);
  const handlePopoverOpen = (event: any) => {
    if (readOnly) {
      return event;
    }
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isHovering = Boolean(anchorEl);
  const hoverColor = isHovering ? "#6c6c6c80" : "";
  const hoverBorder = isHovering
    ? `2px solid ${theme.palette.primary.main}`
    : "2px solid transparent";

  return (
    <div
      onMouseLeave={handlePopoverClose}
      onMouseEnter={handlePopoverOpen}
      style={{ backgroundColor: hoverColor, border: hoverBorder }}
    >
      {props.children}

      {anchorEl && (
        <HoverMenu
          handlePopoverClose={handlePopoverClose}
          actions={props.actions}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
    </div>
  );
}

interface HoverMenuProps {
  handlePopoverClose: () => void;
  actions: HoverActions;
  anchorEl: any;
  setAnchorEl: any;
}

function HoverMenu({ actions, anchorEl, setAnchorEl }: HoverMenuProps) {
  const classes = useStyles();
  const open = Boolean(anchorEl);

  function deleteBlock(event: any) {
    event.preventDefault();
    actions.deleteBlock();
    setAnchorEl(null);
  }

  function editBlock(event: any) {
    event.preventDefault();
    actions.editBlock();
  }

  return (
    <React.Fragment>
      <Popper
        className={classes.popper}
        placement="top-start"
        modifiers={{
          flip: { enabled: false },
          offset: {
            enabled: true,
            offset: "10px, -45%",
          },
        }}
        anchorEl={anchorEl}
        open={open}
      >
        <Paper className={classes.paper}>
          <MenuItem className={classes.hoverButton} onClick={editBlock}>
            <EditButton />
          </MenuItem>
        </Paper>
      </Popper>
      <Popper
        className={classes.popper}
        placement="bottom-start"
        modifiers={{
          flip: { enabled: false },
          offset: {
            enabled: true,
            offset: "10px, -45%",
          },
        }}
        anchorEl={anchorEl}
        open={open}
      >
        <Paper className={classes.paper}>
          <MenuItem className={classes.hoverButton} onClick={deleteBlock}>
            <TrashButton />
          </MenuItem>
        </Paper>
      </Popper>
    </React.Fragment>
  );
}

export { HoverMenu };
