import { isWidthUp, makeStyles, withWidth } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React from "react";
import { Outlet } from "react-router-dom";

type Props = {
  width: Breakpoint;
};

type StyleProps = {
  width: Breakpoint;
};

const useStyles = makeStyles(() => ({
  loadingContainer: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#EEE",
  },
  platoonContainer: {
    display: "flex",
    height: "100%",
  },
  platoonContentWrapper: {
    flex: 1,
    minWidth: 0,
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0 16px",
    maxWidth: ({ width }: StyleProps) =>
      isWidthUp("sm", width) ? "850px" : undefined,
  },
}));

const Layout = ({ width }: Props) => {
  const classes = useStyles({ width });

  return (
    <div data-iframe-height className={classes.platoonContainer}>
      <div className={classes.platoonContentWrapper}>
        <Outlet />
      </div>
    </div>
  );
};

export default withWidth()(Layout);
