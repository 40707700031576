import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

type Props =
  | {
      height?: string;
      size?: string | number;
      color?: "primary" | "secondary";
      customColor?: never;
    }
  | {
      height?: string;
      size?: string | number;
      color: "inherit";
      customColor?: string;
    };

type StyleProps = {
  height: string;
  customColor: string | undefined;
};

const useStyles = makeStyles(() => ({
  loader: {
    width: "100%",
    height: (props: StyleProps) => props.height,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: ({ customColor }) => customColor,
  },
}));

const Loading = ({
  height = "100%",
  size = 80,
  color = "inherit",
  customColor = "white",
}: Props) => {
  const classes = useStyles({ height, customColor });

  return (
    <div className={classes.loader}>
      <CircularProgress size={size} color={color} />
    </div>
  );
};

export default Loading;
