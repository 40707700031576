type Asset = {
  id?: string;
  description?: string;
  attachment: string; // full url
  thumbnail: string; // thumbnail image url
  type: AssetType; //filetype image | video
};

export default Asset;

enum AssetType {
  image = "image",
  video = "video",
}

const emptyAsset = (): Asset => {
  return {
    description: "",
    attachment: "",
    thumbnail: "",
    type: AssetType.image,
  };
};

const copyAsset = (asset: Asset) => {
  return Object.assign({}, asset);
};

export { AssetType, copyAsset, emptyAsset };
