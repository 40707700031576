import { Logger } from '../shared/SafeLogger';
import { CMSNode } from './NodeSchema';
import API from '../shared/API';
import { UserRole } from '../shared/ResourceSchema';

interface Relation {
  child_id: string;
  parent_id: string;
  order: number;
}

function getRelations(nodes: CMSNode[], parent: CMSNode): Relation[] {
  var relations = [] as Relation[];

  if (nodes) {
    nodes.forEach((node, index) => {
      let relation = relationForChild(node, index, parent);
      if (relation) {
        relations.push(relation);
      }

      if (node.children) {
        let childRelations = getRelations(node.children, node);
        relations = relations.concat(childRelations);
      }

    });
  }

  return relations;
}


function relationForChild(child: CMSNode, order: number, parent: CMSNode): Relation | undefined {
  if (child.id && parent.id) {
    return { child_id: child.id, parent_id: parent.id, order: order };
  }
  return undefined;
}


async function postRelationsForChildren(nodes: CMSNode[], parent: CMSNode) {

  const relations = getRelations(nodes, parent);
  const body = { relations: relations };
  const jsonBody = JSON.stringify(body);

  Logger.of('App').info("JSON BODY", jsonBody);
  let options = {
    method: "POST",
    body: jsonBody,
    headers: { "Content-Type": "application/json" }
  } as any

  return API.fetch("nodes/update-order", UserRole.Admin, options)
}


export default Relation;
export { getRelations, postRelationsForChildren }
