import React from "react";
import { Link } from "react-router-dom";
import styles from "./NoTemplatePages.module.scss";
import { ReactComponent as PersonCircle } from "../../../shared/icons/person-circle.svg";

type Props = {
  linkObj?: {
    buttonText: string;
    emptyText: string;
    to: string;
  };
};

// will need refactor when we have another template page type
const NoTemplatePages = ({ linkObj }: Props) => {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>
        <PersonCircle />
      </div>
      <p>
        <Link to={linkObj?.to || ""}>{linkObj?.emptyText} </Link> to introduce
        yourself to your fans.
      </p>
    </div>
  );
};

export default NoTemplatePages;
