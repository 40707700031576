import React, { useEffect } from "react";
import {
  Grid,
  isWidthDown,
  isWidthUp,
  makeStyles,
  withWidth,
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { Outlet } from "react-router-dom";
import Header from "../../shared/Header";
import Layout from "../../shared/Layout";
import ProgressBar from "./ProgressBar";
import { usePromoPackContext } from "../providers/PromoPackProvider";
import { emptyPromoPack } from "../models/PromoPack";
import { usePlatoonReleaseContext } from "../../providers/PlatoonReleaseProvider";
import Loading from "../../shared/Loading";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { useParams } from "react-router-dom";

type Props = {
  width: Breakpoint;
  match?: any;
};

type StyleProps = {
  width: Breakpoint;
};

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    justifyContent: "center",
  },
  contentContainer: {
    padding: theme.spacing(3),
    maxWidth: "1000px",
    overflow: "hidden",
  },
  progressContainer: {
    maxWidth: "840px",
    padding: (props: StyleProps) =>
      isWidthDown("xs", props.width) ? "0 30px" : "",
  },
  links: {
    display: "flex",
    justifyContent: "space-around",
    margin: "30px 0",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
}));

const PromoPackCreator = (props: Props) => {
  const width = props.width;
  const classes = useStyles({ width });
  const { artistId, packId } = useParams();

  const { fetchPromoPackWithRelease, setSavingMode, promoPack, dispatch } =
    usePromoPackContext();

  const { setLoading } = useLoadingContext();
  const { fetchReleases } = usePlatoonReleaseContext();

  useEffect(() => {
    if (packId !== "new") setSavingMode("EDIT");
    else setSavingMode("CREATE");
  }, [artistId, packId]);

  useEffect(() => {
    // if we have a pack id, tell the provider to get it
    // this is when we should be fetching releases
    if (artistId && packId && packId !== "new") {
      setLoading(true);
      fetchReleases(artistId);
      fetchPromoPackWithRelease(artistId, packId).then(() => setLoading(false));
    } else if (artistId && packId === "new") {
      setLoading(true);
      fetchReleases(artistId).then(() => setLoading(false));
      dispatch({ type: "SET_PROMOPACK", payload: emptyPromoPack() });
    }
  }, [artistId]);

  // DOM structure is different on mobile vs desktop
  if (isWidthUp("sm", props.width)) {
    // desktop
    return (
      <Layout boxPropsOveride={{ pb: 14 }}>
        <Grid container className={classes.pageContainer}>
          {isWidthUp("sm", props.width) && (
            <Grid item xs={12}>
              <Header text="Asset Generator" />
            </Grid>
          )}
          <Grid item xs={12} className={classes.progressContainer}>
            <ProgressBar />
          </Grid>
          <Grid item xs={12} className={classes.contentContainer}>
            {promoPack ? (
              <Outlet />
            ) : (
              <Loading height={"calc(100vh - 500px)"} />
            )}
          </Grid>
        </Grid>
      </Layout>
    );
  } else {
    //mobile
    return (
      <>
        <ProgressBar />
        <Layout boxPropsOveride={{ pt: 3, pb: 14 }}>
          {promoPack ? <Outlet /> : <Loading height={"calc(100vh - 500px)"} />}
        </Layout>
      </>
    );
  }
};

export default withWidth()(PromoPackCreator);
