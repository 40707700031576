interface KeyedObject {
  key: string; //describes how to find/reference this object
}

function objectForKey<T extends KeyedObject>(
  key: string,
  objects?: T[]
): T | undefined {
  if (!objects) {
    return undefined;
  }
  const results = objects.filter((object) => object.key === key);
  if (results.length < 1) {
    return undefined;
  } else if (results.length > 1) {
  }

  return results[0];
}

export { objectForKey };
export default KeyedObject;
