import React from "react";
import { Grid } from "@material-ui/core";
import ArtistLinkDivider from "../LinkEditComponents/ArtistLinkDivider";
import ArtistLinkCollapseSection from "../LinkEditComponents/ArtistLinkCollapseSection";
import ArtistName from "../LinkEditLayout/ArtistName";
import UploadTracks from "../LinkEditLayout/UploadTracks/UploadTracks";
import PageDesign from "../LinkEditLayout/PageDesign";
import LaunchDate from "../LinkEditLayout/LaunchDate";
import AlbumArtwork from "../LinkEditLayout/AlbumArtwork";
import HeaderDescription from "../LinkEditLayout/HeaderDescription";
import ArtistSocials from "../LinkEditLayout/ArtistSocials";
import SitePassword from "../LinkEditLayout/ArtistSecurity/SitePassword";
import ArtistAudioDownload from "../LinkEditLayout/ArtistAudioDownload";
import { Track } from "../models/ArtistLink";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Asset } from "../../platoon-cms-lib";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

interface Props {
  width: string;
  forceOpenCollapse?: string[];
  setForceOpenCollapse?: React.Dispatch<React.SetStateAction<string[]>>;
}

export type Update = {
  name: string;
  id?: string;
  value: string;
};

export default function FormFields(props: Props) {
  const { artistLink, dispatch } = useArtistLinkContext();

  const onChange = (update: Update) => {
    props.setForceOpenCollapse && props.setForceOpenCollapse([]);
    let linkInput = { ...artistLink } as any;
    linkInput[update.name] = update.value;

    dispatch({
      type: "SET_ARTISTLINK",
      payload: linkInput,
    });
  };

  ValidatorForm.addValidationRule("isPasswordMatch", (value: string) => {
    return value === artistLink?.password;
  });

  ValidatorForm.addValidationRule(
    "isAudioDownloadPasswordMatch",
    (value: string) => {
      return value === artistLink.audioDownloadPassword;
    }
  );

  ValidatorForm.addValidationRule("hasTracks", (value: Track[]) => {
    return value.length >= 1;
  });

  ValidatorForm.addValidationRule("validEndDate", (value: Date) => {
    if (artistLink?.startDateTime) {
      return artistLink?.startDateTime < value; // since artistLink can be undefined, we cant chack if its less than value
    } else {
      return false; // if it is undefined, just return false
    }
  });

  ValidatorForm.addValidationRule("isURL", (value: string) => {
    if (value === "") return true;
    else
      return Boolean(
        value.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        )
      );
  });

  ValidatorForm.addValidationRule("hasArtistAsset", (value: Asset) => {
    return Boolean(value);
  });

  ValidatorForm.addValidationRule("hasTrackTitle", (value: string) => {
    if (value === "") return false;
    else return true;
  });

  return (
    <div>
      <Grid container style={{ paddingBottom: "30px" }}>
        <ArtistName onChange={onChange} />

        <ArtistLinkDivider />

        <UploadTracks />

        <ArtistLinkDivider />

        <ArtistLinkCollapseSection header={"ALBUM ARTWORK"}>
          <AlbumArtwork />
        </ArtistLinkCollapseSection>

        <ArtistLinkDivider />

        <PageDesign />

        <ArtistLinkDivider />

        <LaunchDate />

        <ArtistLinkDivider />

        <ArtistLinkCollapseSection header={"HEADER & DESCRIPTION"}>
          <HeaderDescription onChange={onChange} />
        </ArtistLinkCollapseSection>

        <ArtistLinkDivider />

        <ArtistLinkCollapseSection
          validationRule={["artistSocials"]}
          forceOpenCollapse={props.forceOpenCollapse}
          setForceOpenCollapse={props.setForceOpenCollapse}
          header={"ARTIST LINKS"}
        >
          <ArtistSocials setForceOpenCollapse={props.setForceOpenCollapse} />
        </ArtistLinkCollapseSection>

        <ArtistLinkDivider />

        <ArtistLinkCollapseSection
          validationRule={["password", "confirmPassword"]}
          forceOpenCollapse={props.forceOpenCollapse}
          setForceOpenCollapse={props.setForceOpenCollapse}
          header={"PASSWORD PROTECTION"}
        >
          <SitePassword
            setForceOpenCollapse={props.setForceOpenCollapse}
            onChange={onChange}
          />
        </ArtistLinkCollapseSection>

        <ArtistLinkDivider />

        <ArtistLinkCollapseSection
          validationRule={[
            "audioDownloadPassword",
            "confirmAudioDownloadPassword",
          ]}
          forceOpenCollapse={props.forceOpenCollapse}
          setForceOpenCollapse={props.setForceOpenCollapse}
          header={"ENABLE DOWNLOADS"}
        >
          <ArtistAudioDownload
            setForceOpenCollapse={props.setForceOpenCollapse}
            onChange={onChange}
          />
        </ArtistLinkCollapseSection>
      </Grid>
    </div>
  );
}
