import { createContext } from 'react'

export const PlatoonContext = createContext<any>(undefined)

interface BlockEditSession {
  blockType: string;       // The type of the entity used by the content editor and modal
  blockData?: any;         // The structured object to persist in the content editor. This should contain everything needed to reconstruct an editing session.
  originalBlockData?: any; // Upon updating an existing block, store original data here so components can detect when the block has changed.
  entityKey?: string;      // A reference to the actual stored entity in content. Needed so content can be replaced.
  frame?: string
  errors?: string[];       // Critical errors that prevent the user from saving
  warnings?: string[];     // Less serious validation problems, but still prevent saving, but with helpful hints
  showWarnings: boolean;   // Declare whether warnings should be shown
  onPrepareSave?: () => Promise<BlockEditSession>;
}

function emptyBlockEditSession(blockType: string) {
  return { blockType: blockType }; // The minimum common denominator for starting a session
}

export { BlockEditSession, emptyBlockEditSession };

interface HoverActions {
  editBlock: () => void;
  deleteBlock: () => void;
}


export { HoverActions };
