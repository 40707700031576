import React from "react";
import { Helmet } from "react-helmet";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Redirect from "./Redirect/Redirect";

const RedirectApp = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Routes>
        <Route path={"apps/:subapp/*"} element={<Redirect />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RedirectApp;
