type AggregateEvent = {
  actionType: string;
  contentType: string;
  contentId: string | undefined;
  lastAggregateAction: string;
  valueType: string;
  value: number;
};

const basePath = "/api/v1/aggregates";

const trackAggregatesEvent = (event: AggregateEvent) => {
  const path = `${basePath}/${event.contentType}/${event.contentId}/records`;

  const options = {
    method: "POST",
    body: JSON.stringify(event),
    headers: { "Content-Type": "application/json" },
  };

  fetch(path, options);
};

type AggregateFetch = {
  contentType: string;
  contentId: string | undefined;
};

const fetchAggregrateData = async (event: AggregateFetch) => {
  const path = `${basePath}/${event.contentType}/${event.contentId}/records`;
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  const aggregrateData = await fetch(path, options).then((res) => {
    return res.json();
  });

  return aggregrateData;
};

export { trackAggregatesEvent, fetchAggregrateData, AggregateEvent };
