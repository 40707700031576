import {
  adaptToFrontend,
  adaptTrackToFrontend,
} from "../../artists/ArtistSchema";
import API from "../../shared/API";
import { defaultResourceSchema, UserRole } from "../../shared/ResourceSchema";
import { BackgroundMode, Track } from "../models/ArtistLink";

export const populateFromRelease = async (artistLink: any, release: any) => {
  const updatedArtistLink = { ...artistLink };
  const artistId = artistLink.artist.id;

  if (release.artworkUrl) {
    artistLink.backgroundMode = BackgroundMode.blur;
  }

  const tracks = release.assets.map((asset: any, index: number) => {
    if (asset.metadata) {
      return {
        id: asset.id,
        albumName: release.name ? release.name : "",
        albumArtwork: release.artworkUrl ? release.artworkUrl : "",
        name: asset.title ? asset.title : `Track ${index + 1}`,
        tempSrc: asset.metadata.url ? asset.metadata.url : "",
        lyrics: asset.lyrics ? asset.lyrics : "",
        artistName: asset.artistName ? asset.artistName : "",
        genre: release.genre?.name ? release.genre.name : "",
      };
    } else
      return {
        name: "NO AUDIO UPLOADED",
        tempSrc: "NONE",
      };
  });

  const uploadTracks = async (tracks: any) => {
    const promises = tracks.map((track: any) => {
      return trackApiEndpoint(track, artistId);
    });
    const results = await Promise.all(promises);
    handleTrackResults(results);
  };

  const handleTrackResults = (results: any) => {
    const tracks = results.map((result: Track) => {
      return { ...result, attachment_data: { metadata: { duration: 0 } } };
    });

    updatedArtistLink.tracks.push(...tracks);

    const trackLinkMap = [] as any;
    results.map((track: Track, idx: number) => {
      trackLinkMap.push({ trackId: track.id, position: idx });
    });
    updatedArtistLink.trackLinks.push(...trackLinkMap);
    return updatedArtistLink;
  };

  const uploadAlbumArtwork = async () => {
    const formData = new FormData();
    formData.append("custom_attachment", release.originalArtworkUrl);
    formData.append("description", "Album Cover");
    formData.append("type", "image");
    formData.append("p4a_release_id", release.id);
    const results = await API.create(
      formData,
      defaultResourceSchema(`/artists/${artistId}/assets`, UserRole.Artist, {
        toFrontend: adaptToFrontend,
      })
    );
    handleAlbumArtworkResults(results);
  };

  const handleAlbumArtworkResults = (results: any) => {
    updatedArtistLink.albumArtwork = results;
    updatedArtistLink.albumArtworkID = results.id;
    updatedArtistLink.backgroundMode = BackgroundMode.blur;
    return updatedArtistLink;
  };

  await uploadAlbumArtwork();
  // TODO: use the uploaded album artwork here when uploading the tracks
  await uploadTracks(tracks);
  return updatedArtistLink;
};

const trackApiEndpoint = async (track: any, artistId: string) => {
  const formData = new FormData();

  formData.append("temp_src", track.tempSrc); // signed url from symphony
  formData.append("title", track.name);
  formData.append("p4a_asset_id", track.id);
  formData.append("metadata[albumName]", track.albumName);
  formData.append("metadata[albumArtwork]", track.albumArtwork); // TODO: this needs to be our asset created by uploadAlbumArtwork().
  formData.append("metadata[lyrics]", track.lyrics);
  formData.append("metadata[artistName]", track.artistName);
  formData.append("metadata[genre]", track.genre);

  return API.create(
    formData,
    defaultResourceSchema(`/artists/${artistId}/tracks`, UserRole.Artist, {
      toFrontend: adaptTrackToFrontend,
    })
  );
};
