import { Block, Template, TemplateVariant, } from '../../../asset-generator-lib';
import { ShapeType } from '../../../asset-generator-lib/composer/models/Block';

const backgroundBlock: Block = {
  id: 'echo_BGB',
  key: 'echo_BGB',
  type: 'SPRITE',
  frame: { origin: { x: 50, y: 50 }, size: { w: 1950, h: 1950 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
    { key: 'background', name: 'Background Color', type: "STATIC", value: '#000001' }
  ],
};

const albumArtworkBlock: Block = {
  id: 'echo_AAB',
  key: 'echo_AAB',
  type: 'SPRITE',
  frame: { origin: { x: 50, y: 50 }, size: { w: 850, h: 850 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
    { key: 'image', name: "Album Artwork", type: 'MAPPED', mappedPath: "release.artworkUrl" }
  ],
}

const textBox: Block = {
  id: 'echo_TB',
  key: 'echo_TB',
  type: 'TEXTBOX',
  params: [
    { key: 'text', name: 'Release Name', type: 'MAPPED', defaultValue: 'Release Name', mappedPath: "release.name" }
  ],
  frame: { origin: { x: 50, y: 72.5 }, size: { h: 100, w: 700 }, anchor: { x: 0.5, y: 0.5 } },
  children: [
    {
      key: '34',
      id: '34',
      type: 'TEXT',
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: '23',
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {
      }
    }
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "FFFFFF", borderSize: 5 },
    padding: { padding: 40 },
    font: {
      fontColor: '#ffffff',
      fontSize: '50px',
      fontFamily: "Futura",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}

const artistName: Block = {
  id: 'echo_AN',
  key: 'echo_AN',
  type: 'TEXT',
  frame: { origin: { x: 50, y: 25 }, anchor: { x: 0.5, y: 1 }, size: { w: 700, h: 0 } },
  params: [
    {
      key: 'text', name: "Artist Name", type: 'MAPPED', defaultValue: "Artist Name", mappedPath: 'release.artistName'
    },
  ],
  style: {
    border: { borderColor: "000000", borderSize: 0 },
    font: {
      fontColor: '#ffffff',
      fontSize: '70px',
      fontFamily: "Futura",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}

const outNow1: Block = {
  id: 'echo_ON1',
  key: 'echo_ON1',
  type: 'TEXT',
  frame: { origin: { x: 50, y: 7.5 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
    {
      key: 'allText', name: "Marketing Message", type: 'SELECT', options: [
        { key:'outNow', name: "Out Now", type: 'STATIC', value: 'OUT NOW' },
        { key:'usRealeaseDate', name: 'US Release Date', type: "MAPPED", mappedPath: 'release.releaseDate' },
        { key:'ukReleaseDate', name: 'UK Release Date ', type: "MAPPED", mappedPath: 'release.releaseDateUK' }
      ]
    },
  ],
  style: {
    alpha: 0.25,
    border: { borderColor: "FFFFFF", borderSize: 5 },
    font: {
      fontColor: '000000',
      fontSize: '190px',
      fontFamily: "Helvetica",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}

const outNow2: Block = {
  id: 'echo_ON2',
  key: 'echo_ON2',
  type: 'TEXT',
  frame: { origin: { x: 50, y: 27 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
  ],
  style: {
    alpha: 0.75,
    border: { borderColor: "FFFFFF", borderSize: 5 },
    font: {
      fontColor: '000000',
      fontSize: '190px',
      fontFamily: "Helvetica",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}

const outNow3: Block = {
  id: 'echo_ON3',
  key: 'echo_ON3',
  type: 'TEXT',
  frame: { origin: { x: -100, y: -100 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
  ],
  style: {
    border: { borderColor: "FFFFFF", borderSize: 5 },
    font: {
      fontColor: '000000',
      fontSize: '190px',
      fontFamily: "Helvetica",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}

const outNow4: Block = {
  id: 'echo_ON4',
  key: 'echo_ON4',
  type: 'TEXT',
  frame: { origin: { x: -100, y: -100 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
  ],
  style: {
    border: { borderColor: "FFFFFF", borderSize: 5 },
    font: {
      fontColor: '000000',
      fontSize: '190px',
      fontFamily: "Helvetica",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}

const outNow5: Block = {
  id: 'echo_ON5',
  key: 'echo_ON5',
  type: 'TEXT',
  frame: { origin: { x: -100, y: -100 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
  ],
  style: {
    border: { borderColor: "FFFFFF", borderSize: 5 },
    font: {
      fontColor: '000000',
      fontSize: '190px',
      fontFamily: "Helvetica",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}

const outNow6: Block = {
  id: 'echo_ON6',
  key: 'echo_ON6',
  type: 'TEXT',
  frame: { origin: { x: 50, y: 82.5 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
  ],
  style: {
    border: { borderColor: "FFFFFF", borderSize: 0 },
    alpha: 1,
    font: {
      fontColor: '#ffffff',
      fontSize: '190px',
      fontFamily: "Helvetica",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}

const outNow7: Block = {
  id: 'echo_ON7',
  key: 'echo_ON7',
  type: 'TEXT',
  frame: { origin: { x: 50, y: 92.5 }, anchor: { x: 0.5, y: 0.5 } },
  params: [
  ],
  style: {
    alpha: .75,
    border: { borderColor: "FFFFFF", borderSize: 5 },
    font: {
      fontColor: '000000',
      fontSize: '190px',
      fontFamily: "Helvetica",
      fontWeight: '600',
    },
    rotation: { angle: 0 }
  }
}


const echoFacebook: TemplateVariant = {
  name: "Facebook Cover",
  key: 'facebookCover',
  size: { w: 820, h: 360 },
  blockOverrides: [
    {
      key: 'echo_BGB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 820, h: 820 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'echo_AAB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 150, h: 150 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'echo_TB',
      type: "TEXTBOX",
      frame: { origin: { x: 50, y: 70 }, size: { w: 200, h: 30 }, anchor: { x: 0.5, y: .5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 2.5 },
        padding: { padding: 10 },
        font: {
          fontColor: '#ffffff',
          fontSize: '20px',
          fontFamily: "Futura",
          fontWeight: '500',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_AN',
      type: "TEXT",
      frame: { origin: { x: 50, y: 22.5 }, anchor: { x: 0.5, y: 0.5 }, size: { w: 225, h: 0 } },
      style: {
        border: { borderColor: "000000", borderSize: 0 },
        padding: { padding: 10 },
        font: {
          fontColor: '#ffffff',
          fontSize: '40px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON1',
      type: "TEXT",
      frame: { origin: { x: 25, y: 15 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 5 },
        alpha: 0.25,
        font: {
          fontColor: '000000',
          fontSize: '60px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON2',
      type: "TEXT",
      frame: { origin: { x: 75, y: 15 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 5 },
        alpha: 0.25,
        font: {
          fontColor: '000000',
          fontSize: '60px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON3',
      type: "TEXT",
      frame: { origin: { x: 25, y: 40 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 5 },
        alpha: 0.5,
        font: {
          fontColor: '000000',
          fontSize: '60px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON4',
      type: "TEXT",
      frame: { origin: { x: 75, y: 40 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 5 },
        alpha: 0.5,
        font: {
          fontColor: '000000',
          fontSize: '60px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON5',
      type: "TEXT",
      frame: { origin: { x: 25, y: 65 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 5 },
        alpha: 0.75,
        font: {
          fontColor: '000000',
          fontSize: '60px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON6',
      type: "TEXT",
      frame: { origin: { x: 75, y: 65 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 5 },
        alpha: .75,
        font: {
          fontColor: '000000',
          fontSize: '60px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON7',
      type: "TEXT",
      frame: { origin: { x: 50, y: 85 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 0 },
        alpha: 1,
        font: {
          fontColor: '#ffffff',
          fontSize: '60px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
  ]
}

const echoSpotify: TemplateVariant = {
  name: "Spotify Header",
  key: 'spotifyHeader',
  size: { w: 2660, h: 1140 },
  blockOverrides: [
    {
      key: 'echo_BGB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 2700, h: 2700 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'echo_AAB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 450, h: 450 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'echo_TB',
      type: "TEXTBOX",
      frame: { origin: { x: 50, y: 70 }, size: { w: 525, h: 80 }, anchor: { x: 0.5, y: .5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 7.5 },
        padding: { padding: 20 },
        font: {
          fontColor: '#ffffff',
          fontSize: '60px',
          fontFamily: "Futura",
          fontWeight: '500',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_AN',
      type: "TEXT",
      frame: { origin: { x: 50, y: 25 }, anchor: { x: 0.5, y: 0.5 }, size: { w: 550, h: 0 } },
      style: {
        border: { borderColor: "000000", borderSize: 0 },
        padding: { padding: 10 },
        font: {
          fontColor: '#ffffff',
          fontSize: '80px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON1',
      type: "TEXT",
      frame: { origin: { x: 50, y: 12.5 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.5,
        font: {
          fontColor: '000000',
          fontSize: '115px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON2',
      type: "TEXT",
      frame: { origin: { x: 50, y: 32.5 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.5,
        font: {
          fontColor: '000000',
          fontSize: '115px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON3',
      type: "TEXT",
      frame: { origin: { x: 50, y: 45 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.5,
        font: {
          fontColor: '000000',
          fontSize: '115px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON4',
      type: "TEXT",
      frame: { origin: { x: 50, y: 57.5 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.5,
        font: {
          fontColor: '000000',
          fontSize: '115px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON5',
      type: "TEXT",
      frame: { origin: { x: 50, y: 70 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.5,
        font: {
          fontColor: '000000',
          fontSize: '115px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON6',
      type: "TEXT",
      frame: { origin: { x: 50, y: 82.5 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 0 },
        alpha: 1,
        font: {
          fontColor: '#ffffff',
          fontSize: '115px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON7',
      type: "TEXT",
      frame: { origin: { x: 50, y: 95 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.5,
        font: {
          fontColor: '000000',
          fontSize: '115px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
  ]
}

const echoTwitter: TemplateVariant = {
  name: "Twitter Header",
  key: 'twitterHeader',
  size: { w: 1500, h: 500 },
  blockOverrides: [
    {
      key: 'echo_BGB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 1500, h: 1500 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'echo_AAB',
      type: "SPRITE",
      frame: { origin: { x: 35, y: 50 }, size: { w: 225, h: 225 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'echo_TB',
      type: "TEXTBOX",
      frame: { origin: { x: 35, y: 70 }, size: { w: 300, h: 40 }, anchor: { x: 0.5, y: .5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 5 },
        padding: { padding: 10 },
        font: {
          fontColor: '#ffffff',
          fontSize: '30px',
          fontFamily: "Futura",
          fontWeight: '500',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_AN',
      type: "TEXT",
      frame: { origin: { x: 35, y: 20 }, anchor: { x: 0.5, y: 0.5 }, size: { w: 325, h: 0 } },
      style: {
        border: { borderColor: "000000", borderSize: 0 },
        font: {
          fontColor: '#ffffff',
          fontSize: '50px',
          fontFamily: "Futura",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON1',
      type: "TEXT",
      frame: { origin: { x: 65, y: 5 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.075,
        font: {
          fontColor: '000000',
          fontSize: '90px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON2',
      type: "TEXT",
      frame: { origin: { x: 65, y: 20 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.15,
        font: {
          fontColor: '000000',
          fontSize: '90px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON3',
      type: "TEXT",
      frame: { origin: { x: 65, y: 35 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.3,
        font: {
          fontColor: '000000',
          fontSize: '90px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON4',
      type: "TEXT",
      frame: { origin: { x: 65, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.6,
        font: {
          fontColor: '000000',
          fontSize: '90px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON5',
      type: "TEXT",
      frame: { origin: { x: 65, y: 65 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 0 },
        alpha: 1,
        font: {
          fontColor: '#ffffff',
          fontSize: '90px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON6',
      type: "TEXT",
      frame: { origin: { x: 65, y: 80 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: .6,
        font: {
          fontColor: '000000',
          fontSize: '90px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON7',
      type: "TEXT",
      frame: { origin: { x: 65, y: 95 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.3,
        font: {
          fontColor: '000000',
          fontSize: '90px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    }
  ]
}

const echoYoutube: TemplateVariant = {
  name: "Youtube Cover",
  key: 'youtubeCover',
  size: { w: 2560, h: 1440 },
  blockOverrides: [
    {
      key: 'echo_BGB',
      type: "SPRITE",
      frame: { origin: { x: 50, y: 50 }, size: { w: 2600, h: 2600 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'echo_AAB',
      type: "SPRITE",
      frame: { origin: { x: 35, y: 50 }, size: { w: 250, h: 250 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: 'echo_TB',
      type: "TEXTBOX",
      frame: { origin: { x: 35, y: 58 }, size: { w: 525, h: 10 }, anchor: { x: 0.5, y: .5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 7.5 },
        padding: { padding: 60 },
        font: {
          fontColor: '#ffffff',
          fontSize: '50px',
          fontFamily: "Futura",
          fontWeight: '500',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_AN',
      type: "TEXT",
      frame: { origin: { x: 35, y: 41.5 }, anchor: { x: 0.5, y: 1 }, size: { w: 550, h: 0 } },
      style: {
        border: { borderColor: "000000", borderSize: 0 },
        padding: { padding: 10 },
        font: {
          fontColor: '#ffffff',
          fontSize: '80px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON1',
      type: "TEXT",
      frame: { origin: { x: 62.5, y: 5 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.1,
        font: {
          fontColor: '000000',
          fontSize: '135px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON2',
      type: "TEXT",
      frame: { origin: { x: 62.5, y: 20 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.2,
        font: {
          fontColor: '000000',
          fontSize: '135px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON3',
      type: "TEXT",
      frame: { origin: { x: 62.5, y: 35 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.35,
        font: {
          fontColor: '000000',
          fontSize: '135px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON4',
      type: "TEXT",
      frame: { origin: { x: 62.5, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.5,
        font: {
          fontColor: '000000',
          fontSize: '135px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON5',
      type: "TEXT",
      frame: { origin: { x: 62.5, y: 65 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 0 },
        alpha: 1,
        font: {
          fontColor: '#ffffff',
          fontSize: '135px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON6',
      type: "TEXT",
      frame: { origin: { x: 62.5, y: 80 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: .5,
        font: {
          fontColor: '000000',
          fontSize: '135px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
    {
      key: 'echo_ON7',
      type: "TEXT",
      frame: { origin: { x: 62.5, y: 95 }, anchor: { x: 0.5, y: 0.5 } },
      style: {
        border: { borderColor: "FFFFFF", borderSize: 10 },
        alpha: 0.35,
        font: {
          fontColor: '000000',
          fontSize: '135px',
          fontFamily: "Helvetica",
          fontWeight: '600',
        },
        rotation: { angle: 0 }
      }
    },
  ]
}

const echoInstagram = {
  name: "Instagram Story",
  key: 'instagramStory',
  size: { w: 1080, h: 1920 }
} as TemplateVariant

const Echo = {
  id: 'echo',
  name: "Echo",
  blocks: [
    backgroundBlock,
    outNow1,
    outNow2,
    outNow3,
    outNow4,
    outNow5,
    outNow6,
    outNow7,
    albumArtworkBlock,
    textBox,
    artistName,
  ],
  meta: { version: "1.0", basis: { w: 100, h: 100 } },
  variants: [echoInstagram, echoFacebook, echoSpotify, echoTwitter, echoYoutube],
} as Template;

export default Echo;
