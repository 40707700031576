import React from "react";
import { useEffectOnce } from "react-use";
import { InputProps } from "../helpers";

//
const MappedInput = ({ param, update, resolvedValue }: InputProps) => {
  useEffectOnce(() => {
    update({ ...param, value: resolvedValue });
  });
  return <></>;
};

export default MappedInput;
