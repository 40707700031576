import { JSONParseSafely } from "../bundles/shared/helpers/helpers";

export function findIndex(obj: any, i: any) {
  // if the object doesnt exist, return an undefined so we dont try to find undefined[i]
  if (obj) {
    return JSONParseSafely(obj[i]) || obj[i];
  } else {
    return undefined;
  }
}

export function resolveMappedPath(path: string, obj: object) {
  /*
    Splits a.b.c into an array of ["a", "b","c"],
    then uses .reduce to run above on supplied object

  .reduce Calls the specified callback function for all the
    elements in an array. The return value of the callback
    function is the accumulated result, and is provided as
    an argument in the next call to the callback function.

  */
  if (typeof path !== "string") {
    console.error("Supplied path is not a string:", path);
    return;
  }
  return path.split(".").reduce(findIndex, obj);
}
