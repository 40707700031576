import React from "react";
import styles from "./DragHandle.module.scss";
import { DraggableProps } from "react-beautiful-dnd";
import { ReactComponent as Line3HorizontalCircle } from "../../../../../shared/icons/line-3-horizontal-circle.svg";

type Props = {
  dragProps: DraggableProps;
  index?: number;
};

const DragHandle = ({ dragProps, index }: Props) => {
  return (
    <>
      <button
        style={{ touchAction: "none" }}
        {...dragProps}
        className="btn--naked flex-vert-center"
      >
        <Line3HorizontalCircle className={styles["drag-icon"]} />
      </button>
      {index && (
        <p className={styles["index"]}>
          {(index + 1).toLocaleString("en-us", { minimumIntegerDigits: 2 })}
        </p>
      )}
    </>
  );
};

export default DragHandle;
