import React, { useState, useEffect, useContext } from "react";
import { Logger } from "../shared/SafeLogger";
import { Link } from "react-router-dom";
import API from "./API";
import { SnackContext } from "../shared/SnackProvider";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import {
  Divider,
  Grid,
  Box,
  TableCell,
  TableRow,
  makeStyles,
  TableHead,
  TableBody,
  TableContainer,
  Table,
  Paper,
  Theme,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import ResourceSchema, {
  ResourceActions,
  defaultActions,
} from "./ResourceSchema";
import ItemIndexHeader from "./ItemIndexHeader";
import ActionDialog from "./ActionDialog";
import { useErrorHandlingContext } from "../providers/ErrorHandlingProvider";

interface ItemRenderProps {
  item: any;
  attr: string;
}

export { ItemRenderProps };

// Non-dependent styles
const useStyles = makeStyles((theme: Theme) => ({
  gridPadding: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  titlesWrapper: {
    display: "flex",
    whiteSpace: "nowrap",
  },
  actionLink: {
    marginLeft: "10px",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  stickyHeader: {
    position: "fixed",
    width: "calc(100% - 260px)",
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    padding: theme.spacing(2),
  },
  header: {
    // position: 'fixed',
    // width: 'calc(100% - 260px)',
    padding: theme.spacing(2),
  },
  addIconButton: {
    boxShadow: "none",
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main}80`,
    },
  },
  itemTitle: {
    maxWidth: "80%",
  },
}));

interface Props {
  schema: ResourceSchema;
  actions?: ResourceActions;
  renderers?: any;
}

function ItemIndex({ schema, renderers, actions }: Props) {
  actions = actions || defaultActions();

  const classes = useStyles();

  const { errorDispatch } = useErrorHandlingContext();
  const { setSnack } = useContext(SnackContext);

  const [items, setItems] = useState([]);
  const [pendingDeletionItem, setPendingDeletionItem] = useState(null);
  const [query, setQuery] = useState(undefined);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const apiPath = "/api/v1";

  const paths = {
    root: `${apiPath}/${schema.rootPath}`,
    newRedirect: schema.rootPath + "/new",
    editItem: (item: any) => {
      return `${schema.rootPath}/${item.id}`;
    },
    item: (item: any) => {
      return `${apiPath}/${schema.rootPath}/${item.id}`;
    },
  };
  Logger.of("App").info(paths);

  const headerCells = (attrs: string[]) => {
    return attrs.map((attr, index) => {
      const align = index === 0 ? "left" : "right";
      return (
        <TableCell key={index} className={classes.capitalize} align={align}>
          {attr}
        </TableCell>
      );
    });
  };

  const ItemCell = (props: any) => {
    let cellContent = props.item[props.attr];

    let Renderer = renderers ? renderers[props.attr] : null;
    if (Renderer) {
      cellContent = <Renderer {...props} />;
    }

    return props.attrIndex === 0 ? (
      <TableCell key={props.attrIndex} component="th" scope="row">
        {cellContent}
      </TableCell>
    ) : (
      <TableCell key={props.attrIndex} align="right">
        {cellContent}
      </TableCell>
    );
  };

  const ItemRow = (props: any) => {
    return (
      <TableRow key={props.index}>
        {schema.attrs.map((attr, attrIndex) => (
          <ItemCell
            key={attr}
            item={props.item}
            attr={attr}
            attrIndex={attrIndex}
          />
        ))}
        <TableCell key={"tools"} align="right">
          <div>
            {actions?.delete && (
              <Link onClick={() => handleDelete(props.item)} to="#">
                <DeleteIcon style={{ color: "white" }} />
              </Link>
            )}

            {actions?.edit && (
              <Link to={`/${schema.rootPath}/${props.item.id}`}>
                <EditIcon style={{ color: "white" }} />
              </Link>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const renderItems = (items: any) => {
    return items.map((item: any, index: number) => {
      return <ItemRow item={item} index={index} key={index} />;
    });
  };

  const onChangePage = (_: unknown, page: number) => {
    setPage(page);
  };

  function fetchItemList() {
    executeFetchItemList();
  }

  async function executeFetchItemList() {
    schema.query = query;

    Logger.of("App").info("Fetching with query, page", query, page);
    try {
      const response = await API.query(schema, page);
      const items = response.results;
      setItems(items);
      setPageCount(response.pagination.totalPages);
    } catch (error) {
      errorDispatch({
        type: "SET_ERROR",
        payload: { error: error, serveSnack: true },
      });
    }
  }

  useEffect(fetchItemList, []);
  useEffect(fetchItemList, [query, page]);

  const handleDelete = (item: any) => {
    setPendingDeletionItem(item);
  };

  function executeDelete(item: any) {
    API.delete(item.id, schema)
      .then(() => {
        setSnack({ message: `${schema.title} deleted successfully` });
        fetchItemList();
      })
      .catch((error: any) => {
        errorDispatch({
          type: "SET_ERROR",
          payload: { error: error, serveSnack: true },
        });
      });
  }

  function ItemIndexContent() {
    return (
      <>
        {actions?.index && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key="0">
                  {headerCells(schema.attrs)}
                  <TableCell key="tools" align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderItems(items)}</TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    );
  }

  return (
    <Grid
      className={classes.gridPadding}
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
    >
      <ItemIndexHeader onSearch={setQuery} schema={schema} actions={actions} />

      <Divider />

      <ItemIndexContent />

      {pendingDeletionItem && (
        <ActionDialog
          open={Boolean(pendingDeletionItem)}
          context={pendingDeletionItem}
          onClose={() => setPendingDeletionItem(null)}
          onAction={executeDelete}
          actionTitle="CONFIRM"
        >
          Are you sure you want to delete?
        </ActionDialog>
      )}

      {pageCount > 1 && (
        <Box p={4}>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Pagination
                page={page}
                onChange={onChangePage}
                count={pageCount}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
}

export default ItemIndex;
