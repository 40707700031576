import React from "react";
import { FormControlLabel, Box, RadioGroup, Radio } from "@material-ui/core";
import { defaultEndDateTime } from "../models/ArtistLink";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

interface Props {
  label: string;
  value: any;
  name: string;
  checkedValue: any;
}

export default function ArtistLinkRadioInput(props: Props) {
  const { artistLink, dispatch } = useArtistLinkContext();

  const str2bool = (value: any) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  function handleChange(event: any) {
    let updatedArtistLink = { ...artistLink } as any;
    updatedArtistLink[props.name] = str2bool(event.target.value);
    if (str2bool(event.target.value) === false && props.name === "expires") {
      updatedArtistLink["endDateTime"] = null;
    } else if (
      str2bool(event.target.value) === true &&
      props.name === "expires"
    ) {
      updatedArtistLink["endDateTime"] =
        artistLink?.startDateTime &&
        defaultEndDateTime(artistLink.startDateTime);
    }
    dispatch({
      type: "SET_ARTISTLINK",
      payload: updatedArtistLink,
    });
  }

  return (
    <Box pt={1}>
      <RadioGroup
        style={{ flexWrap: "nowrap" }}
        row
        value={props.value}
        onChange={handleChange}
      >
        <FormControlLabel
          name={props.name}
          checked={props.checkedValue === props.value}
          value={props.value}
          control={<Radio />}
          label={props.label}
        />
      </RadioGroup>
    </Box>
  );
}
