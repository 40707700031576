import { TemplatePageSection } from "../../../asset-generator-lib/composer/models/Block";
export const artistEpkTemplate: TemplatePageSection[] = [
  {
    key: "HEADER_V2",
    title: "header",
    required: true,
    startOpen: true,
    params: [
      {
        key: "artistName",
        name: "Artist Name",
        type: "RADIO",
        options: [
          {
            key: "textName",
            name: "text",
            type: "TEXT",
            mappedPath: "artist.name",
            validations: {
              type: "string",
              params: [
                { validation: "required", message: "Field is required" },
              ],
            },
          },
          {
            key: "logoName",
            name: "logo",
            type: "IMAGEUPLOAD",
            value: [],
            validations: {
              type: "array",
              params: [
                { validation: "required", message: "File is required" },
                { validation: "min", limit: 1, message: "File is required" },
                { validation: "max", limit: 1, message: "1 file limit" },
              ],
            },
          },
        ],
        value: {
          key: "textName",
          name: "text",
          type: "TEXT",
          mappedPath: "artist.name",
          validations: {
            type: "string",
            params: [{ validation: "required", message: "Field is required" }],
          },
        },
      },
      {
        key: "background",
        name: "background",
        type: "RADIO",
        options: [
          {
            key: "file",
            name: "image",
            type: "IMAGEUPLOAD",
            value: [],
            validations: {
              type: "array",
              params: [
                { validation: "required", message: "File is required" },
                { validation: "min", limit: 1, message: "File is required" },
                { validation: "max", limit: 1, message: "1 file limit" },
              ],
            },
          },
          {
            key: "video",
            name: "video",
            overrideName: "youtube video",
            type: "TEXT",
            value: "",
            validations: {
              type: "string",
              params: [
                {
                  validation: "matches",
                  limit:
                    "^(https?://)?(www.youtube.com|m.youtube.com|youtu.be)/.+$",
                  message:
                    "Must be a valid URL from youtube (including http://)",
                },
                { validation: "required", message: "Field is required" },
              ],
            },
          },
          { key: "color", name: "color", type: "COLOR", value: "#000000" },
        ],
        value: {
          key: "file",
          name: "image",
          type: "IMAGEUPLOAD",
          value: [],
          validations: {
            type: "array",
            params: [
              { validation: "required", message: "File is required" },
              { validation: "min", limit: 1, message: "File is required" },
              { validation: "max", limit: 1, message: "1 file limit" },
            ],
          },
        },
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
      {
        key: "linkFire",
        name: "Linkfire Links",
        type: "MAPPEDSELECT",
        mappedPath: "shareableReleases",
        mappedOption: {
          key: "id",
          name: ["name", "shareableLink"],
          value: "shareableLink",
          type: "STATIC",
          defaultOption: {
            key: "defaultOption",
            name: ["Select A Link"],
            value: null,
            type: "STATIC",
          },
        },
      },
      {
        key: "artistSocials",
        name: "artist socials",
        type: "COLLAPSABLE",
        options: [
          {
            key: "instagram",
            name: "instagram",
            type: "TEXT",
            mappedPath: "artist.socialMedia.instagram",
            validations: {
              type: "string",
              params: [
                {
                  validation: "url",
                  message: "Must be a valid URL (including http://)",
                },
              ],
            },
          },
          {
            key: "tiktok",
            name: "tiktok",
            type: "TEXT",
            validations: {
              type: "string",
              params: [
                {
                  validation: "url",
                  message: "Must be a valid URL (including http://)",
                },
              ],
            },
          },
          {
            key: "twitter",
            name: "twitter",
            type: "TEXT",
            mappedPath: "artist.socialMedia.twitter",
            validations: {
              type: "string",
              params: [
                {
                  validation: "url",
                  message: "Must be a valid URL (including http://)",
                },
              ],
            },
          },
          {
            key: "facebook",
            name: "facebook",
            type: "TEXT",
            mappedPath: "artist.socialMedia.facebook",
            validations: {
              type: "string",
              params: [
                {
                  validation: "url",
                  message: "Must be a valid URL (including http://)",
                },
              ],
            },
          },
          {
            key: "youtube",
            name: "youtube",
            type: "TEXT",
            mappedPath: "artist.socialMedia.youtube",
            validations: {
              type: "string",
              params: [
                {
                  validation: "url",
                  message: "Must be a valid URL (including http://)",
                },
              ],
            },
          },
          {
            key: "website",
            name: "website",
            type: "TEXT",
            validations: {
              type: "string",
              params: [
                {
                  validation: "url",
                  message: "Must be a valid URL (including http://)",
                },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    key: "BIOGRAPHY",
    title: "biography",
    scrollAnimation: "fade-in",
    hidden: true,
    draggable: true,
    params: [
      {
        key: "biography",
        hideLabel: true,
        name: "biography",
        type: "TEXTAREA",
        value: "",
      },
      {
        key: "background",
        name: "BACKGROUND COLOR",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "PHOTOSANDARTWORK",
    title: "photos and artwork",
    scrollAnimation: "fade-in",
    hidden: true,
    draggable: true,
    params: [
      {
        key: "images",
        name: "images",
        type: "MULTIIMAGEUPLOAD",
        value: [],
        hideLabel: true,
      },
      {
        key: "background",
        name: "BACKGROUND COLOR",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "MUSIC",
    title: "Music",
    hidden: true,
    scrollAnimation: "fade-in",
    draggable: true,
    params: [
      {
        key: "releases",
        type: "MULTIRELEASEPICKER",
        buttonTextStates: ["CHOOSE", "CHANGE"],
        name: "releases",
        filter: { key: "shareableLink" },
      },

      {
        key: "background",
        name: "BACKGROUND COLOR",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "VIDEOS_V2",
    title: "videos",
    hidden: true,
    scrollAnimation: "fade-in",
    draggable: true,
    params: [
      {
        key: "videos",
        name: "Add A Youtube Video",
        type: "MULTI",
        multiChildType: "TITLE",
        options: [
          {
            key: "video",
            name: "youtube link",
            type: "TEXT",
            validations: {
              type: "string",
              params: [
                {
                  validation: "matches",
                  limit:
                    "^(https?://)?(www.youtube.com|m.youtube.com|youtu.be)/.+$",
                  message:
                    "Must be a valid URL from youtube (including http://)",
                },
                { validation: "required", message: "Field is required" },
              ],
            },
          },
        ],
      },
      {
        key: "background",
        name: "BACKGROUND COLOR",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "MUSICPLAYER",
    title: "Music Player",
    scrollAnimation: "fade-in",
    hidden: true,
    draggable: true,
    params: [
      {
        key: "modal",
        name: "music player",
        type: "MODAL",
        descriptionPath: "page2Grid.value.releaseTitle.value",
        buttonTextStates: ["CREATE", "EDIT"],
        options: [
          {
            key: "page1Grid",
            type: "GRID",
            name: "Music Player Page 1",
            layout: { size: { xs: 12 } },
            options: [
              {
                key: "release",
                type: "RELEASEPICKER",
                name: "release",
              },
            ],
          },
          {
            key: "page2Grid",
            type: "GRID",
            name: "Music Player Page 2",
            layout: {
              size: { xs: 12 },
              gap: { lg: { h: "60px" } },
            },
            options: [
              {
                key: "artistName",
                name: "artist name",
                type: "MAPPED",
                mappedPath: "artist.name",
              },
              {
                type: "TEXT",
                key: "releaseTitle",
                value: "",
                name: "Release Title",
                sectionPath:
                  "modal.value.page1Grid.value.release.value.raw.name",
                layout: { size: { xs: 12, lg: 6 } },
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                type: "DATE",
                key: "releaseDate",
                value: "",
                name: "Release Date",
                sectionPath:
                  "modal.value.page1Grid.value.release.value.raw.releaseDate",
                layout: { size: { xs: 12, lg: 6 } },
              },
              {
                type: "TEXTAREA",
                key: "releaseBio",
                value: "",
                name: "Release Bio",
                layout: { size: { xs: 12, lg: 12 } },
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                type: "MULTITRACKUPLOAD",
                value: [],
                key: "tracks",
                name: "tracks",
                layout: { size: { xs: 12, lg: 6 } },
                sectionPath: "modal.value.page1Grid.value.release.value.tracks",
                validations: {
                  type: "array",
                  params: [
                    { validation: "required", message: "File is required" },
                    {
                      validation: "min",
                      limit: 1,
                      message: "File is required",
                    },
                    { validation: "max", limit: 10, message: "10 file limit" },
                  ],
                },
              },
              {
                type: "IMAGEUPLOAD",
                value: [],
                key: "albumArtwork",
                name: "album artwork",
                layout: { size: { xs: 12, lg: 6 } },
                sectionPath:
                  "modal.value.page1Grid.value.release.value.artwork",
                validations: {
                  type: "array",
                  params: [
                    { validation: "required", message: "File is required" },
                    {
                      validation: "min",
                      limit: 1,
                      message: "File is required",
                    },
                    { validation: "max", limit: 10, message: "10 file limit" },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        key: "background",
        name: "BACKGROUND COLOR",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },

  {
    key: "METADATA",
    title: "Metadata",
    scrollAnimation: "fade-in",
    hidden: true,
    draggable: true,
    params: [
      {
        key: "metadataReleases",
        type: "MULTIRELEASEPICKER",
        name: "Releases",
        buttonTextStates: ["CHOOSE", "CHANGE"],
        hideMultiPills: true,
      },
      {
        key: "metadataMulti",
        name: "add Metadata",
        type: "MAPPEDMULTI",
        sectionPath: "metadataReleases.value.releases",
        sectionData: "raw.assets",
        multiChildType: "TITLE",
        mappedOptions: [
          {
            key: "name",
            name: "Track Name",
            type: "TEXT",
            mappedPath: "title",
            validations: {
              type: "string",
              params: [
                { validation: "required", message: "Field is required" },
              ],
            },
          },
          {
            key: "upc",
            name: "UPC",
            type: "TEXT",
            sectionPath: "raw.upcEanCode",
          },
          {
            key: "adamId",
            name: "Adam Id",
            type: "TEXT",
            sectionPath: "raw.dspMetadata.appleMusicId",
          },
          {
            key: "spotifyURI",
            name: "Spotify URI",
            type: "TEXT",
            sectionPath: "raw.dspMetadata.spotifyUrl",
          },
        ],
        options: [
          {
            key: "metadataGroup",
            name: "metadata",
            type: "GROUP",
            options: [
              {
                key: "name",
                name: "Track Name",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                key: "upc",
                name: "UPC",
                type: "TEXT",
              },
              {
                key: "adamId",
                name: "Adam Id",
                type: "TEXT",
              },
              {
                key: "spotifyURI",
                name: "Spotify URI",
                type: "TEXT",
              },
            ],
          },
        ],
      },

      {
        key: "background",
        name: "background color",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "TIMELINE",
    title: "timeline",
    scrollAnimation: "fade-in",
    hidden: true,
    draggable: true,
    params: [
      {
        key: "timeline",
        name: "add event",
        type: "MULTI",
        multiChildType: "TITLE",
        options: [
          {
            key: "timeline",
            name: "timeline",
            type: "GROUP",
            options: [
              {
                key: "release",
                name: "Release",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                key: "dates",
                name: "Dates",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                key: "description",
                name: "Description",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        key: "background",
        name: "background color",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "PERFORMANCES",
    title: "performances",
    hidden: true,
    scrollAnimation: "fade-in",
    draggable: true,
    params: [
      {
        key: "videos",
        name: "Add A Youtube Video",
        type: "MULTI",
        multiChildType: "TITLE",
        options: [
          {
            key: "video",
            name: "youtube link",
            type: "TEXT",
            validations: {
              type: "string",
              params: [
                {
                  validation: "matches",
                  limit:
                    "^(https?://)?(www.youtube.com|m.youtube.com|youtu.be)/.+$",
                  message:
                    "Must be a valid URL from youtube (including http://)",
                },
                { validation: "required", message: "Field is required" },
              ],
            },
          },
        ],
      },

      {
        key: "background",
        name: "BACKGROUND COLOR",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },

  {
    key: "TOURDATES",
    title: "tour dates",
    scrollAnimation: "fade-in",
    hidden: true,
    draggable: true,
    params: [
      {
        key: "tourDate",
        name: "add a tour date",
        type: "MULTI",
        multiChildType: "TOURDATE",
        options: [
          {
            key: "tourDate",
            name: "tour date",
            type: "GROUP",
            options: [
              {
                key: "venueName",
                name: "venue name",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                key: "ticketsUrl",
                name: "tickets url",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                    {
                      validation: "url",
                      message: "Must be a valid URL (including http://)",
                    },
                  ],
                },
              },
              {
                key: "dateTime",
                name: "date & time",
                type: "DATETIME",
                validations: {
                  type: "date",
                  params: [
                    { validation: "required", message: "Field is required" },
                    { validation: "nullable" },
                  ],
                },
              },
              {
                key: "city",
                name: "city",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                key: "country",
                name: "country",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        key: "background",
        name: "BACKGROUND COLOR",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "PRESSRELEASES",
    title: "press releases",
    scrollAnimation: "fade-in",
    hidden: true,
    draggable: true,
    params: [
      {
        key: "linkTitle",
        name: "add a press release",
        type: "MULTI",
        multiChildType: "TITLE",
        options: [
          {
            key: "linkTitle",
            name: "link",
            type: "GROUP",
            options: [
              {
                key: "linkTitle",
                name: "LINK TITLE",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                key: "linkUrl",
                name: "LINK URL",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                    {
                      validation: "url",
                      message: "Must be a valid URL (including http://)",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        key: "background",
        name: "background color",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "PRESSCLIPPINGS",
    title: "press clippings",
    scrollAnimation: "fade-in",
    hidden: true,
    draggable: true,
    params: [
      {
        key: "linkTitle",
        name: "add a press clipping",
        type: "MULTI",
        multiChildType: "TITLE",
        options: [
          {
            key: "linkTitle",
            name: "link",
            type: "GROUP",
            options: [
              {
                key: "linkTitle",
                name: "link title",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                key: "linkUrl",
                name: "link url",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                    {
                      validation: "url",
                      message: "Must be a valid URL (including http://)",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        key: "background",
        name: "BACKGROUND COLOR",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "CONTACTS",
    title: "contacts",
    scrollAnimation: "fade-in",
    hidden: true,
    draggable: true,
    params: [
      {
        key: "contact",
        name: "add a contact",
        type: "MULTI",
        multiChildType: "TITLE",
        options: [
          {
            key: "contact",
            name: "contact",
            type: "GROUP",
            options: [
              {
                key: "name",
                name: "NAME",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                key: "phoneOrTitle",
                name: "PHONE, NAME OF COMPANY OR TITLE",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                  ],
                },
              },
              {
                key: "email",
                name: "EMAIL",
                type: "TEXT",
                validations: {
                  type: "string",
                  params: [
                    { validation: "required", message: "Field is required" },
                    { validation: "email", message: "Must be a valid Email" },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        key: "background",
        name: "background color",
        type: "COLOR",
        value: "#000000",
      },
      {
        key: "textColorOverride",
        name: "text color",
        type: "RADIO",
        options: [
          { key: "on", name: "on", type: "COLOR", value: "#FFFFFF" },
          { key: "off", name: "off", type: "STATIC", value: "inherit" },
        ],
        value: { key: "off", name: "off", type: "STATIC", value: "inherit" },
      },
    ],
  },
  {
    key: "PAGEDESIGN",
    title: "Page Design",
    required: false,
    params: [
      {
        key: "headlineFont",
        name: "headline font",
        type: "SELECT",
        // mappedOption:{
        //   source:{
        //     dataSet:'mapped' // or 'section'
        //     path:'releases' // an array of releases (or whatever data)
        // }
        //   option:{

        //     key: 'release.id',
        //     title: 'release.title',
        //     value: 'realease.shareableLink'
        //   }
        // }
        options: [
          { key: "Futura", name: "Futura", type: "STATIC", value: "Futura" },
          {
            key: "Andale Mono",
            name: "Andale Mono",
            type: "STATIC",
            value: "Andale Mono",
          },
          {
            key: "American Typewriter",
            name: "American Typewriter",
            type: "STATIC",
            value: "American Typewriter",
          },
          {
            key: "Arial",
            name: "Arial",
            type: "STATIC",
            value: "Arial",
          },
          {
            key: "Bradley Hand",
            name: "Bradley Hand",
            type: "STATIC",
            value: "Bradley Hand",
          },
          {
            key: "Courier",
            name: "Courier",
            type: "STATIC",
            value: "Courier",
          },
          {
            key: "Didot",
            name: "Didot",
            type: "STATIC",
            value: "Didot",
          },
          {
            key: "Helvetica",
            name: "Helvetica",
            type: "STATIC",
            value: "Helvetica",
          },
          {
            key: "Lato",
            name: "Lato",
            type: "STATIC",
            value: "Lato",
          },
          {
            key: "Luminari",
            name: "Luminari",
            type: "STATIC",
            value: "Luminari",
          },
          {
            key: "Oswald",
            name: "Oswald",
            type: "STATIC",
            value: "Oswald",
          },
          {
            key: "SF Pro Display",
            name: "SF Pro Display",
            type: "STATIC",
            value: "SF Pro Display",
          },
          {
            key: "Times New Roman",
            name: "Times New Roman",
            type: "STATIC",
            value: "Times New Roman",
          },
          {
            key: "Trebuchet MS",
            name: "Trebuchet MS",
            type: "STATIC",
            value: "Trebuchet MS",
          },
          {
            key: "Verdana",
            name: "Verdana",
            type: "STATIC",
            value: "Verdana",
          },
        ],
      },
      {
        key: "headlineColor",
        name: "headline color",
        type: "COLOR",
        value: "#FFFFFF",
      },
      {
        key: "bodyFont",
        name: "body font",
        type: "SELECT",
        options: [
          {
            key: "Helvetica",
            name: "Helvetica",
            type: "STATIC",
            value: "Helvetica",
          },
          {
            key: "Andale Mono",
            name: "Andale Mono",
            type: "STATIC",
            value: "Andale Mono",
          },
          {
            key: "American Typewriter",
            name: "American Typewriter",
            type: "STATIC",
            value: "American Typewriter",
          },
          {
            key: "Arial",
            name: "Arial",
            type: "STATIC",
            value: "Arial",
          },
          {
            key: "Bradley Hand",
            name: "Bradley Hand",
            type: "STATIC",
            value: "Bradley Hand",
          },
          {
            key: "Courier",
            name: "Courier",
            type: "STATIC",
            value: "Courier",
          },
          {
            key: "Didot",
            name: "Didot",
            type: "STATIC",
            value: "Didot",
          },
          { key: "Futura", name: "Futura", type: "STATIC", value: "Futura" },
          {
            key: "Lato",
            name: "Lato",
            type: "STATIC",
            value: "Lato",
          },
          {
            key: "Luminari",
            name: "Luminari",
            type: "STATIC",
            value: "Luminari",
          },
          {
            key: "Oswald",
            name: "Oswald",
            type: "STATIC",
            value: "Oswald",
          },
          {
            key: "SF Pro Display",
            name: "SF Pro Display",
            type: "STATIC",
            value: "SF Pro Display",
          },
          {
            key: "Times New Roman",
            name: "Times New Roman",
            type: "STATIC",
            value: "Times New Roman",
          },
          {
            key: "Trebuchet MS",
            name: "Trebuchet MS",
            type: "STATIC",
            value: "Trebuchet MS",
          },
          {
            key: "Verdana",
            name: "Verdana",
            type: "STATIC",
            value: "Verdana",
          },
        ],
      },
      { key: "bodyColor", name: "body color", type: "COLOR", value: "#FFFFFF" },
    ],
  },
];

export const artistEpkMappedData = [
  {
    source: "artist",
    keys: { name: null, socialMedia: null, countryOfResidence: null },
  },
];
