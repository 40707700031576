import { Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BlockParam } from '../../../../asset-generator-lib/composer/models/Block';
import { SketchPicker } from 'react-color';
import { ReactComponent as Palette } from '../../../shared/icons/palette.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

type Props = {
  update: any;
  param: BlockParam;
  subField: Boolean;
};

type StyleProps = {
  color?: string;
  selected?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  dot: {
    display: 'inline-block',
    height: '35px',
    width: '35px',
    borderRadius: '100%',
    backgroundColor: (props: StyleProps) => props.color,
    border: '1px solid #ffffff',
    padding: '2px',
    margin: 5,
    cursor: 'pointer',
    transition: '.1s ease'
  },
  label: {
    fontFamily: 'Futura',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#ffffff',
    textTransform: 'uppercase',
    marginBottom: '12px'
  },
  iconButton: {
    background: (props: StyleProps) => props.color ? props.color : theme.palette.secondary.dark,
    border: `1px solid ${theme.palette.secondary.light}`,
    fontSize: '35px',
    margin: '5px',
    padding: '9.5px'
  },
  popover: {
    position: 'absolute',
    zIndex: 2
  },
  gridRow: {
    display: 'flex',
    alignItems: 'center'
  },
  sketchPicker: {
    background: `${theme.palette.secondary.light} !important`
  }
}));

const defaultPickerColors = [
  '#ac3331',
  '#f55c59',
  '#f51909',
  '#f58609',
  '#f2d542',
  '#8eee00',
  '#00800e',
  '#00e896',
  '#21cce2',
  '#007af7',
  '#0100e2',
  '#7600ec',
  '#bf00ec',
  '#e586a3',
  '#e58686',
  '#ea0056',
];

const defaultColorDots = [
  '#000000',
  '#ffffff',
];

const ColorInput = ({ param, update, subField }: Props) => {
  const [selected, setSelected] = useState(param.value?.value || param.value);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const classes = useStyles({ color: selected });



  useEffect(() => {
    update({ ...param, value: selected });
  }, [selected]);

  return (
    <Grid item xs={12}>
      {!subField && <div className={classes.label}>{param?.name}</div>}

      <div className={classes.gridRow}>
        <IconButton className={classes.iconButton} onClick={() => setDisplayColorPicker(!displayColorPicker)}>
          <Palette />
        </IconButton>

        {defaultColorDots.map((color: any) => (
          <ColorDot
            key={color}
            color={color}
            selected={color === selected}
            setSelected={setSelected}
          />
        ))}
      </div>

      {displayColorPicker &&
        <ClickAwayListener onClickAway={() => setDisplayColorPicker(false)}>
          <div className={classes.popover}>
            <SketchPicker
              disableAlpha
              color={selected}
              className={classes.sketchPicker}
              onChange={(color: any) => setSelected(color.hex)}
              presetColors={defaultPickerColors}
            />
          </div>
        </ClickAwayListener>
      }
    </Grid>
  );
};

type ColorDotProps = {
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  selected?: boolean;
  color: string;
};

const ColorDot = ({ selected, color, setSelected }: ColorDotProps) => {
  const classes = useStyles({ color, selected });
  return (
    <div className={classes.dot} onClick={() => setSelected(color)} ></div>
  );
};

export default ColorInput;
