import React, { useState } from "react";
import Plugins, { linkPlugin, SideToolbar, InlineToolbar } from "./Plugins";
import Editor from "draft-js-plugins-editor";
import {
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  SelectionState,
  Modifier,
  CompositeDecorator,
} from "draft-js";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { createBlockRenderMap } from "./BlockRenderMap";
import { PlatoonContext, BlockEditSession } from "./PlatoonContext";
import {
  HeadlineOneButton,
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from "draft-js-buttons";
import { MediaBlockRenderer } from "./blocks/MediaBlockRenderer";
import { createMuiTheme, Tooltip } from "@material-ui/core";
import GeneralModal from "../components/ModalCms.tsx/GeneralModal";
import DividerButton from "./Buttons.tsx/DividerButton";
import ImageButton from "./Buttons.tsx/ImageButton";
import VideoButton from "./Buttons.tsx/VideoButton";
import CarouselButton from "./Buttons.tsx/CarouselButton";
import IframeButton from "./Buttons.tsx/IframeButton";
import InsertButton from "./Buttons.tsx/InsertButton";
import PhoneVideoButton from "./Buttons.tsx/PhoneVideoButton";
import { DecoratedLink } from "./LinkDecorator";
import { LinkStrategy } from "./LinkStrategy";
import "draft-js-anchor-plugin/lib/plugin.css";
import "draft-js-focus-plugin/lib/plugin.css";
import "./editorStyles.css";
import "../fonts/sf_pro.css";
import "draft-js/dist/Draft.css";
import "draft-js-inline-toolbar-plugin/lib/plugin.css";

interface ContentEditorProps {
  readOnly: boolean;
  initialRawContent?: any;
  onRawContentChange?: any;
}

export { ContentEditorProps };

const theme = createMuiTheme();
const themeInstance = createMuiTheme({
  spacing: 18,
  palette: {
    primary: {
      main: "#ff3e66",
    },
    secondary: {
      main: "#ffffff99",
    },
    type: "dark",
  },
  typography: {
    h1: {
      [theme.breakpoints.down(420)]: {
        fontSize: "22px",
        marginTop: "7%",
      },
      [theme.breakpoints.up(1070)]: {
        margin: "auto",
        width: "80%",
      },
      [theme.breakpoints.up(1250)]: {
        margin: "auto",
        width: "720px",
      },
      color: "#a5a5a5",
      fontFamily: "SF Pro Display",
      fontWeight: 400,
      fontSize: "36px",
    },
    h2: {
      [theme.breakpoints.down(420)]: {
        fontSize: "16px",
        marginTop: "7%",
      },
      [theme.breakpoints.up(1070)]: {
        margin: "auto",
        width: "80%",
      },
      [theme.breakpoints.up(1250)]: {
        margin: "auto",
        width: "720px",
      },
      color: "#a5a5a5",
      fontWeight: 400,
      fontFamily: "SF Pro Display",
      fontSize: "24px",
    },
    h5: {
      fontFamily: "SF Pro Display",
    },
  },
});

const useStyles = makeStyles((theme: typeof themeInstance) => ({
  root: {
    position: "relative",
    marginTop: "25px",
  },
  contentViewBox: {
    [theme.breakpoints.up(1250)]: {
      maxWidth: "900px",
      margin: "auto",
    },
  },
  toolTip: {
    paddingTop: "12px",
  },
  sideToolBarFlip: {
    "& .draftJsToolbar__popup__GHzbY": {
      top: "-187.5px",
    },
    "& .draftJsToolbar__spacer__2Os2z": {
      bottom: "35px",
    },
    "& .draftJsToolbar__popup__GHzbY:after, .draftJsToolbar__popup__GHzbY:before":
      {
        bottom: "-7%",
        transform: "rotate(180deg)",
      },
    "& .draftJsToolbar__popup__GHzbY:after": {
      borderWidth: "6px",
      marginLeft: "-6px",
    },
  },
}));

// should we rename to be more semantic?
// this is used to both edit and show content

// DraftJSContent ?

function ContentEditor(props: ContentEditorProps) {
  const classes = useStyles();
  const readOnly = props.readOnly;

  const [editorState, setEditorState] = React.useState<EditorState>(
    EditorState.createEmpty(
      new CompositeDecorator([
        {
          strategy: LinkStrategy,
          component: DecoratedLink,
        },
      ])
    )
  );
  const [sideToolBarFlip, setSideToolBarFlip] = useState(false);
  const [blockEditSession, setBlockEditSession] =
    useState<BlockEditSession | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);

  React.useEffect(() => {
    if (props.initialRawContent && props.initialRawContent.length > 0) {
      const content = convertFromRaw(JSON.parse(props.initialRawContent));
      setEditorState(EditorState.createWithContent(content));
    }
  }, [props.initialRawContent]);

  React.useEffect(() => {
    let rawContent = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    if (props.onRawContentChange) {
      props.onRawContentChange(rawContent);
    }
  }, [editorState]);

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const blockRendererFn = (block: any) => {
    const type = block.getType();
    if (type === "atomic") {
      return {
        component: MediaBlockRenderer, // what does this get passed?
        editable: false,
      };
    } else {
      return undefined;
    }
  };

  const deleteBlockWithKey = (key: string) => {
    if (key !== undefined) {
      let contentState = editorState.getCurrentContent();
      const newSelection = new SelectionState({
        anchorKey: key,
        anchorOffset: 0,
        focusKey: key,
        focusOffset: 0,
      });
      const afterKey = contentState.getKeyAfter(key);
      const afterBlock = contentState.getBlockForKey(afterKey);
      let targetRange;
      if (
        afterBlock &&
        afterBlock.getType() === "unstyled" &&
        afterBlock.getLength() === 0 &&
        afterBlock === contentState.getBlockMap().last()
      ) {
        targetRange = new SelectionState({
          anchorKey: key,
          anchorOffset: 0,
          focusKey: afterKey,
          focusOffset: 0,
        });
      } else {
        targetRange = new SelectionState({
          anchorKey: key,
          anchorOffset: 0,
          focusKey: key,
          focusOffset: 1,
        });
      }
      contentState = Modifier.setBlockType(
        contentState,
        targetRange,
        "unstyled"
      );
      contentState = Modifier.removeRange(
        contentState,
        targetRange,
        "backward"
      );
      const newEditorState = EditorState.push(
        editorState,
        contentState,
        "remove-range"
      );
      const testSelection = EditorState.forceSelection(
        newEditorState,
        newSelection
      );
      setEditorState(testSelection);
    }
  };

  const onTab = (e: any) => {
    const maxDepth = 4;
    setEditorState(RichUtils.onTab(e, editorState, maxDepth));
  };

  const handleReturn = (e: any) => {
    if (e.shiftKey) {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return "handled";
    } else {
      return "non-handled";
    }
  };

  const sideToolBarHover = () => {
    let sideToolBar = document.getElementById("sideToolBar");
    let sideToolBarBounds = sideToolBar?.getBoundingClientRect();
    if (
      sideToolBarBounds &&
      sideToolBarBounds.bottom + 125 > window.innerHeight
    ) {
      setSideToolBarFlip(true);
    } else {
      setSideToolBarFlip(false);
    }
  };

  const sideMenuButtons = (props: any) => {
    const buttonAssets = [
      {
        component: <HeadlineOneButton {...props} />,
        direction: "left",
        title: "Header",
      },
      {
        component: (
          <DividerButton
            {...props}
            editorState={editorState}
            onChange={setEditorState}
          />
        ),
        direction: "right",
        title: "Divider",
      },
      {
        component: <UnorderedListButton {...props} />,
        direction: "left",
        title: "Unordered List",
      },
      {
        component: <OrderedListButton {...props} />,
        direction: "right",
        title: "Ordered List",
      },
      {
        component: <InsertButton {...props} />,
        direction: "left",
        title: "Button",
      },
      {
        component: <ImageButton {...props} />,
        direction: "right",
        title: "Image",
      },
      {
        component: <VideoButton {...props} />,
        direction: "left",
        title: "Video",
      },
      {
        component: <CarouselButton {...props} />,
        direction: "right",
        title: "Carousel",
      },
      {
        component: <IframeButton {...props} />,
        direction: "left",
        title: "Iframe",
      },
      {
        component: <PhoneVideoButton {...props} />,
        direction: "right",
        title: "Phone Video",
      },
    ];
    return buttonAssets.map((asset: any) => {
      return (
        <Tooltip
          key={asset.title}
          title={asset.title}
          placement={asset.direction}
          className={classes.toolTip}
          arrow={true}
        >
          <span>{asset.component}</span>
        </Tooltip>
      );
    });
  };

  return (
    <div className={classes.root}>
      <PlatoonContext.Provider
        value={{
          blockEditSession,
          setBlockEditSession,
          anchorEl,
          setAnchorEl,
          readOnly,
          deleteBlockWithKey,
          editorState,
        }}
      >
        <ThemeProvider theme={themeInstance}>
          <div className={classes.contentViewBox}>
            <Editor
              readOnly={props.readOnly}
              blockRendererFn={blockRendererFn}
              blockRenderMap={createBlockRenderMap()} // TODO: Type Errors
              editorState={editorState}
              onChange={setEditorState}
              plugins={Plugins}
              onTab={onTab}
              handleKeyCommand={handleKeyCommand}
              handleReturn={handleReturn} // TODO: Type Errors
            />

            <div
              className={sideToolBarFlip ? classes.sideToolBarFlip : ""}
              onMouseEnter={sideToolBarHover}
              onMouseLeave={() => setSideToolBarFlip(false)}
            >
              <SideToolbar>
                {(externalProps: any) => (
                  <div id="sideToolBar">{sideMenuButtons(externalProps)}</div>
                )}
              </SideToolbar>
            </div>

            <InlineToolbar>
              {
                // may be use React.Fragment instead of div to improve perfomance after React 16
                (externalProps: any) => (
                  <div>
                    <BoldButton {...externalProps} />
                    <ItalicButton {...externalProps} />
                    <UnderlineButton {...externalProps} />
                    <linkPlugin.LinkButton {...externalProps} />
                  </div>
                )
              }
            </InlineToolbar>

            <GeneralModal editorState={editorState} onChange={setEditorState} />
          </div>
        </ThemeProvider>
      </PlatoonContext.Provider>
    </div>
  );
}

export default ContentEditor;
