import React from "react";
import styles from "./RadioInput.module.scss";
import { BlockParam } from "../../../../../asset-generator-lib";
import { TemplatePageDraft } from "../../../types/TemplatePages";
import { useEffectOnce } from "react-use";
import TemplatePageFormRenderer from "../../TemplatePageFormRenderer/TemplatePageFormRenderer";
import { useValidationsContext } from "../../../../providers/ValidationsProvider";

type Props = {
  param: BlockParam;
  update: (input: BlockParam | string) => void;
  subField?: boolean;
  sectionKey: string;
  hideLabel?: true;
  templatePageDraft: TemplatePageDraft;
  path: string[];
};

const RadioInput = ({
  param,
  update,
  subField,
  sectionKey,
  hideLabel,
  templatePageDraft,
  path,
}: Props) => {
  // State now comes from params
  const value = param.value || (param.options && param.options[0]);
  const [selectionCache, setSelectionCache] = React.useState<{
    [item: string]: BlockParam;
  }>({ [value.key]: value });

  const { validations, removeValidation } = useValidationsContext();

  useEffectOnce(() => {
    if (!param.value && param.options && param.options[0]) {
      setValue(param.options[0]);
    }
  });

  const setValue = (newValue: any) => {
    // Examples:
    // newValue: {key: "on", name: "on", type: "PASSWORD", value: "", validations: Object}
    // value: {key: "off", name: "off", type: "STATIC", value: null}
    if (newValue) {
      const v = valueAdd(newValue);
      const s = { ...selectionCache };
      s[newValue.key] = newValue;
      setSelectionCache(s);
      update(v);
    }
  };

  const valueAdd = (value: BlockParam) => {
    var p = { ...param, value: value };
    return p;
  };

  const childUpdate = (subValue: string) => {
    if (value) {
      setValue(subValue);
    }
  };

  // JJ -- using opt.key instead of JSON.stringify my break nested comparisons
  // see commit 93df8c3e1323568c08abce05acc80bf1e0afcca4 for where/how jay might have broken this
  const handleOnChange = (event: any) => {
    event.target.focus();
    const itemKey = event.target.value;
    const item = param.options?.find((opt) => opt.key === itemKey)!;
    // If doing selection cache stuff
    const selectionCacheItem = selectionCache[itemKey];
    const selectionValue = selectionCacheItem || item;
    if (validations[sectionKey || param.key]) {
      removeValidation(sectionKey || param.key);
    }
    setValue(selectionValue);
  };

  return (
    <div className={styles.container} id="🛑 container">
      {!subField && (
        <div
          className={
            hideLabel ? "sr-only sr-only-focusable" : "label label--big"
          }
        >
          {param?.name}
        </div>
      )}

      <div className={styles["radio-wrapper"]}>
        {param.options?.map((item: BlockParam) => {
          return (
            <div
              className={`relative flex-vert-center ${styles["radio-min-height"]}`}
              key={item.name}
            >
              <input
                name={`${sectionKey}-${param.key}`}
                className="radio absolute"
                checked={value.key === item.key}
                value={item.key}
                type="radio"
                onChange={handleOnChange}
              />
              <label htmlFor={item.name} className={styles.label}>
                {item.name}
              </label>
            </div>
          );
        })}
      </div>
      <div className={styles.children}>
        <TemplatePageFormRenderer
          blockParams={[value]}
          update={childUpdate}
          disabled={false}
          templatePageDraft={templatePageDraft}
          sectionKey={sectionKey}
          path={path}
        />
      </div>
    </div>
  );
};

export default RadioInput;
