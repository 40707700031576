import React, { useState } from "react";
import styles from "./ConfirmDelete.module.scss";
import { ReactComponent as XMark } from "../../../shared/icons/xmark.svg";

type Props = {
  callback: () => any;
};

const ConfirmDelete = ({ callback }: Props) => {
  const [confirmText, setConfirmText] = useState(false);

  const handleClick = () => {
    if (confirmText) {
      callback();
    } else {
      setConfirmText(true);
    }
  };
  return (
    <button className="btn btn--naked" onClick={handleClick}>
      {confirmText ? (
        <span className={styles["confirm"]}>Confirm Delete</span>
      ) : (
        <XMark className={styles["icon"]} />
      )}
    </button>
  );
};

export default ConfirmDelete;
