import React from "react";
import { Grid, Box, useTheme, makeStyles } from "@material-ui/core";
import ArtistLink, { BackgroundMode } from "../../models/ArtistLink";

const useStyles = makeStyles(() => ({
  cover: {
    width: "150px",
    height: "150px",
    objectFit: "cover",
  },
}));

interface Props {
  artistLink: ArtistLink;
}

export default function CardImage(props: Props) {
  const theme = useTheme();
  const classes = useStyles();

  const indexCardImage = () => {
    switch (props.artistLink.backgroundMode) {
      case BackgroundMode.blur:
        return props.artistLink.albumArtwork?.attachment;
      case BackgroundMode.image:
        return props.artistLink.backgroundImage?.attachment;
      default:
        return "";
    }
  };

  return (
    <Grid item>
      {props.artistLink.backgroundMode === BackgroundMode.color ? (
        <Box
          border={1}
          borderColor={theme.palette.secondary.light}
          height={150}
          width={150}
          bgcolor={props.artistLink.backgroundColor}
        />
      ) : (
        <img className={classes.cover} src={indexCardImage()} />
      )}
    </Grid>
  );
}
