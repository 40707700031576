import React, { useState, useCallback } from "react";
import { Text } from "@inlet/react-pixi";
import Block, { BlockUtils } from "../../models/Block";
import DraggableBlock from "./DraggableBlock";
import { TemplateEditorState } from "../../TemplateEditorState";

interface Props {
  block: Block;
  parentView: any;
  readOnly: boolean;
  editorState: TemplateEditorState;
  setEditorState: React.Dispatch<React.SetStateAction<TemplateEditorState>>;
  selectBlocks: any;
  selectBlock: any;
  composition?: any;
}

export default function TextBlockRenderer(props: Props) {
  const [textScale, setTextScale] = useState<number>();

  const textRef = useCallback(
    (node) => {
      BlockUtils.resetTextScale(setTextScale).then(() =>
        setTextScale(BlockUtils.findTextScale(node, props.block))
      );
    },
    [props]
  );

  const blockStyle = props.block.style;
  const textAlpha = blockStyle?.alpha ? blockStyle?.alpha : 1;

  const textColor = BlockUtils.paramValuesByKey(
    "textColor",
    props.composition.blocks
  );
  const multiTextValue = BlockUtils.paramValuesByKey(
    "allText",
    props.composition.blocks
  );
  const textValue = BlockUtils.paramValueForKey("text", props.block.params);

  const renderedText = textValue ? textValue : multiTextValue;

  return (
    <DraggableBlock {...props}>
      <Text
        ref={textRef}
        scale={textScale ? textScale : 1}
        alpha={textAlpha}
        anchor={props.block.frame?.anchor}
        style={{
          wordWrap: blockStyle?.wordWrap?.wrap,
          wordWrapWidth: blockStyle?.wordWrap?.wrapWidth,
          stroke: `#${blockStyle?.border?.borderColor}`,
          strokeThickness: blockStyle?.border?.borderSize,
          fill: textColor ? textColor : blockStyle?.font?.fontColor,
          fontSize: blockStyle?.font?.fontSize,
          fontFamily: blockStyle?.font?.fontFamily,
          fontWeight: blockStyle?.font?.fontWeight,
        }}
        angle={blockStyle?.rotation?.angle}
        text={renderedText}
      />
    </DraggableBlock>
  );
}
