import React, { ReactNode } from "react";
import styles from "./Modal.module.scss";
import Overlay from "../../shared/components/Overlay/Overlay"

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
};

const Modal = ({ setOpen, children }: Props) => {
  return (
    <Overlay setOpen={setOpen}>
      <aside className={styles["delete-card"]}>{children} </aside>
    </Overlay>
  );
};

export default Modal;
