import {
  Grid,
  Box,
  makeStyles,
  withWidth,
  isWidthDown,
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React, { ReactNode } from "react";
import StyledLink from "../StyledLink";

type Props = {
  children: ReactNode;
  to?: string;
  newlyDuped: any;
  slug: string;
  width: Breakpoint;
};

type StyleProps = {
  width: Breakpoint;
  to?: string;
};

const useStyles = makeStyles((theme) => ({
  playerCard: {
    "&:hover": {
      backgroundColor: (props: StyleProps) =>
        props.to && theme.palette.secondary.light,
    },
  },
  "@keyframes blinker": {
    from: { backgroundColor: theme.palette.secondary.light },
    to: { backgroundColor: theme.palette.secondary.dark },
  },
  playerCardBlinker: {
    animationName: "$blinker",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount: "3",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  boxWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 5,
    padding: ({ width }: StyleProps) =>
      isWidthDown("sm", width) ? theme.spacing(2) : theme.spacing(3),
  },
}));

const IndexCard = ({ children, to, newlyDuped, slug, width }: Props) => {
  const classes = useStyles({ width, to });

  const cardStyle = () => {
    if (newlyDuped) {
      return slug === newlyDuped
        ? classes.playerCardBlinker
        : classes.playerCard;
    } else return classes.playerCard;
  };

  return (
    <Grid item xs={12}>
      <StyledLink to={to} className={cardStyle()}>
        <Box id={slug} className={`${classes.boxWrapper} ${cardStyle()}`}>
          {children}
        </Box>
      </StyledLink>
    </Grid>
  );
};

export default withWidth()(IndexCard);
