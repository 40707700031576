import { useQueries } from "react-query";
import { adaptTrackToFrontend } from "../../artists/ArtistSchema";

let BASE_PATH = `/api/v1`;

const publicApiCall = (endpoint: string) => {
  return fetch(endpoint, {
    method: "GET",
    credentials: "include",
    mode: "cors",
  }).then((response) => {
    if (!response.ok) throw new Error(response.status.toString());
    else return response.json();
  });
};

const getTracks = (artistId: string, tracks: any) => {
  const trackData = useQueries(
    (artistId &&
      tracks?.map((trackObj: any) => {
        const trackId = trackObj?.asset?.id || trackObj?.id;
        const endpoint = `${BASE_PATH}/artists/${artistId}/tracks/${trackId}`;

        return {
          queryKey: ["processingState", trackId, artistId],
          queryFn: () => publicApiCall(endpoint).then(adaptTrackToFrontend),
          retry: true,
        };
      })) ??
      []
  );

  return trackData;
};

export { getTracks };
