import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "../linkviewer/ErrorPage";
import ErrorHandlingProvider from "../providers/ErrorHandlingProvider";
import { PlatoonArtistProvider } from "../providers/PlatoonArtistProvider";
import ArtistPagesPreviewLoader from "./ArtistPagesPreviewLoader/ArtistPagesPreviewLoader";

const ArtistPagePreviewApp = () => {
  return (
    <ErrorHandlingProvider>
      <PlatoonArtistProvider>
        <Routes>
          <Route index element={<ErrorPage />} />
          <Route
            path={`:templateSlug/:templatePageId`} //probably needs a slug instead
            element={<ArtistPagesPreviewLoader />}
          />
        </Routes>
      </PlatoonArtistProvider>
    </ErrorHandlingProvider>
  );
};

export default ArtistPagePreviewApp;
