import React from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import styles from "./RenderedMusic.module.scss";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import Loading from "../../../../shared/Loading";
import SlideCarousel from "../../../../shared/components/SlideCarousel/SlideCarousel";
import { ReactComponent as PlayOutlined } from "../../../../shared/icons/play-outlined.svg";
import { ReactComponent as ChevronRight } from "../../../../shared/icons/chevron-right.svg";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

const RenderedMusic = ({ convertedBlock, highlight }: Props) => {
  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  const carouselItems = convertedBlock.params?.releases?.value?.releases?.map(
    (release: any, index: number) => {
      const releaseArtwork = release?.artwork?.asset || release?.artwork;
      const releaseShareableLink = release?.raw?.shareableLink;
      const releaseName = release?.raw?.name;

      return (
        <div key={release?.raw?.id + index} className={styles["slide"]}>
          <div className="full-height">
            <div className="relative">
              {releaseArtwork[0]?.id ? (
                <img src={`/api/v1/signed-assets/${releaseArtwork[0]?.id}`} />
              ) : (
                <Loading />
              )}
              <div>
                <a
                  href={releaseShareableLink}
                  target="_blank"
                  className={`${styles["overlay"]}`}
                >
                  <div className="text-center">
                    <PlayOutlined className={styles["icon"]} />
                    <div>
                      <span>LISTEN</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className={`truncated ${styles["slide-data-container"]}`}>
              <span>{releaseName}</span>
              <div>
                <a
                  href={releaseShareableLink}
                  target="_blank"
                  className={"upper-case"}
                >
                  LISTEN NOW <ChevronRight className={styles["chevron"]} />
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  );

  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      <>
        <SlideCarousel>{carouselItems}</SlideCarousel>
      </>
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedMusic;
