import React from "react";
import { makeStyles } from "@material-ui/core";
import { ContentNode } from "../ContentProvider";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import Bookmark from "../Bookmark";

type Props = {
  contentNode: ContentNode;
};

const useStyles = makeStyles(() => ({
  card: {
    // making link like div
    textDecoration: "none",
    fontFamily: "inherit",
    fontSize: "100%",
    border: 0,

    //Styling
    borderRadius: 8,
    backgroundColor: "#1d1d1d",
    padding: "16px 24px 16px 64px",
    color: "#fff",
    textAlign: "start",
    height: "calc(100% - 32px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#2d2d2d",
    },
  },

  text: {
    cursor: "inherit",
    fontFamily: "Futura",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#ffffff",
    textTransform: "uppercase",
    textAlign: "start",
  },
  relativeWrapper: {
    position: "relative",
  },
  bookmarkContainer: {
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "12px",
    height: "40px",
    width: "40px",
  },
  breadcrumb: {
    fontFamily: "Helvetica",
    fontSize: 10,
    color: "#ffffff",
  },
}));

const BookmarkCard = ({ contentNode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.relativeWrapper}>
      <div className={classes.bookmarkContainer}>
        <Bookmark contentNode={contentNode} width="20px" />
      </div>
      <Link
        id={`bookmark-${contentNode.title}`}
        className={classes.card}
        to={contentNode.path()}
      >
        <label
          htmlFor={`bookmark-${contentNode.title}`}
          className={classes.text}
        >
          {contentNode.title}
        </label>
        <Breadcrumbs links={false} contentNodes={contentNode.getCrumbs()} />
      </Link>
    </div>
  );
};

export default BookmarkCard;
