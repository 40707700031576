import React from "react";

import { ReactComponent as PlusCircleBold } from "../../../../../shared/icons/plus-circle-bold.svg";
import styles from "./FileBrowseButton.module.scss";

type Props = {
  buttonName: string;
  inputRef: any;
  multiFile?: boolean | undefined;
  handleFiles: (
    files: FileList,
    event: React.ChangeEvent<HTMLInputElement>,
    accepts?: string
  ) => void;
  accept: string;
};

const FileBrowseButton = ({
  buttonName,
  inputRef,
  accept,
  multiFile,
  handleFiles,
}: Props) => {
  const browseFiles = () => {
    inputRef && inputRef.current && inputRef.current.click();
  };
  return (
    <button
      className={`btn btn--form ${styles["file-browse-button"]}`}
      onClick={browseFiles}
    >
      <PlusCircleBold className={styles["icon"]} /> add {buttonName}
      <input
        ref={inputRef}
        type="file"
        accept={accept}
        multiple={multiFile}
        onChange={(event) =>
          handleFiles(
            event.target.files as FileList,
            event,
            inputRef.current.accept
          )
        }
        className="sr-only"
      />
    </button>
  );
};

export default FileBrowseButton;
