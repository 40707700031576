import React, { useMemo } from "react";
import {
  BlockParam,
  TemplatePageBlockParam,
} from "../../../../asset-generator-lib";
import {
  Layout,
  Sizes,
} from "../../../../asset-generator-lib/composer/models/Block";
import { resolveMappedPath } from "../../../../asset-generator-lib/helpers";
import { UnknownInput } from "../../../promo-pack-builder/PromoPackCreator/FormInputs";
import { getClosestBreakpoint } from "../../../shared/helpers/breakpoint";
import { TemplatePageConfig } from "../../helpers/templateConfigHelpers";
import { TemplatePageDraft } from "../../types/TemplatePages";
import {
  TextInput,
  SelectInput,
  RadioInput,
  TextAreaInput,
  ColorInput,
  MultiInput,
  MappedMultiInput,
  GroupInput,
  DateInput,
  DateTimeInput,
  CollapsableInput,
  DividerInput,
  RadioNoLabel,
  PasswordInput,
  GridInput,
  GridItemWrapper,
  ReleasePickerInput,
  FilteredReleasePickerInput,
  ModalInput,
  MappedSelectInput,
  MultiImageUpload,
  ImageUploadInput,
  TrackUploadInput,
  MultiTrackUpload,
  FileInput,
  MultiFileInput,
  MappedInput,
} from "../inputs";
import MultiReleasePickerInput from "../inputs/MultiReleasePickerInput/MultiReleasePickerInput";
import { useTemplatePageEditorContext } from "../TemplatePageEditor/TemplatePageEditor";

type Props =
  | {
      templateConfig: TemplatePageConfig;
      blockParams?: never;
      update:
        | ((input: TemplatePageConfig) => void)
        | ((input: string | BlockParam<any>) => void)
        | ((input: string) => void);
      disabled: boolean;
      templatePageDraft: TemplatePageDraft;
      subField?: boolean;
      sectionKey: string;
      childKey?: string | null;
      parentError?: string;
      parentLayout?: Layout;
      preTemplateConfigSectionValue?: any;
      path: string[];
      noGridContainerGap?: boolean;
    }
  | {
      templateConfig?: never;
      blockParams: BlockParam[];
      update:
        | ((input: TemplatePageConfig) => void)
        | ((input: string | BlockParam<any>) => void)
        | ((input: string) => void);
      disabled: boolean;
      templatePageDraft: TemplatePageDraft;
      subField?: boolean;
      sectionKey: string;
      childKey?: string | null;
      parentError?: string;
      parentLayout?: Layout;
      preTemplateConfigSectionValue?: any;
      path: string[];
      noGridContainerGap?: boolean;
    };

export const componentList: { [key in TemplatePageBlockParam["type"]]?: any } =
  {
    TEXT: TextInput,
    TEXTAREA: TextAreaInput,
    SELECT: SelectInput,
    MAPPEDSELECT: MappedSelectInput,
    COLOR: ColorInput,
    RADIO: RadioInput,
    RADIONOLABEL: RadioNoLabel,
    FILE: FileInput,
    MULTIFILE: MultiFileInput,
    IMAGEUPLOAD: ImageUploadInput,
    TRACKUPLOAD: TrackUploadInput,
    MULTIIMAGEUPLOAD: MultiImageUpload,
    MULTITRACKUPLOAD: MultiTrackUpload,
    MULTI: MultiInput,
    MAPPEDMULTI: MappedMultiInput,
    GROUP: GroupInput,
    DATE: DateInput,
    DATETIME: DateTimeInput,
    PASSWORD: PasswordInput,
    COLLAPSABLE: CollapsableInput,
    DIVIDER: DividerInput,
    GRID: GridInput,
    MODAL: ModalInput,
    RELEASEPICKER: ReleasePickerInput,
    MULTIRELEASEPICKER: MultiReleasePickerInput,
    FILTEREDRELEASEPICKER: FilteredReleasePickerInput,
    MAPPED: MappedInput,
  };

export type GetInputComponentProps = {
  templatePageDraft: TemplatePageDraft;
  param: BlockParam;
  update: (input: any) => void; // dont know if we can type this or not?
  disabled: Boolean;
  subField?: Boolean;
  parentError?: string;
  sectionKey?: string;
  childKey?: string | null;
  preTemplateConfigSectionValue: any;
  path: string[];
};

export const getInputComponent = ({
  param,
  update,
  disabled,
  subField,
  parentError,
  sectionKey,
  childKey,
  templatePageDraft,
  preTemplateConfigSectionValue,
  path,
}: GetInputComponentProps) => {
  if (param.type === "STATIC") {
    // currently we dont want to show anything the user can't change, unless its for the purpose of
    // setting the value from a mappedPath. MappedInput is currently the only input that doesn't have a
    // modifiable input
    return null;
  } else {
    const TagName = componentList[param.type] || UnknownInput;

    const sectionParam = templatePageDraft?.templateConfig.find(
      (section) => section.key === sectionKey
    );

    const resolvedSectionPath =
      param.sectionPath &&
      sectionParam?.params &&
      resolveMappedPath(
        param.sectionPath,
        preTemplateConfigSectionValue || sectionParam?.params
      );

    const resolvedMappedPath =
      param.mappedPath &&
      templatePageDraft &&
      resolveMappedPath(param.mappedPath, templatePageDraft.mappedData);

    return (
      <GridItemWrapper layout={param.layout} key={param.key}>
        <TagName
          path={path}
          key={param.key}
          param={param}
          update={update}
          subField={subField}
          parentError={parentError}
          disabled={disabled}
          sectionKey={sectionKey}
          childKey={childKey}
          templatePageDraft={templatePageDraft}
          sectionParam={sectionParam} // do we need with resolvedValue now? i dont think so
          resolvedValue={resolvedSectionPath || resolvedMappedPath}
          preTemplateConfigSectionValue={preTemplateConfigSectionValue}
        />
      </GridItemWrapper>
    );
  }
};

// need to reorder for different sizes?
const reOrder = (arr: any[], currentBreakpoint: keyof Sizes<number>) => {
  const clonedArr = [...arr];
  //need to see what our current size is, and take that into consideration

  clonedArr.forEach((item, index) => {
    // how do we deal with the orders?  Do we stick to only the literal one listed, or do we walk down like we do sizes?
    // if we keep it literal, no work needs to be done,  they just need to list all sizes in the template
    // if we want to walk up or down, how do we know when to stop?

    // if we want to walk down sizes:
    // if (item?.layout?.order)
    //   console.log(
    //     "return the closest breakpoint value from order",
    //     getClosestBreakpoint(currentBreakpoint, item?.layout?.order),
    //     ":"
    //   );

    // is a different order provided for the current breakpoint?
    if (typeof item?.layout?.order?.[currentBreakpoint] != "undefined") {
      const element = clonedArr[index];
      clonedArr.splice(index, 1);
      clonedArr.splice(
        // getClosestBreakpoint(currentBreakpoint, item.layout?.order) ||
        item.layout?.order?.[currentBreakpoint],
        0,
        element
      );
    }
  });

  return clonedArr;
};

const TemplatePageFormRenderer = ({
  templateConfig,
  blockParams,
  update,
  disabled,
  templatePageDraft,
  subField,
  sectionKey,
  childKey,
  parentError,
  parentLayout,
  preTemplateConfigSectionValue,
  path,
  noGridContainerGap,
}: Props) => {
  const { currentBreakpoint } = useTemplatePageEditorContext();
  const fields: any = useMemo(() => {
    const source = (templateConfig || blockParams)!;

    const arrayOfInputs = source
      ? reOrder(Object.values(source), currentBreakpoint)
      : [];

    return arrayOfInputs?.map((param) => {
      let updatedPath = path ? [...path, param.key] : [param.key];

      return getInputComponent({
        param: { ...param } as BlockParam,
        update: update,
        disabled,
        sectionKey: sectionKey || param?.key,
        templatePageDraft,
        subField,
        childKey,
        parentError,
        preTemplateConfigSectionValue,
        path: updatedPath,
      });
    });
  }, [templateConfig, blockParams, templatePageDraft, currentBreakpoint]);

  // this doesnt work
  // we need to send in the parent info here, or at least the layout

  const closestBreakpoint: keyof Sizes<number> =
    getClosestBreakpoint(currentBreakpoint, parentLayout?.gap) ||
    currentBreakpoint;

  const columnGap = parentLayout?.gap && parentLayout.gap[closestBreakpoint]?.h;
  const rowGap = parentLayout?.gap && parentLayout.gap[closestBreakpoint]?.v;

  return (
    <div
      className="grid-container" // need to modify this maybe
      style={{
        ...(columnGap && { columnGap: `${columnGap}` }),
        ...(rowGap && { rowGap: `${rowGap}` }),
        ...(noGridContainerGap && { gap: 0 }),
      }}
    >
      {fields}
    </div>
  );
};

export default TemplatePageFormRenderer;
