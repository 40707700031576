import React from "react";
import { Draggable } from "react-beautiful-dnd";

type DnDItem = {
  item: any; // I dont think we need this... just the draggable id?
  index: number;
  children: React.ReactElement;
  draggableId?: string;
};

const DnDItem = ({ item, index, children, draggableId }: DnDItem) => {
  return (
    <Draggable
      key={draggableId || item.key || item._id || item.id}
      draggableId={draggableId || item.key || item._id || item.id}
      index={index}
      // isDragDisabled={true}
    >
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          {React.cloneElement(children, {
            // TODO: Change this from clone, and prop passing to a context situation
            // this could overwrite a prop, or just cause confusion, because you dont know its happening when using this component
            // also alleviates prop drilling
            // 12-22 - sure enough i forgot this is where they came from and spent a bunch of time figuring it out lol
            dragProps: draggableProvided.dragHandleProps,
          })}
        </div>
      )}
    </Draggable>
  );
};

export default DnDItem;
