import React, { useContext, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ContentNode } from "../ContentProvider";
import { TOCContext } from "./TableOfContentsMenu";
import {
  TabPanel,
  Tab,
  Tabs,
  TabList,
  TabPanelList,
} from "../../../shared/Tabs";
import { ReactComponent as ChevronLeftCircle } from "../../../shared/icons/chevron-left-circle.svg";
import { ReactComponent as ChevronRightCircle } from "../../../shared/icons/chevron-right-circle.svg";
import { useActiveButton, useCrumbs } from "../../../shared/CustomHooks";
import { useEffectOnce } from "react-use";

type TOCTabsProps = {
  contentNode: ContentNode;
  crumbs: ContentNode[] | undefined;
};

const useTOCTabsStyles = makeStyles(() => ({
  tabList: {
    flex: "1",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "8px",
  },
  tab: {
    color: "white",
    border: "none",
    borderRadius: "4px 4px 0 0",
    backgroundColor: "#151515",
    padding: "0 8px",
    "&:hover": {
      backgroundColor: "#1d1d1d",
    },
    "& button": {
      background: "none",
      border: "0",
      display: "flex",
      justifyContent: "center",
    },
  },
  activeTab: {
    borderRadius: "4px",
    backgroundColor: "#1d1d1d",
  },
  tabContainer: {
    padding: "0 14px",
    display: "flex",
    justifyContent: "space-between",

    "& button$arrowContainer:last-child": {
      marginLeft: "14px",
    },
    "& button$arrowContainer:first-child": {
      marginRight: "14px",
    },
  },

  panel: {
    backgroundColor: "#1d1d1d",
    borderRadius: "0 0 4px 4px",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 0,
  },
  button: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textDecoration: "none",
    transition: "100ms ease-in-out",
    cursor: "pointer",
    borderRadius: "4px",
    border: "0",
    margin: "4px 0",
    padding: "0 20px",
    backgroundColor: "#1d1d1d",
    "&:hover": {
      backgroundColor: "#343434",
    },
    "&:active": {
      backgroundColor: "#242424",
    },
    fontFamily: "Helvetica",
    fontSize: "13px",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "47px",
    letterSpacing: 0,
    color: "#ffffff",
    textAlign: "center",
  },
  activeButton: {
    backgroundColor: "#555",
  },
  arrowContainer: {
    cursor: "pointer",
    width: "35px",
    height: "35px",
    backgroundColor: "#1d1d1d",
    borderRadius: "35px",
    border: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#b4b4b4",
    marginBottom: "6px",
    "&:hover": {
      color: "#fff",
    },
    "&:disabled": {
      color: "#555",
    },
  },
  arrowSVG: {
    width: "19px", // accounts for 2px padding
  },
}));

const getInitIndex = (
  contentNode: ContentNode | undefined,
  crumbs: ContentNode[] | undefined,
  numberOfTabs: number
) => {
  // get intersection of crumbs and children arrays (there should only be 1 ever since slugs are unique)
  const intersection = crumbs?.filter((element) =>
    contentNode?.children.includes(element)
  )[0]?.slug;

  // get the index of that intersection in the children array
  const itemIndex = contentNode?.children.findIndex(
    (child) => child.slug === intersection
  );
  // return the intersection index, or 0 if not found (should always be found though)
  if (itemIndex === -1) {
    // no intersection? return 0 (which is technically undefined and so annoying)
    return { initIndex: 0, initStartIndex: 0 };
  } else {
    if (itemIndex && itemIndex > numberOfTabs - 1) {
      // if the item index is past the number of tabs
      // move the start index over enough that it's the last item
      const initStartIndex = itemIndex - numberOfTabs + 1;

      // set the index to number of items - 1
      const initIndex = numberOfTabs - 1;

      return { initIndex: initIndex, initStartIndex: initStartIndex };
    } else {
      return { initIndex: itemIndex || 0, initStartIndex: 0 };
    }
  }
};

const TOCTabs = ({ contentNode }: TOCTabsProps) => {
  const classes = useTOCTabsStyles();

  const numberOfTabs = useMemo(() => 4, []);

  const crumbs = useCrumbs(contentNode);

  const [controlledIndex, setControlledIndex] = useState(0);
  const [tabStartIndex, setTabStartIndex] = useState(0);
  const { setOpen } = useContext(TOCContext);

  //i know, gross, but i'm at a loss :(  ryan signed off on it haha
  useEffectOnce(() => {
    let isCurrent = true;

    if (isCurrent) {
      setControlledIndex(
        getInitIndex(contentNode, crumbs, numberOfTabs).initIndex
      );
      setTabStartIndex(
        getInitIndex(contentNode, crumbs, numberOfTabs).initStartIndex
      );
    }
    return () => {
      isCurrent = false;
    };
  });

  const isOutOfRange = (num: number, ceiling: number): boolean =>
    num > Math.abs(ceiling) - 1 || num < 0;

  // handling extra tabs
  // might be able to clean this up
  const changeTabs = (numberOfTabs: number) => {
    const totalTabs = contentNode.children.length;
    let adjustedIndex;

    //persisting the opened tab if possible
    if (numberOfTabs > 0) {
      // moving forward.
      adjustedIndex = controlledIndex - (totalTabs - numberOfTabs);
      setControlledIndex(
        isOutOfRange(adjustedIndex, numberOfTabs) ? 0 : adjustedIndex
      );
    } else {
      // moving backward
      adjustedIndex = controlledIndex + (totalTabs + numberOfTabs);
      setControlledIndex(
        isOutOfRange(adjustedIndex, numberOfTabs) ? 0 : adjustedIndex
      );
    }

    // change tabs
    if (numberOfTabs + tabStartIndex > totalTabs - numberOfTabs) {
      setTabStartIndex(totalTabs - numberOfTabs);
    } else if (numberOfTabs + tabStartIndex < 0) {
      setTabStartIndex(0);
    } else {
      setTabStartIndex(tabStartIndex + numberOfTabs);
    }
  };

  const currentTabs = contentNode.children
    .slice(tabStartIndex, tabStartIndex + numberOfTabs)
    .map((child, index) => (
      <Tab key={`${child.id}-tab`} onClick={() => setControlledIndex(index)}>
        {child.title}
      </Tab>
    ));

  const currentPanels = contentNode.children
    .slice(tabStartIndex, tabStartIndex + numberOfTabs)
    .map((child) => (
      <TabPanel key={`${child.id}-panel`}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {child._children && child._children.length > 0 ? (
            child._children?.map((grandChild) => (
              // map children into the buttons
              <div
                key={`${grandChild.id}-grandchild`}
                className={classes.gridItem}
              >
                <Link
                  onClick={() => setOpen(false)}
                  to={`/${child.prefix}/${grandChild.slug}`}
                  className={`${classes.button} ${
                    useActiveButton(contentNode, grandChild as ContentNode) &&
                    classes.activeButton
                  }`}
                >
                  {grandChild.title}
                </Link>
              </div>
            ))
          ) : (
            //no children, so repeat the tab as a button
            <div className={classes.gridItem}>
              <Link
                onClick={() => setOpen(false)}
                to={child.path()}
                className={`${classes.button} ${
                  useActiveButton(contentNode, child) && classes.activeButton
                }`}
              >
                {child.title}
              </Link>
            </div>
          )}
        </div>
      </TabPanel>
    ));

  return (
    <div>
      <Tabs
        controlledIndex={controlledIndex}
        classOverrides={{
          tabClassName: classes.tab,
          activeTabClassName: classes.activeTab,
          tabListClassName: classes.tabList,
          panelClassName: classes.panel,
        }}
      >
        <div className={classes.tabContainer}>
          <button
            className={classes.arrowContainer}
            disabled={tabStartIndex === 0}
            onClick={() => {
              changeTabs(-numberOfTabs);
            }}
          >
            <ChevronLeftCircle className={classes.arrowSVG} />
          </button>

          <TabList>{currentTabs}</TabList>

          <button
            className={classes.arrowContainer}
            onClick={() => {
              changeTabs(numberOfTabs);
            }}
            disabled={
              // if there are less children than current position + numberOfTabs
              contentNode.children?.length <= tabStartIndex + numberOfTabs
            }
          >
            <ChevronRightCircle className={classes.arrowSVG} />
          </button>
        </div>
        <TabPanelList>{currentPanels}</TabPanelList>
      </Tabs>
    </div>
  );
};

export default TOCTabs;
