import React, { ReactElement } from "react";
import styles from "./InfiniteScrollIndexSection.module.scss";
import { Link, useParams } from "react-router-dom";
import { getInfinitScroll } from "../queries/useGeneric";
import Loading from "../Loading";
import { GenericPaginatedResponse, SortOption } from "../types/SharedTypes";

export type IndexSectionProps = {
  headerTitle: string;
  linkObj: {
    buttonText: string;
    emptyText: string;
    to: string;
  };
  endpoint: string;
  cardElement: ReactElement;
  perPage?: number;
  emptyElement: ReactElement;
  sort: SortOption;
};

const InfiniteScrollIndexSection = ({
  headerTitle,
  linkObj,
  endpoint,
  cardElement,
  perPage = 10,
  emptyElement,
  sort = { attr: "updatedAt", order: "DESC" },
}: IndexSectionProps) => {
  const { artistId } = useParams();

  const { isLoading, data, fetchNextPage } =
    getInfinitScroll<GenericPaginatedResponse>(
      endpoint,
      artistId || "",
      perPage,
      sort
    );

  const reducedData = data?.pages.reduce((collection, page) => {
    return [...collection, ...page.results];
  }, [] as any);

  const cards = reducedData?.map((item: any) =>
    React.cloneElement(cardElement, {
      data: item,
      key: item.id || item._id, // we need to update some of the models to send back the id, not the _id.
    })
  );

  const emptyCard = React.cloneElement(emptyElement, {
    linkObj,
  });

  if (!data && !isLoading) return <div>Error, no data and not loading</div>;

  return (
    <section className={styles["section"]}>
      <header>
        <h4> {headerTitle}</h4>
      </header>

      <div className={styles["card-container"]}>
        {isLoading ? (
          <div>
            <Loading />
          </div>
        ) : (
          cards
        )}
        {!isLoading &&
          data?.pages[0].pagination?.totalEntries === 0 &&
          emptyCard}
      </div>
      <div className={styles["lower-container"]}>
        <button
          className={`btn btn--outline ${styles["btn"]} full-width `}
          onClick={() => fetchNextPage()}
        >
          Load More
        </button>
        <Link
          to={linkObj.to}
          className={`btn ${styles["btn"]} ${styles["btn-bottom"]}`}
        >
          + {linkObj.buttonText}
        </Link>
      </div>
    </section>
  );
};

export default InfiniteScrollIndexSection;
