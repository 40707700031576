import React from "react";
import { makeStyles, Theme, IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
interface Props {
  trackListModal: any;
  trackIndex: number;
  playerColor: string;
  playerOpacity: string;
  displayMode: string;
  playerIsMobile: boolean;
}

type StyleProps = {
  mobilePlayer: boolean;
  playerColor: string | undefined;
  playerFont: string | undefined;
  height: string;
  opacity: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  sectionContainer: {
    height: ({ height }: StyleProps) => height,
    width: "100%",
    backgroundColor: ({ opacity }: StyleProps) => `#333333${opacity}`,
  },
  sectionWrapper: {
    height: "100%",
    maxWidth: "565px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: "12px",
    margin: "auto",
  },
  trackSection: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    height: "50%",
  },
  trackTitleIndex: {
    display: "flex",
    width: "90%",
  },
  trackTitle: {
    color: ({ playerColor }: StyleProps) => playerColor,
    fontFamily: ({ playerFont }: StyleProps) => playerFont,
    fontSize: ({ mobilePlayer }: StyleProps) =>
      mobilePlayer ? "20px" : "24px",
    fontWeight: 600,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  trackIndex: {
    marginRight: ".25rem",
    color: ({ playerColor }: StyleProps) => playerColor,
    fontSize: ({ mobilePlayer }: StyleProps) =>
      mobilePlayer ? "20px" : "24px",
    fontFamily: ({ playerFont }: StyleProps) => playerFont,
  },
  artistName: {
    fontSize: ({ mobilePlayer }: StyleProps) =>
      mobilePlayer ? "18px" : "24px",
    color: ({ playerColor }: StyleProps) => playerColor,
    fontFamily: ({ playerFont }: StyleProps) => playerFont,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  iconButton: {
    color: ({ playerColor }: StyleProps) => playerColor,
    padding: 0,
  },
}));

export default function ArtistNameTitleSection(props: Props) {
  const { artistLink } = useArtistLinkContext();

  const showTrackData = artistLink.tracks && artistLink.tracks.length > 1;
  const height = props.playerIsMobile ? "60px" : "100px";
  const mobilePlayer = props.playerIsMobile;
  const playerFont = artistLink.playerFont;
  const playerColor = artistLink.playerColor;
  const opacity = props.playerOpacity;

  const currentTrack =
    artistLink.tracks && artistLink.tracks[props.trackIndex]?.title;

  const classes = useStyles({
    mobilePlayer,
    playerFont,
    playerColor,
    height,
    opacity,
  });
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionWrapper}>
        <div className={classes.trackSection}>
          <div className={classes.trackTitleIndex}>
            {showTrackData && (
              <div className={classes.trackIndex}>{props.trackIndex + 1}.</div>
            )}
            <div className={classes.trackTitle}>
              {currentTrack?.removeExt()}
            </div>
          </div>
          {showTrackData && (
            <IconButton
              onClick={props.trackListModal}
              className={classes.iconButton}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          )}
        </div>

        <div className={classes.artistName}>
          {artistLink.artist && artistLink.artist.name}
        </div>
      </div>
    </div>
  );
}
