import React from "react";
import { ModalInputActionBarProps } from "../ModalInput";
import styles from "./ModalInputDesktopActionBar.module.scss";

const ModalInputDesktopActionBar = ({
  showSave,
  showPrevious,
  handlePrevious,
  handleCancel,
  handleSave,
  handleNext,
  leftCancel,
}: ModalInputActionBarProps) => {
  return (
    <div className={styles["container"]}>
      {showPrevious && (
        <div style={{ flex: 1 }}>
          <button className="btn" onClick={handlePrevious}>
            Previous
          </button>
        </div>
      )}
      <div style={{ flex: leftCancel ? 1 : "unset" }}>
        <button className="btn btn--outline" onClick={handleCancel}>
          Cancel
        </button>
      </div>

      {showSave ? (
        <button className="btn" onClick={handleSave}>
          Save
        </button>
      ) : (
        <button className="btn" onClick={handleNext}>
          NEXT
        </button>
      )}
    </div>
  );
};

export default ModalInputDesktopActionBar;
