import React from "react";
import Loading from "../../shared/Loading";
import ReactDOM from "react-dom";
import Lockdown from "../../shared/components/Lockdown/Lockdown";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, ClickAwayListener, makeStyles } from "@material-ui/core";
import { useLoadingContext } from "../../providers/LoadingProvider";

type Props = {
  setShowPortal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (userPassword: string) => void;
};

const useStyles = makeStyles((theme) => ({
  portalContainer: {
    // positioning on page
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",

    // container
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
  },
  iconButton: {
    position: "absolute",
    right: "-15px",
    top: "-15px",
    backgroundColor: theme.palette.secondary.light,
  },
  loaderContainer: {
    marginBottom: "25px", // don't need?
  },
}));

const LockDownPortal = ({ setShowPortal, onSubmit }: Props) => {
  const { loading } = useLoadingContext();

  const classes = useStyles();

  return ReactDOM.createPortal(
    <ClickAwayListener
      touchEvent="onTouchStart"
      onClickAway={() => setShowPortal(false)}
    >
      <div className={classes.portalContainer}>
        <IconButton
          size="small"
          className={classes.iconButton}
          onClick={() => setShowPortal(false)}
        >
          <CloseIcon />
        </IconButton>
        <Lockdown
          lockDownMessage={"This download is password protected."}
          onSubmit={onSubmit}
          height={"400px"}
        />
        {loading && (
          <div className={classes.loaderContainer}>
            <Loading height={"100px"} />
          </div>
        )}
      </div>
    </ClickAwayListener>,
    document.body
  );
};

export default LockDownPortal;
