import React from "react"

interface Props {
  color: string
}

const PlayButton = (props: Props) => {

  const iconColor = props.color ? props.color : '#FFFFFF'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="36" viewBox="0 0 31 36">
      <g fill="none" fillRule="evenodd">
        <g fill={`${iconColor}`} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path d="M167.423 171.461l25.218-15.076c1.835-1.094 1.788-3.71 0-4.803l-25.218-15.076c-1.93-1.141-4.423-.285-4.423 1.902v31.15c0 2.14 2.352 3.14 4.423 1.903z" transform="translate(-834 -1015) translate(642 204) translate(-367 -296) translate(396 971)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PlayButton
