import { makeStyles, TextField, withWidth } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React, { useEffect, useState } from "react";
import Product from "../models/p4a_proxy/Products";
import NoResults from "../../shared/NoResults";
import regExp from "../../shared/RegExp";
import Tile from "./Tile";
import { usePlatoonReleaseContext } from "../../providers/PlatoonReleaseProvider";
import { useLoadingContext } from "../../providers/LoadingProvider";
import Loading from "../../shared/Loading";
import isEqual from "lodash.isequal";

type BaseProps = {
  width: Breakpoint;
  dispatch: any;
  customRelease?: React.ReactNode;
  imgSize?: string;
  releaseFilter?: { key: string; value?: string[] };
};

type ConditionalProps =
  | {
      selectedP4aReleaseId: string | null;
      selectedP4aReleaseIds?: never;
      selectedReleasesOnMount?: never;
    }
  | {
      selectedP4aReleaseIds: string[] | null;
      selectedReleasesOnMount: string[] | null;
      selectedP4aReleaseId?: never;
    };

type Props = BaseProps & ConditionalProps;

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing(2),
  },
  releasesGrid: {
    display: "grid",
    gridTemplateColumns: ({ imgSize }: any) =>
      `repeat(auto-fill, minmax(${imgSize || "300px"}, 1fr))`,
    gridGap: theme.spacing(2),
    padding: "8px 0",
    maxWidth: "calc(100% - 4px)",
    width: "100%",
    margin: "auto",
  },
  progressContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
  searchRoot: {
    outline: "none",
    border: "none",
    "& input": {
      color: "white",
      backgroundColor: "#333333",
      paddingLeft: "17px",
    },
    "& .MuiInputBase-adornedStart": {
      color: "white",
      backgroundColor: "#333333",
      fontFamily: "SF Pro Rounded, SF Pro",
      paddingLeft: "12px",
    },
    "& .MuiFormHelperText-root": {
      justifyContent: "flex-start",
    },
  },
}));

const filterReleases = (
  isMulti: boolean,
  releases: Product[] | null,
  selectedP4aReleaseIds: string[] | undefined | null,
  selectedP4aReleaseId: string | undefined | null
) => {
  if (isMulti) {
    return (
      releases?.filter((release) =>
        selectedP4aReleaseIds?.some((id) => id === release.id)
      ) || []
    );
  } else {
    return (
      releases?.filter((release) => selectedP4aReleaseId === release.id) || []
    );
  }
};

const Release = ({
  width,
  selectedP4aReleaseId,
  selectedP4aReleaseIds,
  dispatch,
  customRelease,
  imgSize,
  releaseFilter,
  selectedReleasesOnMount,
}: Props) => {
  const classes = useStyles({ width, imgSize });

  const { releases } = usePlatoonReleaseContext();
  const { loading } = useLoadingContext();

  const isMulti = selectedP4aReleaseIds !== undefined;

  const initPreSearchResults = filterReleases(
    isMulti,
    releases,
    selectedReleasesOnMount,
    selectedP4aReleaseId
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [preSearchReleases, setPreSearchReleases] =
    useState(initPreSearchResults);

  // we only want to update the preSearch releases when we arent searching,
  // so that clicking a release doesnt move it up to the top and such
  useEffect(() => {
    if (searchTerm === "") {
      const filteredReleases = filterReleases(
        isMulti,
        releases,
        selectedP4aReleaseIds,
        selectedP4aReleaseId
      );

      if (!isEqual(filteredReleases, preSearchReleases)) {
        setPreSearchReleases(filteredReleases);
      }
    }
  }, [searchTerm, selectedP4aReleaseIds, selectedP4aReleaseId]);

  // untouched release searching
  const filteredReleaseReg = new RegExp(
    regExp.escapeRegex(searchTerm.toLocaleLowerCase())
  );

  const filteredReleases =
    releases?.filter((release: any) => {
      if (releaseFilter) {
        if (releaseFilter.value) {
          return (
            releaseFilter.value.includes(release[releaseFilter.key]) &&
            filteredReleaseReg.test(release.name.toLocaleLowerCase())
          );
        } else {
          return (
            release[releaseFilter.key] &&
            filteredReleaseReg.test(release.name.toLocaleLowerCase())
          );
        }
      } else {
        return filteredReleaseReg.test(release.name.toLocaleLowerCase());
      }
    }) || [];

  const updateReleaseState = (release: Product) => {
    if (selectedP4aReleaseId === release.id) {
      dispatch({
        type: "SET_RELEASE",
        payload: {
          p4aReleaseId: "",
          release: null,
        },
      });

      // if we aren't currently searching, remove from this hook too
      // will need to add them once the search term is clear as well
      searchTerm === "" &&
        setPreSearchReleases((prevState) =>
          prevState?.filter((prevRelease) => prevRelease.id !== release.id)
        );
    } else {
      dispatch({
        type: "SET_RELEASE",
        payload: {
          p4aReleaseId: release.id,
          release: release,
        },
      });
      // if we aren't currently searching, add to this hook too
      // will need to add them once the search term is clear as well
      searchTerm === "" &&
        setPreSearchReleases((prevState) => [...prevState, release]);
    }
  };

  // Pre-prend the search results with selected releases
  // or pre-pend the search results with the selected releases, and filter out the picked ones from the search result
  // may need to store in state the selected ones so selecting a new one does not move it up to the search res

  const isSearching = searchTerm !== "";

  const sortedSearchResults = [
    ...preSearchReleases,
    ...filteredReleases.filter(
      (release) => !preSearchReleases?.some((preRe) => preRe.id === release.id)
    ),
  ];

  const renderedReleaseReuslts = isSearching
    ? sortedSearchResults
    : filteredReleases;

  const hasReleases = releases && releases?.length > 0;
  const hasFilteredReleases = renderedReleaseReuslts.length > 0;

  return (
    <div className={classes.gridContainer}>
      <div>
        <TextField
          variant="standard"
          InputProps={{ startAdornment: "􀊫" }}
          classes={{ root: classes.searchRoot }}
          placeholder="Search releases"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          fullWidth
        />
      </div>
      {loading ? (
        <Loading height={"calc(100vh - 500px)"} />
      ) : (
        <>
          {!hasReleases && !customRelease && (
            <NoResults text="NO RELEASES AVAILABLE" />
          )}
          {hasReleases && !hasFilteredReleases && !customRelease && (
            <NoResults text="NO RELEASES FOUND" />
          )}
          {(!hasReleases || !hasFilteredReleases) && customRelease && (
            <div className={classes.releasesGrid}>{customRelease}</div>
          )}
          {hasFilteredReleases && (
            <div className={classes.releasesGrid}>
              {customRelease ? customRelease : null}

              {/* when searching, we want to shift all of the initReleases to the start, then the search results */}
              {renderedReleaseReuslts.map((release: Product) => (
                <div
                  key={release.id}
                  onClick={() => {
                    updateReleaseState(release);
                  }}
                >
                  <Tile
                    square
                    release={release}
                    selected={
                      selectedP4aReleaseIds?.some((id) => id === release?.id) ||
                      selectedP4aReleaseId === release?.id
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withWidth()(Release);
