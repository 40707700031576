import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import ArtistLinkMusicPlayer from "../MusicPlayerComponents/ArtistLinkMusicPlayer";
import ActionBar from "../../shared/ActionBar";
import FormFields from "./FormFields";
interface Props {
  onSave: any;
  onCancel: any;
  setForceOpenCollapse: React.Dispatch<React.SetStateAction<string[]>>;
  forceOpenCollapse: string[];
  canSave: boolean;
}

export default function MobileEditView(props: Props) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        cardStyles={{
          front: {
            WebkitTransformStyle: "unset",
            transformStyle: "preserve-3d",
            zIndex: isFlipped ? 0 : 2,
          },
          back: {
            WebkitTransformStyle: "unset",
            transformStyle: "preserve-3d",
            opacity: isFlipped ? "100%" : "0",
          },
        }}
      >
        <FormFields
          width={"100%"}
          setForceOpenCollapse={props.setForceOpenCollapse}
          forceOpenCollapse={props.forceOpenCollapse}
        />
        <ArtistLinkMusicPlayer
          playerHeight={"mobileEdit"}
          playerWidth={12}
          preview={false}
        />
      </ReactCardFlip>

      <ActionBar
        position="bottom"
        buttons={[
          {
            text: isFlipped ? "Edit" : "Preview",
            group: "left",
            action: handleFlip,
            buttonProps: { variant: "outlined" },
          },
          {
            text: "Cancel",
            group: "right",
            action: props.onCancel,
            buttonProps: { variant: "outlined" },
            textProps: {
              color: "textPrimary",
              style: {
                fontWeight: "bold",
              },
            },
          },
          {
            text: "Save",
            group: "right",
            buttonProps: {
              variant: "contained",
              color: "secondary",
              disabled: !props.canSave,
              type: "submit",
            },
          },
        ]}
      />
    </>
  );
}
