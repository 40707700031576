import React, { useContext, useEffect, createContext, useState } from "react";
import PromoPack, { PromoPackAPI } from "../models/PromoPack";
import API from "../../shared/API";
import { defaultResourceSchema, UserRole } from "../../shared/ResourceSchema";
import { useReducer } from "react";
import { getQueryParam } from "../../shared/helpers/helpers";
import { usePlatoonArtistContext } from "../../providers/PlatoonArtistProvider";
import { usePlatoonReleaseContext } from "../../providers/PlatoonReleaseProvider";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { useErrorHandlingContext } from "../../providers/ErrorHandlingProvider";

type Props = {
  children: any;
};

type PromoPackContext = {
  promoPacks: PromoPack[] | undefined;
  promoPackPage: number;
  promoPackPageCount: number;
  setPromoPackPage: React.Dispatch<React.SetStateAction<number>>;
  setPromoPackPageCount: React.Dispatch<React.SetStateAction<number>>;
  fetchPromoPack: (promoPackId: string) => void;
  fetchPromoPacks: (artistId: string) => void;
  postPromoPack: () => Promise<any>;
  updatePromoPack: (promoPack?: PromoPack) => Promise<any>;
  fetchPromoPackWithRelease: (
    artistId: string,
    promoPackId: string
  ) => Promise<void>;
  createAsset: (blob: any, promoPackId: string) => Promise<any>;
  updateAsset: (
    blob: any,
    promopackId: string,
    assetId: string
  ) => Promise<any>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  savingMode: SavingMode;
  setSavingMode: React.Dispatch<React.SetStateAction<SavingMode>>;
  promoPack: PromoPack; // type these before approving pr
  dispatch: any; // type these before approving pr
};

export const PromoPackContext = createContext<PromoPackContext | null>(null);
export const usePromoPackContext = () => useContext(PromoPackContext)!;

type SavingMode = "CREATE" | "EDIT";

export const PromoPackProvider: React.FC<Props> = ({ children }: Props) => {
  const { errorDispatch } = useErrorHandlingContext();
  const { p4aArtist } = usePlatoonArtistContext();
  const { releases, fetchRelease } = usePlatoonReleaseContext();
  const { loading, setLoading, loadingStatus, setLoadingStatus } =
    useLoadingContext();

  const [promoPacks, setPromoPacks] = useState<PromoPack[] | undefined>();

  //use the param, or 1 if none present
  const [promoPackPage, setPromoPackPage] = useState(
    parseInt(getQueryParam("page")) || 1
  );

  const [promoPackPageCount, setPromoPackPageCount] = useState(0);

  const [savingMode, setSavingMode] = useState<SavingMode>("CREATE"); // should we init with create?

  const promoPackReducer = (state: PromoPack | null, action: any) => {
    switch (action.type) {
      case "SET_RELEASE":
        return {
          ...state,
          p4aReleaseId: action.payload.p4aReleaseId,
          release: action.payload.release,
        };

      case "SET_PACK_NAME":
        return {
          ...state,
          name: action.payload.name,
        };

      case "SET_TEMPLATE":
        return {
          ...state,
          templateIdentifier: action.payload.templateIdentifier,
          templateConfig: action.payload.templateConfig,
        };

      case "REMOVE_TEMPLATE":
        return {
          ...state,
          templateIdentifier: "",
          templateConfig: {},
        };

      case "UPDATE_TEMPLATECONFIG":
        return {
          ...state,
          templateConfig: action.payload.templateConfig,
        };

      case "SET_PROMOPACK":
        // replacing the entire promoPack object with a new one
        // if we expand this hook to contain more than just a promo pack, we'll need to restructure
        return {
          ...action.payload,
        };

      default:
        console.error("UNKNOWN ACTION TYPE:", action.type);
        return state;
    }
  };

  const [promoPack, dispatch] = useReducer(promoPackReducer, null);

  useEffect(() => {
    if (
      Object.values(loadingStatus).filter((status) => status === "IN PROGRESS")
        .length > 0
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loadingStatus]);

  const fetchPromoPacks = async (artistId: string) => {
    try {
      setLoadingStatus({
        ...loadingStatus,
        ["fetchPromoPacks"]: "IN PROGRESS",
      });

      const response = await PromoPackAPI.query(artistId, promoPackPage);

      // let's append these promo packs with the releases
      // TODO: what if they delete a release that was associated with a promo pack?
      // TODO: Can we change release to Product everywhere in our app so it matches with Symphony?

      const promoPacksWithReleases = response.results.map((pack: any) => ({
        ...pack,
        release: releases?.find(
          (product) => product.id === pack.p4aReleaseId
        ) || { name: "Release Not Found" }, // probably should have a more complete object here for uh-ohs
      }));

      setPromoPacks(promoPacksWithReleases);
      setPromoPackPageCount(response.pagination.totalPages);
      setLoadingStatus({ ...loadingStatus, ["fetchPromoPacks"]: "DONE" });
    } catch (error) {
      errorDispatch({
        type: "SET_ERROR",
        payload: { error: error, serveErrorPage: true },
      });
    }
  };

  const fetchPromoPackWithRelease = async (
    artistId: string,
    promoPackId: string
  ) => {
    // clear out current promo pack, while fetching a new one.
    // This helps with the download page not showing old assets (promoPack) when loading up a new one.
    dispatch({
      type: "SET_PROMOPACK",
      payload: {},
    });
    try {
      if (artistId === p4aArtist.id) {
        setLoadingStatus({
          ...loadingStatus,
          ["fetchPromoPackWithRelease"]: "IN PROGRESS",
        });

        let promoPackResponse: PromoPack = await PromoPackAPI.read(
          artistId,
          promoPackId
        );

        const releaseResponse: any = await fetchRelease(
          artistId,
          promoPackResponse.p4aReleaseId
        );
        promoPackResponse.release = releaseResponse;
        dispatch({
          type: "SET_PROMOPACK",
          payload: promoPackResponse,
        });

        setLoadingStatus({
          ...loadingStatus,
          ["fetchPromoPackWithRelease"]: "DONE",
        });
      } else throw Error;
    } catch (error) {
      errorDispatch({
        type: "SET_ERROR",
        payload: { error: error, serveErrorPage: true },
      });
    }
  };

  const fetchPromoPack = async (promoPackId: string) => {
    try {
      setLoadingStatus({ ...loadingStatus, ["fetchPromoPack"]: "IN PROGRESS" });

      const response: PromoPack = await PromoPackAPI.read(
        p4aArtist.id,
        promoPackId
      );

      dispatch({
        type: "SET_PROMOPACK",
        payload: response,
      });

      setLoadingStatus({ ...loadingStatus, ["fetchPromoPack"]: "DONE" });
    } catch (error) {
      errorDispatch({
        type: "SET_ERROR",
        payload: { error: error, serveErrorPage: true },
      });
    }
  };

  const postPromoPack = async () => {
    try {
      setLoadingStatus({ ...loadingStatus, ["postPromoPack"]: "IN PROGRESS" });

      const postData = await PromoPackAPI.create(p4aArtist.id, promoPack);
      const postDataWithRelease = {
        ...postData,
        release: releases?.find(
          (product) => product.id === postData.p4aReleaseId
        ),
      };

      dispatch({
        type: "SET_PROMOPACK",
        payload: postDataWithRelease,
      });

      setLoadingStatus({ ...loadingStatus, ["postPromoPack"]: "DONE" });
      return postDataWithRelease;
    } catch (error) {
      errorDispatch({ type: "SET_ERROR", payload: { error: error } });
    }
  };

  const updatePromoPack = async (promoPackParam?: PromoPack) => {
    try {
      // update the promoPack
      setLoadingStatus({
        ...loadingStatus,
        ["updatePromoPack"]: "IN PROGRESS",
      });

      const postData = await PromoPackAPI.update(
        p4aArtist.id,
        promoPackParam ? promoPackParam : promoPack,
        promoPack.id
      );
      // append the release info to the returned updatedPromoPack
      const postDataWithRelease = {
        ...postData,
        release:
          releases &&
          releases.find((product) => product.id === postData.p4aReleaseId),
      };

      dispatch({
        type: "SET_PROMOPACK",
        payload: postDataWithRelease,
      });

      setLoadingStatus({ ...loadingStatus, ["updatePromoPack"]: "DONE" });
      // update each asset happens in customize
      return postDataWithRelease;
    } catch (error) {
      errorDispatch({ type: "SET_ERROR", payload: { error: error } });
    }
  };

  const createAsset = async (blob: any, promoPackId: string) => {
    try {
      setLoadingStatus({ ...loadingStatus, ["createAsset"]: "IN PROGRESS" });

      const newAsset = await API.create(
        blob,
        defaultResourceSchema(
          `promo-packs/${promoPackId}/assets`,
          UserRole.Artist
        )
      );
      setLoadingStatus({ ...loadingStatus, ["createAsset"]: "DONE" });
      return newAsset;
    } catch (error) {
      errorDispatch({ type: "SET_ERROR", payload: { error: error } });
    }
  };

  const updateAsset = async (
    blob: any,
    promoPackId: string,
    assetId: string
  ) => {
    try {
      setLoadingStatus({ ...loadingStatus, ["updateAsset"]: "IN PROGRESS" });

      const updatedAsset = await API.update(
        assetId,
        blob,
        defaultResourceSchema(
          `promo-packs/${promoPackId}/assets`,
          UserRole.Artist
        )
      );
      setLoadingStatus({ ...loadingStatus, ["updateAsset"]: "DONE" });
      return updatedAsset;
    } catch (error) {
      errorDispatch({ type: "SET_ERROR", payload: { error: error } });
    }
  };

  return (
    <PromoPackContext.Provider
      value={{
        promoPacks,
        fetchPromoPacks,
        fetchPromoPack,
        fetchPromoPackWithRelease,
        promoPackPage,
        setPromoPackPage,
        promoPackPageCount,
        setPromoPackPageCount,
        postPromoPack,
        updatePromoPack,
        createAsset,
        updateAsset,
        loading,
        setLoading,
        savingMode,
        setSavingMode,
        promoPack,
        dispatch,
      }}
    >
      {children}
    </PromoPackContext.Provider>
  );
};

export default PromoPackProvider;
