interface IframeModel {
  url?: string;
  code?: string;
}

interface IframeBlock {
  type: string;
  urlIframe?: IframeModel;
  codeIframe?: IframeModel;
  warnings?: string[];
}

function emptyIframeBlock() {
  return {
    type: "url",
    urlIframe: { url: '' },
    codeIframe: { code: '' }
  }
}

export default IframeBlock;
export { IframeModel, emptyIframeBlock };
