import { Logger } from "../../shared/SafeLogger";
import API from "../../shared/API";
import ArtistLinkSchema from "../../artist-links/ArtistLinkSchema";
import RockDove from "../../shared/RockDove";
import { FileUploadType } from "../../template-page-builder/components/inputs/FileInput/FileInput";

// TODO:  Break into own file
interface Artist {
  p4aArtistId: string;
  id: string;
  _id?: string;
  name: string;
  slug?: string;
}

interface ArtistLink {
  id?: string;
  slug: string;
  title: string;
  artistNameFont?: string;
  artistNameLogo?: FileUploadType | null;
  artistNameLogoID?: string;
  artistNameMode: ArtistNameMode;
  tracks?: Track[] | null;
  trackLinks?: TrackLink[] | null;
  backgroundMode?: BackgroundMode;
  backgroundColor?: string;
  backgroundImage?: Asset | null;
  backgroundImageID?: string;
  gaussianBlur?: boolean;
  textColor?: string;
  playerColor?: string;
  playerFont?: string;
  expires?: boolean;
  artistLinkState?: ArtistLinkState;
  startDateTime?: Date;
  endDateTime?: Date | null;
  timeZone?: string;
  albumArtwork?: Asset;
  albumArtworkID?: string;
  headline?: string;
  headlineFont?: string;
  description?: string;
  descriptionFont?: string;
  password?: string;
  p4aReleaseId: string;
  release?: any;
  requiresPassword: boolean;
  audioDownloadPassword?: string;
  requiresAudioDownloadPassword: boolean;
  audioDownloadButton?: ArtistAudioDownloadButton;
  audioDownloadButtonColor?: string;
  audioDownloadButtonPosition?: DownloadButtonPosition;
  artist: Artist;
  artistSocials: ArtistSocials;
  shortId?: string;
}

type ArtistLinkNoEnums = Omit<
  ArtistLink,
  | "artistNameMode"
  | "backgroundMode"
  | "audioDownloadButton"
  | "audioDownloadButtonPosition"
>;

type ArtistSocials = {
  instagram: string;
  twitter: string;
  tiktok: string;
  facebook: string;
  youtube: string;
  website: string;
};

interface Asset {
  attachment: any;
  description?: any;
  p4aAssetId?: string | null;
  slug?: string;
  thumbnail?: string | null;
  derivatives?: AssetDerivatives;
  type?: string;
  id?: string;
  downloadUrl: string;
}

type AssetDerivatives = {
  small: string;
  medium: string;
  large: string;
  xlarge: string;
};
interface Track {
  derivatives?: any;
  title: string;
  attachment: any;
  duration?: number | null;
  id: any;
}

interface TrackLink {
  trackId?: string;
  position?: number;
}

enum ArtistLinkState {
  pending = "PENDING",
  live = "LIVE",
  expired = "EXPIRED",
  abandoned = "ABANDONED",
}

enum ArtistAudioDownloadButton {
  transparent = "Transparent",
  circle = "Circle",
  square = "Square",
}

enum DownloadButtonPosition {
  right = "RIGHT",
  left = "LEFT",
}

enum BackgroundMode {
  color = "color",
  image = "image",
  blur = "blur",
}

enum ArtistNameMode {
  text = "text",
  image = "image",
}

const ArtistLinks: ArtistLink[] = [];

const schema = (artistId: string, dataIsNested: boolean = false) => {
  let schema = { ...ArtistLinkSchema };
  schema.apiRootPath = `artists/${artistId}/links`;
  schema.dataIsNested = dataIsNested;
  return schema;
};

const cloneResource = (artistId: string, resource: ArtistLink) => {
  Logger.of("App").info("artistId", artistId, "resource", resource);
  return API.clone(resource, schema(artistId));
};

const postResource = (artistId: string, resource: ArtistLink) => {
  Logger.of("App").info("artistId", artistId, "resource", resource);
  return API.create(resource, schema(artistId));
};

const patchResource = (artistId: string, resource: ArtistLink, id: string) => {
  return API.update(id, resource, schema(artistId));
};

const deleteResource = (artistId: string, resource: any) => {
  Logger.of("App").info("resource to be deleted", resource);
  return API.delete(artistId, schema(resource, false));
};

const getResources = (artistId: string) => {
  return API.index(schema(artistId, true));
};

const getResourceQueryResponse = (artistId: string, page = 0) => {
  return API.query(schema(artistId, true), page);
};

const getResource = (artistId: string, artistLinkId: string) => {
  return API.read(artistLinkId, schema(artistId, false));
};

const ArtistLinkAPI = {
  index: getResources,
  query: getResourceQueryResponse,
  read: getResource,
  create: postResource,
  update: patchResource,
  delete: deleteResource,
  clone: cloneResource,
};

const defaultArtistLink = () => {
  return {
    slug: "",
    title: "",
    artistNameFont: "SF Pro Display",
    artistNameLogo: null,
    // artistNameLogoID: null,
    artistNameMode: ArtistNameMode.text,
    tracks: [] as Track[],
    trackLinks: [] as TrackLink[],
    backgroundMode: BackgroundMode.color,
    backgroundColor: "#000000",
    backgroundImage: null,
    // backgroundImageID:undefined,
    startDateTime: new Date(),
    endDateTime: null,
    albumArtwork: { attachment: "", description: "" },
    audioDownloadButton: ArtistAudioDownloadButton.transparent,
    audioDownloadButtonColor: "#1d1d1d",
    audioDownloadButtonPosition: DownloadButtonPosition.right,
    gaussianBlur: false,
    expires: false,
    headline: "",
    headlineFont: "SF Pro Display",
    description: "",
    descriptionFont: "SF Pro Display",
    textColor: "#FFFFFF",
    p4aReleaseId: "",
    release: null,
    playerColor: "#FFFFFF",
    playerFont: "SF Pro Display",
    requiresPassword: false,
    requiresAudioDownloadPassword: false,
    artist: { id: "123", name: "empty" },
    artistSocials: {
      instagram: "",
      tiktok: "",
      twitter: "",
      facebook: "",
      youtube: "",
      website: "",
    },
  } as ArtistLink;
};

const constructArtistLink = (data: any) => {
  //type casting until we develop helpers for indexing ArtistLink
  const initArtistLink = { ...defaultArtistLink() } as any;
  initArtistLink.artistSocials = {
    ...initArtistLink.artistSocials,
    ...data.p4aArtist.socialMedia,
  };

  return initArtistLink;
};

const deepCopyArtistLink = (artistLink: ArtistLink) => {
  var al = { ...artistLink };
  al.tracks = artistLink.tracks?.map((track: any) => {
    return { ...track };
  });
  al.trackLinks = artistLink.trackLinks?.map((trackLink: TrackLink) => {
    return { ...trackLink };
  });
  al.artistSocials = { ...artistLink.artistSocials };
  al.artist = { ...artistLink.artist };
  return al;
};

const defaultEndDateTime = (date: Date) => {
  let defaultDate = new Date(date);
  defaultDate.setDate(defaultDate.getDate() + 7);
  return defaultDate;
};

const privateLinkURL = (artistLink: ArtistLink) => {
  let baseURL = process.env.ASSEMBLE_HOST;
  let appPath = RockDove.shared().getParentAppPathFor("/apps/link-viewer");
  return `${window.location.protocol}//${baseURL}${appPath}/${artistLink.artist.slug}/${artistLink.slug}/${artistLink.shortId}`;
};

const artistLinkStateFor = (artistLink: ArtistLink) => {
  const now = new Date();

  if (artistLinkMayBeAbandoned(artistLink)) {
    return ArtistLinkState.abandoned;
  } else if (artistLink.startDateTime && now >= artistLink.startDateTime) {
    return artistLink.expires &&
      artistLink.endDateTime &&
      now >= artistLink.endDateTime
      ? ArtistLinkState.expired
      : ArtistLinkState.live;
  }

  return ArtistLinkState.pending;
};

const artistLinkMayBeAbandoned = (artistLink: ArtistLink) => {
  return artistLink.title === "";
};

export {
  ArtistLinkAPI,
  ArtistLinks,
  ArtistLinkNoEnums,
  BackgroundMode,
  ArtistAudioDownloadButton,
  DownloadButtonPosition,
  ArtistSocials,
  ArtistLinkState,
  Asset,
  Track,
  TrackLink,
  ArtistNameMode,
  defaultArtistLink,
  constructArtistLink,
  deepCopyArtistLink,
  privateLinkURL,
  Artist,
  defaultEndDateTime,
  artistLinkMayBeAbandoned,
  artistLinkStateFor,
};
export default ArtistLink;
