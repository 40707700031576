import Asset from "./Asset";

interface RawNode {
  id?: string;
  identifier: string;
  slug: string;
  title: string;
  subtitle: string;
  description: string;
  type: string;
  rawContent: string;
  cardType: "text" | "icon" | "header" | "bookmark";
  icon: string;
  iconAssetId?: string;
  parentId?: string;
  order: number;
  published: boolean;
  children?: RawNode[];
  isContentRoot?: boolean;
  level: number;
}

export { RawNode };

class Node {
  id?: string;
  identifier: string;
  slug: string;
  title: string;
  type: string;
  subtitle?: string;
  description?: string;
  rawContent?: string;
  cardType?: "text" | "icon" | "header" | "bookmark";
  icon?: string;
  iconAssetId?: string;
  _iconAsset?: Asset;
  parentId?: string;
  _parent?: Node;
  order: number;
  level: number;
  published: boolean;
  _children?: Node[];
  isContentRoot?: boolean;

  setParent(parent?: Node) {
    this._parent = parent;
    this.level = parent ? parent.level + 1 : 0;
  }

  get iconAsset(): Asset | undefined {
    return this._iconAsset;
  }

  get parent(): Node | undefined {
    return this._parent;
  }

  setChildren(children: Node[]) {
    this._children = children;
  }

  get children(): Node[] | undefined {
    return this._children;
  }

  /** These maybe only need to be in editor */
  toRawNode(): RawNode {
    return {
      id: this.id,
      identifier: this.identifier,
      title: this.title,
      type: this.type,

      subtitle: this.subtitle || "",
      description: this.description || "",
      rawContent: this.rawContent || "",
      cardType: this.cardType || "",
      icon: this.icon,
      iconAssetId: this.iconAssetId || "",
      published: this.published,

      parentId: this.parentId || "",
      order: this.order,
      slug: this.slug,
      level: this.level,

      is_content_root: this.isContentRoot || false,
    } as RawNode;
  }

  constructor(rawNode: RawNode) {
    this.id = rawNode.id;
    this.identifier = rawNode.identifier;
    this.slug = rawNode.slug;
    this.title = rawNode.title;
    this.type = rawNode.type;

    this.subtitle = rawNode.subtitle;
    this.description = rawNode.description;
    this.rawContent = rawNode.rawContent;
    this.cardType = rawNode.cardType;
    this.icon = rawNode.icon;
    this.iconAssetId = rawNode.iconAssetId;

    this.published = rawNode.published;

    this.parentId = rawNode.parentId;
    this.order = rawNode.order;
    this.level = rawNode.level;
    this.isContentRoot = rawNode.isContentRoot;
  }

  equals(otherNode: Node) {
    return (
      JSON.stringify(this.toRawNode()) === JSON.stringify(otherNode.toRawNode())
    );
  }
}

export default Node;

class NodeFactory {
  static createNodeFromRaw(
    Type: new (r: RawNode) => Node,
    rawNode: RawNode,
    parent?: Node
  ): Node {
    let node = new Type(rawNode);
    node.setParent(parent);
    node.setChildren(
      rawNode.children
        ? this.createNodesFromRaws(Type, rawNode.children, node)
        : []
    );

    return node;
  }

  static createNodesFromRaws(
    Type: new (r: RawNode) => Node,
    rawNodes: RawNode[],
    parent?: Node
  ): Node[] {
    return rawNodes.map((node) =>
      NodeFactory.createNodeFromRaw(Type, node, parent)
    );
  }
}

export { NodeFactory };
