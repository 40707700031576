import { Block, Template, TemplateVariant } from "../../../asset-generator-lib";
import { ShapeType } from "../../../asset-generator-lib/composer/models/Block";

const backgroundBlock: Block = {
  id: "backgroundBlock",
  key: "backgroundBlock",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 1950, h: 1950 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "background",
      name: "Design",
      type: "SELECT",
      options: [
        {
          key: "artworkBlurred",
          name: "Artwork Blurred",
          type: "MAPPED",
          mappedPath: "release.artworkUrl",
          style: {
            filter: { blur: 100, quality: 10 },
          },
        },
        {
          key: "customBackgroundColor",
          name: "Custom Background Color",
          type: "COLOR",
          value: "#FFFFFF",
        },
      ],
    },
  ],
};

const albumArtworkBlock: Block = {
  id: "artworkblock",
  key: "artworkblock",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 1000, h: 1000 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "image",
      name: "Album Artwork",
      type: "MAPPED",
      mappedPath: "release.artworkUrl",
    },
  ],
};

const textBoxBlock1: Block = {
  id: "textBoxBlock1",
  key: "textBoxBlock1",
  type: "TEXTBOX",
  frame: { origin: { x: 98, y: 20 }, anchor: { x: 1, y: 1 } },
  params: [
    {
      key: "shapeColor",
      name: "TEXT BOX COLOR",
      type: "COLOR",
      value: "#ffffff",
    },
    { key: "textColor", name: "TEXT COLOR", type: "COLOR", value: "#000000" },
    {
      key: "text",
      name: "Marketing Message",
      type: "SELECT",
      options: [
        {
          key: "usReleaseDate",
          name: "US Release Date",
          type: "MAPPED",
          mappedPath: "release.releaseDate",
        },
        {
          key: "ukReleaseDate",
          name: "UK Release Date ",
          type: "MAPPED",
          mappedPath: "release.releaseDateUK",
        },
        { key: "outNow", name: "Out Now", type: "STATIC", value: "Out Now" },
      ],
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 } },
    },
    {
      key: "23",
      id: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 75, h: 75 } },
      style: {},
    },
  ],
  style: {
    wordWrap: { wrap: false, wrapWidth: 0 },
    padding: { padding: 40 },
    background: { color: "ffffff" },
    font: {
      fontColor: "000000",
      fontSize: "40px",
      fontFamily: "Helvetica",
      fontWeight: "600",
    },
    rotation: { angle: -90 },
  },
};

const textBoxBlock2: Block = {
  id: "textBoxBlock2",
  key: "textBoxBlock2",
  type: "TEXTBOX",
  frame: {
    origin: { x: 1, y: 74.5 },
    anchor: { x: 0, y: 0.5 },
    size: { w: 700, h: 48 },
  },
  params: [
    {
      key: "text",
      name: "Release Name",
      type: "MAPPED",
      mappedPath: "release.name",
      defaultValue: "Release Name",
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 } },
    },
    {
      key: "23",
      id: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 75, h: 75 } },
    },
  ],
  style: {
    padding: { padding: 10 },
    background: { color: "ffffff" },
    font: {
      fontColor: "000000",
      fontSize: "48px",
      fontFamily: "Futura",
      fontWeight: "900",
    },
    rotation: { angle: 0 },
  },
};

const textBoxBlock3: Block = {
  id: "textBoxBlock3",
  key: "textBoxBlock3",
  type: "TEXTBOX",
  frame: {
    origin: { x: 1, y: 77.15 },
    anchor: { x: 0, y: 0.5 },
    size: { w: 500, h: 34 },
  },
  params: [
    {
      key: "text",
      name: "Artist Name",
      type: "MAPPED",
      mappedPath: "release.artistName",
      defaultValue: "Artist Name",
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 } },
    },
    {
      key: "23",
      id: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 75, h: 75 } },
    },
  ],
  style: {
    padding: { padding: 10 },
    background: { color: "ffffff" },
    font: {
      fontColor: "000000",
      fontSize: "34px",
      fontFamily: "Futura",
      fontWeight: "400",
    },
    rotation: { angle: 0 },
  },
};

const facebookEffortless: TemplateVariant = {
  name: "Facebook Cover",
  key: "facebookCover",
  size: { w: 820, h: 360 }, //TODO: this key is needed
  blockOverrides: [
    {
      key: "backgroundBlock",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 900, h: 900 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 30, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "artworkblock",
      type: "SPRITE",
      frame: {
        origin: { x: 70, y: 45 },
        size: { w: 250, h: 250 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "textBoxBlock1",
      type: "TEXTBOX",
      frame: { origin: { x: 87, y: 8.5 }, anchor: { x: 1, y: 1 } },
      style: {
        padding: { padding: 15 },
        font: {
          fontColor: "000000",
          fontSize: "20px",
          fontFamily: "Helvetica",
          fontWeight: "500",
        },
        rotation: { angle: -90 },
      },
    },
    {
      key: "textBoxBlock2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 52.5, y: 73 },
        anchor: { x: 0, y: 0.5 },
        size: { w: 225, h: 28 },
      },
      style: {
        padding: { padding: 10 },
        font: {
          fontColor: "000000",
          fontSize: "28px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "textBoxBlock3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 52.5, y: 82 },
        anchor: { x: 0, y: 0.5 },
        size: { w: 225, h: 20 },
      },
      style: {
        padding: { padding: 10 },
        font: {
          fontColor: "000000",
          fontSize: "20px",
          fontFamily: "Futura",
          fontWeight: "400",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const spotifyEffortless: TemplateVariant = {
  name: "Spotify Header",
  key: "spotifyHeader",
  size: { w: 2660, h: 1140 },
  blockOverrides: [
    {
      key: "backgroundBlock",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2800, h: 2800 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "artworkblock",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 700, h: 700 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "textBoxBlock1",
      type: "TEXTBOX",
      frame: { origin: { x: 64, y: 15 }, anchor: { x: 1, y: 1 } },
      style: {
        padding: { padding: 15 },
        font: {
          fontColor: "000000",
          fontSize: "30px",
          fontFamily: "Helvetica",
          fontWeight: "500",
        },
        rotation: { angle: -90 },
      },
    },
    {
      key: "textBoxBlock3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 33.5, y: 82.35 },
        anchor: { x: 0, y: 0.5 },
        size: { w: 725, h: 30 },
      },
      style: {
        padding: { padding: 10 },
        font: {
          fontColor: "000000",
          fontSize: "30px",
          fontFamily: "Futura",
          fontWeight: "400",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "textBoxBlock2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 33.5, y: 78.5 },
        anchor: { x: 0, y: 0.5 },
        size: { w: 725, h: 38 },
      },
      style: {
        padding: { padding: 10 },
        font: {
          fontColor: "000000",
          fontSize: "38px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const youtubeEffortless: TemplateVariant = {
  name: "Youtube Cover",
  key: "youtubeCover", //TODO: this key is needed
  size: { w: 2560, h: 1440 },
  blockOverrides: [
    {
      key: "backgroundBlock",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2650, h: 2650 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
            {
              key: "",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "artworkblock",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 400, h: 400 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "textBoxBlock1",
      type: "TEXTBOX",
      frame: { origin: { x: 58.5, y: 40 }, anchor: { x: 0, y: 1 } },
      style: {
        padding: { padding: 15 },
        font: {
          fontColor: "000000",
          fontSize: "24px",
          fontFamily: "Helvetica",
          fontWeight: "500",
        },
        rotation: { angle: -90 },
      },
    },
    {
      key: "textBoxBlock2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 40, y: 62.5 },
        anchor: { x: 0, y: 0.5 },
        size: { w: 400, h: 34 },
      },
      style: {
        padding: { padding: 10 },
        font: {
          fontColor: "000000",
          fontSize: "34px",
          fontFamily: "Helvetica",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "textBoxBlock3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 40, y: 65 },
        anchor: { x: 0, y: 0.5 },
        size: { w: 400, h: 20 },
      },
      style: {
        padding: { padding: 10 },
        font: {
          fontColor: "000000",
          fontSize: "20px",
          fontFamily: "Futura",
          fontWeight: "400",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const twitterEffortless: TemplateVariant = {
  name: "Twitter Header",
  key: "twitterHeader", //TODO: this key is needed
  size: { w: 1500, h: 500 },
  blockOverrides: [
    {
      key: "backgroundBlock",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 1550, h: 1550 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "artworkblock",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 325, h: 325 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "textBoxBlock1",
      type: "TEXTBOX",
      frame: { origin: { x: 61.5, y: 7.5 }, anchor: { x: 1, y: 1 } },
      style: {
        padding: { padding: 15 },
        font: {
          fontColor: "000000",
          fontSize: "20px",
          fontFamily: "Helvetica",
          fontWeight: "500",
        },
        rotation: { angle: -90 },
      },
    },
    {
      key: "textBoxBlock3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 35, y: 85 },
        anchor: { x: 0, y: 0.5 },
        size: { w: 375, h: 20 },
      },
      style: {
        padding: { padding: 10 },
        font: {
          fontColor: "000000",
          fontSize: "20px",
          fontFamily: "Futura",
          fontWeight: "400",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "textBoxBlock2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 35, y: 79.5 },
        anchor: { x: 0, y: 0.5 },
        size: { w: 375, h: 30 },
      },
      style: {
        padding: { padding: 10 },
        font: {
          fontColor: "000000",
          fontSize: "30px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const instagramEffortless: TemplateVariant = {
  name: "Instagram Story",
  key: "instagramStory",
  size: { w: 1080, h: 1920 },
};

const Effortless: Template = {
  key: "effortless",
  id: "effortless",
  name: "Effortless",
  blocks: [
    backgroundBlock,
    albumArtworkBlock,
    textBoxBlock1,
    textBoxBlock2,
    textBoxBlock3,
  ],
  meta: { version: "1.0", basis: { w: 100, h: 100 } },
  variants: [
    instagramEffortless,
    facebookEffortless,
    spotifyEffortless,
    youtubeEffortless,
    twitterEffortless,
  ],
};

export default Effortless;
