import React from "react";
import ReleasePickerInput from "../ReleasePickerInput/ReleasePickerInput";

const FilteredReleasePickerInput = (props: any) => {
  // releaseFilter can optionally take an array of values to filter on
  // ex: {key: "status", value: ["DELIVERED", "EDIT_IN_FUGA"]}
  return (
    <ReleasePickerInput
      {...props}
      releaseFilter={props.param.filter}
      customRelease={false}
    />
  );
};

export default FilteredReleasePickerInput;
