import React, { ReactNode } from "react";
import styles from "./Layout.module.scss";

type Props = {
  children: ReactNode;
  style?: React.HtmlHTMLAttributes<HTMLDivElement>["style"];
};

const Layout = ({ children, style }: Props) => {
  return (
    <div className={styles["container"]}>
      <div style={{ ...style }} className={styles["main"]}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
