import { Block, Template, TemplateVariant } from "../../../asset-generator-lib";
import { ShapeType } from "../../../asset-generator-lib/composer/models/Block";

const backgroundBlock: Block = {
  id: "classic_BGB",
  key: "classic_BGB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 1950, h: 1950 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "background",
      name: "Design",
      type: "SELECT",
      options: [
        {
          key: "customBackgroundColor",
          name: "Custom Background Color",
          type: "COLOR",
          value: "#FFFFFF",
        },
        {
          key: "artworkBlurred",
          name: "Artwork Blurred",
          type: "MAPPED",
          mappedPath: "release.artworkUrl",
          style: {
            filter: { blur: 80, quality: 10 },
          },
        },
      ],
    },
  ],
};

const albumArtworkBlock: Block = {
  id: "classic_AAB",
  key: "classic_AAB",
  type: "SPRITE",
  frame: {
    origin: { x: 47.5, y: 32.5 },
    size: { w: 850, h: 850 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "image",
      name: "Album Artwork",
      type: "MAPPED",
      mappedPath: "release.artworkUrl",
    },
  ],
};

const rectangle1: Block = {
  id: "classic_R1",
  key: "classic_R1",
  type: "SHAPE",
  shapeType: ShapeType.rect,
  params: [
    { key: "shape", name: "Rectangle 1", type: "STATIC", value: "000000" },
  ],
  frame: { origin: { x: 91, y: 5 }, size: { h: 950, w: 10 } },
  style: {
    border: { borderSize: 0, borderColor: "000000" },
  },
};

const rectangle2: Block = {
  id: "classic_R2",
  key: "classic_R2",
  type: "SHAPE",
  shapeType: ShapeType.rect,
  params: [
    { key: "shape", name: "Rectangle 1", type: "STATIC", value: "000000" },
  ],
  frame: { origin: { x: 8, y: 57 }, size: { h: 675, w: 10 } },
  style: {
    border: { borderSize: 0, borderColor: "000000" },
  },
};

const text1: Block = {
  id: "classic_T1",
  key: "classic_T1",
  type: "TEXT",
  params: [
    { key: "textColor", name: "TEXT COLOR", type: "COLOR", value: "#000000" },
    {
      key: "text",
      name: "Text Block 1",
      type: "MAPPED",
      defaultValue: "Release Name ",
      mappedPath: "release.name",
    },
  ],
  frame: {
    origin: { x: 12.5, y: 60 },
    anchor: { x: 0, y: 1 },
    size: { w: 700, h: 0 },
  },
  style: {
    rotation: { angle: 0 },
    border: { borderSize: 0, borderColor: "000000" },
    font: {
      fontColor: "000000",
      fontSize: "65px",
      fontFamily: "Futura",
      fontWeight: "900",
    },
  },
};

const text2: Block = {
  id: "classic_T2",
  key: "classic_T2",
  type: "TEXT",
  params: [
    {
      key: "text",
      name: "Marketing Message",
      type: "SELECT",
      options: [
        {
          key: "usReleaseDate",
          name: "US Release Date",
          type: "MAPPED",
          mappedPath: "release.outOnReleaseDate",
        },
        {
          key: "ukReleaseDate",
          name: "UK Release Date ",
          type: "MAPPED",
          mappedPath: "release.outOnReleaseDateUK",
        },
        { key: "outNow", name: "OUT NOW", type: "STATIC", value: "OUT NOW" },
      ],
    },
  ],
  frame: { origin: { x: 12.5, y: 60 }, anchor: { x: 0, y: 0 } },
  style: {
    wordWrap: { wrap: false, wrapWidth: 0 },
    rotation: { angle: 0 },
    border: { borderSize: 0, borderColor: "000000" },
    font: {
      fontColor: "000000",
      fontSize: "30px",
      fontFamily: "Helvetica",
      fontWeight: "600",
    },
  },
};

const classicFacebook: TemplateVariant = {
  name: "Facebook Cover",
  key: "facebookCover",
  size: { w: 820, h: 360 },
  blockOverrides: [
    {
      key: "classic_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 900, h: 820 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 30, quality: 10 },
              },
            },
          ],
        },
      ],
    },
    {
      key: "classic_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 30, y: 47.5 },
        size: { w: 250, h: 250 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "classic_R1",
      type: "SHAPE",
      frame: { origin: { x: 14.75, y: 92.5 }, size: { w: 250, h: 5 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "classic_R2",
      type: "SHAPE",
      frame: { origin: { x: 50, y: 13 }, size: { w: 325, h: 5 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "classic_T1",
      type: "TEXT",
      frame: {
        origin: { x: 50, y: 32.5 },
        anchor: { x: 0, y: 1 },
        size: { w: 325, h: 0 },
      },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "000000",
          fontSize: "35px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
      },
    },
    {
      key: "classic_T2",
      type: "TEXT",
      frame: { origin: { x: 50, y: 32.5 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "000000",
          fontSize: "25px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
  ],
};

const classicSpotify: TemplateVariant = {
  name: "Spotify Header",
  key: "spotifyHeader",
  size: { w: 2660, h: 1140 },
  blockOverrides: [
    {
      key: "classic_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2800, h: 2800 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
          ],
        },
      ],
    },
    {
      key: "classic_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 35 },
        size: { w: 450, h: 450 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "classic_R1",
      type: "SHAPE",
      frame: { origin: { x: 60, y: 7.5 }, size: { w: 5, h: 542 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "classic_R2",
      type: "SHAPE",
      frame: { origin: { x: 41.5, y: 60 }, size: { w: 5, h: 350 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "classic_T1",
      type: "TEXT",
      frame: {
        origin: { x: 43.5, y: 63 },
        anchor: { x: 0, y: 1 },
        size: { w: 400, h: 0 },
      },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "000000",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
      },
    },
    {
      key: "classic_T2",
      type: "TEXT",
      frame: { origin: { x: 43.5, y: 63 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "000000",
          fontSize: "26px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
  ],
};

const classicTwitter: TemplateVariant = {
  name: "Twitter Header",
  key: "twitterHeader",
  size: { w: 1500, h: 500 },
  blockOverrides: [
    {
      key: "classic_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 1550, h: 1550 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "classic_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 45 },
        size: { w: 350, h: 350 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "classic_R1",
      type: "SHAPE",
      frame: { origin: { x: 63.5, y: 10 }, size: { w: 400, h: 5 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "classic_R2",
      type: "SHAPE",
      frame: { origin: { x: 38.25, y: 85 }, size: { w: 352.5, h: 5 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "classic_T1",
      type: "TEXT",
      frame: {
        origin: { x: 63.5, y: 27.5 },
        anchor: { x: 0, y: 1 },
        size: { w: 400, h: 0 },
      },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "000000",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
      },
    },
    {
      key: "classic_T2",
      type: "TEXT",
      frame: { origin: { x: 63.5, y: 30 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "000000",
          fontSize: "26px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
  ],
};

const classicYoutube: TemplateVariant = {
  name: "Youtube Cover",
  key: "youtubeCover",
  size: { w: 2560, h: 1440 },
  blockOverrides: [
    {
      key: "classic_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2650, h: 2600 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
          ],
        },
      ],
    },
    {
      key: "classic_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 45, y: 47.5 },
        size: { w: 500, h: 500 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "classic_R1",
      type: "SHAPE",
      frame: { origin: { x: 57.5, y: 30.25 }, size: { w: 500, h: 5 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "classic_R2",
      type: "SHAPE",
      frame: { origin: { x: 35, y: 67.5 }, size: { w: 500, h: 5 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "classic_T1",
      type: "TEXT",
      frame: {
        origin: { x: 57.5, y: 37.5 },
        anchor: { x: 0, y: 1 },
        size: { w: 550, h: 0 },
      },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "000000",
          fontSize: "60px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
      },
    },
    {
      key: "classic_T2",
      type: "TEXT",
      frame: { origin: { x: 57.5, y: 37.5 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "000000",
          fontSize: "35px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
  ],
};

const classicInstagram = {
  name: "Instagram Story",
  key: "instagramStory",
  size: { w: 1080, h: 1920 },
} as TemplateVariant;

const Classic = {
  id: "classic",
  name: "Classic",
  blocks: [
    backgroundBlock,
    albumArtworkBlock,
    rectangle1,
    rectangle2,
    text1,
    text2,
  ],
  meta: { version: "1.0", basis: { w: 100, h: 100 } },
  variants: [
    classicInstagram,
    classicFacebook,
    classicSpotify,
    classicTwitter,
    classicYoutube,
  ],
} as Template;

export default Classic;
