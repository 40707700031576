import React, { useState, useEffect, useContext } from "react";
import { Logger } from "../shared/SafeLogger";
import API, { ResponseError } from "../shared/API";
import NodeSchema, { CMSNode } from "./NodeSchema";
import { SnackContext } from "../shared/SnackProvider";
import { makeStyles } from "@material-ui/core/styles";

import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formHr: {
    margin: "20px 0",
  },
  select: {
    width: "100%",
    display: "flex",
  },
}));

interface Props {
  parentId: string;
  onClose: () => void;
  open: boolean;
  reload: any;
  createdNode: any;
}

const NodeCreateDialogContent = (props: Props) => {
  const classes = useStyles();

  const [node, setNode] = useState<CMSNode>(CMSNode.emptyNode());
  const [responseError, setResponseError] = useState<ResponseError>();
  const { setSnack } = useContext(SnackContext);
  const [isDirty, setIsDirty] = useState(false);
  const [types, setTypes] = useState<string[]>([]);

  useEffect(() => {
    props.createdNode(undefined);
  }, [props.createdNode]);

  const originalNode = CMSNode.emptyNode();

  function handleTitleChange(event: any) {
    let newNode = node.copyNode() as CMSNode;
    newNode.title = event.target.value;
    newNode.identifier = newNode.title;
    setNode(newNode);
  }

  function handleIdentifierChange(event: any) {
    let newNode = node.copyNode() as CMSNode;
    newNode.identifier = event.target.value;
    setNode(newNode);
  }

  function handleTypeSelectChange(event: any) {
    let newNode = node.copyNode() as any;
    newNode.type = event.target.value;
    setNode(newNode);
  }

  const handleError = (error: ResponseError | Error) => {
    Logger.of("App").info("handleError:", error);
    let candidateResponseError = error as ResponseError;
    if (candidateResponseError.reasons !== undefined) {
      Logger.of("App").info("Caught error:", candidateResponseError);
      setResponseError(candidateResponseError);
    } else {
      setSnack({ message: error.message });
    }
  };

  function save(e: any) {
    e.preventDefault();
    const schema = NodeSchema;

    node.parentId = props.parentId;

    API.create(node, schema)
      .then((node) => {
        setSnack({
          message: `Created ${schema.title}`,
          source: `/admin/${schema.rootPath}/${node.id}`,
        });
        props.onClose();
        props.reload();
        props.createdNode(node);
      })
      .catch(handleError);
  }

  useEffect(() => {
    fetch("/api/v1/nodes/types")
      .then((response) => response.json())
      .then((obj) => {
        setTypes(obj.types);
      });
  }, []);

  useEffect(() => {
    checkIsDirty();
  }, [node]);

  const checkIsDirty = () => {
    let nodesAreEqual = node.equals(originalNode);

    setIsDirty(!nodesAreEqual);
  };

  function shouldShowIdentifierError() {
    return responseError && responseError.reasons.identifier !== undefined;
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <form>
        <DialogTitle id="form-dialog-title">Create a New Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pick a type and unique title for this page
          </DialogContentText>
          <FormControl
            className={classes.select}
            error={responseError && responseError.reasons.type !== undefined}
          >
            <InputLabel color="secondary" id="type">
              Type
            </InputLabel>
            <Select
              id="type"
              color="secondary"
              value={node.type}
              onChange={handleTypeSelectChange}
            >
              {types.map((type: string) => (
                <MenuItem value={type}>{type.capitalize()}</MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {responseError && responseError.reasons.type}
            </FormHelperText>
          </FormControl>

          <TextField
            autoFocus
            color="secondary"
            id="title"
            name="title"
            label="Title"
            value={node.title}
            onChange={handleTitleChange}
            helperText={responseError && responseError.reasons.title}
            error={responseError && responseError.reasons.title !== undefined}
            fullWidth
            margin="dense"
          />

          <TextField
            color="secondary"
            id="identifier"
            name="identifier"
            label="Unique Page Identifier"
            value={node.identifier}
            onChange={handleIdentifierChange}
            helperText={responseError && responseError.reasons.identifier}
            error={shouldShowIdentifierError()}
            fullWidth
            disabled={!shouldShowIdentifierError()}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="secondary">
            CANCEL
          </Button>
          <Button
            onClick={save}
            disabled={!isDirty}
            color="secondary"
            type="submit"
          >
            SAVE
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default NodeCreateDialogContent;
