import React, { useContext } from "react";
import API from "../shared/API";
import NodeSchema from "./NodeSchema";
import { SnackContext } from "../shared/SnackProvider";
import { makeStyles } from "@material-ui/core/styles";
import { useErrorHandlingContext } from "../providers/ErrorHandlingProvider";

import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  deleteDialogWrapper: {
    display: "flex",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  cancelButton: {
    color: "#ffffff",
    backgroundColor: "#999999",
  },
  nodeType: {
    marginLeft: ".25em",
  },
  nodeTitle: {
    marginLeft: ".25em",
    fontStyle: "italic",
  },
}));

interface Props {
  onClose: () => void;
  open: boolean;
  reload: any;
  node: any;
}

export default function NodeCreateDialogContent(props: Props) {
  const classes = useStyles();
  const schema = NodeSchema;

  const { errorDispatch } = useErrorHandlingContext();
  const { setSnack } = useContext(SnackContext);

  const handleDelete = (node: any) => {
    API.delete(node.id, schema)
      .then(() => {
        setSnack({ message: `${schema.title} deleted successfully` });
        props.reload();
      })
      .catch((error) => {
        errorDispatch({
          type: "SET_ERROR",
          payload: { error: error, serveSnack: true },
        });
      });
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
      <DialogContent>
        <div className={classes.deleteDialogWrapper}>
          <DialogContentText>Are you sure you want to delete</DialogContentText>
          <DialogContentText className={classes.nodeTitle}>
            {props.node.title}
          </DialogContentText>
          <DialogContentText className={classes.nodeType}>
            - {props.node.type.capitalize()}?
          </DialogContentText>
        </div>
        <div className={classes.buttonsWrapper}>
          <Button
            className={classes.cancelButton}
            variant="contained"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(props.node)}
          >
            Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
