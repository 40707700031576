import React, { useState, useEffect } from "react";
import AssetBlock, {
  AssetBlockProps,
  emptyAssetBlock,
  AssetFrame,
} from "./AssetBlock";
import AssetBlockPreviewItem from "./AssetBlockPreviewItem";
import AssetBlockPickerModal from "./AssetBlockPickerModal";
import Add from "@material-ui/icons/Add";
import {
  makeStyles,
  Theme,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import "../../ModalCms.tsx/modalStyles.scss";
import "../../../../../string.extension";

const useStyles = makeStyles((theme: Theme) => ({
  addButtonText: {
    marginTop: "2.5%",
    fontSize: "14px",
    fontWeight: 600,
    color: "#dc536a",
    fontFamily: "SF Pro Text",
    letterSpacing: "1.25px",
  },
  thumbnailImage: {
    height: 100,
    widht: 100,
  },
  previewGridContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  cancelIcon: {
    padding: "15px",
    cursor: "pointer",
  },
  chooseAssetContainer: {
    marginLeft: "25px",
    marginTop: "5px",
  },
  chooseAssetWrapper: {
    display: "flex",
    cursor: "pointer",
  },
  addIcon: {
    marginTop: "1px",
    color: "#da556c",
  },
  formContainer: {
    width: "100%",
    padding: "25px",
  },
  inputLabel: {
    padding: "25px",
  },
  selectMenu: {
    width: "100%",
  },
}));

function AssetPreviewContent({ assetBlock, setAssetBlock }: AssetBlockProps) {
  const classes = useStyles();
  const [pickerAssetBlock, setPickerAssetBlock] = useState<AssetBlock>();

  useEffect(() => {
    if (!assetBlock.asset) {
      addAssetBlock();
    }
  }, [assetBlock.asset]);

  function addAssetBlock() {
    setPickerAssetBlock(emptyAssetBlock(assetBlock.assetType));
  }

  function onPickAssetBlock(assetBlock: AssetBlock | undefined) {
    setAssetBlock(assetBlock);
    setPickerAssetBlock(undefined);
  }

  function handleSave() {
    onPickAssetBlock(pickerAssetBlock);
  }

  const handleFrameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let assetToBeFramed = { ...assetBlock };
    assetToBeFramed.frame = event.target.value as AssetFrame;
    setAssetBlock(assetToBeFramed);
  };

  return (
    <div>
      {pickerAssetBlock && (
        <AssetBlockPickerModal
          assetBlockProps={{
            assetBlock: pickerAssetBlock,
            setAssetBlock: setPickerAssetBlock,
          }}
          onSave={handleSave}
        />
      )}

      {assetBlock.asset && (
        <List>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.previewGridContainer}
          >
            <ListItem>
              <AssetBlockPreviewItem
                assetBlock={assetBlock}
                setAssetBlock={(assetBlock: AssetBlock | undefined) => {
                  onPickAssetBlock(assetBlock);
                }}
              />
            </ListItem>
          </Grid>
        </List>
      )}

      {!assetBlock.asset && (
        <Box display="flex" className={classes.chooseAssetContainer}>
          <div onClick={addAssetBlock} className={classes.chooseAssetWrapper}>
            <Add className={classes.addIcon} />
            <Typography className={classes.addButtonText}>
              Choose {assetBlock.assetType.capitalize()}
            </Typography>
          </div>
        </Box>
      )}

      {assetBlock.asset && (
        <FormControl className={classes.formContainer}>
          <InputLabel className={classes.inputLabel}>
            Choose {assetBlock.assetType.capitalize()} Frame
          </InputLabel>
          <Select
            className={classes.selectMenu}
            onChange={handleFrameChange}
            value={assetBlock.frame}
            defaultValue={AssetFrame.none}
          >
            <MenuItem value={AssetFrame.none}>None</MenuItem>
            <MenuItem value={AssetFrame.MacBook}>MacBook</MenuItem>
            <MenuItem value={AssetFrame.iPhonePortrait}>
              iPhone Portrait
            </MenuItem>
            <MenuItem value={AssetFrame.iPhoneLandscape}>
              iPhone Landscape
            </MenuItem>
            <MenuItem value={AssetFrame.iPadPortrait}>iPad Portrait</MenuItem>
            <MenuItem value={AssetFrame.iPadLandscape}>iPad Landscape</MenuItem>
          </Select>
        </FormControl>
      )}
    </div>
  );
}

export default AssetPreviewContent;
