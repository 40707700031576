import React, { useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { isWidthUp, makeStyles, withWidth } from "@material-ui/core";
import { ContentNode } from "../ContentProvider";
import { useBehaviors } from "../Behaviors";
import NodeContentView from "./NodeContentView";
import TableOfContentsMenu from "../TableOfContents/TableOfContentsMenu";
import LearnHeader from "./LearnHeader";
import CardSection from "../Cards/CardSection";
import { useAnalyticsContext } from "../../../providers/AnalyticsProvider";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { usePublicationsAppContext } from "../../../providers/PublicationsAppProvider";

type StyleProps = {
  isMobile: Boolean;
};
const useStyles = makeStyles(() => ({
  root: {
    padding: "40px 0",
    background: "#000000",
    display: "grid",
    gap: "35px",
  },
  TOCRelativeContainer: {
    margin: ({ isMobile }: StyleProps) => (isMobile ? -14 : 0),
  },
  TOCContainer: {
    zIndex: 2,
  },
}));

interface Props {
  width: Breakpoint;
}

const NodeView = ({ width }: Props) => {
  const { trackContentNodeViewed } = useAnalyticsContext();
  const { contentNode } = usePublicationsAppContext();

  const isMobile = !isWidthUp("sm", width);
  const classes = useStyles({ isMobile });

  const { slug } = useParams();
  const { pathname } = useLocation();
  let currentNode = contentNode?.findByPath(slug || "");

  if (!currentNode) {
    return <Navigate to={"/apps/publications/"} />;
  }

  // need a new instance of Behaviors in any view that needs access to behaviors
  const behaviors = useBehaviors(currentNode);
  // can we get rid of this dependency??
  useEffect(() => {
    window.scroll(0, 0);
    if (currentNode) {
      trackContentNodeViewed(currentNode);
    }
  }, [pathname, currentNode]);
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <div id={"rootDiv"} className={classes.root}>
      <LearnHeader to={`/${ContentNode.pathPrefix!}`} />

      {contentNode && (
        // new toc
        <div className={`${classes.TOCRelativeContainer}`}>
          <div className={classes.TOCContainer}>
            <TableOfContentsMenu contentNode={contentNode} />
          </div>
        </div>
      )}

      {behaviors.showCards() && <CardSection contentNode={currentNode} />}
      {/* Only show content view and links if this is a page */}
      {behaviors.showContent() && currentNode && (
        <NodeContentView contentNode={currentNode} />
      )}
    </div>
  );
};
export default withWidth()(NodeView);
