import React, { useEffect, useMemo, useState } from "react";
import styles from "./MultiInput.module.scss";
import { BlockParam } from "../../../../../asset-generator-lib";
import TemplatePageFormRenderer from "../../TemplatePageFormRenderer/TemplatePageFormRenderer";

import clone from "just-clone";
import { MultiDate, MultiTitle } from "../MultiComponents/index";
import { UnknownInput } from "../../../../promo-pack-builder/PromoPackCreator/FormInputs";
import { GenericObj } from "../helpers";
import {
  BlockParamMulti,
  MultiBlockParamPreData,
  TemplatePageBlockParam,
} from "../../../../../asset-generator-lib/composer/models/Block";
import { useValidationsContext } from "../../../../providers/ValidationsProvider";
import { TemplatePageDraft } from "../../../types/TemplatePages";
import { isJSON } from "../../../../shared/helpers/helpers";
import { DraggableProps } from "react-beautiful-dnd";
import AddChangeButton from "../AddChangeButton/AddChangeButton";
import MultiRelease from "../MultiComponents/MultiRelease/MultiRelease";
import isEqual from "lodash.isequal";

type Props = {
  param: BlockParamMulti;
  update: (input: TemplatePageBlockParam | string) => void;
  subField?: boolean;
  sectionKey: string;
  templatePageDraft: TemplatePageDraft;
  path: string[];
};

type GetMultiComponentProps = {
  multiChildType: BlockParamMulti["multiChildType"];
  sectionKey: string;
  param: BlockParamMulti | BlockParam<any>;
  setParam: React.Dispatch<React.SetStateAction<GenericObj<any>[]>>;
  editFn?: (key: string) => void;
  deleteFn?: (key: string) => void;
  selectedChildValue: any;
};

export type MultiContainerProps = {
  sectionKey: string;
  param: BlockParam;
  selectedChildValue?: any;
  setParam: React.Dispatch<React.SetStateAction<BlockParam>>;
  editFn: (key: string) => void;
  deleteFn: (key: string) => void;
  index: number;
  dragProps?: DraggableProps;
};

export type MultiChildProps = {
  childParam: MultiBlockParamPreData;
  childValue: any;
  selectedChildValue?: any;
  setParam: React.Dispatch<React.SetStateAction<BlockParam>>;
  editFn: (key: string) => void;
  deleteFn: (key: string) => void;
  index: number;
  dragProps?: DraggableProps;
};

// haven't found a typing that works for this yet
// ({}: any) => JSX.Element is close, but doesn't allow the index prop below
const componentList: {
  [key in BlockParamMulti["multiChildType"]]?: any;
} = {
  TITLE: MultiTitle,
  TOURDATE: MultiDate,
  RELEASE: MultiRelease,
};

export const getMultiComponent = ({
  multiChildType,
  param,
  setParam,
  editFn,
  deleteFn,
  sectionKey,
  selectedChildValue,
}: GetMultiComponentProps) => {
  const TagName = componentList[multiChildType] || UnknownInput;

  return (
    <TagName
      sectionKey={sectionKey}
      param={param} // this is all of the values
      selectedChildValue={selectedChildValue} // this is the selected child value
      setParam={setParam}
      deleteFn={deleteFn}
      editFn={editFn}
    />
  );
};

const MultiInput = ({
  param,
  update,
  subField,
  sectionKey,
  templatePageDraft,
  path,
}: Props) => {
  const { validateAtKey, validations, validationsDispatch } =
    useValidationsContext();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [value, setValue] = useState<GenericObj<any>[]>(param.value || []); //param.value || (param.options && param.options[0])
  const [childValue, setChildValue] = useState<GenericObj<any> | null>(null);

  // Does the child input currently have the param from our value array?  -1 means no, all else are good
  const currentValueIndex = value.findIndex(
    (item) => item.key === childValue?.key
  );

  useEffect(() => {
    if (validations) {
      let validation =
        validations[sectionKey] && validations[sectionKey][param.key];

      if (validation) {
        setErrorMessage(validation);
      } else {
        setErrorMessage("");
      }
    }
  }, [validations]);

  useEffect(() => {
    if (param.value && !isEqual(param.value, value)) {
      setValue(param.value);
    }
  }, [param.value]);

  useEffect(() => {
    // if we have a value, update the template config
    if (value) update(valueAdd(value));
  }, [value]);

  const validateBeforePush = async (childValue: GenericObj<any>) => {
    const obj: any = {};

    // most multi inputs will have a single child, that is a group input.
    // but if its not, then we are using just the first input after, and handle a little differently
    // so we make a backup key here, and use it if the childValue.value isn't JSON
    // (group inputs stringify their value)
    const backupKey: string = (param?.options && param.options[0].key) || "";
    const clonedValue = clone(
      isJSON(childValue?.value) || { [backupKey]: childValue }
    );

    // gather params with validations
    Object.values(clonedValue)
      .filter((item: any) => item.validations)
      .forEach((childParam: any) => {
        // if the option is a group, we can use the childParam.key,
        // otherwise lets use that backup key
        const key =
          param.options && param.options[0].type === "GROUP"
            ? childParam.key
            : backupKey;
        const val = childParam.value;
        obj[key] = val;
      });
    // check only these params that need it
    const valid = await validateAtKey(obj, sectionKey, Object.keys(obj));

    if (valid) {
      pushChildToValue(childValue);
    }
  };

  const pushChildToValue = (childValue: GenericObj<any>) => {
    const update: GenericObj<any>[] = clone(value);

    if (currentValueIndex != -1) {
      const recordIndex = update.findIndex(
        (item) => item.key === childValue.key
      );
      update[recordIndex] = childValue;
    } else {
      // else just add it to the arrray
      update.push(childValue);
    }

    setValue(update);
    setChildValue(null);
  };
  pushChildToValue;
  // we only know the first layer of the shape of the data, that it's got a key value pair, and the key is a string
  const valueAdd = (value: GenericObj<any>) => {
    var p = { ...param, value: value };
    return p;
  };

  const childUpdate = (subValue: GenericObj<any>) => {
    if (subValue) {
      setChildValue(subValue);
    }
  };

  const deleteItem = (key: string) => {
    // don't want to mutate value hook by accident
    const updateValue = clone(value);

    //find the index of the item
    const index = updateValue.findIndex(
      (item: GenericObj<any>) => item.key === key
    );

    // if we found the correct index, delete it
    if (index != -1) {
      updateValue.splice(index, 1);
      setValue(updateValue);
    } else {
      console.error("BAD INDEX");
    }
  };

  const editItem = async (itemKey: string) => {
    // find the item in the value array
    let removedValidation = { ...validations };
    delete removedValidation[sectionKey];
    validationsDispatch({
      type: "REMOVE_VALIDATION",
      payload: removedValidation,
    });
    const record = value.find((item) => item.key === itemKey);

    //set the childValue
    record && setChildValue(clone(record));
  };

  const backupBlockParam: BlockParam = {
    key: "missing",
    name: "missing",
    type: "STATIC",
  };

  // get the first option from the Multi block, that's what you'll be making childInputs of
  const childParam: BlockParam = useMemo(
    () => (param.options && param.options[0]) || backupBlockParam,
    [param]
  );

  const childInput = useMemo(() => {
    // we are generating the key for the child value, which means it IS stable once we create and save it.
    const newKey = new Date().getTime().toString();
    const childKey = childParam.options ? null : childParam.key;

    return (
      <TemplatePageFormRenderer
        path={path}
        blockParams={[
          {
            ...clone(childParam),
            key: childValue?.key || newKey,
            value: childValue?.value,
          },
        ]}
        update={childUpdate}
        disabled={false}
        templatePageDraft={templatePageDraft}
        sectionKey={sectionKey}
        subField={true}
        parentError={errorMessage}
        childKey={childKey}
      />
    );
  }, [value, childValue, errorMessage]);

  const handleCancel = () => {
    // TODO: move this logic into the reducer.
    let removedValidation = { ...validations };
    delete removedValidation[sectionKey];
    validationsDispatch({
      type: "REMOVE_VALIDATION",
      payload: removedValidation,
    });
    setChildValue(null);
  };

  return (
    <>
      <div
        className={styles["multi-input-container"]}
        style={{
          marginBottom: value.length > 0 ? 0 : "8px", //when there are no results, we need a little margin at the bottom
        }}
      >
        {!subField && <div className={"label label--big"}>{param?.name}</div>}
        {<div className={styles["children"]}>{childInput}</div>}
        <div className={styles["button-container"]}>
          <AddChangeButton
            onClick={() => childValue && validateBeforePush(childValue)}
            paramName={param.options && param.options[0]?.name}
            state={currentValueIndex != -1 ? "CHANGE" : "ADD"}
            buttonTextStates={param.buttonTextStates}
            disabled={!childValue?.value}
          />
          {currentValueIndex != -1 && (
            <button
              className={`btn btn--outline ${styles["add-button"]}`}
              disabled={!childValue}
              onClick={handleCancel}
            >
              cancel
            </button>
          )}
        </div>
      </div>
      {/* might want to abstract this piece */}
      {value.length > 0 && (
        <div className={styles["multi-values-container"]}>
          {getMultiComponent({
            multiChildType: param.multiChildType,
            sectionKey,
            param: param,
            setParam: setValue,
            deleteFn: deleteItem,
            editFn: editItem,
            selectedChildValue: childValue,
          })}
        </div>
      )}
    </>
  );
};

export default MultiInput;
