import React, { useState } from "react";
import AssetBlock, {
  AssetCollectionBlockProps,
  emptyAssetBlock,
} from "./AssetBlock";
import AssetBlockPreviewItem from "./AssetBlockPreviewItem";
import AssetBlockPickerModal from "./AssetBlockPickerModal";
import Add from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  makeStyles,
  Theme,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  useTheme,
} from "@material-ui/core";
import "../../ModalCms.tsx/modalStyles.scss";

const useStyles = makeStyles((theme: Theme) => ({
  addButtonText: {
    marginTop: "2.5%",
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: "SF Pro Text",
    letterSpacing: "1.25px",
  },
  thumbnailImage: {
    height: 100,
    widht: 100,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  cancelIcon: {
    display: "flex",
    verticalAlign: "middle",
  },
  listContainer: {
    maxHeight: "65vh",
    overflowY: "auto",
    paddingTop: 0,
    paddingBottom: 0,
    "&::-webkit-scrollbar": {
      width: "0.25em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#e4cfd380",
      borderRadius: "10px",
    },
  },
}));

function AssetCollectionContent({
  assetCollectionBlock,
  setAssetCollectionBlock,
}: AssetCollectionBlockProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [pickerAssetBlock, setPickerAssetBlock] = useState<AssetBlock>();

  function addAssetBlock() {
    setPickerAssetBlock(emptyAssetBlock(assetCollectionBlock.assetType));
  }

  function removeAssetInputField(index: number) {
    let b = { ...assetCollectionBlock };
    b.assetBlocks?.splice(index, 1);
    setAssetCollectionBlock(b);
  }

  function onPickAssetBlock(
    assetBlock: AssetBlock | undefined,
    index: number = -1
  ) {
    let assetBlocks = assetCollectionBlock.assetBlocks;
    if (assetBlocks && assetBlock) {
      if (index >= 0 && assetBlock) {
        assetBlocks[index] = assetBlock;
      } else {
        assetBlocks.push(assetBlock);
      }
    } else if (assetBlock) {
      assetBlocks = [assetBlock];
    }

    assetCollectionBlock.assetBlocks = assetBlocks;

    setAssetCollectionBlock(assetCollectionBlock);
    setPickerAssetBlock(undefined);
  }

  function handleSave() {
    onPickAssetBlock(pickerAssetBlock);
  }

  return (
    <div>
      {pickerAssetBlock && (
        <AssetBlockPickerModal
          assetBlockProps={{
            assetBlock: pickerAssetBlock,
            setAssetBlock: setPickerAssetBlock,
          }}
          onSave={handleSave}
        />
      )}

      {assetCollectionBlock.assetBlocks && (
        <List className={classes.listContainer}>
          {assetCollectionBlock.assetBlocks?.map(
            (assetBlock: AssetBlock, index: any) => {
              return (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.paper}
                >
                  <ListItem>
                    <AssetBlockPreviewItem
                      assetBlock={assetBlock}
                      setAssetBlock={(assetBlock: AssetBlock | undefined) => {
                        onPickAssetBlock(assetBlock, index);
                      }}
                    />

                    <Grid className={classes.cancelIcon} item>
                      <CancelIcon
                        onClick={() => removeAssetInputField(index)}
                      />
                    </Grid>
                  </ListItem>
                </Grid>
              );
            }
          )}
        </List>
      )}

      <Box display="flex" style={{ marginLeft: "25px", marginTop: "5px" }}>
        <div
          onClick={addAssetBlock}
          style={{ display: "flex", cursor: "pointer" }}
        >
          <Add
            style={{ marginTop: "1px", color: theme.palette.primary.main }}
          />
          <Typography className={classes.addButtonText}>
            ADD VIDEO TO SET
          </Typography>
        </div>
      </Box>
    </div>
  );
}

export default AssetCollectionContent;
