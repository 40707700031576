import { ResponseError } from "./API";
import ResourceSchema from "./ResourceSchema";
import RockDove from "./RockDove";

function repackageJson(json: any, rootPath: string) {
  if (json instanceof Array) {
    let repackagedData = {
      [rootPath]: json,
    };
    return repackagedData;
  } else {
    let repackagedData = {
      [rootPath]: json.content ? json.content : json,
      pagination: {
        currentPage: json.number,
        perPage: json.size,
        totalPages: json.totalPages,
      },
    };
    return repackagedData;
  }
}

async function validateJson(json: any, resourceSchema: ResourceSchema) {
  if (json["results"]) {
    if (resourceSchema.adaptor) {
      if (json["results"] instanceof Array) {
        return {
          ...json,
          ["results"]: json["results"].map(
            (backendResource) =>
              resourceSchema.adaptor?.toFrontend &&
              resourceSchema.adaptor.toFrontend(backendResource)
          ),
        };
      }
      return {
        ...json,
        ["results"]:
          resourceSchema.adaptor.toFrontend &&
          resourceSchema.adaptor.toFrontend(json["results"]),
      };
    }
    return json;
  } else {
    throw new Error("Json[rootPath] didnt exist");
  }
}

async function fetchAPI(resourceSchema: ResourceSchema) {
  const headers = await RockDove.shared().getHeaders(resourceSchema.role);

  let base = `${window.location.protocol}//${window.location.host}`;
  let urlString = `${resourceSchema.apiBase}/${resourceSchema.apiRootPath}`;
  let url = new URL(urlString, base);

  try {
    const response = await fetch(url.toString(), { headers });
    const json = await response.json();

    if (response.ok && json) {
      const updatedJson = await repackageJson(json, "results");
      const validatedResponse = await validateJson(updatedJson, resourceSchema);
      return validatedResponse;
    } else {
      throw new ResponseError(json, response);
    }
  } catch (e: any) {
    throw new Error(e);
  }
}

async function fetchPaginated(resourceSchema: ResourceSchema, page: number) {
  const headers = await RockDove.shared().getHeaders(resourceSchema.role);

  let base = `${window.location.protocol}//${window.location.host}`;
  let urlString = `${resourceSchema.apiBase}/${resourceSchema.apiRootPath}`;
  let url = new URL(urlString, base);

  url.searchParams.append("page", page.toString());

  try {
    const response = await fetch(url.toString(), { headers });
    const json = await response.json();

    if (response.ok && json) {
      const updatedJson = await repackageJson(json, "results");
      const validatedResponse = await validateJson(updatedJson, resourceSchema);
      return validatedResponse;
    } else {
      throw new ResponseError(json, response);
    }
  } catch (e: any) {
    throw new Error(e);
  }
}

const ProxyAPI = {
  fetch: fetchAPI,
  fetchPaginated: fetchPaginated,
};

export default ProxyAPI;
