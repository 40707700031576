import React from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import TextInput from "../TextInput/TextInput";

type PasswordProps = {
  param: BlockParam;
  update: (value: any) => void; // really this should be an input of just string
  subField?: boolean;
  sectionKey: string;
  resolvedValue: any;
};

const PasswordInput = ({
  param,
  update,
  subField,
  sectionKey,
  resolvedValue,
}: PasswordProps) => {
  return (
    <TextInput
      param={param}
      update={update}
      subField={subField}
      sectionKey={sectionKey}
      password
      resolvedValue={resolvedValue}
    />
  );
};

export default PasswordInput;
