import { Sizes } from "../../../asset-generator-lib/composer/models/Block";

type BreakpointSchema = {
  name: keyof Sizes<number>;
  minWidth: number;
};

// lifted these from MUI
// we may want to alter lg/md so the form switching from desktop to mobile is at the same px
// right now they are off a bit
const breakpoints: BreakpointSchema[] = [
  { name: "xs", minWidth: 0 },
  { name: "sm", minWidth: 600 },
  { name: "md", minWidth: 900 },
  { name: "lg", minWidth: 1200 },
  { name: "xl", minWidth: 1536 },
];

export const getBreakpoint = (width: number): keyof Sizes<number> => {
  if (width > breakpoints[breakpoints.length - 1]?.minWidth) {
    return breakpoints[breakpoints.length - 1]?.name;
  } else {
    for (let i = 0; i < breakpoints.length; i++) {
      if (width < breakpoints[i + 1]?.minWidth) {
        return breakpoints[i]?.name;
      }
    }
  }
  // fallback
  return breakpoints[breakpoints.length - 1].name;
};

export const walkDownBreakpoints = (
  currentBreakpointIndex: number,
  layoutSizes: Sizes<number>,
  breakpoints: string[]
): keyof Sizes<number> | undefined => {
  // if array is empty (shouldnt be, return undefined)
  if (Object.keys(layoutSizes).length === 0) {
    return undefined;
  }

  // let's start at the current index of our breakpoints array, and work our way down in index
  for (let i = currentBreakpointIndex; i >= 0; i--) {
    if (Object.keys(layoutSizes).includes(breakpoints[i])) {
      // if the current key in breakpoints array is available from the template designer, return it
      return breakpoints[i] as keyof Sizes<number>;
    }
  }
  // this shouldnt ever hit, but it keeps TS happy
  return undefined;
};

export const getClosestBreakpoint = (
  currentBreakpoint: keyof Sizes<any>,
  sizes: Sizes<any> | undefined
): keyof Sizes<number> | undefined => {
  const breakpoints = ["xs", "sm", "md", "lg", "xl"];

  /*
    The template designer may assign values to only some of the breakpoints available,
    therefore we need to first see if they provided a size at our current breakpoint
    and if not, we need to walk down in keys until we find a value
  */

  // if the template designer did not provide a size
  if (!sizes) return undefined;

  // if the designer provided the current breakpoint in their sizes key
  if (sizes && sizes[currentBreakpoint]) {
    return sizes[currentBreakpoint];
  } else {
    // we don't have a provided breakpoint size for this
    // go down a size until you find one
    const currentBreakpointIndex: number =
      breakpoints.indexOf(currentBreakpoint);

    return walkDownBreakpoints(currentBreakpointIndex, sizes, breakpoints);
  }
};
