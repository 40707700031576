import React, { useEffect, useState } from "react";
import { makeStyles, Select, MenuItem } from "@material-ui/core";
import { BlockParam } from "../../../../asset-generator-lib/composer/models/Block";
import { getInputComponent } from "../CustomizeForm";

type Props = {
  param: BlockParam;
  update: (input: BlockParam | string) => void;
  subField?: boolean;
};

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: "Futura",
    fontSize: "12px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#ffffff",
    textTransform: "uppercase",
    marginBottom: "12px",
  },
}));

const SelectInput = ({ param, update, subField }: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState<string>(
    JSON.stringify(param.value) ||
      JSON.stringify(param.options && param.options[0])
  );

  useEffect(() => {
    if (value != "") {
      const parsedValue = JSON.parse(value) ? JSON.parse(value) : {};
      update(valueAdd(parsedValue));
    }
  }, [value]);

  const valueAdd = (value: BlockParam) => {
    var p = { ...param, value: value };
    return p;
  };

  const childUpdate = (subValue: string) => {
    if (value != "") {
      setValue(JSON.stringify(subValue));
    }
  };
  /*
    here we are altering value to match the original color value in the options,
    so the select can actually match up our current selection with the option.
    if the values are different (changing a color), and we don't do this, then
    it shows a blank selection because the strings don't match
  */

  // using the bang on 59 because we KNOW we have options, otherwise we wouldnt be here.  ANd we know we will be successful with the find.
  const alteredValue = JSON.parse(value).value
    ? JSON.stringify({
        ...JSON.parse(value),
        value: param.options?.find(
          (opt) => opt.name === JSON.parse(value).name
        )!.value,
      })
    : value;

  return (
    <>
      {!subField && <div className={classes.label}>{param?.name}</div>}
      <Select
        labelId={param?.key}
        value={alteredValue}
        onChange={(e) => setValue(e.target.value as string)}
        fullWidth
      >
        {param.options &&
          param.options.map((item: BlockParam) => {
            return (
              <MenuItem key={item.name} value={JSON.stringify(item)}>
                {item.name}
              </MenuItem>
            );
          })}
      </Select>
      {
        <div style={{ marginTop: "16px" }}>
          {getInputComponent(JSON.parse(value), childUpdate, true)}
        </div>
      }
    </>
  );
};

export default SelectInput;
