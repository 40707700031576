import React from 'react';
import {
  Box,
  Grid,
  ButtonGroup,
  Button,
  makeStyles
} from '@material-ui/core';
import IphoneButton from '../../platoon-cms-lib/components/Buttons.tsx/IphoneButton';
import DesktopDisplayButton from '../../platoon-cms-lib/components/Buttons.tsx/DesktopDisplayButton';

interface Props {
  handleDisplayClick: any;
  displayMode: string;
}

const useStyles = makeStyles(() => ({
  iconButton: {
    border: 'none',
    padding: '10px 12px',
    backgroundColor: 'transparent',
    transition: 'backgroundColor 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  iconButtonLeft: {
    borderRadius: '22px 0 0 22px',
  },
  iconButtonRight: {
    borderRadius: ' 0 22px 22px 0',
  },
}));

export default function PreviewMode(props: Props) {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Box pb={2} pt={2}>
        <ButtonGroup>
          <Button
            className={`${classes.iconButton} ${classes.iconButtonLeft}`}
            onClick={() => props.handleDisplayClick("Desktop")}
          >
            <DesktopDisplayButton displayMode={props.displayMode} />
          </Button>
          <Button
            className={`${classes.iconButton} ${classes.iconButtonRight}`}
            onClick={() => props.handleDisplayClick("Mobile")}
          >
            <IphoneButton displayMode={props.displayMode} />
          </Button>
        </ButtonGroup>
      </Box>
    </Grid>
  );
}

