import { Block, Template, TemplateVariant } from "../../../asset-generator-lib";
import { ShapeType } from "../../../asset-generator-lib/composer/models/Block";

const backgroundBlock: Block = {
  id: "eclipse_BGB",
  key: "eclipse_BGB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 1920, h: 1920 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "background",
      name: "Background Design",
      type: "SELECT",
      options: [
        {
          key: "artworkBlurred",
          name: "Artwork Blurred",
          type: "MAPPED",
          mappedPath: "release.artworkUrl",
          style: { filter: { blur: 60 } },
        },
        {
          key: "customBackgroundColor",
          name: "Custom Background Color",
          type: "COLOR",
          value: "#FFFFFF",
        },
      ],
    },
  ],
};

const albumArtworkBlock: Block = {
  id: "eclipse_AAB",
  key: "eclipse_AAB",
  type: "SPRITE",
  frame: {
    origin: { x: 40, y: 55 },
    size: { w: 700, h: 700 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "image",
      name: "Album Artwork",
      type: "MAPPED",
      mappedPath: "release.artworkUrl",
    },
  ],
};

const artworkCircle: Block = {
  id: "eclipse_AC",
  key: "eclipse_AC",
  type: "SHAPE",
  shapeType: ShapeType.circle,
  params: [
    {
      key: "image",
      name: "Album Artwork Circle",
      type: "MAPPED",
      mappedPath: "release.artworkUrl",
    },
    {
      key: "texture",
      name: "Album Artwork Circle Texture",
      type: "STATIC",
      value: "/images/asset-generator/eclipse/CrossHatch.png",
    },
  ],
  frame: {
    origin: { x: 55, y: 40 },
    size: { w: 0, h: 0, radius: 400 },
    anchor: { x: 0.5, y: 0.5 },
  },
  style: {
    border: { borderSize: 0, borderColor: "000000" },
    filter: { blur: 20 },
  },
};

const rectangle: Block = {
  id: "eclipse_R",
  key: "eclipse_R",
  type: "SHAPE",
  shapeType: ShapeType.rect,
  params: [
    { key: "shape", name: "Rectangle", type: "STATIC", value: "000000" },
  ],
  frame: { origin: { x: 7.5, y: 7.5 }, size: { h: 25, w: 700 } },
  style: {
    border: { borderSize: 0, borderColor: "000000" },
    background: { color: "#000000" },
  },
};

const text1: Block = {
  id: "eclipse_T1",
  key: "eclipse_T1",
  type: "TEXT",
  params: [
    { key: "textColor", name: "Text Color", type: "COLOR", value: "000000" },
    {
      key: "text",
      name: "Text Block 1",
      type: "MAPPED",
      defaultValue: "Release Name ",
      mappedPath: "release.name",
    },
  ],
  frame: {
    origin: { x: 7.5, y: 10 },
    anchor: { x: 0, y: 0 },
    size: { w: 700, h: 0 },
  },
  style: {
    rotation: { angle: 0 },
    border: { borderSize: 0, borderColor: "000000" },
    font: {
      fontColor: "#000000",
      fontSize: "65px",
      fontFamily: "Futura",
      fontWeight: "900",
    },
  },
};

const text2: Block = {
  id: "eclipse_T2",
  key: "eclipse_T2",
  type: "TEXT",
  params: [
    { key: "text", name: "Text Block 2", type: "STATIC", value: "OUT ON" },
  ],
  frame: { origin: { x: 50, y: 77.5 }, anchor: { x: 0, y: 0 } },
  style: {
    rotation: { angle: 0 },
    border: { borderSize: 0, borderColor: "000000" },
    font: {
      fontColor: "#000000",
      fontSize: "40px",
      fontFamily: "Helvetica",
      fontWeight: "600",
    },
  },
};

const text3: Block = {
  id: "eclipse_T3",
  key: "eclipse_T3",
  type: "TEXT",
  params: [
    {
      key: "text",
      name: "Text Block 3",
      type: "MAPPED",
      mappedPath: "release.releaseDate",
    },
  ],
  frame: { origin: { x: 65, y: 77.5 }, anchor: { x: 0, y: 0 } },
  style: {
    rotation: { angle: 0 },
    border: { borderSize: 0, borderColor: "000000" },
    font: {
      fontColor: "#000000",
      fontSize: "40px",
      fontFamily: "Helvetica",
      fontWeight: "600",
    },
  },
};

const eclipseFacebook: TemplateVariant = {
  name: "Facebook Cover",
  key: "facebookCover",
  size: { w: 820, h: 360 },
  blockOverrides: [
    {
      key: "eclipse_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 820, h: 820 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "eclipse_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 35, y: 50 },
        size: { w: 200, h: 200 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "eclipse_AC",
      type: "SHAPE",
      shapeType: ShapeType.circle,
      frame: {
        origin: { x: 27.5, y: 40 },
        size: { w: 0, h: 0, radius: 100 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderSize: 1, borderColor: "000000" },
        filter: { blur: 20 },
      },
    },
    {
      key: "eclipse_R",
      type: "SHAPE",
      frame: { origin: { x: 50, y: 25 }, size: { w: 300, h: 10 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "eclipse_T1",
      type: "TEXT",
      frame: {
        origin: { x: 50, y: 38.5 },
        anchor: { x: 0, y: 1 },
        size: { w: 300, h: 0 },
      },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "32px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
      },
    },
    {
      key: "eclipse_T2",
      type: "TEXT",
      frame: { origin: { x: 50, y: 38.5 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "22px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
    {
      key: "eclipse_T3",
      type: "TEXT",
      frame: { origin: { x: 62, y: 38.5 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "22px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
  ],
};

const eclipseSpotify: TemplateVariant = {
  name: "Spotify Header",
  key: "spotifyHeader",
  size: { w: 2660, h: 1140 },
  blockOverrides: [
    {
      key: "eclipse_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2660, h: 2660 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "eclipse_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 37.5, y: 55 },
        size: { w: 575, h: 575 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "eclipse_AC",
      type: "SHAPE",
      shapeType: ShapeType.circle,
      frame: {
        origin: { x: 30, y: 40 },
        size: { w: 0, h: 0, radius: 350 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderSize: 1, borderColor: "000000" },
        filter: { blur: 20 },
      },
    },
    {
      key: "eclipse_R",
      type: "SHAPE",
      frame: { origin: { x: 50, y: 30 }, size: { w: 700, h: 20 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "eclipse_T1",
      type: "TEXT",
      frame: {
        origin: { x: 50, y: 40 },
        anchor: { x: 0, y: 1 },
        size: { w: 700, h: 0 },
      },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "70px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
      },
    },
    {
      key: "eclipse_T2",
      type: "TEXT",
      frame: { origin: { x: 50, y: 40 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "50px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
    {
      key: "eclipse_T3",
      type: "TEXT",
      frame: { origin: { x: 58.25, y: 40 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "50px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
  ],
};

const eclipseTwitter: TemplateVariant = {
  name: "Twitter Header",
  key: "twitterHeader",
  size: { w: 1500, h: 500 },
  blockOverrides: [
    {
      key: "eclipse_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 1500, h: 1500 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "eclipse_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 40, y: 50 },
        size: { w: 260, h: 260 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "eclipse_AC",
      type: "SHAPE",
      shapeType: ShapeType.circle,
      frame: {
        origin: { x: 40, y: 50 },
        size: { w: 0, h: 0, radius: 200 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderSize: 1, borderColor: "000000" },
        filter: { blur: 20 },
      },
    },
    {
      key: "eclipse_R",
      type: "SHAPE",
      frame: { origin: { x: 55, y: 35 }, size: { w: 400, h: 15 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "eclipse_T1",
      type: "TEXT",
      frame: {
        origin: { x: 55, y: 50 },
        anchor: { x: 0, y: 1 },
        size: { w: 400, h: 0 },
      },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "45px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
      },
    },
    {
      key: "eclipse_T2",
      type: "TEXT",
      frame: { origin: { x: 55, y: 50 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "35px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
    {
      key: "eclipse_T3",
      type: "TEXT",
      frame: { origin: { x: 65, y: 50 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "35px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
  ],
};

const eclipseYoutube: TemplateVariant = {
  name: "Youtube Cover",
  key: "youtubeCover",
  size: { w: 2560, h: 1440 },
  blockOverrides: [
    {
      key: "eclipse_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2660, h: 2660 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "eclipse_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 30, y: 50 },
        size: { w: 575, h: 575 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "eclipse_AC",
      type: "SHAPE",
      shapeType: ShapeType.circle,
      frame: {
        origin: { x: 30, y: 50 },
        size: { w: 0, h: 0, radius: 500 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderSize: 1, borderColor: "000000" },
        filter: { blur: 20 },
      },
    },
    {
      key: "eclipse_R",
      type: "SHAPE",
      frame: { origin: { x: 55, y: 40 }, size: { w: 700, h: 35 } },
      style: { border: { borderSize: 0, borderColor: "000000" } },
    },
    {
      key: "eclipse_T1",
      type: "TEXT",
      frame: {
        origin: { x: 55, y: 50 },
        anchor: { x: 0, y: 1 },
        size: { w: 700, h: 0 },
      },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "70px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
      },
    },
    {
      key: "eclipse_T2",
      type: "TEXT",
      frame: { origin: { x: 55, y: 50 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "50px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
    {
      key: "eclipse_T3",
      type: "TEXT",
      frame: { origin: { x: 63.5, y: 50 }, anchor: { x: 0, y: 0 } },
      style: {
        rotation: { angle: 0 },
        border: { borderSize: 0, borderColor: "000000" },
        font: {
          fontColor: "#000000",
          fontSize: "50px",
          fontFamily: "Helvetica",
          fontWeight: "400",
        },
      },
    },
  ],
};

const eclipseInstagram = {
  name: "Instagram Story",
  key: "instagramStory",
  size: { w: 1080, h: 1920 },
} as TemplateVariant;

const Eclipse = {
  id: "eclipse",
  name: "Eclipse",
  blocks: [
    backgroundBlock,
    artworkCircle,
    rectangle,
    albumArtworkBlock,
    text1,
    text2,
    text3,
  ],
  meta: { version: "1.0", basis: { w: 100, h: 100 } },
  variants: [
    eclipseInstagram,
    eclipseFacebook,
    eclipseSpotify,
    eclipseTwitter,
    eclipseYoutube,
  ],
} as Template;

export default Eclipse;
