import React from "react";
import { BackgroundMode } from "../models/ArtistLink";
import { Box, Grid } from "@material-ui/core";
import ArtistLinkInputHeader from "../LinkEditComponents/ArtistLinkInputHeader";
import ArtistLinkRadioInput from "../LinkEditComponents/ArtistLinkRadioInput";
import ArtistLinkColorPicker from "../LinkEditComponents/ArtistLinkColorPicker";
import ArtistLinkFontPicker from "../LinkEditComponents/ArtistLinkFontPicker";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import ArtistFileInput from "./ArtistFileInput/ArtistFileInput";

const PageDesign = () => {
  const { artistLink } = useArtistLinkContext();

  return (
    <>
      <ArtistLinkInputHeader header={"PAGE DESIGN"} variant={"h5"} />
      <ArtistLinkInputHeader header={"BACKGROUND"} variant={"h6"} />

      <Box pl={4} display="flex" flexWrap="wrap">
        {artistLink.albumArtwork && (
          <ArtistLinkRadioInput
            name="backgroundMode"
            checkedValue={artistLink.backgroundMode}
            label={"ARTWORK BLUR"}
            value={BackgroundMode.blur}
          />
        )}
        <ArtistLinkRadioInput
          name="backgroundMode"
          checkedValue={artistLink.backgroundMode}
          label={"COLOR"}
          value={BackgroundMode.color}
        />

        <ArtistLinkRadioInput
          name="backgroundMode"
          checkedValue={artistLink.backgroundMode}
          label={"IMAGE"}
          value={BackgroundMode.image}
        />
      </Box>

      {artistLink.backgroundMode === BackgroundMode.color && (
        <>
          <ArtistLinkInputHeader header={"BACKGROUND COLOR"} variant={"h6"} />

          <ArtistLinkColorPicker
            artistLinkKey={artistLink.backgroundColor}
            value={"backgroundColor"}
            defaultValue={"#000000"}
          />
        </>
      )}
      {artistLink.backgroundMode === BackgroundMode.image && (
        <Grid item xs={12}>
          <Box p={1} pl={3} pr={3} style={{ color: "white" }}>
            <ArtistFileInput
              artistLinkKey="backgroundImage"
              artistLinkKeyId="backgroundImageID"
              accepts="image/png, image/jpeg"
            />
          </Box>
        </Grid>
      )}

      <ArtistLinkInputHeader header={"AUDIO PLAYER COLOR"} variant={"h6"} />

      <ArtistLinkColorPicker
        artistLinkKey={artistLink.playerColor}
        value={"playerColor"}
        defaultValue={"#FFFFFF"}
      />

      <ArtistLinkInputHeader header={"AUDIO PLAYER FONT"} variant={"h6"} />

      <ArtistLinkFontPicker artistLinkKey={"playerFont"} />
    </>
  );
};

export default PageDesign;
