import React from "react";
import { Box } from "@material-ui/core";
import ArtistLinkInputHeader from "../LinkEditComponents/ArtistLinkInputHeader";
import ArtistLinkRadioInput from "../LinkEditComponents/ArtistLinkRadioInput";
import {
  ArtistAudioDownloadButton,
  DownloadButtonPosition,
} from "../models/ArtistLink";
import AudioDownloadPassword from "./ArtistSecurity/AudioDownloadPassword";
import ArtistLinkColorPicker from "../LinkEditComponents/ArtistLinkColorPicker";
import ArtistLinkAutoComplete from "../LinkEditComponents/ArtistLinkAutoComplete";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
interface Props {
  onChange: any;
  setForceOpenCollapse: any;
}

const ArtistAudioDownload = (props: Props) => {
  const { artistLink } = useArtistLinkContext();
  const downLoadButtonOptions = Object.values(ArtistAudioDownloadButton);

  return (
    <Box pb={4}>
      <ArtistLinkInputHeader header={"TRACK DOWNLOADS"} variant={"h6"} />

      <Box pl={4} display="flex">
        <ArtistLinkRadioInput
          checkedValue={artistLink.requiresAudioDownloadPassword}
          name={"requiresAudioDownloadPassword"}
          label={"ON"}
          value={true}
        />
        <ArtistLinkRadioInput
          checkedValue={artistLink.requiresAudioDownloadPassword}
          name={"requiresAudioDownloadPassword"}
          label={"OFF"}
          value={false}
        />
      </Box>

      {artistLink.requiresAudioDownloadPassword && (
        <>
          <ArtistLinkInputHeader header={"BUTTON DESIGN"} variant={"h6"} />

          <ArtistLinkAutoComplete
            artistLinkKey={"audioDownloadButton"}
            options={downLoadButtonOptions}
            styles={{ font: "SF Pro Display" }}
          />

          {artistLink.headline && (
            <>
              <ArtistLinkInputHeader
                header={"BUTTON PLACEMENT"}
                variant={"h6"}
              />
              <Box pl={4} display="flex">
                <ArtistLinkRadioInput
                  checkedValue={artistLink.audioDownloadButtonPosition}
                  name={"audioDownloadButtonPosition"}
                  label={"RIGHT"}
                  value={DownloadButtonPosition.right}
                />
                <ArtistLinkRadioInput
                  checkedValue={artistLink.audioDownloadButtonPosition}
                  name={"audioDownloadButtonPosition"}
                  label={"LEFT"}
                  value={DownloadButtonPosition.left}
                />
              </Box>
            </>
          )}

          <ArtistLinkInputHeader header={"BUTTON COLOR"} variant={"h6"} />

          <ArtistLinkColorPicker
            artistLinkKey={artistLink.audioDownloadButtonColor}
            value={"audioDownloadButtonColor"}
            defaultValue={"#FFFFFF"}
          />
        </>
      )}

      <AudioDownloadPassword
        setForceOpenCollapse={props.setForceOpenCollapse}
        onChange={props.onChange}
      />
    </Box>
  );
};

export default ArtistAudioDownload;
