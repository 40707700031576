import React, { useEffect, useState } from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import { useDebounce, useValueResolving } from "../../../../shared/CustomHooks";
import styles from "./DateInput.module.scss";

import { useValidationsContext } from "../../../../providers/ValidationsProvider";
import CustomDatePicker from "./CustomDatePicker/CustomDatePicker";

type DateInputProps =
  | {
      param: BlockParam;
      update: (value: any) => void; // really this should be an input of just string
      subField?: boolean;
      sectionKey: string;
      childKey?: string;
      resolvedValue?: any;
      showTime: true;
    }
  | {
      param: BlockParam;
      update: (value: any) => void; // really this should be an input of just string
      subField?: boolean;
      sectionKey: string;
      childKey?: string;
      resolvedValue?: any;
      showTime?: never;
    };

const DateInput = ({
  param,
  update,
  showTime,
  sectionKey,
  childKey,
  resolvedValue,
}: DateInputProps) => {
  const initParamVal = isNaN(Date.parse(param?.value))
    ? null
    : new Date(param?.value);

  const initVal: any = null;
  const [value, setValue] = useValueResolving(
    initParamVal,
    resolvedValue,
    param,
    initVal
  );

  const valueAsDate = value && new Date(value);

  const key = childKey || param.key;
  const [inputChanged, setInputChanged] = useState<boolean>(false);

  const { validations, validateAtKey } = useValidationsContext();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (validations) {
      let validation = sectionKey
        ? validations[sectionKey]?.[key]
        : validations[key];

      if (validation) {
        setErrorMessage(validation);
      } else {
        setErrorMessage("");
      }
    }
  }, [validations]);

  // I've set the debounce down to instant, so we know we have a dirty input immediately after a change up at the templatePage level
  // if this has performance issues down the line (because of a lot of inputs on the form)
  // then we should pass in an isDirty callback
  const debouncedValue: Date = useDebounce(value, 10);

  useEffect(() => {
    if (validations[sectionKey] && validations[sectionKey][key]) {
      //makes sure that we don't read/cure validations until desired
      validateAtKey({ [key]: debouncedValue }, sectionKey, [key]);
    }

    param && update({ ...param, value: debouncedValue });
  }, [debouncedValue, value]);

  const handleOnCalendarClose = () => {
    if (inputChanged) {
      validateAtKey({ [key]: value }, sectionKey, [key]);
    }
  };

  return (
    <div>
      {param?.name && <div className="label">{param?.name}</div>}
      <span className={"sr-only"} id="date-picker-label">
        {param?.name}
      </span>
      <div style={{ position: "relative", width: "100%" }}>
        <input
          type={showTime ? "datetime-local" : "date"}
          className={`${styles["date-native"]} ${styles["input-end-adornment"]}`}
          aria-labelledby="date-picker-label"
          // have to adjust time for the timezone since there is no datetime input, and only a datetime-local
          value={
            valueAsDate
              ? new Date(
                  valueAsDate?.getTime() -
                    valueAsDate?.getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .slice(0, 16)
              : ""
          }
          onChange={(e) => setValue(new Date(e.target.value))}
        />
        <CustomDatePicker
          value={valueAsDate}
          handleOnCalendarClose={handleOnCalendarClose}
          setValue={setValue}
          setInputChanged={setInputChanged}
          param={param}
          showTime={showTime}
        />
      </div>
      <div className={"error flex-end"}>{errorMessage}</div>
    </div>
  );
};

export default DateInput;
