import Asset, { AssetType } from "../../../models/Asset";
interface AssetBlock {
  asset?: Asset;
  assetType: AssetType;
  assetPickerSource: AssetPickerSource; //upload | url | library
  errors?: string[] | null;
  warnings?: string[] | null;
  frame?: AssetFrame;
}

enum AssetFrame {
  none = "None",
  MacBook = "/images/learn/asset-frame/macbook.png",
  iPhonePortrait = "/images/learn/asset-frame/iphonexframe.png",
  iPadPortrait = "/images/learn/asset-frame/ipad-pro-portrait.png",
  iPadLandscape = "/images/learn/asset-frame/ipadprolandscape.png",
  iPhoneLandscape = "/images/learn/asset-frame/iphonexlandscape.png",
}

enum AssetPickerSource {
  url = "url",
  upload = "upload",
  library = "library",
}

function emptyAssetBlock(
  assetType: AssetType,
  assetPickerSource: AssetPickerSource = AssetPickerSource.url
) {
  return {
    assetType: assetType,
    assetPickerSource: assetPickerSource,
    errors: ["Enter a valid URL"],
  };
}

interface AssetCollectionBlock {
  assetBlocks?: AssetBlock[];
  assetType: AssetType;
}

function emptyAssetCollectionBlock(assetType: AssetType) {
  return {
    assetType: assetType,
  };
}

interface AssetBlockProps {
  assetBlock: AssetBlock;
  setAssetBlock: (assetBlock: AssetBlock | undefined) => void;
}

interface AssetCollectionBlockProps {
  assetCollectionBlock: AssetCollectionBlock;
  setAssetCollectionBlock: (assetCollectionBlock: AssetCollectionBlock) => void;
}

export default AssetBlock;
export {
  AssetPickerSource,
  emptyAssetBlock,
  AssetCollectionBlock,
  emptyAssetCollectionBlock,
  AssetBlockProps,
  AssetCollectionBlockProps,
  AssetFrame,
};
