import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import PromoPackIndex from "./PromoPackIndex/PromoPackIndex";
import ArtistLinkTheme from "../link-builder/ArtistLinkTheme";
import SnackProvider from "../shared/SnackProvider";
import ArtistRedirect from "../link-builder/ArtistRedirect";
import PromoPackProvider from "./providers/PromoPackProvider";
import PromoPackCreator from "./PromoPackCreator/PromoPackCreator";
import { AnalyticsProvider } from "../providers/AnalyticsProvider";
import { PlatoonArtistProvider } from "../providers/PlatoonArtistProvider";
import { PlatoonReleaseProvider } from "../providers/PlatoonReleaseProvider";
import { LoadingProvider } from "../providers/LoadingProvider";
import PromoPackRelease from "./PromoPackCreator/PromoPackRelease";
import Template from "./PromoPackCreator/Template";
import Customize from "./PromoPackCreator/Customize";
import Download from "./PromoPackCreator/Download";
import ErrorHandlingProvider from "../providers/ErrorHandlingProvider";
import { AppType } from "../providers/AnalyticsProvider";
import "../platoon-cms-lib/fonts/futura.css";
import "../platoon-cms-lib/fonts/oswald.css";
import "../platoon-cms-lib/fonts/lato.css";
import "../platoon-cms-lib/fonts/luminari.css";
import "../platoon-cms-lib/fonts/andale_mono.css";

const basePath = "/apps/promote/asset-generator";

const PromoPackBuilderApp = () => {
  return (
    <div>
      <ThemeProvider theme={ArtistLinkTheme}>
        <SnackProvider>
          <LoadingProvider>
            <ErrorHandlingProvider redirectPath={basePath}>
              <PlatoonArtistProvider>
                <AnalyticsProvider app={AppType.assetGenerator}>
                  <PlatoonReleaseProvider>
                    <PromoPackProvider>
                      <Routes>
                        <Route
                          index
                          element={
                            <ArtistRedirect
                              basePath={basePath}
                              subPath="packs"
                            />
                          }
                        />
                        <Route
                          path={`artists/:artistId/packs/:packId`}
                          element={<PromoPackCreator />}
                        >
                          <Route
                            path={`release`}
                            element={<PromoPackRelease />}
                          />
                          <Route path={`template`} element={<Template />} />
                          <Route path={`customize`} element={<Customize />} />
                          <Route path={`download`} element={<Download />} />
                        </Route>

                        <Route
                          path={`artists/:artistId/packs`}
                          element={<PromoPackIndex />}
                        />
                      </Routes>
                    </PromoPackProvider>
                  </PlatoonReleaseProvider>
                </AnalyticsProvider>
              </PlatoonArtistProvider>
            </ErrorHandlingProvider>
          </LoadingProvider>
        </SnackProvider>
      </ThemeProvider>
    </div>
  );
};

export default PromoPackBuilderApp;
