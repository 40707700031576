import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  makeStyles,
  Theme,
  ButtonProps,
  TypographyProps,
  withWidth,
  isWidthUp,
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

type StyleProps = {
  position: Props["position"];
  width: Breakpoint;
};
interface Props {
  buttons?: Array<ButtonObj>;
  position: "bottom" | "top";
  width: Breakpoint;
}

type ButtonObj = {
  text: string;
  action?: () => void;
  buttonProps?: ButtonProps;
  textProps?: TypographyProps;
  group: "left" | "right";
};

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: (props: StyleProps) => (props.position === "bottom" ? "auto" : 0),
    bottom: (props: StyleProps) => (props.position === "bottom" ? 0 : "auto"),
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    height: "70px",
    position: "fixed",
    zIndex: 10,
    color: "primary",
  },
  toolBar: {
    height: "100%",
    minHeight: 0,
  },
  buttonsContainer: {
    display: "flex",
    width: "100%",
  },
  rightButtonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "& > button:not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },
  leftButtonsWrapper: {
    justifyContent: "flex-start",
  },
  buttonStyle: {
    minWidth: "100px",
    maxWidth: (props: StyleProps) =>
      isWidthUp("xs", props.width) ? "200px" : "100px",
    padding: (props: StyleProps) =>
      isWidthUp("sm", props.width) ? "8px" : "4px",
  },
  textSize: {
    fontSize: (props: StyleProps) =>
      isWidthUp("sm", props.width) ? "14px" : "10px",
  },
}));

const ActionBar = (props: Props) => {
  const classes = useStyles({ position: props.position, width: props.width });
  const leftButtons = props?.buttons?.filter(
    (button) => button.group === "left"
  );
  const rightButtons = props?.buttons?.filter(
    (button) => button.group === "right"
  );

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.buttonsContainer}>
          {/* left buttons */}
          <div className={classes.leftButtonsWrapper}>
            {leftButtons?.map((button, index) => (
              <Button
                key={index}
                className={classes.buttonStyle}
                onClick={button.action}
                {...button.buttonProps}
              >
                <Typography {...button.textProps} className={classes.textSize}>
                  {button.text}
                </Typography>
              </Button>
            ))}
          </div>

          {/* right buttons */}
          <div className={classes.rightButtonsWrapper}>
            {rightButtons?.map((button, index) => (
              <Button
                key={index}
                className={classes.buttonStyle}
                onClick={button.action}
                {...button.buttonProps}
              >
                <Typography {...button.textProps} className={classes.textSize}>
                  {button.text}
                </Typography>
              </Button>
            ))}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withWidth()(ActionBar);
