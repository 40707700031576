import React from "react"

interface Props {
  color: string
}

const BackwardButton = (props: Props) => {

  const iconColor = props.color ? props.color : '#FFFFFF'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 39 22">
      <g fill="none" fillRule="evenodd">
        <g fill={`${iconColor}`} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path d="M96.855 164.981c1.128 0 2.08-.864 2.08-2.46v-17.037c0-1.596-.952-2.46-2.08-2.46-.57 0-1.054.19-1.64.527l-14.15 8.32c-.777.454-1.216.982-1.392 1.582v-7.969c0-1.596-.938-2.46-2.066-2.46-.586 0-1.069.19-1.64.527l-14.15 8.32c-1.011.586-1.465 1.29-1.465 2.124 0 .85.468 1.553 1.464 2.139l14.15 8.335c.572.322 1.055.512 1.641.512 1.128 0 2.066-.864 2.066-2.46v-7.97c.176.601.615 1.129 1.391 1.583l14.15 8.335c.572.322 1.056.512 1.641.512z" transform="translate(-731 -1022) translate(642 204) translate(-367 -296) translate(396 971)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}

export default BackwardButton
