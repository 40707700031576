import ResourceSchema, {
  emptyResourceSchema,
  UserRole,
} from "../shared/ResourceSchema";

function adaptToBackend(frontendObject: any) {
  let target = Object.assign({}, frontendObject);
  target.id = frontendObject.id;
  delete target.id;
  return target;
}

function adaptToFrontend(backendObject: any) {
  let target = Object.assign({}, backendObject);
  target.id = backendObject.id;

  return target;
}

const AssetSchema: ResourceSchema = {
  ...emptyResourceSchema(),
  ...{
    title: "Asset",
    pluralTitle: "Assets",
    rootPath: "assets",
    apiRootPath: "assets",
    attrs: ["attachment", "description"],
    adaptor: {
      toFrontend: adaptToFrontend,
      toBackend: adaptToBackend,
    },
    locale: "en-US",
    role: UserRole.Admin, //default asset schema manipulation is done by an admin
  },
};

export { adaptToBackend, adaptToFrontend };
export default AssetSchema;
