import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/PageRenders/ScrollToTop";
import LearnIndex from "./components/PageRenders/LearnIndex";
import NodeView from "./components/PageRenders/NodeView";
import SnackProvider from "../shared/SnackProvider";
import { AnalyticsProvider, AppType } from "../providers/AnalyticsProvider";
import { PlatoonArtistProvider } from "../providers/PlatoonArtistProvider";
import { PublicationsAppProvider } from "../providers/PublicationsAppProvider";
import HistoryPoster from "../shared/HistoryPoster";
import Layout from "./Layout";
import ErrorHandlingProvider from "../providers/ErrorHandlingProvider";
import AuthCheck from "../shared/AuthCheck/AuthCheck";

const PublicationsRoutes = () => {
  const basePath = "apps/publications";
  return (
    <AuthCheck>
      <BrowserRouter>
        <HistoryPoster>
          <SnackProvider>
            <ErrorHandlingProvider redirectPath={basePath}>
              <PlatoonArtistProvider>
                <AnalyticsProvider app={AppType.publications}>
                  <PublicationsAppProvider basePath={basePath}>
                    <ScrollToTop />

                    <Routes>
                      <Route path={"/apps/publications"} element={<Layout />}>
                        <Route index element={<LearnIndex />} />

                        {/* for all content nodes */}
                        <Route
                          path={"/apps/publications/:slug"}
                          element={<NodeView />}
                        />
                      </Route>
                    </Routes>
                  </PublicationsAppProvider>
                </AnalyticsProvider>
              </PlatoonArtistProvider>
            </ErrorHandlingProvider>
          </SnackProvider>
        </HistoryPoster>
      </BrowserRouter>
    </AuthCheck>
  );
};

export default PublicationsRoutes;
