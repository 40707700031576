import React from "react";
import "../../platoon-cms-lib/fonts/tokotype.css";
import ArtistLinkAutoComplete from "./ArtistLinkAutoComplete";
import ArtistLink from "../models/ArtistLink";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

interface Props {
  artistLinkKey: keyof ArtistLink;
}

export default function ArtistFontPicker(props: Props) {
  const { artistLink } = useArtistLinkContext();

  const fontList = [
    "Helvetica",
    "Futura",
    "SF Pro Display",
    "Arial",
    "Verdana",
    "Trebuchet MS",
    "Times New Roman",
    "Didot",
    "American Typewriter",
    "Andale Mono",
    "Courier",
    "Bradley Hand",
    "Luminari",
    "Oswald ",
    "Lato ",
  ];

  const copiedFontList = [...fontList];
  const fontListSorted = copiedFontList.sort((a, b) => a.localeCompare(b));

  const fontToDisplay = fontList.find((font: any) => {
    return font.fontFamily === artistLink[props.artistLinkKey];
  });

  return (
    <ArtistLinkAutoComplete
      artistLinkKey={props.artistLinkKey}
      options={fontListSorted}
      styles={{ font: fontToDisplay }}
      valueIsFont={true}
    />
  );
}
