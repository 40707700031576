import React from "react";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "../linkviewer/ErrorPage";
import ErrorHandlingProvider from "../providers/ErrorHandlingProvider";
import HistoryPoster from "../shared/HistoryPoster";
import ArtistPageLoader from "./ArtistPageLoader/ArtistPageLoader";

const basePath = "artist-pages";
const queryClient = new QueryClient();

const ArtistPagesApp = () => {
  return (
    <ErrorHandlingProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Helmet>
          <HistoryPoster>
            <Routes>
              <Route path={basePath}>
                <Route
                  path={`:templateSlug/:templatePageSlug`} //probably needs a slug instead
                  element={<ArtistPageLoader />}
                />
                <Route path={`*`} element={<ErrorPage />} />
              </Route>
            </Routes>
          </HistoryPoster>
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorHandlingProvider>
  );
};

export default ArtistPagesApp;
