import React from "react";
import { makeStyles } from "@material-ui/core";
import { ContentNode } from "../ContentProvider";
import CardsRenderer from "./CardsRenderer";
import NodeActionButtons from "../NodeActionButtons";
import { Link } from "react-router-dom";

type Props = {
  contentNode: ContentNode;
  noButtons?: true;
};

const useStyles = makeStyles(() => ({
  sectionRoot: {
    padding: "0 0 45px 0",
    "&:not(:first-of-type)": {
      borderTop: "1px solid rgba(256,256,256,.3)",
      paddingTop: "45px",
    },
  },
  sectionHeader: {
    marginBottom: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  sectionTitle: {
    fontFamily: "Futura",
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "22px",
    color: "#ffffff",
    margin: "0 0 8px 0",
    textTransform: "uppercase",
    textDecoration: "none",
  },
  sectionSubtitle: {
    marginTop: "8px",
    fontFamily: "Helvetica",
    fontSize: "15px",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "27px",
    letterSpacing: 0,
    color: "#ffffff",
    margin: 0,
  },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", // if it drops below 300px width, it will snap to 1 column
    gridAutoRows: "min-content",
    gap: "16px",
  },
}));

const CardSection = ({ contentNode, noButtons }: Props) => {
  const classes = useStyles();

  return (
    <section key={contentNode.id} className={classes.sectionRoot}>
      <header className={classes.sectionHeader}>
        <div>
          {contentNode.id === "bookmarks" ? (
            <h1 className={classes.sectionTitle}>{contentNode.title}</h1>
          ) : (
            <Link to={contentNode.path()} className={classes.sectionTitle}>
              {contentNode.title}
            </Link>
          )}
          <h2 className={classes.sectionSubtitle}>{contentNode.description}</h2>
        </div>
        {!noButtons && <NodeActionButtons contentNode={contentNode} />}
      </header>
      <main className={classes.cardContainer}>
        <CardsRenderer contentNode={contentNode as ContentNode} />
      </main>
    </section>
  );
};

export default CardSection;
