import { ApiBase } from "./API";
import { SortOption } from "./types/SharedTypes";

interface KeyMap {
  backend: string;
  frontend: string;
}

export { KeyMap };

function defaultActions() {
  return { index: true, create: false, delete: false, edit: false };
}

// The frontend needs to decide what (if any) authentication to send to the backend based on their expected role
enum UserRole {
  Artist = "artist", // Artist users have access to only their API resources
  Admin = "admin", // Admin users have access to all arist and public resources
  Public = "public", // Public users can see public pages (like artist-links), but nothing more
}

interface ResourceActions {
  index: boolean;
  create: boolean;
  delete: boolean;
  edit: boolean;
}
export { ResourceActions, defaultActions };

interface ResourceSchema {
  title: string;
  pluralTitle: string;
  rootPath: string;
  apiRootPath: string;
  attrs: string[];
  adaptor?: ResourceAdaptor;
  keyMap?: KeyMap[];
  paths: any;
  locale?: string;
  query?: string;
  dataIsNested: boolean;
  page?: number;
  role: UserRole;
  apiBase?: ApiBase;
  sort?: SortOption;
}

function emptyResourceSchema() {
  return {
    title: "",
    pluralTitle: "",
    rootPath: "",
    apiRootPath: "",
    attrs: [""],
    paths: {},
    dataIsNested: false,
    role: UserRole.Public,
    apiBase: "/api/v1",
  } as ResourceSchema;
}

interface ResourceAdaptor {
  toFrontend?: (backendObject: any) => any;
  toBackend?: (frontendObject: any) => any;
}

function defaultResourceSchema(
  apiRootPath: string,
  role = UserRole.Public,
  adaptor?: ResourceAdaptor
) {
  var s = emptyResourceSchema();
  s.role = role;
  s.apiRootPath = apiRootPath;
  s.rootPath = apiRootPath;
  s.adaptor = adaptor;
  // s.apiBase ?
  return s as ResourceSchema;
}

export { emptyResourceSchema, UserRole, defaultResourceSchema };

export default ResourceSchema;
