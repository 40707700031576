import { isWidthUp, makeStyles, withWidth } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useCrumbs } from "../../../shared/CustomHooks";
import { ContentNode } from "../ContentProvider";
import TOCButtons from "./TOCButtons";
import TOCAccordion from "./TOCAccordion";
import TOCTabs from "./TOCTabs";

type TOCDropDownProps = {
  contentNode: ContentNode;
  width: Breakpoint;
};

type StyleProps = {
  isMobile: Boolean;
};

const useTOCDropDownStyles = makeStyles(() => ({
  container: {
    fontFamily: "Helvetica",
    fontSize: "15px",
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "47px",
    letterSpacing: 0,
    color: "#ffffff",

    //absolute stuff
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: "black",
    borderLeft: "1px solid #1d1d1d",
    borderRight: "1px solid #1d1d1d",
    borderBottom: "1px solid #1d1d1d",
    margin: "0 -1px ",
  },
  clickableItem: {
    cursor: "pointer",
    padding: ({ isMobile }: StyleProps) =>
      isMobile ? "0 14px 0 35px" : "0 14px",
  },
  item: {
    borderRadius: "4px",
    transition: "100ms",
    marginTop: "4px",
    "&:hover": {
      backgroundColor: "#1d1d1d",
    },
  },
  selected: {
    borderRadius: "4px",
    transition: "100ms",
    marginTop: "4px",
    backgroundColor: "#151515",
  },
  tocButtonContainer: {
    padding: "0 20px",
  },
}));

const TOCDropDown = ({ contentNode, width }: TOCDropDownProps) => {
  const isMobile = !isWidthUp("sm", width);
  const classes = useTOCDropDownStyles({ isMobile });
  const { slug } = useParams();
  const currentNode = contentNode.findByPath(slug || "");
  const currentNodeTopParentBelowRoot = currentNode?.getTopParentBelowRoot();

  const crumbs = useCrumbs(contentNode);

  const [selected, setSelected] = useState<string>(
    (crumbs && crumbs[1]?.slug) || ""
  );

  const generateTabs = (contentNode: ContentNode) =>
    contentNode.children?.map((child) => (
      <div
        key={`${child.id}-drop-down-item`}
        className={`${
          selected === child.slug ? classes.selected : classes.item
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          onClick={() => setSelected(child.slug)}
          className={classes.clickableItem}
        >
          {child.title} {child.subtitle}
        </div>
        {selected === child.slug && (
          <>
            {child.children.some(
              (grandChild) => grandChild.type === "section"
            ) ? (
              isMobile ? (
                <TOCAccordion contentNode={child} />
              ) : (
                <TOCTabs contentNode={child} crumbs={crumbs} />
              )
            ) : (
              <div className={classes.tocButtonContainer}>
                <TOCButtons contentNode={child} indent={1} />
              </div>
            )}
          </>
        )}
      </div>
    ));

  // unfortunately the design we have will not allow us to
  // programatically pick the right kind of buttons/tabbing based on any other logic
  // we should add a key in the ContentNode that signafies what type of TOC they want... I guess

  // this is gross, but we don't have a better option currently

  const items =
    currentNodeTopParentBelowRoot?.slug === "release-playbook" ? (
      generateTabs(currentNodeTopParentBelowRoot)
    ) : (
      <div style={{ padding: isMobile ? "0 14px" : 0 }}>
        <TOCButtons contentNode={currentNodeTopParentBelowRoot} indent={0} />
      </div>
    );

  return <div className={classes.container}>{items}</div>;
};

export default withWidth()(TOCDropDown);
