import React, { useRef } from "react";
import "../../platoon-cms-lib/fonts/tokotype.css";
import {
  Grid,
  makeStyles,
  ListItem,
  TextField,
  Theme,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import ArtistLink from "../models/ArtistLink";

//these are typed as any for now because of an issue with using the
//artistLinkKey as the key for selecting fields to update in the ArtistLink
//EX: artistlink[artisLinkValue] is broken if type with ArtistLink and keyof ArtistLink
//we found this caused issues because a key of Artist Link could be an enum or string extension and not ALWAYS a string
//experiments can be found here: https://codesandbox.io/s/keysmatching-bl1m0?file=/src/App.tsx

interface Props {
  artistLinkKey: any;
  options: string[];
  styles?: any;
  valueIsFont?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  autoComplete: {
    "& .MuiInputBase-inputTypeSearch": {
      WebkitAppearance: "none",
    },
    "& .MuiFilledInput": {
      paddingTop: "0px",
    },
  },
  autoCompleteOption: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  autoCompleteWrapper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  autoCompleteItem: {
    color: "white",
  },
}));

const ArtistLinkAutoComplete = (props: Props) => {
  const { artistLink, dispatch } = useArtistLinkContext();

  const textField = useRef<HTMLElement>(null);
  const textFieldRef = textField.current;

  const classes = useStyles();

  const onChange = (_: unknown, value: string) => {
    const updatedArtistLink = { ...artistLink } as any;
    updatedArtistLink[props.artistLinkKey] = value;
    dispatch({
      type: "SET_ARTISTLINK",
      payload: updatedArtistLink,
    });
  };

  function textFieldBlur() {
    let timeout = setTimeout(() => {
      textFieldRef?.blur();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }

  const value = props.artistLinkKey as keyof ArtistLink;

  return (
    <Grid item xs={12}>
      <div className={classes.autoCompleteWrapper}>
        <Autocomplete
          blurOnSelect={true}
          selectOnFocus={false}
          disableClearable
          onClose={() => textFieldBlur()}
          className={classes.autoComplete}
          value={artistLink[value] as any}
          popupIcon={<ArrowDropDownCircleOutlinedIcon />}
          onChange={onChange}
          options={props.options.map((option: string) => option)}
          renderOption={(option) => {
            const optionFont = props.valueIsFont ? option : props.styles.font;
            return (
              <ListItem component="div" className={classes.autoCompleteOption}>
                <div
                  style={{ fontFamily: optionFont }}
                  className={classes.autoCompleteItem}
                >
                  {option}
                </div>
              </ListItem>
            );
          }}
          renderInput={(params: any) => {
            return (
              <TextField
                {...params}
                margin="normal"
                variant="filled"
                autoFocus={false}
                inputRef={textField}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  style: {
                    fontFamily: props.valueIsFont
                      ? params.inputProps.value
                      : props.styles.font,
                    paddingTop: 0,
                    paddingLeft: 6,
                  },
                }}
              />
            );
          }}
        />
      </div>
    </Grid>
  );
};

export default ArtistLinkAutoComplete;
