import React from 'react';

const EditButton = () => {
  return (
    <svg width="30px" height="30px" viewBox="0 0 14 13" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <g id="WYSIWYG-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Hover-Edit-Menu" transform="translate(-105.000000, -56.000000)" fill="#888888" fillRule="nonzero">
          <g id="Group-2" transform="translate(94.000000, 44.000000)">
            <path d="M23.1690937,14.7890625 L23.9581562,14.015625 C24.3565937,13.6171875 24.3722187,13.1875 24.0206562,12.828125 L23.7550312,12.5625 C23.4034687,12.2109375 22.9737812,12.25 22.5753437,12.6328125 L21.7862812,13.4140625 L23.1690937,14.7890625 Z M12.1534687,24.671875 L13.9581562,23.9765625 L22.4815937,15.4609375 L21.1144062,14.0859375 L12.5831562,22.609375 L11.8409687,24.34375 C11.7706562,24.53125 11.9659687,24.75 12.1534687,24.671875 Z" id="edit"></path>
          </g>
        </g>
      </g>
    </svg>

  );
}



export default EditButton;
