import React, { useEffect, useRef } from "react";
import ReactAudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import PlayButton from "../../../platoon-cms-lib/components/Buttons.tsx/PlayButton";
import PauseButton from "../../../platoon-cms-lib/components/Buttons.tsx/PauseButton";
import ForwardButton from "../../../platoon-cms-lib/components/Buttons.tsx/ForwardButton";
import BackwardButton from "../../../platoon-cms-lib/components/Buttons.tsx/BackwardButton";
import "react-h5-audio-player/lib/styles.css";
import "react-h5-audio-player/src/styles.scss";
import "./AudioPlayer.css";
import { Track } from "../../../link-builder/models/ArtistLink";
import { documentQueryFromIframeIfPresent } from "../../helpers/helpers";

type Props = {
  files: Track[];
  handleClickPrevious: () => void;
  handleClickNext: () => void;
  currentFileIndex: number;
  setCurrentFileIndex: React.Dispatch<React.SetStateAction<number>>;
  playStatus: boolean;
  setPlayStatus: React.Dispatch<React.SetStateAction<boolean>>;
  playerColor: string;
};

const AudioPlayer = ({
  files,
  currentFileIndex,
  playStatus,
  setPlayStatus,
  setCurrentFileIndex,
  handleClickNext,
  handleClickPrevious,
  playerColor,
}: Props) => {
  const audioPlayer = useRef<any>(null);
  const audioPlayerRef = audioPlayer.current;

  // react h5 audio players inner classes may be inside of our frame-size-wrapper
  const progressFilled: any = documentQueryFromIframeIfPresent(
    ".rhap_progress-filled"
  );
  const progressIndicator: any = documentQueryFromIframeIfPresent(
    ".rhap_progress-indicator"
  );
  const audioCurrentTime: any =
    documentQueryFromIframeIfPresent(".rhap_current-time");

  const audioTotalTime: any =
    documentQueryFromIframeIfPresent(".rhap_total-time");

  useEffect(() => {
    //modifying player styles if user changes playerColor
    if (
      progressFilled &&
      progressIndicator &&
      audioCurrentTime &&
      audioTotalTime
    ) {
      progressFilled.style.backgroundColor = playerColor
        ? `${playerColor}99`
        : "#FFFFFF99";
      progressIndicator.style.backgroundColor = playerColor
        ? playerColor
        : "#FFFFFF";
      audioCurrentTime.style.color = playerColor ? playerColor : "#FFFFFF";
      audioTotalTime.style.color = playerColor ? playerColor : "#FFFFFF";
    }
  }, [
    playerColor,
    progressFilled,
    progressIndicator,
    audioCurrentTime,
    audioTotalTime,
  ]);

  const currentTrack = files && files[currentFileIndex];

  const currentTrackSrc =
    currentTrack?.derivatives?.mp3Kbps192 ||
    currentTrack?.derivatives?.original ||
    currentTrack?.attachment;
  // console.log("currentTrackSrc: ", currentTrackSrc);

  useEffect(() => {
    if (audioPlayerRef) {
      if (playStatus) {
        audioPlayerRef.audio.current.play();
      } else {
        audioPlayerRef.audio.current.pause();
      }
    }
  }, [playStatus, currentFileIndex]);

  useEffect(() => {
    if (currentFileIndex === -1 && files) {
      setPlayStatus(false);
      setCurrentFileIndex(0);
    }
  }, [files?.length]);

  return (
    <div>
      <ReactAudioPlayer
        defaultCurrentTime="00:00"
        defaultDuration="00:00"
        preload="metadata"
        ref={audioPlayer}
        onPlay={() => setPlayStatus(true)}
        onPause={() => setPlayStatus(false)}
        onEnded={handleClickNext}
        showSkipControls={true}
        showJumpControls={false}
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
        src={currentTrackSrc}
        className="audio-player"
        showDownloadProgress={false}
        customIcons={{
          play: <PlayButton color={playerColor} />,
          pause: <PauseButton color={playerColor} />,
          next: <ForwardButton color={playerColor} />,
          previous: <BackwardButton color={playerColor} />,
        }}
        customAdditionalControls={[RHAP_UI.CURRENT_TIME]}
        customVolumeControls={[RHAP_UI.DURATION]}
        customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
        customControlsSection={[
          RHAP_UI.ADDITIONAL_CONTROLS,
          RHAP_UI.MAIN_CONTROLS,
          RHAP_UI.VOLUME_CONTROLS,
        ]}
      />
    </div>
  );
};
export default AudioPlayer;
