// TODO: we should either use this everywhere, or delete.

const breakpoints = {
  mobileTiny: 450,
  mobileCondensed: 500,
  bottomNav: 750,
  topNav: 1069,
};

const platoon_pink = {
  backgroundColor: "black",
  surfaceBackgroundColor: { default: "#35363a", hover: "#444649" },
  strongTextColor: "white",
  textColor: "#a5a5a5",
  highlightColor: "#dc536a",
  fontFamily: "SF Pro Text",
  strongFontFamily: "SF Pro Display",
  breakpoints: breakpoints,
};

const current = platoon_pink;

export default current;
