import React, { useEffect, useState } from "react";
import { ConvertedBlock } from "../../../../asset-generator-lib/composer/models/Block";

import { TemplatePageConfig } from "../../helpers/templateConfigHelpers";
import styles from "./TemplatePageFormSection.module.scss";
import { ReactComponent as LineHorizontal3Light } from "../../../shared/icons/three-lines-custom.svg";
import { ReactComponent as ChevronDownBold } from "../../../shared/icons/chevron-down-bold.svg";
import { useTemplatePageEditorContext } from "../TemplatePageEditor/TemplatePageEditor";
import { DraggableProps } from "react-beautiful-dnd";
import { useValidationsContext } from "../../../providers/ValidationsProvider";
import { TemplatePage, TemplatePageDraft } from "../../types/TemplatePages";
import clone from "just-clone";
import isEqual from "lodash.isequal";
import { useDebounce } from "../../../shared/CustomHooks";
import { Action } from "../../helpers/templatePageReducer";
import { ReactComponent as EyeFill } from "../../../shared/icons/eye-fill.svg";
import { ReactComponent as EyeSlash } from "../../../shared/icons/eye-slash.svg";
import TemplatePageFormRenderer from "../TemplatePageFormRenderer/TemplatePageFormRenderer";

type Props = {
  clonedSection: ConvertedBlock;
  updateTemplateConfigSection: (input: TemplatePageConfig) => void;
  disabled: boolean;
  dragProps?: DraggableProps;
  sectionDragging?: string | null;
  dispatchTemplatePage: React.Dispatch<Action>;
  templatePageDraft: TemplatePageDraft;
};

//component
const TemplatePageFormSection = ({
  clonedSection,
  updateTemplateConfigSection,
  disabled,
  dragProps,
  sectionDragging,
  dispatchTemplatePage,
  templatePageDraft,
}: Props) => {
  const { setOpenInvalidSections, openInvalidSections, validations } =
    useValidationsContext();
  const [open, setOpen] = useState<boolean>(clonedSection.startOpen || false);

  //the useEffect will fire prematurely without knowing if the user has actually clicked
  //the visibility icon button. don't love it, but it functions great. open
  const [hideSection, setHideSection] = useState<boolean | undefined>(
    clonedSection.hidden
  );

  const { highlightedKey, setHighlightedKey } = useTemplatePageEditorContext();

  const debouncedConfigParams = useDebounce(clonedSection.params, 100);

  const paramsChanged = !isEqual(clonedSection.params, debouncedConfigParams);

  useEffect(() => {
    if (open && paramsChanged) {
      setHideSection(false);
    }
  }, [open, paramsChanged]);

  useEffect(() => {
    if (clonedSection["hidden"] !== undefined) {
      const config = templatePageDraft.templateConfig;
      const foundSection = config.findIndex(
        (item) => item.key === clonedSection.key
      )!;
      if (config[foundSection].hidden !== hideSection) {
        const clonedTemplateConfig = clone(templatePageDraft.templateConfig);
        clonedTemplateConfig[foundSection].hidden = hideSection;
        dispatchTemplatePage({
          type: "UPDATE_DRAFT", // used to be UPDATE_TEMPLATECONFIG
          payload: {
            ...templatePageDraft,
            templateConfig: clonedTemplateConfig,
          } as TemplatePage,
        });
      }
    }
  }, [clonedSection, hideSection]);

  useEffect(() => {
    if (
      openInvalidSections &&
      validations[clonedSection.key] &&
      clonedSection.required
    ) {
      setOpen(true);
      setOpenInvalidSections(false);
    }
  }, [validations, openInvalidSections, clonedSection]);

  useEffect(() => {
    if (sectionDragging === clonedSection.key) {
      setOpen(false);
    }
  }, [sectionDragging]);

  useEffect(() => {
    if (!open) {
      // If the section is closing and it's highlighted, kill all highlights
      // If highlightedKey is not defined or null, (as is on first load, lset's make sure to set it to "")
      // This replaces the need for onFocus/onBlur
      if (!highlightedKey || clonedSection.key === highlightedKey) {
        setHighlightedKey("");
      }
      // Otherwise this section is just closing and not really changing highlighted behavior
    } else {
      // If it is, highlight the section. This should have the side affect of un-highlighting any other sections
      setHighlightedKey(clonedSection.key);
    }
  }, [open]);

  const visibleSection = clonedSection.hidden
    ? "hidden-color"
    : "visible-color";

  // we don't know what kind of data we are getting, so using ANY as long as the parent update func is also any
  const wrapUpdateWithSectionKey = (updatedData: any) => {
    const key = clonedSection?.key!;
    updateTemplateConfigSection({
      [key]: updatedData,
    });
  };

  return (
    <section
      id={`${clonedSection.key}-form_section`}
      className={styles.container}
    >
      <header className={styles["top-section"]}>
        {!clonedSection.required && dragProps && (
          <button
            {...dragProps}
            // dragProps isn't being overridden for touch action in css file
            // needs to be inline for override.. quite weird..
            style={{ touchAction: "none" }}
            className={`${styles["drag-handle"]} btn--naked`}
          >
            <LineHorizontal3Light className={styles["visible-color"]} />
          </button>
        )}
        <div className={styles["top-section-wrapper"]} style={{ flex: 1 }}>
          <button className="btn--naked" onClick={() => setOpen(!open)}>
            <h1 className={styles[`${visibleSection}`]}>
              {clonedSection.title}
            </h1>
            <ChevronDownBold
              className={`${styles["icon"]} ${styles[visibleSection]} ${
                !open && styles["rotated"]
              }`}
            />
          </button>
        </div>
        {/* if the template config section doesn't contain the key of hidden, we shouldn't render the button/icon to
        toggle visibility */}
        <div className={styles["icon-wrapper"]}>
          {clonedSection["hidden"] !== undefined && (
            <button
              onClick={() => {
                setHideSection(!hideSection);
              }}
              className={"btn--naked pointer-cursor"}
            >
              {clonedSection.hidden ? (
                <EyeSlash
                  className={`${styles.icon} ${styles["hidden-color"]}`}
                />
              ) : (
                <EyeFill className={styles.icon} />
              )}
            </button>
          )}
        </div>
      </header>
      {sectionDragging !== clonedSection.key && (
        <div
          onFocus={() => setHighlightedKey(clonedSection.key)}
          tabIndex={1}
          className={styles["expand-container"]}
        >
          <div className={`${styles["content"]} ${open && styles["expanded"]}`}>
            <TemplatePageFormRenderer
              templateConfig={clonedSection.params}
              update={wrapUpdateWithSectionKey}
              disabled={disabled}
              templatePageDraft={templatePageDraft}
              sectionKey={clonedSection.key}
              path={[clonedSection.key]}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default TemplatePageFormSection;
