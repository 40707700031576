import { TextStyleFontWeight } from "@pixi/text";
import { TemplatePageConfig } from "../../../bundles/template-page-builder/helpers/templateConfigHelpers";
import KeyedObject, { objectForKey } from "./KeyedObject";

type BlockParam<T = any> = BlockParamMappedPath<T> | BlockParamSectionPath<T>;

type BlockParamMappedPath<T> = BlockParamPreData<T> & {
  mappedPath?: string;
  sectionPath?: never;
  sectionData?: never;
};

type BlockParamSectionPath<T> = BlockParamPreData<T> & {
  mappedPath?: never;
  sectionPath?: string;
  sectionData?: string;
};
type BlockParamPreData<T = any> =
  | MultiBlockParamPreData<T>
  | BlockParamPreDataBulk<T>;

interface BlockParamPreDataBulk<T = any> extends KeyedObject {
  type:
    | "MAPPED"
    | "STATIC"
    | "LINK" // dont need once we have validaiton, can use textfield then
    | "TEXT"
    | "TEXTAREA"
    | "COLOR"
    | "FILE"
    | "MULTIFILE"
    | "TRACKUPLOAD"
    | "IMAGEUPLOAD"
    | "MULTIIMAGEUPLOAD"
    | "MULTITRACKUPLOAD"
    | "MULTI"
    | "MAPPEDMULTI"
    | "SELECT"
    | "MAPPEDSELECT"
    | "RADIO"
    | "RADIONOLABEL"
    | "GROUP"
    | "COLLAPSABLE"
    | "DATE"
    | "DATETIME"
    | "PASSWORD"
    | "DIVIDER"
    | "GRID"
    | "MODAL"
    | "RELEASEPICKER"
    | "MULTIRELEASEPICKER"
    | "FILTEREDRELEASEPICKER";
  name: string; // user-visible name
  multiChildType?: MultiChildType;
  hideLabel?: boolean;
  overrideName?: string;
  filter?: { key: string; value?: string[] };
  options?: BlockParam[];
  mappedOption?: MappedOption;
  mappedOptions?: MappedOptions;
  defaultValue?: any; //provide
  value?: T;
  style?: Style; //hydrated value can by anything, likely a string or number
  validations?: any; // how do we want to do these?
  layout?: Layout;
  hideMultiPills?: boolean;
  buttonTextStates?: [string, string];
}

export interface MultiBlockParamPreData<T = any> extends KeyedObject {
  type: "MODAL";
  name: string; // user-visible name
  descriptionPath: string;
  buttonTextStates?: [string, string];
  hideLabel?: boolean;
  overrideName?: string;
  mappedOption?: never;
  mappedOptions?: never;
  options?: BlockParam[];
  filter?: { key: string; value?: string[] };
  defaultValue?: any; //provide
  value?: T;
  style?: Style; //hydrated value can by anything, likely a string or number
  validations?: any; // how do we want to do these?
  layout?: Layout;
  hideMultiPills?: never;
}

export type Columns = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type Sizes<T> = {
  xs?: T;
  sm?: T;
  md?: T;
  lg?: T;
  xl?: T;
};

export type HV<T> = {
  h?: T;
  v?: T;
};

export type MappedOption = {
  key: string;
  name: string | string[];
  type: string;
  value?: string | null;
  sectionPath?: string;
  sectionData?: string;
  mappedPath?: string;
  validations?: any;
  defaultOption?: MappedOption;
};
export type MappedOptions = MappedOption[];

export type Layout = {
  size?: Sizes<Columns>;
  order?: Sizes<number>;
  gap?: Sizes<HV<string>>;
};

interface BlockParamMulti extends KeyedObject {
  // key is inherited from KeyedObject, the renderer uses key to find properties, e.g. 'image', 'color', 'text'
  name: string; // user-visible name
  type: "MULTI";
  buttonTextStates?: [string, string];
  multiChildType: MultiChildType;
  options?: BlockParam[];
  mappedPath?: string; //hint at what value should be
  sectionPath?: string;
  sectionData?: string;
  mappedOptions?: MappedOptions;
  defaultValue?: any; //provide
  value?: any;
  style?: Style; // hydrated value can by anything, likely a string or number
  validations?: any; // how do we want to do these?
  layout?: Layout;
}

type MultiChildType = "TOURDATE" | "TITLE" | "RELEASE";

type TemplatePageBlockParam = BlockParam | BlockParamMulti;

export type ScrollAnimation = "fade-in";

type TemplatePageSection = {
  key: string;
  required?: boolean;
  hidden?: boolean;
  scrollAnimation?: ScrollAnimation;
  title: string;
  params: TemplatePageBlockParam[];
  noHeader?: boolean;
  draggable?: boolean;
  startOpen?: boolean;
};

interface BlockBase extends KeyedObject {
  // key is inherited from KeyedObject,
  id?: string;
  type: "TEXT" | "SPRITE" | "LAYER" | "TEXTBOX" | "SHAPE";
  shapeType?: ShapeType;
  frame?: Frame;
  children?: Block[];
  style?: Style;
  order?: number;
  required?: boolean; //currently unused, do we want to use in future asset editor?
  hidden?: boolean; //used in future template assets
  scrollAnimation?: ScrollAnimation;
  title?: string;
  draggable?: boolean;
  startOpen?: boolean;
  layout?: Layout;
}
interface Block extends BlockBase {
  params?: BlockParam[];
}

interface ConvertedBlock extends BlockBase {
  // should this extend a block base?
  params: TemplatePageConfig;
}

enum ShapeType {
  rect = "rect",
  roundedRect = "roundedRect",
  circle = "circle",
  torus = "torus",
  ellipse = "ellipse",
}

interface Size {
  w: number;
  h: number;
  radius?: number;
  innerRadius?: number;
  outerRadius?: number;
}

interface Point {
  x: number;
  y: number;
}

interface Anchor {
  x: number;
  y: number;
}

interface Frame {
  origin: Point;
  size?: Size;
  anchor?: Anchor;
}

interface View {
  blocks: Block[];
  frame: Frame;
  scale: Point;
}

interface Background {
  color: string;
}

interface Style {
  fixedWidth?: boolean;
  innerRadius?: number;
  outerRadius?: number;
  radius?: number;
  padding?: Padding;
  color?: string;
  rotation?: Rotation;
  font?: Font;
  filter?: Filter;
  anchor?: number;
  border?: Border;
  wordWrap?: Wordwrap;
  alpha?: number;
  background?: Background;
}

interface Wordwrap {
  wrap: boolean;
  wrapWidth: number;
}

interface Border {
  borderSize: any;
  borderColor: string;
}

interface Filter {
  blur: number;
  quality?: number;
  resolution?: number;
}

interface Rotation {
  angle: number;
}

interface Padding {
  padding: number;
}

interface Font {
  fontSize: string;
  fontWeight: TextStyleFontWeight;
  fontFamily?: string;
  fontColor: string;
}

const resetTextScale = async (setTextScale: any) => {
  setTextScale(1);
};

const findTextScale = (node: any, block: Block) => {
  if (node?.width && block.frame?.size && node?.width > block?.frame?.size?.w) {
    let scaleFactor = block.frame.size?.w / node?.width;
    return scaleFactor;
  } else return 1;
};

const convertPoint = (point: Point, toView: View) => {
  return { x: point.x * toView.scale.x, y: point.y * toView.scale.y };
};

const revertPoint = (point: Point, toView: View) => {
  return { x: point.x / toView.scale.x, y: point.y / toView.scale.y };
};

const convertSize = (size: Size, toView: View) => {
  return { w: size.w * toView.scale.x, h: size.h * toView.scale.y };
};

const convertFrame = (frame: Frame, view: View) => {
  return {
    origin: frame?.origin && view && convertPoint(frame?.origin, view),
    size: frame?.size && view && convertSize(frame?.size, view),
  };
};

const paramForKey = (key: string, params?: BlockParam[]) => {
  const o = objectForKey(key, params);
  if (o) {
    return o;
  }
  const valuedParams = params?.filter(
    (param) => param.type === "SELECT" && param.value
  );
  const valuedParam = valuedParams?.[0];
  return valuedParam && valuedParam.value.key === key && valuedParam.value;
};

const paramValueForKey = (key: string, params?: BlockParam[]) => {
  const p = paramForKey(key, params);
  if (p) {
    return p.value?.value || p.value;
  } else return null;
};

const paramNameForKey = (key: string, params?: BlockParam[]) => {
  const p = paramForKey(key, params);
  if (p) {
    return p.value?.name || p.name;
  } else return null;
};

const paramValuesByKey = (key: string, composition: any) => {
  const blockParams = composition?.map((block: Block) => {
    return block.params;
  });

  const matchedKeys = blockParams
    .flat()
    .filter((param: BlockParam) => param.key === key);

  if (matchedKeys) {
    if (matchedKeys.length > 1) return null;
    else return matchedKeys[0]?.value.value || matchedKeys[0]?.value;
  } else return null;
};

const paramStyleForKey = (key: string, params?: BlockParam[]) => {
  const p = paramForKey(key, params);
  const pOptions = p?.options?.filter((option: BlockParam) => {
    return p.value.name === option.name;
  });
  if (pOptions && pOptions[0]?.style) {
    return pOptions[0].style;
  }
  if (p && !pOptions) return p.value?.style || p.style;
  else return null;
};

const keyForBlockLoader = (
  blockID: string,
  paramKey: string,
  paramName: string
) => {
  return `${blockID}-${paramName}-${paramKey}`;
};

const BlockUtils = {
  resetTextScale,
  findTextScale,
  convertPoint,
  revertPoint,
  convertSize,
  convertFrame,
  paramForKey,
  paramValueForKey,
  paramValuesByKey,
  paramNameForKey,
  paramStyleForKey,
  keyForBlockLoader,
};

export {
  Block,
  BlockParam,
  BlockBase,
  Size,
  Point,
  Frame,
  View,
  BlockUtils,
  ShapeType,
  ConvertedBlock,
  TemplatePageSection,
  TemplatePageBlockParam,
  BlockParamMulti,
};
export default Block;
