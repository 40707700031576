import React from "react";
import TemplatePageEditorForm from "../TemplatePageEditorForm/TemplatePageEditorForm";
import TemplatePagePreviewFrame from "../TemplatePagePreviewFrame/TemplatePagePreviewFrame";
import TemplatePageRenderer from "../TemplatePageRenderer/TemplatePageRenderer";
import AnimatedViewSwitcher from "../../../shared/components/AnimatedViewSwitcher/AnimatedViewSwitcher";
import { Action } from "../../helpers/templatePageReducer";
import { Artist, TemplatePageDraft } from "../../types/TemplatePages";

type TemplatePageCardFlipEditorProps = {
  showPreview: boolean;
  templatePageDraft: TemplatePageDraft;
  dispatchTemplatePage: React.Dispatch<Action>;
  assembleArtist: Artist;
};

const AnimateTemplatePageEditor = ({
  showPreview,
  templatePageDraft,
  assembleArtist,
  dispatchTemplatePage,
}: TemplatePageCardFlipEditorProps) => {
  const displayView = showPreview ? "preview" : "editor";

  return (
    <AnimatedViewSwitcher
      displayedView={{
        value: displayView,
        animation: showPreview ? "slide-up" : "slide-down",
      }}
    >
      <div id="editor">
        <TemplatePageEditorForm
          templatePageDraft={templatePageDraft}
          dispatchTemplatePage={dispatchTemplatePage}
        />
      </div>
      <div id="preview">
        <TemplatePagePreviewFrame>
          <TemplatePageRenderer
            assembleArtist={assembleArtist}
            templatePage={templatePageDraft}
            editable
          />
        </TemplatePagePreviewFrame>
      </div>
    </AnimatedViewSwitcher>
  );
};

export default AnimateTemplatePageEditor;
