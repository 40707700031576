import React from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import { TemplatePageDraft } from "../../../types/TemplatePages";
import RadioInput from "../RadioInput/RadioInput";

type RadioNoLabelProps = {
  param: BlockParam;
  update: (input: BlockParam | string) => void;
  subField?: boolean;
  sectionKey: string;
  templatePageDraft: TemplatePageDraft;
  path: string[];
};

const RadioNoLabel = ({
  param,
  update,
  subField,
  sectionKey,
  templatePageDraft,
  path,
}: RadioNoLabelProps) => {
  return (
    <RadioInput
      param={param}
      update={update}
      subField={subField}
      sectionKey={sectionKey}
      templatePageDraft={templatePageDraft}
      hideLabel
      path={path}
    />
  );
};

export default RadioNoLabel;
