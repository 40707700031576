import React, { useRef, useState } from "react";
import { useArtistLinkContext } from "../../../providers/ArtistLinkProvider";
import { FileUploadType } from "../../../template-page-builder/components/inputs/FileInput/FileInput";
import DropZone from "../../../template-page-builder/components/inputs/FileUploadComponents/DropZone/DropZone";
import FileUpload from "../../../template-page-builder/components/inputs/FileUploadComponents/FileUpload/FileUpload";
import ArtistLink from "../../models/ArtistLink";
import styles from "./ArtistFileInput.module.scss";

type ArtistFileInputProps = {
  artistLinkKey: keyof ArtistLink;
  artistLinkKeyId: keyof ArtistLink;
  accepts: string;
};

// this is for single files
const ArtistFileInput = ({
  artistLinkKey,
  artistLinkKeyId,
  accepts,
}: ArtistFileInputProps) => {
  const inputRef = useRef(null);

  const { artistLink, dispatch } = useArtistLinkContext();

  const [errorMessage, setErrorMessage] = useState("");

  const [localFile, setLocalFile] = useState(artistLink[artistLinkKey]);

  const handleFiles = (
    files: FileList,
    event: React.ChangeEvent<HTMLInputElement>,
    accepts?: string
  ) => {
    // because this is a single file situation, we need to remove the current one first
    setLocalFile(null);

    const filesToUpload: FileUploadType[] = Object.values(files).map((file) => {
      const fileToAdd: FileUploadType = {
        id: file.name + new Date().toISOString(),
        status: "PENDING",
        selectedFile: file,
        asset: null,
      };

      return fileToAdd;
    });

    const acceptableFileTypes =
      accepts &&
      accepts.split(",").map((fileType: string) => {
        return fileType.trim();
      });

    const fileTypesToCheck = filesToUpload.map((file) => {
      return file.selectedFile.type;
    });

    const validFileTypes = fileTypesToCheck.every((file: string) => {
      return acceptableFileTypes?.includes(`${file}`);
    });

    if (validFileTypes || !accepts) {
      setErrorMessage("");

      addFile(filesToUpload[0]);
    } else {
      setErrorMessage(
        `Invalid file present, must be ${
          acceptableFileTypes && [...acceptableFileTypes]
        }`
      );
    }
    event.target.value = "";
  };

  const reshapeData = (file: FileUploadType) => {
    return {
      attachment: file.asset?.attachment,
      description: file.asset?.description,
      slug: file.asset?.slug,
      thumbnail: file.asset?.thumbnail,
      type: "image",
      id: file.asset?.id || file.id,
    };
  };

  const addFile = (file: FileUploadType) => {
    setLocalFile(file);
  };

  const removeFile = () => {
    setLocalFile(null);
  };

  const updateFileProps = (file: any) => {
    setLocalFile(file);
  };

  React.useEffect(() => {
    if (localFile?.status === "COMPLETED") {
      const updatedArtistLink = {
        ...artistLink,
        [artistLinkKey]: reshapeData(localFile), // i believe this is uneccessary, but sending anyway
        [artistLinkKeyId]: localFile?.asset?.id, // this is a MUST.  when you fetch a stealth player,
        //it grabs this link via another db call and sends the full obj in the other key
      };

      dispatch({
        type: "SET_ARTISTLINK",
        payload: {
          ...updatedArtistLink,
        },
      });
    } else {
      const updatedArtistLink = {
        ...artistLink,
        [artistLinkKey]: localFile,
        [artistLinkKeyId]: localFile?.asset?.id,
      };

      dispatch({
        type: "SET_ARTISTLINK",
        payload: {
          ...updatedArtistLink,
        },
      });
    }
  }, [localFile]);

  return (
    <>
      <DropZone
        dropZoneText="Drag and drop image files here"
        handleFiles={handleFiles}
        inputRef={inputRef}
        accept={accepts}
      />
      <div className={`error ${styles["error-position"]}`}>{errorMessage}</div>
      {localFile && (
        <div className={styles["file-wrapper"]}>
          <FileUpload
            artistId={artistLink.artist?.id}
            file={localFile}
            removeFile={removeFile}
            updateFileProps={updateFileProps}
          />
        </div>
      )}
    </>
  );
};

export default ArtistFileInput;
