import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ArtistLink, {
  artistLinkStateFor,
  privateLinkURL,
} from "../models/ArtistLink";
import MenuOptions from "../../shared/indexCards/MenuOptions";
import LinkState from "./LinkIndexCardSections/LinkState";
import CopyLink from "../../shared/indexCards/CopyLink";
import LinkDisplayDate from "./LinkIndexCardSections/LinkDisplayDate";
import CardImage from "./LinkIndexCardSections/CardImage";
import withWidth, { isWidthDown, isWidthUp } from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import IndexCard from "../../shared/indexCards/IndexCard";
import CounterAnalytics from "../../shared/components/CounterAnalytics/CounterAnalytics";
import { ArtistLinkAction } from "../../providers/AnalyticsProvider";

type StyleProps = {
  width: Breakpoint;
};
interface Props {
  data?: ArtistLink;
  deleteArtistLink?: (artistLink: ArtistLink) => void;
  executeDuplicate?: (artistLink: ArtistLink) => void;
  newlyDupedLink?: any;
  width: Breakpoint;
}

const useStyles = makeStyles(() => ({
  analyticsContainer: {
    display: "flex",
  },
  paddingBuffer: {
    paddingLeft: "12px !important",
    overflow: "hidden",
  },
  textAlignShift: {
    textAlign: (props: StyleProps) =>
      isWidthUp("md", props.width) ? "center" : "start",
  },
  copyLinkContainer: {
    padding: (props: StyleProps) =>
      isWidthDown("md", props.width) ? "4px" : 0,
  },
}));

function LinkIndexCard({
  data: artistLink,
  deleteArtistLink,
  executeDuplicate,
  newlyDupedLink,
  width,
}: Props) {
  const classes = useStyles({ width });

  if (!artistLink || !executeDuplicate || !deleteArtistLink) return <></>;

  const [artistLinkState, setArtistLinkState] = useState(
    artistLinkStateFor(artistLink)
  );

  useEffect(() => {
    // Every second we'll check the state of this artist link (w/o going to the backend)
    // Remember the interval handle so we can cancel it on unmount
    const intervalHandle = setInterval(() => {
      // This should only cause a re-render if the state has changed
      setArtistLinkState(artistLinkStateFor(artistLink));
    }, 1000);

    // Unregister the interval on unmount
    return function cleanup() {
      clearInterval(intervalHandle);
    };
  });

  const artistLinkStateProps = {
    LIVE: {
      color: "#1ec337",
      displayName: artistLink.expires ? "Expires" : "Launched",
      displayDate: artistLink.expires
        ? artistLink.endDateTime
        : artistLink.startDateTime,
    },
    PENDING: {
      color: "#999999",
      displayName: "Launches",
      displayDate: artistLink.startDateTime,
    },
    EXPIRED: {
      color: "#d50442",
      displayName: "Expired",
      displayDate: artistLink.endDateTime,
    },
  };

  return (
    <IndexCard
      to={`${artistLink.id}/edit`}
      newlyDuped={newlyDupedLink}
      slug={artistLink.slug}
    >
      <CardImage artistLink={artistLink} />

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
        className={classes.paddingBuffer}
      >
        <Grid item xs={12} md={5} lg={6}>
          <LinkDisplayDate
            artistLink={artistLink}
            artistLinkStateProps={artistLinkStateProps}
            artistLinkState={artistLinkState}
          />
          {artistLink.id && (
            <div className={classes.analyticsContainer}>
              <CounterAnalytics
                resourceId={artistLink.id}
                resourceType={"ArtistLink"}
                actionType={ArtistLinkAction.mount}
                actionDisplayName="OPENS"
              />
              <CounterAnalytics
                resourceId={artistLink.id}
                resourceType={"ArtistLink"}
                actionType={ArtistLinkAction.songPlayed}
                actionDisplayName="STREAMS"
              />
            </div>
          )}
        </Grid>

        <Grid className={classes.copyLinkContainer} item xs={12} md={4} lg={3}>
          <CopyLink text="copy link" link={privateLinkURL(artistLink)} />
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={6} className={classes.textAlignShift}>
              <LinkState
                artistLink={artistLink}
                artistLinkState={artistLinkState}
                artistLinkStateProps={artistLinkStateProps}
              />
            </Grid>
            <Grid item xs={6}>
              <MenuOptions
                options={[
                  {
                    text: "Duplicate",
                    action: () => executeDuplicate(artistLink),
                  },
                  {
                    text: "Delete",
                    textColor: "#d50442",
                    action: () => deleteArtistLink(artistLink),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </IndexCard>
  );
}

export default withWidth()(LinkIndexCard);
