import React from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { ArtistNameMode } from "../models/ArtistLink";
import ArtistSocialsSection from "./ArtistSocialsSection";
import { Textfit } from "react-textfit";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import { ReactComponent as LogoPlaceholder } from "../../shared/icons/artist-name-placeholder.svg";

interface Props {
  preview: boolean;
  fontColor: string;
  playerIsMobile: boolean;
}

type StyleProps = {
  fontColor: string;
  fontFamily: string | undefined;
  contentHasText: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  nameSectionContainer: {
    height: "100%",
    paddingBottom: theme.spacing(0),
  },
  nameSectionWrapper: {
    height: "100%",
  },
  artistNameImage: {
    maxHeight: "100%",
    height: "100%",
    width: "auto",
    maxWidth: "100%",
    objectFit: "contain",
    objectPosition: "bottom",
  },
  artistNameGrid: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
  },
  artistNameContainer: {
    justifyContent: "center",
    alignItems: "flex-end",
    height: "100%",
    paddingTop: ({ contentHasText }: StyleProps) =>
      contentHasText ? "0" : "3rem",
  },
  artistNameWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: "529px",
    height: "70%",
    width: "100%",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  artistName: {
    width: "100%",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    wordBreak: "break-all",
    color: ({ fontColor }: StyleProps) => fontColor,
    fontFamily: ({ fontFamily }: StyleProps) => fontFamily,
    minHeight: "37px",
  },
  artistSocials: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "30%",
  },
}));

export default function ArtistNameSection(props: Props) {
  const { artistLink } = useArtistLinkContext();

  const fontColor = props.fontColor;
  const fontFamily = artistLink.artistNameFont;
  const contentHasText = (artistLink.headline || artistLink.description) === "";

  const classes = useStyles({ fontColor, fontFamily, contentHasText });

  //this is for react Textfit: this will either let the package takeover font sizes, or default to 32
  const artistNameFontSize = artistLink.artist.name.length > 30 ? 1 : 32;

  return (
    <Grid item xs={12} className={classes.artistNameGrid}>
      <Grid container className={classes.artistNameContainer}>
        <div className={classes.artistNameWrapper}>
          {artistLink.artistNameMode === ArtistNameMode.image ? (
            <>
              {artistLink.artistNameLogo ? (
                <img
                  className={classes.artistNameImage}
                  src={
                    artistLink.artistNameLogo?.asset?.attachment ||
                    // @ts-ignore
                    artistLink.artistNameLogo?.attachment
                  }
                />
              ) : (
                <>{props.preview && <LogoPlaceholder />}</>
              )}
            </>
          ) : (
            <Textfit
              mode="multi"
              min={artistNameFontSize}
              className={classes.artistName}
            >
              {artistLink.artist.name}
            </Textfit>
          )}
        </div>
        <div className={classes.artistSocials}>
          <ArtistSocialsSection
            fontColor={props.fontColor}
            playerIsMobile={props.playerIsMobile}
          />
        </div>
      </Grid>
    </Grid>
  );
}
