import React, { useContext } from 'react';
import clsx from 'clsx';
import { PlatoonContext } from "../PlatoonContext"
interface Props {
  theme: any,
  getEditorState: any,
  setEditorState: any,
  addImage: any,
  modifier: any,
  blockType: string,
  editorState: any,
  onChange: any
}



const InsertButton = (props: Props) => {
  const { setBlockEditSession } = useContext(PlatoonContext);

  const onClick = () => {
    let session = { blockType: "button" };
    setBlockEditSession(session);

  };


  const preventBubblingUp = (event: any) => { event.preventDefault(); }

  const blockTypeIsActive = () => {
    const editorState = props.getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === props.blockType;
  };


  const theme = props.theme;
  const className = blockTypeIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <React.Fragment>
      <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
        <button className={className} onClick={onClick} type="button">
          <svg width="24px" height="24px" viewBox="0 0 23 12" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="WYSIWYG-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Menu-Buttons" transform="translate(-39.000000, -140.000000)" fill="#888888" fillRule="nonzero">
                <g id="btn" transform="translate(39.000000, 140.000000)">
                  <path d="M16.7598457,7.01708987 C17.1508532,7.01708987 17.464844,6.54394533 17.464844,6.00439455 C17.464844,5.44824219 17.1567775,5 16.7598457,5 L5.70499831,5 C5.30806649,5 5,5.46484376 5,6.00439455 C5,6.53564455 5.31399084,7.01708987 5.70499831,7.01708987 L16.7598457,7.01708987 Z" id="b"></path>
                  <path d="M19.8754883,1.33226763e-14 C21.784668,1.33226763e-14 22.8388672,1.04589844 22.8388672,2.94677734 L22.8388672,2.94677734 L22.8388672,8.98242188 C22.8388672,10.875 21.784668,11.9291992 19.8754883,11.9291992 L19.8754883,11.9291992 L2.96337891,11.9291992 C1.05419922,11.9291992 -3.90798505e-14,10.8833008 -3.90798505e-14,8.98242187 L-3.90798505e-14,8.98242187 L-3.90798505e-14,2.94677734 C-3.90798505e-14,1.04589844 1.05419922,1.33226763e-14 2.96337891,1.33226763e-14 L2.96337891,1.33226763e-14 Z M19.6181641,2.22460938 L3.22070312,2.22460938 C2.57324219,2.22460938 2.22460937,2.53173828 2.22460937,3.22900391 L2.22460937,3.22900391 L2.22460937,8.70019531 C2.22460937,9.38916016 2.57324219,9.70458984 3.22070313,9.70458984 L3.22070313,9.70458984 L19.6181641,9.70458984 C20.265625,9.70458984 20.6142578,9.38916016 20.6142578,8.70019531 L20.6142578,8.70019531 L20.6142578,3.22900391 C20.6142578,2.53173828 20.265625,2.22460938 19.6181641,2.22460938 L19.6181641,2.22460938 Z" id="b"></path>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </React.Fragment>

  );
}



export default InsertButton;
