import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import { ReactComponent as AlbumArtPlaceholder } from "../../shared/icons/album-art-placeholder.svg";

const useStyles = makeStyles({
  albumArtworkContainer: {
    height: "100%",
    display: "flex",
  },
  albumArtworkWrapper: {
    alignItems: "center",
    justifyContent: "center",
  },
  albumArtwork: {
    zIndex: 1,
    borderRadius: "8px",
    filter: "drop-shadow(0 5px 15px rgba(0, 0, 0, .2))",
    objectFit: "cover",
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
});

type Props = {
  preview: boolean;
};

export default function ArtistNameSection(props: Props) {
  const { artistLink } = useArtistLinkContext();
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.albumArtworkContainer}>
      <Grid container className={classes.albumArtworkWrapper}>
        {artistLink.albumArtwork ? (
          <img
            className={classes.albumArtwork}
            height="85%"
            src={
              artistLink.albumArtwork.derivatives?.medium ||
              artistLink.albumArtwork.attachment
            }
          />
        ) : (
          <>{props.preview && <AlbumArtPlaceholder />}</>
        )}
      </Grid>
    </Grid>
  );
}
