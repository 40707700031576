import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { PlatoonArtistProvider } from "../providers/PlatoonArtistProvider";
import { ArtistLinkProvider } from "../providers/ArtistLinkProvider";
import { PlatoonReleaseProvider } from "../providers/PlatoonReleaseProvider";
import { AnalyticsProvider } from "../providers/AnalyticsProvider";
import { LoadingProvider } from "../providers/LoadingProvider";
import ErrorHandlingProvider from "../providers/ErrorHandlingProvider";
import SnackProvider from "../shared/SnackProvider";
import ArtistLinkIndex from "../../bundles/link-builder/ArtistLinkIndex";
import ArtistLinkEdit from "./ArtistLinkEdit/ArtistLinkEdit";
import ArtistRedirect from "../../bundles/link-builder/ArtistRedirect";
import ArtistLinkTheme from "./ArtistLinkTheme";
import Layout from "../shared/Layout";
import { AppType } from "../providers/AnalyticsProvider";
import ArtistLinkRelease from "./ArtistLinkRelease";
import "../platoon-cms-lib/fonts/futura.css";
import "../platoon-cms-lib/fonts/oswald.css";
import "../platoon-cms-lib/fonts/lato.css";
import "../platoon-cms-lib/fonts/luminari.css";
import "../platoon-cms-lib/fonts/andale_mono.css";

const basePath = "/apps/promote/stealth-builder";

const LinkBuilderApp = () => {
  return (
    <ThemeProvider theme={ArtistLinkTheme}>
      <SnackProvider>
        <LoadingProvider>
          <ErrorHandlingProvider redirectPath={basePath}>
            <PlatoonArtistProvider>
              <AnalyticsProvider app={AppType.artistLinkBuilder}>
                <PlatoonReleaseProvider>
                  <ArtistLinkProvider>
                    <Routes>
                      <Route
                        index
                        element={
                          <ArtistRedirect basePath={basePath} subPath="links" />
                        }
                      />

                      <Route path="artists/:artistId/links">
                        <Route
                          path={`:artistLinkId/release`}
                          element={
                            <Layout boxPropsOveride={{ pb: 14 }}>
                              <ArtistLinkRelease />
                            </Layout>
                          }
                        />
                        <Route
                          path={`:artistLinkId/edit`}
                          element={<ArtistLinkEdit basePath={basePath} />}
                        />
                        <Route
                          index
                          element={
                            <Layout>
                              <ArtistLinkIndex basePath={basePath} />
                            </Layout>
                          }
                        />
                      </Route>
                    </Routes>
                  </ArtistLinkProvider>
                </PlatoonReleaseProvider>
              </AnalyticsProvider>
            </PlatoonArtistProvider>
          </ErrorHandlingProvider>
        </LoadingProvider>
      </SnackProvider>
    </ThemeProvider>
  );
};

export default LinkBuilderApp;
