import { Box, BoxProps, isWidthUp, withWidth } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  width: Breakpoint;
  boxPropsOveride?: BoxProps;
};

const Layout = ({ children, width, boxPropsOveride }: Props) => {
  const paddingSize = isWidthUp("md", width) ? 10 : 0;
  return (
    <Box
      id="Layout"
      margin="auto"
      pl={paddingSize}
      pr={paddingSize}
      pb={11}
      pt={isWidthUp("sm", width) ? 10 : 4}
      maxWidth={1200}
      style={{
        color: "white",
        overflowX: "hidden",
      }}
      {...boxPropsOveride}
    >
      {children}
    </Box>
  );
};

export default withWidth()(Layout);
