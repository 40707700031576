import React, { useEffect, useState } from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import ReadMoreParagraph from "../../ReadMoreParagraph/ReadMoreParagraph";
import AudioPlayer from "../../../../shared/components/AudioPlayer/AudioPlayer";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import { ReactComponent as ChevronUp } from "../../../../shared/icons/chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../../../shared/icons/chevron-down.svg";
import styles from "./RenderedMusicPlayer.module.scss";
import TrackList from "../../TrackList/TrackList";
import { Artist } from "../../../types/TemplatePages";
import { getTracks } from "../../../queries/useTracks";
import isEqual from "lodash.isequal";
import clone from "just-clone";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
  assembleArtist: Artist;
};

const removeUrlSigsFromTracksData = (tracksData: any) => {
  const clonedTracksData = clone(tracksData);
  const reduced = clonedTracksData?.reduce((collector: any, item: any) => {
    // remove aws signatures from attachment, because they update everytime we fetch a track
    const removedSigs = { ...item };
    if (removedSigs.attachment) {
      let qIndex = removedSigs.attachment.indexOf("?");
      removedSigs.attachment = removedSigs.attachment.substring(0, qIndex);
    }
    if (removedSigs.derivatives) {
      Object.keys(removedSigs.derivatives).forEach((key) => {
        let qIndex = removedSigs.derivatives[key]?.indexOf("?");
        if (qIndex) {
          removedSigs.derivatives[key] = removedSigs.derivatives[key].substring(
            0,
            qIndex
          );
        }
      });
    }

    return [...collector, removedSigs];
  }, []);
  return reduced;
};

const shouldUpdateCachedTracksData = (
  cachedTracksData: any,
  tracksData: any
) => {
  const modifiedCachedTracksData =
    removeUrlSigsFromTracksData(cachedTracksData);
  const modifiedTracksData = removeUrlSigsFromTracksData(tracksData);

  // if these two things aren't equal, we need to update
  return !isEqual(modifiedCachedTracksData, modifiedTracksData);
};

const RenderedMusicPlayer = ({
  convertedBlock,
  highlight,
  assembleArtist,
}: Props) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [trackListOpen, setTrackListOpen] = useState<boolean>(false);
  const [trackListReady, setTrackListReady] = useState<boolean>(false);
  const [playStatus, setPlayStatus] = useState<boolean>(false);
  const [cachedTracksData, setCachedTracksData] = useState<any>([]);

  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  const playerValues = convertedBlock?.params?.modal
    ? convertedBlock?.params?.modal?.value?.page2Grid?.value
    : convertedBlock?.params?.page2Grid?.value;

  // fetching fresh track data
  const tracks = getTracks(
    assembleArtist.id,
    playerValues?.tracks?.value
  )?.filter((track: any) => {
    return track.status === "success";
  });

  const tracksData =
    tracks && tracks?.length !== 0
      ? tracks?.map((track: any) => {
          return track.data;
        })
      : [];

  // if there is new TrackData, we want to store that in state
  // we play audio based off state version
  shouldUpdateCachedTracksData(cachedTracksData, tracksData) &&
    setCachedTracksData(tracksData);

  useEffect(() => {
    // if we have tracks but the current file index doesn't have a track
    // we default back to tracks[0], failsafe
    if (cachedTracksData && cachedTracksData[currentFileIndex]) {
      setCurrentFileIndex(0);
    }
  }, [cachedTracksData?.length]);

  const handleClickPrevious = () => {
    setPlayStatus(true);
    if (cachedTracksData) {
      setCurrentFileIndex((prevFileIndex) => {
        return (
          (prevFileIndex + cachedTracksData.length - 1) %
          cachedTracksData.length
        );
      });
    }
  };

  const handleClickNext = () => {
    setPlayStatus(true);
    if (cachedTracksData) {
      setCurrentFileIndex((prevFileIndex) => {
        return (prevFileIndex + 1) % cachedTracksData.length;
      });
    }
  };

  const trackListAnimation = trackListReady
    ? trackListOpen
      ? styles["slide-in"]
      : styles["slide-out"]
    : "";

  const albumArtworkVisibility = trackListReady
    ? trackListOpen
      ? styles["hide-artwork"]
      : styles["show-artwork"]
    : "";

  const releaseDate =
    playerValues?.releaseDate?.value &&
    new Date(playerValues?.releaseDate?.value).toLocaleDateString("en-us");

  const musicPlayerTitle = playerValues?.releaseTitle?.value ? (
    <div className="flex-vert-center space-between">
      <div className={styles["release-title"]}>
        {playerValues.releaseTitle?.value}
      </div>
      <div className={styles["release-date"]}>{releaseDate}</div>
    </div>
  ) : (
    "Music Player"
  );

  const albumArtwork =
    playerValues?.albumArtwork?.value[0]?.asset ||
    playerValues?.albumArtwork?.value[0];

  const artworkId = albumArtwork?.id;

  return (
    <TemplatePageRenderedSectionWrapper
      title={musicPlayerTitle}
      highlightName={"Music Player"}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      {cachedTracksData.length > 0 && (
        <main className={styles.section}>
          <div className={styles["grid-container"]}>
            <img
              className={`${styles["album-artwork"]} ${albumArtworkVisibility}`}
              src={`/api/v1/signed-assets/${artworkId}/medium`}
            />
            <div
              className={`${styles["track-list-container"]} ${trackListAnimation}`}
            >
              <TrackList
                tracks={cachedTracksData}
                currentFileIndex={currentFileIndex}
                setCurrentFileIndex={setCurrentFileIndex}
                playStatus={playStatus}
                setPlayStatus={setPlayStatus}
              />
            </div>
          </div>
          <div>
            <div className={styles["artist-details-container"]}>
              <div
                className={`flex-vert-center space-between ${styles["track-title"]}`}
              >
                <span>
                  {cachedTracksData[currentFileIndex]?.title?.removeExt()}
                </span>
                <button
                  onClick={() => {
                    setTrackListReady(true), setTrackListOpen(!trackListOpen);
                  }}
                  className={`btn--naked ${styles["icon"]} ${styles["track-list-button"]}`}
                >
                  {trackListOpen ? <ChevronUp /> : <ChevronDown />}
                </button>
              </div>
              <div className={styles["artist-name"]}>
                <span>{playerValues?.artistName?.value}</span>
              </div>
            </div>

            <AudioPlayer
              files={cachedTracksData}
              handleClickNext={handleClickNext}
              handleClickPrevious={handleClickPrevious}
              currentFileIndex={currentFileIndex}
              setCurrentFileIndex={setCurrentFileIndex}
              playStatus={playStatus}
              setPlayStatus={setPlayStatus}
              playerColor={textColor}
            />
          </div>
          {playerValues?.releaseBio?.value && (
            <div className={styles["release-bio"]}>
              <ReadMoreParagraph
                maxHeight={80}
                text={playerValues?.releaseBio?.value}
                backgroundColor={backgroundColor}
              />
            </div>
          )}
        </main>
      )}
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedMusicPlayer;
