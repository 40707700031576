import React from "react";
import Template, { TemplateConfig } from "./models/Template";
import Block, { BlockParam, Size } from "./models/Block";
import { resolveMappedPath } from "../helpers";
import Composition from "./models/Composition";
import CompositionRenderer from "./renderers/CompositionRenderer";
import clone from "just-clone";

interface Props {
  template: Template;
  templateConfig: TemplateConfig;
  mapDataSource?: any;
  PassedInCompositionRenderer?: any;
  size?: Size;
  onReadyToExport?: any;
  freezeOnRender?: boolean;
}

function hydratedValueFor(param: BlockParam, mapDataSource: any) {
  const valueIsNested = param.type === "SELECT";

  const mappedPath = valueIsNested ? param.value?.mappedPath : param.mappedPath;
  const valueKey = valueIsNested ? param.value?.key : param.key;
  const style = valueIsNested ? param.value?.style : param.style;
  const value = param.value?.value || param.value;
  if (mappedPath) {
    const mappedValue = resolveMappedPath(mappedPath, mapDataSource);
    const finalValue = {
      ...param,
      value: mappedValue,
      key: valueKey,
      style: style,
    };
    return finalValue;
  } else {
    const finalValue = { ...param, value: value, key: valueKey, style: style };
    return finalValue;
  }
}

function hydrateParams(
  params: BlockParam[],
  templateConfig: TemplateConfig,
  mapDataSource: any
) {
  if (params) {
    params.forEach((param) => {
      const templateConfigParam = templateConfig[param.name];
      if (templateConfigParam) {
        const templateConfigValue =
          typeof templateConfigParam.value === "object"
            ? templateConfigParam.value
            : templateConfigParam;
        param.value = hydratedValueFor(templateConfigValue, mapDataSource);
      } else {
        const valueIsNested = param.type === "SELECT";
        const p = valueIsNested && param.options ? param.options[0] : param;
        param.value = hydratedValueFor(p, mapDataSource);
      }
    });
  }
  return params;
}

async function compose(
  template: Template,
  templateConfig: TemplateConfig,
  mapDataSource?: any
) {
  async function hydrate(block: Block) {
    var hydratedBlock = { ...block };

    if (hydratedBlock.params && templateConfig) {
      hydratedBlock.params = hydrateParams(
        hydratedBlock.params,
        templateConfig,
        mapDataSource
      );
    }
    return Promise.resolve(hydratedBlock);
  }
  //loop through all the template blocks, hydrating each
  const hydratedBlocks = await Promise.all(template.blocks.map(hydrate));
  return { blocks: hydratedBlocks, meta: template.meta } as Composition;
}

export { compose };

const Composer = ({
  template,
  templateConfig,
  mapDataSource,
  size,
  PassedInCompositionRenderer,
  onReadyToExport,
  freezeOnRender,
}: Props) => {
  const [composition, setComposition] = React.useState<Composition>();

  async function refreshComposition() {
    const c = await compose(template, clone(templateConfig), mapDataSource);
    setComposition(c);
  }

  React.useMemo(refreshComposition, [template, templateConfig, mapDataSource]);
  return PassedInCompositionRenderer ? (
    <PassedInCompositionRenderer
      onReadyToExport={onReadyToExport}
      composition={composition}
      size={size}
      freezeOnRender={freezeOnRender}
      mapDataSource={mapDataSource}
    />
  ) : (
    <CompositionRenderer
      onReadyToExport={onReadyToExport}
      composition={composition}
      size={size}
    />
  );
};

export default Composer;
