import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import {
  AggregateEvent,
  fetchAggregrateData,
} from "../../../analytics/models/Aggregates";

type Props = {
  resourceId: string;
  resourceType: string;
  actionType: string;
  actionDisplayName: string;
};

type CounterAnalyticsData = {
  records: AggregateEvent[];
};

const useStyles = makeStyles(() => ({
  analyticsDataContainer: {
    display: "flex",
  },
  analyticsSpan: {
    fontSize: "10px",
    whiteSpace: "nowrap",
    fontFamily: "Helvetica",
    marginRight: "10px",
    color: "#B4B4B4",
  },
}));

const CounterAnalytics = ({
  resourceId,
  resourceType,
  actionType,
  actionDisplayName,
}: Props) => {
  const classes = useStyles();
  const [analyticsData, setAnalyticsData] =
    useState<CounterAnalyticsData | null>(null);

  useEffect(() => {
    if (resourceId) {
      fetchAggregrateData({
        contentId: resourceId,
        contentType: resourceType,
      })
        .then((data: any) => {
          setAnalyticsData(data);
        })
        .catch((error: any) => {
          //TODO: hook up error provider after merge
          console.warn(error);
        });
    }
  }, [resourceId]);

  return (
    <>
      {analyticsData && (
        <div className={classes.analyticsDataContainer}>
          {analyticsData.records.map((analyticsData, index: number) => (
            <React.Fragment key={index}>
              {analyticsData.actionType === actionType && (
                <span className={classes.analyticsSpan}>
                  {actionDisplayName}: {analyticsData.value}
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default CounterAnalytics;
