import React, { useEffect, useRef, useState } from "react";
import styles from "./TemplateShow.module.scss";
import { Template } from "../../template-page-builder/types/Templates";
import { useNavigate, useParams } from "react-router-dom";
import TemplateVersionForm from "../TemplateVersionForm/TemplateVersionForm";
import { CheckCircle } from "@material-ui/icons";

const fetchTemplate = async (id: string) => {
  try {
    const res = await fetch(`/api/v1/templates/${id}`);
    if (res.ok) {
      const json = await res.json();
      return json;
    }
  } catch (error) {
    console.error(error);
    console.log("bananananana");
  }
};

const updateTemplate = async (id: string, update: string) => {
  try {
    const res = await fetch(`/api/v1/templates/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: update,
    });
    if (res.ok) {
      const json = await res.json();
      return json;
    }
  } catch (error) {
    console.error(error);
  }
};
const createTemplate = async (json: string) => {
  try {
    const res = await fetch(`/api/v1/templates`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: json,
    });
    if (res.ok) {
      const json = await res.json();
      return json;
    }
  } catch (error) {
    console.error(error);
  }
};

const TemplateShow = () => {
  let { id } = useParams<any>();
  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);

  const [template, setTemplate] = useState<Template>();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }, [success]);

  const loadTempalte = async (id: string) => {
    if (id === "new") return;

    const res = await fetchTemplate(id);

    if (!res) navigate(`/admin/templates/new`);

    if (formRef?.current) {
      const form = formRef.current;
      form.templateName.value = res.name;
    }

    setTemplate(res);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formRef.current) {
      const json = JSON.stringify({
        name: formRef.current.templateName.value,
        type: formRef.current.templateType.value,
      });

      if (id === "new" && formRef.current.templateName.value != "") {
        createTemplate(json).then((res) =>
          navigate(`/admin/templates/${res.id}`)
        );
      }

      id &&
        id != "new" &&
        updateTemplate(id, json).then((res) => {
          if (id && res) {
            loadTempalte(id);
            setSuccess(true);
          }
        });
    }
  };

  useEffect(() => {
    id && loadTempalte(id);
  }, [id]);

  return (
    <div className={styles["main"]}>
      <h1>{template?.name}</h1>
      <hr
        style={{
          borderBottom: "solid 0.5px #515151",
          borderTop: "none",
          marginBottom: "4em",
        }}
      />
      {id != "new" && (
        <>
          <h2>Raw Tempalte Response</h2>
          <pre>{template && JSON.stringify(template, null, 2)}</pre>
        </>
      )}

      <h2>{id === "new" ? "Create" : "Edit"} Template</h2>
      <form
        onSubmit={onSubmit}
        ref={formRef}
        className={styles["template-form"]}
      >
        {success && (
          <div className={styles["success"]}>
            <CheckCircle style={{ fill: "green", fontSize: "20em" }} />
          </div>
        )}
        <label htmlFor="templateName">Name</label>
        <input type="text" name="templateName" />
        <label htmlFor="type">type</label>
        <select name="templateType">
          <option value="ARTIST_EPK">ARTIST_EPK</option>
        </select>

        <button type="submit">Submit</button>
      </form>
      {template && (
        <TemplateVersionForm template={template} loadTempalte={loadTempalte} />
      )}
    </div>
  );
};

export default TemplateShow;
