import React, { useState } from "react";
import { useMeasure } from "react-use";

import ReadMoreButton from "./ReadMoreButton/ReadMoreButton";
import styles from "./ReadMoreParagraph.module.scss";

type Props = {
  maxHeight: number;
  backgroundColor?: string;
  text: string;
};

const ReadMoreParagraph = ({ maxHeight, text, backgroundColor }: Props) => {
  const [readMore, setReadMore] = useState(false);

  const [bioRef, { height }] = useMeasure<HTMLParagraphElement>();

  return (
    <div
      className={`${styles["collapse"]}
    ${readMore && styles["copy-btn-margin"]}`}
    >
      <div
        style={{
          height: readMore ? `${height}px` : `${maxHeight}px`,
          overflow: "hidden",
          transition: "height 200ms ease-in-out",
        }}
      >
        <p ref={bioRef} id="body-paragraph">
          {text.trim()}
        </p>
      </div>
      {height >= maxHeight && (
        <ReadMoreButton
          readMore={readMore}
          setReadMore={setReadMore}
          backgroundColor={backgroundColor}
        />
      )}
    </div>
  );
};

export default ReadMoreParagraph;
