import React from "react";
import styles from "./DropZone.module.scss";
import { ReactComponent as ArrowUpCircle } from "../../../../../shared/icons/arrow-up-circle.svg";

type Props = {
  dropZoneText: string;
  handleFiles: (
    files: FileList,
    event: React.ChangeEvent<HTMLInputElement>,
    accepts?: string
  ) => void;
  inputRef: any; //help :`(
  multiFile?: boolean | undefined;
  accept: string;
};

const DropZone = ({
  handleFiles,
  inputRef,
  multiFile,
  accept,
  dropZoneText,
}: Props) => {
  return (
    <label className={styles["upload-container"]}>
      <div
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
        onDrop={(e: any) => {
          e.preventDefault();
          e.persist();
          handleFiles(e.dataTransfer.files, e, inputRef.current.accept);
        }}
      >
        <span className="sr-only">Choose File</span>
        <div className="flex-vert-center full-height full-width text-center">
          <div className={`${styles["dropzone-item"]} flex-column full-height`}>
            <div className="flex-center half-height">
              <ArrowUpCircle height={"40px"} />
            </div>
            <div className={`${styles["dropzone-text"]} half-height`}>
              {dropZoneText}
            </div>
          </div>
          <div className={"flex-column align-center"}>
            <span className={styles["dropzone-spacer-text"]}>or</span>
          </div>
          <div className={styles["dropzone-item"]}>
            <span className={styles["dropzone-text"]}>BROWSE FILES</span>
          </div>
        </div>
        <input
          ref={inputRef}
          type="file"
          accept={accept}
          multiple={multiFile}
          onChange={(event) =>
            handleFiles(
              event.target.files as FileList,
              event,
              inputRef.current.accept
            )
          }
          className="sr-only"
        />
      </div>
    </label>
  );
};

export default DropZone;
