import React from "react";
import { Logger } from "../shared/SafeLogger";
import { Switch } from "@material-ui/core";
import ItemIndex, { ItemRenderProps } from "../shared/ItemIndex";
import NodeSchema from "./NodeSchema";
import API from "../shared/API";

function ImageCellContent(props: ItemRenderProps) {
  let icon = props.item[props.attr];
  return <img src={icon} style={{ width: 50, height: 50 }} />;
}

function PublishedCellContent(props: ItemRenderProps) {
  const [checked, setChecked] = React.useState<boolean>(props.item[props.attr]);

  const toggleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.item.published = event.target.checked;
    let node = props.item;
    let formData = node.toFormData();
    API.update(node.id, formData, NodeSchema)
      .then((response) => {
        setChecked(response.putblished);
        Logger.of("App").info("response", response);
      })
      .catch((error) => {
        Logger.of("App").info("Error", error);
      });
  };

  return <Switch size="small" checked={checked} onChange={toggleChecked} />;
}

const renderers = { icon: ImageCellContent, published: PublishedCellContent };

const NodeIndex = () => {
  return <ItemIndex schema={NodeSchema} renderers={renderers} />;
};

export default NodeIndex;
