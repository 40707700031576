import React, { useState } from "react";
import Konami from "react-konami-code";
import API from "../shared/API";
import NodeSchema, { CMSNode } from "./NodeSchema";
import Loading from "../shared/Loading";

import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from "@material-ui/core";

type Props = {
  node: CMSNode;
};

const NodeMassDelete = ({ node }: Props) => {
  const schema = NodeSchema;

  // deleting all records
  const unpublishAndDeleteAll = async (node: CMSNode) => {
    if (process.env.NODE_ENV === "production") {
      alert(
        "You should not have been able to access this on PROD, please contact Assemble.  Canceling delete all."
      );
      return;
    }
    // loop over the children and recursively call this function
    const done = await Promise.all(
      node?.children?.map(
        async (child) => await Promise.resolve(unpublishAndDeleteAll(child))
      )
    );

    // await children to finish being deleted, then unpublish self
    done && (await unpublishAndDeleteNode(node));
  };

  const unpublishAndDeleteNode = async (node: CMSNode) => {
    node.published = false;
    node.isContentRoot = false;
    const formData = node.toFormData();

    // have to update all, then delete, and make sure to delete children first
    return API.update(node.id!, formData, schema).then(() =>
      API.delete(node.id!, schema).then(() => "done")
    );
  };

  return (
    <Konami>
      <DeleteModal
        action={() =>
          node &&
          unpublishAndDeleteAll(node).then(() => window.location.reload())
        }
      />
    </Konami>
  );
};

const useDeleteModalStyles = makeStyles(() => ({
  deleteAllBtn: {
    display: "block",
    marginTop: "20px",
    width: "100%",
  },
  loadingContainer: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const DeleteModal = ({ action }: { action: () => void }) => {
  const classes = useDeleteModalStyles();

  const [open, setOpen] = useState(false);
  const [deleteConfirmInput, setDeleteConfirmInput] = useState("");
  const [deleting, setDeleting] = useState(false);
  return (
    <>
      <Button
        className={classes.deleteAllBtn}
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        Delete All Content
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setDeleteConfirmInput("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {!deleting
            ? "Are you SURE you want to DELETE all the content? "
            : "Deleting all records, don't touch anything, please."}
        </DialogTitle>
        <DialogContent>
          {deleting ? (
            <div className={classes.loadingContainer}>
              <Loading height="20px" />
            </div>
          ) : (
            <>
              <DialogContentText id="alert-dialog-description">
                Please type DELETE into the field, then click 'Agree'.
              </DialogContentText>
              <TextField
                fullWidth
                value={deleteConfirmInput}
                onChange={(e) => setDeleteConfirmInput(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color="secondary"
            autoFocus
            disabled={deleting}
          >
            cancel
          </Button>
          <Button
            onClick={() => {
              setDeleting(true);
              action();
            }}
            color="secondary"
            disabled={deleteConfirmInput !== "DELETE" || deleting}
          >
            Permanently delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NodeMassDelete;
