import React, { useEffect, useState } from "react";

import RockDove from "../RockDove";
import { UserRole } from "../ResourceSchema";
import ErrorPage from "../../linkviewer/ErrorPage";
import Loading from "../Loading";

const AuthCheck: React.FC = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState<boolean | null>(null);

  useEffect(() => {
    // try to get auth through RockDove
    RockDove.shared()
      .getHeaders(UserRole.Artist)
      .then(() => setIsAuthed(true))
      .catch(() => setIsAuthed(false));
  }, []);

  if (isAuthed === null) return <Loading height="100vh" />;
  if (isAuthed === false) return <ErrorPage />;

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthCheck;
