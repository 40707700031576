import React from "react";
import { ContentNodeQueryResult, QueryMatch } from "../ContentProvider";
import { Link } from "react-router-dom";
import { makeStyles, Typography, ListItem } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  listItem: {
    width: "100%",
    color: "#a5a5a5",
    fontSize: "15px",
    fontFamily: "SF Pro Text",
  },
  highlighted: {
    color: "pink",
  },
  noResults: {
    color: "#a5a5a5",
    textAlign: "center",
    marginTop: "5%",
    padding: "20px",
  },
  result: {
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  resultsContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: "#141414",
    borderRadius: "0 0 4px 4px",
    boxShadow: "0 2px 12px 0 rgb(0 0 0 / 70%)",
    maxHeight: "400px",
    overflowY: "auto",
  },
}));

interface Props {
  results: ContentNodeQueryResult[];
  clearSearch: () => void;
}

const SearchResultsContainer = ({ results, clearSearch }: Props) => {
  const classes = useStyles();

  const resultViews = results.map((result) => (
    <SearchResultView
      result={result}
      key={result.contentNode.id}
      clearSearch={clearSearch}
    />
  ));

  return (
    <div className={classes.resultsContainer}>
      {resultViews.length > 0 ? (
        resultViews
      ) : (
        <Typography className={classes.noResults}>No Results</Typography>
      )}
    </div>
  );
};

interface ResultProps {
  result: ContentNodeQueryResult;
  clearSearch: () => void;
}

const SearchResultView = ({ result, clearSearch }: ResultProps) => {
  const classes = useStyles();

  const highlighted = (queryMatch: QueryMatch) => {
    let value = queryMatch.humanReadableValue;
    let range = queryMatch.range;

    let start = Math.max(0, range.index - 20);
    let lengthToEnd = value.length - (range.index + range.length);

    return (
      <React.Fragment>
        {start > 0 && <span>...</span>}
        {value.substr(start, range.index - start)}
        <span className={classes.highlighted}>
          {value.substr(range.index, range.length)}
        </span>
        {value.substr(range.index + range.length, lengthToEnd)}
        {lengthToEnd === 10 && <span>...</span>}
      </React.Fragment>
    );
  };

  const titleView = () => {
    let filteredMatches = result.matches.filter(
      (match) => match.key === "title"
    );
    if (filteredMatches.length > 0) {
      return highlighted(filteredMatches[0]);
    }
    return <span>{result.contentNode.title}</span>;
  };

  const subtitleView = () => {
    let filteredMatches = result.matches.filter(
      (match) => match.key === "subtitle"
    );
    if (filteredMatches.length > 0) {
      return highlighted(filteredMatches[0]);
    }
    return <span>{result.contentNode.subtitle}</span>;
  };

  const contentView = () => {
    let filteredMatches = result.matches.filter(
      (match) => match.key === "content"
    );
    if (filteredMatches.length > 0) {
      return highlighted(filteredMatches[0]);
    }
    return undefined;
  };

  return (
    <Link
      onClick={clearSearch}
      to={result.contentNode.path()}
      style={{ textDecoration: "none" }}
    >
      <ListItem className={classes.listItem} button>
        <Typography className={classes.result}>
          {titleView()} {subtitleView()}
          <br />
          {contentView()}
        </Typography>
      </ListItem>
    </Link>
  );
};

export default SearchResultsContainer;
