import React from "react";
import ArtistLinkInputHeader from "../LinkEditComponents/ArtistLinkInputHeader";
import ArtistLinkInput from "../LinkEditComponents/ArtistLinkInput";
import { InputAdornment, Box, SvgIcon } from "@material-ui/core";
import { ArtistSocials } from "../models/ArtistLink";
import { Update } from "../ArtistLinkEdit/FormFields";
import SocialIcon from "../shared/SocialIcons";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

interface Props {
  setForceOpenCollapse: any;
}

const ArtistSocials = (props: Props) => {
  const { artistLink, dispatch } = useArtistLinkContext();

  const artistSocialsChange = (update: Update) => {
    props.setForceOpenCollapse([]);
    let updatedArtistLink = { ...artistLink } as any;
    updatedArtistLink.artistSocials[update.name] = update.value;
    dispatch({
      type: "SET_ARTISTLINK",
      payload: updatedArtistLink,
    });
  };

  return (
    <>
      {Object.entries(artistLink.artistSocials).map((artistSocial) => {
        const artistSocialKey = artistSocial[0] as keyof ArtistSocials;
        const artistSocialValue = artistSocial[1];
        return (
          <React.Fragment key={artistSocialKey}>
            <ArtistLinkInputHeader header={artistSocialKey} variant={"h6"} />
            <ArtistLinkInput
              artistLinkKey={artistSocialValue}
              validators={["isURL"]}
              errorMessage={["Not a valid URL"]}
              name={"artistSocials"}
              label={`${artistSocialKey.capitalize()} URL`}
              id={artistSocialKey}
              onChange={artistSocialsChange}
              startAdornment={
                <Box ml={2}>
                  <InputAdornment position="start">
                    <SvgIcon>
                      <SocialIcon icon={artistSocialKey} />
                    </SvgIcon>
                  </InputAdornment>
                </Box>
              }
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ArtistSocials;
