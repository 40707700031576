import React from "react";
import { ContentView } from "../../../platoon-cms-lib";
import { ContentNode } from "../ContentProvider";
interface Props {
  contentNode: ContentNode;
}
//why is this component here?
const NodeContentView = React.memo(({ contentNode }: Props) => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return <ContentView contentNode={contentNode} />;
});

export default NodeContentView;
