import React from "react";
import { Box, Grid, makeStyles, IconButton, Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TrackList from "./TrackList";
import ExpandedDescription from "./ExpandedDescription";

interface Props {
  playStatus: boolean;
  setPlayStatus: any;
  setOpenTrackList: any;
  openTrackList: boolean;
  setOpenDescription: any;
  openDescription: boolean;
  fontColor: string;
  trackIndex: number;
  setTrackIndex: any;
}

type StyleProps = {
  iconColor: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    height: "100%",
    maxWidth: "625px",
    padding: theme.spacing(4),
  },
  modalWrapper: {
    height: "90%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#e4cfd380",
      borderRadius: "10px",
    },
  },
  iconButton: {
    color: ({ iconColor }: StyleProps) => iconColor,
  },
}));

const ExpandedContent = (props: Props) => {
  const iconColor = props.fontColor;
  const classes = useStyles({ iconColor });

  return (
    <Box height={1} width={1} display="flex" justifyContent="center">
      <Grid className={classes.modalContainer} container alignItems="center">
        <Grid container justify={"flex-end"}>
          <IconButton
            onClick={() => [
              props.setOpenTrackList(false),
              props.setOpenDescription(false),
            ]}
            className={classes.iconButton}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} className={classes.modalWrapper}>
          <Grid container style={{ height: "100%" }} alignItems="center">
            <Grid item xs={12}>
              {props.openTrackList && (
                <TrackList
                  fontColor={props.fontColor}
                  playStatus={props.playStatus}
                  setPlayStatus={props.setPlayStatus}
                  setOpenTrackList={props.setOpenTrackList}
                  trackIndex={props.trackIndex}
                  setTrackIndex={props.setTrackIndex}
                />
              )}
              {props.openDescription && (
                <Grid item xs={12}>
                  <ExpandedDescription
                    setOpenDescription={props.setOpenDescription}
                    fontColor={props.fontColor}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExpandedContent;
