import React from "react";
import { Logger } from "../shared/SafeLogger";
import { Route } from "react-router-dom";
import FrameRouter, {
  ArtistIdentity,
  SourceConfig,
  SessionRequest,
  SessionResponse,
} from "frame-router";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    padding: "20px",
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function Routes(props: any) {
  const classes = useStyles();
  // What app is this?  it will crash if I don't fix this... but i dont know what it even is
  // Where is this being used??
  const artistSlug = props.match.params.artist_slug;
  const artistLinksParentPath = `/artists/${artistSlug}/links`;

  // Same host, base = ""
  const sourceConfig = {
    base: "",
    pathMapping: {
      "/learn": "/apps/publications",
      [artistLinksParentPath]: "/apps/link-builder",
      "/apps/link-viewer": "/apps/link-viewer",
    },
  } as SourceConfig;

  async function handleSessionRequest(request: SessionRequest) {
    Logger.of("App").info("handling session request:", request);
    const response = await fetch(`/api/v1/artists/${artistSlug}`);
    const json = await response.json();

    const artist = { name: json.name, id: json._id } as ArtistIdentity;

    Logger.of("App").info("Got artist maybe", artist);

    if (!artist) {
      throw Error(`artist.slug ${artistSlug} not found`);
    }

    await new Promise((resolve, reject) => {
      setTimeout(() => {
        Logger.of("App").info(resolve, reject);
        resolve("timeout over");
        //        reject(new Error("DANG"))
      }, 1000);
    });

    const sessionResponse: SessionResponse = {
      token: "fake_token_123",
      artist: artist,
      request: request,
    };
    return sessionResponse;
  }

  const mappedRoutes = Object.keys(sourceConfig.pathMapping).map((item) => (
    <Route
      path={item}
      element={(props: any) => (
        <FrameRouter.Frame
          config={sourceConfig}
          onSessionRequest={handleSessionRequest}
          log
          {...props}
        />
      )}
    />
  ));

  return <div className={classes.container}>{mappedRoutes}</div>;
}
