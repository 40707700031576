import { makeStyles } from "@material-ui/core";
import React from "react";
import Breadcrumbs from "../Breadcrumbs";
import { ContentNode } from "../ContentProvider";

type TOCBreadcrumbProps = {
  contentNode: ContentNode;
};

const useStyles = makeStyles(() => ({
  heading: {
    margin: 0,

    fontFamily: "Futura",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#ffffff",
  },
  uppercase: {
    textTransform: "uppercase",
  },
}));
const TOCBreadcrumb = ({ contentNode }: TOCBreadcrumbProps) => {
  const classes = useStyles();

  const crumbs = contentNode.getCrumbs();
  // remove the top parent below root (it's used as the title)
  crumbs.shift();

  return (
    <div className={classes.uppercase}>
      <h1 className={classes.heading}>
        {contentNode.getTopParentBelowRoot()?.title}
      </h1>
      <Breadcrumbs links contentNodes={crumbs} />
    </div>
  );
};

export default TOCBreadcrumb;
