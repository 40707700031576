import React, { useEffect, useState } from "react";
import styles from "./ArtistPageLoader.module.scss";
import { useParams } from "react-router-dom";
import { getTemplatePagePublic } from "../../template-page-builder/queries/useTemplatePages";
import TemplatePageRenderer from "../../template-page-builder/components/TemplatePageRenderer/TemplatePageRenderer";
import Loading from "../../shared/Loading";
import ErrorPage from "../../linkviewer/ErrorPage";
import Lockdown from "../../shared/components/Lockdown/Lockdown";
import { useErrorHandlingContext } from "../../providers/ErrorHandlingProvider";
import { trackAggregatesEvent } from "../../analytics/models/Aggregates";
import { TemplatePageAction } from "../../providers/AnalyticsProvider";

const ArtistPageLoader = () => {
  const { templatePageSlug } = useParams();
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [showLockdown, setShowLockdown] = useState(false);
  const [templatePageFetchCount, setTemplatePageFetchCount] = useState(0);

  const { errorDispatch } = useErrorHandlingContext();

  const {
    isLoading,
    error,
    data: templatePage,
    refetch,
  } = getTemplatePagePublic(templatePageSlug, password);

  useEffect(() => {
    if (password) refetch();
  }, [password]);

  useEffect(() => {
    setTemplatePageFetchCount(templatePageFetchCount + 1);

    if (templatePage) {
      setShowLockdown(false);

      if (templatePageFetchCount <= 1) {
        //When we start more extenstive analytics for templatePages this can move into
        //the analytics provider, similar to how stealth player performs this action
        const aggregateEvent = {
          actionType: TemplatePageAction.mount,
          contentType: "TemplatePage",
          contentId: templatePage.id,
          lastAggregateAction: "bump",
          valueType: "count",
          value: 0,
        };
        trackAggregatesEvent(aggregateEvent);
      }
    }
  }, [templatePage]);

  // set error for lockdown
  useEffect(() => {
    if (error?.message === "401") {
      // only show error message if there is a password entered and we have error state
      if (password) {
        errorDispatch({
          type: "SET_ERROR",
          payload: {
            error: {
              response: { status: "401" },
              reasons: { message: "Unauthorized" },
            },
            serveErrorPage: false,
            serveSnack: false,
          },
        });
      }
      //setting lock down
      setShowLockdown(true);
    }
  }, [error]);

  const onSubmitPassword = (password: string) => {
    setPassword(password);
  };

  // show 404 page
  if (error && error?.message !== "401") {
    return <ErrorPage />;
  }

  return (
    <>
      {/* loading overlay */}
      {isLoading && (
        <div className={styles["loading-mask"]}>
          <Loading />
        </div>
      )}

      {/* show the lockdown? */}
      {showLockdown ? (
        <div className={styles["container"]}>
          <Lockdown
            lockDownMessage="This page is password protected."
            onSubmit={(e: any) => onSubmitPassword(e)}
            height="400px"
          />
        </div>
      ) : (
        <>
          {templatePage && (
            <TemplatePageRenderer
              templatePage={templatePage}
              assembleArtist={templatePage.artist}
            />
          )}
        </>
      )}
    </>
  );
};

export default ArtistPageLoader;
