import { Logger } from '../shared/SafeLogger';
import API, { validateQueryResponse } from "../shared/API";
import AssetSchema from "./AssetSchema";
import { Asset, AssetService, AssetQuery, AssetQueryResult } from "../platoon-cms-lib";

export default class APIAssetService implements AssetService {
  timeout: number;

  constructor() {
    this.timeout = 1000
  }

  async uploadAsset(formData: FormData): Promise<Asset> {
    Logger.of('App').info("Uploading asset: ", formData);
    return API.create(formData, AssetSchema)
      .then(asset => {
        Logger.of('App').info("GOT ASSET:", asset);
        return Promise.resolve(asset);
      })
  }

  getAssetsForQuery(query: AssetQuery): Promise<AssetQueryResult> {
    const { type, page } = query;
    const schema = AssetSchema;
    const path = `${schema.apiRootPath}?type=${type}&page=${page}`;
    Logger.of('App').info("Querying for path:", path);

    return API.fetch(path, schema.role)
      .then((response: Response) => {
        const result = validateQueryResponse(response, schema);
        return Promise.resolve(result);
      })
  }
}
