import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import PreviewMode from "./PreviewMode";
import ArtistMusicPlayerSection from "./ArtistMusicPlayerSection";
import ArtistNameTitleSection from "./ArtistNameTitleSection";
import { BackgroundMode } from "../models/ArtistLink";
import ArtistContentArea from "./ArtistContentArea";
import ExpandedContent from "./ExpandedContent";
import { use100vh } from "react-div-100vh";
import { useMeasure } from "react-use";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

interface Props {
  playerHeight?: any;
  playerWidth: any;
  preview: boolean;
  width?: any;
}

type StyleProps = {
  previewMargin: string;
  playerHeight: string;
  previewDisplayHeight: string;
  blurFilter: string;
  playerMaxWidth: string;
  backgroundColor: string;
  playerBackground: string;
};

const useStyles = makeStyles((theme) => ({
  playerContainer: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.secondary.dark,
  },
  playerWrapper: {
    minHeight: "calc(100vh - 174px)",
    marginBottom: ({ previewMargin }: StyleProps) => previewMargin,
    justify: "center",
    alignItems: "center",
  },
  player: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  playerSize: {
    height: ({ playerHeight }: StyleProps) => playerHeight,
    width: "100%",
    maxHeight: ({ previewDisplayHeight }: StyleProps) => previewDisplayHeight,
    backdropFilter: ({ blurFilter }: StyleProps) => `blur(${blurFilter})`,
    WebkitBackdropFilter: ({ blurFilter }: StyleProps) => `blur(${blurFilter})`,
  },
  playerBackground: {
    backgroundColor: ({ backgroundColor }: StyleProps) => backgroundColor,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: ({ playerBackground }: StyleProps) =>
      `url(${playerBackground})`,
    height: "100%",
    width: "100%",
    maxWidth: ({ playerMaxWidth }: StyleProps) => playerMaxWidth,
    transform: "translateZ(0)",
    willChange: "transform",
  },
  expandedContentContainer: {
    height: "100%",
    width: "100%",
  },
  audioControlsContainer: {
    height: "100%",
    width: "100%",
  },
}));

const ArtistLinkMusicPlayer = (props: Props) => {
  const { artistLink } = useArtistLinkContext();

  const linkViewerHeight = use100vh();

  const [displayMode, setDisplayMode] = useState("Mobile");
  const [trackIndex, setTrackIndex] = useState<number>(0);
  const [openTrackList, setOpenTrackList] = useState(false);
  const [openDescription, setOpenDescription] = useState(false);
  const [playStatus, setPlayStatus] = useState(false);

  const [ref, { width, height }] = useMeasure<HTMLDivElement>();

  const playerIsMobile = () => {
    if (width < 500) return true;
    else if (height < 650) return true;
    else if (width > 500 && height < 650) return true;
    else return false;
  };

  useEffect(() => {
    if (artistLink.tracks && !artistLink.tracks[trackIndex]) {
      setTrackIndex(0);
    }
  }, [artistLink.tracks?.length]);

  const handleClickPrevious = () => {
    setPlayStatus(true);
    if (artistLink.tracks) {
      let updatedIndex =
        (trackIndex + artistLink.tracks.length - 1) % artistLink.tracks.length;
      setTrackIndex(updatedIndex);
    }
  };

  const handleClickNext = () => {
    setPlayStatus(true);
    if (artistLink.tracks) {
      let updatedIndex = (trackIndex + 1) % artistLink.tracks.length;
      setTrackIndex(updatedIndex);
    }
  };

  const handleDisplayClick = (previewMode: any) => {
    setDisplayMode(previewMode);
  };

  const trackListModal = () => {
    setOpenTrackList(!openTrackList);
  };

  const chosenBackground = () => {
    switch (artistLink.backgroundMode) {
      case BackgroundMode.color:
        return artistLink.backgroundColor;
      case BackgroundMode.image:
        return (
          artistLink.backgroundImage?.derivatives?.medium ||
          artistLink.backgroundImage?.attachment
        );
      case BackgroundMode.blur:
        return artistLink.albumArtwork?.attachment;
      default:
        return artistLink.backgroundColor;
    }
  };

  const fontColor = artistLink.textColor ? artistLink.textColor : "white";
  const playerColor = artistLink.playerColor ? artistLink.playerColor : "white";
  const playerOpacity =
    artistLink.backgroundMode === BackgroundMode.color ? "FF" : "80";
  const blurFilter =
    artistLink.backgroundMode === BackgroundMode.blur ? "75px" : "0px";
  const backgroundColor = artistLink.backgroundColor
    ? artistLink.backgroundColor
    : "black";
  const playerMaxWidth =
    displayMode === "Mobile" && props.preview ? "415px" : "unset";
  const previewDisplayHeight =
    displayMode === "Mobile" && props.preview
      ? "900px"
      : `calc(${props.playerHeight})`;
  const previewMargin = props.preview ? "100px" : "0px";

  const calcPlayerHeight = () => {
    if (props.playerHeight === "mobileEdit") {
      return `calc(${linkViewerHeight}px - 70px)`;
    } else if (props.playerHeight) return props.playerHeight;
    else return linkViewerHeight;
  };

  const playerHeight = calcPlayerHeight();
  const playerBackground = chosenBackground();

  const classes = useStyles({
    blurFilter,
    backgroundColor,
    playerMaxWidth,
    previewDisplayHeight,
    playerHeight,
    playerBackground,
    previewMargin,
  });

  return (
    <div className={classes.playerContainer}>
      {props.preview && (
        <PreviewMode
          handleDisplayClick={handleDisplayClick}
          displayMode={displayMode}
        />
      )}
      <Grid className={classes.playerWrapper} container>
        <Grid item xs={props.playerWidth}>
          <div className={classes.player}>
            <div className={classes.playerBackground}>
              <Grid ref={ref} container className={classes.playerSize}>
                {openTrackList || openDescription ? (
                  <div className={classes.expandedContentContainer}>
                    <ExpandedContent
                      setOpenTrackList={setOpenTrackList}
                      openTrackList={openTrackList}
                      setOpenDescription={setOpenDescription}
                      openDescription={openDescription}
                      trackIndex={trackIndex}
                      setTrackIndex={setTrackIndex}
                      playStatus={playStatus}
                      setPlayStatus={setPlayStatus}
                      fontColor={fontColor}
                    />
                  </div>
                ) : (
                  <ArtistContentArea
                    fontColor={fontColor}
                    preview={props.preview}
                    displayMode={displayMode}
                    setOpenDescription={setOpenDescription}
                    playerIsMobile={playerIsMobile()}
                  />
                )}

                <Grid container direction="column" justify="flex-end">
                  <div className={classes.audioControlsContainer}>
                    <ArtistNameTitleSection
                      displayMode={displayMode}
                      trackIndex={trackIndex}
                      trackListModal={trackListModal}
                      playerOpacity={playerOpacity}
                      playerColor={playerColor}
                      playerIsMobile={playerIsMobile()}
                    />
                    <ArtistMusicPlayerSection
                      setPlayStatus={setPlayStatus}
                      playStatus={playStatus}
                      trackIndex={trackIndex}
                      setTrackIndex={setTrackIndex}
                      handleClickNext={handleClickNext}
                      handleClickPrevious={handleClickPrevious}
                      playerColor={playerColor}
                      playerOpacity={playerOpacity}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ArtistLinkMusicPlayer;
