import React from 'react';
import Composition from '../models/Composition';
import { BlockParam, Size } from '../models/Block';

interface CompositionRendererProps {
  composition?: Composition;
  size?: Size;
  ref?: React.MutableRefObject<any>;
  onReadyToExport?: any
  freezeOnRender?: boolean
}

interface ParamViewProps {
  blockParam: BlockParam;
  blockKey: string;
}

function ParamView({ blockKey, blockParam }: ParamViewProps) {
  return (
    <div>
      {`${blockKey}: ${blockParam.name} : ${blockParam.value}`}
    </div>
  );
}



function CompositionRenderer({ composition }: CompositionRendererProps) {
  return (
    <div>
      { composition?.blocks.map((block) => (
        <div>Type: {block.type} : Hydrated Params:
          {block.params && Object.entries(block.params).map(entry => {
            return <ParamView blockKey={entry[0] as string} blockParam={entry[1] as BlockParam} />;
          })}
        </div>))}
    </div>
  );
}

export { CompositionRendererProps };
export default CompositionRenderer;
