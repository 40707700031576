import {
  useQuery,
  UseQueryResult,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "react-query";
import { UserRole } from "../ResourceSchema";
import RockDove from "../RockDove";
import { SortOption } from "../types/SharedTypes";

let BASE_PATH = `/api/v1/artists`;

const fetchFn = async (endpoint: string, headers: any = undefined) => {
  const authHeaders = await RockDove.shared().getHeaders(UserRole.Artist);

  return fetch(endpoint, {
    method: "GET",
    credentials: "include",
    mode: "cors",
    headers: { ...authHeaders, ...headers },
  })
    .then((res) => res.json())
    .catch((e) => console.error("😡 CATCH", { e }));
};
type fetchInfiniteFnArgs = {
  endpoint: string;
  pageParam: number;
  headers: any;
};
const mutationFn = async (
  method: string,
  artistId: string,
  endpoint: string,
  id?: string
) => {
  const url = `${BASE_PATH}/${artistId}/${endpoint}/${id}`;
  const authHeaders = await RockDove.shared().getHeaders(UserRole.Artist);
  const headers: any = {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    credentials: "include", // include, *same-origin, omit
    headers: {
      ...authHeaders,
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
  };
  return fetch(url, headers);
};

const getPaginated = <T>(
  endpoint: string,
  artistId: string,
  perPage: number,
  page: number,
  sort: SortOption
): UseQueryResult<T> => {
  const url = `${BASE_PATH}/${artistId}/${endpoint}?perPage=${perPage}&page=${page}&sortAttr=${sort.attr}&sortOrder=${sort.order}`;

  return useQuery([endpoint, page], () => fetchFn(url), {
    keepPreviousData: true,
  });
};

const fetchInfiniteFn = async ({
  endpoint,
  pageParam,
  headers = undefined,
}: fetchInfiniteFnArgs) => {
  const authHeaders = await RockDove.shared().getHeaders(UserRole.Artist);
  const formedEndpoint = `${endpoint}&page=${pageParam}`;
  return fetch(formedEndpoint, {
    method: "GET",
    credentials: "include",
    mode: "cors",
    headers: { ...authHeaders, ...headers },
  })
    .then((res) => res.json())
    .catch((e) => console.error("😡 CATCH", { e }));
};

const getInfinitScroll = <T>(
  endpoint: string,
  artistId: string,
  perPage: number,
  sort: SortOption
): UseInfiniteQueryResult<T> => {
  const formedUrl = `${BASE_PATH}/${artistId}/${endpoint}?perPage=${perPage}&sortAttr=${sort.attr}&sortOrder=${sort.order}`;

  return useInfiniteQuery(
    ["infiniteIndex"],
    ({ pageParam = 1 }) =>
      fetchInfiniteFn({ endpoint: formedUrl, pageParam, headers: undefined }),
    {
      getPreviousPageParam: (firstPage) => {
        return firstPage.pagination.currentPage - 1 ?? undefined;
      },
      getNextPageParam: (lastPage) => {
        return lastPage.pagination.currentPage + 1 ?? undefined;
      },
    }
  );
};

export { getPaginated, mutationFn, getInfinitScroll };
