
enum BlockType {
  button = 'button',
  iframe = 'url',
  image = 'image',
  video = 'video',
  phoneVideo = 'phone-video',
  carousel = 'carousel'
};

function labelsForBlockType(type: BlockType) {
  switch (type) {
    case BlockType.button:
      return { title: "Insert Buttons", create: "ADD BUTTON(S)", update: "SAVE BUTTON(s)" };
    case BlockType.iframe:
      return { title: "Insert Iframe", create: "ADD IFRAME", update: "SAVE IFRAME" };
    case BlockType.carousel:
      return { title: "Insert Carousel", create: "ADD Carousel", update: "SAVE Carousel" };
    case BlockType.phoneVideo:
      return { title: "Insert Video", create: "ADD Video", update: "SAVE Video" };
    case BlockType.image:
      return { title: "Insert Image", create: "ADD Image", update: "SAVE Image" };
    case BlockType.video:
      return { title: "Insert Video", create: "ADD Video", update: "SAVE Video" };
    default:
      return { title: "Insert Item", create: "ADD ITEM", update: "SAVE ITEM" }
  }
}

export default BlockType;
export { labelsForBlockType }


