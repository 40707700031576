import { Grid, makeStyles, withWidth } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Asset } from "../../link-builder/models/ArtistLink";
import { usePromoPackContext } from "../providers/PromoPackProvider";
import { ReactComponent as DownloadIcon } from "../../shared/icons/download.svg";
import ActionBar from "../../shared/ActionBar";
import { Link, useNavigate } from "react-router-dom";
import PromoPack from "../models/PromoPack";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { isWidthUp } from "@material-ui/core";
import axios from "axios";
import RockDove from "../../shared/RockDove";
import { UserRole } from "../../shared/ResourceSchema";

type Props = {
  width: Breakpoint;
};
type DownloadStyleProps = {
  width: Breakpoint;
};

const useDownloadStyles = makeStyles(() => ({
  assetMargin: {
    margin: "10px 0",
  },
  downloadContainer: {
    columnCount: (props: DownloadStyleProps) =>
      isWidthUp("sm", props.width) ? 2 : 1,
  },
}));

const useAssetActionsStyles = makeStyles(() => ({
  button: {
    background: "none",
    border: "none",
    padding: 0,
    height: "22px",
    margin: "6px",
    cursor: "pointer",
    display: "contents",
    color: "white",
  },
  overlay: {
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0.89) 0%, rgba(0, 0, 0, 0) 65%)",
    position: "absolute",
    zIndex: 3,
    width: "calc(100% - 26px)",
    height: "50px",
    display: "flex",
    fontFamily: "Futura",
    fontSize: "12px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#ffffff",
    padding: "13px",
  },
  wrapper: {
    width: "fit-content",
    position: "relative",
  },
}));

export async function zipAssets(setLoading: any, promoPack: PromoPack) {
  // lets just bail if there are no assets
  if (!promoPack.promoAssets) return;

  setLoading(true);

  RockDove.shared()
    .getHeaders(UserRole.Artist)
    .then(async (headers: any) => {
      let results = await axios({
        url: `/api/v1/artists/${promoPack.artist.p4aArtistId}/promo-packs/${promoPack.id}/download-assets-zip`,
        method: "GET",
        responseType: "blob",
        headers,
      });

      let hidden_a = document.createElement("a");
      hidden_a.href = window.URL.createObjectURL(new Blob([results.data]));
      hidden_a.setAttribute("download", `${promoPack.name}.zip`);
      document.body.appendChild(hidden_a);
      hidden_a.click();

      setLoading(false);
    });
}

const Download = ({ width }: Props) => {
  const navigate = useNavigate();
  const { promoPack, loading } = usePromoPackContext();
  const classes = useDownloadStyles({ width: width });
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    // check for required data, and redirect back as needed
    if (!loading && !promoPack?.p4aReleaseId) navigate("../release");
    if (!loading && !promoPack?.templateIdentifier) navigate("../template");
    if (!loading && !promoPack?.promoAssets) navigate("../customize");
  }, [promoPack, loading]);

  const assets = promoPack?.promoAssets?.map((asset, index) => (
    <div key={index} className={classes.assetMargin}>
      <AssetActions asset={asset} key={asset.attachment} />
    </div>
  ));

  if (promoPack?.promoAssets?.length < 1)
    return (
      <div style={{ color: "#fff" }}>
        There are no assets to be displayed. Please visit the{" "}
        <Link style={{ color: "#fff" }} to="../customize">
          Customize
        </Link>{" "}
        step and hit save.{" "}
      </div>
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.downloadContainer}>{assets}</div>
      </Grid>

      <Grid item xs={12}>
        <ActionBar
          position="bottom"
          buttons={[
            {
              text: "Edit",
              group: "left",
              action: () => navigate("../customize"),
              buttonProps: {
                variant: "outlined",
              },
            },
            {
              text: downloading ? "Downloading" : "Download All",
              group: "right",
              action: () => zipAssets(setDownloading, promoPack),
              buttonProps: {
                variant: "outlined",
                disabled: downloading,
              },
            },
            {
              text: "Exit",
              action: () => navigate("./../.."),
              group: "right",
              buttonProps: {
                variant: "contained",
                color: "secondary",
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default withWidth()(Download);

type AssetProps = {
  asset: Asset;
};

const AssetActions = ({ asset }: AssetProps) => {
  const classes = useAssetActionsStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.overlay}>
        <div style={{ flex: 1 }}>{asset.description}</div>
        <a
          className={classes.button}
          aria-label="download asset"
          href={asset.downloadUrl}
        >
          <DownloadIcon />
        </a>
      </div>
      <img
        key={asset.attachment}
        src={asset.derivatives?.medium || asset.attachment}
        style={{ maxWidth: "100%" }}
      />
    </div>
  );
};
