import React, { useContext } from "react";
import { makeStyles, Theme, Divider } from "@material-ui/core";
import { PlatoonContext, HoverActions } from "../PlatoonContext";
import ButtonBlockRenderer from "./buttons/ButtonBlockRenderer";
import IframeBlockRenderer from "./iframe/IframeBlockRenderer";
import ImageBlockRenderer from "./image/ImageBlockRenderer";
import VideoBlockRenderer from "./video/VideoBlockRenderer";
import PhoneVideoBlockRenderer from "./video/PhoneVideoBlockRenderer";
import CarouselBlockRenderer from "./video/CarouselBlockRenderer";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    color: "white",
    borderBottom: "solid 1px #666666",
    height: "1px",
    margin: "auto",
    [theme.breakpoints.down(500)]: {
      marginTop: "50px",
      marginBottom: "50px",
    },
  },
  assetCentered: {
    marginTop: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    maxWidth: "100%",
  },
  asset: {
    margin: "auto",
    maxWidth: "580px",
    maxHeight: "450px",
    width: "auto",
    height: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    [theme.breakpoints.down(680)]: {
      maxWidth: "400px",
      height: "230px",
    },
    [theme.breakpoints.down(650)]: {
      maxWidth: "350px",
      height: "200px",
    },
    [theme.breakpoints.down(520)]: {
      maxWidth: "300px",
      height: "170px",
    },
  },
}));

export const MediaBlockRenderer = (props: any) => {
  const classes = useStyles();

  // TODO: here we get the asset that is saved in raw_content in db.  we will need to pull the asset from our db, instead of using it's attachment
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));

  const { setBlockEditSession, deleteBlockWithKey } =
    useContext(PlatoonContext);
  const { src } = entity.getData();

  const type = entity.getType();

  const startBlockEditSession = () => {
    // Make a deep copy of the entity data source so we can track changes to the block data
    let ogSrc = JSON.parse(JSON.stringify(src));
    let ogSrc2 = JSON.parse(JSON.stringify(src));
    // Create a session to hand to the context
    let session = {
      blockType: type,
      blockData: ogSrc,
      originalBlockData: ogSrc2,
      entityKey: props.block.getEntityAt(0),
    };
    setBlockEditSession(session);
  };

  const deleteBlock = () => {
    let key = props.block.getKey(0);
    if (key) {
      deleteBlockWithKey(key);
    }
  };

  const hoverActions = {
    editBlock: startBlockEditSession,
    deleteBlock: deleteBlock,
  } as HoverActions;

  const DividerRenderer = () => {
    return <Divider classes={{ root: classes.divider }} />;
  };

  let renderer;
  switch (type) {
    case "divider":
      return DividerRenderer();
    case "image":
      return (renderer = (
        <ImageBlockRenderer
          assetBlock={src}
          styles={classes}
          hoverActions={hoverActions}
        />
      ));
    case "button":
      return (renderer = (
        <ButtonBlockRenderer
          buttonBlock={src}
          classes={classes}
          hoverActions={hoverActions}
        />
      ));
    case "video":
      return (renderer = (
        <VideoBlockRenderer
          assetBlock={src}
          hoverActions={hoverActions}
          styles={classes}
        />
      ));
    case "carousel":
      return (renderer = (
        <CarouselBlockRenderer
          assetCollectionBlock={src}
          hoverActions={hoverActions}
        />
      ));
    case "url":
    case "code":
      return (renderer = (
        <IframeBlockRenderer iframeBlock={src} hoverActions={hoverActions} />
      ));
    case "phone-video":
      return (renderer = (
        <PhoneVideoBlockRenderer assetBlock={src} hoverActions={hoverActions} />
      ));
  }

  return renderer;
};
