import { makeStyles } from "@material-ui/core";
import React, { ReactChild } from "react";
import { useMeasure } from "react-use";
import { TemplateVariant } from "../../../asset-generator-lib";
import { returnLowestScale } from "../../shared/helpers/helpers";
import { ReactComponent as XMarkCircleBold } from "../../shared/icons/xmark-circle-bold.svg";
import Overlay from "../../shared/components/Overlay/Overlay";

type Props = {
  children?: ReactChild;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentVariant: TemplateVariant;
};
const useStyles = makeStyles(() => ({
  close: {
    position: "absolute",
    top: 14,
    left: 14,
    color: "white",
    fill: "white",
    height: "60px",
    width: "60px",
    border: 0,
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  previewContainer: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "zoom-out",
  },
}));
const ZoomedPreview = ({ children, setOpen, currentVariant }: Props) => {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>(); // type argument for what kind of ref you're using here
  const classes = useStyles();

  return (
    <Overlay setOpen={setOpen}>
      <>
        <button className={classes.close}>
          <XMarkCircleBold />
        </button>
        <div ref={ref} className={classes.previewContainer} style={{}}>
          <div
            style={{
              transform: `scale(${returnLowestScale(
                currentVariant?.size,
                width,
                height
              )})`,
            }}
          >
            {children}
          </div>
        </div>
      </>
    </Overlay>
  );
};

export default ZoomedPreview;
