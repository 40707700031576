import React, { ReactNode, useState, useEffect } from "react";
import Frame from "react-frame-component";

type Props = {
  children: ReactNode;
};

const FrameSizeWrapper = ({ children }: Props) => {
  const [height, setHeight] = useState(0);

  // TODO: any - I've tried HTMLElement and HTMLIframeElement, neither allow we what we need to do.
  const iframeRef = React.useRef<any>(null);

  // // if we don't set this, it reads the ref too soon, and then is always 1 click behind
  const delayedHandleResize = () => {
    setTimeout(handleResize, 100);
  };

  const handleResize = () => {
    // using last child's scroll height because body doesnt shrink back down
    const scrollHeight =
      iframeRef.current?.contentDocument?.body?.lastChild?.scrollHeight;
    if (scrollHeight != height) setHeight(scrollHeight);
  };

  /*
    We need to grab the head contents, which is an HTML collection,
    filter out any elements we don't want (in this case, iframe),
    then convert them to react elements
  */
  //@ts-ignore
  const headContents = [...document.head?.children]
    .filter((item) => item.localName !== "iframe")
    .map((item, index) => {
      // the attributes come in an array, we need to convert that into an object
      // witht the localName as the key and the value as the value

      const attributesObj = [...item.attributes].reduce(
        (accumulator, currentAttribute) => {
          return {
            ...accumulator,
            [currentAttribute.localName]: currentAttribute.value,
          };
        },
        {}
      );

      // create the react element, passing along the tag type, key, attributes
      // and if it's has any innerHTML, the innerHTML
      const element = React.createElement(item.localName, {
        key: index,
        ...attributesObj,
        ...(item.innerHTML && {
          dangerouslySetInnerHTML: { __html: item.innerHTML },
        }),
      });
      return element;
    });

  // gotta grab our css variables that we want to send forward into the iFrame
  // unfortunately there isn't a built in method to grab all at once

  const variables = [
    "--bodyColor",
    "--bodyFont",
    "--headlineColor",
    "--headlineFont",
  ];
  //@ts-ignore
  const hydratedVariables = variables.map(
    (variable: string) =>
      `${variable}: ${document.documentElement.style.getPropertyValue(
        variable
      )}`
  );

  const cssVariables = (
    <style type="text/css">
      {`:root{
         ${hydratedVariables.join(";")};
        }`}
      {`body{
          overflow: hidden
        }`}
    </style>
  );

  useEffect(() => delayedHandleResize(), [delayedHandleResize, iframeRef]);

  // using inline styles because of the dynamic height we are setting for the iframe.
  return (
    <Frame
      style={{
        height, // uses the hook that measures the content inside the frame
        width: "100%",
        overflow: "hidden",
        border: "none",
      }}
      id="frame-size-wrapper"
      head={[...headContents, cssVariables]}
      ref={iframeRef}
      onLoad={delayedHandleResize}
      onClick={delayedHandleResize}
      scrolling="yes" // allows for scrolling within frame. Enabled by default to support small preview windows.
    >
      {children}
    </Frame>
  );
};

export default FrameSizeWrapper;
