import React from "react";
import Modal from "../Modal/Modal";
import styles from "./DeleteConfirmation.module.scss";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: () => void;
};

const DeleteConfirmation = ({ handleDelete, setOpen }: Props) => {
  return (
    <Modal setOpen={setOpen}>
      <header className={styles["header"]}>
        <h1>confirm</h1>
      </header>
      <main className={styles["main"]}>
        <h2>Are you sure you want to delete?</h2>
        <div className={styles["delete-actions"]}>
          <button
            onClick={() => setOpen(false)}
            className={`btn ${styles["btn-cancel"]}`}
          >
            cancel
          </button>
          <button className="btn" onClick={handleDelete}>
            confirm
          </button>
        </div>
      </main>
    </Modal>
  );
};

export default DeleteConfirmation;
