import React, { ReactNode } from "react";
import { DraggableProps } from "react-beautiful-dnd";
import DragHandle from "../FileUploadComponents/DragHandle/DragHandle";
import FileDetails from "./MultiPillDetails/MultiPillDetails";
import RemoveFile from "../FileUploadComponents/RemoveFile/RemoveFile";
import styles from "./MultiPill.module.scss";

type MultiFile =
  | {
      index?: number;
      dragProps?: DraggableProps;
      selected?: boolean | undefined;
      prePend?: never;
    }
  | {
      index?: number;
      dragProps?: never;
      selected?: boolean | undefined;
      prePend: ReactNode;
    };

type MultiPillProps =
  | {
      remove: any; // TODO
      multiFile?: MultiFile;
      children?: never;
      text: string;
    }
  | {
      remove: any; // TODO
      multiFile?: MultiFile;
      children: ReactNode;
      text?: never;
    };

const MultiPill = ({ children, text, multiFile, remove }: MultiPillProps) => {
  const cssVars = {
    "--bgColor": multiFile?.selected ? "#333" : "transparent",
  } as React.CSSProperties;

  return (
    <div style={cssVars} className={`${styles["container"]}`}>
      {multiFile?.dragProps && multiFile?.index !== undefined && (
        <DragHandle dragProps={multiFile?.dragProps} />
      )}
      {multiFile?.prePend}
      {children ? children : <FileDetails text={text} />}
      <RemoveFile removeFn={remove} />
    </div>
  );
};

export default MultiPill;
