import React from "react";
import {
  Artist,
  TemplatePage,
  TemplatePageDraft,
} from "../../types/TemplatePages";
import TemplatePageComponentRenderer from "../TemplatePageComponentRenderer/TemplatePageComponentRenderer";
import styles from "./TemplatePageRenderer.module.scss";
import { useTemplatePageEditorContext } from "../TemplatePageEditor/TemplatePageEditor";
import { useElementBoundingClientRect } from "../../../shared/CustomHooks";
import clone from "just-clone";
import TemplatePageFooter from "../TemplatePageFooter/TemplatePageFooter";

type Props = {
  templatePage: TemplatePageDraft | TemplatePage; // used both in the editor, preview and published apps
  assembleArtist: Artist;
  editable?: boolean;
};

// similar pattern to the form
const TemplatePageRenderer = ({
  templatePage,
  editable,
  assembleArtist,
}: Props) => {
  const { highlightedKey } = useTemplatePageEditorContext();

  // set global css props for page design stuff

  const pageDesign = templatePage.templateConfig.find(
    (block) => block.key === "PAGEDESIGN"
  );

  let root = document.documentElement;
  const bodyColor = pageDesign?.params.bodyColor?.value;
  const bodyFont = pageDesign?.params.bodyFont?.value?.value;
  const headlineColor = pageDesign?.params.headlineColor?.value;
  const headlineFont = pageDesign?.params.headlineFont?.value?.value;

  root.style.setProperty("--bodyColor", bodyColor);
  root.style.setProperty("--bodyFont", bodyFont);
  root.style.setProperty("--headlineColor", headlineColor);
  root.style.setProperty("--headlineFont", headlineFont);

  const headerSize = useElementBoundingClientRect("preview-header");

  const templatePageClone = clone(templatePage);

  const visibleSections = templatePageClone.templateConfig.filter((section) => {
    if (section["hidden"]) {
      return section.hidden !== true;
    } else return section;
  });

  const sections = visibleSections?.map((section) => {
    return (
      <TemplatePageComponentRenderer
        key={section.key}
        convertedBlock={section}
        highlight={section.key === highlightedKey}
        editable={editable}
        assembleArtist={assembleArtist}
      />
    );
  });

  return (
    <div
      className={styles["sectionsContainer"]}
      style={{ marginTop: `${headerSize?.height}px` }}
    >
      {sections}
      <TemplatePageFooter />
    </div>
  );
};

export default TemplatePageRenderer;
