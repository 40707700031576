import React from "react";
import { Logger } from "../shared/SafeLogger";

import { UserRole } from "../shared/ResourceSchema";
import ItemIndex, { ItemRenderProps } from "../shared/ItemIndex";
import ArtistSchema from "./ArtistSchema";

import { Typography } from "@material-ui/core";
import { defaultActions } from "../shared/ResourceSchema";

function ArtistLinkContent(props: ItemRenderProps) {
  const linkInfo = props.item[props.attr];
  return (
    <Typography
      style={{ color: "#DDD" }}
    >{ linkInfo }</Typography>
  );
}
const renderers = { linkInfo: ArtistLinkContent };

const ArtistIndex = () => {
  var schema = { ...ArtistSchema };
  schema.role = UserRole.Admin;

  let actions = { ...defaultActions(), ...{ edit: true } };
  Logger.of("App").info(actions);
  return <ItemIndex schema={schema} renderers={renderers} />;
};

export default ArtistIndex;
