import React, {
  useEffect,
  useReducer,
  useState,
  useContext,
  SetStateAction,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTemplate } from "../../queries/useTemplates";
import TemplatePageEditorForm from "../TemplatePageEditorForm/TemplatePageEditorForm";
import TemplatePageRenderer from "../TemplatePageRenderer/TemplatePageRenderer";
import TemplatePagePreviewFrame from "../TemplatePagePreviewFrame/TemplatePagePreviewFrame";
import styles from "./TemplatePageEditor.module.scss";
import useWindowSize, {
  useDebounceCloner,
  useScrollToView,
} from "../../../shared/CustomHooks";
import {
  Action,
  defaultTemplatePage,
  templatePageReducerFn,
} from "../../helpers/templatePageReducer";
import { blockConfigFromBlock } from "../../helpers/templateConfigHelpers";
import clone from "just-clone";
import {
  ConvertedBlock,
  Sizes,
  TemplatePageSection,
} from "../../../../asset-generator-lib/composer/models/Block";
import { useMutation, useQueryClient } from "react-query";
import {
  createTemplatePageFn,
  getTemplatePage,
  updateTemplatePageFn,
} from "../../queries/useTemplatePages";
import { Template } from "../../types/Templates";
import {
  MappedData,
  TemplatePage,
  TemplatePageAdaptor,
  TemplatePageDraft,
} from "../../types/TemplatePages";
import Loading from "../../../shared/Loading";
import { GenericObj } from "../inputs/helpers";
import { usePlatoonArtistContext } from "../../../providers/PlatoonArtistProvider";
import { usePlatoonReleaseContext } from "../../../providers/PlatoonReleaseProvider";
import {
  getLatestDraft,
  isLiveAndPublished,
  templatePageExternalLinkURL,
} from "../../../shared/helpers/helpers";
import { getBreakpoint } from "../../../shared/helpers/breakpoint";

import {
  useValidationsContext,
  Validation,
} from "../../../providers/ValidationsProvider";
import {
  launchDateTemplateConfig,
  siteNameTemplateConfig,
  sitePasswordTemplateConfig,
} from "../StaticTemplateSections/StaticTemplateSections";
import AnimateTemplatePageEditor from "../AnimateTemplatePageEditor/AnimateTemplatePageEditor";
import TemplatePageEditorDesktopActionBar from "../TemplatePageEditorDesktopActionBar/TemplatePageEditorDesktopActionBar";
import TemplatePageEditorMobileActionBar from "../TemplatePageEditorMobileActionBar/TemplatePageEditorMobileActionBar";
import { useSnackContext } from "../../../shared/SnackProvider";
/*
  import isEqual from "react-fast-compare";

  we were using this for all object deep comparing - but it was failing when choosing
  a release in the "submitted" state, so I installed lodash.isequal and only swapped
  in this one location.  SHOULD be able to swap react-fast-compare with lodash.isqual
  everywhere, but didnt want to open that can of worms as we wrap up this SOW

 */
import isEqual from "lodash.isequal";

// // new EPK template config
// import { artistEpkTemplate } from "../../helpers/artistEpkTemplate";
// const artistEpkTemplateJsonString = JSON.stringify(artistEpkTemplate);
// console.log("🔥🔥 ", { artistEpkTemplate }, { artistEpkTemplateJsonString });

// sandbox template, have fun!
// import { sandboxTemplate } from "../../helpers/sandboxTemplate";
// const sandboxTemplateJsonString = JSON.stringify(sandboxTemplate);
// console.log("🔥🔥 ", { sandboxTemplate }, { sandboxTemplateJsonString });

type CreateMutationProps = {
  artistId: string;
  templatePage: TemplatePage;
  template: Template;
};

export type UpdateMutationProps = {
  artistId: string;
  id: string;
  templatePage: TemplatePage;
};

type TemplatePageEditorContextProps = {
  highlightedKey: string | null;
  setHighlightedKey: React.Dispatch<React.SetStateAction<string | null>>;
  templatePage: TemplatePage;
  dispatchTemplatePage: React.Dispatch<Action>;
  formTouched: boolean;
  setFormTouched: React.Dispatch<SetStateAction<boolean>>;
  currentBreakpoint: keyof Sizes<number>;
};

export const TemplatePageEditorContext = React.createContext(
  {} as TemplatePageEditorContextProps
);

export const useTemplatePageEditorContext = () =>
  useContext(TemplatePageEditorContext)!;

// COMPONENT
const TemplatePageEditor = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { width } = useWindowSize();
  const [showPreview, setShowPreview] = useState(false);
  const desktopView = width > 1048; //same as scss $tablet-breakpoint

  const currentBreakpoint = getBreakpoint(width);

  const {
    isValidAt,
    createValidationSchema,
    setOpenInvalidSections,
    validateAtKey,
  } = useValidationsContext();

  const { p4aArtist } = usePlatoonArtistContext();
  const { release, fetchReleases, releases } = usePlatoonReleaseContext();
  const { setSnack } = useSnackContext();

  const { templateSlug, templatePageId, artistId } = useParams();
  const [formTouched, setFormTouched] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [clickedPublishOrUpdate, setClickedPublishOrUpdate] = useState<
    "UPDATE" | "PUBLISH"
  >();

  const defaultDebounceTime = 500;
  const [debounceTime, setDebounceTime] = useState(defaultDebounceTime);

  const [isExistingTemplatePage] = useState(templatePageId !== "new");
  const [highlightedKey, setHighlightedKey] = useState<string | null>(null);

  const [templatePage, dispatchTemplatePage] = useReducer(
    templatePageReducerFn,
    defaultTemplatePage
  );

  // get latest draft
  const templatePageDraft = getLatestDraft(templatePage?.drafts);

  const context: TemplatePageEditorContextProps = {
    highlightedKey,
    setHighlightedKey,
    templatePage,
    dispatchTemplatePage,
    setFormTouched,
    formTouched,
    currentBreakpoint,
  };
  // because the debouce was just using the obj we passed it, it was updating the debounce and the value simultaneously
  // i made a version that clones the value before setting the value in the custom hook.
  const debouncedTemplatePage = useDebounceCloner(templatePage, debounceTime);

  // fetch template, and can we async a second fetch?
  const { isLoading, data: template } = getTemplate(
    templateSlug,
    !isExistingTemplatePage
  );

  // this is refetching after a publish
  const { data: templatePageRecord } = getTemplatePage(
    artistId,
    templatePageId,
    isExistingTemplatePage
  );

  // Mapped Data funcs
  const grabSource = (source: MappedData["source"]) => {
    switch (source) {
      case "artist":
        return p4aArtist;
      case "release":
        return release;
      case "shareableReleases":
        return releases?.filter((release) => {
          return release.shareableLink;
        });
      default:
        console.error("grabSource doesnt know where to get:", source);
        return null;
    }
  };

  const assembleMappedData = (mappings: MappedData[]) => {
    // if we've got nothing to work with, just return undefined
    if (!mappings) return undefined;

    const mappedData: GenericObj<any> = {};
    mappings?.forEach((data) => {
      // had to type as any, because of keys below
      // tried doing keyof, but couldnt get it to go
      const allData: any = grabSource(data?.source);
      if (data?.keys) {
        let refinedData: any = {};
        Object.keys(data?.keys)?.forEach((key) => {
          refinedData[key] = allData[key];
        });
        mappedData[data?.source] = refinedData;
      } else {
        mappedData[data?.source] = allData;
      }
    });
    return mappedData;
  };

  // MUTATIONS
  const createMutation = useMutation({
    mutationFn: ({ artistId, templatePage, template }: CreateMutationProps) => {
      // do some manual stuff

      const manualUpdates = {
        name:
          templatePageDraft.name === "" || templatePageDraft.name === undefined
            ? `${p4aArtist.name} ${template.name}`
            : templatePageDraft.name,
        templateVersionId: template.newestTemplateVersion.id,
        templateType: template.type, // can we do this when we initilize the template page??
        templateSlug: template.slug, // can we do this when we initilize the template page??
      };

      // add those updates to the draft
      const drafts = clone(templatePage.drafts);
      drafts[0] = { ...drafts[0], ...manualUpdates };

      // and to the templatePage
      const creationTemplatePage: TemplatePage = {
        ...templatePage,
        ...manualUpdates,
        drafts,
      };

      setDebounceTime(0);
      dispatchTemplatePage({
        type: "UPDATE_DRAFT",
        payload: { ...templatePageDraft, ...manualUpdates },
      });

      return createTemplatePageFn(artistId, creationTemplatePage);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries("template-pages");
      let parsedBody = await data.json();

      const responseTemplatePage = TemplatePageAdaptor.toFrontend(parsedBody);
      // let's put stuff back into the draft that we get from ther server,
      // so when we compare after dispatching, everything is gucci
      const ammendedResponse: TemplatePage = {
        ...responseTemplatePage,
      };

      ammendedResponse.drafts[0] = {
        ...ammendedResponse.drafts[0],
        id: parsedBody.id,
        artist: parsedBody.artist,
        slug: parsedBody.slug,
        // name: parsedBody.name, // ??
        // password: parsedBody.password, // TODO: Check this
      };

      // we set the debounceTime to 0, so we don't set the form to a dirty state with these results
      setDebounceTime(0);
      dispatchTemplatePage({
        type: "RESPONSE_UPDATE",
        payload: ammendedResponse,
      });

      navigate(`./../../${parsedBody.id}/edit`);
    },
    onError: async (error) => {
      console.error("An error occurred trying to create a template page", {
        error,
      });
    },
  });

  const updateMutation = useMutation({
    mutationFn: ({ artistId, templatePage, id }: UpdateMutationProps) => {
      // We've made a design change here, in that EVERY bit of data moving we do should be
      // done in the reducer, and an update just sends state up to the db
      return updateTemplatePageFn(artistId, templatePage, id);
    },
    onSuccess: async (data) => {
      let parsedBody = await data.json();
      const responseTemplatePage = TemplatePageAdaptor.toFrontend(parsedBody);
      queryClient.invalidateQueries("template-pages");
      queryClient.invalidateQueries(["template_page", responseTemplatePage.id]);

      setDebounceTime(0);
      dispatchTemplatePage({
        type: "RESPONSE_UPDATE",
        payload: responseTemplatePage,
      });

      if (clickedPublishOrUpdate === "PUBLISH") {
        // redirect if publishing (but not updating)
        navigate("../");
      } else if (clickedPublishOrUpdate === "UPDATE") {
        setSnack({
          message: isLiveAndPublished(
            responseTemplatePage.startDate,
            responseTemplatePage.endDate,
            responseTemplatePage.published
          ) ? (
            <>
              Page updated.{" "}
              <a
                className={styles["link"]}
                target="_new"
                href={templatePageExternalLinkURL(
                  templatePage?.templateSlug,
                  responseTemplatePage?.slug
                )}
              >
                View Page
              </a>
            </>
          ) : (
            "Scheduled page updated."
          ),
        });
        setClickedPublishOrUpdate(undefined);
      }
    },
    onError: async (error) => {
      console.error("An error occurred trying to create a template page", {
        error,
      });
    },
  });

  // fetch releases
  useEffect(() => {
    if (artistId) {
      fetchReleases(artistId);
    }
  }, [artistId]);

  // loading up an existing templatePage
  useEffect(() => {
    if (templatePageRecord && templatePageRecord.drafts) {
      if (isEqual(templatePageRecord, templatePage)) {
        // console.warn("we already have a matching template loaded, exiting");
        return;
      }
      setDebounceTime(0); // don't set the form to dirty

      if (isEqual(templatePage.templateConfig, [])) {
        dispatchTemplatePage({
          type: "SET_TEMPLATEPAGE",
          payload: clone(templatePageRecord),
        });
      }
    }
  }, [templatePageRecord]);

  // is dirty UE
  useEffect(() => {
    setIsDirty(!isEqual(debouncedTemplatePage, templatePage));
  }, [debouncedTemplatePage, templatePage]);

  //builds the schema if the length of the config changes
  useEffect(() => {
    createValidationSchema([
      ...templatePageDraft.templateConfig,
      sitePasswordTemplateConfig(templatePageDraft),
      launchDateTemplateConfig(templatePageDraft),
      siteNameTemplateConfig(templatePageDraft),
    ]);
  }, [
    templatePageDraft.templateConfig.length,
    templatePageDraft.startDate,
    templatePageDraft.endDate,
    templatePageDraft.name,
  ]);

  useEffect(() => {
    // if the form has been touched,
    // and there is a change to template page,
    // create or update

    /*
      we are now only using form touch for the initial page load.
      once it's clicked, its forever clicked.

      we keep things from re-updating by changing debounce time length
    */

    if (
      debounceTime != 0 &&
      templatePageId === "new" &&
      formTouched &&
      artistId &&
      template
    ) {
      // we need to stop this from running again
      createMutation.mutate({
        artistId,
        templatePage: debouncedTemplatePage,
        template: template,
      });
    } else if (
      debounceTime != 0 &&
      formTouched &&
      artistId &&
      templatePageId &&
      !isEqual(debouncedTemplatePage, templatePageRecord)
    ) {
      updateMutation.mutate({
        artistId,
        templatePage: debouncedTemplatePage,
        id: templatePageId,
      });
    }
    // if the debounceTime is 0, set it back to the defaultconst defaultDebounceTime = 3000
    debounceTime === 0 && setDebounceTime(defaultDebounceTime);
  }, [debouncedTemplatePage]);

  // loading a template, and creating our new template page state

  useEffect(() => {
    if (!template) return;

    if (!isExistingTemplatePage) {
      // get newest template version from array
      const newestTemplateVersion = template.newestTemplateVersion;

      // build the sections first,
      // then do the blockConfigfromBlock inside of those objects
      const sections = mapOverSections(newestTemplateVersion.blocks);

      const mappedData: unknown =
        newestTemplateVersion?.mappedData &&
        assembleMappedData(newestTemplateVersion?.mappedData);

      setDebounceTime(0); // don't set the form to dirty

      dispatchTemplatePage({
        type: "UPDATE_DRAFT",
        payload: {
          templateConfig: sections,
          mappedData: mappedData,
        } as TemplatePageDraft,
      });
    }
  }, [template]);

  useEffect(() => {
    // if we have our draft and dependencies within our system
    // we will update mappedData with said depencies once its available
    if (templatePageDraft && releases) {
      let updatedMappedData: any = {};
      templatePageDraft.mappedData &&
        Object.keys(templatePageDraft.mappedData).forEach((data: any) => {
          if (data.includes("Releases" || "releases")) {
            updatedMappedData[data] = grabSource(data);
          } else {
            updatedMappedData[data] = templatePageDraft.mappedData[data];
          }
        });

      //don't dispatch if mappedData is equal
      if (!isEqual(templatePageDraft.mappedData, updatedMappedData)) {
        dispatchTemplatePage({
          type: "UPDATE_DRAFT",
          payload: {
            ...templatePageDraft,
            templateConfig: templatePageDraft.templateConfig,
            mappedData: updatedMappedData,
          } as TemplatePageDraft,
        });
      }
    }
  }, [templatePageDraft, releases]);

  const mapOverSections = (templateBlocks: TemplatePageSection[]) => {
    const convertedBlocks = templateBlocks.map((block) => {
      // we are converting the array of blocks within a section into a
      // key'd object here

      let final = clone(block) as any;
      final.params = blockConfigFromBlock(block);

      return final as ConvertedBlock;
    });

    return convertedBlocks;
  };

  const handlePublish = async (alreadyPublished: boolean) => {
    !formTouched && setFormTouched(true);
    setClickedPublishOrUpdate(alreadyPublished ? "UPDATE" : "PUBLISH");

    let requiredFields: Validation[] = [];

    //order matter!!!! for scroll to error func
    const sections = [
      siteNameTemplateConfig(templatePageDraft),
      ...templatePageDraft.templateConfig,
      launchDateTemplateConfig(templatePageDraft),
      sitePasswordTemplateConfig(templatePageDraft),
    ];

    const requiredSections = sections.filter((section) => {
      return section.required;
    });

    requiredSections.forEach((section) => {
      Object.values(section.params).forEach((param) => {
        if (param.value?.validations || param.validations) {
          const paramKey = param.value.key || param.key;
          const paramValue =
            param.value.value === undefined || param.value.value === null
              ? param.value
              : param.value.value;
          requiredFields.push({
            sectionKey: section.key,
            key: paramKey,
            payload: paramValue,
          });
          // TODO: is there ever a time we dont have a param key here?
          // was set to paramKey || section.key
          validateAtKey({ [paramKey]: paramValue }, section.key, [paramKey]);
        }
      });
    });

    isValidAt(requiredFields).then((res: boolean[]) => {
      if (res.includes(false)) {
        const topMostError = res.findIndex((innerRes: any) => {
          return !innerRes;
        });

        const errorKey = `${requiredFields[topMostError]?.sectionKey}-form_section`;
        useScrollToView(errorKey);

        setClickedPublishOrUpdate(undefined);
        setOpenInvalidSections(true);
      } else {
        if (artistId && templatePageId) {
          setDebounceTime(defaultDebounceTime);
          dispatchTemplatePage({
            type: "PUBLISH",
          });
        }
      }
    });
  };

  const handleUnPublish = () => {
    !formTouched && setFormTouched(true);
    dispatchTemplatePage({
      type: "UNPUBLISH",
    });
  };

  const handleMobilePreviewClick = () => {
    setHighlightedKey(null);
    setShowPreview(!showPreview);
  };

  const mutationSuccess = createMutation.isSuccess || updateMutation.isSuccess;
  const mutationLoading = createMutation.isLoading || updateMutation.isLoading;

  if (isLoading || !templatePageDraft.templateConfig)
    return <Loading height="100vh" />;

  return (
    <TemplatePageEditorContext.Provider value={context}>
      <main
        id="editor-main"
        className={styles.main}
        onFocus={() => !formTouched && setFormTouched(true)}
        onChange={() => !formTouched && setFormTouched(true)}
      >
        {desktopView ? (
          <div>
            <div className={styles.sidebar}>
              <TemplatePageEditorForm
                templatePageDraft={templatePageDraft}
                dispatchTemplatePage={dispatchTemplatePage}
              />
            </div>
            <div className={styles.preview}>
              <TemplatePagePreviewFrame>
                <TemplatePageRenderer
                  templatePage={templatePageDraft}
                  assembleArtist={templatePage.artist}
                  editable
                />
              </TemplatePagePreviewFrame>
            </div>
            <TemplatePageEditorDesktopActionBar
              templatePage={templatePage}
              formTouched={formTouched}
              isDirty={isDirty}
              mutationLoading={mutationLoading}
              mutationSuccess={mutationSuccess}
              handlePublish={handlePublish}
              handleUnPublish={handleUnPublish}
            />
          </div>
        ) : (
          <div>
            <AnimateTemplatePageEditor
              showPreview={showPreview}
              templatePageDraft={templatePageDraft}
              assembleArtist={templatePage.artist}
              dispatchTemplatePage={dispatchTemplatePage}
            />
            <TemplatePageEditorMobileActionBar
              templatePage={templatePage}
              formTouched={formTouched}
              showPreview={showPreview}
              isDirty={isDirty}
              mutationSuccess={mutationSuccess}
              mutationLoading={mutationLoading}
              handlePublish={handlePublish}
              handleUnPublish={handleUnPublish}
              handlePreview={handleMobilePreviewClick}
            />
          </div>
        )}
      </main>
    </TemplatePageEditorContext.Provider>
  );
};

export default TemplatePageEditor;
