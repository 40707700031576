import ResourceSchema, { UserRole } from "../../bundles/shared/ResourceSchema";

const TemplateSchema: ResourceSchema = {
  title: "Asset Generator",
  pluralTitle: "Asset Generators",
  rootPath: "templates",
  apiRootPath: "promo-pack-asset", // i dont think this is in use anywhere??
  attrs: [""],
  paths: {},
  locale: "en-US",
  dataIsNested: false,
  role: UserRole.Admin, //default asset generator schema manipulation is done by an admin
};

export default TemplateSchema;
