import { TemplateVariant } from "../../../asset-generator-lib";
import { bookmarkedNodes } from "../../publications/components/BookmarksManager";
import {
  bookmarksNode,
  ContentNode,
} from "../../publications/components/ContentProvider";
import { TemplatePageDraft } from "../../template-page-builder/types/TemplatePages";

export const downloadTextFromString = (text: string, fileName: string) => {
  const element = document.createElement("a");
  const file = new Blob([text], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
};

//injest a time and convert it to "mins:secs" as a string
export const convertTime = (time: number) => {
  let mins = Math.floor(time / 60);
  if (mins < 10) {
    mins = 0 + mins;
  }
  let secs = Math.floor(time % 60);
  const formattedTime = mins + ":" + (secs < 10 ? "0" + secs : secs);
  return formattedTime.toString();
};

export const getAudioDuration = (src: any): Promise<number> => {
  return new Promise((resolve) => {
    var audio = new Audio();
    audio.onloadedmetadata = () => {
      resolve(audio.duration);
    };
    audio.src = src;
  });
};

export const returnLowestScale = (
  templateSize: TemplateVariant["size"],
  width?: number,
  height?: number
) => {
  // @ts-ignore  TODO: sometimes we don't supply all 3 props
  const widthScale = width / templateSize.w;
  // @ts-ignore  TODO: sometimes we don't supply all 3 props
  const heightScale = height / templateSize.h;

  // find smallest scale, filter out any undefined values
  // adding a 1, because if all of these are over 1, we should just use the template size
  // (which is a scale of 1)
  const arr = [widthScale, heightScale, 1].filter((value) => value);
  const lowestScale = Math.min(...arr);

  return lowestScale;
};

export const findPair = (searchParams: any, param: string) => {
  for (var pair of searchParams.entries()) {
    // this is our page query param
    if (pair[0] === param) {
      return pair[1];
    } else return undefined;
  }
};

export function getQueryParam(param: string) {
  const url = new URL(window.location.href);

  // for some reason URLSearchParams wont itterate when typed properly, even though I have dom.iterable in the tsconfig
  // https://stackoverflow.com/questions/50677868/error-ts2339-property-entries-does-not-exist-on-type-formdata
  // it was working 2mo ago, but something isn't working right with the multiple tsconfigs now 🤨
  // also, do we still need? or can we use useParams with react-router-dom 6?

  const searchParams: any = new URLSearchParams(url.search);

  return findPair(searchParams, param);
}

export function updateQueryParam(param: string, value: string) {
  const url = new URL(window.location.href);
  const currentParams = new URLSearchParams(url.search);

  currentParams.set(param, value);

  return `?${currentParams.toString()}`;
}

export const createBookmarkNodes = (contentNode: ContentNode) => {
  const bookmarkNode = bookmarksNode();

  bookmarkNode._children = bookmarkedNodes(contentNode);
  bookmarkNode.id = "bookmarks";
  bookmarkNode.description =
    bookmarkNode.children.length > 0
      ? "Pages you've saved to read later."
      : "When you bookmark a chapter, it will appear here.";
  return bookmarkNode;
};

export const isLiveAndPublished = (
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
  published: boolean | null | undefined
) => {
  const now = new Date();
  const start = startDate ? new Date(startDate) : undefined;
  const end = endDate ? new Date(endDate || "") : undefined;

  if ((!start || start < now) && (!end || end > now) && published) {
    // as long as something is published, and the start/end date aren't on the wrong side of now, it's live.
    return true;
  } else {
    return false;
  }
};

export const templatePageLiveStatus = (
  startDate: Date | null,
  endDate: Date | null,
  published: boolean | null
) => {
  const now = new Date();
  const start = startDate || undefined;
  const end = endDate || undefined;

  if (!published) {
    return { details: null, status: "Not Published" };
  } else if (start && start > now) {
    return {
      details: { verb: "Launches", date: start },
      status: "Scheduled",
    };
  } else if (end && end < now) {
    return { details: { verb: "Expired", date: end }, status: "Expired" };
  } else {
    return {
      details: end
        ? { verb: "Expires", date: end }
        : start
        ? { verb: "Launched", date: start }
        : null,
      status: "Published",
    };
  }
};

export const isJSON = (jsonString: string) => {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}

  return false;
};

export const JSONParseSafely = (jsonString: string) => {
  // Try to parse a json string, catch and return null if it can't be parsed.
  // null object is a more acceptable fallback than a primative like false.
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}

  return null;
};

export const removeHttp = (url: string) => {
  return url?.replace(/^https?:\/\//, "");
};

export const getLatestDraft = (drafts: TemplatePageDraft[]) =>
  drafts[drafts.length - 1];

export const getLatestDraftIndex = (drafts: TemplatePageDraft[]) => {
  return drafts.length - 1 <= 0 ? 0 : drafts.length - 1;
};

export const getCurrentURLBase = () =>
  `${window.location.protocol}//${process.env.SYMPHONY_HOST}`;

export const getExternalURLBase = () =>
  `${window.location.protocol}//${process.env.ASSEMBLE_HOST}`;

//document query selector from iframes content window if present
export const documentQueryFromIframeIfPresent = (query: string) => {
  //Type casted as HTMLIframeElement because content window does
  //not exist on content element
  const iframeElement = document.getElementById(
    "frame-size-wrapper"
  ) as HTMLIFrameElement;
  const iframedElement =
    iframeElement?.contentWindow?.document.querySelector(query);
  const element = iframedElement
    ? iframedElement
    : document.querySelector(query);
  return element;
};

export const getElementFromIframe = (id: string) => {
  //Type casted as HTMLIframeElement because content window does
  //not exist on content element
  const iframeElement = document.getElementById(
    "frame-size-wrapper"
  ) as HTMLIFrameElement;
  const iframedElement =
    iframeElement?.contentWindow?.document.getElementById(id);
  const element = iframedElement || null;
  return element;
};

export const templatePageExternalLinkURL = (
  templateSlug: string,
  templatePageSlug: string
) => `${getExternalURLBase()}/artist-pages/${templateSlug}/${templatePageSlug}`;

export const templatePagePreviewLinkURL = (
  templateSlug: string,
  templatePageSlug: string
) =>
  `${getCurrentURLBase()}/promote/artist-pages-preview/${templateSlug}/${templatePageSlug}`;

export const constructValuePath = (path: string[]) =>
  path.join(".value.") + ".value";

export const isURL = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
};

export const idFallthrough = (obj: any) => {
  return obj?.asset?.id || obj?.asset?._id || obj?._id || obj?.id;
};
