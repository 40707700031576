import React from "react";
import styles from "./PromoteLearnCard.module.scss";
import { ReactComponent as ChevronForwardCircleFill } from "../../../shared/icons/chevron-forward-circle-fill.svg";

type Props = {
  cardData: LearnLinkCardData;
};

export type LearnLinkCardData = {
  icon: any; // these should be functional components or a ReactNode, but it doesnt like either.  Check out leanrLinksData.ts for more
  title: string;
  description: string;
  link: string;
};

const PromoteLearnCard = ({ cardData }: Props) => {
  return (
    <a className={styles["card"]} href={cardData.link}>
      <div className={styles["card-square"]}>
        <div className={styles["icon-container"]}>
          <cardData.icon />
        </div>
      </div>
      <h2 className={styles["title"]}>
        {cardData.title} <ChevronForwardCircleFill style={{ width: "16px" }} />
      </h2>
      <p className={styles["p"]}>{cardData.description}</p>
    </a>
  );
};

export default PromoteLearnCard;
