import React, { createContext, useContext, useEffect, useReducer } from "react";
import ErrorPage from "../linkviewer/ErrorPage";
import { useSnackContext } from "../shared/SnackProvider";

type Props = {
  redirectPath?: string;
  children: React.ReactNode;
};

type ErrorHandlingContext = {
  providedErrorState: ProvidedErrorState;
  errorDispatch: any;
};

type ProvidedErrorState = {
  status: number | null;
  message: string | string[] | null;
  serveErrorPage: boolean;
  serveSnack: boolean;
};

export const ErrorHandlingContext = createContext<ErrorHandlingContext | null>(
  null
);
export const useErrorHandlingContext = () => useContext(ErrorHandlingContext)!;

const ErrorHandlingProvider = ({ children, redirectPath }: Props) => {
  const snackContext = useSnackContext;

  const setSnack = snackContext() ? snackContext().setSnack : null;

  const defaultErrorState: ProvidedErrorState = {
    status: null,
    message: null,
    serveErrorPage: false,
    serveSnack: false,
  };

  //TODO: Implement action type for useReducer
  const ProvidedErrorStateReducer = (
    state: ProvidedErrorState,
    action: any
  ) => {
    switch (action.type) {
      case "SET_ERROR":
        return {
          ...state,
          status: action.payload.error?.response?.status,
          message: action.payload.error?.reasons?.message,
          serveErrorPage: action.payload.serveErrorPage,
          serveSnack: action.payload.serveSnack,
        };

      case "CLEAR_ERRORS":
        return defaultErrorState;

      case "CLEAR_ERROR_MESSAGE":
        return {
          ...state,
          message: null,
        };

      default:
        console.warn("UNKNOWN ACTION TYPE", action.type);
        return state;
    }
  };

  const [providedErrorState, errorDispatch] = useReducer(
    ProvidedErrorStateReducer,
    defaultErrorState
  );

  useEffect(() => {
    if (providedErrorState.serveSnack && providedErrorState.message) {
      setSnack(providedErrorState.message);
    }
  }, [providedErrorState.serveSnack]);

  return (
    <ErrorHandlingContext.Provider
      value={{ providedErrorState, errorDispatch }}
    >
      {providedErrorState.serveErrorPage ? (
        <ErrorPage
          status={providedErrorState.status}
          errorDispatch={errorDispatch}
          redirectPath={redirectPath}
        />
      ) : (
        children
      )}
    </ErrorHandlingContext.Provider>
  );
};

export default ErrorHandlingProvider;
