import React from "react";

type ViewSwitcherProps = {
  children: React.ReactNode;
  displayedView: string;
};

const ViewSwitcher = ({ displayedView, children }: ViewSwitcherProps) => {
  const childrenArr = React.Children.toArray(children);
  const element:
    | string
    | number
    | {}
    | React.ReactElement<any, string>
    | undefined = childrenArr.find((child) => {

      //is this if even needed?  a match is a match, why do this first?
      if (
        !React.isValidElement(child) ||
        !child ||
        !child.props
        // !child.props["data-view"] // if data-view === 0, this would hit, and not find what is needed
      ) {
        return undefined;
      }
      // usual case
      return child.props["data-view"] == displayedView;
    });
  return (element as React.ReactElement) || <div>Section not found</div>;
};

export default ViewSwitcher;
