import React, { createContext, useContext, useReducer, useState } from "react";
import * as yup from "yup";
import { ConvertedBlock } from "../../asset-generator-lib/composer/models/Block";
import { GenericObj } from "../template-page-builder/components/inputs/helpers";
import clone from "just-clone";
const merge = require("lodash.merge");

type Props = {
  children: React.ReactNode;
};

type ValidationsContext = {
  validateAtKey: (
    payload: GenericObj<any>,
    sectionKey: string,
    keys?: string[]
  ) => Promise<boolean>;
  isValid: (payload: any, sectionKey: string, key?: string) => Promise<any>;
  isValidAt: (payload: Validation[]) => Promise<any>;
  validations: GenericObj<any>;
  validationsDispatch: React.Dispatch<ValidationAction>;
  createValidationSchema: (payload: any) => void;
  validationSchema: yup.AnyObjectSchema | undefined;
  openInvalidSections: boolean;
  setOpenInvalidSections: React.Dispatch<React.SetStateAction<boolean>>;
  removeValidation: (paramKey: string) => void;
};

export type Validation = {
  sectionKey: string;
  payload: string;
  key: string;
};

type ValidationAction =
  | { type: "SET_VALIDATIONS"; payload: any }
  | { type: "REMOVE_VALIDATION"; payload: any };

export const ValidationsContext = createContext<ValidationsContext | null>(
  null
);
export const useValidationsContext = () => useContext(ValidationsContext)!;

const ValidationsProvider = ({ children }: Props) => {
  const [validationSchema, setValidationSchema] =
    useState<yup.AnyObjectSchema>();
  const [openInvalidSections, setOpenInvalidSections] =
    useState<boolean>(false);

  const ValidationStateReducer = (state: any, action: ValidationAction) => {
    switch (action.type) {
      case "SET_VALIDATIONS":
        // we are receiving a payload with failed and passed validations that live under their section key
        // we must add in our failed ones first
        let updatedState = action.payload.failed
          ? merge(clone(state), action.payload.failed)
          : state;

        // then remove passed validations
        action?.payload?.passed &&
          Object.entries(action?.payload?.passed).forEach((item: any) => {
            // loop over sections
            item[1] &&
              Object.keys(item[1]).forEach((subKey) => {
                // loop over keys in sections
                delete updatedState[item[0]][subKey];
              });
          });
        // ship it!
        return updatedState;
      case "REMOVE_VALIDATION":
        // TODO:This might need to either be removed, or written differently now.
        return { ...action.payload };
      default:
        console.warn("UNKNOWN ACTION TYPE", action);
        return state;
    }
  };

  const [validations, validationsDispatch] = useReducer(
    ValidationStateReducer,
    {}
  );

  const createYupObj = (
    option: any,
    validation: any,
    schema: any,
    key: string
  ) => {
    let validator = (yup as any)[option.validations.type]();

    option.validations.params.forEach((subOption: any) => {
      if (subOption) {
        const validatorRule = subOption.limit
          ? [subOption.limit, subOption.message]
          : [subOption.message];

        validator = validator[subOption.validation](...validatorRule);
        validation[option.key] = validator;

        schema[key] = yup.object().shape({ ...validation });
      }
    });
  };

  const createYupSchema = (schema: any, config: ConvertedBlock) => {
    const { key, params } = config;

    let validation: any = {};

    Object.values(params).forEach((param) => {
      if (param.validations) {
        createYupObj(param, validation, schema, key);
      }
      //TODO: should be done recursively
      if (param.options) {
        param.options.forEach((option) => {
          if (option.validations) {
            createYupObj(option, validation, schema, key);
          }
          if (option.options) {
            option.options.forEach((subOption) => {
              if (subOption.validations) {
                createYupObj(subOption, validation, schema, key);
              }
              if (subOption.options) {
                subOption.options.forEach((subSubOption) => {
                  createYupObj(subSubOption, validation, schema, key);
                });
              }
            });
          }
        });
      }
    });
    return schema;
  };

  // payload could be a different shape based on project
  // unless this should be the TemplatePageValidationsProvider
  const createValidationSchema = (payload: ConvertedBlock[]) => {
    //reduces the payload to find just the sections that have validations
    const yupSchema = payload.reduce(createYupSchema, {});
    setValidationSchema(yup.object().shape({ ...yupSchema }));
  };

  // validateAtKey is a function designed to check supplied keys/values against the schema rules.
  // it will construct an object that contains an object for both passed and failed fields
  // which gets sent off to the useReducer - then return a true false in case the place that calls it needs to know the outcome
  const validateAtKey = async (
    // check on payload types
    payload: GenericObj<any>,
    sectionKey: string,
    keys?: string[]
  ) => {
    // if keys are NOT supplied, we need to grab all of the keys from the section via the schema
    const whereToValidate = keys
      ? keys
      : Object.keys(validationSchema?.fields[sectionKey].fields);

    // we need to store the errors in validation as:
    /*
      [sectionKey]:{
        inputKey: 'error message
      }
    */
    // store all of the pass fail in an array
    const validationPromises = whereToValidate.map(async (key: any) => {
      // loop over each key, .isValid() it, if it passes, put it in the passed obj
      if (validationSchema?.fields[sectionKey]?.fields[key]) {
        const res = await isValid(payload[key], sectionKey, key).then(
          (res) => res
        );
        if (!res) {
          // isValid of field failed
          // now run validate at

          const validationResponse = await validationSchema
            // to use validateAt we need to supply yup with:
            //   1 - the path of where to check
            //   2 - the ROOT value relative tot he starting schema, not the value at the nested path
            .validateAt(
              `${sectionKey}.${key}`,
              { [sectionKey]: payload },
              {
                abortEarly: false,
              }
            )
            .catch((error: yup.ValidationError) => {
              // we knew this would fail, so we only have a .catch here
              // time to package up the error in the way we want
              const formattedError: any = {};

              error.inner.forEach((innerError: yup.ValidationError) => {
                if (innerError.path) {
                  const errorKey = innerError.path.includes(".")
                    ? (innerError.path.split(".").pop() as string)
                    : innerError.path;
                  formattedError[errorKey] = innerError.message;
                }
              });
              return { failed: formattedError };
            });
          return validationResponse;
        } else {
          // these are the ones that passed.
          return { passed: { [key]: payload[key] } };
        }
      }
    });

    // wait for the array of promises to resolve
    const allItems: any = await Promise.all(validationPromises);

    // now lets package the array of objects up together how we want
    const validationErrors = allItems.reduce(
      (collector: any, item: any) => {
        if (item?.passed) {
          return {
            passed: {
              ...collector.passed, // spread in the sectionKeys that are in here already
              [sectionKey]: Object.assign(
                collector.passed[sectionKey],
                item.passed
              ), // merge in the items from the section key
            },
            failed: { ...collector.failed },
          };
        } else if (item?.failed) {
          // same as above, but for failed
          return {
            failed: {
              ...collector.failed,
              [sectionKey]: Object.assign(
                collector.failed[sectionKey],
                item.failed
              ),
            },
            passed: { ...collector.passed },
          };
        } else {
          return collector;
        }
      },
      { passed: { [sectionKey]: {} }, failed: { [sectionKey]: {} } } // init obj with shape
    );

    // ship it!
    validationsDispatch({
      type: "SET_VALIDATIONS",
      payload: validationErrors,
    });

    return !(Object.keys(validationErrors.failed[sectionKey]).length > 0);
  };

  // TODO: rename, and change what the return value is (true/false)
  // I feel like this should be renamed, it looks like it's recursive, but the isValid called below
  // comes from yup
  // isValid will return true/false if the payload is valid
  // or throw error if key doesn't exist
  const isValid = async (payload: any, sectionKey: string, key?: string) => {
    if (validationSchema?.fields[sectionKey]) {
      if (key) {
        const valid = await validationSchema.fields[sectionKey]?.fields[
          key
        ]?.isValid(payload);
        return valid;
      } else {
        const valid = await validationSchema.fields[sectionKey]?.isValid(
          payload
        );
        return valid;
      }
    } else {
      // doesnt have a validation key, return true
      return true;
    }
  };

  //isValidAt can search sections in the schema and return a promise of true/false
  const isValidAt = async (payload: Validation[]) => {
    const testedValidations = payload.map((validation: Validation) => {
      const valid = validationSchema?.fields[validation.sectionKey]?.fields[
        validation.key
      ]?.isValid(validation.payload);
      return valid;
    });

    return Promise.all(testedValidations);
  };

  const removeValidation = (paramKey: string) => {
    if (validations[paramKey]) {
      delete validations[paramKey];
    }
  };

  return (
    <ValidationsContext.Provider
      value={{
        validateAtKey,
        isValid,
        isValidAt,
        validations,
        validationsDispatch,
        createValidationSchema,
        validationSchema,
        openInvalidSections,
        setOpenInvalidSections,
        removeValidation,
      }}
    >
      {children}
    </ValidationsContext.Provider>
  );
};

export default ValidationsProvider;
