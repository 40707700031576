// import { Logger as UnsafeLogger } from "react-logger-lib"; //leaving this here in case we ever want to use react-logger-lib again

// same interface method as react-logger-lib, just wraps
const of = (_: any) => {
  const LoggerInstance = process.env.LOGGING_ENABLED ? console : null;

  /* eslint-disable no-console */
  return {
    info: (...args : any[]) => LoggerInstance && LoggerInstance.info(...args),
    warn: (...args : any[]) => LoggerInstance && LoggerInstance.warn(...args),
    error: (...args : any[]) => LoggerInstance && LoggerInstance.error(...args),
    trace: (...args : any[]) => LoggerInstance && LoggerInstance.info(...args)
  };

};


const Logger = { of };

export { Logger };

export default {};
