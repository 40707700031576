import React, { useEffect } from "react";
import { CMSNode } from "./NodeSchema";
import {
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { ArrowBack as BackToParentIcon } from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  headerBackIcon: {
    margin: "auto",
    color: "white",
  },
  iconStyle: {
    color: "white",
  },
  titlesWrapper: {
    display: "flex",
    whiteSpace: "nowrap",
  },
  nodeTitle: {
    maxWidth: "80%",
  },
  subtitleSpacing: {
    marginLeft: ".5rem",
  },
  headerSaveButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  stickyHeader: {
    position: "fixed",
    width: "calc(100% - 260px)",
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    paddingTop: theme.spacing(2),
  },
}));

interface Props {
  node: CMSNode;
  onSave: () => void;
  isDirty: boolean;
}

export default function NodeEditHeader({ node, onSave, isDirty }: Props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateBack = (route: string) => {
    navigate(route);
  };

  const escFunction = (e: any) => {
    if (e.keyCode === 27) {
      //Do whatever when esc is pressed
      node.parentId && navigateBack(node.parentId);
    }
  };

  useEffect(() => {
    //remove old one first
    document.removeEventListener("keyup", escFunction);
    if (node.parentId) {
      document.addEventListener("keyup", escFunction);
    }

    return () => {
      document.removeEventListener("keyup", escFunction);
    };
  }, [node.parentId]);

  return (
    <React.Fragment>
      <Box className={classes.stickyHeader}>
        <Grid
          container
          wrap="nowrap"
          direction="row"
          spacing={2}
          alignItems={"flex-start"}
        >
          <Grid item className={classes.headerBackIcon}>
            <Typography noWrap>
              {node.hasParent && (
                <Link to={node.parentId || ""}>
                  <BackToParentIcon className={classes.iconStyle} />
                </Link>
              )}
            </Typography>
          </Grid>
          <Grid zeroMinWidth item className={classes.titlesWrapper}>
            <Typography
              variant="h4"
              gutterBottom
              noWrap
              className={classes.nodeTitle}
            >
              {node.title}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.subtitleSpacing}
            >
              - {node.type.capitalize()}
            </Typography>
          </Grid>
          <Grid className={classes.headerSaveButton} item xs>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSave}
              value="Save"
              disabled={!isDirty}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </Box>
    </React.Fragment>
  );
}
