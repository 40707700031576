import React, { useEffect, useState } from "react";
import styles from "./FileUpload.module.scss";
import { DraggableProps } from "react-beautiful-dnd";
import isEqual from "react-fast-compare";
import type { FileUploadType } from "../../FileInput/FileInput";
import RockDove from "../../../../../shared/RockDove";
import axios from "axios";
import { UserRole } from "../../../../../shared/ResourceSchema";
import { useTemplatePageEditorContext } from "../../../TemplatePageEditor/TemplatePageEditor";
import MultiPill from "../../MultiPill/MultiPill";
import MultiPillDetails from "../../MultiPill/MultiPillDetails/MultiPillDetails";
import ProgressBar from "../ProgressBar/ProgressBar";
import { AssetType } from "../../../../../platoon-cms-lib";
import { useSnackContext } from "../../../../../shared/SnackProvider";
import clone from "just-clone";
import { idFallthrough } from "../../../../../shared/helpers/helpers";

type Props = {
  file: FileUploadType;
  index?: number;
  removeFile: (id: string) => void;
  artistId?: string;
  updateFileProps: (file: FileUploadType) => void;
  dragProps?: DraggableProps;
  multiFile?: boolean | undefined;
  overrideAdapters?: {
    responseAdapter: any;
    formAdapter: any;
    apiEndpoint: string;
    pillAdapter: (file: any) => string;
    updateFileTextPropName: string;
  };
  editablePillText?: boolean;
};

export type LegacyFileUpload = FileUploadType & {
  _id?: string;
  attachment?: string;
  description?: string;
  thumbnail?: string;
  type?: AssetType;
  id?: string;
  title?: string;
  derivatives?: any;
  downloadUrl?: string;
};

const FileUpload = ({
  file,
  index = 0,
  artistId,
  removeFile,
  updateFileProps,
  dragProps,
  multiFile,
  overrideAdapters,
  editablePillText,
}: Props) => {
  const [localFile, setLocalFile] = useState<LegacyFileUpload>({ ...file });

  const [progress, setProgress] = useState(
    localFile.status === "COMPLETED" ? 100 : 0
  );
  const { formTouched, setFormTouched } = useTemplatePageEditorContext();
  const { setSnack } = useSnackContext();

  // This UE was causing duplicate file uploads. There may be an edge
  // case in which this UE is needed but need further testing - AB
  // useEffect(() => {
  //   setLocalFile({ ...file });
  // }, [file]);

  useEffect(() => {
    let isCurrent = true;
    // if this file errors, remove it
    if (isCurrent && localFile.status === "ERRORED") {
      removeFile(localFile.id);
    } else if (
      isCurrent &&
      artistId &&
      localFile.status === "PENDING" &&
      localFile.selectedFile &&
      !isEqual(localFile.selectedFile, {})
    ) {
      // upload it
      uploadAsset(artistId, localFile, overrideAdapters?.apiEndpoint);
    } else if (!isEqual(localFile, file)) {
      updateFileProps(localFile);
    } else if (isEqual(localFile.selectedFile, {}) && !localFile.asset) {
      // this is for when an upload gets abandoned
      // we have to mark the form as touched, then we can remove the file.
      if (setFormTouched && !formTouched) {
        setFormTouched(true);
      }
      removeFile(localFile._id || localFile.id);
    }

    // need to clean up this ue in case the file gets deleted
    // or unmounted before these changes can happen
    return () => {
      isCurrent = false;
    };
  }, [localFile, artistId]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      progress &&
        progress > 0 &&
        progress < 100 &&
        localFile.status === "PENDING" &&
        setLocalFile((prev) => {
          return { ...prev, status: "INPROGRESS" };
        });
    } else {
      // TODO cancel the upload??
      // https://stackoverflow.com/questions/44852054/cant-cancel-axios-post-request-via-canceltoken
    }

    // need to clean up this ue in case the file gets deleted
    // or unmounted before these changes can happen
    return () => {
      isCurrent = false;
    };
  }, [progress]);

  const prepFormData = (uploadFile: FileUploadType) => {
    const fileData = uploadFile.selectedFile;
    const formData = new FormData();
    formData.append("attachment", fileData);
    formData.append("description", fileData.name);
    formData.append("type", fileData.type);

    return formData;
  };

  // upload fn
  const uploadAsset = (
    artistId: string,
    file: FileUploadType,
    apiEndpoint?: string
  ) => {
    const BASE_PATH = `/api/v1`;

    // do we have an adapter?
    const formData = overrideAdapters?.formAdapter
      ? overrideAdapters.formAdapter(file)
      : prepFormData(file);
    // do we have a custom endpoint?
    const url = apiEndpoint
      ? `${BASE_PATH}/artists/${artistId}/${apiEndpoint}`
      : `${BASE_PATH}/artists/${artistId}/assets`;

    RockDove.shared()
      .getHeaders(UserRole.Artist)
      .then((authHeaders) => {
        const options = {
          headers: authHeaders,
          // is type any in the axios docs
          onUploadProgress: (progressEvent: any) => {
            const { loaded, total } = progressEvent;

            let percent = Math.round((loaded * 100) / total);

            if (percent < 100 && percent > progress) {
              setProgress((prev) => {
                if (prev < percent) {
                  return percent;
                } else return prev;
              });
            }
          },
        };
        axios
          .post(url, formData, options)
          .then((response) => {
            // if we have an override adapter, let's use it
            let update: FileUploadType = overrideAdapters?.responseAdapter
              ? overrideAdapters.responseAdapter(response.data, file)
              : {
                  ...file,
                  asset: response.data,
                  status: "COMPLETED",
                };

            setLocalFile(update);
          })
          .catch((error) => {
            const update = {
              ...file,
              // asset: response.data,
              status: "ERRORED",
            } as FileUploadType;
            console.log({ error });
            console.log(error.response.data);
            setLocalFile(update);
            setSnack({
              message: `${file?.selectedFile?.name} failed to upload.`,
              hideDuration: 10000,
            });
          });
      });
  };

  const updateFileText = (text: string) => {
    if (overrideAdapters?.updateFileTextPropName) {
      setLocalFile({
        ...localFile,
        [overrideAdapters.updateFileTextPropName]: text,
      });
    } else {
      let updateLocalFile = clone(localFile);

      if (updateLocalFile.asset) {
        updateLocalFile.asset.description = text;
      } else {
        updateLocalFile.description = text;
      }
      setLocalFile(updateLocalFile);
    }
  };

  const content = (
    <div className={styles["content-container"]}>
      {multiFile && (
        <div className={styles["index"]}>
          {(index + 1).toLocaleString("en-us", {
            minimumIntegerDigits: 2,
          })}
        </div>
      )}
      <MultiPillDetails
        editablePillText={editablePillText ? updateFileText : undefined}
        multiFile={multiFile}
        image={`/api/v1/signed-assets/${idFallthrough(localFile)}/small`}
        text={
          overrideAdapters?.pillAdapter && !localFile.status
            ? overrideAdapters.pillAdapter(localFile)
            : localFile.asset?.description ||
              localFile.description ||
              localFile.asset?.title ||
              localFile.title ||
              localFile.selectedFile?.name ||
              "untitled"
        }
        errored={localFile.status === "ERRORED"}
      />
      {/* // if progress goes too fast, this never shows anything. */}
      {!localFile.asset && (
        <div className={styles["progress-container"]}>
          <ProgressBar progress={progress} />
        </div>
      )}
    </div>
  );

  return (
    <MultiPill
      multiFile={{
        dragProps,
        index,
      }}
      remove={() => removeFile(localFile.id)}
    >
      {content}
    </MultiPill>
  );
};

export default FileUpload;
