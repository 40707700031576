import React from "react";
import { useParams } from "react-router-dom";
import styles from "./Redirect.module.scss";
import { ReactComponent as PlatoonLogo } from "../../shared/icons/platoon-logo.svg";
import { ReactComponent as PlatoonSymbol } from "../../shared/icons/platoon-symbol.svg";

const Redirect = () => {
  const protocol = window.location.protocol;
  const params = useParams();

  const subapp =
    params["subapp"] === "link-viewer" ? "stealth-player" : params["subapp"];

  let url = new URL(
    `${subapp}/${params["*"]}`,
    `${protocol}//${process.env.ASSEMBLE_HOST}`
  );

  // needs designs
  return (
    <div className={`fullscreen-center ${styles["fullscreen"]}`}>
      <PlatoonSymbol className={styles["symbol"]} />
      <div className={styles["container"]}>
        <PlatoonLogo className={styles["logo"]} />
        <h1>oops, we've moved!</h1>
        <a className="btn" href={url.toString()} target="_parent">
          click to continue
        </a>
      </div>
    </div>
  );
};

export default Redirect;
