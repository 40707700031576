import { makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { ContentNode } from "../ContentProvider";
import TOCButtons from "./TOCButtons";
import { Link } from "react-router-dom";
import { useActiveButton, useCrumbs } from "../../../shared/CustomHooks";
import { TOCContext } from "./TableOfContentsMenu";

type Props = {
  contentNode: ContentNode;
};

const useStyles = makeStyles(() => ({
  mobileRoot: {
    padding: "0",
    fontSize: "13px",
    transition: "1s ease-in-out",
  },
  button: {
    cursor: "pointer",
    width: "100%",
    fontFamily: "Helvetica",
    fontSize: "13px",
    lineHeight: "50px",
    color: "#ffffff",
    padding: "0 14px 0 48px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    backgroundColor: "transparent",
    border: "none",
    textAlign: "inherit",
    textDecoration: "none",
    fontWeight: "normal",
  },
  child: {
    transition: "1s ease-in-out",
  },
  active: {
    backgroundColor: "#1d1d1d",
  },
  activeButton: {
    backgroundColor: "#555555",
  },
  childrenContainer: {
    padding: "0 14px",
  },
}));

const getInitSlug = (
  contentNode: ContentNode | undefined,
  crumbs: ContentNode[] | undefined
) => {
  // get intersection of crumbs and children arrays (there should only be 1 ever since slugs are unique)
  const intersection = crumbs?.filter((element) =>
    contentNode?.children.includes(element)
  )[0]?.slug;

  return intersection;
};

const TOCAccordion = ({ contentNode }: Props) => {
  const classes = useStyles();
  const crumbs = useCrumbs(contentNode);

  const [selected, setSelected] = useState(
    getInitSlug(contentNode, crumbs) || contentNode.children[0].slug
  );
  const { setOpen } = useContext(TOCContext);

  const tabs = contentNode.children.map((child) => (
    <div
      key={child.slug}
      className={`${classes.child} ${
        child.slug === selected && classes.active
      }`}
    >
      {child.type === "section" ? (
        // sections have children to render
        <>
          <button
            className={classes.button}
            onClick={() =>
              selected === child.slug
                ? setSelected("")
                : setSelected(child.slug)
            }
          >
            {child.title}
          </button>

          {child.slug === selected && (
            <div className={classes.childrenContainer}>
              {child.children.some((item) => item.type === "section") ? (
                <TOCAccordion contentNode={child} />
              ) : (
                <TOCButtons
                  contentNode={child}
                  indent={2}
                  fontWeight="normal"
                  fontSize="13px"
                />
              )}
            </div>
          )}
        </>
      ) : (
        // articles just need a button to click and navigate with
        <div
          className={`${classes.child}  ${
            useActiveButton(contentNode, child) && classes.activeButton
          }`}
        >
          <Link
            key={child.slug}
            to={child.path()}
            className={`${classes.button}`}
            onClick={() => setOpen(false)}
          >
            {child.title}
          </Link>
        </div>
      )}
    </div>
  ));

  return <div className={classes.mobileRoot}> {tabs} </div>;
};

export default TOCAccordion;
