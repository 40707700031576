import React from "react";
import {
  isWidthUp,
  makeStyles,
  Typography,
  withWidth,
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import Product from "../models/p4a_proxy/Products";

type Props = {
  release: Product;
  width: Breakpoint;
  selected: boolean;
  square?: true; // TODO: how do we want to determine shape?? this makes it reusable
  rectangle?: true; // TODO: change paddingTop % to make diff aspect ratios
};

type StyleProps = {
  width: Breakpoint;
  backgroundImage: string;
  selected: boolean;
};

const useStyles = makeStyles(() => ({
  tile: {
    backgroundColor: "#333333",
    backgroundImage: (props: StyleProps) =>
      `linear-gradient(rgba(0, 0, 0, 0) 75%, rgb(0, 0, 0,.75) 100%),
        url(${props.backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    paddingTop: "100%",
    position: "relative",
    opacity: (props: StyleProps) => (props.selected ? 1 : 0.8),
    borderRadius: "4px",
    outline: (props: StyleProps) => (props.selected ? "2px solid #ff4169" : ""),
    transition: ".1s ease",
  },
  text: {
    position: "absolute",
    padding: "0 0 4% 5%",
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "SF Pro Text ",
    fontSize: (props: StyleProps) =>
      isWidthUp("sm", props.width) ? "17px" : "13px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: -0.41,
    color: "#ffffff",
  },
}));

const Tile = ({ width, release, selected }: Props) => {
  const classes = useStyles({
    width,
    selected,
    backgroundImage: release.artworkUrl || "",
  });

  return (
    <div className={classes.tile}>
      <Typography variant="caption" className={classes.text}>
        {release.name}
      </Typography>
    </div>
  );
};

export default withWidth()(Tile);
