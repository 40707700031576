
function escapeRegex(string: string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

const regExp = {
  escapeRegex
}

export default regExp
