import React, { useEffect, useRef, useState } from "react";
import styles from "./FileInput.module.scss";
import { BlockParam } from "../../../../../asset-generator-lib";
import { usePlatoonArtistContext } from "../../../../providers/PlatoonArtistProvider";
import DropZone from "../FileUploadComponents/DropZone/DropZone";
import DnDWrapper from "../DnDInput/DnDWrapper/DnDWrapper";
import { useValidationsContext } from "../../../../providers/ValidationsProvider";
import FileUpload, {
  LegacyFileUpload,
} from "../FileUploadComponents/FileUpload/FileUpload";
import DnDItem from "../DnDInput/DnDItem/DnDItem";
import { useTemplatePageEditorContext } from "../../TemplatePageEditor/TemplatePageEditor";
import { useValueResolving } from "../../../../shared/CustomHooks";
import { isMobile } from "react-device-detect";
import FileBrowseButton from "../FileUploadComponents/FileBrowseButton/FileBrowseButton";

type Props = {
  param: BlockParam;
  update: (input: BlockParam | string) => void;
  subField?: boolean;
  childKey?: string;
  sectionKey: string;
  multiFile?: boolean | undefined;
  resolvedValue?: any;
  accept: string;
  overrideAdapters?: any;
  dndTextOverride?: string;
};

export type ArtistAsset = {
  _id?: never;
  id: string;
  description: string;
  slug: string;
  type: string;
  attachment: string;
  thumbnail?: string;
  title?: string;
  derivatives?: any;
  downloadUrl?: string;
};

export type FileUploadType = {
  id: string;
  status: "PENDING" | "INPROGRESS" | "COMPLETED" | "ERRORED";
  selectedFile: File;
  asset: ArtistAsset | null;
};

const FileInput = ({
  param,
  update,
  subField,
  multiFile,
  sectionKey,
  childKey,
  resolvedValue,
  accept,
  overrideAdapters,
  dndTextOverride,
}: Props) => {
  const { isValid, validateAtKey, validations } = useValidationsContext();
  const { assembleArtist } = usePlatoonArtistContext();
  const { setHighlightedKey } = useTemplatePageEditorContext();
  const key = childKey || param.key;

  const initValue: LegacyFileUpload[] = [];
  const [files, setFiles] = useValueResolving<LegacyFileUpload[]>(
    param.value || [],
    resolvedValue,
    param,
    initValue
  );

  const [errorMessage, setErrorMessage] = useState<string>("");

  const inputRef = useRef(null);

  const updateFileProps = (file: LegacyFileUpload) => {
    setFiles((prevFiles) => {
      const index = prevFiles.findIndex((prevFile) => {
        const prevFileId = prevFile.id || prevFile._id;
        const fileId = file.id || file._id;
        return prevFileId === fileId;
      });
      prevFiles[index] = file;
      return [...prevFiles];
    });
  };

  // validations
  useEffect(() => {
    if (validations) {
      let validation = sectionKey
        ? validations[sectionKey]?.[key]
        : validations[key];

      if (validation) {
        setErrorMessage(validation);
      } else {
        setErrorMessage("");
      }
    }

    // TODO:  may need a cleanup fn
  }, [validations]);

  // as we receive updates to the files, pass those values up
  useEffect(() => {
    update(valueAdd(files));
  }, [files]);

  const valueAdd = (value: FileUploadType[]) => {
    var p = { ...param, value: value };
    return p;
  };

  const handleFiles = (
    files: FileList,
    event: React.ChangeEvent<HTMLInputElement>,
    accepts?: string
  ) => {
    const filesToUpload: FileUploadType[] = Object.values(files).map((file) => {
      const fileToAdd: FileUploadType = {
        id: file.name + new Date().toISOString(),
        status: "PENDING",
        selectedFile: file,
        asset: null,
      };

      return fileToAdd;
    });

    const filesToValidate =
      param.value?.length > 0
        ? [...param.value, ...filesToUpload]
        : filesToUpload;

    // validation of file type is being treated seperately from yup
    // because yup has a prod bug with nested schema and using the array().of() method

    const acceptableFileTypes =
      accepts &&
      accepts.split(",").map((fileType: string) => {
        return fileType.trim();
      });

    const fileTypesToCheck = filesToUpload.map((file) => {
      return file.selectedFile.type;
    });

    const validFileTypes = fileTypesToCheck.every((file: string) => {
      return acceptableFileTypes?.includes(`${file}`);
    });

    if (validFileTypes || !accepts) {
      setErrorMessage("");
      isValid(filesToValidate, sectionKey, param.key)
        .then((res) => {
          if (res) {
            setFiles((prevFiles) => [...prevFiles, ...filesToUpload]);
          } else {
            validateAtKey({ [key]: filesToValidate }, sectionKey, [param.key]);
          }
        })
        .catch((error) => {
          console.warn(error);
          // this was still setting them to files, but what do we want to do instead?
          // can we break this out per file or something?
          setFiles((prevFiles) => [...prevFiles, ...filesToUpload]);
        });
    } else {
      setErrorMessage(
        `Invalid file present, must be ${
          acceptableFileTypes && [...acceptableFileTypes]
        }`
      );
    }

    // this is resetting the input so if the user deletes a file
    // then they can re-upload: if we don't set to blank string it doesn't recognize
    // the onChange event
    event.target.value = "";
  };

  const removeFile = (id: string) => {
    setFiles((prevFiles) => [
      ...prevFiles.filter((stateFile) =>
        //@ts-ignore
        stateFile._id ? stateFile._id != id : stateFile.id != id
      ),
    ]);
  };

  const onDragStart = () => {
    setHighlightedKey(sectionKey);
  };

  const reOrderFiles = (files: FileUploadType[]) => {
    setFiles(files);
  };

  const label = param.overrideName ? param.overrideName : param.name;

  return (
    <div className={styles.container}>
      {!param.hideLabel && !subField && (
        <>
          <div className="label">{label}</div>
          <label className={"sr-only"} htmlFor={param?.key}>
            {label}!!
          </label>
        </>
      )}
      {(!param.value || !param.value.length || multiFile) && (
        <>
          {isMobile ? (
            <FileBrowseButton
              buttonName={param.name}
              accept={accept}
              multiFile={multiFile}
              inputRef={inputRef}
              handleFiles={handleFiles}
            />
          ) : (
            <DropZone
              dropZoneText={`Drag and drop ${
                dndTextOverride ? dndTextOverride : "image"
              } files here`}
              handleFiles={handleFiles}
              inputRef={inputRef}
              multiFile={multiFile}
              accept={accept}
            />
          )}
          <div className={`error ${styles["error-position"]}`}>
            {errorMessage}
          </div>
        </>
      )}
      {files?.length > 0 && (
        <div className={styles["file-wrapper"]}>
          {multiFile ? (
            <DnDWrapper
              id={param.key}
              items={files}
              setItems={reOrderFiles}
              onDragStart={onDragStart}
            >
              {files?.map((file: FileUploadType, index: number) => (
                <DnDItem
                  key={file.id}
                  item={file}
                  index={index}
                  draggableId={file.id}
                >
                  <FileUpload
                    key={file.id}
                    artistId={assembleArtist?.id}
                    file={file}
                    index={index}
                    removeFile={removeFile}
                    updateFileProps={updateFileProps}
                    multiFile={true}
                    overrideAdapters={overrideAdapters}
                  />
                </DnDItem>
              ))}
            </DnDWrapper>
          ) : (
            files.map((file: LegacyFileUpload, index: number) => (
              <FileUpload
                key={file.id || file._id}
                artistId={assembleArtist?.id}
                file={file}
                index={index}
                removeFile={removeFile}
                updateFileProps={updateFileProps}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default FileInput;
