import React from "react"

interface Props {
  displayMode?: string
}

const DesktopDisplayButton = (props: Props) => {

  const iconColor = props.displayMode === 'Desktop' ? '#FFFFFF' : 'gray'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18">
      <g fill={`${iconColor}`} fillRule="evenodd" >
        <g fill={`${iconColor}`} fillRule="nonzero">
          <path d="M837.993 183c.48 0 .865-.393.865-.865 0-.473-.385-.866-.865-.866h-2.5l-.236-1.539h5.322c1.59 0 2.421-.804 2.421-2.421v-9.879c0-1.626-.83-2.43-2.42-2.43h-16.15c-1.6 0-2.43.804-2.43 2.43v9.879c0 1.617.83 2.421 2.43 2.421h5.313l-.227 1.54h-2.509c-.471 0-.856.392-.856.865 0 .472.385.865.856.865h10.986zm2.945-7.29H824.07c-.253 0-.367-.097-.367-.368v-7.807c0-.594.254-.83.83-.83h15.94c.568 0 .83.236.83.83v7.807c0 .27-.113.367-.366.367zm-8.434 2.884c-.332 0-.603-.271-.603-.603 0-.324.271-.595.603-.595.324 0 .595.271.595.595 0 .332-.271.603-.595.603z" transform="translate(-822 -165)" />
        </g>
      </g>
    </svg>
  )
}

export default DesktopDisplayButton
