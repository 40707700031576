/*
  Class names:
    asm-tabs-root
    asm-tabs-tab-list
    asm-tabs-tab
    asm-tabs-tab-active
    asm-tabs-panel-list
    asm-tabs-panel
*/

import React, { useContext } from "react";
import ViewSwitcher from "./ViewSwitcher";

type ClassOverrides = {
  rootClassName?: string;
  tabListClassName?: string;
  tabClassName?: string;
  activeTabClassName?: string;
  panelListClassName?: string;
  panelClassName?: string;
};

type TabsProps = {
  children: React.ReactNode;
  classOverrides: ClassOverrides;
  controlledIndex?: number;
};

type TabsContextProps = {
  displayedView: number;
  setDisplayedView: React.Dispatch<number>;
  classOverrides: ClassOverrides;
  isControlled: boolean;
};

type TabContextProps = {
  index: number;
  classOverrides: ClassOverrides;
};

const TabsContext = React.createContext({} as TabsContextProps);
const TabContext = React.createContext({} as TabContextProps);

const Tabs = ({ children, classOverrides, controlledIndex }: TabsProps) => {
  const [displayedView, setDisplayedView] = React.useState(
    controlledIndex ? controlledIndex : 0
  );

  const context = {
    displayedView:
      controlledIndex != undefined ? controlledIndex : displayedView,
    setDisplayedView,
    classOverrides,
    isControlled: controlledIndex != undefined,
  };

  return (
    <TabsContext.Provider value={context}>
      <div
        className={`
          asm-tabs-root
          ${classOverrides.rootClassName ? classOverrides.rootClassName : ""}
        `}
      >
        {children}
      </div>
    </TabsContext.Provider>
  );
};

type TabListProps = {
  children: React.ReactNode;
};

const TabList = ({ children }: TabListProps) => {
  const { classOverrides } = React.useContext(TabsContext);

  const childrenArr = React.Children.map(children, (child, index) => {
    return (
      <TabContext.Provider value={{ index, classOverrides }} children={child} />
    );
  });

  return (
    <div
      className={`
        asm-tabs-tab-list
        ${
          classOverrides.tabListClassName ? classOverrides.tabListClassName : ""
        }
      `}
      style={
        !classOverrides.tabListClassName
          ? {
              display: "flex",
              justifyContent: "space-between",
            }
          : {}
      }
    >
      {childrenArr}
    </div>
  );
};

interface TabProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: React.ReactNode;
  index?: string;
}

const Tab = (props: TabProps) => {
  const { displayedView, setDisplayedView, isControlled } =
    useContext(TabsContext);
  const { index, classOverrides } = useContext(TabContext);

  return (
    <button
      {...props}
      data-view={index}
      style={{
        cursor: "pointer",
        ...props.style,
      }}
      className={`
        asm-tabs-tab
        ${classOverrides.tabClassName ? classOverrides.tabClassName : ""}
        ${displayedView === index ? "asm-tabs-tab-active" : ""}
        ${
          displayedView === index && classOverrides.activeTabClassName
            ? classOverrides.activeTabClassName
            : ""
        }
        ${props.className}
      `}
      onClick={(e) => {
        // if it's not controlled, use our internal hook, otherwise, use the one provided
        // is there a way to require that onClick if it's controlled?
        !isControlled
          ? setDisplayedView(index)
          : props.onClick && props.onClick(e);
      }}
    >
      {props.children}
    </button>
  );
};

type TabPanelListProps = {
  children: React.ReactNode;
};

const TabPanelList = ({ children }: TabPanelListProps) => {
  const { displayedView, classOverrides } = useContext(TabsContext);

  // wrap each panel in a div with the propper data-view prop.
  const childrenArr = React.Children.map(children, (child, index) => {
    return (
      <div
        key={index}
        data-view={index}
        className={`
        asm-tabs-panel
        ${classOverrides.panelClassName ? classOverrides.panelClassName : ""}
        `}
      >
        {child}
      </div>
    );
  });

  // return all panels wrapped in a ViewSwitcher
  return (
    <div
      className={`
          asm-tabs-panel-list
          ${
            classOverrides.panelListClassName
              ? classOverrides.panelListClassName
              : ""
          }
        `}
    >
      <ViewSwitcher displayedView={displayedView.toString()}>
        {childrenArr}
      </ViewSwitcher>
    </div>
  );
};

const TabPanel = ({ children }: any) => {
  return children;
};

export { Tabs, Tab, TabPanelList, TabPanel, TabList };
