import React, { useContext } from 'react';
import clsx from 'clsx';
import { PlatoonContext } from "../PlatoonContext"

interface Props {
  theme: any,
  getEditorState: any,
  setEditorState: any,
  addImage: any,
  modifier: any,
  blockType: string,
  editorState: any,
  onChange: any
}

const IframeButton = (props: Props) => {
  const { setBlockEditSession } = useContext(PlatoonContext);

  const onClick = () => {

    let session = { blockType: "url" };
    setBlockEditSession(session);

  };

  const preventBubblingUp = (event: any) => { event.preventDefault(); }

  const blockTypeIsActive = () => {
    const editorState = props.getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === props.blockType;
  };

  const theme = props.theme;
  const className = blockTypeIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <React.Fragment>
      <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
        <button className={className} onClick={onClick} type="button">
          <svg width="24px" height="24px" viewBox="0 0 27 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="WYSIWYG-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Menu-Buttons" transform="translate(-37.000000, -205.000000)" fill="#888888" fillRule="nonzero">
                <path d="M45.4650117,217.335938 C46.0958711,217.335938 46.4943086,216.837891 46.4943086,216.281738 C46.4943086,215.758789 46.212082,215.360352 45.6310273,215.169434 L40.3102266,213.193848 L40.3102266,213.11084 L45.6310273,211.176758 C46.2203828,210.994141 46.4943086,210.628906 46.4943086,210.089355 C46.4943086,209.533203 46.0958711,209.051758 45.4650117,209.051758 C45.149582,209.051758 44.9420625,209.118164 44.6349336,209.234375 L38.6666719,211.716309 C37.8697969,212.031738 37.5128633,212.504883 37.5128633,213.202148 C37.5128633,213.899414 37.8780976,214.380859 38.6666719,214.687988 L44.6349336,217.14502 C44.9420625,217.26123 45.149582,217.335938 45.4650117,217.335938 Z M48.6667227,220.838867 C49.2560781,220.838867 49.5964102,220.564941 49.7707266,219.967285 L53.4479727,207.275391 C53.4894766,207.134277 53.5060781,207.009766 53.5060781,206.860352 C53.5060781,206.254395 53.0744375,205.839355 52.4269766,205.839355 C51.8376211,205.839355 51.4972891,206.138184 51.3146719,206.777344 L47.6457266,219.427734 C47.6125234,219.552246 47.5876211,219.70166 47.5876211,219.867676 C47.5876211,220.415527 47.9777578,220.838867 48.6667227,220.838867 Z M55.6452891,217.335938 C55.952418,217.335938 56.1599375,217.26123 56.4670664,217.14502 L62.4436289,214.679688 C63.2405039,214.364258 63.5891367,213.882812 63.5891367,213.185547 C63.5891367,212.488281 63.2239023,212.015137 62.4353281,211.708008 L56.4670664,209.234375 C56.1599375,209.118164 55.952418,209.051758 55.6452891,209.051758 C55.0144297,209.051758 54.6076914,209.533203 54.6076914,210.089355 C54.6076914,210.628906 54.889918,210.98584 55.4709727,211.176758 L60.7917734,213.160645 L60.7917734,213.243652 L55.4709727,215.169434 C54.889918,215.360352 54.6076914,215.758789 54.6076914,216.281738 C54.6076914,216.837891 55.0144297,217.335938 55.6452891,217.335938 Z" id="iframe"></path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </React.Fragment>

  );
}



export default IframeButton;
