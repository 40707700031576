import React, { useState, useEffect } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { Grid, makeStyles, TextareaAutosize, Theme } from "@material-ui/core";
import { useDebounce } from "../../shared/CustomHooks";

interface Props {
  name?: string;
  label?: string;
  onChange?: any;
  artistLinkKey?: any;
  id?: any;
  multiline?: any;
  disabled?: boolean;
  validators?: string[];
  errorMessage?: string[];
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  type?: string;
  characterCount?: number;
  characterLimit?: number;
  display?: string;
  padding?: boolean;
  onKeyPress?: any;
  onBlur?: () => void;
  onFocus?: () => void;
}

interface StyleProps {
  display: string | undefined;
  padding: boolean | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputStyle: {
    whiteSpace: "pre-line",
    display: ({ display }: StyleProps) => display,
  },
  inputPadding: {
    paddingLeft: ({ padding }: StyleProps) => (padding ? 0 : theme.spacing(3)),
    paddingRight: ({ padding }: StyleProps) => (padding ? 0 : theme.spacing(3)),
    padding: theme.spacing(1),
  },
}));

//using TextareaAutosizeProps didn't work, using any for now
const CustomInput = (props: any) => {
  const { inputRef, ...other } = props;
  return (
    <TextareaAutosize
      ref={inputRef}
      {...other}
      //using inline style due to issues with class overrides
      style={{ minWidth: "calc(100% - 20px)" }}
    />
  );
};
export default function ArtistLinkInput(props: Props) {
  const classes = useStyles({ display: props.display, padding: props.padding });
  const showHelperText = props.characterCount
    ? `${props.characterCount}/${props.characterLimit}`
    : "";

  const [localValue, setLocalValue] = useState(props.artistLinkKey || "");
  const debouncedValue = useDebounce(localValue, 500);

  useEffect(() => {
    props.onChange && props.onChange({ name: props.id, value: debouncedValue });
  }, [debouncedValue]);

  useEffect(() => {
    if (localValue !== props.artistLinkKey) {
      setLocalValue(props.artistLinkKey || "");
    }
  }, [props.artistLinkKey]);

  return (
    <Grid item xs={12}>
      <div className={classes.inputPadding}>
        <TextValidator
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          value={localValue}
          onChange={(e: any) => setLocalValue(e.target.value)}
          onKeyPress={props.onKeyPress}
          disabled={props.disabled}
          type={props.type}
          autoComplete="false"
          id={props.id}
          helperText={showHelperText}
          rows={3}
          name={props.name ? props.name : ""}
          fullWidth
          placeholder={props.label}
          validators={props.validators}
          errorMessages={props.errorMessage}
          InputProps={{
            inputComponent: props.multiline && CustomInput,
            startAdornment: props.startAdornment,
            endAdornment: props.endAdornment,
            className: classes.inputStyle,
          }}
        />
      </div>
    </Grid>
  );
}
