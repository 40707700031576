import { SvgIcon } from "@material-ui/core";
import React from "react";
import CustomTile from "../../../promo-pack-builder/PromoPackCreator/CustomTile";
import styles from "./CreateFromScratch.module.scss";
import { ReactComponent as Plus } from "../../icons/plus.svg";

type Props = {
  onClick: () => void;
  selected: boolean;
};

const CreateFromScratch = ({ onClick, selected }: Props) => {
  return (
    <div onClick={onClick} className={styles["container"]}>
      <CustomTile
        text="CREATE FROM SCRATCH"
        icon={
          <SvgIcon className={styles["icon"]}>
            <Plus />
          </SvgIcon>
        }
        selected={selected}
      />
    </div>
  );
};

export default CreateFromScratch;
