//COMPONENT NOT BEING USED CURRENTLY

import React, { useContext } from "react";
import Template from "./models/Template";
import Block from "./models/Block";
import TemplateManager from "../../bundles/promo-pack-builder/templates/TemplateManager";

const EditorStateContext = React.createContext<any>(null);
export const useEditorStateContext = () => useContext(EditorStateContext)!;

const EditorStateProvider = ({ children }: any) => {
  const starterTemplate = TemplateManager.masterTemplates[0];
  const [editorState, setEditorState] = React.useState<TemplateEditorState>({
    template: starterTemplate,
    stage: null,
    selectedBlocks: [],
    selectedBlock: undefined,
  });
  const readOnly = false;

  const blockIsSelected = (block: Block) => {
    let blockIndex = editorState?.selectedBlocks?.findIndex(
      (chosenBlock: Block) => block.id === chosenBlock.id
    );
    return blockIndex && blockIndex > -1;
  };

  const selectBlocks = (block: Block, select: boolean) => {
    const isSelected = blockIsSelected(block);
    if (select && !isSelected) {
      var eS = { ...editorState };
      if (eS && eS.selectedBlocks) {
        eS.selectedBlocks = [block, ...eS.selectedBlocks];
        setEditorState(eS);
      }
    } else if (!select && isSelected) {
      var eS = { ...editorState };
      var i = eS?.selectedBlocks?.indexOf(block);
      i && eS?.selectedBlocks?.splice(i, 1);
      setEditorState(eS);
    }
  };

  const selectBlock = (block: Block) => {
    const eS = { ...editorState };
    eS.selectedBlock = block;
    setEditorState(eS);
  };

  return (
    <EditorStateContext.Provider
      value={{
        editorState,
        setEditorState,
        blockIsSelected,
        selectBlocks,
        selectBlock,
        readOnly,
      }}
    >
      {children}
    </EditorStateContext.Provider>
  );
};

interface TemplateEditorState {
  template: Template;
  selectedBlock?: Block;
  selectedBlocks?: Block[];
  stage: any;
}

function emptyEditorState() {
  return {
    template: {},
    selectedBlock: undefined,
    selectedBlocks: undefined,
  } as TemplateEditorState;
}

export { TemplateEditorState, emptyEditorState, EditorStateContext };
export default EditorStateProvider;
