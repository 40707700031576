import ResourceSchema, {
  emptyResourceSchema,
  UserRole,
} from "../../shared/ResourceSchema";

function adaptPromoPackToBackend(frontendObject: any) {
  let target = {
    id: frontendObject.id,
    name: frontendObject.name,
    p4aReleaseId: frontendObject.p4aReleaseId,
    templateIdentifier: frontendObject.templateIdentifier,
    templateConfig: JSON.stringify(frontendObject.templateConfig),
    marketingMessage: frontendObject.marketingMessage,
    assetsFinishedAt: frontendObject.assetsFinishedAt,
    createdAt:
      frontendObject.createdAt && frontendObject.createdAt.toISOString(),
    updatedAt:
      frontendObject.updatedAt && frontendObject.updatedAt.toISOString(),
    artist: {
      id: frontendObject.artist.id,
      p4aArtistId: frontendObject.artist.p4aArtistId,
      name: frontendObject.artist.name,
      slug: frontendObject.artist.slug,
    },
  };
  return target;
}

function tryParseJSON(jsonString: string) {
  try {
    var output = JSON.parse(jsonString);

    if (output && typeof output === "object") {
      return output;
    }
  } catch (e) {
    console.log("🛑: Bad JSON", e, "\n", { jsonString });
  }
  return undefined;
}

function adaptPromoPackToFrontend(backendObject: any) {
  // if we always go fetch this, on this step, it slows us WAY down.
  // Better to fetch all releases just once, and match up in the provider

  const target = {
    id: backendObject.id,
    name: backendObject.name,
    p4aReleaseId: backendObject.p4aReleaseId,
    templateIdentifier: backendObject.templateIdentifier,
    templateConfig: tryParseJSON(backendObject.templateConfig),
    marketingMessage: backendObject.marketingMessage,
    thumbnail: backendObject.thumbnail,
    assetsFinishedAt: backendObject.assetsFinishedAt,
    assetsFinished: backendObject.assetsFinished,
    createdAt:
      backendObject.createdAt && new Date(Date.parse(backendObject.createdAt)),
    updatedAt:
      backendObject.updatedAt && new Date(Date.parse(backendObject.updatedAt)),
    artist: {
      id: backendObject.artist.id,
      p4aArtistId: backendObject.artist.p4aArtistId,
      name: backendObject.artist.name,
      slug: backendObject.artist.slug,
    },
    promoAssets: backendObject.promoAssets,
  };
  return target;
}

const PromoPackSchema: ResourceSchema = {
  ...emptyResourceSchema(),
  ...{
    title: "Promo Pack",
    pluralTitle: "Promo Packs",
    rootPath: "promo-packs",
    apiRootPath: "promo-packs",
    attrs: ["title", "artist"], // ??
    adaptor: {
      toFrontend: adaptPromoPackToFrontend,
      toBackend: adaptPromoPackToBackend,
    },
    role: UserRole.Artist,
  },
};

export { adaptPromoPackToFrontend, adaptPromoPackToBackend };
export default PromoPackSchema;
