import React, { useEffect, useContext, useState } from "react";
import { ClickAwayListener, InputBase, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import StyleGuide from "../../StyleGuide";
import SearchResultsContainer from "./SearchResultsContainer";
import { ContentNode, ContentNodeQueryResult } from "../ContentProvider";
import { TOCContext } from "./TableOfContentsMenu";

type Props = {
  contentNode: ContentNode;
};

const useStyles = makeStyles((theme) => ({
  clearIcon: {
    color: "#979797",
    marginLeft: "55px",
    [theme.breakpoints.down(StyleGuide.breakpoints.topNav)]: {
      marginRight: "1%",
      marginTop: "1px",
    },
  },
  inputSearch: {
    marginLeft: "4px",
    "& .MuiInputBase-input": {
      color: "#fff",
      fontFamily: "Helvetica",
      fontSize: "12px",
      fontWeight: "bold",
      fontStyle: "normal",
      lineHeight: "16px",
      letterSpacing: 0,
      textTransform: "uppercase",
    },
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "44px",
    borderRadius: "4px",
    border: "solid 1px #303030",
  },
  searchIcon: {
    color: "#979797",
    marginLeft: "16px",
  },
  searchContainer: {
    position: "relative",
  },
  searchFlex: {
    display: "flex",
  },
}));

const TOCSearch = ({ contentNode }: Props) => {
  const classes = useStyles();
  const { setOpen, open } = useContext(TOCContext);

  const [resultsShow, setResultsShow] = useState(false);

  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const [searchResults, setSearchResults] = React.useState<
    ContentNodeQueryResult[]
  >([]);

  useEffect(() => {
    if (searchQuery) {
      let results = contentNode.queryResultsMatchingDefaults(searchQuery);
      setSearchResults(results);
    }
  }, [searchQuery]);

  const handleSearch = (event: any) => {
    setSearchQuery(
      event.target.value.length > 0 ? event.target.value : undefined
    );
  };

  const clearSearch = () => {
    setSearchQuery(undefined);
  };

  return (
    <div
      id="search-container"
      className={classes.searchContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.searchFlex}>
        <div className={classes.searchBox}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.inputSearch}
            placeholder="search"
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
            readOnly={false}
            value={searchQuery ? searchQuery : ""}
            onFocus={() => {
              setResultsShow(true);
              setOpen(false);
            }}
          />
          {searchQuery && (
            <ClearIcon className={classes.clearIcon} onClick={clearSearch} />
          )}
        </div>
      </div>

      {searchQuery && resultsShow && !open && (
        <ClickAwayListener onClickAway={() => setResultsShow(false)}>
          <div>
            <SearchResultsContainer
              results={searchResults}
              clearSearch={clearSearch}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default TOCSearch;
