import {
  ClickAwayListener,
  isWidthUp,
  makeStyles,
  withWidth,
} from "@material-ui/core";
import React, { useState } from "react";
import { ContentNode } from "../ContentProvider";
import TOCDropDown from "./TOCDropDown";
import TOCBreadcrumb from "./TOCBreadcrumb";
import { ReactComponent as ChevronDown } from "../../../shared/icons/chevron-down.svg";
import { ReactComponent as XCircleFill } from "../../../shared/icons/x-circle-fill.svg";
import TOCSearch from "./TOCSearch";
import { useAnalyticsContext } from "../../../providers/AnalyticsProvider";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

type Props = {
  contentNode: ContentNode;
  width: Breakpoint;
};

type StyleProps = {
  isMobile: Boolean;
  open: Boolean;
};

const useStylesTableOfContentsMenu = makeStyles(() => ({
  root: {
    cursor: "pointer",
    color: "white",
    padding: ({ isMobile }: StyleProps) => (isMobile ? "14px" : "0"),
    border: ({ open }) =>
      open ? "1px solid #1d1d1d" : "1px solid transparent",
    borderRadius: "4px",
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.7)",
    transition: "200ms ease-in-out",

    // positioning
    position: "relative",
    zIndex: 2,
  },
  topContainer: {
    backgroundColor: ({ open }) => (open ? "black" : "#141414"),
    borderRadius: "4px",
    padding: "15px 18px",
    display: "flex",
    alignItems: "center",
    flexDirection: ({ isMobile }) => (isMobile ? "row-reverse" : "row"),
  },
  black: {
    backgroundColor: "#000",
    transition: "200ms ease-in-out",
  },
  menuAction: {
    cursor: "pointer",
    width: "21px",
    height: "21px",
    marginRight: "28px",
    fontFamily: "SFCompact-Regular",

    userSelect: "none",
    msUserSelect: "none",
    mozUserSelect: "none",
    khtmlUserSelect: "none",
    webkitUserSelect: "none",
    webkitTouchCallout: "none",
  },
  breadcrumb: {
    flex: 1,
  },
  search: {
    width: "277px",
  },
}));

type TOCContextProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
};

export const TOCContext = React.createContext({} as TOCContextProps);

const TableOfContentsMenu = ({ contentNode, width }: Props) => {
  const { trackTOCClicked } = useAnalyticsContext();

  const [open, setOpen] = useState(false);
  const isMobile = !isWidthUp("sm", width);

  const classes = useStylesTableOfContentsMenu({ isMobile, open });

  const currentNode = contentNode.findByPath(window.location.href);

  const TOCClickHandler = () => {
    if (!open && currentNode) {
      trackTOCClicked(currentNode);
    }
    setOpen(!open);
  };

  return (
    <TOCContext.Provider value={{ setOpen: setOpen, open: open }}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div
          className={`${classes.root} ${open && classes.black}`}
          onClick={TOCClickHandler}
        >
          <div className={`${classes.topContainer} `}>
            <div className={classes.menuAction}>
              {open ? <XCircleFill /> : <ChevronDown />}
            </div>
            <div className={classes.breadcrumb}>
              <TOCBreadcrumb
                contentNode={(currentNode as ContentNode) || contentNode}
              />
            </div>
            {!isMobile && (
              <div className={classes.search}>
                <TOCSearch contentNode={contentNode} />
              </div>
            )}
          </div>
          {open && <TOCDropDown contentNode={contentNode} />}
        </div>
      </ClickAwayListener>
    </TOCContext.Provider>
  );
};

export default withWidth()(TableOfContentsMenu);
