import React, { useContext } from "react";
import { PlatoonContext } from "../../PlatoonContext";
import { Box } from "@material-ui/core";
import { AssetType } from "../../../models/Asset";
import AssetPreviewContent from "./AssetPreviewContent";
import AssetBlock, { emptyAssetBlock } from "./AssetBlock";

interface Props {
  assetType: AssetType;
}

export default function AssetPreviewBlockPicker({ assetType }: Props) {
  const { blockEditSession, setBlockEditSession } = useContext(PlatoonContext);

  const assetBlock =
    (blockEditSession.blockData as AssetBlock) || emptyAssetBlock(assetType);

  const setAssetBlock = (assetBlock: AssetBlock | undefined) => {
    let s = { ...blockEditSession };
    s.blockData = assetBlock;
    setBlockEditSession(s);
  };

  return (
    <Box>
      <AssetPreviewContent
        assetBlock={assetBlock}
        setAssetBlock={setAssetBlock}
      />
    </Box>
  );
}
