import React, { useState } from "react";
import { Logger } from "../shared/SafeLogger";
import { CMSNode } from "./NodeSchema";
import {
  AssetBlockPickerModal,
  AssetBlock,
  emptyAssetBlock,
  AssetType,
} from "../platoon-cms-lib";
import { ResponseError } from "../shared/API";
import { Link } from "react-router-dom";
import {
  Divider,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Fab,
  Tooltip,
  Switch,
} from "@material-ui/core";
import {
  Image as ImageIcon,
  Delete as CancelIcon,
  OpenInNew as OpenInNew,
} from "@material-ui/icons";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { isURL } from "../shared/helpers/helpers";

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    width: "100%",
  },
  imageWell: {
    width: "100px",
    height: "100px",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    position: "relative",
  },
  icon: {
    width: "100%",
    height: "100%",
    position: "absolute",
    objectFit: "contain",
  },
  iconPlaceholder: {
    width: "100px",
    height: "100px",
    padding: "20px",
    position: "absolute",
  },
  deleteIcon: {
    float: "right",
    width: "40px",
    height: "40px",
    top: "-20px",
    right: "-20px",
  },
}));

interface Props {
  node: CMSNode;
  setNode: React.Dispatch<React.SetStateAction<CMSNode | undefined>>;
  responseError?: ResponseError;
  cardTypes: string[];
}

export default function NodeAttributePane({
  node,
  setNode,
  responseError,
  cardTypes,
}: Props) {
  const [pickerAssetBlock, setPickerAssetBlock] = useState<AssetBlock>();
  const [iconHover, setIconHover] = useState(false);
  const classes = useStyles();

  const handleItemChange = (event: any) => {
    let newNode = node?.copyNode() as any;
    newNode[event.target.name] = event.target.value;

    setNode(newNode);
  };

  const handleCardTypeSelectChange = (event: any) => {
    let newNode = node?.copyNode() as any;
    newNode.cardType = event.target.value;
    setNode(newNode);
  };

  const handlePublishChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newNode = node?.copyNode() as any;
    // setPublished(event.target.checked);
    newNode.published = event.target.checked;
    setNode(newNode);
  };

  const handleDeleteIcon = (event: any) => {
    event.stopPropagation();
    let newNode = node?.copyNode() as any;
    newNode.icon = "";
    Logger.of("App").info("Setting icon to null", newNode);
    setNode(newNode);
  };

  const openIconPicker = () => {
    // start a new blockEditSession if true
    setPickerAssetBlock(emptyAssetBlock(AssetType.image));
  };

  const handleIconPickerSave = () => {
    // use pickerAssetBlock to set the icon asset
    // if we are chosing an asset, we will provide the ID to the asset,
    // otherwise we are hotlinking, and that should save the full URL (attachment)

    const id = pickerAssetBlock?.asset?.id || null;
    const attachment = pickerAssetBlock?.asset?.attachment;
    let newNode = node?.copyNode() as CMSNode;
    newNode.icon = id || attachment;
    setNode(newNode);
    setPickerAssetBlock(undefined);
  };

  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <Switch
            checked={node.published}
            onChange={handlePublishChange}
            name="published"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label="Published"
      />

      {/*
        not sure this node.previewPath is going to work when deployed.
        I believe we are going to have to send them to platoon.fm/learn/{article ID}
      */}
      <Link to={node.previewPath} target="_blank">
        <IconButton color="secondary" aria-label="preview" component="span">
          <OpenInNew />
        </IconButton>
      </Link>

      <TextField
        color="secondary"
        id="title"
        name="title"
        label="Title"
        value={node.title}
        onChange={handleItemChange}
        helperText={responseError && responseError.reasons.title}
        error={responseError && responseError.reasons.title !== undefined}
        fullWidth
        margin="normal"
      />
      <TextField
        color="secondary"
        id="subtitle"
        name="subtitle"
        label="Subtitle"
        value={node.subtitle}
        onChange={handleItemChange}
        helperText={responseError && responseError.reasons.subtitle}
        error={responseError && responseError.reasons.subtitle !== undefined}
        fullWidth
        margin="normal"
      />
      <TextField
        color="secondary"
        id="description"
        name="description"
        label="Description"
        type="text"
        value={node.description}
        onChange={handleItemChange}
        helperText={responseError && responseError.reasons.description}
        error={responseError && responseError.reasons.description !== undefined}
        fullWidth
        margin="normal"
      />
      <TextField
        color="secondary"
        id="identifier"
        name="identifier"
        label="Unique Page Identifier"
        type="text"
        value={node.identifier}
        onChange={handleItemChange}
        helperText={responseError && responseError.reasons.identifier}
        error={responseError && responseError.reasons.identifier !== undefined}
        fullWidth
        margin="normal"
      />

      {node.hasParent && (
        <React.Fragment>
          <Typography variant="h6">Icon</Typography>
          <div
            className={classes.imageWell}
            onClick={openIconPicker}
            onMouseEnter={() => setIconHover(true)}
            onMouseLeave={() => setIconHover(false)}
          >
            {node.icon && node.icon.length > 0 ? (
              <React.Fragment>
                <img
                  src={
                    isURL(node.icon)
                      ? node.icon
                      : `/api/v1/signed-assets/${node.icon}`
                  }
                  className={classes.icon}
                />

                {iconHover && (
                  <Tooltip title="Remove Icon" aria-label="new" placement="top">
                    <Fab
                      className={classes.deleteIcon}
                      onClick={handleDeleteIcon}
                      color="secondary"
                      aria-label="delete"
                      size="small"
                    >
                      <CancelIcon />
                    </Fab>
                  </Tooltip>
                )}
              </React.Fragment>
            ) : (
              <ImageIcon
                className={classes.iconPlaceholder}
                color={iconHover ? "action" : "disabled"}
              />
            )}
          </div>

          {pickerAssetBlock && (
            <AssetBlockPickerModal
              assetBlockProps={{
                assetBlock: pickerAssetBlock,
                setAssetBlock: setPickerAssetBlock,
              }}
              onSave={handleIconPickerSave}
            />
          )}
        </React.Fragment>
      )}

      {node.type === "section" && (
        <FormControl
          className={classes.select}
          error={responseError && responseError.reasons.card_type !== undefined}
        >
          <InputLabel color="secondary" id="cardType">
            Contained Page Card Type
          </InputLabel>
          <Select
            id="cardType"
            color="secondary"
            value={node.cardType}
            onChange={handleCardTypeSelectChange}
          >
            {cardTypes.map((cardType: string) => (
              <MenuItem key={cardType} value={cardType}>
                {cardType.capitalize()}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {responseError && responseError.reasons.card_type}
          </FormHelperText>
        </FormControl>
      )}

      <Divider />
    </React.Fragment>
  );
}
