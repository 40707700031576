import createFocusPlugin from 'draft-js-focus-plugin';
import createSideToolbarPlugin from 'draft-js-side-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin'
import 'draft-js-side-toolbar-plugin/lib/plugin.css';
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const blockDndPlugin = createBlockDndPlugin();
const blockBreakoutPlugin = createBlockBreakoutPlugin()
const focusPlugin = createFocusPlugin(); //to get the hover effect
const linkPlugin = createLinkPlugin();

const sideToolbarPlugin = createSideToolbarPlugin({
  position: 'left',
});
const { SideToolbar } = sideToolbarPlugin;

const Plugins = [
  linkPlugin,
  sideToolbarPlugin,
  inlineToolbarPlugin,
  focusPlugin,
  blockBreakoutPlugin

];

export default Plugins;
export {
  linkPlugin,
  SideToolbar,
  InlineToolbar,
  focusPlugin,
  blockDndPlugin, blockBreakoutPlugin
};

