import React, { useEffect } from "react";
import { Logger } from "./SafeLogger";
import { useLocation } from "react-router-dom";

import RockDove from "./RockDove";

export default function HistoryPoster(props: any) {
  const location = useLocation();

  useEffect(() => {
    postHistory();
  }, [location]);

  function postHistory() {
    // this gets sent off to the framerouter, and changes out the location.  make sure all parts of the url are being sent forward!
    Logger.of("App").info(
      "$$$ Sending history notification",
      `${location.pathname}${location.search}`,
      props
    );
    RockDove.shared().sendHistoryNotification(
      `${location.pathname}${location.search}`
    ); //TODO: Hook this up again
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}
