import React from "react";
import { BeforeCapture, DragDropContext, Droppable } from "react-beautiful-dnd";

type DnDWrapperProps = {
  id: string;
  items: any; //keeping as any for other apps to use
  setItems: any; //keeping as any for other apps to use
  children: React.ReactNode;
  onDragStart?: any;
  setSectionDragging?: React.Dispatch<React.SetStateAction<string | null>>;
};

const DnDWrapper = ({
  id,
  items,
  setItems,
  onDragStart,
  children,
  setSectionDragging,
}: DnDWrapperProps) => {
  const handleOnBeforeCapture = (before: BeforeCapture) => {
    onDragStart && onDragStart();
    setSectionDragging && setSectionDragging(before.draggableId);
  };

  const reOrder = (items: any, startIndex: number, endIndex: number) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      //resets sectionDragging if no destination found
      setSectionDragging && setSectionDragging(null);
      return;
    }
    const reOrderedItems = reOrder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(reOrderedItems);
  };

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onBeforeCapture={handleOnBeforeCapture}
    >
      <Droppable droppableId={id}>
        {(droppableProvided) => (
          <>
            <div ref={droppableProvided.innerRef}>
              {children}
              {droppableProvided.placeholder}
            </div>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnDWrapper;
