import ResourceSchema, {
  emptyResourceSchema,
  UserRole,
} from "../shared/ResourceSchema";
import {
  adaptToFrontend,
  adaptTracksToFrontend,
  adaptTrackLinksToFrontend,
} from "../artists/ArtistSchema";

function adaptArtistLinkToBackend(frontendObject: any) {
  let target = {
    id: frontendObject.id,
    shortId: frontendObject.shortId,
    slug: frontendObject.slug,
    title: frontendObject.title,
    headline: frontendObject.headline,
    description: frontendObject.description,
    password: frontendObject.password,
    audioDownloadPassword: frontendObject.audioDownloadPassword,
    expires: frontendObject.expires,
    p4aReleaseId: frontendObject.p4aReleaseId,
    playerColor: frontendObject.playerColor,
    playerFont: frontendObject.playerFont,
    textColor: frontendObject.textColor,
    backgroundColor: frontendObject.backgroundColor,
    backgroundImage: frontendObject.backgroundImage,
    backgroundImageId: frontendObject.backgroundImageID,
    backgroundMode: frontendObject.backgroundMode,
    gaussianBlur: frontendObject.gaussianBlur,
    descriptionFont: frontendObject.descriptionFont,
    albumArtwork: frontendObject.albumArtwork,
    albumArtworkId: frontendObject.albumArtworkID,
    artistNameFont: frontendObject.artistNameFont,
    artistNameLogo: frontendObject.artistNameLogo,
    artistNameLogoId: frontendObject.artistNameLogoID,
    artistNameMode: frontendObject.artistNameMode,
    requiresPassword: frontendObject.requiresPassword,
    requiresAudioDownloadPassword: frontendObject.requiresAudioDownloadPassword,
    audioDownloadButton: frontendObject.audioDownloadButton,
    audioDownloadButtonColor: frontendObject.audioDownloadButtonColor,
    audioDownloadButtonPosition: frontendObject.audioDownloadButtonPosition,
    startDateTime:
      frontendObject.startDateTime &&
      frontendObject.startDateTime.toISOString(),
    endDateTime:
      frontendObject.endDateTime && frontendObject.endDateTime.toISOString(),
    headlineFont: frontendObject.headlineFont,
    tracks: frontendObject.tracks,
    trackLinks: frontendObject.trackLinks,
    artistSocials: frontendObject.artistSocials,
  };
  return target;
}

function adaptArtistLinkToFrontend(backendObject: any, depth = 2) {
  let target = {
    id: backendObject.id,
    shortId: backendObject.shortId,
    slug: backendObject.slug,
    title: backendObject.title,
    headline: backendObject.headline,
    description: backendObject.description,
    password: backendObject.password,
    audioDownloadPassword: backendObject.audioDownloadPassword,
    expires: backendObject.expires,
    p4aReleaseId: backendObject.p4aReleaseId,
    playerColor: backendObject.playerColor,
    playerFont: backendObject.playerFont,
    textColor: backendObject.textColor,
    backgroundColor: backendObject.backgroundColor,
    backgroundImage: backendObject.backgroundImage,
    backgroundImageID: backendObject.backgroundImageId,
    backgroundMode: backendObject.backgroundMode,
    gaussianBlur: backendObject.gaussianBlur,
    descriptionFont: backendObject.descriptionFont,
    albumArtwork: backendObject.albumArtwork,
    albumArtworkID: backendObject.albumArtworkId,
    artistNameFont: backendObject.artistNameFont,
    artistNameLogo: backendObject.artistNameLogo,
    artistNameLogoID: backendObject.artistNameLogoId,
    artistNameMode: backendObject.artistNameMode,
    requiresPassword: backendObject.requiresPassword,
    requiresAudioDownloadPassword: backendObject.requiresAudioDownloadPassword,
    audioDownloadButton: backendObject.audioDownloadButton,
    audioDownloadButtonColor: backendObject.audioDownloadButtonColor,
    audioDownloadButtonPosition: backendObject.audioDownloadButtonPosition,
    startDateTime:
      backendObject.startDateTime &&
      new Date(Date.parse(backendObject.startDateTime)),
    endDateTime:
      backendObject.endDateTime &&
      new Date(Date.parse(backendObject.endDateTime)),
    headlineFont: backendObject.headlineFont,
    artist: depth > 1 ? adaptToFrontend(backendObject.artist) : null,
    tracks: depth > 1 ? adaptTracksToFrontend(backendObject.tracks) : null,
    trackLinks:
      depth > 1 ? adaptTrackLinksToFrontend(backendObject.trackLinks) : null,
    artistSocials: backendObject.artistSocials,
  };
  return target;
}

const ArtistLinkSchema: ResourceSchema = {
  ...emptyResourceSchema(),
  ...{
    title: "Artist Link",
    pluralTitle: "Stealth Players",
    rootPath: "links",
    apiRootPath: "links",
    attrs: ["title", "artist"],
    adaptor: {
      toFrontend: adaptArtistLinkToFrontend,
      toBackend: adaptArtistLinkToBackend,
    },
    role: UserRole.Artist,
  },
};

export { adaptArtistLinkToFrontend, adaptArtistLinkToBackend };
export default ArtistLinkSchema;
