import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import ArtistLinkInputHeader from "../../LinkEditComponents/ArtistLinkInputHeader";
import ArtistLinkRadioInput from "../../LinkEditComponents/ArtistLinkRadioInput";
import ArtistLinkInput from "../../LinkEditComponents/ArtistLinkInput";
import { Update } from "../../ArtistLinkEdit/FormFields";
import { useArtistLinkContext } from "../../../providers/ArtistLinkProvider";

interface Props {
  onChange: any;
  setForceOpenCollapse: any;
}

const ArtistPassword = (props: Props) => {
  const { artistLink, ogArtistLink, setOGArtistLink, dispatch } =
    useArtistLinkContext();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [hasPassword, setHasPassword] = useState(false);

  const passwordHeader = hasPassword ? "PASSWORD" : "CREATE PASSWORD";
  const passwordEditable = hasPassword;

  useEffect(() => {
    if (ogArtistLink?.password) {
      setHasPassword(true);
    }
  }, [artistLink.password]);

  const confirmPasswordChange = (update: Update) => {
    props.setForceOpenCollapse([]);
    setConfirmPassword(update.value);
  };

  const resetPassword = () => {
    setOGArtistLink({ ...(ogArtistLink as any), password: null });
    dispatch({
      type: "SET_ARTISTLINK",
      payload: { ...artistLink, password: null },
    });
    setHasPassword(false);
  };

  return (
    <Box pb={4}>
      <ArtistLinkInputHeader header={"SITE PASSWORD"} variant={"h6"} />

      <Box pl={4} display="flex">
        <ArtistLinkRadioInput
          checkedValue={artistLink.requiresPassword}
          name={"requiresPassword"}
          label={"ON"}
          value={true}
        />
        <ArtistLinkRadioInput
          checkedValue={artistLink.requiresPassword}
          name={"requiresPassword"}
          label={"OFF"}
          value={false}
        />
      </Box>

      {artistLink.requiresPassword && (
        <Box>
          <ArtistLinkInputHeader header={passwordHeader} variant={"h6"} />
          <ArtistLinkInput
            disabled={passwordEditable}
            name={"password"}
            id={"password"}
            label={"Enter Password"}
            onChange={props.onChange}
            artistLinkKey={artistLink.password}
            validators={["required"]}
            errorMessage={["Password is required"]}
          />
          {!hasPassword && (
            <>
              <ArtistLinkInputHeader
                header={"CONFIRM PASSWORD"}
                variant={"h6"}
              />
              <ArtistLinkInput
                name={"confirmPassword"}
                id={"confirmPassword"}
                label={"Confirm Password"}
                onChange={confirmPasswordChange}
                artistLinkKey={confirmPassword}
                validators={["isPasswordMatch"]}
                errorMessage={["Passwords do not match"]}
              />
            </>
          )}
        </Box>
      )}
      {hasPassword && artistLink.requiresPassword && (
        <Box pl={3} pr={3} display="flex" justifyContent="flex-end">
          <Button onClick={resetPassword} variant="contained" color="secondary">
            Reset
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ArtistPassword;
