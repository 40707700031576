import { Typography, makeStyles, Grid } from '@material-ui/core';
import React from 'react';
import StyledLink from './StyledLink';

type Props = {
  text: string,
  action?: {
    to: string;
    text: string;
  };
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    padding: `${theme.spacing(12)}px 0px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(12)}px 20px`
    },
    textAlign: 'center',

  },
  text: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '.75rem'
    },
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'inline-block',
  },
  newLinkText: {
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    color: theme.palette.text.secondary,
    marginLeft: '.5rem',
  },
}));

const NoResults = ({ text, action }: Props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item>

        <Typography variant="h5" className={`${classes.text} ${classes.newLinkText}`} >
          {text}
        </Typography>

        {action &&
          <StyledLink to={action.to}>
            <Typography variant="h5" className={`${classes.text} ${classes.secondary}`}  >
              {action.text}
            </Typography>
          </StyledLink>
        }
      </Grid>
    </Grid>
  );
};

export default NoResults;
