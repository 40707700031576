import React, { useState } from "react";
import AssetBlock from "../asset/AssetBlock";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactPlayer from "react-player";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import Asset from "../../../models/Asset";
import Hover from "../../Hover";
import { makeStyles, Theme } from "@material-ui/core";
import { HoverActions } from "../../PlatoonContext";

const useStyles = makeStyles((theme: Theme) => ({
  imageCentered: {
    [theme.breakpoints.down(420)]: {
      marginTop: "10%",
    },
    marginTop: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
  },
  cellPhonePlayer: {
    marginTop: "15px",
    marginBottom: "50px",
  },
  playIcon: {
    marginTop: "175px",
    position: "absolute",
    zIndex: 1,
    fontSize: "50px",
    color: "#fff",
  },
  cellPhoneImage: {
    position: "absolute",
    height: "390px",
  },
}));

interface Props {
  assetBlock?: AssetBlock; //used to be src, let's name it something really readable and specific to this module
  hoverActions: HoverActions;
}

interface VideoURLProps {
  asset: Asset;
  hoverActions: HoverActions;
}

const PhoneVideoRenderer = ({ asset, hoverActions }: VideoURLProps) => {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  const assetLoaded = () => {
    setLoading(false);
  };
  const togglePlaying = () => {
    setIsPlaying(!isPlaying);
  };

  const url =
    asset.description === "hotlinked image"
      ? asset.attachment
      : `/api/v1/signed-assets/${asset.id}`;

  return (
    <Hover actions={hoverActions}>
      <div className={classes.imageCentered}>
        <div style={{ display: loading ? "block" : "none" }}>
          <Loader type="Oval" color="grey" height={100} width={100} />
        </div>
        <div
          onClick={togglePlaying}
          className={classes.imageCentered}
          style={{ display: loading ? "none" : "" }}
        >
          {!isPlaying && (
            <PlayCircleFilledIcon
              className={classes.playIcon}
              onClick={togglePlaying}
            />
          )}
          <img
            src="/images/learn/phone-video/iphonex.png"
            alt="logo"
            className={classes.cellPhoneImage}
          />
          <ReactPlayer
            width="170px"
            height="360px"
            position="absolute"
            className={classes.cellPhonePlayer}
            onReady={assetLoaded}
            onEnded={togglePlaying}
            playing={isPlaying}
            url={url}
            type="mp4"
          />
        </div>
      </div>
    </Hover>
  );
};

function PhoneVideoBlockRenderer({ assetBlock, hoverActions }: Props) {
  return (
    <>
      {assetBlock?.asset && (
        <PhoneVideoRenderer
          asset={assetBlock.asset}
          hoverActions={hoverActions}
        />
      )}
    </>
  );
}

export default PhoneVideoBlockRenderer;
