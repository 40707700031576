import React, { useEffect, useState } from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import { useDebounce, useValueResolving } from "../../../../shared/CustomHooks";
import { TemplatePage } from "../../../types/TemplatePages";
import styles from "./TextAreaInput.module.scss";
import { useValidationsContext } from "../../../../providers/ValidationsProvider";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";

type TextAreaInputProps = {
  param: BlockParam;
  update: (value: any) => void; // really this should be an input of just string
  subField?: boolean;
  sectionKey: string;
  childKey?: string;
  templatePageDraft?: TemplatePage;
  sectionParam: ConvertedBlock | undefined;
  resolvedValue?: any;
};

const TextAreaInput = ({
  param,
  update,
  sectionKey,
  childKey,
  resolvedValue,
}: TextAreaInputProps) => {
  const key = childKey || param.key;
  const { validations, validateAtKey } = useValidationsContext();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inputChanged, setInputChanged] = useState<boolean>(false);

  const initVal = "";
  const [value, setValue] = useValueResolving(
    param.value,
    resolvedValue,
    param,
    initVal
  );

  // I've set the debounce down to instant, so we know we have a dirty input immediately after a change up at the templatePage level
  // if this has performance issues down the line (because of a lot of inputs on the form)
  // then we should pass in an isDirty callback

  //If this is set to 0 the value lags by 1 character
  const debouncedValue = useDebounce(value, 10);

  useEffect(() => {
    if (validations) {
      let validation = sectionKey
        ? validations[sectionKey]?.[key]
        : validations[key];

      if (validation) {
        setErrorMessage(validation);
      } else {
        setErrorMessage("");
      }
    }
  }, [validations]);

  useEffect(() => {
    // watches debouncedValue, validates then does stuff
    if (debouncedValue) {
      if (
        validations[sectionKey] &&
        validations[sectionKey][key] &&
        inputChanged
      ) {
        //makes sure that we don't read/cure validations until desired
        validateAtKey({ [key]: debouncedValue }, sectionKey, [key]);
      }

      param
        ? update({ ...param, value: debouncedValue }) // using debouncedValue instead of value here.  this stops a double dispatch
        : update(debouncedValue);
    }
  }, [debouncedValue]); // removed value dependency

  const handleOnBlur = () => {
    if (inputChanged) {
      validateAtKey({ [key]: value }, sectionKey, [key]);
    }
  };

  const label = param.overrideName ? param.overrideName : param.name;

  return (
    <div className={styles.container}>
      {!param.hideLabel && (
        <>
          <div className="label">{label}</div>
          <label className={"sr-only"} htmlFor={param?.key}>
            {label}!!
          </label>
        </>
      )}
      <textarea
        id={param?.key}
        className={"textarea"}
        value={value}
        onChange={(e: any) => {
          setValue(e.target.value), setInputChanged(true);
        }}
        onBlur={handleOnBlur}
      />
      <div className={`error ${styles.errorPosition}`}>{errorMessage}</div>
    </div>
  );
};

export default TextAreaInput;
