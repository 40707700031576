import React from "react";
import {
  BlockParamMulti,
  ConvertedBlock,
} from "../../../../../asset-generator-lib/composer/models/Block";
import styles from "./RenderedContacts.module.scss";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import { JSONParseSafely } from "../../../../shared/helpers/helpers";
import { BlockParam } from "../../../../../asset-generator-lib/composer/models/Block";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

type Contact = {
  name: BlockParam<string>;
  phoneOrTitle: BlockParam<string>;
  email: BlockParam<string>;
};

const RenderedContacts = ({ convertedBlock, highlight }: Props) => {
  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  const contacts = convertedBlock.params.contact?.value.map(
    (contact: BlockParamMulti) => JSONParseSafely(contact?.value)
  );

  const contactEls = contacts.map((contact: Contact) => (
    <div className={styles["contact"]} key={contact.name?.value}>
      <p className={`${styles["p"]} ${styles["font--bold"]}`}>
        {contact.name?.value}
      </p>
      <p className={`${styles["p"]}`}>{contact.phoneOrTitle?.value}</p>
      <p className={`${styles["p"]}`}>{contact.email?.value}</p>
    </div>
  ));

  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      <main className={styles["container"]}>{contactEls}</main>
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedContacts;
