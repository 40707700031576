import React, { useContext } from "react";
import { AtomicBlockUtils } from "draft-js";
import { PlatoonContext, BlockEditSession } from "../PlatoonContext";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Typography, Button, Box, Divider } from "@material-ui/core";
import ButtonPicker from "../blocks/buttons/ButtonPicker";
import IframePicker from "../blocks/iframe/IframePicker";
import BlockType, { labelsForBlockType } from "../blocks/BlockTypes";
import AssetBlockPicker from "../blocks/asset/AssetBlockPicker";
import AssetCollectionBlockPicker from "../blocks/asset/AssetCollectionBlockPicker";
import AssetPreviewBlockPicker from "../blocks/asset/AssetPreviewBlockPicker";
import { AssetType } from "../../models/Asset";

const useStyles = makeStyles(() => ({
  root: {
    marginRight: "10px",
  },
  modal: {
    overflowY: "hidden",
    overflowX: "hidden",
    outline: 0,
    border: "none",
  },
  modalTitle: {
    color: "#ffffff",
    fontSize: "26px",
    fontFamily: "SF Pro Display",
    marginTop: "30px",
    marginLeft: "30px",
    textTransform: "capitalize",
  },
  modalBody: {
    height: "auto",
    borderRadius: "5px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    width: "635px",
    backgroundColor: "#333333",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "35px",
    marginBottom: "35px",
  },
  cancelButton: {
    marginLeft: "30px",
    width: "150px",
    color: "#ffffff",
    backgroundColor: "#999999",
  },
  button: {
    marginRight: "30px",
    width: "150px",
  },
  divider: {
    marginTop: "50px",
  },
}));

interface Props {
  editorState: any;
  onChange: any;
}

const GeneralModal = (props: Props) => {
  const classes = useStyles();

  // Used to coordinate the editor state and the current block editing modal
  const { blockEditSession, setBlockEditSession } = useContext(PlatoonContext);

  const insertCustomBlock = (props: Props, blockType: any, srcType: any) => {
    const contentState = props.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      blockType,
      "IMMUTABLE",
      { src: srcType }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    return AtomicBlockUtils.insertAtomicBlock(
      props.editorState,
      entityKey,
      " "
    );
  };

  const modalIsOpen = () => {
    // Existence of a session is the only thing that drives whether the modal is open or not
    return blockEditSession !== null;
  };

  const handleClose = () => {
    // Killing the session closes the modal
    setBlockEditSession(null);
  };

  const sessionShouldUpdate = (session: BlockEditSession) => {
    // We have original block data and an entity to replace, so this is update
    return session?.originalBlockData && session?.entityKey;
  };

  const sessionShouldCreate = (session: BlockEditSession) => {
    // No original data and no entity key, so this must be create
    return !sessionShouldUpdate(session);
  };

  const onSaveSession = (blockEditSession: BlockEditSession) => {
    // Check to see if there are warnings that prevent us from saving
    if (blockEditSession.warnings) {
      let bes = { ...blockEditSession };
      // We only turn warnings on now to give the user a chance prior to this to
      // input valid fields
      bes.showWarnings = true;
      setBlockEditSession(bes);

      // Don't save the session block data
      return;
    }

    const commitSave = (session: BlockEditSession) => {
      if (sessionShouldUpdate(session)) {
        const contentState = props.editorState.getCurrentContent();
        contentState.replaceEntityData(blockEditSession.entityKey, {
          src: blockEditSession.blockData,
        });
      } else {
        props.onChange(
          insertCustomBlock(
            props,
            blockEditSession.blockType,
            blockEditSession.blockData
          )
        );
      }
      handleClose();
    };

    // if (blockEditSession.onPrepareSave) {
    //   blockEditSession.onPrepareSave()
    //     .then(commitSave);
    // } else {
    commitSave(blockEditSession);
    // }
  };

  const canSaveCurrentSession = () => {
    if (blockEditSession) {
      // If we don't have urgent errors and the blockData has changed, save is ok
      return (
        !blockEditSession.errors &&
        JSON.stringify(blockEditSession.originalBlockData) !==
          JSON.stringify(blockEditSession.blockData)
      );
    }
    // Default to false to be careful, but it shouldn't be possible to get to here without a blockEditSession
    return false;
  };

  const canShowSaveButton = () => {
    // It's ok to show the save button if we can actually save OR
    // if there are warnings. Note that the user still won't be able to actually save with warnings
    // but we'll let them hit the save button to trigger the warnings.
    return (
      blockEditSession &&
      (blockEditSession.showWarnings || canSaveCurrentSession())
    );
  };

  const onSaveCurrentSession = () => {
    onSaveSession(blockEditSession);
  };

  const modalLabels = labelsForBlockType(blockEditSession?.blockType);
  const saveButtonTitle = sessionShouldCreate(blockEditSession)
    ? modalLabels.create
    : modalLabels.update;

  return (
    <Modal
      className={classes.modal}
      disableRestoreFocus={true}
      open={modalIsOpen()}
      onClose={handleClose}
    >
      <div className={classes.modalBody}>
        <Typography className={classes.modalTitle}>
          {modalLabels.title}
        </Typography>

        {blockEditSession?.blockType === BlockType.image && (
          <AssetPreviewBlockPicker assetType={AssetType.image} />
        )}
        {blockEditSession?.blockType === BlockType.video && (
          <AssetPreviewBlockPicker assetType={AssetType.video} />
        )}
        {blockEditSession?.blockType === BlockType.iframe && <IframePicker />}
        {blockEditSession?.blockType === BlockType.button && <ButtonPicker />}
        {blockEditSession?.blockType === BlockType.phoneVideo && (
          <AssetBlockPicker assetType={AssetType.video} />
        )}
        {blockEditSession?.blockType === BlockType.carousel && (
          <AssetCollectionBlockPicker assetType={AssetType.video} />
        )}
        <Divider className={classes.divider} />

        <Box className={classes.buttonContainer}>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.cancelButton}
          >
            CANCEL
          </Button>

          <Button
            className={classes.button}
            onClick={onSaveCurrentSession}
            variant="contained"
            color="primary"
            disabled={!canShowSaveButton()}
          >
            {saveButtonTitle}
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default GeneralModal;
