import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ArtistLink from '../../models/ArtistLink';
interface Props {
  artistLink: ArtistLink;
  artistLinkStateProps: any;
  artistLinkState: any;
}

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: "15px",
    fontFamily: "Helvetica",
    fontWeight: "bold",
  },
  details: {
    fontSize: '12px',
    fontFamily: "Helvetica",
    whiteSpace: "nowrap",
    color: theme.palette.primary.contrastText,

    display: 'flex',
    flexWrap: 'wrap'
  },
  date: {
    color: theme.palette.text.primary,
    ml: '.25em',
    fontWeight: "bold"
  },
  inlineBlock: {
    display: 'inline-block'
  }
}));

export default function LinkDisplayDate(props: Props) {
  const classes = useStyles();
  const { artistLinkStateProps, artistLinkState } = props;

  return (
    <Grid container alignContent="center">
      <Grid item xs={12} className={classes.title}>
        {props.artistLink.title}
      </Grid>
      <Grid item xs={12} className={classes.details}>
        <span className={classes.inlineBlock}>
          {artistLinkStateProps[artistLinkState].displayName}&nbsp;
          <span className={classes.date}>
            {artistLinkStateProps[artistLinkState].displayDate?.toLocaleDateString('en-US')}&nbsp;
          </span>
        </span>
        <span className={classes.inlineBlock}>
          at {artistLinkStateProps[artistLinkState].displayDate?.toLocaleTimeString('en-US', {
          hour: '2-digit', minute: '2-digit'
        })}
        </span>
      </Grid>
    </Grid >
  );
}
