import React from "react"
const PagesButton = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
      <path fill="#FFF" d="M11.874 21.88c1.602 0 2.424-.83 2.424-2.448v-1.577H16c1.61 0 2.423-.83 2.423-2.449V7.305c0-.789-.166-1.312-.689-1.851L13.186.855C12.679.34 12.13.166 11.426.166h-4.3c-1.594 0-2.424.83-2.424 2.449v1.577h-1.71c-1.594 0-2.424.822-2.424 2.44v12.8c0 1.619.822 2.449 2.424 2.449h8.882zm4.076-5.054h-1.652v-5.18c0-.896-.116-1.295-.673-1.86l-4.872-4.93c-.531-.54-.971-.664-1.776-.664H5.73v-1.56c0-.914.49-1.437 1.453-1.437h4.4V5.77c0 .897.423 1.287 1.286 1.287h4.532v8.334c0 .93-.498 1.436-1.452 1.436zm1.203-10.708h-4.158c-.34 0-.474-.133-.474-.473v-4.2l4.632 4.673zm-5.337 14.734H3.042c-.946 0-1.444-.507-1.444-1.445V6.657c0-.913.498-1.444 1.452-1.444h3.885v4.997c0 .946.473 1.394 1.395 1.394h4.939v7.803c0 .938-.49 1.445-1.453 1.445zm1.245-10.219H8.438c-.382 0-.532-.15-.532-.53V5.42l5.155 5.213z" />
    </svg>
  )
}

export default PagesButton
