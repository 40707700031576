import React from "react";
import MenuOptions from "../../shared/indexCards/MenuOptions";
import { ContentNode } from "./ContentProvider";
import { ReactComponent as EllipsisCircleFill } from "../../shared/icons/ellipsis-circle-fill.svg";
import Bookmark from "./Bookmark";
import { makeStyles } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSnackContext } from "../../shared/SnackProvider";

type NodeActionButtonsProps = {
  contentNode: ContentNode;
};
const useStyles = makeStyles(() => ({
  iconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "7px",
  },
  menuIcon: {
    height: "24px",
    color: "#fff",
  },
}));

const NodeActionButtons = ({ contentNode }: NodeActionButtonsProps) => {
  const classes = useStyles();
  const { setSnack } = useSnackContext();

  return (
    <div className={classes.iconContainer}>
      <MenuOptions
        icon={<EllipsisCircleFill className={classes.menuIcon} />}
        options={[
          {
            text: (
              <CopyToClipboard text={contentNode.privateLinkURL()}>
                <span>Copy direct link</span>
              </CopyToClipboard>
            ),
            textColor: "#fff",
            action: () =>
              setSnack({ message: "Direct link copied to clipboard" }),
          },
        ]}
      />

      <Bookmark contentNode={contentNode} width="17px" />
    </div>
  );
};

export default NodeActionButtons;
