import React, { useEffect, useRef, useState } from "react";
import styles from "./TemplateVersionForm.module.scss";
import { Template } from "../../template-page-builder/types/Templates";
import {
  artistEpkTemplate,
  artistEpkMappedData,
} from "../../template-page-builder/helpers/artistEpkTemplate";
import { CheckCircle } from "@material-ui/icons";

type Props = {
  template: Template;
  loadTempalte: (id: string) => Promise<void>;
};

const createTemplateVersion = async (json: string) => {
  try {
    const res = await fetch(`/api/v1/template-versions`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: json,
    });
    if (res.ok) {
      const json = await res.json();
      return json;
    }
  } catch (error) {
    console.error(error);
  }
};

const TemplateVersionForm = ({ template, loadTempalte }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }, [success]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formRef.current) {
      const json = JSON.stringify({
        template: formRef.current.template.value,
        mapped_data: JSON.stringify(
          JSON.parse(formRef.current.mappedData.value)
        ),
        blocks: JSON.stringify(JSON.parse(formRef.current.blocks.value)),
      });

      createTemplateVersion(json).then(() => {
        loadTempalte(template.id);
        setSuccess(true);
      });
    }
  };

  const importData = (field: string, word: string, obj: any) => {
    if (formRef.current?.[field].value != "") {
      if (
        formRef.current &&
        window.confirm(
          `Are you sure you want to import the ${word}? This will overwrite what you have entered`
        )
      ) {
        formRef.current[field].value = JSON.stringify(obj, null, 2);
      }
    } else {
      formRef.current[field].value = JSON.stringify(obj, null, 2);
    }
  };

  return (
    <div className={styles["main"]}>
      {success && (
        <div className={styles["success"]}>
          <CheckCircle style={{ fill: "green", fontSize: "20em" }} />
        </div>
      )}
      <h2>Create New Template Version</h2>
      <form onSubmit={onSubmit} ref={formRef}>
        <h3>Current Template Version</h3>
        <div className={styles["current"]}>
          {template.newestTemplateVersion ? (
            <>
              <h4>id: {template.newestTemplateVersion?.id}</h4>
              <h4>
                createdAt:{" "}
                {new Date(
                  //@ts-ignore
                  template.newestTemplateVersion?.createdAt
                ).toLocaleString()}
              </h4>
              <h4>version: {template.newestTemplateVersion?.version}</h4>
            </>
          ) : (
            <h4>none</h4>
          )}
        </div>
        <label htmlFor="mappedData">Mapped Data</label>
        <div className={styles["flex"]}>
          <textarea name="mappedData" />
          <div>
            <button
              type="button"
              onClick={() =>
                importData(
                  "mappedData",
                  "artistEpkMappedData",
                  artistEpkMappedData
                )
              }
              title="Import from ArtistEpkTemplate.ts"
            >
              Import
            </button>
            {template.newestTemplateVersion && (
              <button
                type="button"
                onClick={() =>
                  importData(
                    "mappedData",
                    "newestTmplateVersion mappedData",
                    //@ts-ignore
                    JSON.parse(template.newestTemplateVersion.mappedData)
                  )
                }
                title="Copy current from newestTemplateVersion"
              >
                Current
              </button>
            )}
          </div>
        </div>
        <label htmlFor="blocks">blocks</label>
        <div className={styles["flex"]}>
          <textarea name="blocks" />
          <div>
            <button
              type="button"
              onClick={() =>
                importData("blocks", "artistEpkTemplate", artistEpkTemplate)
              }
              title="Import from ArtistEpkTemplate.ts"
            >
              Import
            </button>

            {template.newestTemplateVersion && (
              <button
                type="button"
                onClick={() =>
                  importData(
                    "blocks",
                    "newestTmplateVersion blocks",
                    //@ts-ignore
                    JSON.parse(template.newestTemplateVersion.blocks)
                  )
                }
                title="Copy current from newestTemplateVersion"
              >
                Current
              </button>
            )}
          </div>
        </div>

        <input type="hidden" name="template" value={template.id} />
        <button type="submit" className={styles["submit"]}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default TemplateVersionForm;
