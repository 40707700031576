import React, { useEffect, useState } from "react";
import styles from "./SlideCarouselVideo.module.scss";
import { ReactComponent as PlayCircle } from "../../../shared/icons/play-circle.svg";
import { ReactComponent as PauseCircle } from "../../../shared/icons/pause-circle.svg";
import ReactPlayer from "react-player";
import { createPortal } from "react-dom";
import { TemplatePageBlockParam } from "../../../../asset-generator-lib";
import { getElementFromIframe } from "../../helpers/helpers";

type Props = {
  carouselKey: string;
  index: number;
  playIndex: number;
  setPlayIndex: React.Dispatch<React.SetStateAction<number>>;
  vid: TemplatePageBlockParam;
};

const SlideCarouselVideo = ({
  carouselKey,
  index,
  playIndex,
  setPlayIndex,
  vid,
}: Props) => {
  const [portalLocation, setPortalLocation] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    if (playIndex == index) {
      let portalElement =
        getElementFromIframe("overlay-content") ||
        document.getElementById("overlay-content");
      setPortalLocation(portalElement);
    } else {
      let portalElement =
        getElementFromIframe(`${carouselKey} slide ${index}`) ||
        document.getElementById(`${carouselKey} slide ${index}`);
      setPortalLocation(portalElement);
    }
  }, [playIndex]);

  return (
    <div id={`${carouselKey} slide ${index}`} className={styles["slide"]}>
      {portalLocation &&
        createPortal(
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            url={vid.value}
            playing={playIndex == index}
            playsinline={true}
            controls={false}
            light={playIndex !== index}
            playIcon={
              <div className={styles["icon-wrapper"]}>
                <PlayCircle className={styles["icon"]} />
              </div>
            }
            onClickPreview={() => {
              setPlayIndex(index);
            }}
          />,
          portalLocation
        )}

      {playIndex === index && (
        <div className={` ${styles["slide"]}`}>
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            url={vid.value}
            light={true}
            playIcon={<PauseCircle className={styles["icon"]} />}
          />
        </div>
      )}
    </div>
  );
};

export default SlideCarouselVideo;
