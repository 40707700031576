import DatePicker from "react-datepicker";
import React from "react";
import styles from "./CustomDatePicker.module.scss";
import { ReactComponent as ArrowtriangleLeftFill } from "../../../../../shared/icons/arrowtriangle-left-fill.svg";
import { ReactComponent as ArrowtriangleRightFill } from "../../../../../shared/icons/arrowtriangle-right-fill.svg";
import CustomTimeInput from "../CustomTimeInput/CustomTimeInput";
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker-overrides.css";

import { BlockParam } from "../../../../../../asset-generator-lib";

type Props = {
  value: Date | null;
  handleOnCalendarClose: () => void;
  setValue: React.Dispatch<React.SetStateAction<Date | null>>;
  setInputChanged: React.Dispatch<React.SetStateAction<boolean>>;
  param: BlockParam<any>;
  showTime: true | undefined;
};

const CustomDatePicker = ({
  value,
  handleOnCalendarClose,
  setValue,
  setInputChanged,
  param,
  showTime,
}: Props) => {
  // https://reactdatepicker.com/#example-custom-time-class-name

  return (
    <DatePicker
      selected={value}
      onCalendarClose={handleOnCalendarClose}
      // had problems when specifying this type regarding the input, and the formatting of the text.  Since this is very closed loop, i'm going to leave as any
      onChange={(date: any) => {
        setValue(date), setInputChanged(true);
      }}
      showPopperArrow={false}
      calendarClassName="date-header"
      placeholderText={`Select ${param.name}`}
      showTimeInput={showTime}
      timeIntervals={30}
      dateFormat={showTime ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy"}
      onKeyDown={(e) => e.preventDefault()}
      customInput={
        <input
          type="text"
          className={`input ${styles["input-end-adornment"]} ${styles["rotate"]}`}
          // @ts-ignore: value is supposed to be a string, but DatePicker is doing something special to convert here that we cant see
          value={value}
        />
      }
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        const month = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(date);
        const year = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
        }).format(date);
        return (
          <div className={styles["calendar-header-container"]}>
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className="btn--naked"
            >
              <ArrowtriangleLeftFill className={styles["icon"]} />
            </button>
            <div className="react-datepicker__current-month">
              {month}, {year}
            </div>
            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className="btn--naked"
            >
              <ArrowtriangleRightFill className={styles["icon"]} />
            </button>
          </div>
        );
      }}
      customTimeInput={
        <CustomTimeInput
          value={value}
          onChange={(e: any) => console.log({ e })}
        />
      }
    />
    // </div>
  );
};

export default CustomDatePicker;
