import React, { useEffect, useState } from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import TemplatePageFormRenderer from "../../TemplatePageFormRenderer/TemplatePageFormRenderer";
import { InputProps, GenericObj } from "../helpers";

const initValues = (param: BlockParam) => {
  const newValues: GenericObj<BlockParam> = {};
  param.options?.forEach((item) => {
    newValues[item.key] = { ...item };
  });
  return { ...newValues };
};

const GroupInput = ({
  param,
  update,
  sectionKey,
  templatePageDraft,
  path,
}: InputProps) => {
  const [value, setValue] = useState<GenericObj<BlockParam>>(
    param.value ? JSON.parse(param.value) : initValues(param)
  );

  // we have to set local value, then send that to the update
  useEffect(() => {
    if (Object.keys(value).length < 1) {
      initValues(param);
    } else {
      update({ ...param, value: JSON.stringify(value) });
    }
  }, [value]);

  // we don't know what kind of data we are getting, so using ANY as long as the parent update func is also any
  const wrapUpdateWithParamKey = (updatedData: any) => {
    setValue({ ...value, [updatedData.key]: updatedData });
  };

  return (
    <div id="GROUPINPUT ✅">
      <TemplatePageFormRenderer
        blockParams={Object.values(value)}
        update={wrapUpdateWithParamKey}
        disabled={false}
        templatePageDraft={templatePageDraft}
        sectionKey={sectionKey}
        path={path}
        noGridContainerGap={true}
      />
    </div>
  );
};

export default GroupInput;
