import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ContentNode } from "./ContentProvider";

type Props = {
  contentNodes: ContentNode[] | undefined;
  links: boolean;
};

const useStyles = makeStyles(() => ({
  crumb: {
    display: "inline",
    "&:not(:first-child):before": {
      padding: "4px",
      fontFamily:
        "SFCompact-Regular, SF Compact Regular, SF Pro Rounded, SF Pro",
      content: "'􀆊'",
      color: "#fff",
    },

    fontFamily: "Helvetica",
    fontSize: "10px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#fff",
    textTransform: "uppercase",
  },
  crumbs: {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
}));

const Breadcrumbs = ({ contentNodes, links }: Props) => {
  const classes = useStyles();

  const crumbsJSX = contentNodes?.map((crumb) => (
    <li
      key={`${crumb.id}-crumb`}
      className={classes.crumb}
      onClick={(e) => e.stopPropagation()}
    >
      {links ? (
        <Link className={classes.link} to={crumb.path()}>
          {crumb.title}
        </Link>
      ) : (
        crumb.title
      )}
    </li>
  ));

  return <ul className={classes.crumbs}>{crumbsJSX}</ul>;
};

export default Breadcrumbs;
