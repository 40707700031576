import React from "react";
import Template, { variantForKey, TemplateConfig } from "./models/Template";
import Block, { Size } from "./models/Block";
import { objectForKey } from "./models/KeyedObject";
import { CompositionRendererProps } from "./renderers/CompositionRenderer";
import Composer from "./Composer";

async function templatize(masterTemplate: Template, variantKey: string) {
  const variant = variantForKey(variantKey, masterTemplate.variants);

  if (!variant) {
    return masterTemplate;
  }

  async function augment(block: Block) {
    const replacementBlock = { ...block };
    const overrideBlock =
      variant && objectForKey(block.key, variant.blockOverrides);
    if (overrideBlock) {
      if (overrideBlock.params) {
        replacementBlock.params = overrideBlock.params;
      } else null;
      if (overrideBlock.frame) {
        replacementBlock.frame = overrideBlock.frame;
      } else null;
      if (overrideBlock.style) {
        replacementBlock.style = overrideBlock.style;
      } else null;
      if (overrideBlock.shapeType) {
        replacementBlock.shapeType = overrideBlock.shapeType;
      } else null;
    }
    return Promise.resolve(replacementBlock);
  }

  //Copy the master (has to be deep clone)
  const template = { ...masterTemplate };
  template.blocks = await Promise.all(template.blocks.map(augment));
  return template;
}

interface VariantComposerProps {
  template: Template;
  templateConfig: TemplateConfig;
  mapDataSource?: any;
  size: Size;
  compositionRenderer?: ({
    composition,
    size,
  }: CompositionRendererProps) => JSX.Element;
  variantKey: string;
  onReadyToExport?: any;
  freezeOnRender?: boolean;
}

const VariantComposer = ({
  template,
  templateConfig,
  mapDataSource,
  size,
  compositionRenderer,
  variantKey,
  onReadyToExport,
  freezeOnRender,
}: VariantComposerProps) => {
  // Form the template from the variant
  const [variantTemplate, setVariantTemplate] = React.useState<Template>();

  async function refreshTemplate() {
    const vt = await templatize(template, variantKey);
    setVariantTemplate(vt);
  }

  React.useMemo(refreshTemplate, [template, variantKey]);

  return variantTemplate ? (
    <Composer
      template={variantTemplate}
      templateConfig={templateConfig}
      mapDataSource={mapDataSource}
      size={size}
      PassedInCompositionRenderer={compositionRenderer}
      onReadyToExport={onReadyToExport}
      freezeOnRender={freezeOnRender}
    />
  ) : null;
};

export default VariantComposer;
