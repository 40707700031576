import { Grid } from "@material-ui/core";
import React from "react";

type Props = {
  param: any;
};

const UnknownInput = ({ param }: Props) => {
  return (
    <Grid item xs={12} style={{ outline: "1px solid red", color: "white" }}>
      <div>Unknown Input</div>
      <pre>{JSON.stringify(param, null, 2)}</pre>
    </Grid>
  );
};

export default UnknownInput;
