import { IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ReactComponent as Left } from "../../shared/icons/left.svg";
import { ReactComponent as Right } from "../../shared/icons/right.svg";

type Props = {
  variantName: string;
  changeVariant: (value: number) => void;
};
const useStyles = makeStyles(() => ({
  controlsOutter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  controlsInner: {
    width: "329px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  variantText: {
    fontFamily: "Futura",
    fontSize: "15px",
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: 0,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  button: {
    fill: "red", // temp
  },
}));
const CustomizePreviewControls = ({ variantName, changeVariant }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.controlsOutter}>
      <div className={classes.controlsInner}>
        <IconButton
          aria-label="previous variant"
          onClick={() => changeVariant(-1)}
        >
          <Left className={classes.button} />
        </IconButton>

        <Typography variant="caption" className={classes.variantText}>
          {variantName}
        </Typography>

        <IconButton onClick={() => changeVariant(1)}>
          <Right className={classes.button} />
        </IconButton>
      </div>
    </div>
  );
};

export default CustomizePreviewControls;
