import React from "react";
import Header from "../../shared/Header";
import { Grid, Typography, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  splashText: {
    fontFamily: "Helvetica",
    fontSize: "15px",
    fontWeight: "bold",
  },
}));

const MobileSplash = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <Header text="asset generator" />
      </Grid>
      <Box pl={3}>
        <Grid item>
          <Typography className={classes.splashText} variant="h6">
            COMING SOON ON MOBILE
          </Typography>
        </Grid>
      </Box>
    </>
  );
};

export default MobileSplash;
