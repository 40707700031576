import React, { useRef, useState } from "react";
import styles from "./UploadTracks.module.scss";
import ArtistLinkInputHeader from "../../LinkEditComponents/ArtistLinkInputHeader";
import { Box, Grid } from "@material-ui/core";
import { Track, TrackLink } from "../../models/ArtistLink";
import "../../../../string.extension";
import { useArtistLinkContext } from "../../../providers/ArtistLinkProvider";
import DropZone from "../../../template-page-builder/components/inputs/FileUploadComponents/DropZone/DropZone";
import DnDWrapper from "../../../template-page-builder/components/inputs/DnDInput/DnDWrapper/DnDWrapper";
import DnDItem from "../../../template-page-builder/components/inputs/DnDInput/DnDItem/DnDItem";
import { FileUploadType } from "../../../template-page-builder/components/inputs/FileInput/FileInput";
import FileUpload, {
  LegacyFileUpload,
} from "../../../template-page-builder/components/inputs/FileUploadComponents/FileUpload/FileUpload";
import ArtistLinkInput from "../../LinkEditComponents/ArtistLinkInput";

const UploadTracks = () => {
  const inputRef = useRef(null);
  const { artistLink, dispatch } = useArtistLinkContext();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // when dropping in new files
  const handleFiles = (
    files: FileList,
    event: React.ChangeEvent<HTMLInputElement>,
    accepts?: string
  ) => {
    const filesToUpload: FileUploadType[] = Object.values(files).map((file) => {
      const fileToAdd: FileUploadType = {
        id: file.name + new Date().toISOString(),
        status: "PENDING",
        selectedFile: file,
        asset: null,
      };

      return fileToAdd;
    });

    const acceptableFileTypes =
      accepts &&
      accepts.split(",").map((fileType: string) => {
        return fileType.trim();
      });

    const fileTypesToCheck = filesToUpload.map((file) => {
      return file.selectedFile.type;
    });

    const validFileTypes = fileTypesToCheck.every((file: string) => {
      return acceptableFileTypes?.includes(`${file}`);
    });

    if (validFileTypes || !accepts) {
      setErrorMessage("");
      // setFiles((prevFiles) => [...prevFiles, ...filesToUpload]);
      // instead of setting files, dispatch
      addFiles(filesToUpload);
    } else {
      setErrorMessage(
        `Invalid file present, must be ${
          acceptableFileTypes && [...acceptableFileTypes]
        }`
      );
    }
    event.target.value = "";
  };

  const addFiles = (files: any[]) => {
    const updatedTracks = artistLink.tracks ? [...artistLink.tracks] : [];
    const updatedArtistLink = {
      ...artistLink,
      tracks: updatedTracks.concat(files),
    };

    dispatch({
      type: "SET_ARTISTLINK",
      payload: {
        ...updatedArtistLink,
      },
    });
  };

  // tracks are re-ordered in the DnDWrapper, let's make the trackList match and dispatch
  const setTrackReOrder = (tracks: any[]) => {
    const orderedTrackLinks = tracks?.map((track: Track, idx: number) => ({
      position: idx,
      trackId: track.id,
    }));

    dispatch({
      type: "SET_ARTISTLINK",
      payload: {
        ...artistLink,
        tracks: tracks,
        trackLinks: orderedTrackLinks,
      },
    });
  };

  // removing a track
  const removeTrack = (id: string) => {
    const updatedArtistLink = { ...artistLink };
    updatedArtistLink.tracks = updatedArtistLink.tracks?.filter(
      (selectedTrack: Track) => selectedTrack.id !== id
    );
    updatedArtistLink.trackLinks = updatedArtistLink.trackLinks?.filter(
      (trackLink: TrackLink) => {
        return trackLink.trackId !== id;
      }
    );
    dispatch({
      type: "SET_ARTISTLINK",
      payload: updatedArtistLink,
    });
  };

  // updating artistLink.tracks and .trackList
  const updateFileProps = (file: any) => {
    const updatedTracks = artistLink.tracks ? [...artistLink.tracks] : [];

    const index = updatedTracks.findIndex((prevFile) => {
      const prevFileId = prevFile.id;
      const fileId = file.id;
      return prevFileId === fileId;
    });
    if (index === -1) {
      updatedTracks.push(file);
    } else {
      updatedTracks[index] = file;
    }
    const updatedArtistLink = { ...artistLink, tracks: updatedTracks };

    dispatch({
      type: "SET_ARTISTLINK",
      payload: updatedArtistLink,
    });
  };

  // adapter functions for FileUpload
  const responseAdapter: (
    response: any,
    file: LegacyFileUpload
  ) => FileUploadType = (response, file) => {
    const adaptedResponse: FileUploadType = {
      id: file.id,
      status: "COMPLETED",
      selectedFile: file.selectedFile,
      asset: {
        id: response.id,
        description: response.title,
        slug: "",
        type: response.attachment_data?.metadata?.mime_type,
        attachment: response.attachment,
      },
    };
    return adaptedResponse;
  };

  const formAdapter = (file: FileUploadType) => {
    const formData = new FormData();
    formData.append("attachment", file.selectedFile);
    formData.append("title", file.selectedFile.name);
    formData.append("metadata[albumName]", "");
    formData.append(
      "metadata[albumArtwork]",
      artistLink.albumArtwork?.attachment
        ? artistLink.albumArtwork?.attachment
        : ""
    );
    formData.append("metadata[lyrics]", "");
    formData.append("metadata[artistName]", artistLink.artist?.name);
    formData.append("metadata[genre]", "");

    return formData;
  };

  const pillAdapter = (track: Track) => {
    return track.title;
  };

  return (
    <>
      <ArtistLinkInputHeader header={"UPLOAD TRACKS"} variant={"h5"} />

      <Grid item xs={12}>
        <Box p={1} pl={3} pr={3}>
          <div className={styles.container} style={{ color: "white" }}>
            <DropZone
              dropZoneText="Drag and drop audio files here"
              handleFiles={handleFiles} // TODO - bring over handle files from fileinput
              inputRef={inputRef}
              multiFile={true}
              accept="audio/mpeg, audio/mp3, audio/wav, audio/x-wav"
            />
            <div className={`error ${styles["error-position"]}`}>
              {errorMessage}
            </div>
            <ArtistLinkInput
              id={"hasTracks"}
              artistLinkKey={artistLink.tracks}
              padding={true}
              display={"none"}
              validators={["hasTracks"]}
              errorMessage={["Must include at least 1 track"]}
            />
            {artistLink?.tracks && artistLink?.tracks?.length > 0 && (
              <div className={styles["file-wrapper"]}>
                <DnDWrapper
                  id={artistLink.slug}
                  items={artistLink.tracks}
                  setItems={setTrackReOrder}
                >
                  {artistLink?.tracks?.map(
                    (
                      file: any, // TODO: upldate file typing
                      index: number
                    ) => (
                      <DnDItem
                        key={file.id}
                        item={file}
                        index={index}
                        draggableId={file.id}
                      >
                        <FileUpload
                          key={file}
                          artistId={artistLink.artist?.id}
                          file={file}
                          index={index}
                          removeFile={removeTrack}
                          updateFileProps={updateFileProps}
                          multiFile={true}
                          editablePillText
                          overrideAdapters={{
                            responseAdapter,
                            formAdapter,
                            apiEndpoint: "tracks",
                            pillAdapter,
                            updateFileTextPropName: "title",
                          }}
                        />
                      </DnDItem>
                    )
                  )}
                </DnDWrapper>
              </div>
            )}
          </div>
        </Box>
      </Grid>
    </>
  );
};

export default UploadTracks;
