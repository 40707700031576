import React from "react";
import styles from "./TemplatePageFooter.module.scss";

const TemplatePageFooter = () => {
  return (
    <div className={`flex-center ${styles.footer}`}>
      <p className={styles.paragraph}>
        {`Copyright © ${new Date().getFullYear()} Platoon. All rights reserved.`}
      </p>
    </div>
  );
};

export default TemplatePageFooter;
