//COMPONENT NOT BEING USED CURRENTLY

import React, { useRef } from "react";
import { Sprite } from "@inlet/react-pixi";
import Block, { BlockUtils } from "../../models/Block";
import * as PIXI from "pixi.js";

interface Props {
  block: Block;
  parentView: any;
  readOnly: boolean;
  editorState: any;
  setEditorState: any;
  selectBlock: any;
  selectBlocks: any;
  children: any;
  bottomBlock?: any;
  topBlock?: any;
}

export default function DraggableBlock({
  block,
  parentView,
  readOnly,
  editorState,
  setEditorState,
  selectBlocks,
  selectBlock,
  children,
}: Props) {
  const f =
    block?.frame?.origin &&
    parentView &&
    BlockUtils.convertPoint(block.frame.origin, parentView);

  const dragRef = useRef(null);

  const onDragStart = (event: any) => {
    const sprite = event.currentTarget;
    selectBlocks(block, true);
    sprite.alpha = 0.5;
    sprite.data = event.data;
    sprite.dragging = true;
  };

  const onDragMove = (event: any) => {
    const sprite = event.currentTarget;
    if (sprite.dragging) {
      const newPosition = sprite.data.getLocalPosition(sprite.parent);
      sprite.x = newPosition.x;
      sprite.y = newPosition.y;
    }
  };

  const onDragEnd = (event: any) => {
    const sprite = event.currentTarget;
    sprite.alpha = 1;
    sprite.dragging = false;
    sprite.data = null;
  };

  const onMouseOver = (event: any) => {
    const sprite = event.currentTarget;
    sprite.alpha = 0.75;
  };

  const onMouseOut = (event: any) => {
    const sprite = event.currentTarget;
    sprite.alpha = 1;
  };

  const onMouseUp = (event: any) => {
    const sprite = event.currentTarget;
    let template = editorState.template;
    let blocks = editorState.template.blocks;
    let chosenBlock = blocks.findIndex((data: any) => data.id === block.id);
    let convertedDragCoor = BlockUtils.revertPoint(
      { x: sprite.x, y: sprite.y },
      parentView
    );
    blocks[chosenBlock].frame.origin.x = convertedDragCoor.x;
    blocks[chosenBlock].frame.origin.y = convertedDragCoor.y;
    setEditorState({
      ...editorState,
      template: { ...template, blocks: [...blocks, chosenBlock] },
    });
    selectBlock(block);
    sprite.alpha = 5;
  };

  return (
    <>
      {readOnly === undefined ? (
        <Sprite texture={PIXI.Texture.EMPTY} x={f?.x} y={f?.y}>
          {children}
        </Sprite>
      ) : (
        <Sprite
          ref={dragRef}
          name={block.id}
          texture={PIXI.Texture.EMPTY}
          interactive
          buttonMode={true}
          pointerdown={onDragStart}
          pointerup={onDragEnd}
          pointerupoutside={onDragEnd}
          pointermove={onDragMove}
          mouseover={onMouseOver}
          mouseout={onMouseOut}
          mouseup={onMouseUp}
          x={f?.x}
          y={f?.y}
        >
          {children}
        </Sprite>
      )}
    </>
  );
}
