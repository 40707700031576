import React from "react"

interface Props {
  color: string
}

const ForwardButton = (props: Props) => {

  const iconColor = props.color ? props.color : '#FFFFFF'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 40 22">
      <g fill="none" fillRule="evenodd">
        <g fill={`${iconColor}`} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path d="M278.873 164.981c.571 0 1.07-.19 1.64-.512l14.151-8.335c.996-.586 1.45-1.29 1.45-2.139 0-.835-.454-1.538-1.45-2.124l-14.15-8.32c-.586-.337-1.07-.528-1.641-.528-1.143 0-2.08.865-2.08 2.461v7.97c-.161-.601-.615-1.129-1.392-1.583l-14.15-8.32c-.571-.337-1.055-.528-1.64-.528-1.129 0-2.08.865-2.08 2.461v17.037c0 1.596.951 2.46 2.08 2.46.585 0 1.069-.19 1.64-.512l14.15-8.335c.777-.454 1.23-.982 1.392-1.582v7.969c0 1.596.952 2.46 2.08 2.46z" transform="translate(-928 -1022) translate(642 204) translate(-367 -296) translate(396 971)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}

export default ForwardButton
