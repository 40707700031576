import React, { useEffect, useState } from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import { useDebounce, useValueResolving } from "../../../../shared/CustomHooks";
import styles from "./TextInput.module.scss";
import { useValidationsContext } from "../../../../providers/ValidationsProvider";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TemplatePageDraft } from "../../../types/TemplatePages";

type TextInputProps = {
  param: BlockParam;
  update: (value: any) => void; // really this should be an input of just string
  subField?: boolean;
  parentError?: string;
  sectionKey: string;
  childKey?: string;
  password?: true;
  templatePageDraft?: TemplatePageDraft;
  resolvedValue?: any;
};

const TextInput = ({
  param,
  update,
  parentError,
  sectionKey,
  childKey,
  password,
  resolvedValue,
}: TextInputProps) => {
  const { validations, validateAtKey } = useValidationsContext();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inputChanged, setInputChanged] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState(false);

  const initValue = "";
  const [value, setValue] = useValueResolving<string>(
    param.value || initValue,
    resolvedValue,
    param,
    initValue
  );

  const key = childKey || param.key;

  // I've set the debounce down to instant, so we know we have a dirty input immediately after a change up at the templatePage level
  // if this has performance issues down the line (because of a lot of inputs on the form)
  // then we should pass in an isDirty callback

  // If this is set to 0 the value lags by 1 character
  const debouncedValue = useDebounce(value, 10);

  useEffect(() => {
    if (validations) {
      let validation = sectionKey
        ? validations[sectionKey]?.[key]
        : validations[key];

      if (validation) {
        setErrorMessage(validation);
      } else {
        setErrorMessage("");
      }
    }
  }, [validations]);

  useEffect(() => {
    if (validations[sectionKey] && validations[sectionKey][key]) {
      //makes sure that we don't read/cure validations until desired
      validateAtKey({ [key]: debouncedValue }, sectionKey, [key]);
    }
    param && update({ ...param, value: debouncedValue });
  }, [debouncedValue]);

  const handleOnBlur = () => {
    if (inputChanged) {
      validateAtKey({ [key]: value }, sectionKey, [key]);
    }
  };

  return (
    <div>
      {param?.name && (
        <div className="label">{param?.overrideName || param?.name}</div>
      )}
      <label className={"sr-only"} htmlFor={param?.key}>
        {param?.overrideName || param?.name}
      </label>
      <div style={{ position: "relative" }}>
        {password && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowPassword(!showPassword);
            }}
            className={`btn--naked ${styles["show-password"]}`}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </button>
        )}
        <input
          type={!showPassword && password ? "password" : "text"}
          className={"input"}
          value={value || ""}
          onChange={(e: any) => {
            setValue(e.target.value), setInputChanged(true);
          }}
          onBlur={handleOnBlur}
        />
        <div className={`error ${styles.errorPosition}`}>
          {parentError || errorMessage}
        </div>
      </div>
    </div>
  );
};

export default TextInput;
