import { Grid, isWidthUp, makeStyles, withWidth } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React, { useState } from "react";
import { useMeasure } from "react-use";
import {
  PixiCompositionRenderer,
  Template,
  TemplateVariant,
  VariantComposer,
} from "../../../asset-generator-lib";
import { returnLowestScale } from "../../shared/helpers/helpers";
import ZoomedPreview from "./ZoomedPreview";
import CustomizePreviewControls from "./CustomizePreviewControls";
import { usePromoPackContext } from "../providers/PromoPackProvider";

type Props = {
  currentVariant: TemplateVariant;
  width: Breakpoint;
  template: Template;
  changeVariant: (value: number) => void;
  variantIndex: number;
  handleReadyToExport: (exportFunc: any) => void;
};

type StyleProps = {
  width: Breakpoint;
};

const useStyles = makeStyles(() => ({
  previewContainer: {
    maxWidth: (props: StyleProps) =>
      isWidthUp("sm", props.width) ? "468px" : "332px",
    height: (props: StyleProps) =>
      isWidthUp("sm", props.width) ? "582px" : "317px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },

  hiddenTemplates: {
    display: "none",
  },
  loader: {
    width: "100%",
    height: "calc(100vh - 500px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingMask: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: "rgba(0, 0, 0, .8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  previewButton: {
    cursor: "zoom-in",
    margin: 0,
    padding: 0,
    border: 0,
    background: "none",
  },
}));

const CustomizePreview = ({
  currentVariant,
  width: widthBreakpoint,
  changeVariant,
  template,
  variantIndex,
  handleReadyToExport,
}: Props) => {
  const classes = useStyles({ width: widthBreakpoint });
  const [zoomedView, setZoomedView] = useState(false);

  const [ref, { width, height }] = useMeasure<HTMLDivElement>(); // type argument for what kind of ref you're using here
  const { promoPack } = usePromoPackContext();

  const variantComposer = template && (
    <VariantComposer
      template={template}
      variantKey={currentVariant?.key}
      size={currentVariant?.size}
      templateConfig={promoPack.templateConfig}
      mapDataSource={promoPack}
      compositionRenderer={PixiCompositionRenderer}
      freezeOnRender={false}
      onReadyToExport={handleReadyToExport}
    />
  );

  return (
    <Grid item xs={12} sm={6}>
      <div ref={ref} className={classes.previewContainer}>
        <button
          className={classes.previewButton}
          style={{
            transform: `scale(${returnLowestScale(
              currentVariant?.size,
              width,
              height
            )})`,
          }}
          onClick={() => setZoomedView(true)}
          title="Zoom in"
        >
          {zoomedView ? (
            <ZoomedPreview
              setOpen={setZoomedView}
              currentVariant={currentVariant}
            >
              {variantComposer}
            </ZoomedPreview>
          ) : (
            variantComposer
          )}
        </button>
      </div>

      <CustomizePreviewControls
        variantName={template.variants[variantIndex].name}
        changeVariant={changeVariant}
      />
    </Grid>
  );
};

export default withWidth()(CustomizePreview);
