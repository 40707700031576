import React from "react";
import styles from "./ArtistPagesPreviewHeader.module.scss";

const ArtistPagesPreviewHeader = () => {
  return (
    <header id="preview-header" className={styles["header"]}>
      <h1 className={styles["text-container"]}>
        This is a preview of your page.
      </h1>
    </header>
  );
};

export default ArtistPagesPreviewHeader;
