import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import {
  isWidthUp,
  makeStyles,
  Typography,
  withWidth,
} from "@material-ui/core";

type Props = {
  width: Breakpoint;
};

type CheckPoint = {
  text: string;
  route: string;
};

type ProgressBarStyleProps = {
  width: Breakpoint;
};

type DotStyleProps = {
  content: string;
  color: string;
  state?: DotProps["state"];
};

const useProgressBarStyles = makeStyles({
  list: {
    margin: "auto",
    display: "flex",
    padding: "30px 0",
  },

  mobileStep: {
    textTransform: "uppercase",
    fontSize: "15px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: 0,
    color: "#b4b4b4",
    padding: "20px 0 0 20px",
  },
  mobileText: {
    textTransform: "uppercase",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: -0.42,
    color: "#ffffff",
    marginBottom: "20ox",
  },
  marginBottom: {
    marginBottom: (props: ProgressBarStyleProps) =>
      isWidthUp("sm", props.width) ? "10px" : "0px",
  },
});

const useDotStyles = makeStyles(() => ({
  item: {
    position: "relative",
    display: "inline",
    flex: "1 1 25%",
    textAlign: "center",
    "&::before": {
      fontFamily: "SF Pro Rounded, SF Pro",
      fontSize: "24px",
      content: (props: DotStyleProps) => `"${props.content}"`,
      color: (props: DotStyleProps) => `${props.color}`,
      position: "absolute",
      top: "-28px",
      left: "50%",
      transform: "translateX(-50%)",
      cursor: (props: DotStyleProps) => props.state === "checked" && "pointer",
    },
    "&:not(:last-child)": {
      "&::after": {
        position: "absolute",
        content: '""',
        top: "-13px",
        left: "calc(50% + 24px)",
        width: "calc(100% - 48px)",
        height: "1px",
        background: "rgba(255, 255, 255, 0.3)",
      },
    },
  },
  text: {
    textTransform: "uppercase",
    fontSize: "15px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "22px",
    letterSpacing: 0,
    color: (props: DotStyleProps) => `${props.color}`,
    marginTop: "12px",
    cursor: (props: DotStyleProps) =>
      props.state === "checked" ? "pointer" : "",
  },
}));

const useDropDownStyles = makeStyles(() => ({
  selectContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    padding: "4px 20px",
  },
  dropdownItem: {
    width: "100%",
    border: 0,
    backgroundColor: "#000",
    color: "white",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "9px 0 9px 20px",
    textTransform: "capitalize",
  },
  dropdownActive: {
    color: "#ffffff",
    cursor: "pointer",
    backgroundColor: "#383838",
  },
  dropdownIcon: {
    fontFamily: "SF Pro Rounded, SF Pro",
    marginRight: "15px",
  },
  dropdownText: {
    display: "flex",
    fontFamily: "Futura",
    fontWeight: "normal",
    fontSize: "15px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.47,
    letterSpacing: "normal",
    color: "#b4b4b4",
    textTransform: "uppercase",
  },
  dropdownContainer: {
    marginTop: "16px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
    borderTop: "1px solid rgba(255, 255, 255, 0.15)",
  },
  flip: {
    transform: "rotate(180deg)",
  },
  currentStep: {
    fontFamily: "Futura",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.42px",
    color: "#ffffff",
    textTransform: "uppercase",
  },

  icon: {
    color: "white",
    cursor: "pointer",
    userSelect: "none",
    fontFamily: "SF Pro Rounded, SF Pro",
  },
}));

const ProgressBar = ({ width }: Props) => {
  const classes = useProgressBarStyles({ width: width });

  /*
    might be able to refactor to use checkPoints object to create the routes
    in PromoPackCreator as well, then pass into here
  */

  const checkPoints: Array<CheckPoint> = [
    { text: "Pick Release", route: "release" },
    { text: "Choose template", route: "template" },
    { text: "Customize", route: "customize" },
    { text: "Download", route: "download" },
  ];

  const { pathname } = useLocation();

  const currentRoute = pathname.substring(
    pathname.lastIndexOf("/") + 1,
    pathname.length
  );

  // get the current route, and determine it's index
  const currentIndex = checkPoints.findIndex(
    (checkPoint) => checkPoint.route === currentRoute
  );

  if (isWidthUp("sm", width)) {
    // full view

    type GetDotStateFunc = (
      index: number,
      currentIndex: number
    ) => DotProps["state"];

    const getDotState: GetDotStateFunc = (index, currentIndex) => {
      if (index === currentIndex) {
        return "filled";
      } else if (index < currentIndex) {
        return "checked";
      } else {
        return "empty";
      }
    };

    return (
      <div className={classes.marginBottom}>
        <ul className={classes.list}>
          {checkPoints.map((checkPoint, index) => {
            return (
              <Dot
                key={index}
                text={checkPoint.text}
                route={checkPoint.route}
                state={getDotState(index, currentIndex)}
              />
            );
          })}
        </ul>
      </div>
    );
  } else {
    //mobile view
    return (
      <div className={classes.marginBottom}>
        <Typography className={classes.mobileStep}>
          STEP {currentIndex + 1} OF {checkPoints.length}
        </Typography>
        <DropDown options={checkPoints} currentIndex={currentIndex} />
      </div>
    );
  }
};

type DotProps = {
  text: string;
  route: string;
  state: "filled" | "empty" | "checked";
};

const Dot = ({ text, state, route }: DotProps) => {
  let color: string;
  let content: string;
  const navigate = useNavigate();

  switch (state) {
    case "checked":
      color = "#b4b4b4";
      content = "􀁣";
      break;
    case "empty":
      color = "#b4b4b4";
      content = "􀀀";
      break;
    case "filled":
      color = "#fff";
      content = "􀀁";
      break;
  }

  const classes = useDotStyles({ content, color, state });

  return (
    <li
      className={classes.item}
      onClick={() => state === "checked" && navigate(`./${route}`)}
    >
      <Typography className={classes.text}>{text}</Typography>
    </li>
  );
};

type DropDownProps = {
  options: Option[];
  currentIndex: number;
};

type Option = {
  text: string;
  route: string;
};

const DropDown = ({ options, currentIndex }: DropDownProps) => {
  const [open, setOpen] = useState(true);
  const classes = useDropDownStyles({});
  const navigate = useNavigate();

  const getDot = (index: number) => {
    if (index === currentIndex) {
      return "􀀁";
    } else if (index > currentIndex) {
      return "􀀀";
    } else if (index < currentIndex) {
      return "􀁣";
    }
    return "";
  };

  return (
    <div>
      <div className={classes.selectContainer}>
        <div className={classes.currentStep}>{options[currentIndex]?.text}</div>
        <div
          className={`${classes.icon} ${open && classes.flip}`}
          onClick={() => setOpen(!open)}
        >
          􀁰
        </div>
      </div>
      {open && (
        <div className={classes.dropdownContainer}>
          {options?.map((option, index) => (
            <button
              key={index}
              className={`${classes.dropdownItem} ${
                index === currentIndex && classes.dropdownActive
              }`}
              onClick={() =>
                index < currentIndex && navigate(`./${option.route}`)
              }
            >
              <div className={classes.dropdownIcon}>{getDot(index)}</div>
              <div className={classes.dropdownText}>
                {index + 1} - {option.text}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default withWidth()(ProgressBar);
