import React from "react";
import FileInput from "../FileInput/FileInput";

const TrackUploadInput = (props: any) => {
  const formAdapter = (file: any) => {
    const formData = new FormData();
    formData.append("attachment", file.selectedFile);
    formData.append("title", file.selectedFile.name);
    formData.append("metadata[albumName]", file.selectedFile.name);
    formData.append("metadata[albumArtwork]", "");
    formData.append("metadata[lyrics]", "");
    formData.append(
      "metadata[artistName]",
      props.templatePageDraft?.mappedData?.artist?.name
    );
    formData.append("metadata[genre]", "");

    return formData;
  };
  return (
    <FileInput
      {...props}
      overrideAdapters={{
        apiEndpoint: "tracks",
        formAdapter: formAdapter,
      }}
      accept={
        "audio/x-wav, audio/vnd.wave, audio/wav, audio/wave, audio/mpeg, audio/mp3"
      }
      dndTextOverride="audio"
    />
  );
};

export default TrackUploadInput;
