import ProxyAPI from "../../../shared/ProxyAPI";
import ResourceSchema, {
  emptyResourceSchema,
  UserRole,
} from "../../../shared/ResourceSchema";

interface P4aArtist {
  active: boolean | null;
  bankInfo: boolean | null;
  countryOfOrigin: string | null;
  countryOfResidence: string | null;
  creatorType: any | null;
  dateOfBirth: number | null;
  dri: any | null;
  dspMetadata: any | null;
  email: string | null;
  firstName: string | null;
  id: string;
  imprint: any | null;
  invitationDate: string | null;
  invitationStatus: string | null;
  lastName: string | null;
  legalName: string | null;
  name: string | null;
  onboarded: boolean | null;
  phoneNumber: any | null;
  socialMedia: SocialMedia[] | [];
  role: string;
}

interface SocialMedia {
  socialMediaId: string;
  url: string;
  name: string;
}

const adaptP4aArtistToBackend = (frontendObject: any) => {
  let target = {
    active: frontendObject.active,
    bankInfo: frontendObject.bankInfo,
    countryOfOrigin: frontendObject.countryOfOrigin,
    countryOfResidence: frontendObject.countryOfResidence,
    dateOfBirth: frontendObject.dateOfBirth,
    dri: frontendObject.dri,
    dspMetadata: frontendObject.dspMetadata,
    email: frontendObject.email,
    firstName: frontendObject.firstName,
    id: frontendObject.id,
    imprint: frontendObject.imprint,
    invitationDate: frontendObject.invitationDate,
    invitationStatus: frontendObject.invitationStatus,
    lastName: frontendObject.lastName,
    legalName: frontendObject.legalName,
    name: frontendObject.name,
    onboarded: frontendObject.onboarded,
    phoneNumber: frontendObject.phoneNumber,
    socialMedia: frontendObject.socialMedia,
    role: frontendObject.role,
  };
  return target;
};

const adaptP4aArtistToFrontend = (backendObject: any) => {
  let target = {
    active: backendObject.active,
    bankInfo: backendObject.bankInfo,
    countryOfOrigin: backendObject.countryOfOrigin,
    countryOfResidence: backendObject.countryOfResidence,
    dateOfBirth: backendObject.dateOfBirth,
    dri: backendObject.dri,
    dspMetadata: backendObject.dspMetadata,
    email: backendObject.email,
    firstName: backendObject.firstName,
    id: backendObject.id,
    imprint: backendObject.imprint,
    invitationDate: backendObject.invitationDate,
    invitationStatus: backendObject.invitationStatus,
    lastName: backendObject.lastName,
    legalName: backendObject.legalName,
    name: backendObject.name,
    onboarded: backendObject.onboarded,
    phoneNumber: backendObject.phoneNumber,
    socialMedia: backendObject.socialMedia,
    role: backendObject.role,
  };
  return target;
};

const P4aArtistSchema: ResourceSchema = {
  ...emptyResourceSchema(),
  ...{
    title: "p4a Artist",
    rootPath: "user",
    apiRootPath: "user",
    attrs: ["title", "artist"], // ??
    adaptor: {
      toFrontend: adaptP4aArtistToFrontend,
      toBackend: adaptP4aArtistToBackend,
    },
    role: UserRole.Artist,
    apiBase: "/p4a-api",
  },
};

const p4aArtistSchema = (artistId: string, dataIsNested: boolean = false) => {
  let schema = { ...P4aArtistSchema };
  schema.apiRootPath = `user/artists/${artistId}`;
  schema.dataIsNested = dataIsNested;
  return schema;
};

const p4aArtistSocialsSchema = (dataIsNested: boolean = false) => {
  let schema = { ...P4aArtistSchema };
  schema.apiRootPath = `user/social-media`;
  schema.dataIsNested = dataIsNested;
  return schema;
};

const getResource = (artistId: string) => {
  return ProxyAPI.fetch(p4aArtistSchema(artistId, false));
};

const getSocials = () => {
  return ProxyAPI.fetch(p4aArtistSocialsSchema());
};

const P4aArtistAPI = {
  read: getResource,
};

const P4aArtistSocialsAPI = {
  read: getSocials,
};

const emptyP4aArtist = () => {
  return {
    active: null,
    bankInfo: null,
    countryOfOrigin: null,
    countryOfResidence: null,
    creatorType: null,
    dateOfBirth: null,
    dri: null,
    dspMetadata: null,
    email: null,
    firstName: null,
    id: "",
    imprint: null,
    invitationDate: null,
    invitationStatus: null,
    lastName: null,
    legalName: null,
    name: null,
    onboarded: null,
    phoneNumber: null,
    socialMedia: [],
    role: "guest",
  } as P4aArtist;
};

export {
  P4aArtistAPI,
  P4aArtistSocialsAPI,
  P4aArtist,
  SocialMedia,
  emptyP4aArtist,
};
