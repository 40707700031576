import React from "react";
import ColorPicker from "material-ui-color-picker";
import { Box, Grid, makeStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

type Props = {
  artistLinkKey: string | undefined;
  value: string;
  defaultValue: string;
};

type StyleProps = {
  color: string;
};

const useStyles = makeStyles(() => ({
  colorPickerContainer: {
    marginTop: "-25px",
  },
  colorPicker: {
    "& .MuiInput-input": {
      paddingLeft: 40,
      color: "white",
    },
  },
  colorIcon: {
    color: ({ color }: StyleProps) => color,
    position: "relative",
    top: "35px",
    left: "5px",
    zIndex: 1,
    strokeWidth: 1,
  },
}));

export default function ArtistColorPicker(props: Props) {
  const { artistLink, dispatch } = useArtistLinkContext();
  const color = props.artistLinkKey || props.defaultValue;

  const classes = useStyles({ color });

  const handleColorChange = (color: any) => {
    //using any as a typecast because of the keyof ArtistLink/ArtistLink don't work
    let updatedArtistLink = { ...artistLink } as any;
    updatedArtistLink[props.value] = color;
    dispatch({
      type: "SET_ARTISTLINK",
      payload: updatedArtistLink,
    });
  };

  return (
    <Grid className={classes.colorPickerContainer} item xs={12}>
      <Box p={1} pl={3} pr={3}>
        <FiberManualRecordIcon className={classes.colorIcon} stroke="white" />

        <ColorPicker
          className={classes.colorPicker}
          InputProps={{ value: props.artistLinkKey }}
          value={color}
          onChange={(color: any) => handleColorChange(color)}
          fullWidth
        />
      </Box>
    </Grid>
  );
}
