import { defaultResourceSchema, UserRole } from "../ResourceSchema";
import { Track } from "../../link-builder/models/ArtistLink";
import {
  adaptToFrontend,
  adaptTrackToFrontend,
} from "../../artists/ArtistSchema";
import API from "../API";
import Product from "../../promo-pack-builder/models/p4a_proxy/Products";

// TODO define tracks type

export const uploadTracks = async (tracks: Track[], artistId: string) => {
  // needs to be our artist ID, not the symphony one
  const promises = tracks.map((track: Track) => {
    return uploadTrack(track, artistId);
  });

  const results = await Promise.all(promises);

  return results;
};

export const uploadTrack = async (
  asset: Product["assets"] | undefined,
  artistId: string
) => {
  if (!asset) return undefined;

  const formData = new FormData();
  formData.append("temp_src", asset.tempSrc);
  formData.append("title", asset.name);
  formData.append("p4a_asset_id", asset.id);
  formData.append("metadata[albumName]", asset.albumName);
  formData.append("metadata[albumArtwork]", asset.albumArtwork); // TODO: this needs to be our asset created by uploadAlbumArtwork().
  formData.append("metadata[lyrics]", asset.lyrics);
  formData.append("metadata[artistName]", asset.artistName);
  formData.append("metadata[genre]", asset.genre);

  return API.create(
    formData,
    defaultResourceSchema(`artists/${artistId}/tracks`, UserRole.Artist, {
      toFrontend: adaptTrackToFrontend,
    })
  );
};

export const uploadAlbumArtwork = async (
  artworkUrl: string | undefined,
  artworkDesc: string,
  artistId: string,
  p4aReleaseId: string
) => {
  if (!artworkUrl) return undefined;

  // upload an image
  const formData = new FormData();
  formData.append("custom_attachment", artworkUrl);
  formData.append("description", artworkDesc);
  formData.append("type", "image");
  formData.append("p4aReleaseId", p4aReleaseId);
  const results = await API.create(
    formData,
    defaultResourceSchema(`artists/${artistId}/assets`, UserRole.Artist, {
      toFrontend: adaptToFrontend,
    })
  );
  return results;
};
