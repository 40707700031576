import { Grid, TextField, makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { BlockParam } from "../../../../asset-generator-lib/composer/models/Block";
import { useDebounce } from "../../../shared/CustomHooks";
import { ValidationObject } from "../customizeHelpers";
import { validation } from "../CustomizeForm";

type TextInputProps =
  | {
      promoPackField?: never;
      name?: never;
      initValue?: never;
      param: BlockParam;
      update: (value: any) => void; // really this should be an input of just string
      subField?: boolean;
      validationCallback: (returnedObj: ValidationObject) => void;
    }
  | {
      promoPackField: true;
      param?: never;
      name: string;
      initValue: string;
      update: (value: any) => void; // really this should be an input of just an InputObject
      subField?: boolean;
      validationCallback: (returnedObj: ValidationObject) => void;
    };

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: "Futura",
    fontSize: "12px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#ffffff",
    textTransform: "uppercase",
    marginBottom: "12px",
  },
}));

const TextInput = ({
  validationCallback,
  param,
  update,
  name,
  initValue,
  subField,
}: TextInputProps) => {
  const isFirstRun = useRef(true);

  const [value, setValue] = useState<string>(initValue || param?.value || "");
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();

  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    //watches debouncedValue, validates then does stuff
    if (validation(debouncedValue && debouncedValue.trim())) {
      // we want to run this callback even on initial load,
      // because we want it in the collection immediately
      validationCallback({
        field: name || param?.name!, // if name isnt provided, we have to have a param.name, so we banging it
        valid: true,
      });

      //only set error message if it's not the initial load
      !isFirstRun.current && setErrorMessage("");

      //blockIndex, paramKey, value
      //send data up
      param
        ? update({ ...param, value: debouncedValue })
        : update(debouncedValue);
    } else {
      validationCallback({
        field: name || param?.name!, // if name isnt provided, we have to have a param.name, so we banging it
        valid: false,
      });

      !isFirstRun.current && setErrorMessage("Field is required");
    }
  }, [debouncedValue]);

  useEffect(() => {
    isFirstRun.current = false;
  }, []);

  return (
    <Grid item xs={12}>
      {!subField && <div className={classes.label}>{param?.name || name}</div>}
      <TextField
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        fullWidth
        error={errorMessage ? true : false}
        helperText={errorMessage}
      />
    </Grid>
  );
};

export default TextInput;
