import React, { useEffect, useState } from "react";
import Loading from "../../shared/Loading";
import { makeStyles, SvgIcon } from "@material-ui/core";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import { ReactComponent as PlayArrow } from "../../shared/icons/playArrow.svg";
import { ReactComponent as Pause } from "../../shared/icons/pause.svg";
import { Track } from "../models/ArtistLink";
import { convertTime, getAudioDuration } from "../../shared/helpers/helpers";

type TrackListItemProps = {
  playStatus: boolean;
  setPlayStatus: React.Dispatch<React.SetStateAction<boolean>>;
  track: Track;
  trackIndex: number;
  setTrackIndex: React.Dispatch<React.SetStateAction<number>>;
  setOpenTrackList: React.Dispatch<React.SetStateAction<boolean>>;
  fontColor: string;
  index: number;
};

type StyleProps = {
  fontColor: string;
  fontFamily: string | undefined;
  trackColor: string | undefined;
  playStatus: boolean;
};

const useStyles = makeStyles(() => ({
  trackItemContainer: {
    width: "100%",
    "&:hover": {
      backgroundColor: "#e3e3e31a",
    },
  },
  trackItemWrapper: {
    cursor: "pointer",
    paddingTop: "8px",
    paddingBottom: "8px",
    display: "flex",
    width: "95%",
    margin: "auto",
  },
  indexContainer: {
    width: "5%",
    color: ({ fontColor }: StyleProps) => fontColor,
    fontFamily: ({ fontFamily }: StyleProps) => fontFamily,
  },
  iconStyle: {
    marginLeft: "-3px",
    height: "18px",
    width: "18px",
  },
  trackIndex: {
    fontSize: "18px",
  },
  trackTitle: {
    width: "85%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "5px",
    paddingRight: "20px",

    color: ({ trackColor }: StyleProps) => trackColor,
    whiteSpace: "nowrap",
    fontSize: "18px",
    fontFamily: ({ fontFamily }: StyleProps) => fontFamily,
  },
  trackDuration: {
    color: ({ trackColor }: StyleProps) => trackColor,
    fontFamily: ({ fontFamily }: StyleProps) => fontFamily,
    fontSize: "18px",
    width: "10%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const TrackListItem = (props: TrackListItemProps) => {
  const { artistLink } = useArtistLinkContext();
  const { track } = props;

  const currentTrackSrc =
    track.derivatives?.mp3Kbps192 ||
    track.derivatives?.original ||
    track.attachment;

  const isCurrentTrack = props.trackIndex === props.index;
  const trackColor = isCurrentTrack ? props.fontColor : `${props.fontColor}80`;

  const classes = useStyles({
    fontColor: props.fontColor,
    fontFamily: artistLink.playerFont,
    trackColor,
    playStatus: props.playStatus,
  });

  const [trackDurationDescription, setTrackDurationDescription] = useState<
    string | undefined
  >(undefined);

  const playPauseTrack = (index: number) => {
    if (isCurrentTrack) {
      props.setPlayStatus(!props.playStatus);
    } else {
      props.setPlayStatus(true);
    }
    props.setTrackIndex(index);
  };

  useEffect(() => {
    if (!trackDurationDescription) {
      if (track.duration == null) {
        // Fall back to calculating
        getAudioDuration(currentTrackSrc).then((td: number) => {
          setTrackDurationDescription(convertTime(td));
        });
      } else {
        // Using new track.duration property which is less magical than alternatives:
        // attachmentData?.metadata?.duration
        // metadata?.duration
        setTrackDurationDescription(convertTime(track.duration));
      }
    }
  }, [track, trackDurationDescription]);

  return (
    <div className={classes.trackItemContainer}>
      <div
        className={classes.trackItemWrapper}
        onClick={() => {
          playPauseTrack(props.index), props.setOpenTrackList(false);
        }}
      >
        <div className={classes.indexContainer}>
          {isCurrentTrack ? (
            <SvgIcon className={classes.iconStyle}>
              {props.playStatus ? <Pause /> : <PlayArrow />}
            </SvgIcon>
          ) : (
            <span className={classes.trackIndex}>{props.index + 1}.</span>
          )}
        </div>
        <span className={classes.trackTitle}>{track.title?.removeExt()}</span>
        <div className={classes.trackDuration}>
          {trackDurationDescription ? (
            <span>{trackDurationDescription}</span>
          ) : (
            <Loading height={"20px"} size={"20px"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackListItem;
