import React, { useEffect, useRef, useState } from "react";
import { Grid, isWidthUp, makeStyles, withWidth } from "@material-ui/core";
import Header from "../../shared/Header";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import NoResults from "../../shared/NoResults";
import PromoPackIndexCard from "./PromoPackIndexCard";
import Layout from "../../shared/Layout";
import { usePromoPackContext } from "../providers/PromoPackProvider";
import PromoPack, { PromoPackAPI } from "../models/PromoPack";
import { useSnackContext } from "../../shared/SnackProvider";
import ActionDialog from "../../shared/ActionDialog";
import Pagination from "@material-ui/lab/Pagination";
import { isMobile } from "react-device-detect";
import MobileSplash from "./MobileSplash";
import { useNavigate, useParams } from "react-router-dom";
import { getQueryParam, updateQueryParam } from "../../shared/helpers/helpers";
import { usePlatoonArtistContext } from "../../providers/PlatoonArtistProvider";
import { usePlatoonReleaseContext } from "../../providers/PlatoonReleaseProvider";
import Loading from "../../shared/Loading";

type Props = {
  width: Breakpoint;
};

type StyleProps = {
  width: Breakpoint;
};

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    flex: 1,
    paddingRight: ({ width }: StyleProps) =>
      isWidthUp("md", width) ? theme.spacing(10) : theme.spacing(0),
    paddingLeft: ({ width }: StyleProps) =>
      isWidthUp("md", width) ? theme.spacing(10) : theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
}));

const PromoPackIndex = (props: Props) => {
  const classes = useStyles({ width: props.width });
  const {
    promoPacks,
    fetchPromoPacks,
    promoPackPage,
    setPromoPackPage,
    promoPackPageCount,
  } = usePromoPackContext();

  const { p4aArtist } = usePlatoonArtistContext();
  const { artistId } = useParams();
  const { releases, fetchReleases } = usePlatoonReleaseContext();
  const { setSnack } = useSnackContext();

  const [newlyDupedLink, setNewlyDupedLink] = useState<string | null>(null);
  const [pendingDeletionItem, setPendingDeletionItem] =
    useState<PromoPack | null>(null);
  const navigate = useNavigate();

  const isFirstRun = useRef(true);

  useEffect(() => {
    isFirstRun.current = false;
  }, []);

  useEffect(() => {
    const pageParam = getQueryParam("page");
    pageParam &&
      pageParam != promoPackPage &&
      setPromoPackPage(parseInt(pageParam));
  }, [window.location.search]);

  useEffect(() => {
    if (promoPackPage > promoPackPageCount && promoPackPageCount > 0) {
      setPromoPackPage(promoPackPageCount);
    }

    //get new query params, and old ones
    const newParams = updateQueryParam("page", promoPackPage.toString());
    const currentParams =
      "?" + new URLSearchParams(window.location.search).toString();

    //update the query param string IF its not the first run and it's not the current params
    !isFirstRun.current && newParams != currentParams && navigate(newParams);
  }, [promoPackPage, promoPackPageCount]);

  useEffect(() => {
    if (artistId && !releases) fetchReleases(artistId);
    if (artistId && releases) fetchPromoPacks(artistId);
  }, [artistId, releases, promoPackPage]);

  useEffect(() => {
    let link = newlyDupedLink ? document.getElementById(newlyDupedLink) : null;
    if (link) {
      link.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [promoPacks, newlyDupedLink]);

  const onChangePage = (_: unknown, page: number) => {
    if (page != promoPackPage) {
      setNewlyDupedLink(null);
      setPromoPackPage(page);
      scrollTop();
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  };

  const deletePromoPack = (promoPack: PromoPack) => {
    setPendingDeletionItem(promoPack);
  };

  const executeDelete = (promoPack: PromoPack) => {
    PromoPackAPI.delete(promoPack.id, p4aArtist.id)
      .then(() => {
        needsToPaginate();
      })
      .catch((error: any) => console.warn("error", error));
  };

  const needsToPaginate = () => {
    if (promoPacks && promoPacks.length <= 1) {
      setPromoPackPage(promoPackPage - 1);
    } else {
      artistId && fetchPromoPacks(artistId);
    }
  };
  const executeDuplicate = (promoPack: PromoPack) => {
    PromoPackAPI.clone(p4aArtist.id, promoPack)
      .then((result: any) => {
        artistId && fetchPromoPacks(artistId);
        setNewlyDupedLink(result.slug);
        setSnack({
          message: "Promo Pack duplicated",
          source: `./packs/${result.id}/customize`,
        });
      })
      .catch((error: any) => console.warn("error", error));
  };

  if (!promoPacks) {
    return <Loading size={80} height="100vh" />;
  } else {
    return (
      <>
        <Layout>
          <Grid container justify="center" direction="column">
            {isMobile ? (
              <MobileSplash />
            ) : (
              <>
                <Grid item>
                  <Header
                    text="asset generator"
                    button={{
                      link: "./new/release",
                      fullText: "create new assets",
                      shortText: "+ new",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid spacing={1} container className={classes.cardContainer}>
                    {promoPacks?.length === 0 ? (
                      <Grid item xs={12}>
                        <NoResults
                          text="no promo packs yet"
                          action={{
                            to: "./new/release",
                            text: "create one now",
                          }}
                        />
                      </Grid>
                    ) : (
                      promoPacks?.map((promoPack: PromoPack, index: number) => {
                        return (
                          <Grid item xs={12} key={index}>
                            {/* temp values until we get data layer */}
                            <PromoPackIndexCard
                              data={promoPack}
                              newlyDupedLink={
                                newlyDupedLink ? newlyDupedLink : ""
                              }
                              executeDuplicate={executeDuplicate}
                              deletePromoPack={deletePromoPack}
                            />
                          </Grid>
                        );
                      })
                    )}
                  </Grid>

                  {promoPackPageCount > 1 && (
                    <Grid item xs={12}>
                      <Grid container justify="center">
                        <Pagination
                          page={promoPackPage}
                          onChange={onChangePage}
                          count={promoPackPageCount}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Layout>
        {pendingDeletionItem && (
          <ActionDialog
            open={pendingDeletionItem ? true : false}
            context={pendingDeletionItem}
            onClose={() => setPendingDeletionItem(null)}
            onAction={executeDelete}
            actionTitle="CONFIRM"
          >
            Are you sure you want to delete?
          </ActionDialog>
        )}
      </>
    );
  }
};

export default withWidth()(PromoPackIndex);
