import * as React from "react";
import { PlatoonContext } from './PlatoonContext';

export function DecoratedLink({
  children,
  entityKey,
}: {
  children: React.ReactNode;
  entityKey: string;
}) {
  const { editorState } = React.useContext(PlatoonContext)
  const entity = editorState.getCurrentContent().getEntity(entityKey);
  const entityData = entity ? entity.getData() : undefined;
  const href = (entityData && entityData.url) || undefined;

  return (
    <a
      style={{ color: 'inherit' }}
      title={href}
      href={href.validUrl()}
      target="_blank"
      rel="noopener"
    >
      {children}
    </a>
  );
}
