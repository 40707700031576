import {
  Template,
  TemplateVariant,
  TemplateConfig,
} from "../../../asset-generator-lib";
import Effortless from "./Effortless";
import Classic from "./Classic";
import Eclipse from "./Eclipse";
import SunBeam from "./SunBeam";
import Space from "./Space";
import Liquid from "./Liquid";
import Blur from "./Blur";
import Architecture from "./Architecture";
import Grain from "./Grain";
import Echo from "./Echo";
import clone from "just-clone";

const CommonTemplateVariants: TemplateVariant[] = [
  {
    key: "facebookCover",
    size: { w: 820, h: 360 },
    name: "Facebook Cover",
  },
  {
    key: "instagramStory",
    name: "Instagram Story",
    size: { w: 1080, h: 1920 },
  },
  {
    key: "spotifyHeader",
    name: "Spotify Header",
    size: { w: 2660, h: 1140 },
  },
  {
    key: "twitterHeader",
    name: "Twitter Header",
    size: { w: 1500, h: 500 },
  },
  {
    key: "youtubeCover",
    name: "Youtube Cover",
    size: { w: 2560, h: 1440 },
  },
];

// refactor and/or type propperly
function templateConfigFromTemplate(template: Template) {
  var config = {} as TemplateConfig;

  template?.blocks?.forEach((block) => {
    block?.params?.forEach((param) => {
      config[param.name] = clone(param);
    });
  });

  return config;
}

const masterTemplates = [
  Liquid,
  Echo,
  Space,
  Effortless,
  Architecture,
  Classic,
];

const getTemplateById = (templateIdentifier: string): Template | undefined => {
  // we are doing a deep copy here, then sending out the copy so we can protect the original data from mutation
  const clonedTemplates = clone(TemplateManager.masterTemplates);
  const foundTemplate = Object.values(clonedTemplates).find(
    (template) => template.id === templateIdentifier
  );
  return foundTemplate;
};

const TemplateManager = {
  masterTemplates,
  Effortless,
  Classic,
  Echo,
  Space,
  SunBeam,
  Grain,
  Architecture,
  Blur,
  Liquid,
  Eclipse,
  CommonTemplateVariants,
  templateConfigFromTemplate,
  getTemplateById,
};

export default TemplateManager;
