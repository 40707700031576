import React, { useContext } from 'react';
import clsx from 'clsx';
import { PlatoonContext } from "../PlatoonContext"


interface Props {
  theme: any,
  getEditorState: any,
  setEditorState: any,
  addVideo: any,
  modifier: any,
  blockType: string
}

const CarouselButton = (props: Props) => {
  const { setBlockEditSession } = useContext(PlatoonContext)

  const onClick = () => {

    let session = { blockType: "carousel" };
    setBlockEditSession(session);
  };

  const preventBubblingUp = (event: any) => { event.preventDefault(); }


  const blockTypeIsActive = () => {
    const editorState = props.getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === props.blockType;
  };


  const theme = props.theme;
  const className = blockTypeIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
      <button className={className} onClick={onClick} type="button">
        <svg width="24px" height="24px" viewBox="0 0 27 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
          <g id="WYSIWYG-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu-Buttons" transform="translate(-72.000000, -171.000000)" fill="#888888" fillRule="nonzero">
              <path d="M90.4816641,186.792969 C92.3244375,186.792969 93.3454336,185.755371 93.3454336,183.945801 L93.3454336,174.05127 C93.3454336,172.366211 92.3244375,171.320312 90.4816641,171.320312 L80.7365469,171.320312 C78.8854727,171.320312 77.8644766,172.366211 77.8644766,174.175781 L77.8644766,184.062012 C77.8644766,185.755371 78.8854727,186.792969 80.7365469,186.792969 L90.4816641,186.792969 Z M76.4948477,184.726074 L76.4948477,173.395508 C75.6398672,173.495117 75.116918,173.976562 75.116918,174.972656 L75.116918,183.148926 C75.116918,184.14502 75.6398672,184.626465 76.4948477,184.726074 Z M94.6071523,184.726074 C95.4621328,184.626465 95.985082,184.14502 95.985082,183.148926 L95.985082,174.972656 C95.985082,173.976562 95.4621328,173.495117 94.6071523,173.395508 L94.6071523,184.726074 Z M90.066625,184.626465 L81.1432852,184.626465 C80.5124258,184.626465 80.0890859,184.236328 80.0890859,183.588867 L80.0890859,174.524414 C80.0890859,173.876953 80.4792227,173.486816 81.1515859,173.486816 L90.066625,173.486816 C90.7306875,173.486816 91.129125,173.876953 91.129125,174.524414 L91.129125,183.588867 C91.129125,184.236328 90.7306875,184.626465 90.066625,184.626465 Z M73.8551992,183.306641 L73.8551992,174.806641 C73.0417227,174.881348 72.5768789,175.395996 72.5768789,176.267578 L72.5768789,181.854004 C72.5768789,182.717285 73.0417227,183.240234 73.8551992,183.306641 Z M97.2468008,174.806641 C98.0602773,174.881348 98.5251211,175.395996 98.5251211,176.267578 L98.5251211,176.267578 L98.5251211,181.854004 C98.5251211,182.717285 98.0602773,183.240234 97.2468008,183.306641 L97.2468008,183.306641 Z M83,176.458512 C83,176.068376 83.456543,175.877458 83.8134766,176.084977 L83.8134766,176.084977 L88.3540039,178.741227 C88.6860352,178.932145 88.6860352,179.430192 88.3540039,179.629411 L88.3540039,179.629411 L83.8134766,182.269059 C83.4399414,182.48488 83,182.285661 83,181.903825 L83,181.903825 Z" id="carousel"></path>
            </g>
          </g>
        </svg>
      </button>
    </div>
  );
}


export default CarouselButton;
