import React from "react";

import { ReactComponent as CheckMarkCloud } from "../../../shared/icons/checkmark-icloud.svg";
import { useNavigate } from "react-router-dom";
import { TemplatePage } from "../../types/TemplatePages";
import styles from "./TemplatePageEditorDesktopActionBar.module.scss";
import { templatePagePreviewLinkURL } from "../../../shared/helpers/helpers";

type ActionBarProps = {
  templatePage: TemplatePage;
  formTouched: boolean;
  isDirty: boolean;
  mutationLoading: boolean;
  mutationSuccess: boolean;
  handlePublish: (alreadyPublished: boolean) => Promise<void>;
  handleUnPublish: () => void;
};

const TemplatePageEditorDesktopActionBar = ({
  templatePage,
  formTouched,
  isDirty,
  mutationLoading,
  mutationSuccess,
  handlePublish,
  handleUnPublish,
}: ActionBarProps) => {
  const navigate = useNavigate();

  return (
    <footer className={styles.footer}>
      <div className="flex-1">
        <button
          className="btn btn--outline"
          onClick={() => navigate("../")}
          disabled={formTouched && (isDirty || mutationLoading)}
        >
          exit
        </button>
      </div>
      <div className={styles.save}>
        {mutationLoading ? (
          <>
            <CheckMarkCloud className={styles["save-icon"]} />
            SAVING
          </>
        ) : (
          mutationSuccess &&
          !isDirty && (
            <>
              <CheckMarkCloud className={styles["save-icon"]} />
              SAVED
            </>
          )
        )}
      </div>
      {templatePage.published && (
        <button onClick={handleUnPublish} className="btn">
          unpublish
        </button>
      )}
      <a
        className="btn btn--outline"
        href={templatePagePreviewLinkURL(
          templatePage?.templateSlug,
          templatePage?.slug
        )}
        target="_blank"
      >
        preview
      </a>
      {/* <button className="btn btn--outline" onClick={handlePreview}>
        preview
      </button> */}
      <button
        className="btn"
        onClick={() => handlePublish(templatePage.published)}
      >
        {templatePage.published ? "update" : "publish"}
      </button>
    </footer>
  );
};

export default TemplatePageEditorDesktopActionBar;
