import { Pagination } from "../../shared/types/SharedTypes";
import { TemplateVersion, TemplateVersionAdaptor, BackendTemplateVersion } from "./TemplateVersion";

// Adapts attributes between frontend/native (e.g. Dates, Onjects) and backend/serializable (e.g. strings) types
export const TemplateAdaptor = {
  toFrontend: (backendObject: BackendTemplate) => {
    // Create a frontend object with native dates/objects by deserializing backend attributes
    return {
      ...backendObject,
      newestTemplateVersion: TemplateVersionAdaptor.toFrontend(backendObject.newestTemplateVersion)
    } as Template;
  }
}

export type TemplateBase = {
  id: string;
  name: string;
  slug: string;
  type: string;
}

export type BackendTemplate = TemplateBase & {
  newestTemplateVersion: BackendTemplateVersion;
}

// FrontendTemplate implied
export type Template = TemplateBase & {
  newestTemplateVersion: TemplateVersion;
};

export type PaginatedTemplates = {
  pagination: Pagination;
  results: Template[];
};
