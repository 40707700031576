import React from "react";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Theme,
  makeStyles,
} from "@material-ui/core";
import PagesButton from "../platoon-cms-lib/components/Buttons.tsx/PagesButton";
import { People, PermMedia, Dashboard } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import NodeSchema from "../nodes/NodeSchema";
import ArtistSchema from "../artists/ArtistSchema";
import AssetSchema from "../assets/AssetSchema";

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
    zIndex: 0,
  },
  drawerContainer: {
    overflow: "auto",
  },
}));

const LinkItem = (props: any) => {
  const IconComponent = props.iconComponent;

  return (
    <NavLink
      to={`admin/${props.path}`}
      style={({ isActive }) =>
        isActive
          ? { backgroundColor: "#AAA", color: "pink" }
          : { color: "white", textDecoration: "none" }
      }
    >
      <ListItem button key={props.title}>
        <ListItemIcon>
          <IconComponent />
        </ListItemIcon>
        <ListItemText primary={props.title} />
      </ListItem>
    </NavLink>
  );
};

const SideNav = () => {
  const classes = useStyles();

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <LinkItem
              path={`${NodeSchema.rootPath}`}
              title={NodeSchema.pluralTitle}
              iconComponent={PagesButton}
            />
            <LinkItem
              path={`${AssetSchema.rootPath}`}
              title={AssetSchema.pluralTitle}
              iconComponent={PermMedia}
            />
            <LinkItem
              path={`${ArtistSchema.rootPath}`}
              title={ArtistSchema.pluralTitle}
              iconComponent={People}
            />
            <LinkItem
              path={`templates`}
              title={"Templates"}
              iconComponent={Dashboard}
            />
          </List>
          <Divider />
        </div>
      </Drawer>
    </>
  );
};

export default SideNav;
