import React, { useMemo, useEffect, useRef, useState } from "react";
import { Stage, Container } from "@inlet/react-pixi";
import { CompositionRendererProps } from "./CompositionRenderer";
import PixiBlockRenderer from "./pixi/PixiBlockRenderer";
import Block, { BlockParam, Frame, View } from "../models/Block";
import { useEditorStateContext } from "../TemplateEditorState";
import * as PIXI from "pixi.js";
import { addShapeBlockResources } from "./pixi/ShapeBlockRenderer";
import { addSpriteBlockResources } from "./pixi/SpriteBlockRenderer";
import Loading from "../../../bundles/shared/Loading";

const PixiCompositionRenderer = ({
  composition,
  size,
  onReadyToExport,
  freezeOnRender,
}: CompositionRendererProps) => {
  let editorStateContext = useEditorStateContext();
  const [image, setImage] = useState<any>();
  const [pixiStage, setPixiStage] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const stageRef = useRef<any>(null);

  PIXI.settings.ROUND_PIXELS = true;

  const loadStageResources = () => {
    pixiStage?.loader?.reset();
    stageRef.current?.props?.children?.props?.children?.map(
      (childBlock: any) => {
        childBlock?.props?.block?.params?.map(
          (childBlockParams: BlockParam) => {
            addSpriteBlockResources(
              childBlockParams,
              childBlock.props.block,
              pixiStage.loader
            );
            addShapeBlockResources(
              childBlockParams,
              childBlock.props.block,
              pixiStage.loader
            );
          }
        );
      }
    );
    pixiStage?.loader?.load(() => renderStage());
  };

  const renderStage = () => {
    if (freezeOnRender) {
      freeze();
    }
    pixiStage.renderer.render(pixiStage.stage);
    setLoading(false);
  };

  useMemo(() => {
    if (pixiStage && composition) loadStageResources();
  }, [pixiStage, composition]);

  async function freeze() {
    let image = await exportRenderedComposition();
    setImage(image);
  }

  useEffect(() => {
    setImage(null);
    if (onReadyToExport && pixiStage) {
      setTimeout(() => {
        onReadyToExport(() => exportRenderedComposition);
      }, 1000);
    }
  }, [composition]);

  const exportRenderedComposition = () => {
    return new Promise((resolve) => {
      if (pixiStage) {
        let shadowStage = size && PIXI.RenderTexture.create(size?.w, size?.h);
        pixiStage.renderer.render(pixiStage.stage, shadowStage);
        let extractedStage =
          pixiStage.renderer.plugins.extract.canvas(shadowStage);
        extractedStage?.toBlob((blob: any) => {
          resolve(blob);
        }, "image/png");
        shadowStage?.destroy();
      }
    });
  };

  const blocks = composition && composition.blocks;
  if (!composition || !blocks) {
    return <div>LOADING</div>;
  }
  const viewportSize = size || { w: 400, h: 400 };
  const frame = {
    origin: { x: 0, y: 0 },
    size: composition?.meta.basis,
  } as Frame;
  const scale = viewportSize &&
    frame?.size && {
      x: viewportSize?.w / frame?.size?.w,
      y: viewportSize?.h / frame?.size?.h,
    };
  const rootView = { blocks, frame, scale } as View;

  return composition ? (
    <>
      {loading && freezeOnRender && (
        <div
          style={{
            height: viewportSize.h,
            width: viewportSize.w,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <Loading size={200} height="200px" />
        </div>
      )}
      {image && (
        <img
          style={{ width: viewportSize.w, height: viewportSize.h }}
          src={URL.createObjectURL(image)}
        />
      )}
      {!image && (
        <div style={{ visibility: freezeOnRender ? "hidden" : "visible" }}>
          <Stage
            ref={stageRef}
            raf={false}
            width={viewportSize.w}
            height={viewportSize.h}
            renderOnComponentChange={!freezeOnRender}
            options={{
              antialias: true,
              sharedLoader: false,
              preserveDrawingBuffer: true,
            }}
            onMount={setPixiStage}
          >
            <Container>
              {rootView.blocks.map((block: Block, index: number) => {
                if (block) {
                  return (
                    <PixiBlockRenderer
                      key={index}
                      block={block}
                      parentView={rootView}
                      editorState={editorStateContext?.editorState}
                      setEditorState={editorStateContext?.setEditorState}
                      readOnly={editorStateContext?.readOnly}
                      selectBlocks={editorStateContext?.selectBlocks}
                      selectBlock={editorStateContext?.selectBlock}
                      composition={composition}
                      loader={pixiStage?.loader}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Container>
          </Stage>
        </div>
      )}
    </>
  ) : (
    <div>LOADING</div>
  );
};

export default PixiCompositionRenderer;
