import { ConvertedBlock } from "../../../asset-generator-lib/composer/models/Block";
import { Pagination } from "../../shared/types/SharedTypes";
import { GenericObj } from "../components/inputs/helpers";
import { JSONParseSafely } from "../../shared/helpers/helpers";

export type Artist = {
  id: string;
  p4aArtistId: string;
  name: String;
  slug: String;
};

type TemplatePageBase = {
  id: string;
  name: string;
  published: boolean;
  password: string | null;
  templateVersionId: string; // this should be camel case, but causes error on update
  templateType: string;
  templateSlug: string;
  artist: Artist;
  slug: string;
};

export type TemplatePagePostBody = {
  name: string;
  published: boolean;
  startDate: string | null;
  endDate: string | null;
  publishedDate: string | null;
  password: string | null;
  templateVersionId: string;
  templateType: string;
  templateSlug: string;
  templateConfig: string;
  mappedData: string;
  drafts?: string;
};

// TemplatePage, as used by frontend components, assumes objects are parsed/converted already, hence the re-typing here
export type TemplatePage = TemplatePageDraft & {
  drafts: TemplatePageDraft[];
};

export type TemplatePageDraft = TemplatePageBase & {
  templateConfig: ConvertedBlock[];
  mappedData: GenericObj<any>; // we dont really know the shape of this.
  startDate: Date | null;
  endDate: Date | null;
  publishedDate?: Date | null;
};

// TemplatePage, as used by frontend components, assumes objects are parsed/converted already, hence the re-typing here
export type PatchTemplatePage = {
  name?: string;
  published?: boolean;
  password?: string | null;
  templateVersionId?: string;
  templateType?: string;
  templateSlug?: string;
  templateConfig?: ConvertedBlock[];
  mappedData?: GenericObj<any>; // we dont really know the shape of this.
  startDate?: Date | null;
  endDate?: Date | null;
  publishedDate?: Date | null;
  drafts?: TemplatePageDraft[];
};

// TemplatePageResponse comes from the backend. These fields are re-typed to indicate they are interfacing directly with the API
export type TemplatePageResponse = TemplatePageBase & {
  templateConfig: string;
  mappedData: string;
  startDate: string | null;
  endDate: string | null;
  publishedDate?: string | null;
  drafts: string;
};

// Adapts TemplatePage attributes between frontend/native (e.g. Dates, Onjects) and backend/serializable (e.g. strings) types
export const TemplatePageAdaptor = {
  toFrontend: (backendObject: TemplatePageResponse) => {
    // Create a frontend object with native dates/objects by deserializing backend attributes
    return {
      ...backendObject,
      startDate: backendObject.startDate
        ? new Date(backendObject.startDate)
        : null,
      endDate: backendObject.endDate ? new Date(backendObject.endDate) : null,
      templateConfig: JSONParseSafely(backendObject.templateConfig),
      mappedData: JSONParseSafely(backendObject.mappedData),
      publishedDate: backendObject.publishedDate
        ? new Date(backendObject.publishedDate)
        : null,
      // since this func is called recursively, the top layer will have drafts, the second layer will not
      ...(backendObject.drafts && {
        drafts: JSONParseSafely(backendObject.drafts).map(
          TemplatePageAdaptor.toFrontend
        ),
      }),
    } as TemplatePage;
  },
  toBackend: (frontendObject: PatchTemplatePage | TemplatePage) => {
    // Create a backend object with strings by serializing frontend attributes
    // ONLY creating keys/values for those existing in the frontend object
    // effectively a PATCH
    var body = {
      ...frontendObject,
      ...(frontendObject.mappedData && {
        mappedData: JSON.stringify(frontendObject.mappedData),
      }),
      ...(frontendObject.templateConfig && {
        templateConfig: JSON.stringify(frontendObject.templateConfig),
      }),
      ...(frontendObject.drafts && {
        drafts: JSON.stringify(
          frontendObject.drafts?.map(TemplatePageAdaptor.toBackend)
        ),
      }),
      ...(frontendObject.startDate && {
        startDate: frontendObject.startDate.toISOString(),
      }),
      ...(frontendObject.endDate && {
        endDate: frontendObject.endDate.toISOString(),
      }),
      ...(frontendObject.publishedDate && {
        publishedDate: frontendObject.publishedDate.toISOString(),
      }),
    } as any;

    // delete body.id; //remove the id, we shouldn't need it creating or updating

    return body as TemplatePagePostBody;
  },
};

export type BackendPaginatedTemplatePages = {
  pagination: Pagination;
  results: TemplatePageResponse[];
};

export type PaginatedTemplatePages = {
  pagination: Pagination;
  results: TemplatePage[];
};

export const PaginatedTemplatePagesAdaptor = {
  toFrontend: (
    backendPaginatedTemplatePages: BackendPaginatedTemplatePages
  ) => {
    return {
      ...backendPaginatedTemplatePages,
      results: backendPaginatedTemplatePages.results.map(
        TemplatePageAdaptor.toFrontend
      ),
    } as PaginatedTemplatePages;
  },
};

export type MappedData = {
  source: "artist" | "release" | "shareableReleases";
  keys: MappedDataKeys;
};

export type MappedDataKeys = { [key: string]: any };
