import React from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import styles from "./RenderedLinkTitle.module.scss";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import { isJSON } from "../../../../shared/helpers/helpers";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

const RenderedLinkTitle = ({ convertedBlock, highlight }: Props) => {
  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  const linkTitles = convertedBlock.params.linkTitle?.value?.map(
    (linkTitle: any, index: number) => {
      const linkTitleParsed = isJSON(linkTitle.value);
      return (
        <h1 key={index} className={styles["link-title-header"]}>
          <a
            className={styles["link-title-anchor"]}
            href={linkTitleParsed.linkUrl.value}
            target="_blank"
          >
            {linkTitleParsed.linkTitle.value}
          </a>
        </h1>
      );
    }
  );

  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title || "Section"}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      {linkTitles && linkTitles.length > 0 && (
        <main className={styles["link-titles-wrapper"]}>{linkTitles}</main>
      )}
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedLinkTitle;
