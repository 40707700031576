import clone from "just-clone";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ContentNode } from "../publications/components/ContentProvider";
import { UserRole } from "./ResourceSchema";
import { isMobile } from "react-device-detect";
import RockDove, { Headers } from "./RockDove";
import { usePrevious } from "react-use";
import { BlockParam } from "../../asset-generator-lib";
import isEqual from "lodash.isequal";

// value:any because we can use useDebounce for anything
export function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

export function useScrollToView(id: string) {
  const iframeElement = document.getElementById(
    "frame-size-wrapper"
  ) as HTMLIFrameElement;
  const iframe = iframeElement?.contentWindow?.document.getElementById(id);

  const element = iframe || document.getElementById(id);

  isMobile
    ? element?.scrollIntoView(true)
    : element?.scrollIntoView({ behavior: "smooth", block: "center" });
}

export function useDebounceCloner(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(clone(value));
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

export function useActiveButton(contentNode: ContentNode, node: ContentNode) {
  const { slug } = useParams();
  const currentSlug = contentNode?.findByPath(slug || "")?.slug;
  // checking if this child is the current slug, or if it's children are (due to FAQs/Finance being a section as a button)
  if (
    node.slug === currentSlug ||
    node.children.some((item) => item.slug === currentSlug)
  ) {
    return true;
  } else {
    return false;
  }
}

export function useCrumbs(contentNode: ContentNode) {
  const { slug } = useParams();
  const currentNode = contentNode.findByPath(slug || "");
  const crumbs = currentNode?.getCrumbs();
  return crumbs;
}

export function useHeaders() {
  const [headers, setHeaders] = useState<Headers | undefined>(undefined);

  useEffect(() => {
    RockDove.shared()
      .getHeaders(UserRole.Artist)
      .then((token) => setHeaders(token));
  }, []);
  return headers;
}

export function useElementBoundingClientRect(id: string) {
  const [elementSize, setElementSize] = useState<DOMRect | undefined>(
    document.getElementById(id)?.getBoundingClientRect()
  );
  useEffect(() => {
    function handleResize() {
      const element = document.getElementById(id);
      setElementSize(element?.getBoundingClientRect());
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return elementSize;
}

type WindowSizeReturn = {
  width: number;
  height: number;
};

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSizeReturn>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function useValueResolving<T>(
  value: T,
  resolvedValue: any,
  param: BlockParam,
  initValue: any
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [paramValue, setParamValue] = useState(value);
  const prevResolvedValue = usePrevious(resolvedValue);

  const isSubscribed = Boolean(param.sectionPath);

  // we need to update value if we are subscribed and the resolved value changes.
  // do not update though if we already have that resolvedValue saved in value,
  // or it could infinite loop / cause unecessary rerenders

  useEffect(() => {
    // we're initing and don't have a param.value yet
    // but we and have a resolvedValue to use

    if (isEqual(value, initValue) && resolvedValue != null) {
      setParamValue(resolvedValue);
    }

    // if we are subscribed and our resolvedValue changes update state
    // on init we don't have a prevResolvedValue so no update needed here
    // this allows the user to change a param wit a resolvedValue and not have it overwritten

    if (
      isSubscribed &&
      prevResolvedValue &&
      !isEqual(prevResolvedValue, resolvedValue)
    ) {
      setParamValue(resolvedValue);
    }
  }, [resolvedValue, isSubscribed]);

  return [paramValue, setParamValue];
}
