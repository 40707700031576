import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LinkButtonTabContent, CopyButtonTabContent } from "./ButtonTabContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ButtonBlock, { emptyButtonBlock } from "./ButtonBlock";
import { PlatoonContext } from "../../PlatoonContext";

const useStyles = makeStyles(() => ({
  modalContent: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    marginBottom: "30px",
  },
  tabs: {
    color: "#ffffff99",
    fontSize: "14px",
    fontFamily: "SF Pro Display",
    letterSpacing: "1.25px",
    width: "64px",
  },
  modalTitle: {
    color: "#ffffff",
    fontSize: "26px",
    fontFamily: "SF Pro Display",
    marginTop: "30px",
    marginLeft: "30px",
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#999999",
  },
}));

const pickerModes = [
  { title: "Link", value: "button" },
  { title: "Copy", value: "copy-button" },
];

export default function ButtonPicker() {
  const classes = useStyles();

  const { blockEditSession, setBlockEditSession } = useContext(PlatoonContext);
  // Casting block data to a ButtonBlock and initializing if it's null.
  // This ensures ButtonPicker and descendants have types to work with
  // instead of just object data, which is error prone and not readable.
  const buttonBlock =
    (blockEditSession.blockData as ButtonBlock) || emptyButtonBlock();

  const setButtonBlock = (
    buttonBlock: ButtonBlock,
    errors: string[] | null = null,
    warnings: string[] | null = null
  ) => {
    let s = { ...blockEditSession };
    s.blockData = buttonBlock;
    s.errors = errors;
    s.warnings = warnings;
    setBlockEditSession(s);
  };

  const handlePickerMode = (_: unknown, newMode: string | null) => {
    if (newMode !== buttonBlock.type && newMode !== null) {
      buttonBlock.type = newMode;
      setButtonBlock(buttonBlock);
    }
  };

  return (
    <Box>
      <Tabs
        value={buttonBlock.type}
        indicatorColor="secondary"
        onChange={handlePickerMode}
        aria-label="Picker Mode"
      >
        {pickerModes.map((mode) => (
          <Tab
            className={classes.tabs}
            value={mode.value}
            aria-label={mode.title}
            label={mode.title}
          ></Tab>
        ))}
      </Tabs>

      <div className={classes.modalContent}>
        {buttonBlock.type === "button" && (
          <LinkButtonTabContent
            buttonBlock={buttonBlock}
            setButtonBlock={setButtonBlock}
          />
        )}

        {buttonBlock.type === "copy-button" && (
          <CopyButtonTabContent
            buttonBlock={buttonBlock}
            setButtonBlock={setButtonBlock}
          />
        )}
      </div>
    </Box>
  );
}
