import React from "react";
import { Divider, makeStyles } from "@material-ui/core";
import PlatoonLogo from "../shared/PlatoonLogo.png";
import { Link } from "react-router-dom";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as ArrowLeftLong } from "../../bundles/shared/icons/arrow-left-long.svg";

interface Props {
  status?: number;
  redirectPath?: string;
  errorDispatch?: any;
}

function nameFor(status?: number) {
  // should we add additional built in cases
  switch (status) {
    case 401:
      return "Unauthorized";
      break;
    case 404:
    default:
      return "Not found";
      break;
  }
}

const useStyles = makeStyles(() => ({
  errorPageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "100vh",
  },
  errorPageWrapper: {
    width: "100%",
  },
  platoonLogo: {
    padding: "24px",
    maxHeight: "50%",
    maxWidth: "20%",
    margin: "auto",
  },
  divider: {
    height: "3px",
    backgroundColor: "white",
    width: "15%",
    margin: "auto",
  },
  errorStatus: {
    fontSize: "24px",
    fontFamily: "SF Pro Display",
    textAlign: "center",
    color: "white",
  },
  navLinkContainer: {
    display: "flex",
    justifyContent: "center",
  },
  navLinkWrapper: {
    width: "50%",
    display: "flex",
  },
  navLink: {
    color: "white",
    display: "flex",
    fontSize: "18px",
    fontFamily: "SF Pro Display",
    width: "12.5%",
    textDecoration: "none",
    justifyContent: "space-evenly",
  },
}));

const ErrorPage = ({ status, redirectPath, errorDispatch }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.errorPageContainer}>
      <div className={classes.errorPageWrapper}>
        {redirectPath && (
          <div className={classes.navLinkContainer}>
            <div className={classes.navLinkWrapper}>
              <Link
                onClick={() => errorDispatch({ type: "CLEAR_ERRORS" })}
                className={classes.navLink}
                to={redirectPath}
              >
                <SvgIcon>
                  <ArrowLeftLong color="white" />
                </SvgIcon>
                Home
              </Link>
            </div>
          </div>
        )}
        <div className={classes.platoonLogo}>
          <img height="100%" width="100%" src={PlatoonLogo} />
        </div>
        <Divider className={classes.divider} />
        <h1 className={classes.errorStatus}>{nameFor(status)}</h1>
      </div>
    </div>
  );
};
export default ErrorPage;
