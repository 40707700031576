import { ContentNode } from "./ContentProvider";
// TODO: we should make a context for bookmarks so we get live updates from around the site

const localStorageItemKey = "bookmarks-v0.2.0";

const bookmarkedIds = (): string[] => {
  let bookmarksJSON = localStorage.getItem(localStorageItemKey);
  let bookmarksObj = bookmarksJSON ? JSON.parse(bookmarksJSON) : {};
  let nodeIds = Object.entries(bookmarksObj)
    .filter(([, value]) => value === true)
    .map(([key]) => key);
  return nodeIds;
};

const bookmarkedNodes = (rootNode: ContentNode): ContentNode[] => {
  let ids = bookmarkedIds();
  let nodesOrUndefined: (ContentNode | undefined)[] = ids.map((nodeId) =>
    rootNode.findById(nodeId)
  );
  return nodesOrUndefined.filter((item) => item !== undefined) as ContentNode[];
};

const bookmarkId = (id: string, bookmark: boolean = true) => {
  let bookmarksJSON = localStorage.getItem(localStorageItemKey);
  let bookmarksObj = bookmarksJSON ? JSON.parse(bookmarksJSON) : {};
  bookmarksObj[id] = bookmark;

  localStorage.setItem(localStorageItemKey, JSON.stringify(bookmarksObj));
};

const bookmarkNode = (node: ContentNode, bookmark: boolean = true) => {
  if (node.id) {
    bookmarkId(node.id, bookmark);
  }
};

const isNodeBookmarked = (node: ContentNode): boolean => {
  return bookmarkedIds().filter((id) => id === node.id).length > 0;
};

export { bookmarkedNodes, bookmarkedIds, bookmarkNode, isNodeBookmarked };
