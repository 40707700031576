import React, { useEffect, useState } from "react";
import Release from "../../../../promo-pack-builder/PromoPackCreator/Release";
import styles from "./MultiReleasePickerInput.module.scss";
import { ProductAPI } from "../../../../promo-pack-builder/models/p4a_proxy/Products";
import { usePlatoonArtistContext } from "../../../../providers/PlatoonArtistProvider";
import {
  uploadAlbumArtwork,
  uploadTracks,
} from "../../../../shared/helpers/assets";
import { constructValuePath } from "../../../../shared/helpers/helpers";
import { resolveMappedPath } from "../../../../../asset-generator-lib/helpers";
import Overlay from "../../../../shared/components/Overlay/Overlay";
import Loading from "../../../../shared/Loading";
import useWindowSize from "../../../../shared/CustomHooks";
import { TemplatePageBlockParam } from "../../../../../asset-generator-lib";
import FormModal from "../../FormModal/FormModal";
import { BlockParam } from "../../../../../asset-generator-lib/composer/models/Block";
import DnDWrapper from "../DnDInput/DnDWrapper/DnDWrapper";
import DnDItem from "../DnDInput/DnDItem/DnDItem";
import MultiPill from "../MultiPill/MultiPill";
import { useTemplatePageEditorContext } from "../../TemplatePageEditor/TemplatePageEditor";

/*
  Not only do we need multi pick functionality on the picker (<Release/>)
  but we also need to pre-build into this the modal, and multi results
*/

type Dispatch = {
  type: string;
  payload: { p4aReleaseId: string };
};

type Props = {
  param: BlockParam;
  update: (input: TemplatePageBlockParam | string) => void;
  preTemplateConfigSectionValue: any;
  path: string[];
  sectionKey: string;
};

const MultiReleasePickerInput = ({
  param,
  update,
  preTemplateConfigSectionValue,
  path,
  sectionKey,
}: Props) => {
  const { width } = useWindowSize();

  const verb = param.value
    ? `${(param.buttonTextStates && param.buttonTextStates[1]) || "EDIT"} `
    : `${(param.buttonTextStates && param.buttonTextStates[0]) || "CREATE A"} `;

  const desktopView = width > 1048; //same as scss $tablet-breakpoint

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const initValue = param?.value?.p4aReleaseIds || null;

  const [selectedP4aReleaseIds, setSelectedP4aReleaseIds] = useState<
    string[] | null
  >(initValue || null);

  // for storing the state on modal open, so if we hit cancel, we can revert back
  // this may also come in handy for search stuff
  // maybe make into an object that stores ids and releases
  const [sessionInitState, setSessionInitState] = useState<string[] | null>(
    null
  );

  const {
    p4aArtist: { id: p4aAristId },
    assembleArtist,
  } = usePlatoonArtistContext();

  const { setHighlightedKey } = useTemplatePageEditorContext();

  useEffect(() => {
    if (open) {
      setSessionInitState(selectedP4aReleaseIds);
    } else {
      setSessionInitState(null);
    }
  }, [open]);

  const onDragStart = () => {
    setHighlightedKey(sectionKey);
  };

  const updateReleaseOrder = (reOrderedFiles: any) => {
    update({
      ...param,
      value: {
        p4aReleaseIds: param.value.p4aReleaseIds,
        releases: reOrderedFiles,
      },
    });
  };

  const removeRelease = (releaseId: string) => {
    const filteredReleaseIds =
      selectedP4aReleaseIds?.filter(
        (release: string) => release !== releaseId
      ) || [];

    // send update
    update({
      ...param,
      value: {
        p4aReleaseIds: filteredReleaseIds,
        releases: param.value.releases.filter(
          (release: any) => release.id !== releaseId
        ),
      },
    });
    // remove from local state array
    setSelectedP4aReleaseIds(filteredReleaseIds);
  };

  const uploadAssets = async (
    p4aAristId: string,
    assmebleArtistId: string,
    selectedP4aReleaseId: string
  ) => {
    setLoading(true);
    return ProductAPI.read(p4aAristId, selectedP4aReleaseId).then(
      async (res) => {
        // we need to save all of the data + have things ready for the file inputs
        // which means adding keys / re-orging some data in the formats we need
        // mapped path would be something like modal.value.page1Grid.value.releasePicker.value.audioAssets

        const release = res.results;

        // can't use originalArtwork url because it has token which breaks
        // transcoding
        const tracks = release.assets.map((asset: any, index: number) => {
          if (asset.metadata) {
            return {
              id: asset.id,
              albumName: release.name ? release.name : "",
              albumArtwork: release.artworkUrl ? release.artworkUrl : "",
              name: asset.title ? asset.title : `Track ${index + 1}`,
              tempSrc: asset.metadata.url ? asset.metadata.url : "",
              lyrics: asset.lyrics ? asset.lyrics : "",
              artistName: asset.artistName ? asset.artistName : "",
              genre: release.genre?.name ? release.genre.name : "",
            };
          } else
            return {
              name: "NO AUDIO UPLOADED",
              tempSrc: "NONE",
            };
        });

        const uploadedTracks = await uploadTracks(tracks, assmebleArtistId);

        const uploadedArtwork = await uploadAlbumArtwork(
          res.results.originalArtworkUrl,
          res.results.name,
          assmebleArtistId,
          res.results.id
        );

        return {
          id: selectedP4aReleaseId,
          raw: res.results, // the full obj from symphony
          ...(uploadedTracks && { tracks: uploadedTracks }), // the tracks in our system
          ...(uploadedArtwork && { artwork: [uploadedArtwork] }), // the artwork asset in our system
        };
      }
    );
  };

  // TODO: this can probably be simplified?
  const setSelectedId = ({ payload }: Dispatch) => {
    // we want to be able to do multiple here
    if (!selectedP4aReleaseIds) {
      // if we dont have anything yet
      setSelectedP4aReleaseIds([payload.p4aReleaseId]);
    } else if (
      selectedP4aReleaseIds?.some((id) => id === payload.p4aReleaseId)
    ) {
      // if this is in there
      setSelectedP4aReleaseIds(
        selectedP4aReleaseIds.filter((id) => id !== payload.p4aReleaseId)
      );
    } else {
      // if this isnt in there
      if (selectedP4aReleaseIds) {
        setSelectedP4aReleaseIds([
          ...selectedP4aReleaseIds,
          payload.p4aReleaseId,
        ]);
      } else {
        setSelectedP4aReleaseIds([payload.p4aReleaseId]);
      }
    }
  };

  const handleSave = async () => {
    // upload tracks and artwork, then update the value
    if (selectedP4aReleaseIds && selectedP4aReleaseIds?.length > 0) {
      const promises = selectedP4aReleaseIds?.map((selectedP4aReleaseId) => {
        if (
          assembleArtist?.id &&
          p4aAristId &&
          selectedP4aReleaseId !== "" &&
          selectedP4aReleaseId !==
            resolveMappedPath(
              constructValuePath(path.slice(1)) + ".raw.id",
              preTemplateConfigSectionValue
            )
        ) {
          return uploadAssets(
            p4aAristId,
            assembleArtist.id,
            selectedP4aReleaseId
          );
        } else {
          return undefined;
        }
      });

      Promise.all(promises).then((res) => {
        // going to need to with all results
        update({
          ...param,
          value: {
            p4aReleaseIds: res.map((item) => item.id),
            releases: res,
          },
        });
        setLoading(false);
        setOpen(false);
      });
    } else {
      update({
        ...param,
        value: null,
      });
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setSelectedP4aReleaseIds(sessionInitState);
    setOpen(false);
  };

  return (
    <>
      <FormModal
        headerText={param.overrideName || verb + param.name}
        state={param.value ? "CHANGE" : "ADD"} // change
        open={open}
        setOpen={setOpen}
        handleSave={handleSave}
        handleCancel={handleCancel}
        param={param}
      >
        <div className={styles["container"]}>
          <Release
            imgSize={desktopView ? "220px" : "1fr"}
            releaseFilter={param.filter}
            selectedP4aReleaseIds={selectedP4aReleaseIds}
            selectedReleasesOnMount={sessionInitState}
            dispatch={setSelectedId}
          />
          {loading && (
            <Overlay>
              <Loading />
            </Overlay>
          )}
        </div>
      </FormModal>
      {!param.hideMultiPills && param?.value?.releases && (
        <div>
          <DnDWrapper
            id={param?.key}
            items={param.value.releases}
            setItems={updateReleaseOrder}
            onDragStart={onDragStart}
          >
            {param.value.releases.map((release: any, index: number) => (
              <DnDItem key={release.id} item={release} index={index}>
                <MultiPillWrapper
                  text={release.raw.name || "untitled"}
                  remove={() => removeRelease(release.id)}
                  index={index}
                />
              </DnDItem>
            ))}
          </DnDWrapper>
        </div>
      )}
    </>
  );
};

const MultiPillWrapper = (props: any) => {
  // dragProps come from prop injection via our DND package,
  // so, to get them and send properly, we have to wrap
  return (
    <MultiPill
      {...props}
      multiFile={{
        index: props.index,
        dragProps: props.dragProps,
      }}
    />
  );
};

export default MultiReleasePickerInput;
