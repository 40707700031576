import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Theme,
  MenuItem,
  Menu,
  IconButton,
  Grid,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";

const useStyles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  logo: {
    maxWidth: "160px",
  },
  betaLogo: {
    border: "solid 1px white",
    borderRadius: "3px",
    paddingLeft: "2px",
    paddingRight: "2px",
    paddingTop: "1px",
    fontSize: "9px",
    marginLeft: "15px",
  },
});

function NavBar({ classes }: any) {
  const imageURL = "/images/platoon_logo_white.png";

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    window.location.href = "/sign_out";
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters={true}>
          <Grid container>
            <Grid item xs={12}>
              <Box pl={10} pr={10}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={6}>
                    <Link to="/admin">
                      <img src={imageURL} alt="logo" className={classes.logo} />
                    </Link>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid justify="flex-end" container>
                      <IconButton edge="end" onClick={handleClick}>
                        <AccountCircle />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar disableGutters={true} />

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default withStyles(useStyles)(NavBar);
