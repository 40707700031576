import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArtistLink, { ArtistLinkAPI } from "./models/ArtistLink";
import Header from "../shared/Header";
import LinkIndexCard from "./LinkIndexComponents/LinkIndexCard";
import ToolTipTOS from "./LinkIndexComponents/ToolTipTOS";
import ActionDialog from "../shared/ActionDialog";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Grid, makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useSnackContext } from "../shared/SnackProvider";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import NoResults from "../shared/NoResults";
import { getQueryParam, updateQueryParam } from "../shared/helpers/helpers";
import { useArtistLinkContext } from "../providers/ArtistLinkProvider";
import Loading from "../shared/Loading";
import { useParams } from "react-router-dom";
import { useErrorHandlingContext } from "../providers/ErrorHandlingProvider";

type Props = {
  basePath: string;
  width: Breakpoint;
};
type StyleProps = {
  width: Breakpoint;
};
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    flex: 1,
    paddingRight: ({ width }: StyleProps) =>
      isWidthUp("md", width) ? theme.spacing(10) : theme.spacing(0),
    paddingLeft: ({ width }: StyleProps) =>
      isWidthUp("md", width) ? theme.spacing(10) : theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
}));

function ArtistLinkIndex({ width }: Props) {
  const classes = useStyles({ width });

  const { errorDispatch } = useErrorHandlingContext();
  const { artistLinks, fetchArtistLinks, pageCount } = useArtistLinkContext();

  const [page, setPage] = useState(parseInt(getQueryParam("page")) || 1);
  const [pendingDeletionItem, setPendingDeletionItem] =
    useState<ArtistLink | null>(null);
  const [newlyDupedLink, setNewlyDupedLink] = useState<string | null>(null);
  const { setSnack } = useSnackContext();
  const { artistId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const pageParam = getQueryParam("page");
    pageParam && pageParam != page && setPage(parseInt(pageParam));
  }, [window.location.search]);
  useEffect(() => {
    if (page > pageCount && pageCount > 0) {
      setPage(pageCount);
    }
    //get new query params, and old ones
    const newParams = updateQueryParam("page", page.toString());
    const currentParams =
      "?" + new URLSearchParams(window.location.search).toString();
    //update the query param string IF its not the  and it's not the current params
    newParams != currentParams && navigate(newParams);
  }, [page, pageCount]);
  useEffect(() => {
    if (newlyDupedLink) {
      let link = document.getElementById(newlyDupedLink);
      if (link) {
        link.scrollIntoView({ block: "center", behavior: "smooth" });
        setNewlyDupedLink(null);
      }
    }
  }, [artistLinks, newlyDupedLink]);
  const onChangePage = (_: unknown, newPage: number) => {
    if (newPage !== page) {
      setNewlyDupedLink(null);
      setPage(newPage);
      scrollTop();
    }
  };
  useEffect(() => {
    fetchArtistLinks(artistId, page);
  }, [artistId, page]);
  const deleteArtistLink = (artistLink: ArtistLink) => {
    setPendingDeletionItem(artistLink);
  };
  function needsToPaginate() {
    if (artistLinks && artistLinks.length <= 1) {
      setPage(page - 1);
    } else {
      fetchArtistLinks(artistId, page);
    }
  }

  //TODO move to provider
  const executeDelete = (artistLink: ArtistLink) => {
    if (artistLink.id) {
      ArtistLinkAPI.delete(artistLink.id, artistId)
        .then(() => needsToPaginate())
        .catch((error) =>
          errorDispatch({
            type: "SET_ERROR",
            payload: { error: error, serveErrorPage: true },
          })
        );
    }
  };

  //TODO move to provider
  const executeDuplicate = (artistLink: ArtistLink) => {
    ArtistLinkAPI.clone(artistLink.artist.id, artistLink)
      .then((result: any) => [
        fetchArtistLinks(artistId, page),
        setNewlyDupedLink(result.slug),
        setSnack({
          message: "Player duplicated",
          source: `${result.id}`,
        }),
      ])
      .catch((error) =>
        errorDispatch({
          type: "SET_ERROR",
          payload: { error: error, serveErrorPage: true },
        })
      );
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  };
  if (!artistLinks) {
    return <Loading height={"100vh"} />;
  } else {
    return (
      <>
        <Grid container justify="center" direction="column">
          <Grid item>
            <Header
              text="my listening links"
              button={{
                link: "new/release",
                fullText: "Create New Link",
                shortText: "+ New",
              }}
              toolTip={<ToolTipTOS page={page} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid spacing={1} container className={classes.cardContainer}>
              {artistLinks?.length === 0 ? (
                <Grid item xs={12}>
                  <NoResults
                    text="no listening links yet"
                    action={{
                      to: "new/release",
                      text: "create new link",
                    }}
                  />
                </Grid>
              ) : (
                artistLinks?.map((artistLink: ArtistLink, index: number) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <LinkIndexCard
                        executeDuplicate={executeDuplicate}
                        deleteArtistLink={deleteArtistLink}
                        data={artistLink}
                        newlyDupedLink={newlyDupedLink}
                      />
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
          {pageCount > 1 && (
            <Grid item xs={12}>
              <Grid container justify="center">
                <Pagination
                  page={page}
                  onChange={onChangePage}
                  count={pageCount}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        {pendingDeletionItem && (
          <ActionDialog
            open={pendingDeletionItem ? true : false}
            context={pendingDeletionItem}
            onClose={() => setPendingDeletionItem(null)}
            onAction={executeDelete}
            actionTitle="CONFIRM"
          >
            Are you sure you want to delete?
          </ActionDialog>
        )}
      </>
    );
  }
}
export default withWidth()(ArtistLinkIndex);
