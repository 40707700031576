import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled.div`
  display: contents;
  text-align: inherit;
  border-radius: 5px;
  padding: unset;
  background: unset;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default (props: any) => {
  let navigate = useNavigate();

  if (props.to) {
    return (
      <StyledLink
        {...props}
        onClick={() => navigate(props.to)}
        style={{ cursor: "pointer" }}
      />
    );
  } else {
    return <StyledLink {...props} />;
  }
};
