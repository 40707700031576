import React from "react";
import { ReactComponent as ChevronDown } from "../../../../shared/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../../../shared/icons/chevron-up.svg";

type Props = {
  readMore: boolean;
  setReadMore: React.Dispatch<React.SetStateAction<boolean>>;
  backgroundColor?: string;
};

const ReadMoreButton = ({ readMore, setReadMore, backgroundColor }: Props) => {
  return (
    <button
      onClick={() => setReadMore(!readMore)}
      style={{
        fontFamily: "inherit",
        transition: "background, padding-top 200ms ease-in-out",
        background: readMore
          ? `linear-gradient(180deg, ${backgroundColor}00 100%, ${backgroundColor} 50%)`
          : `linear-gradient(180deg, ${backgroundColor}00 0%, ${backgroundColor} 50%)`,
        paddingTop: readMore ? "70px" : "50px",
      }}
    >
      <hr />
      {readMore ? (
        <>
          <p>Read Less</p>
          <ChevronUp />
        </>
      ) : (
        <>
          <p>Read More</p>
          <ChevronDown />
        </>
      )}
    </button>
  );
};

export default ReadMoreButton;
