import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logger } from "../../shared/SafeLogger";
import ActionBar from "../../shared/ActionBar";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useTheme, makeStyles, Theme } from "@material-ui/core";
import ArtistLink, {
  ArtistLinkAPI,
  Track,
  defaultArtistLink,
} from "../models/ArtistLink";
import API from "../../shared/API";
import { defaultResourceSchema, UserRole } from "../../shared/ResourceSchema";
import FormFields from "./FormFields";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import MobileEditView from "./MobileEditView";
import ArtistLinkMusicPlayer from "../MusicPlayerComponents/ArtistLinkMusicPlayer";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { usePlatoonReleaseContext } from "../../providers/PlatoonReleaseProvider";
import Loading from "../../shared/Loading";
import { useParams } from "react-router-dom";
interface Props {
  basePath: string;
  width: Breakpoint;
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
    overflow: "hidden",
  },
  formWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  formFields: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "500px",
    maxHeight: "calc(100vh - 70px)",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#e4cfd380",
      borderRadius: "10px",
    },
    borderRight: `1px solid ${theme.palette.secondary.light}`,
  },
  musicPlayer: {
    width: "100%",
    minWidth: 0,
    paddingLeft: "500px",
  },
}));

// broken when trying to make new link.  it is trying to fetch the id of "new"

function ArtistLinkEdit(props: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();

  const { loading } = useLoadingContext();
  const { setRelease, fetchRelease } = usePlatoonReleaseContext();
  const {
    artistLink,
    loadArtistLink,
    createArtistLink,
    saveArtistLink,
    ogArtistLink,
    setOGArtistLink,
  } = useArtistLinkContext();
  const [forceOpenCollapse, setForceOpenCollapse] = useState<string[]>([]);

  const { artistId, artistLinkId } = useParams();
  const hasReleasePicked = artistLink && artistLink.p4aReleaseId;

  useEffect(() => {
    if (hasReleasePicked && artistLinkId === "new" && artistId) {
      fetchRelease(artistId, artistLink.p4aReleaseId).then(
        (p4aRelease: any) => {
          createArtistLink(artistId, p4aRelease);
        }
      );
    } else if (artistId && artistLinkId !== "new") {
      artistId && loadArtistLink(artistId, artistLinkId);
    } else {
      !artistLink ? navigate("../new/release") : createArtistLink(artistId);
    }
  }, [artistLinkId, hasReleasePicked, artistId]);

  const setValidationErrors = (errors: any) => {
    let topMostError = document.getElementById(
      `${errors[0].props.id}-helper-text`
    );
    topMostError?.scrollIntoView({ block: "center", behavior: "smooth" });

    errors.map((error: any) => {
      let validationError = error.props.name;
      switch (validationError) {
        case "artistSocials":
        case "password":
        case "confirmPassword":
        case "audioDownloadPassword":
        case "confirmAudioDownloadPassword":
          return setForceOpenCollapse([...forceOpenCollapse, validationError]);
      }
    });
  };

  const handleCancel = () => {
    setOGArtistLink(defaultArtistLink());
    setRelease(null);
    if (artistLinkId) {
      navigate("../");
    } else if (artistLink?.id) {
      ArtistLinkAPI.delete(artistLink.id, artistId).then(() => {
        navigate("../");
      });
    }
  };

  const handleSave = () => {
    // Save the artist link
    var promises = [];

    if (artistLinkIsDirty() && artistLink) {
      promises.push(
        new Promise((res) => {
          Logger.of("App").info("Running promise to saveArtistLink");
          saveArtistLink(artistLink).then(res);
        })
      );
    }

    if (tracksAreDirty() && artistLink) {
      promises.push(
        new Promise((res) => {
          saveTracksForArtistLink(artistLink).then(res);
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        navigate("../");
        setRelease(null);
        setOGArtistLink(defaultArtistLink());
      })
      .catch((e) => Logger.of("App").info("Error handling save", e));
  };

  const saveTracksForArtistLink = async (artistLink: ArtistLink) => {
    return artistLink?.tracks?.map((track: Track) => {
      const trimmedTrack = { title: track.title };
      return API.update(
        track.id,
        trimmedTrack,
        defaultResourceSchema(
          `/artists/${artistLink.artist.id}/tracks`,
          UserRole.Artist
        )
      );
    });
  };

  const isDirty = () => {
    return artistLinkIsDirty() || tracksAreDirty();
  };

  const artistLinkIsDirty = () => {
    return JSON.stringify(ogArtistLink) !== JSON.stringify(artistLink);
  };

  const tracksAreDirty = () => {
    return (
      JSON.stringify(ogArtistLink?.tracks) !==
      JSON.stringify(artistLink?.tracks)
    );
  };

  return (
    <>
      {!artistLink || loading ? (
        <Loading height={"100vh"} />
      ) : (
        <ValidatorForm
          className={classes.formContainer}
          onSubmit={handleSave}
          instantValidate={true}
          onError={(errors: any) => setValidationErrors(errors)}
        >
          {isWidthUp("md", props.width) ? (
            <>
              <div className={classes.formWrapper}>
                <div className={classes.formFields}>
                  <FormFields
                    setForceOpenCollapse={setForceOpenCollapse}
                    forceOpenCollapse={forceOpenCollapse}
                    width={`${theme.breakpoints.width("sm").toString()}px`}
                  />
                </div>
                <div className={classes.musicPlayer}>
                  <ArtistLinkMusicPlayer
                    playerHeight={"calc(100vh - 170px)"}
                    playerWidth={12}
                    preview={true}
                  />
                </div>
              </div>
              <ActionBar
                position="bottom"
                buttons={[
                  {
                    text: "Cancel",
                    action: handleCancel,
                    group: "right",
                    buttonProps: { variant: "outlined" },
                    textProps: {
                      color: "textPrimary",
                      style: {
                        fontWeight: "bold",
                      },
                    },
                  },
                  {
                    text: "Save",
                    group: "right",
                    buttonProps: {
                      variant: "contained",
                      color: "secondary",
                      disabled: !isDirty(),
                      type: "submit",
                    },
                  },
                ]}
              />
            </>
          ) : (
            <MobileEditView
              onSave={handleSave}
              onCancel={handleCancel}
              setForceOpenCollapse={setForceOpenCollapse}
              forceOpenCollapse={forceOpenCollapse}
              canSave={isDirty()}
            />
          )}
        </ValidatorForm>
      )}
    </>
  );
}

export default withWidth()(ArtistLinkEdit);
