import React from "react";
import { ReactComponent as ArrowLeft } from "../../../shared/icons/arrow-left.svg";
import { ReactComponent as ArrowCounterClockWise } from "../../../shared/icons/arrow-counterclockwise-circle.svg";
import { ReactComponent as ArrowsUpAndDown } from "../../../shared/icons/arrows-up-and-down.svg";
import { ReactComponent as CheckMarkCircle } from "../../../shared/icons/checkmark-circle.svg";
import { ReactComponent as PencilCircle } from "../../../shared/icons/pencil-circle.svg";
import { ReactComponent as CheckMarkCloud } from "../../../shared/icons/checkmark-icloud.svg";
import { useNavigate } from "react-router-dom";
import styles from "./TemplatePageEditorMobileActionBar.module.scss";
import { TemplatePage } from "../../types/TemplatePages";

type ActionBarProps = {
  templatePage: TemplatePage;
  formTouched: boolean;
  isDirty: boolean;
  showPreview: boolean;
  mutationLoading: boolean;
  mutationSuccess: boolean;
  handlePublish: (alreadyPublished: boolean) => Promise<void>;
  handleUnPublish: () => void;
  handlePreview: () => void;
};

const TemplatePageEditorMobileActionBar = ({
  templatePage,
  formTouched,
  isDirty,
  showPreview,
  mutationLoading,
  mutationSuccess,
  handlePublish,
  handleUnPublish,
  handlePreview,
}: ActionBarProps) => {
  const navigate = useNavigate();

  return (
    <footer className={styles.footer}>
      <div className="flex-1">
        <button
          onClick={() => navigate("../")}
          disabled={formTouched && (isDirty || mutationLoading)}
          className={`${styles["icon-button"]} btn--naked btn--cursor`}
        >
          <ArrowLeft className={styles.icon} />
          <div className={styles["icon-text"]}>EXIT</div>
        </button>
      </div>
      {templatePage.published && (
        <button onClick={handleUnPublish} className={"btn--naked btn--cursor"}>
          <ArrowCounterClockWise className={styles.icon} />
          <div className={`text-center ${styles["icon-text"]} `}>UNPUBLISH</div>
        </button>
      )}
      <div className={styles["icon-width"]}>
        <button
          onClick={handlePreview}
          className={`${styles["icon-button"]} btn--naked  btn--cursor full-width`}
        >
          {showPreview ? (
            <PencilCircle className={styles.icon} />
          ) : (
            <ArrowsUpAndDown className={styles.icon} />
          )}
          <div className={`text-center ${styles["icon-text"]} `}>
            {showPreview ? "EDIT" : "PREVIEW"}
          </div>
        </button>
      </div>
      <div className="relative">
        <div>
          {mutationLoading ? (
            <div className={styles["mobile-save"]}>
              <button className={"btn--naked"}>
                <CheckMarkCloud className={styles.icon} />
                <div className={`text-center ${styles["save"]} `}>SAVING</div>
              </button>
            </div>
          ) : (
            mutationSuccess &&
            !isDirty && (
              <div className={styles["mobile-save"]}>
                <button className={"btn--naked"}>
                  <CheckMarkCloud className={styles.icon} />
                  <div className={`text-center ${styles["save"]} `}>SAVED</div>
                </button>
              </div>
            )
          )}
        </div>
        <div className={styles["icon-width"]}>
          <button
            onClick={() => handlePublish(templatePage.published)}
            className={`${styles["icon-button"]} btn--naked btn--cursor full-width`}
          >
            <CheckMarkCircle className={styles.icon} />
            <div className={`text-center ${styles["icon-text"]} `}>
              {templatePage.published ? "UPDATE" : "PUBLISH"}
            </div>
          </button>
        </div>
      </div>
    </footer>
  );
};

export default TemplatePageEditorMobileActionBar;
