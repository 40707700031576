import React, { useState } from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import styles from "./UnknownSection.module.scss";
// import { resolveMappedPath } from "../../../../../asset-generator-lib/helpers";
// import { useTemplatePageEditorContext } from "../../TemplatePageEditor/TemplatePageEditor";

type Props = {
  convertedBlock: ConvertedBlock;
};

const UnknownSection = ({ convertedBlock }: Props) => {
  const [open, setOpen] = useState(false);

  // example of how to get mapped vaules from mapped data
  // const { templatePage } = useTemplatePageEditorContext();

  // if (convertedBlock.key === "mappedTest") {
  //   const mappedPath = convertedBlock?.params?.mappedTest?.mappedPath;
  //   const mappedValue = resolveMappedPath(
  //     mappedPath || "",
  //     templatePage.mappedData
  //   );

  //   console.log("", {
  //     mappedPath,
  //     mappedValue,
  //   });
  // }

  return (
    <div className={styles.section}>
      <button className="btn--naked" onClick={() => setOpen(!open)}>
        UnknownSection: {convertedBlock.key}
      </button>
      <pre style={{ display: open ? "block" : "none" }}>
        {JSON.stringify(convertedBlock, null, 2)}
      </pre>
    </div>
  );
};

export default UnknownSection;
