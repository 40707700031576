import React, { useContext } from 'react';
import clsx from 'clsx';
import { PlatoonContext } from "../PlatoonContext"

interface Props {
  theme: any,
  getEditorState: any,
  setEditorState: any,
  addImage: any,
  modifier: any,
  blockType: string
}

const ImageButton = (props: Props) => {
  const { setBlockEditSession } = useContext(PlatoonContext);

  const onClick = () => {

    let session = { blockType: "image" };
    setBlockEditSession(session);
  };


  const blockTypeIsActive = () => {
    const editorState = props.getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === props.blockType;
  };


  const theme = props.theme;
  const className = blockTypeIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <React.Fragment>
      <div className={theme.buttonWrapper} >
        <button className={className} onClick={onClick} type="button">
          <svg width="24px" height="24px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="WYSIWYG-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Menu-Buttons" transform="translate(-75.000000, -138.000000)" fill="#888888" fillRule="nonzero">
                <path d="M92.0070547,153.983887 C93.9162344,153.983887 94.9704336,152.929688 94.9704336,151.037109 L94.9704336,141.001465 C94.9704336,139.100586 93.9162344,138.054688 92.0070547,138.054688 L78.0949453,138.054688 C76.1857656,138.054688 75.1315664,139.100586 75.1315664,141.001465 L75.1315664,151.037109 C75.1315664,152.937988 76.1857656,153.983887 78.0949453,153.983887 L92.0070547,153.983887 Z M77.3561758,149.576172 L77.3561758,141.283691 C77.3561758,140.586426 77.7048086,140.279297 78.3522695,140.279297 L91.7497305,140.279297 C92.3971914,140.279297 92.7458242,140.586426 92.7458242,141.283691 L92.7458242,149.244141 L89.2013906,145.932129 C88.8361562,145.608398 88.4045156,145.434082 87.9645742,145.434082 C87.516332,145.434082 87.101293,145.591797 86.7277578,145.923828 L82.9924062,149.269043 L81.4650625,147.899414 C81.1164297,147.583984 80.726293,147.42627 80.3361562,147.42627 C79.9377187,147.42627 79.6222891,147.575684 79.2736562,147.882812 L77.3561758,149.576172 Z M82.0793203,146.313965 C83.1999258,146.313965 84.1296133,145.392578 84.1296133,144.255371 C84.1296133,143.126465 83.1999258,142.188477 82.0793203,142.188477 C80.9421133,142.188477 80.0124258,143.126465 80.0124258,144.255371 C80.0124258,145.392578 80.9421133,146.313965 82.0793203,146.313965 Z" id="img"></path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </React.Fragment>

  );
}



export default ImageButton;
