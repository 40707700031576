import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { ContentNode } from "../ContentProvider";

type Props = {
  contentNode: ContentNode;
};

const useStyles = makeStyles(() => ({
  card: {
    // remove link stuff
    textDecoration: "none",
    fontFamily: "inherit",
    fontSize: "100%",
    border: 0,

    //Styling
    borderRadius: 8,
    backgroundColor: "#1d1d1d",
    // TODO(PLTN-4482) change back to "16px 24px" when we have the missing icons.
    padding: "24px",
    color: "#fff",

    // layout
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#2d2d2d",
    },
  },
  iconHolder: {
    minWidth: "32px",
    height: "38px",
  },
  icon: {
    height: "100%",
  },
  text: {
    marginLeft: "15px",
    fontFamily: "Futura",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#ffffff",
    textTransform: "uppercase",
  },
}));

const IconCard = ({ contentNode }: Props) => {
  const classes = useStyles();
  return (
    <Link to={contentNode.path()} className={classes.card}>
      {/* TODO(PLTN-4482) add icon back when we have the missing icons.*/}
      <label className={classes.text}>{contentNode.title}</label>
    </Link>
  );
};

export default IconCard;
