import React, { useState, useEffect, useContext } from "react";
import API, { ResponseError } from "../shared/API";
import { DropzoneArea } from "material-ui-dropzone";
import { SnackContext } from "../shared/SnackProvider";

import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  createStyles,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Asset, emptyAsset, copyAsset } from "../platoon-cms-lib";
import AssetSchema from "./AssetSchema";

// Non-dependent styles
const styles = createStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formHr: {
    margin: "20px 0",
  },
  image: {
    maxWidth: "200px",
    width: "20%",
  },
});

const AssetEdit = (props: any) => {
  const assetId = props.match.params.id;
  const schema = AssetSchema;

  const creationNeeded = () => assetId === undefined;

  const [asset, setAsset] = useState<Asset>(emptyAsset());
  const [responseError, setResponseError] = useState<ResponseError>();
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { setSnack } = useContext(SnackContext);

  const loadIfNeeded = () => {
    if (creationNeeded()) {
      return;
    }

    setLoading(true);

    API.read(assetId, schema)
      .then((asset) => {
        setAsset(asset);
        setLoading(false);
      })
      .catch(handleError);
  };

  useEffect(loadIfNeeded, [assetId]);

  const save = () => {
    setLoading(true);
    createOrUpdate().catch(handleError);
  };

  const createOrUpdate = () => {
    const formData = new FormData();
    const file = files[0];
    if (file) {
      formData.append("attachment", files[0]);
    }

    if (asset.description) {
      formData.append("description", asset.description);
    }

    return creationNeeded()
      ? API.create(formData, schema).then((response) => {
          location.href = `/${schema.rootPath}/${response.id}`;
        })
      : API.update(assetId, formData, schema).then((response) => {
          setAsset(response);
          setSnack({ message: `Saved ${schema.title}` });
          setFiles([]);
          setResponseError(undefined);
          setLoading(false);
        });
  };

  const handleError = (error: ResponseError | Error) => {
    setLoading(false);
    let candidateResponseError = error as ResponseError;
    if (candidateResponseError.reasons !== undefined) {
      setResponseError(candidateResponseError);
    } else {
      setSnack({ message: error.message });
    }
  };

  const onChangeDescription = (event: any) => {
    let newAsset = copyAsset(asset) as any;
    newAsset[event.target.name] = event.target.value;
    setAsset(newAsset);
  };

  const clearAttachment = () => {
    let newAsset = copyAsset(asset);
    newAsset.attachment = "";
    setAsset(newAsset);
  };

  return (
    <>
      <Grid
        justify="space-between" // Add it here :)
        container
        spacing={0}
      >
        <Typography variant="h4" gutterBottom>
          {schema.title}
        </Typography>
      </Grid>

      <Divider />

      <div>
        <Button
          variant="contained"
          disabled={loading}
          color="primary"
          onClick={save}
          value="Save"
        >
          Save
        </Button>

        <div>
          <TextField
            id="description"
            name="description"
            label="Description"
            type="text"
            value={asset.description}
            onChange={onChangeDescription}
            helperText={responseError && responseError.reasons.description}
            error={
              responseError && responseError.reasons.description !== undefined
            }
            fullWidth
            margin="normal"
          />
        </div>
        <Divider />
        <div>
          {asset.attachment ? (
            <div>
              <img src={asset.attachment} />
              <br />
              <Button color="secondary" onClick={clearAttachment}>
                Clear Attachment
              </Button>
            </div>
          ) : (
            <DropzoneArea onChange={setFiles} />
          )}
        </div>
      </div>

      {loading && <CircularProgress />}
    </>
  );
};

export default withStyles(styles)(AssetEdit);
