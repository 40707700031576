import React from "react";
import { Box, Grid, InputAdornment, makeStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

const useStyles = makeStyles(() => ({
  dateTimePicker: {
    "& .MuiTypography-h3": {
      fontSize: "2.125rem",
    },
  },
}));

interface Props {
  artistLinkKey?: any;
  timeFrame: any;
}

export default function ArtistLinkInputDatePicker(props: Props) {
  const classes = useStyles();
  const { artistLink, dispatch } = useArtistLinkContext();
  const startDateTime = artistLink.startDateTime;

  const handleDateChange = (date: Date | null) => {
    let updatedArtistLink = { ...artistLink } as any;
    updatedArtistLink[props.timeFrame] = date;
    dispatch({
      type: "SET_ARTISTLINK",
      payload: updatedArtistLink,
    });
  };

  const setMinDate = () => {
    if (props.timeFrame === "endDateTime") return startDateTime;
    if (
      props.timeFrame === "startDateTime" &&
      startDateTime &&
      startDateTime < new Date()
    ) {
      return startDateTime;
    } else return new Date();
  };

  return (
    <>
      <Grid item xs={12}>
        <Box pl={3} pr={3} display="flex">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              PopoverProps={{ className: classes.dateTimePicker }}
              fullWidth
              minDate={setMinDate()}
              minDateMessage={""}
              variant="inline"
              margin="normal"
              id="date-picker-inline"
              value={props.artistLinkKey}
              onChange={handleDateChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowDropDownCircleOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Grid>
    </>
  );
}
