import React from "react";

type LockIconProps = {
  className?: React.HTMLAttributes<HTMLOrSVGElement>["className"];
};
const LockIcon = ({ className }: LockIconProps) => {
  return (
    <svg
      className={className}
      width="150px"
      height="149px"
      viewBox="0 0 150 149"
      version="1.1"
    >
      <title>lock</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M74.8033333,149.003906 C115.549427,149.003906 149.24474,115.382812 149.24474,74.5625 C149.24474,33.8164062 115.475208,0.12109375 74.7291146,0.12109375 C33.9830208,0.12109375 0.361927087,33.8164062 0.361927087,74.5625 C0.361927087,115.382812 33.9830208,149.003906 74.8033333,149.003906 Z M95.2134896,112.191406 L54.3189583,112.191406 C49.0494271,112.191406 46.5259896,109.59375 46.5259896,103.878906 L46.5259896,72.4101562 C46.5259896,67.0664062 48.6783333,64.5429688 53.2056771,64.171875 L53.2056771,54.5234375 C53.2056771,39.53125 62.4830208,30.328125 74.8033333,30.328125 C87.1236458,30.328125 96.4009896,39.53125 96.4009896,54.5234375 L96.4009896,64.0976562 C100.928333,64.46875 103.080677,67.0664062 103.080677,72.4101562 L103.080677,103.878906 C103.080677,109.59375 100.483021,112.191406 95.2134896,112.191406 Z M90.0181771,64.0234375 L90.0181771,53.9296875 C90.0181771,43.5390625 83.9322396,36.5625 74.8033333,36.5625 C65.6744271,36.5625 59.5884896,43.5390625 59.5884896,53.9296875 L59.5884896,64.0234375 L90.0181771,64.0234375 Z"
          id="lock"
          fill="#FFFFFF"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export default LockIcon;
