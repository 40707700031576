import React from "react";
import styles from "./ScrollAnimation.module.scss";
import { useInView } from "react-intersection-observer";
import { ScrollAnimation } from "../../../../asset-generator-lib/composer/models/Block";

type Props = {
  children: React.ReactNode;
  animation: ScrollAnimation;
};

const ScrollAnimation = ({ children, animation }: Props) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
    triggerOnce: true,
    delay: 200,
  });

  const style = inView ? styles[animation] : styles["invisible"];

  return (
    <div ref={ref} className={style}>
      {children}
    </div>
  );
};

export default ScrollAnimation;
