import React, { useEffect } from "react";
import {
  ArtistLinkAction,
  useAnalyticsContext,
} from "../../providers/AnalyticsProvider";
import { Button, makeStyles, Theme } from "@material-ui/core";
import Linkify from "react-linkify";
import CopyToClipBoard from "react-copy-to-clipboard";
import { useSnackContext } from "../../shared/SnackProvider";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

type Props = {
  setOpenDescription: any;
  fontColor: string;
};

type StyleProps = {
  color: string;
  fontColor: string;
  fontFamily: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    height: "100%",
    padding: theme.spacing(1),
    fontSize: "18px",
    color: ({ color }: StyleProps) => color,
    whiteSpace: "pre-line",
    fontFamily: ({ fontFamily }: StyleProps) => fontFamily,
    overflowY: "auto",
  },
  inlineLink: {
    color: ({ fontColor }: StyleProps) => `${fontColor}B3`,
  },
  copyBio: {
    marginTop: theme.spacing(2),
  },
  copyBioText: {
    cursor: "pointer",
    color: "white",
    fontSize: "14px !important",
    fontFamily: ({ fontFamily }: StyleProps) => fontFamily,
    border: "1px solid white",
    borderRadius: "unset",
    letterSpacing: "unset",
    padding: "6px 12px",
  },
}));

const ExpandedDescription = ({ fontColor }: Props) => {
  const { trackArtistLink } = useAnalyticsContext();
  const { artistLink } = useArtistLinkContext();
  const { setSnack } = useSnackContext();

  useEffect(() => {
    if (artistLink.id) {
      trackArtistLink(artistLink, ArtistLinkAction.bioViewed);
    }
  }, [artistLink.id]);

  const fontFamily = artistLink.descriptionFont
    ? artistLink.descriptionFont
    : "";

  const classes = useStyles({
    color: fontColor,
    fontFamily: fontFamily,
    fontColor: fontColor,
  });

  const copyBio = () => {
    setSnack({ message: "Bio copied" });
  };

  const componentDecorator = (href: any, text: any, key: any) => (
    <a
      className={classes.inlineLink}
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i>{text}</i>
    </a>
  );

  return (
    <div className={classes.description}>
      <Linkify componentDecorator={componentDecorator}>
        {artistLink.description}
      </Linkify>
      <CopyToClipBoard
        text={artistLink.description ? artistLink.description : ""}
      >
        <div className={classes.copyBio}>
          <Button
            variant="outlined"
            onClick={copyBio}
            className={classes.copyBioText}
          >
            Copy Bio
          </Button>
        </div>
      </CopyToClipBoard>
    </div>
  );
};

export default ExpandedDescription;
