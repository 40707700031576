import React from "react";
import { Node } from "../../../platoon-cms-lib";
import { ContentNode } from "../ContentProvider";
import BookmarkCard from "./BookmarkCard";
import IconCard from "./IconCard";
import TextCard from "./TextCard";

type Props = {
  contentNode: ContentNode;
};

const CardsRenderer = ({ contentNode }: Props) => {
  const renderCardType = (
    parentCardType: Node["cardType"],
    card: ContentNode
  ) => {
    switch (parentCardType) {
      case "bookmark":
        return <BookmarkCard contentNode={card} key={card.id} />;
      case "text":
        return <TextCard contentNode={card} key={card.id} />;
      case "icon":
        return <IconCard contentNode={card} key={card.id} />;
      case "header":
        return <TextCard contentNode={card} key={card.id} />;
      default:
        return (
          <div key={card.id}>
            parent card type "{parentCardType}" not recognized
          </div>
        );
    }
  };

  const cards = contentNode.children.map((card) =>
    renderCardType(contentNode.cardType, card)
  );

  return <React.Fragment>{cards}</React.Fragment>;
};

export default CardsRenderer;
