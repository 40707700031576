import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { ContentNode } from "../ContentProvider";

type Props = {
  contentNode: ContentNode;
};

const useStyles = makeStyles(() => ({
  card: {
    // remove link stuff
    textDecoration: "none",
    display: "block",
    fontFamily: "inherit",
    fontSize: "100%",
    border: 0,

    //Styling
    borderRadius: 8,
    backgroundColor: "#1d1d1d",
    padding: "24px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2d2d2d",
    },
  },
  title: {
    fontFamily: "Futura",
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "22px",
    color: "#ffffff",
    textTransform: "uppercase",
    margin: "0 0 8px 0",

    textAlign: "start",
  },
  subtitle: {
    fontFamily: "Helvetica",
    fontSize: "15px",
    fontWeight: "bold",
    color: "#ff4169",
    textTransform: "uppercase",
    margin: "0 0 16px 0",

    textAlign: "start",
  },
  description: {
    fontFamily: "Helvetica",
    fontSize: "15px",
    color: "#ffffff",
    margin: "0 0 8px 0",
    textAlign: "start",
  },
}));

const TextCard = ({ contentNode }: Props) => {
  const classes = useStyles();

  return (
    <Link to={contentNode.path()} className={classes.card}>
      <h1 className={classes.title}> {contentNode.title}</h1>
      <h2 className={classes.subtitle}>{contentNode.subtitle}</h2>
      <p className={classes.description}>{contentNode.description}</p>
    </Link>
  );
};

export default TextCard;
