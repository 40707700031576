import React from "react";
import Nestable from "react-nestable";
import "./NodeStyles.scss";
import { CMSNode } from "./NodeSchema";
import NodeCreateDialogContent from "./NodeCreateDialogContent";
import NodeDeleteDialogContent from "./NodeDeleteDialogContent";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ChevronRight as RightIcon,
  KeyboardArrowDown as DownIcon,
} from "@material-ui/icons";
import { Fab, Grid, Typography, Tooltip, useTheme } from "@material-ui/core";
import { makeStyles, Theme, createMuiTheme } from "@material-ui/core/styles";

const style = createMuiTheme({
  spacing: 5,
});

const useStyles = makeStyles((theme: Theme) => ({
  treeContainer: {
    backgroundColor: theme.palette.background.default,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  row: {
    display: "flex",
    cursor: "move",
    border: "solid .5px #515151",
    backgroundColor: theme.palette.secondary.dark,
    height: style.spacing(12),
    lineHeight: "60px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  "@keyframes blinker": {
    from: { backgroundColor: theme.palette.secondary.dark },
    to: { backgroundColor: theme.palette.secondary.light },
  },
  rowBlinker: {
    display: "flex",
    cursor: "move",
    border: "solid .5px #515151",
    height: style.spacing(12),
    backgroundColor: theme.palette.secondary.dark,
    lineHeight: "60px",
    animationName: "$blinker",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount: "3",
  },
  text: {
    width: "50%",
    fontSize: "15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: style.spacing(3),
    flexGrow: 1,
    paddingLeft: style.spacing(6),
  },
  subtitle: {
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 0,
    paddingRight: style.spacing(3),
    marginRight: style.spacing(8),
    fontSize: style.spacing(3),
  },
  collapseIcon: {
    fontSize: style.spacing(3),
    cursor: "pointer",
    marginRight: "0px",
    position: "relative",
    top: style.spacing(1),
  },
  treeContentContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: "30vw",
    width: "100%",
  },
  nodeTypeStyle: {
    flexGrow: 1,
    flexBasis: 0,
    marginRight: style.spacing(3),
  },
  nodePublishedStyle: {
    flexGrow: 1,
    flexBasis: 0,
    marginTop: style.spacing(1.5),
  },
  nodeActionStyle: {
    flexGrow: 1,
    flexBasis: 0,
    display: "flex",
    marginTop: style.spacing(1.5),
    paddingRight: style.spacing(2),
  },
  nodeTreeHeadersContainer: {
    display: "flex",
    marginTop: style.spacing(6),
    marginBottom: style.spacing(4),
  },
  nodeTreeHeadersWrapper: {
    flexGrow: 1,
    display: "flex",
    maxWidth: "30vw",
    width: "100%",
  },
  nodeTreeHeaderTitle: {
    flexGrow: 1,
    paddingLeft: style.spacing(6),
  },
  nodeTreeHeaderSubTitle: {
    flexGrow: 1,
    flexBasis: "0",
    paddingRight: style.spacing(3.5),
    marginRight: style.spacing(8),
  },
  nodeTreeHeaderType: {
    flexGrow: 1,
    flexBasis: "0",
    marginRight: style.spacing(3),
  },
  nodeTreeHeaderIcons: {
    display: "flex",
    flexGrow: 1,
    flexBasis: "0",
    paddingRight: style.spacing(5),
  },
  addIconButton: {
    boxShadow: "none",
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main}80`,
    },
  },
}));

interface Props {
  rootNode?: CMSNode;
  items?: CMSNode[];
  setItems: any;
  reload: any;
}

const NodeTree = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();

  const { rootNode, reload, items, setItems } = props;
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [pendingDeletionNode, setPendingDeletionNode] = React.useState(false);
  const [createdNode, setCreatedNode] = React.useState<CMSNode | null>(null);

  const collapseIcon = (props: any) => {
    return props.isCollapsed ? (
      <span className={classes.collapseIcon}>
        <RightIcon />
      </span>
    ) : (
      <span className={classes.collapseIcon}>
        <DownIcon />
      </span>
    );
  };

  const handleChange = (items: any) => {
    setItems(items);
  };

  //Callback which has two parameters: dragItem - item which is being dragged, destinationParent - item where dragItem is about to land.
  //Let function return false if this movement should not happen.
  const validateChange = (_: CMSNode, destinationParent?: CMSNode) => {
    let parent = destinationParent || rootNode;
    return parent && parent.type !== "article";
  };

  function addNode() {
    setDialogIsOpen(true);
  }

  function deleteNode(item: any) {
    setPendingDeletionNode(item);
  }

  function handleDialogClose() {
    setDialogIsOpen(false);
    setPendingDeletionNode(false);
  }

  const ItemRow = (props: any) => {
    const { item, collapseIcon, handler } = props;

    const ItemRowStyle = () => {
      if (createdNode) {
        return item.id === createdNode.id ? classes.rowBlinker : classes.row;
      } else return classes.row;
    };

    return (
      <React.Fragment>
        <div className={ItemRowStyle()}>
          <div className={classes.text}>
            {handler}
            {collapseIcon}
            {item.title}
          </div>
          <div className={classes.treeContentContainer}>
            <div className={classes.subtitle}>{item.subtitle}</div>
            <div className={classes.nodeTypeStyle}>
              {item.type.capitalize()}
            </div>
            <div className={classes.nodePublishedStyle}>
              <div>
                {item.published ? (
                  <VisibilityIcon
                    style={{ color: `${theme.palette.secondary.main}80` }}
                  />
                ) : (
                  <VisibilityOffIcon style={{ opacity: "30%" }} />
                )}
              </div>
            </div>
            <div className={classes.nodeActionStyle}>
              <Link onClick={() => deleteNode(item)} to="#">
                <DeleteIcon style={{ color: "white" }} />
              </Link>
              <Link to={`${item.id}`}>
                <EditIcon style={{ color: "white" }} />
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Grid justify="space-between" container spacing={0}>
        <Typography variant="h6" gutterBottom>
          Contained Pages
        </Typography>

        <Tooltip title="New" aria-label="new" placement="top">
          <Fab
            className={classes.addIconButton}
            onClick={addNode}
            color="secondary"
            aria-label="add"
            size="small"
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>

      <div className={classes.nodeTreeHeadersContainer}>
        <div className={classes.nodeTreeHeaderTitle}>Title</div>
        <div className={classes.nodeTreeHeadersWrapper}>
          <div className={classes.nodeTreeHeaderSubTitle}>Subtitle</div>
          <div className={classes.nodeTreeHeaderType}>Type</div>
          <div className={classes.nodeTreeHeaderIcons}>Published</div>
          <div className={classes.nodeTreeHeaderIcons}>Actions</div>
        </div>
      </div>

      <Nestable
        className={classes.treeContainer}
        items={items}
        collapsed={true}
        renderItem={ItemRow}
        renderCollapseIcon={collapseIcon}
        onChange={handleChange}
        confirmChange={validateChange}
      />

      {dialogIsOpen && (
        <NodeCreateDialogContent
          createdNode={setCreatedNode}
          open={dialogIsOpen}
          onClose={handleDialogClose}
          parentId={rootNode?.id || ""}
          reload={reload}
        />
      )}

      {pendingDeletionNode && (
        <NodeDeleteDialogContent
          open={pendingDeletionNode}
          node={pendingDeletionNode}
          onClose={handleDialogClose}
          reload={reload}
        />
      )}
    </div>
  );
};

export default NodeTree;
