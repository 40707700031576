import React from "react";

import Release from "./Release";
import ActionBar from "../../shared/ActionBar";
import { useNavigate } from "react-router-dom";
import { usePromoPackContext } from "../providers/PromoPackProvider";

const PromoPackRelease = () => {
  const { promoPack, dispatch } = usePromoPackContext();
  const navigate = useNavigate();
  return (
    <>
      <Release
        selectedP4aReleaseId={promoPack?.p4aReleaseId}
        dispatch={dispatch}
      />
      <ActionBar
        position="bottom"
        buttons={[
          {
            text: "Next",
            group: "right",
            action: () => {
              navigate("../template");
            },
            buttonProps: {
              color: "secondary",
              variant: "contained",
              disabled: !promoPack?.p4aReleaseId,
            },
          },
          {
            text: "Cancel",
            group: "left",
            action: () => navigate("./../.."),
            buttonProps: {
              variant: "outlined",
            },
          },
        ]}
      />
    </>
  );
};

export default PromoPackRelease;
