import React, { useEffect } from "react";
import ContentEditor from "./ContentEditor";
import { isWidthUp, makeStyles, withWidth } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { ContentNode } from "../../publications/components/ContentProvider";
import NodeActionButtons from "../../publications/components/NodeActionButtons";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";
interface ContentViewProps {
  contentNode: ContentNode;
  width: Breakpoint;
}
type StyleProps = {
  width: Breakpoint;
};
export { ContentViewProps };

const useStyles = makeStyles(() => ({
  contentViewBox: {
    borderRadius: "4px",
    backgroundColor: "#1d1d1d",
    padding: ({ width }: StyleProps) =>
      isWidthUp("sm", width) ? "24px 60px" : "24px 16px",
    color: "#fff",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: ({ width }: StyleProps) =>
      isWidthUp("sm", width) ? "24px" : "32px",
  },
  title: {
    fontFamily: "Futura",
    fontSize: "21px",
    fontWeight: "bold",
    color: "#ffffff",
    textTransform: "uppercase",

    margin: 0,
  },
  subtitle: {
    fontFamily: "Helvetica",
    fontSize: "10px",
    fontWeight: "bold",
    color: "#ff4169",
    margin: "6px 0",
    textTransform: "uppercase",
  },
}));

const ContentView = ({ contentNode, width }: ContentViewProps) => {
  const { trackContentNodeViewed } = useAnalyticsContext();

  const classes = useStyles({ width });

  useEffect(() => {
    trackContentNodeViewed(contentNode);
  }, [contentNode]);

  return (
    <div className={classes.contentViewBox}>
      <header className={classes.header}>
        <div>
          <h1 className={classes.title}>{contentNode.title}</h1>
          <h2 className={classes.subtitle}>{contentNode.subtitle}</h2>
        </div>
        {contentNode && <NodeActionButtons contentNode={contentNode} />}
      </header>

      <section>
        <ContentEditor
          readOnly={true}
          initialRawContent={contentNode?.content || ""}
        />
      </section>
    </div>
  );
};

export default withWidth()(ContentView);
