import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronLeftCircleFill } from "../../../shared/icons/chevron-left-circle-fill.svg";

type Props = {
  to: string;
};

const useStyles = makeStyles(() => ({
  container: {},
  icon: {
    height: "15px",
    color: "white",
    marginRight: "20px",
  },
  link: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Futura",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    color: "#ffffff",
    textTransform: "uppercase",
    textDecoration: "none",
    width: "fit-content",
  },
  learnText: {},
}));

const LearnHeader = ({ to }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Link to={to} className={classes.link}>
        <ChevronLeftCircleFill className={classes.icon} />
        Learn!
      </Link>
    </div>
  );
};

export default LearnHeader;
