import { makeStyles } from "@material-ui/core";
import React from "react";
import API, { ResponseError } from "../shared/API";
import { useSnackContext } from "../shared/SnackProvider";
import NodeSchema, { CMSNode } from "./NodeSchema";
import ProdNodes from "./ProdNodes.json";
import { Logger } from "../shared/SafeLogger";

const useStyles = makeStyles(() => ({
  span: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));
const NodeMassCreate = () => {
  const classes = useStyles();
  const schema = NodeSchema;
  const { setSnack } = useSnackContext();

  const importProdNodes = async () =>
    await Promise.resolve(createNode(ProdNodes));

  const createNode = async (node: any, parentId?: string) => {
    let newNode = CMSNode.emptyNode();

    // update appropriate keys
    newNode.parentId = parentId;
    newNode.cardType = node.card_type;
    newNode.description = node.description;
    newNode.icon = node.icon;
    newNode.iconAssetId = node.icon_asset;
    newNode.identifier = node.identifier;
    newNode.order = node.order;
    newNode.published = node.published;
    newNode.rawContent = node.raw_content;
    newNode.slug = node.slug;
    newNode.subtitle = node.subtitle;
    newNode.title = node.title;
    newNode.type = node.type;
    newNode.isContentRoot = node.is_content_root;

    // create the record
    return API.create(newNode, schema)
      .then(async (returnedNode) => {
        setSnack({
          message: `Created ${newNode.title}`,
          source: `/${schema.rootPath}/${node.id}`,
        });

        if (node.children) {
          await Promise.all(
            node.children.map(
              async (childNode: any) =>
                await Promise.resolve(createNode(childNode, returnedNode.id))
            )
          );
        }
      })
      .catch(handleError);
  };

  const handleError = (error: ResponseError | Error) => {
    Logger.of("App").info("handleError:", error);
    let candidateResponseError = error as ResponseError;
    if (candidateResponseError.reasons !== undefined) {
      Logger.of("App").info("Caught error:", candidateResponseError);
    } else {
      setSnack({ message: error.message });
    }
  };

  return (
    <span
      className={classes.span}
      onClick={() => importProdNodes().then(() => window.location.reload())}
    >
      Import from ProdNodes.json?
    </span>
  );
};

export default NodeMassCreate;
