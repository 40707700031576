import React from 'react';
import clsx from 'clsx';
import { AtomicBlockUtils } from "draft-js";

interface Props {
  theme: any,
  getEditorState: any,
  setEditorState: any,
  addImage: any,
  modifier: any,
  blockType: string,
  editorState: any,
  onChange: any
}

const DividerButton = (props: Props) => {

  const addDivider = () => {
    const blockType = "divider"

    props.onChange(insertCustomBlock(props, blockType))
  }

  const insertCustomBlock = (props: Props, blockType: any) => {
    const contentState = props.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      blockType,
      "IMMUTABLE",
      {}
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    return AtomicBlockUtils.insertAtomicBlock(props.editorState, entityKey, " ");

  };


  const preventBubblingUp = (event: any) => { event.preventDefault(); }

  const blockTypeIsActive = () => {
    const editorState = props.getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === props.blockType;
  };

  const theme = props.theme;
  const className = blockTypeIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <React.Fragment>
      <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
        <button className={className} onClick={addDivider} type="button">
          <svg width="24px" height="10px" viewBox="0 0 18 3" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="WYSIWYG-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Menu-Buttons" transform="translate(-77.000000, -77.000000)" fill="#888888" fillRule="nonzero">
                <path d="M93.4770508,79.0170898 C94.0249023,79.0170898 94.4648438,78.5439453 94.4648438,78.0043945 C94.4648438,77.4482422 94.0332031,77 93.4770508,77 L77.987793,77 C77.4316406,77 77,77.4648438 77,78.0043945 C77,78.5356445 77.4399414,79.0170898 77.987793,79.0170898 L93.4770508,79.0170898 Z" id="hr"></path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </React.Fragment>

  );
}



export default DividerButton;
