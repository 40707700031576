import React from 'react';
import {
  Box ,
} from '@material-ui/core';
import ArtistLink, { artistLinkStateFor } from '../../models/ArtistLink';

interface Props {
  artistLink: ArtistLink
  artistLinkState: any
  artistLinkStateProps: any
}

export default function LinkState(props: Props) {
  return (
    <Box pr={2}>
      <Box
        fontSize="15px"
        fontFamily="Helvetica"
        fontWeight="bold"
        color={props.artistLinkStateProps[props.artistLinkState].color}>
        {artistLinkStateFor(props.artistLink)}
      </Box>
    </Box>
  );
}
