import React, { useContext, createContext, useState } from "react";
import Product, {
  ProductAPI,
} from "../promo-pack-builder/models/p4a_proxy/Products";
import { useErrorHandlingContext } from "./ErrorHandlingProvider";
import { usePlatoonArtistContext } from "./PlatoonArtistProvider";

type Props = {
  children: React.ReactNode;
};

type PlatoonReleaseContext = {
  release: Product | null;
  releases: Product[] | null;
  setRelease: React.Dispatch<React.SetStateAction<Product | null>>;
  setReleases: React.Dispatch<React.SetStateAction<Product[] | null>>;
  fetchReleases: (artistId: string) => Promise<any>;
  fetchRelease: (artistId: string, p4a_release_id: string) => Promise<any>;
};

export const PlatoonReleaseContext =
  createContext<PlatoonReleaseContext | null>(null);
export const usePlatoonReleaseContext = () =>
  useContext(PlatoonReleaseContext)!;

export const PlatoonReleaseProvider = ({ children }: Props) => {
  const { p4aArtist } = usePlatoonArtistContext();
  const { errorDispatch } = useErrorHandlingContext();
  const [releases, setReleases] = useState<Product[] | null>(null);
  const [release, setRelease] = useState<Product | null>(null);

  const fetchReleases = async (artistId: string) => {
    try {
      if (artistId === p4aArtist.id) {
        const data = await ProductAPI.index(artistId);
        setReleases(data.results);
      } else throw Error;
    } catch (error) {
      errorDispatch({
        type: "SET_ERROR",
        payload: { error: error, serveErrorPage: true },
      });
    }
  };

  const fetchRelease = async (artistId: string, p4a_release_id: string) => {
    try {
      if (artistId === p4aArtist.id) {
        const data = await ProductAPI.read(artistId, p4a_release_id);
        setRelease(data.results);
        return data.results;
      } else throw Error;
    } catch (error) {
      errorDispatch({
        type: "SET_ERROR",
        payload: { error: error, serveErrorPage: true },
      });
    }
  };

  return (
    <PlatoonReleaseContext.Provider
      value={{
        releases,
        setReleases,
        release,
        setRelease,
        fetchReleases,
        fetchRelease,
      }}
    >
      {children}
    </PlatoonReleaseContext.Provider>
  );
};
