import React, { useState, useEffect } from "react";
import AssetBlock, { AssetBlockProps } from "./AssetBlock";
import CMSModal from "./CMSModal";
import AssetBlockSource from "./AssetBlockSource";

interface Props {
  assetBlockProps: AssetBlockProps;
  onSave: () => void;
}

export default function AssetBlockPickerModal({
  assetBlockProps,
  onSave,
}: Props) {
  const [showSaveButton, setShowSaveButton] = useState(false);

  useEffect(() => {
    setShowSaveButton(canShowSaveButton());
  }, [assetBlockProps]);

  function interceptSetAssetBlock(assetBlock: AssetBlock | undefined) {
    assetBlockProps.setAssetBlock(assetBlock);
    let showSave = canShowSaveButton();
    setShowSaveButton(showSave);
  }

  function handleClose() {
    assetBlockProps.setAssetBlock(undefined); // this can't be null, going to try feeding it a blank one with a func like it does when it inits
  }

  function canShowSaveButton(): boolean {
    return (
      (assetBlockProps &&
        assetBlockProps.assetBlock &&
        !assetBlockProps.assetBlock.errors &&
        !assetBlockProps.assetBlock.warnings) ||
      false
    );
  }

  function saveTitle() {
    return `PICK ${assetBlockProps.assetBlock.assetType}`;
  }

  function title() {
    return `Insert ${assetBlockProps.assetBlock.assetType}`;
  }

  return (
    <CMSModal
      title={title()}
      saveTitle={saveTitle()}
      showSaveButton={showSaveButton}
      open={true}
      onSave={onSave}
      onClose={handleClose}
    >
      <AssetBlockSource
        assetBlock={assetBlockProps.assetBlock}
        setAssetBlock={interceptSetAssetBlock}
      />
    </CMSModal>
  );
}
