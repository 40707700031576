import React, { useMemo } from "react";
import {
  VariantComposer,
  PixiCompositionRenderer,
  Template,
  variantForKey,
} from "../../../asset-generator-lib";
import PromoPack from "../models/PromoPack";
import {
  Grid,
  isWidthUp,
  makeStyles,
  Typography,
  withWidth,
} from "@material-ui/core";
import { useMeasure } from "react-use";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { usePromoPackContext } from "../providers/PromoPackProvider";
import { returnLowestScale } from "../../shared/helpers/helpers";
import clone from "just-clone";

type ComposerTileProps = {
  template: Template;
  width: Breakpoint;
  selected: boolean;
  promoPack: PromoPack;
};

type StyleProps = {
  width: Breakpoint;
  selected: boolean;
  // scale: number;
};

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    margin: "auto auto",
    border: (props: StyleProps) =>
      props.selected ? "2px solid #ff4169" : "2px solid rgba(0,0,0,0)",
  },
  overlay: {
    backgroundColor: "#333333",
    backgroundImage: () =>
      `linear-gradient(rgba(0, 0, 0, 0) 75%, rgb(0, 0, 0,.75) 100%),
        url()`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: (props: StyleProps) => (props.selected ? 0 : 0.5),
    transition: ".1s ease",
    zIndex: 5,
    "&:hover": {
      opacity: 0,
    },
  },
  text: {
    position: "absolute",
    padding: "0 0 4% 5%",
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "SF Pro Text ",
    fontSize: (props: StyleProps) =>
      isWidthUp("sm", props.width) ? "17px" : "13px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: -0.41,
    color: "#ffffff",
    zIndex: 6,
  },
}));

const ComposerTile = (props: ComposerTileProps) => {
  const { dispatch, promoPack } = usePromoPackContext();
  const promoPackCopy = useMemo(() => {
    const clonedPromoPack = clone(promoPack);
    return { ...clonedPromoPack, templateConfig: {} };
  }, []);

  const classes = useStyles({ width: props.width, selected: props.selected });

  const [ref, { width }] = useMeasure();

  const updateNewPromoPack = <K extends keyof PromoPack>(
    key: K,
    template: Template
  ) => {
    if (promoPack[key] === template.id) {
      dispatch({ type: "REMOVE_TEMPLATE" });
    } else {
      dispatch({
        type: "SET_TEMPLATE",
        payload: {
          templateIdentifier: template.id,
          // clearing the templateconfig on switch for now
          templateConfig: {},
        },
      });
    }
  };

  const defaultVariant = variantForKey(
    "instagramStory",
    props.template.variants
  )!; // we know this exists, although the ! feels dirty
  const scale = returnLowestScale(defaultVariant.size, width);

  return (
    <Grid
      item
      xs={6}
      md={4}
      onClick={() => {
        updateNewPromoPack("templateIdentifier", props.template);
      }}
    >
      <div>
        <div
          id="wrapper-for-width-measuring"
          style={{
            maxHeight: isWidthUp("xs", props.width) ? "588px" : "280px",
            maxWidth: isWidthUp("sm", props.width) ? "405px" : "155px",
          }}
          ref={ref as React.LegacyRef<HTMLDivElement>}
        >
          <div
            className={classes.wrapper}
            style={{
              width: defaultVariant.size?.w * scale,
              maxHeight: defaultVariant.size?.h * scale,
            }}
          >
            <div className={classes.overlay}></div>
            <div
              id="scale"
              style={{
                transform: `scale(${scale})`,
                transformOrigin: "top left",
              }}
            >
              <VariantComposer
                template={props.template}
                variantKey={defaultVariant?.key}
                size={defaultVariant?.size}
                templateConfig={promoPackCopy.templateConfig}
                mapDataSource={promoPackCopy}
                compositionRenderer={PixiCompositionRenderer}
                freezeOnRender={true}
              />
            </div>
            <Typography variant="caption" className={classes.text}>
              {props.template.name}
            </Typography>
          </div>
        </div>
      </div>
    </Grid>
  );
};
export default withWidth()(ComposerTile);
