import { Block, Template, TemplateVariant } from "../../../asset-generator-lib";
import { ShapeType } from "../../../asset-generator-lib/composer/models/Block";

const backgroundBlock: Block = {
  id: "liquid_BGB",
  key: "liquid_BGB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 1950, h: 1950 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "background",
      name: "Design",
      type: "SELECT",
      options: [
        {
          key: "preMadeArt",
          name: "Pre Made Art",
          type: "STATIC",
          value: "/images/asset-generator/liquid/LiquidBackground.png",
        },
        {
          key: "artworkBlurred",
          name: "Artwork Blurred",
          type: "MAPPED",
          mappedPath: "release.artworkUrl",
          style: {
            filter: { blur: 100, quality: 10 },
          },
        },
        {
          key: "customBackgroundColor",
          name: "Custom Background Color",
          type: "COLOR",
          value: "#FFFFFF",
        },
      ],
    },
  ],
};

const textBackgroundBlock: Block = {
  id: "liquid_TBB",
  key: "liquid_TBB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 80 },
    size: { w: 850, h: 350 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    { key: "color", name: "Background", type: "STATIC", value: "#000001" },
  ],
};

const albumArtworkBlock: Block = {
  id: "liquid_AAB",
  key: "liquid_AAB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 850, h: 850 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "image",
      name: "Album Artwork",
      type: "MAPPED",
      mappedPath: "release.artworkUrl", // this points back to a release, which comes from syphony's api, the problem is, it's static and saved into the pack.  we should be using the asset that is created and points to this release id
    },
  ],
};

const text: Block = {
  id: "liquid_T",
  key: "liquid_T",
  type: "TEXT",
  params: [
    {
      key: "text",
      name: "Artist Name",
      type: "MAPPED",
      defaultValue: "Artist Name",
      mappedPath: "release.artistName",
    },
  ],
  frame: {
    origin: { x: 50, y: 79 },
    anchor: { x: 0.5, y: 1 },
    size: { w: 750, h: 0 },
  },
  style: {
    rotation: { angle: 0 },
    border: { borderSize: 0, borderColor: "000000" },
    font: {
      fontColor: "#ffffff",
      fontSize: "55px",
      fontFamily: "Futura",
      fontWeight: "900",
    },
  },
};

const textBox1: Block = {
  id: "liquid_TB1",
  key: "liquid_TB1",
  type: "TEXTBOX",
  frame: { origin: { x: 10, y: 25 }, anchor: { x: 0, y: 0 } },
  params: [
    {
      key: "shapeColor",
      name: "TEXT BOX COLOR",
      type: "COLOR",
      value: "#000000",
    },
    { key: "textColor", name: "TEXT COLOR", type: "COLOR", value: "#ffffff" },
    {
      key: "text",
      name: "Marketing Message",
      type: "SELECT",
      options: [
        { key: "outNow", name: "Out Now", type: "STATIC", value: "OUT NOW" },
        {
          key: "usReleaseDate",
          name: "US Release Date",
          type: "MAPPED",
          mappedPath: "release.releaseDate",
        },
        {
          key: "ukReleaseDate",
          name: "UK Release Date ",
          type: "MAPPED",
          mappedPath: "release.releaseDateUK",
        },
      ],
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      id: "23",
      type: "SHAPE",
      shapeType: ShapeType.circle,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {},
    },
  ],
  style: {
    border: { borderColor: "ffffff", borderSize: 5 },
    wordWrap: { wrap: false, wrapWidth: 0 },
    padding: { padding: 50 },
    background: { color: "000000" },
    font: {
      fontColor: "#ffffff",
      fontSize: "40px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 0 },
  },
};

const textBox2: Block = {
  id: "liquid_TB2",
  key: "liquid_TB2",
  type: "TEXTBOX",
  frame: {
    origin: { x: 50, y: 82.5 },
    size: { h: 80, w: 600 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "text",
      name: "Release Name",
      defaultValue: "Release Name",
      type: "MAPPED",
      mappedPath: "release.name",
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      id: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
    },
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "ffffff", borderSize: 5 },
    wordWrap: { wrap: false, wrapWidth: 0 },
    padding: { padding: 40 },
    font: {
      fontColor: "#ffffff",
      fontSize: "40px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 0 },
  },
};

const liquidFacebook: TemplateVariant = {
  name: "Facebook Cover",
  key: "facebookCover",
  size: { w: 820, h: 360 },
  blockOverrides: [
    {
      key: "liquid_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 900, h: 820 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "preMadeArt",
              name: "Pre Made Art",
              type: "STATIC",
              value: "/images/asset-generator/liquid/LiquidBackground.png",
            },
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 30, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "liquid_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 25, y: 50 },
        size: { w: 175, h: 175 },
        anchor: { x: 0, y: 0.5 },
      },
    },
    {
      key: "liquid_TBB",
      type: "SPRITE",
      frame: {
        origin: { x: 45, y: 50 },
        size: { w: 250, h: 175 },
        anchor: { x: 0, y: 0.5 },
      },
    },
    {
      key: "liquid_TB1",
      type: "TEXTBOX",
      frame: { origin: { x: 21.5, y: 22.5 }, anchor: { x: 0, y: 0 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 3 },
        padding: { padding: 10 },
        font: {
          fontColor: "#ffffff",
          fontSize: "12px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "liquid_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 61, y: 57.5 },
        size: { w: 150, h: 0 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 3 },
        padding: { padding: 40 },
        font: {
          fontColor: "#ffffff",
          fontSize: "25px",
          fontFamily: "Futura",
          fontWeight: "500",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "liquid_T",
      type: "TEXT",
      frame: {
        origin: { x: 61, y: 45 },
        anchor: { x: 0.5, y: 1 },
        size: { w: 175, h: 0 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        font: {
          fontColor: "#ffffff",
          fontSize: "35px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const liquidSpotify: TemplateVariant = {
  name: "Spotify Header",
  key: "spotifyHeader",
  size: { w: 2660, h: 1140 },
  blockOverrides: [
    {
      key: "liquid_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2800, h: 2800 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "preMadeArt",
              name: "Pre Made Art",
              type: "STATIC",
              value: "/images/asset-generator/liquid/LiquidBackground.png",
            },
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "liquid_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 550, h: 550 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "liquid_TBB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 750, h: 900 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "liquid_TB1",
      type: "TEXTBOX",
      frame: { origin: { x: 57.5, y: 70 }, anchor: { x: 0, y: 0 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        padding: { padding: 40 },
        font: {
          fontColor: "#ffffff",
          fontSize: "20px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "liquid_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 26.5 },
        size: { w: 400, h: 15 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        padding: { padding: 50 },
        font: {
          fontColor: "#ffffff",
          fontSize: "35px",
          fontFamily: "Futura",
          fontWeight: "500",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "liquid_T",
      type: "TEXT",
      frame: {
        origin: { x: 50, y: 22.5 },
        anchor: { x: 0.5, y: 1 },
        size: { w: 450, h: 0 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: "#ffffff",
          fontSize: "70px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const liquidTwitter: TemplateVariant = {
  name: "Twitter Header",
  key: "twitterHeader",
  size: { w: 1500, h: 500 },
  blockOverrides: [
    {
      key: "liquid_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 1550, h: 1550 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "liquid_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 25, y: 50 },
        size: { w: 275, h: 275 },
        anchor: { x: 0, y: 0.5 },
      },
    },
    {
      key: "liquid_TBB",
      type: "SPRITE",
      frame: {
        origin: { x: 60, y: 50 },
        size: { w: 550, h: 275 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "liquid_TB1",
      type: "TEXTBOX",
      frame: { origin: { x: 22.5, y: 17.5 }, anchor: { x: 0, y: 0 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        padding: { padding: 25 },
        font: {
          fontColor: "#ffffff",
          fontSize: "18px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "liquid_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 60, y: 55 },
        size: { w: 300, h: 30 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        padding: { padding: 30 },
        font: {
          fontColor: "#ffffff",
          fontSize: "25px",
          fontFamily: "Futura",
          fontWeight: "500",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "liquid_T",
      type: "TEXT",
      frame: {
        origin: { x: 60, y: 47.5 },
        anchor: { x: 0.5, y: 1 },
        size: { w: 300, h: 0 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: "#ffffff",
          fontSize: "50px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const liquidYoutube: TemplateVariant = {
  name: "Youtube Cover",
  key: "youtubeCover",
  size: { w: 2560, h: 1440 },
  blockOverrides: [
    {
      key: "liquid_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2650, h: 2650 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "preMadeArt",
              name: "Pre Made Art",
              type: "STATIC",
              value: "/images/asset-generator/liquid/LiquidBackground.png",
            },
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "liquid_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 25, y: 50 },
        size: { w: 400, h: 400 },
        anchor: { x: 0, y: 0.5 },
      },
    },
    {
      key: "liquid_TBB",
      type: "SPRITE",
      frame: {
        origin: { x: 60, y: 50 },
        size: { w: 1000, h: 400 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "liquid_TB1",
      type: "TEXT",
      frame: { origin: { x: 60, y: 55 }, anchor: { x: 0.5, y: 0 } },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        font: {
          fontColor: "#ffffff",
          fontSize: "50px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "liquid_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 60, y: 50 },
        size: { w: 750, h: 55 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        padding: { padding: 30 },
        font: {
          fontColor: "#ffffff",
          fontSize: "50px",
          fontFamily: "Futura",
          fontWeight: "500",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "liquid_T",
      type: "TEXT",
      frame: {
        origin: { x: 60, y: 45 },
        anchor: { x: 0.5, y: 1 },
        size: { w: 800, h: 0 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: "#ffffff",
          fontSize: "80px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const liquidInstagram = {
  name: "Instagram Story",
  key: "instagramStory",
  size: { w: 1080, h: 1920 },
} as TemplateVariant;

const Liquid = {
  id: "liquid",
  name: "Liquid",
  blocks: [
    backgroundBlock,
    textBackgroundBlock,
    albumArtworkBlock,
    textBox1,
    textBox2,
    text,
  ],
  meta: { version: "1.0", basis: { w: 100, h: 100 } },
  variants: [
    liquidInstagram,
    liquidFacebook,
    liquidSpotify,
    liquidTwitter,
    liquidYoutube,
  ],
} as Template;

export default Liquid;
