import React from "react";
import { MultiContainerProps } from "../../MultiInput/MultiInput";
import MultiTitle from "../MultiTitle/MultiTitle";

const MultiRelease = ({
  sectionKey,
  param,
  setParam,
  editFn,
  deleteFn,
  selectedChildValue,
  index,
}: MultiContainerProps) => {
  return (
    <MultiTitle
      index={index}
      sectionKey={sectionKey}
      param={param}
      setParam={setParam}
      editFn={editFn}
      deleteFn={deleteFn}
      selectedChildValue={selectedChildValue}
    />
  );
};

export default MultiRelease;
