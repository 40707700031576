import React, { ReactChild, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./Overlay.module.scss";
import { ReactComponent as Close } from "../../../shared/icons/close.svg";

type Props = {
  children?: ReactChild;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  backgroundColor?: string;
  closeIcon?: boolean;
};

const Overlay = ({ setOpen, children, backgroundColor, closeIcon }: Props) => {
  useEffect(() => {
    document.body.classList.add("overlay-open");

    return () => {
      document.body.classList.remove("overlay-open");
    };
  }, []);

  const bgColor = backgroundColor || "rgba(0, 0, 0, 0.8)";

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setOpen && setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  return ReactDOM.createPortal(
    <div
      className={styles["overlay"]}
      style={{ backgroundColor: bgColor }}
      onClick={(e) => {
        e.stopPropagation();
        setOpen && setOpen(false);
      }}
    >
      <div
        className="relative flex-center"
        onClick={(e) => e.stopPropagation()}
      >
        {closeIcon && (
          <button
            className={`btn--naked ${styles["icon"]}`}
            onClick={() => setOpen && setOpen(false)}
          >
            <Close />
          </button>
        )}
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Overlay;
