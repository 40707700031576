import React, { useEffect } from "react";
import { ConvertedBlock } from "../../../../asset-generator-lib/composer/models/Block";
import { useTemplatePageEditorContext } from "../TemplatePageEditor/TemplatePageEditor";
import { Artist } from "../../types/TemplatePages";
import RenderedCarouselVideos from "../TemplatePageRenderedComponents/RenderedVideosCarousel/RenderedVideosCarousel";
import RenderedBiography from "../TemplatePageRenderedComponents/RenderedBiography/RenderedBiography";
import RenderedPhotos from "../TemplatePageRenderedComponents/RenderedPhotos/RenderedPhotos";
import RenderedHeader from "../TemplatePageRenderedComponents/RenderedHeader/RenderedHeader";
import RenderedLinkTitle from "../TemplatePageRenderedComponents/RenderedLinkTitle/RenderedLinkTitle";
import RenderedVideos from "../TemplatePageRenderedComponents/RenderedVideos/RenderedVideos";
import UnknownSection from "../TemplatePageRenderedComponents/UnknownSection/UnknownSection";
import RenderedContacts from "../TemplatePageRenderedComponents/RenderedContacts/RenderedContacts";
import RenderedTourDates from "../TemplatePageRenderedComponents/RenderedTourDates/RenderedTourDates";
import ScrollAnimation from "../../../shared/components/ScrollAnimation/ScrollAnimation";
import RenderedTimeline from "../TemplatePageRenderedComponents/RenderedTimeline/RenderedTimeline";
import RenderedHeader_V2 from "../TemplatePageRenderedComponents/RenderedHeader_V2/RenderedHeader_V2";
import RenderedMusic from "../TemplatePageRenderedComponents/RenderedMusic/RenderedMusic";
import RenderedMusicPlayer from "../TemplatePageRenderedComponents/RenderedMusicPlayer/RenderedMusicPlayer";
import RenderedMetaData from "../TemplatePageRenderedComponents/RenderedMetadata/RenderedMetadata";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
  assembleArtist: Artist;
  editable?: boolean;
};

type RenderedComponentProps = {
  convertedBlock: ConvertedBlock;
  assembleArtist: Artist;
  highlight: boolean;
};

type RenderedComponent = ({
  convertedBlock,
  assembleArtist,
  highlight,
}: RenderedComponentProps) => JSX.Element;

type ComponentList = { [key: string]: RenderedComponent };

const componentList: ComponentList = {
  BIOGRAPHY: RenderedBiography,
  HEADER: RenderedHeader,
  HEADER_V2: RenderedHeader_V2,
  PRESSRELEASES: RenderedLinkTitle,
  PRESSCLIPPINGS: RenderedLinkTitle,
  VIDEOS: RenderedVideos,
  VIDEOS_V2: RenderedCarouselVideos,
  PHOTOSANDARTWORK: RenderedPhotos,
  CONTACTS: RenderedContacts,
  TOURDATES: RenderedTourDates,
  PERFORMANCES: RenderedCarouselVideos,
  TIMELINE: RenderedTimeline,
  MUSIC: RenderedMusic,
  MUSICPLAYER: RenderedMusicPlayer,
  METADATA: RenderedMetaData,
};

export const getRenderedComponent = (
  convertedBlock: ConvertedBlock,
  highlight: boolean,
  assembleArtist: Artist
) => {
  // i've been getting random errors if it's just inferred as a RenderedComponent. I think this fixes
  const TagName: RenderedComponent | RenderedComponentProps =
    componentList[convertedBlock.key.toUpperCase()] || UnknownSection;
  return (
    <TagName
      convertedBlock={convertedBlock}
      highlight={highlight}
      assembleArtist={assembleArtist} // might not need this- it's just being passed through
    />
  );
};

const TemplatePageComponentRenderer = ({
  convertedBlock,
  highlight,
  editable,
  assembleArtist,
}: Props) => {
  const component = getRenderedComponent(
    convertedBlock,
    highlight,
    assembleArtist
  );
  const { highlightedKey } = useTemplatePageEditorContext();
  const highlightRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Only handle scrolling if needed: convertedBlock supports it; we have a highlighted key that matches this key.
    if (
      highlightRef &&
      highlightRef.current &&
      highlightedKey === convertedBlock.key
    ) {
      highlightRef.current.scrollIntoView();
    }
  }, [highlightedKey, highlightRef]);

  if (!editable && convertedBlock.scrollAnimation) {
    return (
      <ScrollAnimation animation={convertedBlock.scrollAnimation}>
        <div id={convertedBlock.key} ref={highlightRef}>
          {component}
        </div>
      </ScrollAnimation>
    );
  } else {
    return (
      <div id={convertedBlock.key} ref={highlightRef}>
        {component}
      </div>
    );
  }
};

export default TemplatePageComponentRenderer;
