import React, { useRef, useState } from "react";
import AssetBlock, { AssetFrame, AssetPickerSource } from "../asset/AssetBlock";
import { HoverActions } from "../../PlatoonContext";
import ReactPlayer from "react-player";
import Hover from "../../Hover";
import FramedAsset from "../asset/FramedAsset";

import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

import { makeStyles } from "@material-ui/core";
import Loading from "../../../../shared/Loading";

type StyleProps = {
  isLocal: boolean;
};

const useStyles = makeStyles(() => ({
  playIcon: {
    fontSize: "75px",
    position: "absolute",
    top: "calc(50% - 37.5px)",
    zIndex: 1,
    color: "#fff",
  },
  aspectRatio: {
    width: "100%",
    height: ({ isLocal }: StyleProps) => (!isLocal ? 0 : ""),
    paddingTop: ({ isLocal }: StyleProps) => (!isLocal ? "56.25%" : ""),
    maxHeight: ({ isLocal }: StyleProps) => (isLocal ? "450px" : undefined),
    position: "relative",
  },
  playerContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

interface Props {
  assetBlock: AssetBlock;
  hoverActions: HoverActions;
  styles: any;
}

const VideoBlockRenderer = ({ assetBlock, hoverActions, styles }: Props) => {
  const reactPlayer = useRef<any>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLocal, setIsLocal] = useState(false);
  const classes = useStyles({ isLocal });

  const assetLoaded = () => {
    setLoading(false);
    if (reactPlayer?.current?.getInternalPlayer().playerInfo === undefined) {
      setIsLocal(true);
    } else {
      setIsLocal(false);
    }
  };
  const togglePlaying = () => {
    setIsPlaying(!isPlaying);
  };

  const url =
    assetBlock.assetPickerSource === AssetPickerSource.url
      ? assetBlock?.asset?.attachment
      : `/api/v1/signed-assets/${assetBlock.asset?.id}`;

  return (
    <Hover actions={hoverActions}>
      {assetBlock.frame === AssetFrame.none || !assetBlock.frame ? (
        <div className={styles.assetCentered}>
          <div style={{ display: loading ? "block" : "none" }}>
            <Loading height="100px" />
          </div>
          <div
            onClick={togglePlaying}
            className={`${styles.assetCentered} ${classes.aspectRatio}`}
            style={{
              display: loading ? "none" : "",
            }}
          >
            {!isLocal ? (
              <div className={classes.playerContainer}>
                {!isPlaying && isLocal && (
                  <PlayCircleFilledIcon
                    className={classes.playIcon}
                    onClick={togglePlaying}
                  />
                )}

                <ReactPlayer
                  className={styles.assetFrame}
                  ref={reactPlayer}
                  url={url}
                  onReady={assetLoaded}
                  onEnded={togglePlaying}
                  playing={isPlaying}
                  height="100%"
                  width="100%"
                  controls={true}
                />
              </div>
            ) : (
              <>
                {!isPlaying && isLocal && (
                  <PlayCircleFilledIcon
                    className={classes.playIcon}
                    onClick={togglePlaying}
                  />
                )}

                <ReactPlayer
                  className={styles.assetFrame}
                  ref={reactPlayer}
                  url={url}
                  onReady={assetLoaded}
                  onEnded={togglePlaying}
                  playing={isPlaying}
                  height="100%"
                  width="100%"
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <FramedAsset assetBlock={assetBlock} />
      )}
    </Hover>
  );
};

export default VideoBlockRenderer;
