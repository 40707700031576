import {
  Button,
  Grid,
  isWidthDown,
  makeStyles,
  Typography,
  withWidth,
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React, { useState } from "react";
import IndexCard from "../../shared/indexCards/IndexCard";
import MenuOptions from "../../shared/indexCards/MenuOptions";
import PromoPack from "../models/PromoPack";
import { zipAssets } from "../PromoPackCreator/Download";
import TemplateManager from "../templates/TemplateManager";

type Props = {
  data?: PromoPack;
  newlyDupedLink?: string;
  width: Breakpoint;
  deletePromoPack?: (promoPack: PromoPack) => void;
  executeDuplicate?: (promoPack: PromoPack) => void;
};

type StyleProps = {
  width: Breakpoint;
};

const useStyles = makeStyles(() => ({
  cover: {
    width: "125px",
    height: "125px",
    marginRight: "22px",
  },
  container: {
    paddingLeft: "12px !important",
    overflow: "hidden",
  },
  thumbnailContainer: {
    width: ({ width }: StyleProps) =>
      isWidthDown("sm", width) ? "120px" : "140px",
    height: ({ width }: StyleProps) =>
      isWidthDown("sm", width) ? "120px" : "140px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thumbnail: {
    maxWidth: ({ width }: StyleProps) =>
      isWidthDown("sm", width) ? "120px" : "140px",
    maxHeight: ({ width }: StyleProps) =>
      isWidthDown("sm", width) ? "120px" : "140px",
  },
  heading: {
    fontFamily: "Helvetica",
    fontSize: "15px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    color: "#ffffff",
    textTransform: "uppercase",
    marginBottom: "5px",
    padding: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  details: {
    fontFamily: "Helvetica",
    fontSize: "12px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    lineHeight: 1.25,
    color: "#ffffff",
    marginBottom: "5px",
    textTransform: "capitalize",
  },
  date: {
    fontFamily: "Helvetica",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    lineHeight: 1.25,
    color: "#b4b4b4",
  },
  actionsRow: {
    display: "flex",
    alignItems: "center",
  },
  downloadColumn: {
    flex: 1,
    display: "flex",
    justifyContent: ({ width }: StyleProps) =>
      isWidthDown("md", width) ? "flex-start" : "flex-end",
  },
  menuColumn: {
    marginLeft: "22px",
  },
  gridContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: ({ width }: StyleProps) =>
      isWidthDown("sm", width) ? "120px 1fr" : "140px 1fr",
  },
  missingTemplate: {
    color: "red",
  },
  errorMessage: {
    textTransform: "none",
    display: "block",
  },
}));

const PromoPackIndexCard = ({
  data,
  newlyDupedLink,
  width,
  deletePromoPack = () => console.log("deletePromoPackFunc missing from props"),
}: // executeDuplicate
Props) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles({ width });
  if (!data || !deletePromoPack) return <div></div>;

  return (
    // if the template is not availabe, remove the to
    <IndexCard
      to={
        TemplateManager.getTemplateById(data?.templateIdentifier)
          ? `${data?.id}/download`
          : undefined
      }
      newlyDuped={newlyDupedLink}
      slug={"slug"}
    >
      <div className={classes.gridContainer}>
        <div className={classes.thumbnailContainer}>
          <img className={classes.thumbnail} src={data?.thumbnail} />
        </div>

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          className={classes.container}
        >
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <Typography className={classes.heading}>{data?.name}</Typography>

            <Typography className={classes.details}>
              {data?.release?.name} &#183;&nbsp;
              {TemplateManager.getTemplateById(data?.templateIdentifier) ? (
                <>{data?.templateIdentifier}</>
              ) : (
                <span className={classes.missingTemplate}>
                  Template unavailable ({data?.templateIdentifier})
                  <span className={classes.errorMessage}>
                    You can download your assets, but not customize this pack.
                  </span>
                </span>
              )}
            </Typography>

            <Typography className={classes.date}>
              {data?.createdAt?.toLocaleDateString(undefined, {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <div className={classes.actionsRow}>
              <div className={classes.downloadColumn}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={(event) => {
                    event.stopPropagation();
                    zipAssets(setLoading, data);
                  }}
                  disabled={loading}
                >
                  {loading ? "Downloading" : "Download (.zip)"}
                </Button>
              </div>
              <div className={classes.menuColumn}>
                <MenuOptions
                  options={[
                    {
                      text: "Delete",
                      textColor: "#d50442",
                      action: () => deletePromoPack(data),
                    },
                  ]}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </IndexCard>
  );
};

export default withWidth()(PromoPackIndexCard);
