import React, { useEffect, useState } from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import styles from "./RenderedPhotos.module.scss";
import SlideCarousel from "../../../../shared/components/SlideCarousel/SlideCarousel";
import { ReactComponent as Zoom } from "../../../../shared/icons/plus-magnifyingglass.svg";
import { ReactComponent as Download } from "../../../../shared/icons/square-and-arrow-down.svg";
import Overlay from "../../../../shared/components/Overlay/Overlay";
import Loading from "../../../../shared/Loading";
import { AssetType } from "../../../../platoon-cms-lib";
import { LegacyFileUpload } from "../../inputs/FileUploadComponents/FileUpload/FileUpload";
import { ArtistAsset } from "../../inputs/FileInput/FileInput";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

// this is a conditional type that handles our old file upload type and new
export type Image =
  | {
      id?: string | undefined;
      description?: string | undefined;
      attachment: string;
      thumbnail: string;
      type: AssetType;
      asset?: never;
    }
  | {
      id: string;
      status: "PENDING" | "INPROGRESS" | "COMPLETED";
      selectedFile: File;
      asset: ArtistAsset | null;
      attachment?: never;
      description?: never;
      thumbnail?: never;
      type?: never;
    };

const RenderedPhotos = ({ convertedBlock, highlight }: Props) => {
  const [zoomed, setZoomed] = useState<string | undefined>(undefined);
  const [photos, setPhotos] = useState(convertedBlock.params.images?.value);

  useEffect(() => {
    convertedBlock.params.images?.value &&
      setPhotos(convertedBlock.params.images?.value);
  }, [convertedBlock]);

  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  // image (this should be refactored into its own component)
  const images = photos?.map((file: LegacyFileUpload, index: number) => {
    const asset = file?.asset || file;

    const id = asset.id || asset._id || index;

    return (
      <div
        key={id}
        className={styles["slide"]}
        style={{
          backgroundImage: `url('/api/v1/signed-assets/${id}/medium')`,
        }}
      >
        {asset ? (
          <div className={styles["overlay"]}>
            <button
              className={"btn--naked"}
              onClick={() => setZoomed(`/api/v1/signed-assets/${id}`)}
            >
              <Zoom className={styles["icon"]} />
              High-Res
            </button>
            <a
              className={`btn--naked ${styles["download-link"]}`}
              href={`/api/v1/signed-assets/${id}?disposition=attachment`}
              download={`${asset.description}`}
            >
              <Download className={`${styles["icon"]} ${styles["download"]}`} />
              Download
            </a>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  });

  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      {images?.length > 0 && (
        <>
          <SlideCarousel>{images}</SlideCarousel>
          {zoomed && (
            <Overlay setOpen={() => setZoomed(undefined)} closeIcon={true}>
              <div className={styles["zoomed"]}>
                <img className={styles["img"]} src={zoomed} />
              </div>
            </Overlay>
          )}
        </>
      )}
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedPhotos;
