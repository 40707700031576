import React from "react";
import FileInput from "../FileInput/FileInput";

const MultiImageUpload = (props: any) => {
  return (
    <FileInput {...props} accept={"image/png, image/jpeg"} multiFile={true} />
  );
};

export default MultiImageUpload;
