import React, { useState, useEffect } from "react";
import Release from "../../../../promo-pack-builder/PromoPackCreator/Release";
import styles from "./ReleasePickerInput.module.scss";
import CreateFromScratch from "../../../../shared/components/CreateFromScratch/CreateFromScratch";
import { ProductAPI } from "../../../../promo-pack-builder/models/p4a_proxy/Products";
import { usePlatoonArtistContext } from "../../../../providers/PlatoonArtistProvider";
import {
  uploadAlbumArtwork,
  uploadTracks,
} from "../../../../shared/helpers/assets";
import { constructValuePath } from "../../../../shared/helpers/helpers";
import { resolveMappedPath } from "../../../../../asset-generator-lib/helpers";
import Overlay from "../../../../shared/components/Overlay/Overlay";
import Loading from "../../../../shared/Loading";
import useWindowSize from "../../../../shared/CustomHooks";
import {
  BlockParam,
  TemplatePageBlockParam,
} from "../../../../../asset-generator-lib";

type Dispatch = {
  type: string;
  payload: { p4aReleaseId: string };
};

type Props = {
  param: BlockParam;
  update: (input: TemplatePageBlockParam | string) => void;
  preTemplateConfigSectionValue: any;
  path: string[];
  releaseFilter?: { key: string; value: string[] };
  customRelease?: boolean;
};

const ReleasePickerInput = ({
  param,
  update,
  preTemplateConfigSectionValue,
  path,
  releaseFilter,
  customRelease = true,
}: Props) => {
  const { width } = useWindowSize();
  const desktopView = width > 1048; //same as scss $tablet-breakpoint

  const [loading, setLoading] = useState(false);

  const initValue =
    resolveMappedPath(
      constructValuePath(path.slice(1)) + ".raw.id",
      preTemplateConfigSectionValue
    ) ||
    param?.value?.raw?.id ||
    null;

  const [selectedP4aReleaseId, setSelectedP4aReleaseId] = useState(
    initValue || ""
  );

  const {
    p4aArtist: { id: p4aAristId },
    assembleArtist,
  } = usePlatoonArtistContext();

  const uploadAssetsAndUpdateValue = async (
    p4aAristId: string,
    assmebleArtistId: string
  ) => {
    setLoading(true);
    ProductAPI.read(p4aAristId, selectedP4aReleaseId).then(async (res) => {
      // we need to save all of the data + have things ready for the file inputs
      // which means adding keys / re-orging some data in the formats we need
      // mapped path would be something like modal.value.page1Grid.value.releasePicker.value.audioAssets
      // TODO: we need to see if they have uploaded the tracks already to our system
      // if not, upload ours
      const release = res.results;
      //can't use originalArtwork url because it has token which breaks
      //transcoding
      const tracks = release.assets.map((asset: any, index: number) => {
        if (asset.metadata) {
          return {
            id: asset.id,
            albumName: release.name ? release.name : "",
            albumArtwork: release.artworkUrl ? release.artworkUrl : "", // todo
            name: asset.title ? asset.title : `Track ${index + 1}`,
            tempSrc: asset.metadata.url ? asset.metadata.url : "",
            lyrics: asset.lyrics ? asset.lyrics : "",
            artistName: asset.artistName ? asset.artistName : "",
            genre: release.genre?.name ? release.genre.name : "",
          };
        } else
          return {
            name: "NO AUDIO UPLOADED",
            tempSrc: "NONE",
          };
      });

      const uploadedTracks = await uploadTracks(tracks, assmebleArtistId);

      const uploadedArtwork = await uploadAlbumArtwork(
        res.results.originalArtworkUrl,
        res.results.name,
        assmebleArtistId,
        res.results.id
      );

      // set validation to true / loading done
      setLoading(false);

      // send off the update
      param &&
        update({
          ...param,
          value: {
            raw: res.results, // the full obj from symphony
            ...(uploadedTracks && { tracks: uploadedTracks }), // the tracks in our system
            ...(uploadedArtwork && { artwork: [uploadedArtwork] }), // the artwork asset in our system
          },
        });
    });
  };

  useEffect(() => {
    // upload tracks and artwork, then update the value
    // make sure we have all the pieces needed, and that we havent already fetched and temporarily stored this
    // TODO: have a cache, incase they click one, then another, and back to one they had already fetched

    if (
      assembleArtist?.id &&
      p4aAristId &&
      selectedP4aReleaseId !== "" &&
      selectedP4aReleaseId !==
        resolveMappedPath(
          constructValuePath(path.slice(1)) + ".raw.id",
          preTemplateConfigSectionValue
        )
    ) {
      // make sure we havent already done this
      if (selectedP4aReleaseId !== param.value?.raw?.id)
        uploadAssetsAndUpdateValue(p4aAristId, assembleArtist.id);
    } else if (selectedP4aReleaseId === "") {
      // just send the update with nothing
      update({
        ...param,
        value: null,
      });
    }
  }, [selectedP4aReleaseId]);

  // set to empty string
  const handleFromScratchClick = () => {
    setSelectedP4aReleaseId("");
  };

  const setSelectedId = ({ payload }: Dispatch) => {
    setSelectedP4aReleaseId(payload.p4aReleaseId);
  };

  return (
    <div className={styles["container"]}>
      <Release
        imgSize={desktopView ? "220px" : "1fr"}
        releaseFilter={releaseFilter}
        customRelease={
          customRelease && (
            <CreateFromScratch
              onClick={handleFromScratchClick}
              selected={selectedP4aReleaseId === ""}
            />
          )
        }
        selectedP4aReleaseId={selectedP4aReleaseId}
        dispatch={setSelectedId}
      />
      {loading && (
        <Overlay>
          <Loading />
        </Overlay>
      )}
    </div>
  );
};

export default ReleasePickerInput;
