import { Block, Template, TemplateVariant } from "../../../asset-generator-lib";
import { ShapeType } from "../../../asset-generator-lib/composer/models/Block";

const backgroundBlock: Block = {
  id: "grain_BGB",
  key: "grain_BGB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 75 },
    size: { w: 3000, h: 3000 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "background",
      name: "Design",
      type: "SELECT",
      options: [
        {
          key: "preMadeArt",
          name: "Pre Made Art",
          type: "STATIC",
          value: "/images/asset-generator/grain/GrainBackground.png",
        },
        {
          key: "artworkBlurred",
          name: "Artwork Blurred",
          type: "MAPPED",
          mappedPath: "release.artworkUrl",
          style: {
            filter: { blur: 60 },
          },
        },
        {
          key: "customBackgroundColor",
          name: "Custom Background Color",
          type: "COLOR",
          value: "#FFFFFF",
        },
      ],
    },
  ],
};

const albumArtworkBlock: Block = {
  id: "grain_AAB",
  key: "grain_AAB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 700, h: 700 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "image",
      name: "Album Artwork",
      type: "MAPPED",
      mappedPath: "release.artworkUrl",
    },
  ],
};

const textBox1: Block = {
  id: "grain_TB1",
  key: "grain_TB1",
  type: "TEXTBOX",
  frame: {
    origin: { x: 10, y: 50 },
    anchor: { x: 0.5, y: 0.5 },
    size: { h: 40, w: 500 },
  },
  params: [
    { key: "shapeColor", name: "Shape Color", type: "COLOR", value: "#000000" },
    { key: "textColor", name: "Text Color", type: "COLOR", value: "#ffffff" },
    {
      key: "text",
      name: "Artist Name",
      defaultValue: "Artist Name",
      type: "MAPPED",
      mappedPath: "release.artistName",
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {},
    },
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "ffffff", borderSize: 0 },
    padding: { padding: 40 },
    font: {
      fontColor: "#ffffff",
      fontSize: "40px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 270 },
  },
};

const textBox2: Block = {
  id: "grain_TB2",
  key: "grain_TB2",
  type: "TEXTBOX",
  frame: {
    origin: { x: 90, y: 50 },
    anchor: { x: 0.5, y: 0.5 },
    size: { h: 40, w: 500 },
  },
  params: [
    {
      key: "text",
      name: "Artist Name",
      defaultValue: "Artist Name",
      type: "MAPPED",
      mappedPath: "release.artistName",
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {},
    },
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "ffffff", borderSize: 0 },
    padding: { padding: 40 },
    font: {
      fontColor: "#ffffff",
      fontSize: "40px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 270 },
  },
};

const textBox3: Block = {
  id: "grain_TB3",
  key: "grain_TB3",
  type: "TEXTBOX",
  frame: {
    origin: { x: 50, y: 72.5 },
    anchor: { x: 0.5, y: 0.5 },
    size: { h: 40, w: 600 },
  },
  params: [
    {
      key: "text",
      name: "Marketing Message",
      type: "SELECT",
      options: [
        {
          key: "releaseDate",
          name: "Release Date",
          type: "MAPPED",
          mappedPath: "release.releaseDate",
        },
        { key: "outNow", name: "OUT NOW", type: "STATIC", value: "OUT NOW" },
      ],
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {},
    },
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "ffffff", borderSize: 0 },
    wordWrap: { wrap: true, wrapWidth: 850 },
    padding: { padding: 40 },
    font: {
      fontColor: "#ffffff",
      fontSize: "40px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 0 },
  },
};

const textBox4: Block = {
  id: "grain_TB4",
  key: "grain_TB4",
  type: "TEXTBOX",
  frame: {
    origin: { x: 50, y: 27.5 },
    anchor: { x: 0.5, y: 0.5 },
    size: { h: 40, w: 600 },
  },
  params: [
    {
      key: "text",
      name: "Release Name",
      defaultValue: "Release Name",
      type: "MAPPED",
      mappedPath: "release.name",
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {},
    },
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "ffffff", borderSize: 0 },
    padding: { padding: 40 },
    font: {
      fontColor: "#ffffff",
      fontSize: "40px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 0 },
  },
};

const grainFacebook: TemplateVariant = {
  name: "Facebook Cover",
  key: "facebookCover",
  size: { w: 820, h: 360 },
  blockOverrides: [
    {
      key: "grain_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 75 },
        size: { w: 1500, h: 1500 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "grain_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 200, h: 200 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "grain_TB1",
      type: "TEXTBOX",
      frame: {
        origin: { x: 32.5, y: 50 },
        size: { w: 125, h: 18 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "18px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 270 },
      },
    },
    {
      key: "grain_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 67.5, y: 50 },
        size: { w: 125, h: 18 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "18px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 270 },
      },
    },
    {
      key: "grain_TB3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 90 },
        size: { w: 125, h: 18 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "18px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "grain_TB4",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 10 },
        size: { w: 125, h: 18 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "18px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const grainSpotify: TemplateVariant = {
  name: "Spotify Header",
  key: "spotifyHeader",
  size: { w: 2660, h: 1140 },
  blockOverrides: [
    {
      key: "grain_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 75 },
        size: { w: 4250, h: 3500 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "grain_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 575, h: 575 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "grain_TB1",
      type: "TEXTBOX",
      frame: {
        origin: { x: 35, y: 50 },
        size: { w: 400, h: 40 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 270 },
      },
    },
    {
      key: "grain_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 65, y: 50 },
        size: { w: 400, h: 40 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 270 },
      },
    },
    {
      key: "grain_TB3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 82.5 },
        size: { w: 400, h: 40 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "grain_TB4",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 17.5 },
        size: { w: 400, h: 40 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const grainTwitter: TemplateVariant = {
  name: "Twitter Header",
  key: "twitterHeader",
  size: { w: 1500, h: 500 },
  blockOverrides: [
    {
      key: "grain_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 75 },
        size: { w: 2500, h: 2500 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "grain_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 250, h: 250 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "grain_TB1",
      type: "TEXTBOX",
      frame: {
        origin: { x: 39, y: 50 },
        size: { w: 150, h: 20 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "20px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 270 },
      },
    },
    {
      key: "grain_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 61, y: 50 },
        size: { w: 150, h: 20 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "20px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 270 },
      },
    },
    {
      key: "grain_TB3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 82.5 },
        size: { w: 150, h: 20 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "20px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "grain_TB4",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 17.5 },
        size: { w: 150, h: 20 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "20px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const grainYoutube: TemplateVariant = {
  name: "Youtube Cover",
  key: "youtubeCover",
  size: { w: 2560, h: 1440 },
  blockOverrides: [
    {
      key: "grain_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 75 },
        size: { w: 4000, h: 3500 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "grain_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 575, h: 575 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "grain_TB1",
      type: "TEXTBOX",
      frame: {
        origin: { x: 35, y: 50 },
        size: { w: 400, h: 40 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 270 },
      },
    },
    {
      key: "grain_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 65, y: 50 },
        size: { w: 400, h: 40 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 270 },
      },
    },
    {
      key: "grain_TB3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 77.5 },
        size: { w: 400, h: 40 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "grain_TB4",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 22.5 },
        size: { w: 400, h: 40 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 20 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const grainInstagram = {
  name: "Instagram Story",
  key: "instagramStory",
  size: { w: 1080, h: 1920 },
} as TemplateVariant;

const Grain = {
  id: "grain",
  name: "Grain",
  blocks: [
    backgroundBlock,
    albumArtworkBlock,
    textBox1,
    textBox2,
    textBox3,
    textBox4,
  ],
  meta: { version: "1.0", basis: { w: 100, h: 100 } },
  variants: [
    grainInstagram,
    grainFacebook,
    grainSpotify,
    grainTwitter,
    grainYoutube,
  ],
} as Template;

export default Grain;
