import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../../../shared/CustomHooks";
import Loading from "../../../../../shared/Loading";

import styles from "./MultiPillDetails.module.scss";

type Props = {
  multiFile?: boolean;
  image?: string | undefined;
  text: string | undefined;
  errored?: boolean;
  editablePillText?: (text: string) => void;
};

const FileDetails = ({
  multiFile,
  image,
  text,
  errored,
  editablePillText,
}: Props) => {
  const [value, setValue] = useState(text || "");
  const [imageLoaded, setImageLoaded] = useState(false);

  const debouncedValue: string = useDebounce(value, 1000);

  useEffect(() => {
    //Make the callback only if the debouncedValue has changed
    debouncedValue !== text &&
      editablePillText &&
      editablePillText(debouncedValue);
  }, [debouncedValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setValue(e.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      //@ts-ignore
      event.target.blur();
    }
  };

  return (
    <div className={styles["preview-box"]}>
      {editablePillText ? (
        <input
          className={styles["text"]}
          value={value.removeExt()}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
      ) : (
        <span
          className={`${styles["text"]} ellipsis ${errored && styles["error"]}`}
        >
          {!multiFile && image && (
            <>
              <img
                style={{ display: imageLoaded ? "block" : "none" }}
                onLoad={() => setImageLoaded(true)}
                src={image}
              />
              {!imageLoaded && (
                <div style={{ marginRight: "10px" }}>
                  <Loading size="40px" />
                </div>
              )}
            </>
          )}
          {text?.removeExt()}
          {errored && " has errored during upload."}
        </span>
      )}
    </div>
  );
};

export default FileDetails;
