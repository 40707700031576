import React, { useEffect } from "react";
import TemplateManager from "../templates/TemplateManager";
import { Grid } from "@material-ui/core";
import { usePromoPackContext } from "../providers/PromoPackProvider";
import ActionBar from "../../shared/ActionBar";
import { useNavigate } from "react-router-dom";
import ComposerTile from "./ComposerTile";
import Template from "../../../asset-generator-lib/composer/models/Template";

export default function Template() {
  const { promoPack, savingMode } = usePromoPackContext();
  const navigate = useNavigate();

  useEffect(() => {
    // check for required data, and redirect back as needed
    if (Object.keys(promoPack).length > 2 && !promoPack?.p4aReleaseId)
      navigate("../release");
  }, [promoPack]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {(savingMode === "CREATE" ||
            (savingMode === "EDIT" && promoPack?.id)) &&
            TemplateManager.masterTemplates.map(
              (template: Template, index: number) => (
                <ComposerTile
                  selected={
                    promoPack?.templateIdentifier === template.id ? true : false
                  }
                  key={index}
                  template={template}
                  promoPack={promoPack!}
                />
              )
            )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ActionBar
          position="bottom"
          buttons={[
            {
              text: "Next",
              group: "right",
              action: () => navigate("../customize"),
              buttonProps: {
                variant: "contained",
                color: "secondary",
                disabled: !promoPack?.templateIdentifier,
              },
            },
            {
              text: "Cancel",
              group: "left",
              action: () => navigate("./../.."),
              buttonProps: {
                variant: "outlined",
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
