import { Block, Template, TemplateVariant } from "../../../asset-generator-lib";
import { ShapeType } from "../../../asset-generator-lib/composer/models/Block";

const backgroundBlock: Block = {
  id: "space_BGB",
  key: "space_BGB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 1950, h: 1950 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "background",
      name: "Design",
      type: "SELECT",
      options: [
        {
          key: "preMadeArt",
          name: "Pre Made Art",
          type: "STATIC",
          value: "/images/asset-generator/space/SpaceMaskedBackground.png",
        },
        {
          key: "arworkBlurred",
          name: "Artwork Blurred",
          type: "MAPPED",
          mappedPath: "release.artworkUrl",
          style: {
            filter: { blur: 100, quality: 10 },
          },
        },
        {
          key: "customBackgroundColor",
          name: "Custom Background Color",
          type: "COLOR",
          value: "#FFFFFF",
        },
      ],
    },
  ],
};

const albumArtworkBlock: Block = {
  id: "space_AAB",
  key: "space_AAB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 850, h: 850 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "image",
      name: "Album Artwork",
      type: "MAPPED",
      mappedPath: "release.artworkUrl",
    },
  ],
};

const text: Block = {
  id: "space_T",
  key: "space_T",
  type: "TEXT",
  params: [
    {
      key: "text",
      name: "Artist Name",
      type: "MAPPED",
      defaultValue: "Artist Name",
      mappedPath: "release.artistName",
    },
  ],
  frame: {
    origin: { x: 50, y: 80 },
    anchor: { x: 0.5, y: 1 },
    size: { w: 750, h: 0 },
  },
  style: {
    wordWrap: { wrap: false, wrapWidth: 0 },
    rotation: { angle: 0 },
    border: { borderSize: 0, borderColor: "000000" },
    font: {
      fontColor: "#ffffff",
      fontSize: "55px",
      fontFamily: "Futura",
      fontWeight: "900",
    },
  },
};

const textBox1: Block = {
  id: "space_TB1",
  key: "space_TB1",
  type: "TEXTBOX",
  frame: { origin: { x: 10, y: 27.5 }, anchor: { x: 0, y: 0 } },
  params: [
    {
      key: "shapeColor",
      name: "TEXT BOX COLOR",
      type: "COLOR",
      value: "#000000",
    },
    { key: "textColor", name: "TEXT COLOR", type: "COLOR", value: "#ffffff" },
    {
      key: "text",
      name: "Marketing Message",
      type: "SELECT",
      options: [
        { key: "outNow", name: "Out Now", type: "STATIC", value: "OUT NOW" },
        {
          key: "usReleaseDate",
          name: "US Release Date",
          type: "MAPPED",
          mappedPath: "release.releaseDate",
        },
        {
          key: "ukReleaseDate",
          name: "UK Release Date ",
          type: "MAPPED",
          mappedPath: "release.releaseDateUK",
        },
      ],
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      id: "23",
      type: "SHAPE",
      shapeType: ShapeType.circle,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {},
    },
  ],
  style: {
    background: { color: "#000000" },
    border: { borderColor: "ffffff", borderSize: 5 },
    wordWrap: { wrap: false, wrapWidth: 0 },
    padding: { padding: 50 },
    font: {
      fontColor: "#ffffff",
      fontSize: "40px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 0 },
  },
};

const textBox2: Block = {
  id: "space_TB2",
  key: "space_TB2",
  type: "TEXTBOX",
  frame: {
    origin: { x: 50, y: 82.5 },
    size: { h: 50, w: 850 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "text",
      name: "Release Name",
      defaultValue: "Release Name",
      type: "MAPPED",
      mappedPath: "release.name",
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      id: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
    },
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "ffffff", borderSize: 5 },
    wordWrap: { wrap: false, wrapWidth: 0 },
    padding: { padding: 40 },
    font: {
      fontColor: "#ffffff",
      fontSize: "40px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 0 },
  },
};

const spaceFacebook: TemplateVariant = {
  name: "Facebook Cover",
  key: "facebookCover",
  size: { w: 820, h: 360 },
  blockOverrides: [
    {
      key: "space_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 900, h: 900 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "preMadeArt",
              name: "Pre Made Art",
              type: "STATIC",
              value: "/images/asset-generator/space/SpaceMaskedBackground.png",
            },
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 30, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "space_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 70, y: 50 },
        size: { w: 200, h: 200 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "space_TB1",
      type: "TEXT",
      frame: { origin: { x: 30, y: 77.5 }, anchor: { x: 0.5, y: 1 } },
      style: {
        border: { borderColor: "000000", borderSize: 0 },
        font: {
          fontColor: "#ffffff",
          fontSize: "45px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "space_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 30, y: 50 },
        size: { h: 0, w: 200 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        padding: { padding: 40 },
        font: {
          fontColor: "#ffffff",
          fontSize: "25px",
          fontFamily: "Futura",
          fontWeight: "500",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "space_T",
      type: "TEXT",
      frame: {
        origin: { x: 30, y: 37.5 },
        anchor: { x: 0.5, y: 1 },
        size: { h: 0, w: 250 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        font: {
          fontColor: "#ffffff",
          fontSize: "35px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const spaceSpotify: TemplateVariant = {
  name: "Spotify Header",
  key: "spotifyHeader",
  size: { w: 2660, h: 1140 },
  blockOverrides: [
    {
      key: "space_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2800, h: 2800 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "preMadeArt",
              name: "Pre Made Art",
              type: "STATIC",
              value: "/images/asset-generator/space/SpaceMaskedBackground.png",
            },
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "space_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 62.5, y: 50 },
        size: { w: 425, h: 425 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "space_TB1",
      type: "TEXT",
      frame: { origin: { x: 37.5, y: 65 }, anchor: { x: 0.5, y: 1 } },
      style: {
        border: { borderColor: "000000", borderSize: 0 },
        font: {
          fontColor: "#ffffff",
          fontSize: "65px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "space_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 37.5, y: 50 },
        size: { w: 400, h: 30 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        padding: { padding: 50 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "500",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "space_T",
      type: "TEXT",
      frame: {
        origin: { x: 37.5, y: 42.5 },
        anchor: { x: 0.5, y: 1 },
        size: { w: 500, h: 0 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: "#ffffff",
          fontSize: "70px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const spaceTwitter: TemplateVariant = {
  name: "Twitter Header",
  key: "twitterHeader",
  size: { w: 1500, h: 500 },
  blockOverrides: [
    {
      key: "space_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 1550, h: 1550 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "space_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 65, y: 50 },
        size: { w: 250, h: 250 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "space_TB1",
      type: "TEXT",
      frame: { origin: { x: 40, y: 71.5 }, anchor: { x: 0.5, y: 1 } },
      style: {
        border: { borderColor: "000000", borderSize: 0 },
        font: {
          fontColor: "#ffffff",
          fontSize: "45px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "space_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 40, y: 50 },
        size: { w: 200, h: 20 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 50 },
        font: {
          fontColor: "#ffffff",
          fontSize: "25px",
          fontFamily: "Futura",
          fontWeight: "500",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "space_T",
      type: "TEXT",
      frame: {
        origin: { x: 40, y: 40 },
        anchor: { x: 0.5, y: 1 },
        size: { w: 350, h: 0 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: "#ffffff",
          fontSize: "35px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const spaceYoutube: TemplateVariant = {
  name: "Youtube Cover",
  key: "youtubeCover",
  size: { w: 2560, h: 1440 },
  blockOverrides: [
    {
      key: "space_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2650, h: 2650 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "preMadeArt",
              name: "Pre Made Art",
              type: "STATIC",
              value: "/images/asset-generator/space/SpaceMaskedBackground.png",
            },
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "space_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 62.5, y: 50 },
        size: { w: 425, h: 425 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "space_TB1",
      type: "TEXT",
      frame: { origin: { x: 37.5, y: 65 }, anchor: { x: 0.5, y: 1 } },
      style: {
        border: { borderColor: "000000", borderSize: 0 },
        font: {
          fontColor: "#ffffff",
          fontSize: "90px",
          fontFamily: "Futura",
          fontWeight: "900",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "space_TB2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 37.5, y: 50 },
        size: { w: 400, h: 70 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 5 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 50 },
        font: {
          fontColor: "#ffffff",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "500",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "space_T",
      type: "TEXT",
      frame: {
        origin: { x: 37.5, y: 42.5 },
        anchor: { x: 0.5, y: 1 },
        size: { w: 500, h: 0 },
      },
      style: {
        border: { borderColor: "ffffff", borderSize: 0 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 40 },
        font: {
          fontColor: "#ffffff",
          fontSize: "70px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const spaceInstagram = {
  name: "Instagram Story",
  key: "instagramStory",
  size: { w: 1080, h: 1920 },
} as TemplateVariant;

const Space = {
  id: "space",
  name: "Space",
  blocks: [backgroundBlock, albumArtworkBlock, textBox1, textBox2, text],
  meta: { version: "1.0", basis: { w: 100, h: 100 } },
  variants: [
    spaceInstagram,
    spaceFacebook,
    spaceSpotify,
    spaceTwitter,
    spaceYoutube,
  ],
} as Template;

export default Space;
