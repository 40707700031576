import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import "../platoon-cms-lib/fonts/luminari.css";
import "../platoon-cms-lib/fonts/andale_mono.css";
import "../platoon-cms-lib/fonts/futura.css";
import "../platoon-cms-lib/fonts/oswald.css";
import "../platoon-cms-lib/fonts/lato.css";

import { PlatoonArtistProvider } from "../providers/PlatoonArtistProvider";
import { AnalyticsProvider, AppType } from "../providers/AnalyticsProvider";
import { LoadingProvider } from "../providers/LoadingProvider";
import Layout from "../shared/Layout";
import ArtistRedirect from "../link-builder/ArtistRedirect";
import TemplatePageIndex from "./components/TemplatePageIndex/TemplatePageIndex";
import TemplatePageEditor from "./components/TemplatePageEditor/TemplatePageEditor";
import ValidationsProvider from "../providers/ValidationsProvider";
import { PlatoonReleaseProvider } from "../providers/PlatoonReleaseProvider";
import ErrorHandlingProvider from "../providers/ErrorHandlingProvider";
import SnackProvider from "../shared/SnackProvider";

const basePath = "/apps/promote/template-page-builder";

const TemplatePageBuilderApp = () => {
  return (
    <SnackProvider>
      <LoadingProvider>
        <ErrorHandlingProvider redirectPath={basePath}>
          <PlatoonArtistProvider>
            <PlatoonReleaseProvider>
              <AnalyticsProvider app={AppType.templatePageBuilder}>
                <Routes>
                  <Route
                    index
                    element={
                      <ArtistRedirect basePath={basePath} subPath="pages" />
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <ArtistRedirect basePath={basePath} subPath="pages" />
                    }
                  />
                  <Route path={`artists`} element={<Outlet />}>
                    <Route path="*" element={<div>404</div>} />
                    <Route path={`:artistId/pages`}>
                      <Route
                        index
                        element={
                          <Layout>
                            <TemplatePageIndex />
                          </Layout>
                        }
                      />
                      <Route
                        path={`:templateSlug/:templatePageId/edit`}
                        element={
                          <ValidationsProvider>
                            <TemplatePageEditor />
                          </ValidationsProvider>
                        }
                      />
                      <Route
                        path={`:templateSlug/:templatePageId/preview`}
                        element={<div>preview</div>}
                      />
                    </Route>
                  </Route>
                </Routes>
              </AnalyticsProvider>
            </PlatoonReleaseProvider>
          </PlatoonArtistProvider>
        </ErrorHandlingProvider>
      </LoadingProvider>
    </SnackProvider>
  );
};
export default TemplatePageBuilderApp;
