import React, { useEffect, useState } from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import TemplatePageFormRenderer from "../../TemplatePageFormRenderer/TemplatePageFormRenderer";
import { GenericObj, InputProps } from "../helpers";

const initValues = (param: BlockParam) => {
  const newValues: GenericObj<BlockParam> = {};
  param.options?.forEach((item) => {
    newValues[item.key] = { ...item };
  });
  return { ...newValues };
};
const GridInput = ({
  param,
  update,
  sectionKey,
  templatePageDraft,
  preTemplateConfigSectionValue,
  path,
}: InputProps) => {
  const [value, setValue] = useState<GenericObj<BlockParam>>(
    param.value || initValues(param)
  );

  // we have to set local value, then send that to the update
  useEffect(() => {
    if (Object.keys(value).length < 1) {
      initValues(param);
    } else {
      update({ ...param, value: value });
    }
  }, [value]);

  // we don't know what kind of data we are getting, so using ANY as long as the parent update func is also any
  const wrapUpdateWithParamKey = (updatedData: any) => {
    setValue((prevValue) => {
      return { ...prevValue, [updatedData.key]: updatedData };
    });
  };

  return (
    <TemplatePageFormRenderer
      blockParams={Object.values(value)}
      update={wrapUpdateWithParamKey}
      disabled={false}
      templatePageDraft={templatePageDraft}
      sectionKey={sectionKey}
      parentLayout={param.layout}
      preTemplateConfigSectionValue={preTemplateConfigSectionValue}
      path={path}
    />
  );
};

export default GridInput;
