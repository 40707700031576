import React, { useEffect } from "react";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import TrackListItem from "./TrackListItem";
import { Track } from "../models/ArtistLink";
import {
  useAnalyticsContext,
  ArtistLinkAction,
} from "../../providers/AnalyticsProvider";

interface Props {
  trackIndex: number;
  setTrackIndex: React.Dispatch<React.SetStateAction<number>>;
  playStatus: boolean;
  setPlayStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTrackList: React.Dispatch<React.SetStateAction<boolean>>;
  fontColor: string;
}

export default function TrackList(props: Props) {
  const { trackArtistLink } = useAnalyticsContext();
  const { artistLink } = useArtistLinkContext();

  useEffect(() => {
    if (artistLink.id) {
      trackArtistLink(artistLink, ArtistLinkAction.tracklistViewed);
    }
  }, [artistLink.id]);

  return (
    <div>
      {artistLink.tracks &&
        artistLink.tracks.map((track: Track, index: number) => {
          return (
            <TrackListItem
              setOpenTrackList={props.setOpenTrackList}
              playStatus={props.playStatus}
              setPlayStatus={props.setPlayStatus}
              track={track}
              trackIndex={props.trackIndex}
              setTrackIndex={props.setTrackIndex}
              fontColor={props.fontColor}
              index={index}
              key={index}
            />
          );
        })}
    </div>
  );
}
