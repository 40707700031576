import React, { useEffect, useRef, useState } from "react";
import { BlockParam } from "../../../../../asset-generator-lib";
import { MultiBlockParamPreData } from "../../../../../asset-generator-lib/composer/models/Block";
import { resolveMappedPath } from "../../../../../asset-generator-lib/helpers";
import Overlay from "../../../../shared/components/Overlay/Overlay";
import Header from "../../../../shared/Header";
import TemplatePageFormRenderer from "../../TemplatePageFormRenderer/TemplatePageFormRenderer";
import styles from "./ModalInput.module.scss";
import { GenericObj, InputProps } from "../helpers";

import AddChangeButton from "../AddChangeButton/AddChangeButton";
import { useValidationsContext } from "../../../../providers/ValidationsProvider";
import clone from "just-clone";
import ConfirmDelete from "../../ConfirmDelete/ConfirmDelete";
import useWindowSize from "../../../../shared/CustomHooks";
import ModalInputDesktopActionBar from "./ModalInputDesktopActionBar/ModalInputDesktopActionBar";
import ModalInputMobileActionBar from "./ModalInputMobileActionBar/ModalInputMobileActionBar";

export type ModalInputActionBarProps = {
  handlePrevious: () => void;
  handleCancel: () => void;
  handleSave: () => void;
  handleNext: () => void;
  showPrevious: boolean;
  showSave: boolean;
  leftCancel?: boolean;
};

const initValues = (param: BlockParam) => {
  const newValues: GenericObj<BlockParam> = {};
  param.options?.forEach((item) => {
    newValues[item.key] = { ...item };
  });
  return { ...newValues };
};

type OverrideInputProps = InputProps & {
  param: MultiBlockParamPreData;
};

const ModalInput = ({
  param,
  update,
  sectionKey,
  templatePageDraft,
  path,
}: OverrideInputProps) => {
  const { width } = useWindowSize();
  const desktopView = width > 1048; //same as scss $tablet-breakpoint
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const modalContainerRef = useRef<any>(null);

  const [value, setValue] = useState<GenericObj<BlockParam>>(
    param.value || initValues(param)
  );

  useEffect(() => {
    if (modalContainerRef.current) {
      modalContainerRef.current.scroll(0, 0);
    }
  }, [currentPage]);

  const { validateAtKey, validations, validationsDispatch } =
    useValidationsContext();

  const validatePage = async (currentPage: number) => {
    // can probably replace obj here with a reducer
    const obj: any = {};
    const clonedValue = clone(value);

    // grab the current values, and filter out any that dont have validations
    const currentPageValue = Object.values(clonedValue)[currentPage].value;
    currentPageValue &&
      Object.values(currentPageValue)
        .filter((item: any) => item.validations)
        .forEach((param: any) => {
          const key = param.key;
          const val = param.value;
          obj[key] = val;
        });

    // validate these keys
    const valid = await validateAtKey(obj, sectionKey, Object.keys(obj));

    // if it returns true, fire off the callback

    return valid;
  };

  const handleCancel = () => {
    // we are removing validation entries when they hit cancel
    // we dont save their data into the template config until user hits save on last page and validations pass
    // so no need to keep validation errors in our bigger object, that could hold up publishing
    let removedValidation = { ...validations };
    delete removedValidation[sectionKey];
    validationsDispatch({
      type: "REMOVE_VALIDATION",
      payload: removedValidation,
    });
    setCurrentPage(0);
    setOpen(false);
  };
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNext = async () => {
    const isValid = await validatePage(currentPage);
    if (isValid) setCurrentPage(currentPage + 1);
  };

  // we have to set local value, then send that to the update
  const handleSave = async () => {
    const isValid = await validatePage(currentPage);
    if (isValid) {
      update({ ...param, value: value });
      setCurrentPage(0);
      setOpen(false);
    }
  };

  // when we get back several at the same time, we are losing values
  //  ex: loading page 2, several inputs get a resolved init value, which gets sent back here.
  // how do we queue those up?
  const wrapUpdateWithParamKey = (updatedData: any) => {
    setValue((prevValue) => {
      return { ...prevValue, [updatedData.key]: updatedData };
    });
  };

  const verb = param.value ? "EDIT " : "CREATE ";

  const displayTitle =
    param.value && resolveMappedPath(param.descriptionPath, param.value);

  const confirmedDelete = () => {
    // send an update that sets value to null (we show the description if there's a value)
    update({ ...param, value: null });

    // re-initialize the value
    setValue(initValues(param));
    setCurrentPage(0);
  };

  const actionBarProps: ModalInputActionBarProps = {
    handlePrevious: handlePrevious,
    handleCancel: handleCancel,
    handleSave: handleSave,
    handleNext: handleNext,
    showPrevious: currentPage > 0,
    showSave: currentPage === Object.keys(value).length - 1,
  };

  const allPages = Object.values(value).map((page, index) => (
    <div
      key={page.key}
      id={`Page ${index}`}
      style={{
        // keeping these inputs initted allows them to keep their state, and receive updates from sectionPaths
        display: index === currentPage ? "block" : "none",
      }}
    >
      <TemplatePageFormRenderer
        blockParams={[page]}
        update={wrapUpdateWithParamKey}
        disabled={false}
        templatePageDraft={templatePageDraft}
        sectionKey={sectionKey}
        parentLayout={param.layout}
        // we need to pass in the values from all pages
        // so we can make them available for sectionPath values
        // ^^ not sure if this is outdated?  I think it can be removed
        preTemplateConfigSectionValue={{
          modal: { ...param, value: value },
        }}
        path={path}
      />
    </div>
  ));

  return (
    <>
      {param.value && param.descriptionPath && (
        <div className={styles["description"]}>
          <span className={styles["text"]}> {displayTitle}</span>{" "}
          {/*needs ellipsis */}
          <ConfirmDelete callback={confirmedDelete} />
        </div>
      )}

      {/* form  button */}
      <AddChangeButton
        onClick={() => setOpen(!open)}
        paramName={param.overrideName || param.name} // we need to turn off the verb
        state={param.value ? "CHANGE" : "ADD"}
        buttonTextStates={param.buttonTextStates}
        noVerb={!!param.overrideName} // if they are overriding the name, we are disabling the verb on add/change
      />

      {/* modal */}
      {open && param.options && (
        <Overlay setOpen={setOpen}>
          <div className={styles["modal"]}>
            <Header
              text={param.overrideName || verb + param.name}
              padding={0}
            />
            <div ref={modalContainerRef} className={styles["container"]}>
              {/* what if we render all the inputs for the modal, and show/hide pages from view so they stay initiated */}

              {param?.options && allPages}
            </div>

            {desktopView ? (
              <ModalInputDesktopActionBar {...actionBarProps} />
            ) : (
              <ModalInputMobileActionBar {...actionBarProps} />
            )}
          </div>
        </Overlay>
      )}
    </>
  );
};

export default ModalInput;
