import Asset, { AssetType } from "../models/Asset";

interface AssetService {
  uploadAsset(formData: FormData): Promise<Asset>;
  getAssetsForQuery(query: AssetQuery): Promise<AssetQueryResult>;
}

interface AssetQuery {
  type: AssetType;
  page?: number;
}

interface AssetQueryResult {
  results: Asset[];
  pagination: any;
}

export { AssetService, AssetQuery, AssetQueryResult };

export default class AssetManager {
  private static _service: AssetService;

  private constructor() {}

  static useService(service: AssetService) {
    AssetManager._service = service;
  }

  static currentService(): AssetService {
    if (!AssetManager._service) {
      // Throw a runtime error to let the developer know this is not ok
      throw new Error(
        "AssetManager has no current service. Call useService() before trying to get the current service."
      );
    }
    return AssetManager._service;
  }

  static async uploadAsset(formData: FormData): Promise<Asset> {
    return AssetManager.currentService().uploadAsset(formData);
  }

  static async getAssetsForQuery(query: AssetQuery): Promise<AssetQueryResult> {
    return AssetManager.currentService().getAssetsForQuery(query);
  }
}
