import React, { useEffect, useRef } from "react";
import ReactAudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import PlayButton from "../../platoon-cms-lib/components/Buttons.tsx/PlayButton";
import PauseButton from "../../platoon-cms-lib/components/Buttons.tsx/PauseButton";
import ForwardButton from "../../platoon-cms-lib/components/Buttons.tsx/ForwardButton";
import BackwardButton from "../../platoon-cms-lib/components/Buttons.tsx/BackwardButton";
import { Box, Grid, makeStyles, Theme } from "@material-ui/core";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import {
  useAnalyticsContext,
  ArtistLinkAction,
} from "../../providers/AnalyticsProvider";

type Props = {
  handleClickPrevious: () => void;
  handleClickNext: () => void;
  trackIndex: number;
  setTrackIndex: React.Dispatch<React.SetStateAction<number>>;
  playerOpacity: string;
  playerColor: string;
  playStatus: boolean;
  setPlayStatus: React.Dispatch<React.SetStateAction<boolean>>;
};

type StyleProps = {
  playerOpacity: string;
  playerColor: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  audioPlayerContainer: {
    height: "100%",
    justify: "center",
    alignItems: "center",
    backgroundColor: ({ playerOpacity }: StyleProps) =>
      `${theme.palette.primary.light}${playerOpacity}`,
  },
  audioPlayer: {
    "&.rhap_container": {
      backgroundColor: "inherit",
      boxShadow: "none",
      color: "#fff !important",
      maxWidth: "625px",
      padding: 0,
      margin: "auto",
      "&:focus": {
        outline: 0,
      },
    },
    "& .rhap_controls-section": {
      marginTop: "5px",
    },
    "& .rhap_current-time": {
      left: theme.spacing(4),
      minWidth: "35px",
    },
    "& .rhap_time": {
      color: ({ playerColor }: StyleProps) => playerColor || "#868686",
      fontSize: "12px",
      position: "relative",
      bottom: "35px",
      right: theme.spacing(4),
      minWidth: "35px",
      marginTop: "35px",
    },
    "& .rhap_skip-button": {
      "&:focus": {
        outline: 0,
      },
    },
    "& .rhap_play-pause-button": {
      marginRight: "35px",
      marginLeft: "35px",
      "&:focus": {
        outline: 0,
      },
    },
    "& .rhap_main-controls": {
      display: "contents",
      position: "relative",
      top: "15px",
    },
    "& .rhap_progress-filled": {
      backgroundColor: ({ playerColor }: StyleProps) => playerColor,
    },
    "& .rhap_progress-indicator": {
      backgroundColor: ({ playerColor }: StyleProps) => playerColor,
      height: "11px",
      width: "11px",
      top: "-3px",
      marginLeft: "-2px",
    },
    "& .rhap_progress-container": {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      height: "10px",
      margin: "unset",
      "&:focus": {
        outline: 0,
      },
    },
  },
}));
export default function ArtistMusicPlayerSection(props: Props) {
  const { artistLink } = useArtistLinkContext();
  const { trackArtistLink } = useAnalyticsContext();

  const playerColor = props.playerColor;
  const playerOpacity = props.playerOpacity;
  const classes = useStyles({ playerColor, playerOpacity });

  const audioPlayer = useRef<any>(null);
  const audioPlayerRef = audioPlayer.current;

  const currentTrack = () => {
    return artistLink.tracks && artistLink.tracks[props.trackIndex];
  };

  const currentTrackSrc =
    currentTrack()?.derivatives?.mp3Kbps192 ||
    currentTrack()?.derivatives?.original ||
    currentTrack()?.attachment;

  useEffect(() => {
    const curTrack = currentTrack();
    if (audioPlayerRef && props.playStatus) {
      if (artistLink.id && curTrack) {
        trackArtistLink(artistLink, ArtistLinkAction.songStarted, curTrack);
      }
      audioPlayerRef.audio.current.play();
    } else if (audioPlayerRef && !props.playStatus) {
      audioPlayerRef.audio.current.pause();
    }
  }, [props.playStatus, props.trackIndex]);

  useEffect(() => {
    const curTrack = currentTrack();

    let isCurrent = true;
    if (props.playStatus && isCurrent && curTrack) {
      let playTimer = setTimeout(
        () =>
          trackArtistLink(artistLink, ArtistLinkAction.songPlayed, curTrack),
        30000
      );

      return () => {
        clearTimeout(playTimer);
        isCurrent = false;
      };
    } else {
      return () => {
        isCurrent = false;
      };
    }
  }, [props.playStatus]);

  useEffect(() => {
    if (props.trackIndex === -1 && artistLink.tracks) {
      props.setPlayStatus(false);
      props.setTrackIndex(0);
    }
  }, [artistLink.tracks?.length]);

  return (
    <Box height={100}>
      <Grid className={classes.audioPlayerContainer} container>
        <Grid item xs={12}>
          <Box height={1}>
            <ReactAudioPlayer
              defaultCurrentTime="00:00"
              defaultDuration="00:00"
              preload="metadata"
              ref={audioPlayer}
              onPlay={() => props.setPlayStatus(true)}
              onPause={() => props.setPlayStatus(false)}
              onEnded={props.handleClickNext}
              className={classes.audioPlayer}
              showSkipControls={true}
              showJumpControls={false}
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              onClickPrevious={props.handleClickPrevious}
              onClickNext={props.handleClickNext}
              src={currentTrackSrc}
              showDownloadProgress={false}
              customIcons={{
                play: <PlayButton color={playerColor} />,
                pause: <PauseButton color={playerColor} />,
                next: <ForwardButton color={playerColor} />,
                previous: <BackwardButton color={playerColor} />,
              }}
              customAdditionalControls={[RHAP_UI.CURRENT_TIME]}
              customVolumeControls={[RHAP_UI.DURATION]}
              customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
              customControlsSection={[
                RHAP_UI.ADDITIONAL_CONTROLS,
                RHAP_UI.MAIN_CONTROLS,
                RHAP_UI.VOLUME_CONTROLS,
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
