import { Block, Template, TemplateVariant } from "../../../asset-generator-lib";
import { ShapeType } from "../../../asset-generator-lib/composer/models/Block";

const backgroundBlock: Block = {
  id: "architecture_BGB",
  key: "architecture_BGB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 50 },
    size: { w: 1950, h: 1950 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "background",
      name: "Design",
      type: "SELECT",
      options: [
        {
          key: "arworkBlurred",
          name: "Artwork Blurred",
          type: "MAPPED",
          mappedPath: "release.artworkUrl",
          style: {
            filter: { blur: 100, quality: 10 },
          },
        },
        {
          key: "customBackgroundColor",
          name: "Custom Background Color",
          type: "COLOR",
          value: "#FFFFFF",
        },
      ],
    },
  ],
};

const albumArtworkBlock: Block = {
  id: "architecture_AAB",
  key: "architecture_AAB",
  type: "SPRITE",
  frame: {
    origin: { x: 50, y: 40 },
    size: { w: 850, h: 850 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "image",
      name: "Album Artwork",
      type: "MAPPED",
      mappedPath: "release.artworkUrl",
    },
  ],
};

const text1: Block = {
  id: "architecture_T1",
  key: "architecture_T1",
  type: "TEXTBOX",
  params: [
    {
      key: "shapeColor",
      name: "Text Box Color",
      type: "COLOR",
      value: "#FFFFFF",
    },
    { key: "textColor", name: "Text Color", type: "COLOR", value: "#000000" },
    {
      key: "text",
      name: "Artist Name",
      type: "MAPPED",
      defaultValue: "Artist Name",
      mappedPath: "release.artistName",
    },
  ],
  frame: {
    origin: { x: 30.325, y: 75 },
    size: { h: 120, w: 340 },
    anchor: { x: 0.5, y: 0.5 },
  },
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
      style: {},
    },
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "000000", borderSize: 15 },
    padding: { padding: 70 },
    background: { color: "#ffffff" },
    font: {
      fontColor: "000000",
      fontSize: "50px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 0 },
  },
};

const text2: Block = {
  id: "architecture_T2",
  key: "architecture_T2",
  type: "TEXTBOX",
  frame: {
    origin: { x: 69.1, y: 75 },
    size: { h: 150, w: 382.5 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "text",
      name: "Marketing Message",
      type: "SELECT",
      options: [
        { key: "outNow", name: "Out Now", type: "STATIC", value: "OUT NOW" },
        {
          key: "usReleaseDate",
          name: "US Release Date",
          type: "MAPPED",
          mappedPath: "release.releaseDate",
        },
        {
          key: "ukReleaseDate",
          name: "UK Release Date ",
          type: "MAPPED",
          mappedPath: "release.releaseDateUK",
        },
      ],
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      id: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
    },
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "000000", borderSize: 15 },
    padding: { padding: 40 },
    background: { color: "#ffffff" },
    font: {
      fontColor: "000000",
      fontSize: "50px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 0 },
  },
};

const text3: Block = {
  id: "architecture_T3",
  key: "architecture_T3",
  type: "TEXTBOX",
  frame: {
    origin: { x: 50, y: 66 },
    size: { h: 100, w: 775 },
    anchor: { x: 0.5, y: 0.5 },
  },
  params: [
    {
      key: "text",
      name: "Release Name",
      defaultValue: "Release Name",
      type: "MAPPED",
      mappedPath: "release.name",
    },
  ],
  children: [
    {
      key: "34",
      id: "34",
      type: "TEXT",
      frame: { origin: { x: 50, y: 50 }, anchor: { x: 0.5, y: 0.5 } },
    },
    {
      key: "23",
      id: "23",
      type: "SHAPE",
      shapeType: ShapeType.rect,
      frame: { origin: { x: 50, y: 50 }, size: { w: 0, h: 0 } },
    },
  ],
  style: {
    fixedWidth: true,
    border: { borderColor: "000000", borderSize: 15 },
    padding: { padding: 60 },
    background: { color: "#ffffff" },
    font: {
      fontColor: "000000",
      fontSize: "60px",
      fontFamily: "Futura",
      fontWeight: "600",
    },
    rotation: { angle: 0 },
  },
};

const architectureFacebook: TemplateVariant = {
  name: "Facebook Cover",
  key: "facebookCover",
  size: { w: 820, h: 360 },
  blockOverrides: [
    {
      key: "architecture_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 900, h: 900 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 30, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "architecture_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 250, h: 250 },
        anchor: { x: 1, y: 0.5 },
      },
    },
    {
      key: "architecture_T1",
      type: "TEXTBOX",
      frame: {
        origin: { x: 57.8, y: 26.5 },
        size: { w: 103.75, h: 54.25 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 7.5 },
        padding: { padding: 20 },
        font: {
          fontColor: "000000",
          fontSize: "35px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "architecture_T2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 73, y: 26.5 },
        size: { w: 87, h: 34.25 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 7.5 },
        padding: { padding: 40 },
        font: {
          fontColor: "000000",
          fontSize: "35px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "architecture_T3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 65.5, y: 60.25 },
        size: { w: 200, h: 119.5 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 7.5 },
        padding: { padding: 50 },
        font: {
          fontColor: "000000",
          fontSize: "50px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const architectureSpotify: TemplateVariant = {
  name: "Spotify Header",
  key: "spotifyHeader",
  size: { w: 2660, h: 1140 },
  blockOverrides: [
    {
      key: "architecture_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2800, h: 2800 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "architecture_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 550, h: 550 },
        anchor: { x: 1, y: 0.5 },
      },
    },
    {
      key: "architecture_T1",
      type: "TEXTBOX",
      frame: {
        origin: { x: 55.6, y: 30.8 },
        size: { w: 235, h: 50 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 12 },
        padding: { padding: 50 },
        font: {
          fontColor: "000000",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "architecture_T2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 65.9, y: 30.8 },
        size: { w: 230, h: 50 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 12 },
        padding: { padding: 50 },
        font: {
          fontColor: "000000",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "architecture_T3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 60.7, y: 54.25 },
        size: { w: 497.5, h: 381.75 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 12 },
        padding: { padding: 60 },
        font: {
          fontColor: "000000",
          fontSize: "60px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const architectureTwitter: TemplateVariant = {
  name: "Twitter Header",
  key: "twitterHeader",
  size: { w: 1500, h: 500 },
  blockOverrides: [
    {
      key: "architecture_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 1550, h: 1550 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "architecture_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 40, y: 50 },
        size: { w: 300, h: 300 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "architecture_T1",
      type: "TEXTBOX",
      frame: {
        origin: { x: 55.05, y: 29 },
        size: { w: 110, h: 48.5 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 7.5 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 35 },
        font: {
          fontColor: "000000",
          fontSize: "45px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "architecture_T2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 64.9, y: 29 },
        size: { w: 112.5, h: 48.5 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 7.5 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 35 },
        font: {
          fontColor: "000000",
          fontSize: "45px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "architecture_T3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 60.05, y: 58 },
        size: { w: 259, h: 177.5 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 7.5 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 35 },
        font: {
          fontColor: "000000",
          fontSize: "65px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const architectureYoutube: TemplateVariant = {
  name: "Youtube Cover",
  key: "youtubeCover",
  size: { w: 2560, h: 1440 },
  blockOverrides: [
    {
      key: "architecture_BGB",
      type: "SPRITE",
      frame: {
        origin: { x: 50, y: 50 },
        size: { w: 2650, h: 2650 },
        anchor: { x: 0.5, y: 0.5 },
      },
      params: [
        {
          key: "background",
          name: "Design",
          type: "SELECT",
          options: [
            {
              key: "artworkBlurred",
              name: "Artwork Blurred",
              type: "MAPPED",
              mappedPath: "release.artworkUrl",
              style: {
                filter: { blur: 80, quality: 10 },
              },
            },
            {
              key: "customBackgroundColor",
              name: "Custom Background Color",
              type: "COLOR",
              value: "#FFFFFF",
            },
          ],
        },
      ],
    },
    {
      key: "architecture_AAB",
      type: "SPRITE",
      frame: {
        origin: { x: 33.25, y: 50 },
        size: { w: 400, h: 400 },
        anchor: { x: 0.5, y: 0.5 },
      },
    },
    {
      key: "architecture_T1",
      type: "TEXTBOX",
      frame: {
        origin: { x: 50, y: 40 },
        size: { w: 400, h: 50 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 12 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 50 },
        font: {
          fontColor: "000000",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "500",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "architecture_T2",
      type: "TEXTBOX",
      frame: {
        origin: { x: 67, y: 40 },
        size: { w: 400, h: 50 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 12 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 50 },
        font: {
          fontColor: "000000",
          fontSize: "40px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
    {
      key: "architecture_T3",
      type: "TEXTBOX",
      frame: {
        origin: { x: 58.5, y: 53.375 },
        size: { w: 825, h: 230 },
        anchor: { x: 0.5, y: 0.5 },
      },
      style: {
        fixedWidth: true,
        border: { borderColor: "000000", borderSize: 12 },
        wordWrap: { wrap: false, wrapWidth: 0 },
        padding: { padding: 60 },
        font: {
          fontColor: "000000",
          fontSize: "45px",
          fontFamily: "Futura",
          fontWeight: "600",
        },
        rotation: { angle: 0 },
      },
    },
  ],
};

const architectureInstagram = {
  name: "Instagram Story",
  key: "instagramStory",
  size: { w: 1080, h: 1920 },
} as TemplateVariant;

const Architecture = {
  id: "architecture",
  name: "Architecture",
  blocks: [backgroundBlock, albumArtworkBlock, text1, text2, text3],
  meta: { version: "1.0", basis: { w: 100, h: 100 } },
  variants: [
    architectureInstagram,
    architectureFacebook,
    architectureSpotify,
    architectureTwitter,
    architectureYoutube,
  ],
} as Template;

export default Architecture;
