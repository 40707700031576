import React, { useEffect } from "react";
import { Pagination } from "../types/SharedTypes";
import styles from "./Pagination.module.scss";

type Props = {
  onChange: (newPage: number) => void;
  pagination: Pagination;
};

const Pagination = ({
  pagination: { perPage, currentPage, totalPages, totalEntries },
  onChange,
}: Props) => {
  const startRecord = currentPage * perPage - (perPage - 1);
  const endRecord =
    currentPage === totalPages ? totalEntries : currentPage * perPage;

  useEffect(() => {
    if (currentPage > totalPages) {
      onChange(totalPages);
    }
  }, [totalPages]);

  return (
    <div className={styles.pagination}>
      <button
        disabled={currentPage === 1}
        onClick={() => onChange(currentPage - 1)}
      >
        &lt; prev
      </button>
      <p>{`${startRecord} - ${endRecord} of ${totalEntries} `}</p>
      <button
        disabled={currentPage === totalPages}
        onClick={() => onChange(currentPage + 1)}
      >
        next &gt;
      </button>
    </div>
  );
};

export default Pagination;
