import React from "react";
import {
  BlockParam,
  BlockParamMulti,
  ConvertedBlock,
} from "../../../../../asset-generator-lib/composer/models/Block";
import { JSONParseSafely } from "../../../../shared/helpers/helpers";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import styles from "./RenderedTimeline.module.scss";
type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

type Timeline = {
  name: BlockParam<string>;
  release: BlockParam<string>;
  dates: BlockParam<string>;
  description: BlockParam<string>;
};

const RenderedTimeline = ({ convertedBlock, highlight }: Props) => {
  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  const timelines = convertedBlock.params.timeline?.value.map(
    (timeline: BlockParamMulti) => JSONParseSafely(timeline.value)
  );

  //TODO: could use https://stackoverflow.com/questions/19087811/how-to-dynamically-generate-a-list-of-classes-with-commas-separating-them
  //instead of hard coding header-1, header-2, etc..
  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title || "Section"}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      <main className={styles["section"]}>
        {!!timelines?.length &&
          timelines?.map((timeline: Timeline, idx: number) => {
            return (
              <div key={idx} className={styles["grid-container"]}>
                <span className={`${styles["header"]} ${styles["header-1"]}`}>
                  {timeline.release?.name}
                </span>
                <span className={`${styles["header"]} ${styles["header-2"]}`}>
                  {timeline.dates?.name}
                </span>
                <span className={`${styles["header"]} ${styles["header-3"]}`}>
                  {timeline.description?.name}
                </span>
                <span className={`${styles["entry"]} ${styles["entry-1"]}`}>
                  {timeline.release?.value}
                </span>
                <span className={`${styles["entry"]} ${styles["entry-2"]}`}>
                  {timeline.dates?.value}
                </span>
                <span className={`${styles["entry"]} ${styles["entry-3"]}`}>
                  {timeline.description?.value}
                </span>
              </div>
            );
          })}
      </main>
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedTimeline;
