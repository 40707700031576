import clone from "just-clone";
import isEqual from "react-fast-compare";
import { ConvertedBlock } from "../../../asset-generator-lib/composer/models/Block";
import { getLatestDraftIndex } from "../../shared/helpers/helpers";
import {
  Artist,
  TemplatePage,
  TemplatePageDraft,
} from "../types/TemplatePages";

export type Action =
  | { type: "PUBLISH" }
  | { type: "UNPUBLISH" }
  | { type: "UPDATE_NAME"; payload: string }
  | { type: "UPDATE_TEMPLATECONFIG"; payload: ConvertedBlock[] }
  | { type: "SET_TEMPLATEPAGE"; payload: TemplatePage }
  | { type: "UPDATE_DRAFT"; payload: TemplatePageDraft; source?: string }
  | { type: "RESPONSE_UPDATE"; payload: TemplatePage };

export const templatePageReducerFn = (state: TemplatePage, action: Action) => {
  switch (action.type) {
    case "UPDATE_DRAFT":
      const newDrafts = [...state.drafts];
      newDrafts.splice(state.drafts.length - 1, 1, action.payload);

      return { ...state, drafts: [...newDrafts] };
    case "PUBLISH":
      // we can control how many drafts we want to keep.
      // 0 means we only ever have a single draft available.
      // (we are setting the index, so its the count - 1)
      const maxDraftIndex = 0;
      const now = new Date();

      // get the drafts
      let updatedDrafts = clone(state.drafts);

      // get the latest draft
      const latestDraftIndex: number = getLatestDraftIndex(updatedDrafts);
      // clone the current state, so we can make that the newest draft
      let draftToBePublished = {
        ...clone(state.drafts[latestDraftIndex]),
      };

      // make a copy (before we add publish / publish date info)
      const newDraft = clone(draftToBePublished);

      // update the publish date & publish of the draft we are publishing
      draftToBePublished.publishedDate = now;
      draftToBePublished.published = true;

      // add back to drafts - must be a clone so it doesnt reference back to the template config that gets published!
      updatedDrafts[latestDraftIndex] = clone(draftToBePublished);

      // if we have reached our draft index limit, remove the first draft,
      // and add the new draft, else just add the new draft
      if (latestDraftIndex < maxDraftIndex) {
        updatedDrafts.push(newDraft);
      } else if (maxDraftIndex === 0) {
        // if we only keep a single draft, we want to just keep using it,
        // we created the newdraft for no reason, oh well
      } else {
        // if we have reached our max, and not doing just a single draft,
        // kick out the oldest, and add a new
        updatedDrafts.shift();
        updatedDrafts.push(newDraft);
      }

      // now we want to promote the draft to be published by spreading it
      // to the main tempalte page state,
      // and then including the drafts as well
      const updatedTemplatePage: TemplatePage = {
        ...state,
        ...draftToBePublished,
        drafts: updatedDrafts,
      };

      return { ...updatedTemplatePage };

    case "UNPUBLISH":
      return {
        ...state,
        published: false,
        publishedDate: null,
      };
    case "UPDATE_NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "UPDATE_TEMPLATECONFIG":
      return {
        ...state,
        templateConfig: action.payload,
      };
    case "SET_TEMPLATEPAGE":
      return {
        ...state,
        ...action.payload,
      };
    case "RESPONSE_UPDATE":
      // if the response is the same (minus the current draft)
      if (
        isEqual({ ...state, drafts: null }, { ...action.payload, drafts: null })
      ) {
        return state;
      } else {
        const update = {
          ...state,
          ...action.payload,
          drafts: state.drafts,
        };
        return update;
      }

    default:
      console.error("❌❌ UNKNOWN ACTION TYPE:", action);
      return state;
  }
};

export const defaultTemplatePageDraft: TemplatePageDraft = {
  id: "",
  name: "",
  published: false,
  startDate: null,
  endDate: null,
  password: null,
  templateVersionId: "",
  templateType: "",
  templateSlug: "",
  mappedData: {},
  templateConfig: [] as ConvertedBlock[],
  artist: {} as Artist,
  slug: "",
  publishedDate: null,
};

export const defaultTemplatePage: TemplatePage = {
  id: "",
  name: "",
  published: false,
  startDate: null,
  endDate: null,
  password: null,
  templateVersionId: "",
  templateType: "",
  templateSlug: "",
  mappedData: {},
  templateConfig: [] as ConvertedBlock[],
  artist: {} as Artist,
  slug: "",
  drafts: [defaultTemplatePageDraft],
  publishedDate: null,
};
