import ProxyAPI from "../../../shared/ProxyAPI";
import ProductSchema from "../../schemas/p4a_proxy/ProductSchema";

interface Product {
  assets: any;
  id: string;
  dateSubmitted: Date | null;
  dspMetadata: any;
  artistId: string;
  artistName: string;
  name: string;
  artworkUrl: string | null;
  genre: any | null;
  alternateGenre: any | null;
  status: "DRAFT" | "REJECTED" | "SUBMITTED" | "EDIT_IN_FUGA" | "DELIVERED";
  category: "SINGLE" | "EP" | "ALBUM" | "VIDEO";
  version: string | null;
  youtubeMonetization: boolean;
  originalReleaseDate: Date | null;
  tracksNumber: number;
  releaseDate: Date | null;
  catNumber: string | null;
  upcEanCode: string | null;
  recordingLocation: string | null;
  releaseLanguage: string | null;
  copyrightYear: number | null;
  copyright: string | null;
  flagged: boolean;
  recordingYear: number | null;
  metadataLanguage: any | null;
  label: any | null;
  preAddEnabled: boolean;
  shareableLink: string | null;
  originalArtworkUrl: string | null;
}

const ProductAPI = {
  index: getResources,
  query: getResourceQueryResponse,
  read: getResource,
};

function productsSchema(artistId: string, dataIsNested: boolean = false) {
  let schema = { ...ProductSchema };
  schema.apiRootPath = `submission/artists/${artistId}/products`;
  schema.dataIsNested = dataIsNested;
  return schema;
}
function productSchema(
  artistId: string,
  p4a_release_id: string,
  dataIsNested: boolean = false
) {
  let schema = { ...ProductSchema };
  schema.apiRootPath = `submission/artists/${artistId}/products/${p4a_release_id}`;
  schema.dataIsNested = dataIsNested;
  return schema;
}

function getResources(artistId: string) {
  return ProxyAPI.fetch(productsSchema(artistId, false));
}

function getResourceQueryResponse(artistId: string, page = 0) {
  return ProxyAPI.fetchPaginated(productsSchema(artistId, false), page);
}

function getResource(artistId: string, p4a_release_id: string) {
  return ProxyAPI.fetch(productSchema(artistId, p4a_release_id, false));
}

function emptyProduct() {
  return {
    assets: null,
    id: "",
    dateSubmitted: null,
    dspMetadata: null,
    artistId: "",
    artistName: "",
    name: "",
    artworkUrl: null,
    genre: null,
    alternateGenre: null,
    status: "DRAFT",
    category: "SINGLE",
    version: null,
    youtubeMonetization: false,
    originalReleaseDate: null,
    tracksNumber: 1,
    releaseDate: null,
    catNumber: null,
    upcEanCode: null,
    recordingLocation: null,
    releaseLanguage: null,
    copyrightYear: null,
    copyright: null,
    flagged: false,
    recordingYear: null,
    metadataLanguage: null,
    label: null,
    preAddEnabled: false,
    shareableLink: null,
    originalArtworkUrl: null,
  } as Product;
}

function deepCopyRelease(product: Product) {
  var p = { ...product };
  return p;
}

export { ProductAPI, emptyProduct, deepCopyRelease };

export default Product;
