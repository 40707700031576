import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Divider, Box, Typography, Modal } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    overflowY: "hidden",
    overflowX: "hidden",
    outline: 0,
    border: "none",
  },
  modalTitle: {
    color: "#ffffff",
    fontSize: "26px",
    fontFamily: "SF Pro Display",
    marginTop: "30px",
    marginLeft: "30px",
    textTransform: "capitalize",
  },
  modalBody: {
    borderRadius: "5px",
    // overflowY: 'hidden',
    outline: "none",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    // width: "auto",
    // height: '525px',
    width: "635px",
    // maxHeight: '500px',
    // minWidth: '550px',
    backgroundColor: "#333333",
    // border: '2px solid #DB405A',
    // padding: theme.spacing(2, 4, 3),
  },
  root: {
    marginRight: "10px",
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#999999",
  },
  headerText: {
    marginBottom: "10px",
    color: "#cccccc",
    fontFamily: "SF Pro Text",
    fontSize: "18px",
    fontWeight: "normal",
    lineHeight: 1.56,
    [theme.breakpoints.down(420)]: {
      fontSize: "15px",
    },
  },
}));

interface ModalProps {
  title: string;
  saveTitle: string;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  showSaveButton: boolean;
  children?: React.ReactElement | React.ReactElement[];
}

export default function CMSModal(props: ModalProps) {
  const classes = useStyles();

  function handleSave(event: any) {
    event.preventDefault();
    props.onSave();
    props.onClose();
  }

  return (
    <Modal
      className={classes.modal}
      disableRestoreFocus={true}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={classes.modalBody} style={{ height: "auto" }}>
        <Typography className={classes.modalTitle}>{props.title}</Typography>

        {props.children}

        <Divider style={{ marginTop: "50px" }} />

        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginTop: "35px", marginBottom: "35px" }}
        >
          <Button
            onClick={props.onClose}
            style={{ marginLeft: "30px", width: "150px" }}
            variant="contained"
            className={classes.button}
          >
            CANCEL
          </Button>

          <Button
            style={{ marginRight: "30px", width: "150px" }}
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={!props.showSaveButton}
          >
            {props.saveTitle}
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
