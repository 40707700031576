import React, { useEffect } from "react";
import ArtistLinkMusicPlayer from "../link-builder/MusicPlayerComponents/ArtistLinkMusicPlayer";
import Lockdown from "../shared/components/Lockdown/Lockdown";
import { Box } from "@material-ui/core";
import { useArtistLinkContext } from "../providers/ArtistLinkProvider";
import { useParams } from "react-router-dom";
import { useErrorHandlingContext } from "../providers/ErrorHandlingProvider";
import {
  ArtistLinkAction,
  useAnalyticsContext,
} from "../providers/AnalyticsProvider";

export default function ArtistLinkPublicViewer() {
  const { trackArtistLink } = useAnalyticsContext();
  const { providedErrorState } = useErrorHandlingContext();

  const { artistSlug, artistLinkSlug, artistLinkShortId } = useParams();
  const { artistLink, loadArtistLinkWithPassword } = useArtistLinkContext();

  const handleSubmit = (password: string) => {
    loadArtistLinkWithPassword(
      artistSlug,
      artistLinkSlug,
      artistLinkShortId,
      password
    );
  };
  useEffect(() => {
    loadArtistLinkWithPassword(artistSlug, artistLinkSlug, artistLinkShortId);
  }, [artistLinkShortId, artistSlug, artistLinkSlug]);

  useEffect(() => {
    if (artistLink) {
      const mountPayload = {
        tracks: artistLink.tracks?.length,
        hasPassword: artistLink.requiresPassword,
        expDate: artistLink.endDateTime,
        p4a_release_id: artistLink.p4aReleaseId,
        assetDownloadable: artistLink.requiresAudioDownloadPassword,
      };
      trackArtistLink(artistLink, ArtistLinkAction.mount, mountPayload);
    }
  }, [artistLink]);

  if (providedErrorState.status === 403 && !providedErrorState.serveErrorPage) {
    return (
      <Lockdown
        lockDownMessage="This link is password protected."
        onSubmit={handleSubmit}
        height={"100vh"}
      />
    );
  }
  return (
    <Box height={1} width={1}>
      {artistLink && <ArtistLinkMusicPlayer playerWidth={12} preview={false} />}
    </Box>
  );
}
