import React, { useState, ReactNode } from "react";
import {
  Grid,
  MenuItem,
  Popper,
  ClickAwayListener,
  makeStyles,
} from "@material-ui/core";

import { ReactComponent as EllipsisBold } from "../../shared/icons/ellipsis-bold.svg";

interface Props {
  options: Option[];
  icon?: ReactNode;
}

type Option = {
  text: ReactNode | string;
  textColor?: string;
  action: any;
};

type StyleProps = {
  color?: string;
};

const useStyles = makeStyles(() => ({
  icon: {
    color: "#fff",
    width: "18px",
    padding: "13px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
  button: {
    cursor: "pointer",
    backgroundColor: "transparent",
    padding: 0,
    border: 0,
    margin: 0,
  },
  menu: {
    width: "150px",
    backgroundColor: "#0d0d0d",
    borderRadius: "8px",
  },
  menuItem: {
    fontFamily: "Helvetica",
    fontSize: "12px",
    borderRadius: "8px",
  },
  option: {
    color: ({ color }: StyleProps) => (color ? color : "#b4b4b4"),
  },
}));

export default function MenuOptions({ icon, options }: Props) {
  const classes = useStyles({});

  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  const handleMenuAction = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    action: FunctionConstructor
  ) => {
    event.stopPropagation();
    action();
    handleClose(event);
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorElement(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpen(false);
  };

  return (
    <Grid container justify="flex-end">
      <Grid item>
        <button className={classes.button} onClick={handleClick}>
          {icon ? icon : <EllipsisBold className={classes.icon} />}
        </button>
        {open && (
          <Popper open={open} placement="bottom-start" anchorEl={anchorElement}>
            <ClickAwayListener
              touchEvent="onTouchStart"
              onClickAway={handleClose}
            >
              <button
                onClick={(event) => {
                  event.stopPropagation(), event.preventDefault();
                }}
                className={`btn--naked ${classes.menu}`}
              >
                {options.map((option, index) => (
                  <MenuItem
                    key={index}
                    onClick={(event) => handleMenuAction(event, option.action)}
                    className={classes.menuItem}
                  >
                    <MenuOption textColor={option.textColor}>
                      {option.text}
                    </MenuOption>
                  </MenuItem>
                ))}
              </button>
            </ClickAwayListener>
          </Popper>
        )}
      </Grid>
    </Grid>
  );
}

type MenuOptionProps = {
  textColor?: string;
  children?: ReactNode;
};
const MenuOption = ({ textColor, children }: MenuOptionProps) => {
  const classes = useStyles({ color: textColor });
  return <div className={classes.option}>{children}</div>;
};
