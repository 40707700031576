import { Logger } from "./SafeLogger";
import FrameRouter, {
  ArtistIdentity,
  SessionRequest,
  SessionResponse,
  FrameMessage,
} from "frame-router";
import FrameRouterPackage from "../../../../node_modules/frame-router/package.json";
import base64 from "base-64";
import utf8 from "utf8";
import { UserRole } from "./ResourceSchema";
import * as Sentry from "@sentry/react";

interface RockDoveRequest {
  // url: string
  execute: (arg0: SessionResponse) => Promise<any>;
  resolve: any;
  reject: any; //TODO: put errors in here
}

interface ArtistCredentials {
  artist: ArtistIdentity;
  token: string;
}

export type Headers = {
  Authorization: string;
};

declare const window: any;

const messageHandler = (event: any) => {
  if (typeof event.data === "string") {
    const payload = event.data.replace("[iFrameSizer]message:", "");
    try {
      const data = JSON.parse(payload);
      // Logger.of('App.RockDove').info("RockDove got data", data);

      if (data.type && data.type === "SESSION_RESPONSE") {
        Logger.of("App.RockDove").info("handleSessionResponse:", data.data);
        RockDove.shared().handleSessionResponse(data.data);
      }
    } catch (e) {
      // Logger.of('App.RockDove').info("Could not parse json");
    }
  }
};

class RockDove {
  private static _shared: RockDove;

  private queue: RockDoveRequest[] | null;
  private currentRequest: RockDoveRequest | null | undefined;
  private currentSession: SessionResponse | null;
  private currentSessionResponseHandler:
    | ((sessionResponse: SessionResponse) => void)
    | null;

  private constructor() {
    this.queue = [];
    this.currentRequest = null;
    this.currentSession = null;
    this.currentSessionResponseHandler = null;

    window.addEventListener("message", messageHandler);
  }

  public static shared() {
    if (!this._shared) {
      this._shared = new RockDove();
    }
    return this._shared;
  }

  public handleSessionResponse(sessionResponse: SessionResponse) {
    // Logger.of('App.RockDove').info("handleSessionResponse currentSessionResponseHandler", this.currentSessionResponseHandler);
    if (this.currentSessionResponseHandler) {
      // Logger.of('App.RockDove').info("RockDove resolving current session request")
      this.currentSessionResponseHandler(sessionResponse);
      this.currentSessionResponseHandler = null;
    }
  }

  public sendHistoryNotification(message: any) {
    this.getParentIFrame().then((parentIFrame) => {
      try {
        const frameMessage = {
          version: FrameRouterPackage.version,
          type: "HISTORY_CHANGED",
          reason: "history changed",
          state: "default",
          data: message,
        } as FrameMessage;
        parentIFrame.sendMessage(frameMessage);
      } catch {
        Logger.of("App.RockDove").warn(
          "could not get parentIFrame, looks like RockDove is not embedded in an iFrame"
        );
      }
    });
  }

  public async getArtistCredentials(): Promise<ArtistCredentials> {
    const execute = (sessionResponse: SessionResponse) => {
      Logger.of("App.RockDove").info(
        "executing get identity with ",
        sessionResponse
      );
      const credentials = {
        artist: sessionResponse.artist,
        token: sessionResponse.token,
      } as ArtistCredentials;
      return Promise.resolve(credentials);
    };

    return new Promise((resolve, reject) => {
      const request = { execute, resolve, reject, running: false };
      Logger.of("App.RockDove").info("enqueue getArtistCredentials request");
      this.enqueue(request);
    });
  }

  public async getUserCategory() {
    const execute = (sessionResponse: SessionResponse) => {
      Logger.of("App.RockDove").info(
        "executing get identity with ",
        sessionResponse
      );
      const token = sessionResponse.token;
      const roles = token.split(".")[1];
      const adat = JSON.parse(atob(roles));
      let category;
      if (adat.roles && adat.roles.includes("manager")) {
        category = "manager";
      } else if (
        adat.roles &&
        !adat.roles.includes("manager") &&
        adat.roles.includes("artist")
      ) {
        category = "artist";
      }
      return Promise.resolve(category);
    };

    return new Promise((resolve, reject) => {
      const request = { execute, resolve, reject, running: false };
      Logger.of("App.RockDove").info("enqueue getArtistCredentials request");
      this.enqueue(request);
    });
  }

  public async getHeaders(role: UserRole): Promise<Headers | undefined> {
    if (role === UserRole.Artist) {
      try {
        Logger.of("App.RockDove").info("awaiting artist credentials...");
        const credentials = await this.getArtistCredentials();
        const bytes = utf8.encode(JSON.stringify(credentials));
        const encodedCredentials = base64.encode(bytes);
        Logger.of("App.RockDove").info(
          "got artist credentials, encoded:",
          encodedCredentials
        );
        const headers = { Authorization: "Bearer " + encodedCredentials };
        return headers;
      } catch (e) {
        Logger.of("App.RockDove").error("could not get headers, error:", e);
        throw e;
      }
    }
    return undefined;
  }

  public getOrigin() {
    return window.location.origin;
  }

  public getParentAppPathFor(subAppPath: string) {
    const sourceConfig = this.currentSession?.sourceConfig;
    if (!sourceConfig) {
      Logger.of("App.RockDove").error(
        "currentSession.sourceConfig is null, redirecting to native /apps/link-viewer"
      );

      return "/apps/link-viewer";
    }

    return FrameRouter.getParentAppPathFor(subAppPath, sourceConfig);
  }

  get isRunning(): boolean {
    return this.currentRequest !== null && this.currentRequest !== undefined;
  }

  private enqueue(request: RockDoveRequest) {
    this.queue && this.queue.push(request);
    this.runQueueIfNeeded();
  }

  private runQueueIfNeeded() {
    Logger.of("App.RockDove").info(
      "runQueueIfNeeded, running?",
      this.isRunning
    );
    if (!this.isRunning) {
      this.runQueue();
    }
  }

  private runQueue() {
    Logger.of("App.RockDove").info("runQueue, queue:", this.queue);
    this.currentRequest = this.queue && this.queue.pop();
    Logger.of("App.RockDove").info(
      "runQueue, currentRequest:",
      this.currentRequest
    );

    if (this.currentRequest) {
      this.runRequest(this.currentRequest).then(() => {
        Logger.of("App.RockDove").info("Setting current request to null");
        this.currentRequest = null;
        this.runQueueIfNeeded();
      });
    }
  }

  private runRequest(request: RockDoveRequest) {
    return this.getSession()
      .then((sessionResponse: any) => {
        // this should be SessionResponse, but wont let it assign here because it's unkown
        Logger.of("App.RockDove").info("got sessionResponse:", sessionResponse);

        request
          .execute(sessionResponse)
          .then((response: any) => {
            const resolver = request.resolve;
            resolver(response);
          })
          .catch(this.currentRequest && this.currentRequest.reject);
      })
      .catch(this.currentRequest && this.currentRequest.reject);
  }

  private sessionIsValid(session: SessionResponse) {
    try {
      const components = session.token.split(".");
      if (components.length !== 3) {
        throw new Error("session.token should be jwt format");
      }

      const payload = base64.decode(components[1]);
      if (!payload) {
        throw new Error("Could not base64 decode jwt token payload");
      }

      const expirationDate = new Date(JSON.parse(payload).exp * 1000);
      const now = new Date();

      if (now > expirationDate) {
        throw new Error(`${now} is later than expiration ${expirationDate}`);
      }
      Logger.of("App.RockDove").info(
        "validated token:",
        expirationDate,
        session.token
      );
    } catch (e) {
      Logger.of("App.RockDove").warn("session is not valid because", e);
      return false;
    }

    return true;
  }

  private getSession() {
    return new Promise((resolve, reject) => {
      if (this.currentSession && this.sessionIsValid(this.currentSession)) {
        Logger.of("App.RockDove").info(
          "getSession resolving with current session:",
          this.currentSession
        );

        resolve(this.currentSession);
        return;
      } else {
        Logger.of("App.RockDove").info(
          "no current session, or expired, asking parentIFrame for new session..."
        );
      }

      this.getParentIFrame().then((parentIFrame) => {
        Logger.of("App.RockDove").info(
          "sending message to get session, parentIFrame",
          parentIFrame
        );
        const sessionRequest = {
          identifier: "REQUEST_IDENTIFIER",
        } as SessionRequest;

        if (parentIFrame) {
          const frameMessage = {
            version: FrameRouterPackage.version,
            type: "SESSION_REQUEST",
            reason: "no session",
            state: "blocked",
            data: sessionRequest,
          } as FrameMessage;

          this.currentSessionResponseHandler = (
            sessionResponse: SessionResponse
          ) => {
            Logger.of("App.RockDove").info(
              "got session response, resolving getSession promise",
              sessionResponse
            );
            Sentry.setUser({ artist_p4a_artist_id: sessionResponse.artist.id });

            if (this.sessionIsValid(sessionResponse)) {
              this.currentSession = sessionResponse;
              resolve(this.currentSession);
            } else {
              const e = new Error(
                "Got invalid session from parent iFrame host"
              );
              reject(e);
              throw e;
            }
          };

          parentIFrame.sendMessage(frameMessage);
        } else {
          const message = "No parent iFrame found";

          Logger.of("App.RockDove").error(message);
          reject(new Error(message));
        }
      });
    });
  }

  private _getParentIFrame() {
    return "parentIFrame" in window ? window.parentIFrame : null;
  }

  private async getParentIFrame() {
    const parentIFrame = this._getParentIFrame();
    Logger.of("App.RockDove").info("parentIFrame:", parentIFrame);
    if (parentIFrame) {
      return parentIFrame;
    }

    //Otherwise wait a bit
    return new Promise((resolve) => {
      Logger.of("App.RockDove").info(
        "waiting 1 second for dom to load, then trying to get iframe..."
      );

      return setTimeout(() => {
        Logger.of("App.RockDove").info("resolving promise to get iframe...");

        resolve(this._getParentIFrame());
      }, 1000);
    });
  }
}

export { ArtistCredentials };
export default RockDove;
