import React, { useEffect } from "react";
import { useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { useDebounce } from "../../shared/CustomHooks";
import { Update } from "../ArtistLinkEdit/FormFields";

type BetterProps = {
  initValue: string;
  update: (update: Update) => void;
  passThroughProps: any; // typing this has been a real challenge.  it should just be the type from the TextValidator props
};

const TextValidatorWrapper = (props: BetterProps) => {
  const [localValue, setLocalValue] = useState(props.initValue);
  const debouncedValue = useDebounce(localValue, 500);

  useEffect(() => {
    props.update({ name: props.passThroughProps.id, value: debouncedValue });
  }, [debouncedValue]);

  return (
    <TextValidator
      {...props.passThroughProps}
      value={localValue}
      onChange={(e: any) => setLocalValue(e.target.value)}
    />
  );
};

export default TextValidatorWrapper;
