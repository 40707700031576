import React from "react"

interface Props {
  color: string
}

const PauseButton = (props: Props) => {

  const iconColor = props.color ? props.color : '#FFFFFF'

  return (
    <svg width="27px" height="34px" viewBox="0 0 27 34" version="1.1">
      <title>pause</title>
      <g id="V3" stroke="none" strokeWidth="1" fill={`${iconColor}`} fillRule="evenodd">
        <g id="Page-Structure" transform="translate(-1017.000000, -1015.000000)" fill={`${iconColor}`} fillRule="nonzero">
          <path d="M1025.03125,1048.14844 C1027.16016,1048.14844 1028.19531,1046.99609 1028.19531,1045.23828 L1028.19531,1018.57812 C1028.19531,1016.82031 1027.16016,1015.64844 1025.03125,1015.64844 L1021.16406,1015.64844 C1019.03516,1015.64844 1017.98047,1016.82031 1017.98047,1018.57812 L1017.98047,1045.23828 C1017.98047,1046.97656 1019.03516,1048.14844 1021.16406,1048.14844 L1025.03125,1048.14844 Z M1039.875,1048.14844 C1041.98438,1048.14844 1043.03906,1046.97656 1043.03906,1045.23828 L1043.03906,1018.57812 C1043.03906,1016.82031 1041.98438,1015.64844 1039.875,1015.64844 L1035.98828,1015.64844 C1033.87891,1015.64844 1032.82422,1016.82031 1032.82422,1018.57812 L1032.82422,1045.23828 C1032.82422,1046.99609 1033.87891,1048.14844 1035.98828,1048.14844 L1039.875,1048.14844 Z" id="pause"></path>
        </g>
      </g>
    </svg>
  )
}

export default PauseButton
