import React from "react";
import { makeStyles, withWidth } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

type Props = {
  width: Breakpoint;
  backgroundImage?: string;
  text?: string;
  icon?: React.ReactNode;
  selected: boolean;
};

type StyleProps = {
  backgroundImage?: string;
  selected?: boolean;
};

const useStyles = makeStyles((theme) => ({
  tileContainer: {
    backgroundColor: "#333333",
    paddingTop: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: ({ selected }: StyleProps) => (selected ? 1 : 0.8),
    borderRadius: "4px",
    outline: ({ selected }: StyleProps) =>
      selected ? "2px solid #ff4169" : "2px dashed #999",
    transition: ".1s ease",
  },
  tileWrapper: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing(3),
  },
  icon: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Futura",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#ffffff",
  },
}));

const CustomTile = ({ text, icon, backgroundImage, selected }: Props) => {
  const classes = useStyles({
    selected,
    backgroundImage: backgroundImage || "",
  });
  return (
    <div className={classes.tileContainer}>
      <div className={classes.tileWrapper}>
        <div className={classes.icon}>{icon}</div>
        <h1 className={classes.text}>{text}</h1>
      </div>
    </div>
  );
};

export default withWidth()(CustomTile);
