import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../platoon-cms-lib/fonts/luminari.css";
import "../platoon-cms-lib/fonts/andale_mono.css";
import "../platoon-cms-lib/fonts/futura.css";
import "../platoon-cms-lib/fonts/oswald.css";
import "../platoon-cms-lib/fonts/lato.css";
import ArtistPagesPreviewApp from "../artist-pages-preview/ArtistPagesPreviewApp";
import HistoryPoster from "../shared/HistoryPoster";
import { QueryClient, QueryClientProvider } from "react-query";
import { Helmet } from "react-helmet";
import PromoteIndex from "./PromoteIndex/PromoteIndex";
import Loading from "../shared/Loading";
import AuthCheck from "../shared/AuthCheck/AuthCheck";
const LazyTemplatePageBuilderApp = React.lazy(
  () => import("../template-page-builder/TemplatePageBuilderApp")
);
const LazyPromoPackBuilderApp = React.lazy(
  () => import("../promo-pack-builder/PromoPackBuilderApp")
);
const LazyLinkBuilderApp = React.lazy(
  () => import("../link-builder/LinkBuilderApp")
);

const basePath = "apps/promote";

const queryClient = new QueryClient();

const PromoteApp = () => {
  return (
    <div id="promote-app-content">
      <AuthCheck>
        <Router>
          <QueryClientProvider client={queryClient}>
            <Helmet>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
            </Helmet>
            <HistoryPoster>
              <React.Suspense fallback={<Loading />}>
                <Routes>
                  <Route path={basePath}>
                    <Route index element={<PromoteIndex />} />
                    <Route
                      path={"template-page-builder/*"}
                      element={<LazyTemplatePageBuilderApp />}
                    />
                    <Route
                      path={"asset-generator/*"}
                      element={<LazyPromoPackBuilderApp />}
                    />
                    <Route
                      path={"stealth-builder/*"}
                      element={<LazyLinkBuilderApp />}
                    />
                    <Route
                      path={"artist-pages-preview/*"}
                      element={<ArtistPagesPreviewApp />}
                    />
                  </Route>
                </Routes>
              </React.Suspense>
            </HistoryPoster>
          </QueryClientProvider>
        </Router>
      </AuthCheck>
    </div>
  );
};
export default PromoteApp;
