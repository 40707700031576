import React, { createContext, useContext, useEffect, useState } from "react";
import {
  ContentNode,
  fetchContent,
} from "../publications/components/ContentProvider";
import Loading from "../shared/Loading";
import { useErrorHandlingContext } from "./ErrorHandlingProvider";

type Props = {
  children: React.ReactNode;
  basePath: string;
};

type PublicationsAppContext = {
  contentNode: ContentNode | null;
};

export const PublicationsAppContext =
  createContext<PublicationsAppContext | null>(null);
export const usePublicationsAppContext = () =>
  useContext(PublicationsAppContext)!;

export const PublicationsAppProvider = ({ children, basePath }: Props) => {
  const { errorDispatch } = useErrorHandlingContext();
  const [contentNode, setContentNode] = useState<ContentNode | null>(null);

  useEffect(() => {
    if (!contentNode) {
      fetchContent()
        .then((contentNode: any) => {
          //Shared content being set is a side effect of fetchContent, not sure I like it
          ContentNode.pathPrefix = basePath;
          setContentNode(contentNode);
        })
        .catch((error) => {
          errorDispatch({
            type: "SET_ERROR",
            payload: { error: error, serveErrorPage: true },
          });
        });
    }
  }, [contentNode]);

  return (
    <PublicationsAppContext.Provider value={{ contentNode }}>
      {contentNode ? children : <Loading height={"100vh"} />}
    </PublicationsAppContext.Provider>
  );
};
