import React from "react";
import { makeStyles } from "@material-ui/core";
import { ContentNode } from "../ContentProvider";
import CardSection from "../Cards/CardSection";
import { createBookmarkNodes } from "../../../shared/helpers/helpers";
import { usePublicationsAppContext } from "../../../providers/PublicationsAppProvider";

const useStyles = makeStyles(() => ({
  root: {
    color: "#fff",
    padding: "40px 0",
  },
  learnH1: {
    textTransform: "uppercase",
    fontFamily: "Futura",
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: -0.5,
    color: "#ffffff",
    margin: 0,
  },
  learnHeaderContainer: {
    position: "relative",
    marginBottom: "64px;",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:before": {
      content: "''",
      position: "absolute",
      borderRadius: "1px",
      width: "50px",
      left: 0,
      bottom: "-24px",
      borderBottom: "2px solid white",
    },
  },
}));
const LearnIndex = () => {
  const { contentNode } = usePublicationsAppContext();
  const classes = useStyles();

  const cardSections = contentNode?.children.map((childNode: ContentNode) => (
    <CardSection contentNode={childNode} key={childNode.id} />
  ));

  return (
    <div className={classes.root}>
      <header className={classes.learnHeaderContainer}>
        <h1 className={classes.learnH1}>Learn!</h1>
      </header>
      {/* bookmarks, then normal card sections */}
      {contentNode && (
        <CardSection contentNode={createBookmarkNodes(contentNode)} noButtons />
      )}
      {cardSections}
    </div>
  );
};

export default LearnIndex;
