import React, { useContext, createContext, useEffect } from "react";
import { ContentNode } from "../publications/components/ContentProvider";
import ArtistLink from "../link-builder/models/ArtistLink";
import { emptyP4aArtist } from "../promo-pack-builder/models/p4a_proxy/P4aArtist";
import PromoPack from "../promo-pack-builder/models/PromoPack";
import { Asset } from "../link-builder/models/ArtistLink"; //testedODO: move this model out to common
import { trackAggregatesEvent } from "../analytics/models/Aggregates";
import { usePlatoonArtistContext } from "./PlatoonArtistProvider";
import { Logger } from "../shared/SafeLogger";
import { useLocation } from "react-use";
import { trackStructEvent as spTrackStructEvent } from "@snowplow/browser-tracker";

const slugify = (...args: (string | number)[]): string => {
  const value = args.join(" ");

  return value
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, "-"); // separator
};

const trackStructEvent = (event: any) => {
  Logger.of("App.AnalyticsProvider").info("event", event);
  spTrackStructEvent(event);
};

export enum AppType {
  publications = "publications",
  artistLinkBuilder = "artist_link_builder",
  artistLinkPlayer = "artist_link_player",
  assetGenerator = "asset_generator",
  templatePageBuilder = "template_page_builder",
}

// Declare a monster set of actions as varaibles
// the compiler should ensure they're all used

// First global/normalized actions, user must look at se_label to ensure all is good
// const app_visit = "visit"; //needs more definition
const appMount = "mount"; //tested but needs to wait for rockdove session to have a current user
const appUnmount = "unmount";

// Learn tab (Publications)
export enum PublicationsAction {
  contentNodeViewed = "content_node_viewed", //tested
  contentNodeUnbookmarked = "content_node_unbookmarked", //tested
  contentNodeBookmarked = "content_node_bookmarked", //tested
  tocClicked = "toc_clicked", //tested
}

export enum ArtistLinkAction {
  mount = "mount", //in test
  artistLinkViewed = "artist_link_viewed", //tested
  artistLinkCreated = "artist_link_created", //tested
  artistLinkUpdated = "artist_link_updated", //tested
  tracklistViewed = "tracklist_viewed", //tested
  songStarted = "song_started", //tested
  songPlayed = "song_played", //tested
  bioViewed = "bio_viewed", //tested
  socialLinkClicked = "social_link_clicked", //tested
  assetDownloaded = "asset_downloaded", //tested
}

export enum TemplatePageAction {
  mount = "mount",
}

export enum AssetGeneratorAction {
  promoPackCreated = "promo_pack_created",
  promoPackUpdated = "promo_pack_updated",
  ctaSelected = "cta_selected", //needs more definition. might be cta_selected.out_now
  backgroundSelected = "background_selected", //needs more definition. might be background_selected.album_artwork
  assetCreated = "asset_created", //tested
  // asset_created_youtube = "asset_created.youtube-cover", //needed for validation later
  // asset_created_twitter = "asset_created.twitter",
  // asset_created_spotify = "asset_created.spotify",
  // asset_created_facebook = "asset_created.facebook",
  // asset_created_instagram = "asset_created.instagram"
}

const apiPath = (path: string) => `${window.location.origin}/api/v1${path}`;

type Props = {
  app: AppType;
  children: React.ReactNode;
};

type AnalyticsContext = {
  trackContentNodeViewed: (arg: ContentNode) => void;
  trackTOCClicked: (arg: ContentNode) => void;
  trackContentNodeBookmarked: (arg: ContentNode, selected: boolean) => void;
  trackArtistLink: (
    artistLink: ArtistLink,
    action: ArtistLinkAction,
    contentPayload?: any
  ) => void;
  trackPromoPack: (promoPack: PromoPack, action: AssetGeneratorAction) => void;
  trackPromoAsset: (
    promoAsset: Asset,
    action: AssetGeneratorAction,
    p4aArtistId: string
  ) => void;
};

export const AnalyticsContext = createContext<AnalyticsContext | null>(null);
export const useAnalyticsContext = () => useContext(AnalyticsContext)!;

export const AnalyticsProvider = ({ app, children }: Props) => {
  const platoonArtistContext = usePlatoonArtistContext;

  const location = useLocation();
  const stealthViewer = location.pathname?.includes("stealth-player")!;

  const p4aArtist = platoonArtistContext()
    ? platoonArtistContext().p4aArtist
    : emptyP4aArtist();

  const contentDataForNode = (node: ContentNode) => {
    return {
      contentId: node.id,
      contentTitle: node.title,
      contentType: "ContentNode",
      contentUrl: apiPath(`/nodes/${node.slug}`),
    };
  };

  const dataForArtistLink = (artistLink: ArtistLink, contentPayload: any) => {
    const payload =
      contentPayload && JSON.stringify(contentPayload).length <= 1024
        ? JSON.stringify(contentPayload)
        : JSON.stringify({});
    return {
      contentId: artistLink.id,
      contentTitle: artistLink.title,
      contentType: "ArtistLink",
      contentOriginator: artistLink.artist.p4aArtistId,
      contentUrl: apiPath(
        `/artists/${artistLink.artist.id}/links/${artistLink.id}`
      ),
      contentPayload: payload,
    };
  };

  const contentDataForPromoPack = (promoPack: PromoPack) => {
    return {
      contentId: promoPack.id,
      contentTitle: promoPack.name,
      contentType: "PromoPack",
      contentUrl: apiPath(
        `/artists/${promoPack.artist.slug}/promo-packs/${promoPack.id}`
      ),
      contentOriginator: promoPack.artist.id,
    };
  };

  const contentDataForPromoAsset = (asset: Asset, p4aArtistId: string) => {
    return {
      contentId: asset.id,
      contentTitle: asset.description,
      contentType: "PromoAsset",
      contentUrl: apiPath(`/artists/${p4aArtistId}/promo-asset/${asset.id}`),
      contentOriginator: p4aArtistId,
    };
  };

  const contextForData = (contentData: any) => {
    return [
      {
        schema: "iglu:com.assembleinc/platoon/jsonschema/1-0-0",
        data: contentData,
      },
    ];
  };

  // TODO: trackVisit maybe needs needs a session in localstorage
  const trackAppMount = (didMount: boolean) => {
    const struct_evt = {
      category: p4aArtist.role,
      action: didMount ? appMount : appUnmount,
      label: app,
      property: p4aArtist?.id,
      context: [
        {
          contentId: "",
        },
      ],
    };
    trackStructEvent(struct_evt);
  };

  // learn-tab tracking
  const trackContentNodeViewed = (currentNode: ContentNode) => {
    const contentData = contentDataForNode(currentNode);
    const struct_evt = {
      category: p4aArtist.role,
      action: PublicationsAction.contentNodeViewed,
      label: app,
      property: p4aArtist?.id,
      context: contextForData(contentData),
    };
    trackStructEvent(struct_evt);
  };

  const trackTOCClicked = (currentNode: ContentNode) => {
    const contentData = contentDataForNode(currentNode);
    const struct_evt = {
      category: p4aArtist.role,
      action: PublicationsAction.tocClicked,
      label: app,
      property: p4aArtist?.id,
      context: contextForData(contentData),
    };
    trackStructEvent(struct_evt);
  };

  const trackContentNodeBookmarked = (
    currentNode: ContentNode,
    selected: boolean
  ) => {
    const contentData = contentDataForNode(currentNode);
    const struct_evt = {
      category: p4aArtist.role,
      action: selected
        ? PublicationsAction.contentNodeUnbookmarked
        : PublicationsAction.contentNodeBookmarked,
      label: app,
      property: p4aArtist?.id,
      context: contextForData(contentData),
    };
    trackStructEvent(struct_evt);
  };

  const hasAggregateEvent = (action: ArtistLinkAction) => {
    switch (action) {
      case ArtistLinkAction.mount:
        return true;
      case ArtistLinkAction.songPlayed:
        return stealthViewer;
      default:
        return false;
    }
  };

  // artist-link tracking
  const trackArtistLink = (
    artistLink: ArtistLink,
    action: ArtistLinkAction,
    contentPayload?: any
  ) => {
    const contentData = dataForArtistLink(artistLink, contentPayload);

    const struct_evt = {
      category: p4aArtist.role,
      action: action,
      label: app,
      property: p4aArtist?.id,
      context: contextForData(contentData),
    };

    if (hasAggregateEvent(action)) {
      const aggregateEvent = {
        actionType: action,
        contentType: "ArtistLink",
        contentId: artistLink.id,
        lastAggregateAction: "bump",
        valueType: "count",
        value: 0,
      };
      trackAggregatesEvent(aggregateEvent);
    }
    trackStructEvent(struct_evt);
  };

  // asset-generator tracking
  const trackPromoPack = (
    promoPack: PromoPack,
    action: AssetGeneratorAction
  ) => {
    const contentData = contentDataForPromoPack(promoPack);
    const struct_evt = {
      category: p4aArtist.role,
      action: action,
      label: app,
      property: p4aArtist?.id,
      context: contextForData(contentData),
    };
    trackStructEvent(struct_evt);
  };

  const trackPromoAsset = (
    promoAsset: Asset,
    action: AssetGeneratorAction,
    p4aArtistId: string
  ) => {
    const assetType = slugify(promoAsset.description);
    const fullAction = `${action}.${assetType}`; //tested

    const contentData = contentDataForPromoAsset(promoAsset, p4aArtistId);

    const struct_evt = {
      category: p4aArtist.role,
      action: fullAction,
      label: app,
      property: p4aArtist?.id,
      context: contextForData(contentData),
    };
    trackStructEvent(struct_evt);
  };

  // testedrack the mount/unmount of the analytics provider for this app
  // Updating to having a mount event exist in the linkViewerApp,
  // Will need to add proper visit event which will act like the below useEffect with a
  // token in local storage

  useEffect(() => {
    if (app === AppType.artistLinkBuilder) {
      trackAppMount(true);
    }
  }, [app]);

  return (
    <AnalyticsContext.Provider
      value={{
        trackContentNodeViewed,
        trackTOCClicked,
        trackContentNodeBookmarked,
        trackArtistLink,
        trackPromoPack,
        trackPromoAsset,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
