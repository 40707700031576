import React from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import { JSONParseSafely } from "../../../../shared/helpers/helpers";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import styles from "./RenderedTourDates.module.scss";
import { BlockParam } from "../../../../../asset-generator-lib/composer/models/Block";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

type TourDate = {
  city: BlockParam;
  country: BlockParam;
  dateTime: BlockParam<string>;
  ticketsUrl: BlockParam;
  venueName: BlockParam;
};

const RenderedTourDates = ({ convertedBlock, highlight }: Props) => {
  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  const tourDates = convertedBlock.params.tourDate.value?.sort(
    (tourDate1: BlockParam, tourDate2: BlockParam) => {
      const parsedTourDate1 = JSONParseSafely(tourDate1?.value);
      const parsedTourDate2 = JSONParseSafely(tourDate2?.value);

      //needs to be any to do arithmatic
      const date1 = new Date(parsedTourDate1.dateTime.value);
      const date2 = new Date(parsedTourDate2.dateTime.value);

      //kinda cool way to coerce dates to number :)
      return +date1 - +date2;
    }
  );

  const tourDateEls =
    !!tourDates?.length &&
    tourDates?.map((tourDate: BlockParam, index: number) => {
      const parsedTourDate: TourDate = JSONParseSafely(tourDate.value);
      const date = new Date(parsedTourDate.dateTime.value || "");

      return (
        <div className={styles["tour-date"]} key={index}>
          <div className={styles["date"]}>
            <p className={styles["day"]}>
              {date.toLocaleString("en-us", { day: "2-digit" })}
            </p>
            <p className={styles["month"]}>
              {date.toLocaleString("en-us", { month: "short" })}
            </p>
          </div>
          <div className={styles["details"]}>
            <p className={styles["venue"]}>
              <a href={parsedTourDate.ticketsUrl?.value} target="_new">
                {parsedTourDate.venueName.value}
              </a>
            </p>
            <p className={styles["second-line"]}>
              {date.toLocaleDateString("en-us", { weekday: "short" })}{" "}
              {date.toLocaleTimeString("en-us", { timeStyle: "short" })} -{" "}
              {parsedTourDate.city.value}, {parsedTourDate.country.value}{" "}
            </p>
          </div>
          <div>
            <a
              href={parsedTourDate.ticketsUrl.value}
              target="_new"
              className={`btn btn--outline btn--block ${styles["action"]}`}
            >
              BUY TICKETS
            </a>
          </div>
        </div>
      );
    });

  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      <main className={styles["conatiner"]}>{tourDateEls}</main>
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedTourDates;
