import ResourceSchema, {
  emptyResourceSchema,
  UserRole,
} from "../../../shared/ResourceSchema";

function adaptProductToBackend(frontendObject: any) {
  let target = {
    assets: frontendObject.assets,
    alternateGenre: frontendObject.alternateGenre,
    artistId: frontendObject.artistId,
    artistName: frontendObject.artistName,
    artworkUrl: frontendObject.artworkUrl,
    id: frontendObject.id,
    dateSubmitted: frontendObject.dateSubmitted?.toISOString(),
    name: frontendObject.name,
    genre: frontendObject.genre,
    status: frontendObject.status,
    category: frontendObject.category,
    version: frontendObject.version,
    originalReleaseDate: frontendObject.originalReleaseDate?.toISOString(),
    tracksNumber: frontendObject.tracksNumber,
    releaseDate: frontendObject.releaseDate?.toISOString(),
    catNumber: frontendObject.catNumber,
    upcEanCode: frontendObject.upcEanCode,
    recordingLocation: frontendObject.recordingLocation,
    releaseLanguage: frontendObject.releaseLanguage,
    copyrightYear: frontendObject.copyrightYear,
    copyright: frontendObject.copyright,
    flagged: frontendObject.flagged,
    recordingYear: frontendObject.recordingYear,
    metadataLanguage: frontendObject.metadataLanguage,
    label: frontendObject.label,
    preAddEnabled: frontendObject.preAddEnabled,
    shareableLink: frontendObject.shareableLink,
    youtubeMonetization: frontendObject.youtubeMonetization,
    originalArtworkUrl: frontendObject.originalArtworkUrl,
    dspMetadata: frontendObject.dspMetadata,
  };
  return target;
}

function adaptProductToFrontend(backendObject: any) {
  let defaultReleaseImage = "/images/no-image.png";
  let defaultReleaseDate = "No Date";
  let defaultReleaseName = "No Release Name";
  let target = {
    id: backendObject.id,
    assets: backendObject.assets,
    dateSubmitted:
      backendObject.dateSubmitted &&
      new Date(Date.parse(backendObject.dateSubmitted)),
    artistId: backendObject.artistId,
    artistName: backendObject.artistName,
    name: backendObject.name ? backendObject.name : defaultReleaseName,
    artworkUrl: backendObject.artworkUrl
      ? backendObject.artworkUrl
      : defaultReleaseImage,
    genre: backendObject.genre,
    alternateGenre: backendObject.alternateGenre,
    status: backendObject.status,
    category: backendObject.category,
    version: backendObject.version,
    youtubeMonetization: backendObject.youtubeMonetization,
    originalReleaseDate:
      backendObject.originalReleaseDate &&
      new Date(backendObject.originalReleaseDate).toLocaleDateString("en-us", {
        timeZone: "UTC",
      }),
    tracksNumber: backendObject.tracksNumber,
    outOnReleaseDate: backendObject.releaseDate
      ? `OUT ON ${new Date(backendObject.releaseDate).toLocaleDateString(
          "en-us",
          { timeZone: "UTC" }
        )}`
      : defaultReleaseDate,
    outOnReleaseDateUK: backendObject.releaseDate
      ? `OUT ON ${new Date(backendObject.releaseDate).toLocaleDateString(
          "en-GB",
          { timeZone: "UTC" }
        )}`
      : defaultReleaseDate,
    releaseDate: backendObject.releaseDate
      ? new Date(backendObject.releaseDate).toLocaleDateString("en-us", {
          timeZone: "UTC",
        })
      : defaultReleaseDate,
    releaseDateUK: backendObject.releaseDate
      ? new Date(backendObject.releaseDate).toLocaleDateString("en-GB", {
          timeZone: "UTC",
        })
      : defaultReleaseDate,
    catNumber: backendObject.catNumber,
    upcEanCode: backendObject.upcEanCode,
    recordingLocation: backendObject.recordingLocation,
    releaseLanguage: backendObject.releaseLanguage,
    copyrightYear: backendObject.copyrightYear,
    copyright: backendObject.copyright,
    flagged: backendObject.flagged,
    recordingYear: backendObject.recordingYear,
    metadataLanguage: backendObject.metadataLanguage,
    label: backendObject.label,
    preAddEnabled: backendObject.preAddEnabled,
    shareableLink: backendObject.shareableLink,
    originalArtworkUrl: backendObject.originalArtworkUrl,
    dspMetadata: backendObject.dspMetadata,
  };
  return target;
}

const ProductSchema: ResourceSchema = {
  ...emptyResourceSchema(),
  ...{
    title: "p4a Product",
    pluralTitle: "p4a Products",
    rootPath: "product",
    apiRootPath: "product",
    attrs: ["title", "artist"], // ??
    adaptor: {
      toFrontend: adaptProductToFrontend,
      toBackend: adaptProductToBackend,
    },
    role: UserRole.Artist,
    apiBase: "/p4a-api",
  },
};

export { adaptProductToFrontend, adaptProductToBackend };
export default ProductSchema;
