import { Grid } from "@material-ui/core";
import clone from "just-clone";
import React, { useEffect } from "react";
import { useState } from "react";
import { Template, TemplateConfig } from "../../../asset-generator-lib";
import { BlockParam } from "../../../asset-generator-lib/composer/models/Block";
import { usePromoPackContext } from "../providers/PromoPackProvider";
import { ValidationObject } from "./customizeHelpers";
import { SelectInput, TextInput, UnknownInput, ColorInput } from "./FormInputs";

type Props = {
  template: Template;
  templateConfig: TemplateConfig;
  disabled: boolean;
  validationCallback?: (returnedObj: ValidationObject) => void;
};

// validation function can live anywhere and be imported as needed.
export const validation = (input: string) => {
  if (input) {
    return true;
  } else {
    return false;
  }
};

export function getInputComponent(
  param: BlockParam,
  update: (input: any) => void,
  disabled: Boolean,
  subField?: Boolean
) {
  const componentList: { [key in BlockParam["type"]]?: any } = {
    TEXT: TextInput,
    SELECT: SelectInput,
    COLOR: ColorInput,
  };
  if (param.type === "STATIC" || param.type === "MAPPED") {
    // currently we dont want to show anything the user can't change.
    // eventually we may want to render out that data in some way - maybe in a text field that is locked
    return null;
  } else {
    const TagName = componentList[param.type] || UnknownInput;
    return (
      <Grid item key={param.name} xs={12}>
        <TagName
          param={param}
          update={update}
          subField={subField}
          disabled={disabled}
        />
      </Grid>
    );
  }
}
const CustomizeForm = ({ templateConfig, disabled }: Props) => {
  const { promoPack, dispatch } = usePromoPackContext();
  const [fieldTags, setFieldTags] = useState<JSX.Element[]>([]);

  const updateTemplateConfig = (newData: BlockParam) => {
    const clonedTemplateConfig: TemplateConfig = clone(
      promoPack.templateConfig
    );

    clonedTemplateConfig[newData.name] = {
      ...newData,
    };

    dispatch({
      type: "UPDATE_TEMPLATECONFIG",
      payload: {
        templateConfig: clonedTemplateConfig,
      },
    });
  };

  const mapFields = (items: TemplateConfig) => {
    const newFieldTags = Object.values(items).map((param: BlockParam) => {
      return getInputComponent(param, updateTemplateConfig, disabled);
    });

    let fieldTags: JSX.Element[] = [];
    newFieldTags.forEach((item) => item && fieldTags.push(item));
    setFieldTags(fieldTags);
  };

  useEffect(() => {
    mapFields(templateConfig);
  }, [templateConfig]);

  return <>{fieldTags}</>;
};

export default CustomizeForm;
