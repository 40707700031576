import Block, { Size, BlockParam } from './Block';
import KeyedObject, { objectForKey } from './KeyedObject';

type TemplateConfig = {
  [index: string]: BlockParam;
};

interface TemplateMetadata {
  version: string;
  basis: Size;
}

interface Template extends KeyedObject {
  // key comes from KeyedObject
  id?: string; //maybe comes from a backend object
  blocks: Block[];
  name: string;
  meta: TemplateMetadata;
  variants: TemplateVariant[];
  defaultTemplateConfig?: TemplateConfig;
}

interface TemplateVariant extends KeyedObject {
  // key comes from KeyedObject
  id?: string;
  super?: TemplateVariant;
  name: string;
  size: Size;
  blockOverrides?: Block[];
}

function templateForKey(key: string, templates?: Template[]) { return objectForKey(key, templates); };
function variantForKey(key: string, variants?: TemplateVariant[]) { return objectForKey(key, variants); };

export { TemplateMetadata, TemplateVariant, TemplateConfig, templateForKey, variantForKey };
export default Template;
