interface String {
  capitalize: () => string;
  removeExt: () => string;
  validUrl: () => string;
}

String.prototype.capitalize = function (): string {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.removeExt = function (): string {
  const lastDot = this.lastIndexOf(".");
  if (lastDot === -1) return this as string;
  else return this.substring(0, lastDot);
};

String.prototype.validUrl = function (): string {
  let newUrl = this.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
};
