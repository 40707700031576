import React, { useState } from "react";
import styles from "./Lockdown.module.scss";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockIcon from "../../../platoon-cms-lib/components/Buttons.tsx/LockIcon";
import { useErrorHandlingContext } from "../../../providers/ErrorHandlingProvider";

interface LockdownProps {
  lockDownMessage: string;
  onSubmit: any;
  height: string;
}

const Lockdown = ({ onSubmit, height, lockDownMessage }: LockdownProps) => {
  const { providedErrorState, errorDispatch } = useErrorHandlingContext();
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSubmit(userPassword);
  };

  const onChange = (event: any) => {
    setUserPassword(event.target.value);

    errorDispatch({
      type: "CLEAR_ERROR_MESSAGE",
    });
  };

  return (
    <div className={styles["container"]} style={{ minHeight: height }}>
      <div className={styles["lockdown"]}>
        <LockIcon className={styles["icon"]} />

        <h3>{lockDownMessage}</h3>
        <h3>Enter the password below to continue.</h3>
        <form
          onSubmit={handleSubmit}
          className={`full-width ${styles["form"]}`}
        >
          {/* need to have the submit button be the first button in the form,
          because hitting enter inside the text field activates the first button in a form,
          but we don't want to show, so we are hiding it */}
          <input
            type="submit"
            className="full-width btn hidden"
            value="Unlock"
            disabled={userPassword === ""}
          />
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <div className={styles["input-wrapper"]}>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowPassword(!showPassword);
              }}
              className={`btn btn--naked ${styles["end-adornment"]}`}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              className={`input full-width ${styles["input-override"]}`}
              onChange={onChange}
              value={userPassword}
              placeholder="Password"
            />
            {providedErrorState.message && (
              <p className="error">{providedErrorState.message}</p>
            )}
          </div>
          <input
            type="submit"
            className="full-width btn"
            value="Unlock"
            disabled={userPassword === ""}
          />
        </form>
      </div>
    </div>
  );
};

export default Lockdown;
