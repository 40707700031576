import { Logger } from "../../shared/SafeLogger";

import { Node, RawNode, NodeFactory } from "../../platoon-cms-lib";

import API from "../../shared/API";
import { UserRole } from "../../shared/ResourceSchema";
import RockDove from "../../shared/RockDove";

// const removeMd = require('remove-markdown');
const removeMd = (input: string) => {
  return input;
};

// interface ContentNodeJSON {
//     title: string;
//     subtitle?: string;
//     _id: string;
//     type: string;
//     children?: [];
//     content?: string;
//     subcontent?: string;
//     description?: string;
//     icon?: string;
//     card_type?: string;
// }

class ContentNodeQuery {
  query: string;
  keys: string[];
  constructor(query: string, keys: string[]) {
    this.query = query;
    this.keys = keys;
  }
}

export interface QueryMatch {
  key: string;
  range: { index: number; length: number };
  humanReadableValue: string;
}

export class ContentNodeQueryResult {
  matches: QueryMatch[];
  contentNode: ContentNode;
  didMatch: boolean;

  constructor(contentNode: ContentNode, matches: QueryMatch[]) {
    this.matches = matches;
    this.contentNode = contentNode;
    this.didMatch = this.matches.length > 0;
  }

  // Does this result have the given key as a match?
  matchedKey(key: string) {
    let matchesKey =
      this.matches.filter((match) => match.key === key).length > 0;
    return matchesKey;
  }
}

export class ContentNode extends Node {
  showsInCards: boolean;
  static pathPrefix?: string;

  constructor(rawNode: RawNode) {
    super(rawNode);
    this.showsInCards = true;
  }

  //add another function that uses window.location.origin and /playbook/${this.id}
  //absolute path/url
  get content() {
    return this.rawContent;
  }

  get parent(): ContentNode | undefined {
    return this._parent && (this._parent as ContentNode);
  }

  get children(): ContentNode[] {
    return this._children as ContentNode[];
  }

  get prefix(): string {
    return ContentNode.pathPrefix || "";
  }

  path(): string {
    let prefix = ContentNode.pathPrefix || "";
    let s = `/${prefix}/${this.slug}`;
    return s;
  }

  absolutePath(): string {
    let baseURL = window.location.origin.toString();
    let subPath = this.path();

    return `${baseURL}${subPath}`;
  }

  privateLinkURL(): string {
    let baseURL = process.env.SYMPHONY_HOST;
    let appPath = RockDove.shared().getParentAppPathFor("/apps/publications");
    return `${window.location.protocol}//${baseURL}${appPath}/${this.slug}`;
  }

  findByPath(path: string) {
    // /apps/publications -> /
    let prefix = ContentNode.pathPrefix || "";
    path = path?.replace(prefix, "");
    if (path === undefined || path.length <= 1) {
      return this;
    }

    let pathComponents = path
      .split("/")
      .filter((component) => component.length > 0);
    let component = pathComponents[pathComponents.length - 1];

    return this.findBySlug(component);

    // return this.findByPathComponents(pathComponents);
  }
  /**
   * Find children (or this node) by looking at the first element of a path.
   * Full path components are needed because this recursively searches through the node tree unde this node (including this node)
   */
  findByPathComponents(pathComponents: string[]): ContentNode | undefined {
    // If this node is a leaf (no children) or we're out of path components to inspect, return this node
    if (
      this.children === undefined ||
      pathComponents.length <= 0 ||
      pathComponents[0] === undefined
    ) {
      return this;
    }
    // pathComponent should ultimately be a child slug.
    // pre-release-activities/pre-content/snippets
    let pathComponent = pathComponents[0];

    // If the path component is this slug, don't reject it, but shift it out and
    // look keep looking for children
    if (pathComponent === this.slug) {
      pathComponents.shift();
      return this.findByPathComponents(pathComponents);
    }

    //Find my child with the first part of the passed in path
    // let pathComponent = 'welcome';
    let matchingChildren = this.children?.filter(
      (child) => child.slug === pathComponent
    );
    if (matchingChildren.length > 0) {
      let child = matchingChildren[0];
      pathComponents.shift();
      // pre-content/snippets
      let c = child as ContentNode;
      return c.findByPathComponents(pathComponents);
    } else {
      Logger.of("App").info("DID NOT FIND CHILD PATH MATCHING", pathComponent);
    }

    return undefined;
  }

  siblingWithOrder(order: number): ContentNode | undefined {
    let p = this.parent;

    return p && p.children && p.children[order];
  }

  nextSibling(): ContentNode | undefined {
    let next = this.siblingWithOrder(this.order + 1);
    return next;
  }

  previousSibling(): ContentNode | undefined {
    return this.siblingWithOrder(this.order - 1);
  }

  print(level: number) {
    this.children?.forEach((childNode) => childNode.print(level + 1));
  }

  // // fromJSON is used to convert an serialized version
  // // of the User to an instance of the class
  // static fromJSON(json: ContentNodeJSON | string, parent?: ContentNode): ContentNode {
  //     if (typeof json === 'string') {
  //         // if it's a string, parse it first
  //         return JSON.parse(json, ContentNode.reviver);
  //     } else {
  //         // create an instance of the User class
  //         let n = Object.create(ContentNode.prototype);
  //         // copy all the fields from the json object
  //         let contentNode = Object.assign(n, json, {
  //             level: parent ? parent.level + 1 : 0,
  //             parent: parent
  //         });

  //         contentNode.id = json._id;
  //         contentNode.cardType = json.card_type;

  //         contentNode.children = json.children ? json.children.map((childJSON, index) => {
  //             let childNode = ContentNode.fromJSON(childJSON, contentNode)
  //             childNode.order = index;
  //             return childNode;
  //         }) : undefined;
  //         return contentNode;

  //     }
  // }

  // static reviver(key: string, value: any): any {
  //     return key === "" ? ContentNode.fromJSON(value) : value;
  // }

  humanReadable(value: string): string {
    //removing extraneous charactors that remove markdown isn't getting to.
    let newValue = value.split("*").join("");
    newValue = newValue.split("&nbsp").join("");
    return removeMd(newValue);
  }

  queryResultForQuery(query: ContentNodeQuery): ContentNodeQueryResult {
    let queryMatches: QueryMatch[] = [];

    Object.entries(this).forEach(([key, value]) => {
      let keyIsOk =
        query.keys.filter((queryKey) => queryKey === key).length > 0;

      if (keyIsOk && typeof value === "string") {
        // Remove markdown from the string
        let hrValue = this.humanReadable(value) as string;

        // Lowercase things to actually do the search, but don't save this hrValue
        let index = hrValue.toLowerCase().indexOf(query.query.toLowerCase());

        if (index! - -1) {
          let queryMatch = {
            key: key,
            humanReadableValue: hrValue,
            range: { index: index, length: query.query.length },
          };
          queryMatches.push(queryMatch);
        }
      }
    });

    let result = new ContentNodeQueryResult(this, queryMatches);

    return result;
  }

  queryResultsMatchingDefaults(stringValue: string): ContentNodeQueryResult[] {
    let defaultQuery = new ContentNodeQuery(stringValue, [
      "id",
      "title",
      "subtitle",
      "content",
    ]);
    let results = this.queryResultsMatchingQuery(defaultQuery);

    // sort the results by order of the ContentNodeQuery.keys
    return results.sort(
      (resultA: ContentNodeQueryResult, resultB: ContentNodeQueryResult) => {
        let val = 0;

        //Loop through all default keys (order matters), finding first to hit resultA and resultB
        defaultQuery.keys.some((key) => {
          let aMatch = resultA.matchedKey(key);
          let bMatch = resultB.matchedKey(key);

          if (aMatch || bMatch) {
            if (aMatch && bMatch) {
              val = 0;
            } else if (aMatch) {
              val = -1;
            } else {
              val = 1;
            }

            return true;
          }
          return false;
        });

        return val;
      }
    );
  }

  queryResultsMatchingQuery(
    query: ContentNodeQuery,
    matches: ContentNodeQueryResult[] = []
  ): ContentNodeQueryResult[] {
    if (matches === undefined) {
      matches = [];
    }

    let nodeQuery = this.queryResultForQuery(query);
    if (nodeQuery.didMatch) {
      matches.push(nodeQuery);
    }

    if (this.children) {
      this.children.forEach((childNode) =>
        childNode.queryResultsMatchingQuery(query, matches)
      );
    }

    return matches;
  }

  findById(id: string): ContentNode | undefined {
    var node;
    if (this.id === id) {
      return this;
    }
    if (this.children) {
      this.children.some((child) => (node = child.findById(id)));
    }
    return node;
  }

  findBySlug(slug: string): ContentNode | undefined {
    var node;
    if (this.slug === slug) {
      return this;
    }
    if (this.children) {
      this.children.some((child) => (node = child.findBySlug(slug)));
    }
    return node;
  }

  findChildContaining(contentNode: ContentNode): ContentNode | undefined {
    if (this.children) {
      let matchingChildren = this.children.filter((child) => {
        return contentNode.id && child.findById(contentNode.id) !== undefined
          ? child
          : undefined;
      });
      return matchingChildren[0];
    }
    return undefined;
  }

  hasParent(): boolean {
    return (
      (this.parentId && this.parentId.length > 0) ||
      this.parent !== undefined ||
      false
    );
  }

  getCrumbs = (
    contentNode: ContentNode = this,
    crumbs: Node[] = [this]
  ): ContentNode[] => {
    // this does NOT return the root node!
    if (contentNode?._parent?._parent) {
      crumbs?.push(contentNode._parent);
      return this.getCrumbs(contentNode._parent as ContentNode, crumbs);
    } else {
      return crumbs.reverse() as ContentNode[];
    }
  };

  getRoot = (contentNode: ContentNode = this): ContentNode => {
    if (contentNode?._parent) {
      return this.getRoot(contentNode._parent as ContentNode);
    } else {
      return contentNode;
    }
  };

  getTopParentBelowRoot = (
    contentNode: ContentNode = this
  ): ContentNode | undefined => {
    // if it has a parent
    if (contentNode._parent) {
      //check for grandparent
      if (contentNode._parent._parent) {
        // not there yet
        return this.getTopParentBelowRoot(contentNode._parent as ContentNode);
      } else {
        // if there is no grandparent, you've found the right one
        return contentNode as ContentNode;
      }
    } else {
      console.log("this contentNode doesn't have a parent");
      return undefined;
    }
  };
}

async function fetchContent() {
  // Have the API fetch /api/v1/content-node-root on behalf of an Artist user role
  return API.fetch("content-root-node", UserRole.Artist)
    .then((response: any) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Failed to get ok response fetching content");
      }
    })
    .then((json) => {
      let contentNode = NodeFactory.createNodeFromRaw(ContentNode, json);
      return contentNode;
    })
    .catch((e) => {
      Logger.of("App").info(
        "ContentProvider failed to fetchContent with error",
        e
      );
      throw e;
    });
}
export { fetchContent };

const bookmarksNode = () => {
  let n = new ContentNode({
    id: "bookmarks",
    identifier: "bookmarks",
    slug: "bookmarks",
    title: "Bookmarks",
    subtitle: "",
    description: "",
    type: "article",
    cardType: "bookmark",
    rawContent: "",
    parentId: "",
    icon: "",
    order: 0,
    published: true,
    level: 0,
  });
  n.showsInCards = false;
  return n;
};

export { bookmarksNode };
