import React, { useState } from "react";
import { Sprite, Graphics, withFilters, Container } from "@inlet/react-pixi";
import Block, { BlockParam, BlockUtils, ShapeType } from "../../models/Block";
import DraggableBlock from "./DraggableBlock";
import * as PIXI from "pixi.js";
import { TemplateEditorState } from "../../TemplateEditorState";

interface Props {
  block: Block;
  parentView: any;
  readOnly: boolean;
  editorState: TemplateEditorState;
  setEditorState: React.Dispatch<React.SetStateAction<TemplateEditorState>>;
  selectBlocks: any;
  selectBlock: any;
  composition: any;
  loader: any;
}
const blockValue = (value: any) => {
  if (value.charAt(0) === "/") return value;
  else return `${value}?cacheblock=true`;
};

export const addShapeBlockResources = (
  params: BlockParam,
  childBlock: Block,
  pixiLoader: any
) => {
  if (
    (params.key === "image" || params.key === "texture") &&
    childBlock.type === "SHAPE"
  ) {
    let blockKey =
      childBlock.id &&
      BlockUtils.keyForBlockLoader(
        childBlock.id,
        params.key,
        params.value.name || params.name
      );
    if (!pixiLoader.resources.blockKey && childBlock.params) {
      pixiLoader.add(
        blockKey,
        blockValue(BlockUtils.paramValueForKey(params.key, childBlock.params))
      );
    }
  }
};

export default function ShapeBlockRenderer(props: Props) {
  const [mask, setMask] = useState(null);

  const shapeType = props.block.shapeType as ShapeType;
  const shapeFrame = props.block.frame;
  const shapeStyle = props.block.style;

  const imageName = BlockUtils.paramNameForKey("image", props.block.params);
  const textureName = BlockUtils.paramNameForKey("texture", props.block.params);

  const shapeKey =
    props.block.id &&
    BlockUtils.keyForBlockLoader(props.block.id, "image", imageName);
  const textureKey =
    props.block.id &&
    BlockUtils.keyForBlockLoader(props.block.id, "texture", textureName);

  const shapeImage = shapeKey && props.loader?.resources[shapeKey]?.url;
  const shapeTexture = textureKey && props.loader?.resources[textureKey]?.url;

  const colorFill = BlockUtils.paramValueForKey("color", props.block.params);

  const colorByTextFill = BlockUtils.paramValuesByKey(
    "textColor",
    props.composition.blocks
  );
  const colorByShapeFill = BlockUtils.paramValuesByKey(
    "shapeColor",
    props.composition.blocks
  );

  const shapeColor = () => {
    if (colorByShapeFill) return colorByShapeFill;
    else if (colorByTextFill) return colorByTextFill;
    else if (colorFill) return colorFill;
    else return shapeStyle?.background?.color;
  };

  const draw = (g: any) => {
    g.clear();
    g.lineStyle(
      parseInt(shapeStyle?.border?.borderSize),
      `0x${shapeStyle?.border?.borderColor}`
    );
    g.beginFill(
      PIXI.utils.string2hex(
        shapeImage || shapeTexture ? undefined : shapeColor()
      )
    );
    switch (shapeType) {
      case ShapeType.rect:
        return g.drawRect(0, 0, shapeFrame?.size?.w, shapeFrame?.size?.h);
      case ShapeType.roundedRect:
        return g.drawRoundedRect(
          0,
          0,
          shapeFrame?.size?.w,
          shapeFrame?.size?.h,
          shapeFrame?.size?.radius
        );
      case ShapeType.circle:
        return g.drawCircle(0, 0, shapeFrame?.size?.radius);
      case ShapeType.ellipse:
        return g.drawEllipse(0, 0, shapeFrame?.size?.w, shapeFrame?.size?.h);
      case ShapeType.torus:
        return (
          g.drawCircle(0, 0, shapeFrame?.size?.outerRadius),
          g.beginHole(),
          g.drawCircle(0, 0, shapeFrame?.size?.innerRadius),
          g.endHole()
        );
      default:
        break;
    }
    g.endFill();
  };

  const Filters = withFilters(Container, {
    blur: PIXI.filters.BlurFilter,
  });

  const blurFilter = props.block?.style?.filter?.blur;

  return (
    <DraggableBlock {...props}>
      <Container mask={shapeImage && mask ? mask : null}>
        {shapeImage && shapeFrame?.size?.radius && (
          <>
            <Filters blur={{ blur: blurFilter }}>
              <Sprite
                texture={PIXI.Texture.from(shapeImage)}
                width={shapeFrame.size.radius * 3}
                height={shapeFrame.size.radius * 3}
                anchor={0.5}
              />
            </Filters>
            {shapeTexture && (
              <Sprite
                texture={PIXI.Texture.from(shapeTexture)}
                anchor={props?.block?.frame?.anchor}
              />
            )}
          </>
        )}
        <Graphics ref={(ref: any) => setMask(ref)} draw={draw} />
      </Container>
    </DraggableBlock>
  );
}
