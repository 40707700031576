import React from "react";
import styles from "./MultiTitle.module.scss";

import { ReactComponent as Pencil } from "../../../../../shared/icons/pencil.svg";
import DnDItem from "../../DnDInput/DnDItem/DnDItem";
import DnDWrapper from "../../DnDInput/DnDWrapper/DnDWrapper";
import MultiPill from "../../MultiPill/MultiPill";
import { JSONParseSafely } from "../../../../../shared/helpers/helpers";
import {
  MultiChildProps,
  MultiContainerProps,
} from "../../MultiInput/MultiInput";
import { useTemplatePageEditorContext } from "../../../TemplatePageEditor/TemplatePageEditor";
import { resolveMappedPath } from "../../../../../../asset-generator-lib/helpers";
import { MultiBlockParamPreData } from "../../../../../../asset-generator-lib/composer/models/Block";

const MultiTitle = ({
  sectionKey,
  param,
  setParam,
  editFn,
  deleteFn,
  selectedChildValue,
}: MultiContainerProps) => {
  const { setHighlightedKey } = useTemplatePageEditorContext();

  const onDragStart = () => {
    setHighlightedKey(sectionKey);
  };

  return (
    <DnDWrapper
      id={param?.key}
      items={param?.value}
      setItems={setParam}
      onDragStart={onDragStart}
    >
      {param?.value?.map(
        (childParam: MultiBlockParamPreData, index: number) => {
          const parsedChildValue =
            JSONParseSafely(childParam.value) || childParam.value;

          return (
            <DnDItem key={childParam.key} item={childParam} index={index}>
              <Title
                index={index}
                childParam={childParam}
                childValue={parsedChildValue}
                selectedChildValue={selectedChildValue}
                setParam={setParam}
                editFn={editFn}
                deleteFn={deleteFn}
              />
            </DnDItem>
          );
        }
      )}
    </DnDWrapper>
  );
};

const Title = ({
  childParam,
  childValue,
  selectedChildValue,
  index,
  editFn,
  deleteFn,
  dragProps,
}: MultiChildProps) => {
  // this will only get used below if it's not a string
  // and since we don't know what it is, we have to keep any
  const firstValue: any = Object.values(childValue)[0];

  // if value is a string, use it.
  // otherwise it's an obj, and we are going to try the title prop first,
  // if it doesn't exist, try firstValue.value
  let text: string =
    typeof childValue === "string"
      ? childValue
      : childValue?.title?.value || firstValue?.value;

  if (typeof text !== "string") {
    text = "error";
  }

  const mappedTitle =
    childParam &&
    childParam.descriptionPath &&
    resolveMappedPath(childParam.descriptionPath, childParam.value);

  return (
    <MultiPill
      multiFile={{
        dragProps,
        index,
        selected: childParam.key === selectedChildValue?.key,
      }}
      remove={() => deleteFn(childParam?.key)}
    >
      <div className={styles["container"]}>
        <div className={styles["index"]}>
          {" "}
          {(index + 1).toLocaleString("en-us", { minimumIntegerDigits: 2 })}
        </div>

        <p className={`ellipsis ${styles["text"]}`}>{mappedTitle || text}</p>

        <button
          className="btn--naked"
          onClick={() => {
            editFn(childParam.key);
          }}
        >
          <Pencil className={styles["icon"]} />
        </button>
      </div>
    </MultiPill>
  );
};

export default MultiTitle;
