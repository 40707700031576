import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { usePlatoonArtistContext } from "../providers/PlatoonArtistProvider";
interface Props {
  basePath: string;
  subPath: string;
}
export default function ArtistRedirect({ basePath, subPath }: Props) {
  const { p4aArtist } = usePlatoonArtistContext();

  const [target, setTarget] = React.useState<string | null>();

  useEffect(() => {
    if (p4aArtist.id) {
      setTarget(`${basePath}/artists/${p4aArtist.id}/${subPath}`);
    }
  }, [p4aArtist.id]);

  return target ? <Navigate to={target} /> : <div>Redirecting</div>;
}
