// import isEqual from "react-fast-compare";
import clone from "just-clone";
import {
  TemplatePageSection,
  TemplatePageBlockParam,
} from "../../../asset-generator-lib";
import { TemplatePageDraft } from "../types/TemplatePages";
import { Action } from "./templatePageReducer";
import { BlockBase } from "../../../asset-generator-lib/composer/models/Block";

export type TemplatePageConfig = { [key: string]: TemplatePageBlockParam };

export const updateSection = (
  input: TemplatePageConfig,
  templatePageDraft: TemplatePageDraft,
  dispatchTemplatePage: React.Dispatch<Action>
) => {
  const sectionKey = Object.keys(input)[0];
  const paramKey = input[sectionKey].key;

  // cloning here wasn't allowing for correct state updates, it was overwriting section values
  // i.e. the header section artistName & artistSocials
  // was only allowing the last paramKey to have a value on initial load
  // this is because we are mutating data in real time, since multiple things can be updated at once
  const updateTemplateConfig = templatePageDraft.templateConfig;

  if (updateTemplateConfig.find((item) => item.key === sectionKey)) {
    // this is doing the find twice, but it ensures we have a section to update
    // I used the ! to avoid the error on the next line'
    // there's no reason to ever have an undefined here though

    updateTemplateConfig.find((item) => item.key === sectionKey)!.params[
      paramKey
    ] = input[sectionKey];
  }

  dispatchTemplatePage({
    type: "UPDATE_DRAFT", // used to be UPDATE_TEMPLATECONFIG
    payload: { ...templatePageDraft, templateConfig: updateTemplateConfig },
  });
};

export const blockConfigFromBlock = (block: TemplatePageSection) => {
  let config = {} as TemplatePageConfig;
  block?.params?.forEach((param) => {
    config[param.key] = clone(param);
  });
  return config;
};

export const blockIsHeader = (block: BlockBase) => {
  // We may need to write more helpers like this as V2 of things start popping up
  return block.key === "HEADER" || block.key === "HEADER_V2";
};
