import React, { useState, useEffect } from "react";
import styles from "./ColorInput.module.scss";
import { BlockParam } from "../../../../../asset-generator-lib";

type Props = {
  param: BlockParam;
  update: (input: BlockParam | string) => void;
  subField?: boolean;
};

const ColorInput = ({ param, update, subField }: Props) => {
  const [value, setValue] = useState<string>(param.value || "#000000");
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    if (value != "") {
      update({ ...param, value: value });
    }
  }, [value]);

  const handleOnChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <div className={styles["inputContainer"]}>
      {!subField && <div className={"label"}>{param?.name}</div>}

      <div
        className={`${styles["container"]} ${
          focused && styles["container--active"]
        }`}
      >
        <div className={styles["wrapper"]}>
          <div
            className={styles["color-dot"]}
            style={{ backgroundColor: value }}
          />
          <span className={styles["hex-value"]}>{value}</span>
        </div>
        <input
          className={styles["input"]}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          name={param.name}
          value={value}
          type="color"
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

export default ColorInput;
