import React, { useContext } from "react";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { Button, makeStyles } from "@material-ui/core";
import CopyToClipBoard from "react-copy-to-clipboard";
import { SnackContext } from "../SnackProvider";

interface Props {
  text: string;
  link: string;
  width: Breakpoint;
}

type StyleProps = {
  width: Breakpoint;
};

const useStyles = makeStyles(() => ({
  copyButtonWrapper: {
    display: "flex",
    justifyContent: (props: StyleProps) =>
      isWidthUp("md", props.width) ? "center" : "flex-start",
  },
}));

const CopyLink = ({ link, text, width }: Props) => {
  const { setSnack } = useContext(SnackContext); // this isnt available everywhere

  const classes = useStyles({ width });

  const copyLink = (event: any) => {
    event.stopPropagation();
    setSnack({ message: "Link copied" });
  };

  return (
    <div className={classes.copyButtonWrapper}>
      <CopyToClipBoard text={link}>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={copyLink}
        >
          {text}
        </Button>
      </CopyToClipBoard>
    </div>
  );
};

export default withWidth()(CopyLink);
