import React, { useState, useEffect, useContext } from "react";
import { Logger } from "../shared/SafeLogger";
// import { usePrompt } from "react-router-dom";
import { ContentEditor } from "../platoon-cms-lib";
import Alert from "@material-ui/lab/Alert";
import NodeTree from "./NodeTree";
import NodeEditHeader from "./NodeEditHeader";
import NodeAttributePane from "./NodeAttributePane";
import { Divider, Grid } from "@material-ui/core";
import { SnackContext } from "../shared/SnackProvider";
import { makeStyles, Theme } from "@material-ui/core/styles";
import API, { ResponseError } from "../shared/API";
import NodeSchema, { CMSNode } from "./NodeSchema";
import { getRelations, postRelationsForChildren } from "./Relation";
import NodeMassCreateDelete from "./NodeMassDelete";
import NodeMassCreate from "./NodeMassCreate";
import Loading from "../shared/Loading";
import { useParams, useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  gridPadding: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  contentGrid: {
    marginTop: theme.spacing(10),
  },
  contentEditorWrapper: {
    position: "relative",
    marginTop: "25px",
    backgroundColor: "#191919",
    padding: "24px 60px",
    maxWidth: "850px",
    margin: "auto",
    borderRadius: "4px",
  },
  editorWrapper: {
    padding: "20px",
    minHeight: "100vh",
    backgroundColor: "#000",
  },
  contentHeading: {
    color: "#ffffff",
    margin: 0,
    fontSize: "21px",
    fontFamily: "Futura",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  subtitle: {
    fontFamily: "Helvetica",
    fontSize: "10px",
    fontWeight: "bold",
    color: "#ff4169",
    margin: "6px 0",
    textTransform: "uppercase",
  },
}));

const NodeEdit = () => {
  // Get the optional nodeId
  const { id: nodeId } = useParams();

  // get locale search param
  const [searchParams] = useSearchParams();
  const locale = searchParams.get("locale");

  const schema = NodeSchema;
  const classes = useStyles();

  const [originalNode, setOriginalNode] = useState<CMSNode | undefined>();
  const [node, setNode] = useState<CMSNode | undefined>();
  const [responseError, setResponseError] = useState<ResponseError>();
  const [loading, setLoading] = useState(true);
  const { setSnack } = useContext(SnackContext);
  const [isDirty, setIsDirty] = useState(false);
  const [cardTypes, setCardTypes] = useState<string[]>([]);
  const [items, setItems] = React.useState<any>();
  const [originalItems, setOriginalItems] = React.useState<any>();

  // This is used by ContentEditor and SHOULD NOT CHANGE between loading a node and it being reloaded.
  const [originalRawContent, setOriginalRawContent] = React.useState<
    undefined | string
  >(undefined);

  if (locale) {
    // If we find a query param like ?locale=de, adjust the schema since that will govern API calls
    schema.locale = locale;
  }

  const loadIfNeeded = () => {
    setLoading(true);

    nodeId
      ? API.read(nodeId, schema).then(handleNode).catch(handleError)
      : API.fetchAPI("content-root-node-all", schema)
          .then(handleNode)
          .catch(handleRootError);
  };

  function handleNode(node: CMSNode) {
    setLoading(false);
    setOriginalRawContent(undefined);
    setOriginalNode(node);
    setNode(node);
    setOriginalItems(node.children);
    setItems(node.children);
    setOriginalRawContent(node.rawContent);

    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }

  // load content
  useEffect(() => {
    loadIfNeeded();
  }, [nodeId]);

  const checkIsDirty = () => {
    setIsDirty(nodeIsDirty() || relationsAreDirty());
  };

  function nodeIsDirty() {
    return node && originalNode && !node.equals(originalNode);
  }

  function relationsAreDirty() {
    if (node && originalNode) {
      let relations = getRelations(items, node);
      let originalRelations = getRelations(originalItems, node);
      let itemsAreEqual =
        JSON.stringify(relations) === JSON.stringify(originalRelations);
      return !itemsAreEqual;
    }
    return false;
  }

  // check if the form is dirty
  useEffect(() => checkIsDirty(), [node, items]);

  // fetch card types
  useEffect(() => {
    let isCurrent = true;

    fetch("/api/v1/nodes/types")
      .then((response) => response.json())
      .then((obj) => {
        if (isCurrent) {
          setCardTypes(obj.card_types);
        }
      });

    return () => {
      isCurrent = false;
    };
  }, []);

  // save changes
  const save = () => {
    if (!node) {
      return;
    }

    setLoading(true);

    var promises = [];

    if (relationsAreDirty()) {
      let p = new Promise((resolve, reject) => {
        return postRelationsForChildren(items, node)
          .then(resolve)
          .catch(reject);
      });

      promises.push(p);
    }

    if (nodeIsDirty()) {
      let p = new Promise((resolve, reject) => {
        return updateNode(node).then(resolve).catch(reject);
      });
      promises.push(p);
    }

    Promise.all(promises)
      .then(() => {
        loadIfNeeded();
        setSnack({ message: `Saved ${schema.title}` });
        setResponseError(undefined);
        setLoading(false);
      })
      .catch(handleError);
  };

  const updateNode = (node: CMSNode) => {
    if (node.id) {
      const formData = node?.toFormData();

      return API.update(node.id, formData, schema);
    } else {
      throw new Error("Could not update page because it has no id");
    }
  };

  const handleError = (error: ResponseError | Error) => {
    setLoading(false);
    Logger.of("App").info("handleError:", error);
    let candidateResponseError = error as ResponseError;
    if (candidateResponseError.reasons !== undefined) {
      Logger.of("App").info("Caught error:", candidateResponseError);
      setResponseError(candidateResponseError);
    } else {
      setSnack({ message: error.message });
    }
  };

  const handleRootError = (error: ResponseError | Error) => {
    setLoading(false);
    Logger.of("App").info("handleRootError:", error);
  };

  const handleRawContentChange = (rawContent: any) => {
    if (!node?.rawContent) {
      // If the current node has rawContent = "", that means it's brand new
      // so we should take this opportunity to update the original node
      // to ensure state is NOT dirty when we also setNode below
      let ogNode = node?.copyNode() as any;
      ogNode.rawContent = rawContent;
      setOriginalNode(ogNode);
    }

    //Always update the current node's rawContent
    let newNode = node?.copyNode() as any;
    newNode.rawContent = rawContent;
    setNode(newNode);
  };

  // TODO: usePrompt / useBlocker doesn't exist in RRD v6

  // usePrompt(
  //   "There are unsaved changes, are you sure you want to continue?",
  //   isDirty
  // );

  // this structure is not right, if made to be a react component, it goes into an infinite loop
  const nodeView = (node: CMSNode) => (
    <Grid
      className={classes.gridPadding}
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
    >
      {/* <Prompt
        when={isDirty}
        message={() =>
          `There are unsaved changes, are you sure you want to continue?`
        }
      /> */}

      <NodeEditHeader node={node} onSave={save} isDirty={isDirty} />

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={6}
      >
        <Divider />
        <Grid className={classes.contentGrid} item xs={9}>
          <div>
            {node.type === "article" ? (
              <div className={classes.editorWrapper}>
                <div className={classes.contentEditorWrapper}>
                  <div>
                    <h1 className={classes.contentHeading}>{node.title}</h1>
                    <h2 className={classes.subtitle}>{node.subtitle}</h2>
                  </div>
                  {originalRawContent !== undefined && (
                    <ContentEditor
                      readOnly={false}
                      initialRawContent={originalRawContent}
                      onRawContentChange={handleRawContentChange}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div>
                {node && node.id && node.id.length > 0 && (
                  <NodeTree
                    reload={loadIfNeeded}
                    rootNode={node}
                    items={items}
                    setItems={setItems}
                  />
                )}
              </div>
            )}
          </div>
        </Grid>

        <Grid className={classes.contentGrid} item xs={3}>
          <NodeAttributePane
            node={node}
            setNode={setNode}
            responseError={responseError}
            cardTypes={cardTypes}
          />
          {process.env.NODE_ENV != "production" && (
            <NodeMassCreateDelete node={node} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  function BackupView() {
    return loading ? (
      <Loading height="80px" size={80} />
    ) : (
      <Alert severity="error">
        Page Not Found. &nbsp;&nbsp;
        {process.env.NODE_ENV != "production" && <NodeMassCreate />}
      </Alert>
    );
  }

  return node ? nodeView(node) : <BackupView />;
};

export default NodeEdit;
