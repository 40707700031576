import React, { useState } from "react";
import ResourceSchema, { ResourceActions } from "./ResourceSchema";
import SearchBar from "material-ui-search-bar";
import {
  Grid,
  Theme,
  Tooltip,
  Typography,
  Fab,
  makeStyles,
} from "@material-ui/core";

import { Add as AddIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  gridPadding: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  titlesWrapper: {
    display: "flex",
    whiteSpace: "nowrap",
  },
  actionLink: {
    marginLeft: "10px",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  stickyHeader: {
    position: "fixed",
    width: "calc(100% - 260px)",
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    padding: theme.spacing(2),
  },
  header: {
    // position: 'fixed',
    // width: 'calc(100% - 260px)',
    padding: theme.spacing(2),
  },
  addIconButton: {
    boxShadow: "none",
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main}80`,
    },
  },
  itemTitle: {
    maxWidth: "80%",
  },
}));

interface Props {
  onSearch: any;
  schema: ResourceSchema;
  actions: ResourceActions;
}

export default function ItemIndexHeader({ onSearch, schema, actions }: Props) {
  const classes = useStyles();

  const [internalQuery, setInternalQuery] = useState<string | undefined>(
    undefined
  );

  function executeSearch() {
    onSearch(internalQuery);
  }

  function cancelSearch() {
    setInternalQuery(undefined);
    onSearch(undefined);
  }

  function addItem() {
    //TODO: implement
  }

  return (
    <Grid
      direction="row"
      justify="space-between"
      alignItems="center"
      container
      className={classes.header}
    >
      <Typography variant="h6">{schema.pluralTitle}</Typography>

      <SearchBar
        value={internalQuery}
        onChange={setInternalQuery}
        onRequestSearch={executeSearch}
        onCancelSearch={cancelSearch}
      />

      {actions.create && (
        <Tooltip title={`New ${schema.title}`} aria-label="new" placement="top">
          <Fab
            className={classes.addIconButton}
            onClick={addItem}
            color="secondary"
            aria-label="add"
            size="small"
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
    </Grid>
  );
}
