import React from "react";
import styles from "./PromoteIndex.module.scss";
import Layout from "../../shared/Layout/Layout";
import PromoteCard from "./PromoteCard/PromoteCard";
import PromoteLearnCard from "./PromoteLearnCard/PromoteLearnCard";
import { cardsData } from "./config/cardsData";
import { learnLinksData } from "./config/learnLinksData";

const PromoteIndex = () => {
  const cards = cardsData.map((card) => (
    <PromoteCard key={card.title} cardData={card} />
  ));

  const learnLinkCards = learnLinksData.map((card) => (
    <PromoteLearnCard key={card.title} cardData={card} />
  ));

  return (
    <Layout style={{ maxWidth: "820px", paddingTop: "0" }}>
      <main className={styles["main"]}>
        <h1 className={styles["h1"]}>Promote</h1>
        <hr className={styles["hr"]} />
        <p className={styles["p"]}>
          Build content to help you promote your new and prior releases.
        </p>
        <section className={styles["card-container"]}>{cards}</section>
        <hr className={styles["hr"]} />
        <h2 className={styles["h2"]}>Useful Sections</h2>
        <p className={styles["p"]}>
          Be sure to check out resources on best promotional practices in the
          Release Playbook.
        </p>
        <section className={styles["link-card-container"]}>
          {learnLinkCards}
        </section>
      </main>
    </Layout>
  );
};

export default PromoteIndex;
