import React from "react";
import { Box } from "@material-ui/core";
import ArtistFileInput from "./ArtistFileInput/ArtistFileInput";

const AlbumArtwork = () => {
  return (
    <Box p={1} pl={3} pr={3} style={{ color: "white" }}>
      <ArtistFileInput
        artistLinkKey="albumArtwork"
        artistLinkKeyId="albumArtworkID"
        accepts="image/png, image/jpeg"
      />
    </Box>
  );
};

export default AlbumArtwork;
