import React from "react";
import ArtistLinkInput from "../LinkEditComponents/ArtistLinkInput";
import ArtistLinkInputHeader from "../LinkEditComponents/ArtistLinkInputHeader";
import ArtistLinkFontPicker from "../LinkEditComponents/ArtistLinkFontPicker";
import ArtistLinkColorPicker from "../LinkEditComponents/ArtistLinkColorPicker";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import { Update } from "../ArtistLinkEdit/FormFields";

interface Props {
  onChange: (update: Update) => void;
}

const HeaderDescription = (props: Props) => {
  const { artistLink } = useArtistLinkContext();

  const artistDescriptionValue = () => {
    let description = artistLink.description;
    if (description && description?.length > 1400) {
      return description.substring(0, 1400);
    } else return description;
  };

  const artistHeadlineValue = () => {
    let headline = artistLink.headline;
    if (headline && headline?.length > 50) {
      return headline.substring(0, 50);
    } else return headline;
  };

  return (
    <>
      <ArtistLinkInputHeader header={"HEADLINE FONT"} variant={"h6"} />

      <ArtistLinkFontPicker artistLinkKey={"headlineFont"} />

      <ArtistLinkInputHeader header={"HEADLINE"} variant={"h6"} />

      <ArtistLinkInput
        name={"headline"}
        id={"headline"}
        label={"Add Headline"}
        onChange={props.onChange}
        artistLinkKey={artistHeadlineValue()}
        characterCount={artistHeadlineValue()?.length}
        characterLimit={100}
      />

      <ArtistLinkInputHeader header={"DESCRIPTION FONT"} variant={"h6"} />

      <ArtistLinkFontPicker artistLinkKey={"descriptionFont"} />

      <ArtistLinkInputHeader header={"DESCRIPTION"} variant={"h6"} />

      <ArtistLinkInput
        name={"description"}
        id={"description"}
        multiline={true}
        type={"text"}
        label={"Add Description"}
        onChange={props.onChange}
        characterCount={artistDescriptionValue()?.length}
        characterLimit={1400}
        artistLinkKey={artistDescriptionValue()}
      />

      <ArtistLinkInputHeader header={"TEXT/ICON COLOR"} variant={"h6"} />

      <ArtistLinkColorPicker
        artistLinkKey={artistLink.textColor}
        value={"textColor"}
        defaultValue={"#FFFFFF"}
      />
    </>
  );
};

export default HeaderDescription;
