import { Logger } from "../../shared/SafeLogger";
import API from "../../shared/API";
import PromoPackSchema from "../schemas/PromoPackSchema";
import { TemplateConfig } from "../../../asset-generator-lib";
import { Asset } from "../../link-builder/models/ArtistLink";

type PromoPack = {
  id: string;
  name: string;
  p4aReleaseId: string;
  release?: any;
  templateIdentifier: string;
  templateConfig: TemplateConfig;
  marketingMessage: string;
  thumbnail: string | undefined;
  artist: Artist;
  createdAt: Date;
  updatedAt: Date;
  promoAssets: Asset[];
  assetsFinishedAt?: Date; //we write this value when we want to call assetsFinished
  assetsFinished: Boolean; //readonly: don't try to write this value, use assetsFinishedAt
};

interface Artist {
  id: string;
  p4aArtistId: string;
  name: string;
  slug: string;
}

const PromoPackAPI = {
  index: getResources,
  query: getResourceQueryResponse,
  read: getResource,
  create: postResource,
  update: patchResource,
  delete: deleteResource,
  clone: cloneResource,
};

function schema(artistId: string, dataIsNested: boolean = false) {
  let schema = { ...PromoPackSchema };
  schema.apiRootPath = `artists/${artistId}/promo-packs`;
  schema.dataIsNested = dataIsNested;
  return schema;
}

function cloneResource(artistId: string, resource: PromoPack) {
  Logger.of("App").info("artistId", artistId, "resource", resource);
  return API.clone(resource, schema(artistId));
}

function postResource(artistId: string, resource: PromoPack) {
  Logger.of("App").info("artistId", artistId, "resource", resource);
  return API.create(resource, schema(artistId));
}

function patchResource(artistId: string, resource: PromoPack, id: string) {
  return API.update(id, resource, schema(artistId));
}

function deleteResource(artistId: string, resource: any) {
  Logger.of("App").info("resource to be deleted", resource);
  return API.delete(artistId, schema(resource, false));
}

function getResources(artistId: string) {
  return API.index(schema(artistId, true));
}

function getResourceQueryResponse(artistId: string, page = 0) {
  return API.query(schema(artistId, true), page);
}

function getResource(artistId: string, artistLinkId: string) {
  return API.read(artistLinkId, schema(artistId, false));
}

function emptyPromoPack() {
  return {
    id: "",
    name: "",
    p4aReleaseId: "",
    release: {},
    templateIdentifier: "",
    templateConfig: {},
    marketingMessage: "On May 25th, 2021", // can we remove this?
    thumbnail: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    artist: {
      // can we remove this?
      id: "",
      p4aArtistId: "",
      name: "DJ MAYHEM",
      slug: "",
      avatar: "https://picsum.photos/1080/1080",
    },
    promoAssets: [],
    assetsFinished: false,
  } as PromoPack;
}

function deepCopyPromoPack(promoPack: PromoPack) {
  var pp = { ...promoPack };
  pp.artist = { ...promoPack.artist };
  return pp;
}

export {
  PromoPackAPI,
  emptyPromoPack,
  deepCopyPromoPack,
  Artist,
  TemplateConfig,
};

export default PromoPack;
