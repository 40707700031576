import React, { useRef, useState } from "react";
import AssetBlock, { AssetFrame } from "./AssetBlock";
import { AssetType } from "../../../models/Asset";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

type FramedAssetProps = {
  assetBlock: AssetBlock;
};

type StyleProps = {
  height: string;
  width: string;
  none?: boolean;
};

const useStyles = makeStyles(() => ({
  assetFrame: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "450px",
    width: "auto",
    height: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  asset: {
    maxHeight: ({ height }: StyleProps) => `${height}`,
    maxWidth: ({ width }: StyleProps) => `${width}`,
    position: "absolute",
    margin: "auto",
    width: "auto",
    height: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  playIcon: {
    position: "absolute",
    margin: "auto",
    maxHeight: "75px",
    maxWidth: "75px",
    width: "auto",
    height: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    color: "#fff",
  },
  container: {
    width: "fit-content",
    margin: "auto",
    position: "relative",
    minWidth: ({ none }: StyleProps) => (none ? "350px" : "unset"),
    minHeight: ({ none }: StyleProps) => (none ? "175px" : "unset"),
  },
  frameWrapper: {
    position: "relative",
    margin: "auto",
    width: "fit-content",
    height: "100%",
    maxWidth: "580px",
    maxHeight: "450px",
    minWidth: ({ none }: StyleProps) => (none ? "350px" : "unset"),
    minHeight: ({ none }: StyleProps) => (none ? "175px" : "unset"),
  },
}));

const assetAdjustments = (frame: AssetFrame | undefined) => {
  switch (frame) {
    case AssetFrame.MacBook:
      return { width: "75%", height: "80%" };
    case AssetFrame.iPhonePortrait:
      return { width: "80%", height: "75%" };
    case AssetFrame.iPhoneLandscape:
      return { width: "85%", height: "80%" };
    case AssetFrame.iPadLandscape:
      return { width: "90%", height: "90%" };
    case AssetFrame.iPadPortrait:
      return { width: "85%", height: "85%" };
    default:
      return { width: "100%", height: "100%", none: true };
  }
};

const FramedAsset = (props: FramedAssetProps) => {
  const classes = useStyles({
    ...assetAdjustments(props.assetBlock.frame),
  });
  const reactPlayer = useRef<any>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLocal, setIsLocal] = useState(false);

  const assetLoaded = () => {
    if (reactPlayer.current.getInternalPlayer().playerInfo === undefined) {
      setIsLocal(true);
    } else {
      setIsLocal(false);
    }
  };

  const togglePlaying = () => {
    setIsPlaying(!isPlaying);
  };

  const imgUrl =
    props.assetBlock?.assetPickerSource === "url"
      ? props.assetBlock.asset?.attachment
      : `/api/v1/signed-assets/${props.assetBlock?.asset?.id}/medium`;

  return (
    <div className={classes.frameWrapper}>
      {props.assetBlock.assetType === AssetType.image ? (
        <React.Fragment>
          {props.assetBlock.frame !== AssetFrame.none && (
            <img className={classes.assetFrame} src={props.assetBlock.frame} />
          )}
          <img className={classes.asset} src={imgUrl} />
        </React.Fragment>
      ) : (
        <div onClick={togglePlaying} className={classes.container}>
          {props.assetBlock.frame !== AssetFrame.none && (
            <img className={classes.assetFrame} src={props.assetBlock.frame} />
          )}
          {!isPlaying && isLocal && (
            <PlayCircleFilledIcon
              className={classes.playIcon}
              onClick={togglePlaying}
            />
          )}
          <ReactPlayer
            className={classes.asset}
            url={props.assetBlock?.asset?.attachment}
            ref={reactPlayer}
            onEnded={togglePlaying}
            onReady={assetLoaded}
            playing={isPlaying}
            width="100%"
            height="100%"
          />
        </div>
      )}
    </div>
  );
};

export default FramedAsset;
