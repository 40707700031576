import { useQuery } from "react-query";
import RockDove from "../../shared/RockDove";
import { UserRole } from "../../shared/ResourceSchema";
import { Template, TemplateAdaptor } from "../types/Templates";

let BASE_PATH = `/api/v1`;

const apiCall = (endpoint: string, headers: any) => {
  return fetch(endpoint, {
    method: "GET",
    credentials: "include",
    mode: "cors",
    headers,
  })
    .then((res) => res.json())
    .catch((e) => console.error("😡 CATCH", { e }));
};

export const getTemplate = (
  templateSlug: string | undefined,
  isNewTemplatePage: boolean
) => {

  const url = `${BASE_PATH}/templates/${templateSlug}`;
  const response = useQuery<Template>(
    ["template", templateSlug],
    async () => {
      const authHeaders = await RockDove.shared().getHeaders(UserRole.Artist);
      return apiCall(url, authHeaders).then(TemplateAdaptor.toFrontend)
    },
    {
      enabled: !!isNewTemplatePage,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    }
  );
  return { ...response };
};
