import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import useWindowSize from "../../shared/CustomHooks";
import Linkify from "react-linkify";
import ArtistAudioDownload from "./ArtistAudioDownload";
import { DownloadButtonPosition } from "../models/ArtistLink";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

type Props = {
  fontColor: string;
  displayMode: string;
  preview: boolean;
  setOpenDescription: React.Dispatch<React.SetStateAction<boolean>>;
};

type StyleProps = {
  headlineFont: string | undefined;
  descriptionFont: string | undefined;
  fontColor: string;
  isMobile: boolean;
  reverseRow: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  headlineDescriptionContainer: {
    height: "100%",
    maxWidth: "625px",
    margin: "auto",
    justifyContent: "center",
  },
  headlineDescriptionWrapper: {
    marginTop: "1.5%",
    height: "auto",
    width: "100%",
    justifyContent: "center",
  },
  headlineContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    justifyContent: "center",
    flexDirection: ({ reverseRow }: StyleProps) =>
      reverseRow ? "row-reverse" : "row",
  },
  descriptionContainer: {
    display: "flex",
  },
  descriptionText: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: ({ isMobile }: StyleProps) => (isMobile ? "1.5vh" : "18px"),
    lineHeight: 1.75,
    color: ({ fontColor }: StyleProps) => fontColor,
    fontFamily: ({ descriptionFont }: StyleProps) => descriptionFont,
    padding: theme.spacing(1),
    paddingBottom: "4px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    whiteSpace: "pre-line",
  },
  headlineText: {
    width: "100%",
    fontSize: ({ isMobile }: StyleProps) => (isMobile ? "2vh" : "32px"),
    color: ({ fontColor }: StyleProps) => fontColor,
    fontFamily: ({ headlineFont }: StyleProps) => headlineFont,
    fontWeight: 500,
    paddingBottom: theme.spacing(0),
    overflowWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  truncationText: {
    textAlign: "end",
    paddingRight: theme.spacing(4),
    color: ({ fontColor }: StyleProps) => fontColor,
    fontSize: ({ isMobile }: StyleProps) => (isMobile ? "1.25vh" : "14px"),
    justifyContent: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
    opacity: "60%",
    "&:hover": {
      opacity: "25%",
    },
  },
  inlineLink: {
    color: ({ fontColor }: StyleProps) => `${fontColor}B3`,
  },
}));

export default function ArtistHeadlineDescriptionSection({
  fontColor,
  displayMode,
  setOpenDescription,
  preview,
}: Props) {
  const { artistLink } = useArtistLinkContext();
  const width = useWindowSize().width;
  const height = useWindowSize().height;

  const headlineFont = artistLink.headlineFont;
  const descriptionFont = artistLink.descriptionFont;
  const isMobile = height < 1200 || width < 425;
  const reverseRow =
    artistLink.audioDownloadButtonPosition === DownloadButtonPosition.left;

  const classes = useStyles({
    headlineFont,
    descriptionFont,
    fontColor,
    isMobile,
    reverseRow,
  });

  const hasDownloadOption = artistLink.requiresAudioDownloadPassword;

  const [truncateDescription, setTruncateDescription] = useState(false);

  const handleTruncationClick = () => {
    setOpenDescription(true);
  };

  useEffect(() => {
    const description = document.getElementById("descriptionTruncate");
    shouldDescriptionTruncate(description);
  }, [width, artistLink.description, displayMode]);

  const shouldDescriptionTruncate = (description: any) => {
    let truncateDescription =
      description.offsetHeight < description.scrollHeight ||
      description.offsetWidth < description.scrollWidth;
    setTruncateDescription(truncateDescription);
  };

  const componentDecorator = (href: any, text: any, key: any) => (
    <a
      className={classes.inlineLink}
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i>{text}</i>
    </a>
  );

  return (
    <Grid item xs={12} style={{ height: "100%" }}>
      <Grid container className={classes.headlineDescriptionContainer}>
        <div className={classes.headlineDescriptionWrapper}>
          <div className={classes.headlineContainer}>
            {artistLink.headline && (
              <div className={classes.headlineText}>{artistLink.headline}</div>
            )}
            {hasDownloadOption && (
              <ArtistAudioDownload
                artistLink={artistLink}
                preview={preview}
                fontColor={fontColor}
                isMobile={isMobile}
              />
            )}
          </div>

          <div className={classes.descriptionContainer}>
            <div id="descriptionTruncate" className={classes.descriptionText}>
              <Linkify componentDecorator={componentDecorator}>
                {artistLink.description}
              </Linkify>
            </div>
          </div>

          {truncateDescription && (
            <div
              onClick={handleTruncationClick}
              className={classes.truncationText}
              style={{ color: fontColor }}
            >
              See More
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
