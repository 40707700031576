import React from "react";
import styles from "./SelectInput.module.scss";
import { BlockParam } from "../../../../../asset-generator-lib";
import TemplatePageFormRenderer from "../../TemplatePageFormRenderer/TemplatePageFormRenderer";
import { TemplatePageDraft } from "../../../types/TemplatePages";
import { useEffectOnce } from "react-use";

type Props = {
  param: BlockParam;
  update: (input: BlockParam | string) => void;
  subField?: boolean;
  sectionKey: string;
  templatePageDraft: TemplatePageDraft;
  path: string[];
};

const SelectInput = ({
  param,
  update,
  subField,
  sectionKey,
  templatePageDraft,
  path,
}: Props) => {
  // State now comes from params
  const value = param.value || (param.options && param.options[0]);
  const [selectionCache, setSelectionCache] = React.useState<{
    [item: string]: BlockParam;
  }>({ [value.key]: value });

  useEffectOnce(() => {
    if (!param.value && param.options && param.options[0]) {
      setValue(param.options[0]);
    }
  });

  const setValue = (newValue: any) => {
    // Examples:
    // newValue: {key: "on", name: "on", type: "PASSWORD", value: "", validations: Object}
    // value: {key: "off", name: "off", type: "STATIC", value: null}
    if (newValue) {
      const v = valueAdd(newValue);
      const s = { ...selectionCache };
      s[newValue.key] = newValue;
      setSelectionCache(s);
      update(v);
    }
  };

  const valueAdd = (value: BlockParam) => {
    var p = { ...param, value: value };
    return p;
  };

  const childUpdate = (subValue: string) => {
    if (value) {
      setValue(subValue);
    }
  };

  // JJ -- using opt.key instead of JSON.stringify my break nested comparisons
  // see commit 93df8c3e1323568c08abce05acc80bf1e0afcca4 for where/how jay might have broken this
  const handleOnChange = (event: any) => {
    const itemKey = event.target.value;
    const item = param.options?.find((opt) => opt.key === itemKey)!;
    // If doing selection cache stuff
    const selectionCacheItem = selectionCache[itemKey];

    setValue(selectionCacheItem || item);
  };

  return (
    <>
      {!subField && <div className={"label"}>{param?.name}</div>}
      <select
        id={param?.key}
        className="select"
        value={value.key}
        onChange={handleOnChange}
      >
        {param.options &&
          param.options.map((item: BlockParam) => {
            return (
              <option key={item.key} value={item.key}>
                {item.name}
              </option>
            );
          })}
      </select>
      {
        <div className={styles.children}>
          <TemplatePageFormRenderer
            blockParams={[value]}
            update={childUpdate}
            disabled={true}
            templatePageDraft={templatePageDraft}
            subField={true}
            sectionKey={sectionKey}
            path={path}
          />
        </div>
      }
    </>
  );
};

export default SelectInput;
