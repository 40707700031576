import React from "react";
import { Logger } from "../../shared/SafeLogger"
import { Box } from "@material-ui/core";
import ArtistLinkInputHeader from "../LinkEditComponents/ArtistLinkInputHeader";
import ArtistLinkRadioInput from "../LinkEditComponents/ArtistLinkRadioInput";
import ArtistLinkInputDateTimePicker from "../LinkEditComponents/ArtistLinkInputDateTimePicker";
import ArtistLinkInput from "../LinkEditComponents/ArtistLinkInput";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";

const LaunchDate = () => {
  const { artistLink } = useArtistLinkContext();
  return (
    <>
      <ArtistLinkInputHeader header={"LAUNCH DATE"} variant={"h5"} />
      <ArtistLinkInputHeader header={"START DATE & TIME"} variant={"h6"} />
      <ArtistLinkInputDateTimePicker
        artistLinkKey={artistLink.startDateTime}
        timeFrame={"startDateTime"}
      />
      <Box pl={4} display="flex">
        <ArtistLinkRadioInput
          checkedValue={artistLink.expires}
          name={"expires"}
          label={"EXPIRES"}
          value={true}
        />
        <ArtistLinkRadioInput
          checkedValue={artistLink.expires}
          name={"expires"}
          label={"NEVER EXPIRES"}
          value={false}
        />
      </Box>

      {artistLink.expires === true && (
        <>
          <ArtistLinkInputHeader
            header={"EXPIRATION DATE & TIME"}
            variant={"h6"}
          />
          <ArtistLinkInputDateTimePicker
            timeFrame={"endDateTime"}
            artistLinkKey={artistLink.endDateTime}
          />
          <Box width={1} pr={3}>
            <ArtistLinkInput
              artistLinkKey={artistLink.endDateTime}
              padding={true}
              display={"none"}
              id={"endDateTime"}
              name={"endDateTime"}
              label={"end date"}
              onChange={() => Logger.of("App").info("validator")}
              validators={["validEndDate"]}
              errorMessage={["Expiration date must be after start date"]}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default LaunchDate;
