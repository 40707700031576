import React, { useState } from "react";
import { ConvertedBlock } from "../../../../../asset-generator-lib/composer/models/Block";
import TemplatePageRenderedSectionWrapper from "../../TemplatePageRenderedSectionWrapper/TemplatePageRenderedSectionWrapper";
import SlideCarousel from "../../../../shared/components/SlideCarousel/SlideCarousel";
import SlideCarouselVideo from "../../../../shared/components/SlideCarouselVideo/SlideCarouselVideo";
import Overlay from "../../../../shared/components/Overlay/Overlay";
import { TemplatePageBlockParam } from "../../../../../asset-generator-lib/composer/models/Block";
import styles from "./RenderedVideosCarousel.module.scss";

type Props = {
  convertedBlock: ConvertedBlock;
  highlight: boolean;
};

const RenderedCarouselVideos = ({ convertedBlock, highlight }: Props) => {
  const [playIndex, setPlayIndex] = useState(-1);

  const textColorOverride = convertedBlock?.params?.textColorOverride?.value;
  const textColor: string =
    textColorOverride?.type === "COLOR" && textColorOverride?.value;
  const backgroundColor = convertedBlock?.params?.background?.value;

  const videos = convertedBlock.params.videos?.value.map(
    (vid: TemplatePageBlockParam, index: number) => {
      return (
        <SlideCarouselVideo
          key={vid.key}
          carouselKey={convertedBlock.key}
          index={index}
          playIndex={playIndex}
          setPlayIndex={setPlayIndex}
          vid={vid}
        />
      );
    }
  );
  return (
    <TemplatePageRenderedSectionWrapper
      title={convertedBlock.title}
      highlight={highlight}
      styleOverride={{
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
    >
      {convertedBlock.params.videos?.value?.length > 0 && (
        <>
          <SlideCarousel>{videos}</SlideCarousel>
          {playIndex > -1 && (
            <Overlay
              setOpen={() => {
                setPlayIndex(-1);
              }}
              closeIcon={true}
            >
              <div
                className={`flex-center ${styles["overlay-content"]}`}
                id="overlay-content"
              ></div>
            </Overlay>
          )}
        </>
      )}
    </TemplatePageRenderedSectionWrapper>
  );
};

export default RenderedCarouselVideos;
