import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  Theme,
  Box,
  makeStyles,
} from "@material-ui/core";

interface Props {
  open: boolean;
  context: any;
  onClose: any;
  actionTitle: string;
  onAction: any;
  children: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  cancelButton: {
    color: "#ffffff",
    backgroundColor: "#999999",
  },
  nodeType: {
    marginLeft: ".25em",
  },
  nodeTitle: {
    marginLeft: ".25em",
    fontStyle: "italic",
  },
}));

export default function ActionDialog(props: Props) {
  const classes = useStyles();

  function handleAction() {
    props.onAction(props.context);
    props.onClose();
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Box fontSize="24px">Confirm</Box>
      </DialogTitle>
      <DialogContent>
        <Box p={2} display="flex">
          <DialogContentText>{props.children}</DialogContentText>
        </Box>
        <div className={classes.buttonsWrapper}>
          <Button
            className={classes.cancelButton}
            variant="contained"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={handleAction}>
            {props.actionTitle}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
