import React from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
interface Props {
  header: string;
  variant: any;
}

type StyleProps = {
  paddingBottom: number;
  paddingTop: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      paddingBottom: ({ paddingBottom }: StyleProps) =>
        theme.spacing(paddingBottom),
      paddingTop: ({ paddingTop }: StyleProps) => theme.spacing(paddingTop),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    header: {
      fontWeight: 800,
      textTransform: "uppercase",
    },
  })
);

export default function ArtistLinkInputHeader(props: Props) {
  let paddingBottom = props.variant === "h6" ? 0 : 2;
  let paddingTop = props.variant === "h6" ? 2 : 0;

  const classes = useStyles({ paddingBottom, paddingTop });

  return (
    <Grid item xs={12}>
      <div className={classes.headerContainer}>
        <Typography variant={props.variant} color="textPrimary">
          <div className={classes.header}>{props.header}</div>
        </Typography>
      </div>
    </Grid>
  );
}
