import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 875,
      lg: 925,
      xl: 1000,
    },
  },
  typography: {
    fontFamily: "Futura",
    fontWeightBold: "bold",
    h5: {
      lineHeight: 1.75,
    },
    h6: {
      fontSize: "12px",
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: "#000000",
      dark: "#000000",
      light: "#333333",
      contrastText: "#b4b4b4",
    },
    secondary: {
      main: "#ff3e66",
      dark: "#1d1d1d",
      light: "#424242",
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "#ff3e66",
    },
  },

  overrides: {
    MuiIcon: {
      colorAction: {
        color: "#fff",
      },
    },
    MuiButton: {
      label: {
        fontWeight: 800,
      },
      containedSecondary: {
        WebkitTextFillColor: "black",
        color: "black",
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "#ffffff80 !important",
        },
      },
      root: {
        borderRadius: 0,
      },
      outlined: {
        backgroundColor: "black",
        border: "2px solid white",
        padding: "4px 15px",
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
        backgroundColor: "#1d1d1d",
        height: "39px",
        maxHeight: "39px",
        paddingRight: "10px",
        cursor: "pointer",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "0px",
        },
        "&:after": {
          borderBottom: "0px",
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "0px !important",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Helvetica",
      },
      input: {
        WebkitBorderRadius: "unset",
        backgroundColor: "#1d1d1d",
        padding: 10,
        paddingLeft: 10,
      },
      multiline: {
        backgroundColor: "#1d1d1d",
        padding: 10,
      },
      adornedStart: {
        backgroundColor: "#1d1d1d",
      },
      adornedEnd: {
        backgroundColor: "#1d1d1d",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "#fff",
        whiteSpace: "nowrap",
      },
    },
    MuiFormLabel: {
      root: {
        zIndex: 1,
        color: "#fff !important",
        whiteSpace: "nowrap",
        fontFamily: "Futura",
        paddingLeft: 20,
      },
    },
    MuiRadio: {
      root: {
        color: "#ff3e66",
      },
    },
    MuiCollapse: {
      // container: {
      //   width: "100%",
      // },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 8,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#424242",
      },
    },

    MuiFilledInput: {
      root: {
        borderTopRightRadius: "0px",
        borderTopLeftRadius: "0px",
        backgroundColor: "#1d1d1d",
      },
      underline: {
        "&:before": {
          borderBottom: "0px",
        },
        "&:after": {
          borderBottom: "0px",
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "0px",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    MuiListItem: {
      secondaryAction: {
        paddingRight: 0,
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: "-12px",
      },
    },
  },
});
