import React from "react";
import styles from "./AddChangeButton.module.scss";
import { ReactComponent as PlusCircleBold } from "../../../../shared/icons/plus-circle-bold.svg";
import { ReactComponent as Pencil } from "../../../../shared/icons/pencil.svg";

type Props = {
  onClick: (() => void) | undefined;
  state: "CHANGE" | "ADD";
  paramName: string | undefined;
  disabled?: boolean;
  buttonTextStates?: [string, string];
  noVerb?: boolean;
};

const AddChangeButton = ({
  onClick,
  state,
  paramName,
  disabled,
  buttonTextStates,
  noVerb,
}: Props) => {
  return (
    <button
      className={`btn btn--form ${styles["add-button"]}`}
      onClick={onClick}
      disabled={disabled}
    >
      {state === "CHANGE" ? (
        <>
          <Pencil className={styles["icon"]} />{" "}
          {!noVerb && (buttonTextStates?.[1] || "CHANGE")}{" "}
        </>
      ) : (
        <>
          <PlusCircleBold className={styles["icon"]} />{" "}
          {!noVerb && (buttonTextStates?.[0] || "ADD")}{" "}
        </>
      )}
      {paramName}
    </button>
  );
};

export default AddChangeButton;
