import React, { ReactChild } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Divider,
  makeStyles,
} from "@material-ui/core";
import StyledLink from "./StyledLink";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

type StyleProps = {
  width: Breakpoint;
};

const useStyles = makeStyles(() => ({
  divider: {
    height: "2px",
    backgroundColor: "white",
    width: "50px",
  },
  headerWrapper: {
    display: "flex",
  },
  headerText: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: (props: StyleProps) =>
      isWidthUp("sm", props.width) ? "24px" : "20px",
    color: "white",
    fontFamily: "'Futura', 'Roboto', Helvitica, Arial, sans-serif",
  },
  marginBottom: {
    marginBottom: "30px",
  },
  buttonWrapper: {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    height: "35px",
    fontWeight: "bold",
  },
}));

type Props = {
  text: string;
  width: Breakpoint;
  button?: {
    link?: string;
    fullText?: string;
    shortText?: string;
  };
  toolTip?: ReactChild;
  padding?: Number;
};

function Header({ text, button, width, toolTip, padding = 3 }: Props) {
  const classes = useStyles({ width });
  const buttonText =
    isWidthUp("md", width) || !button?.shortText
      ? button?.fullText
      : button?.shortText;
  const hasButton = button?.link ? true : false;

  return (
    <Grid container className={classes.marginBottom}>
      <Grid item xs={12}>
        <Box pl={padding} pr={padding}>
          <Grid container alignItems="center">
            {/* heading */}
            <Grid item xs={hasButton ? 9 : 12}>
              <Box className={classes.headerWrapper}>
                <Typography variant="h4" className={classes.headerText}>
                  {text}
                  {toolTip ? toolTip : null}
                </Typography>
              </Box>
              <Box pt={3}>
                <Divider className={classes.divider} />
              </Box>
            </Grid>

            {/* button */}
            {hasButton && (
              <Grid item justify="flex-end" container xs={3}>
                <StyledLink to={button?.link}>
                  <Button variant="contained" color="secondary">
                    <Box className={classes.buttonWrapper}>{buttonText}</Box>
                  </Button>
                </StyledLink>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default withWidth()(Header);
