import React from "react";
import { Grid, Link, makeStyles, Theme, SvgIcon } from "@material-ui/core";
import { ArtistSocials } from "../models/ArtistLink";
import SocialIcon from "../shared/SocialIcons";
import { useArtistLinkContext } from "../../providers/ArtistLinkProvider";
import {
  useAnalyticsContext,
  ArtistLinkAction,
} from "../../providers/AnalyticsProvider";

interface Props {
  fontColor: string;
  playerIsMobile: boolean;
}

type StyleProps = {
  fontColor: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  socialsContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  socialsWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  iconWrapper: {
    height: "100%",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "flex-start",
  },
  iconStyle: {
    color: ({ fontColor }: StyleProps) => fontColor,
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  linkStyle: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
}));
export default function ArtistSocialsSection(props: Props) {
  const { artistLink } = useArtistLinkContext();
  const { trackArtistLink } = useAnalyticsContext();
  const fontColor = props.fontColor;
  const classes = useStyles({ fontColor });

  const trackSocialClick = (artistSocialKey: string) => {
    if (artistLink.id) {
      trackArtistLink(artistLink, ArtistLinkAction.socialLinkClicked, {
        social: artistSocialKey,
      });
    }
  };

  return (
    <Grid container className={classes.socialsContainer}>
      <div className={classes.socialsWrapper}>
        {Object.entries(artistLink.artistSocials).map((artistSocial) => {
          //TODO: had to use typecasting because object constructor returns strings
          const artistSocialKey = artistSocial[0] as keyof ArtistSocials;
          const artistSocialValue = artistSocial[1];

          return (
            <React.Fragment key={artistSocialKey}>
              {artistSocialValue && (
                <div className={classes.iconWrapper}>
                  <Link
                    onClick={() => {
                      trackSocialClick(artistSocialKey);
                    }}
                    className={classes.linkStyle}
                    href={artistSocialValue.validUrl()}
                    target="_blank"
                  >
                    <SvgIcon className={classes.iconStyle}>
                      <SocialIcon icon={artistSocialKey} />
                    </SvgIcon>
                  </Link>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </Grid>
  );
}
